window.domain = window.location.origin+'/';
window.site_url = domain; //"https://dr.cloudcounting.com/";
window.api_url = "https://devapi.rentsynergy.com/";
window.expense_api_url = "https://devexpensesapi.rentsynergy.com/";
window.expense_x_api_key = "isKNemGV4S5CGmZhcbTfq8pM35wxTIkR7PsBif3N";
window.expense_customer_id = "6433a9f75907786f51d5d70c";
window.accessToken = '';
window.max_file_size = 8;
window.maxLength = 500;
window.dwolla_transaction_amount = 5000;
window.disable_confirmation_text = 'disable';
window.delete_confirmation_text = 'delete';
window.restore_confirmation_text = 'restore';
window.cancel_confirmation_text = 'cancel';
window.confirmation_text = 'confirm';
window.WEBSOCKET_URL = 'wss://hzwts9n9jd.execute-api.us-east-2.amazonaws.com/development';
window.HTML2PDF_URL = 'https://cdnjs.cloudflare.com/ajax/libs/html2pdf.js/0.9.0/html2pdf.bundle.min.js';
window.color_array = ['#e7f7f4','#ffe0d1','#97E0E1','#f5e4ef','#f4edfa'];
window.border_color_array = ['#4ba695','#d18f6f','#6FBABB','#b579a0','#b991db'];
window.canvas_width = 817;
window.canvas_height = '';
//window.canvas_height = 1057;
window.role_dd = ['Rent Synergy', 'Property Owner', 'Tenant'];
window.date_dd = ['Equal To', 'Greater Than', 'Less Than', 'Between'];
window.record_to_display = 5;
window.announcementStrWord = 80;
window.currency_sign = '$';

/* Setup timer for autosave after 5 second */
var typingTimer;                //timer identifier
var doneTypingInterval = 5000;  //time in ms, 5 second for example

/* Set logged in User information in localStorage */
window.sa_role = 'cf2e020c-591b-4741-b324-be7e1a088b2a';
window.rss_role = 'ddacd535-3969-42c3-b452-935cc0b0eef2';
window.po_role = '1dc82947-e650-494a-b798-986a6f701d1f';
window.t_role = 'c963a422-edb4-4bad-97ed-276c5abb3b79';

if(localStorage.getItem("user") !== null) {
	window.current_page = localStorage.getItem('page_name');
	window.user = JSON.parse(localStorage.getItem('user'));
	window.status_code = user.user_details.status_code;
	window.skip_add_payment_details = user.user_details.skip_add_payment_details;
	window.is_bank_added = user.user_details.is_bank_added;
	window.user_service_name = $.trim(user.user_details.service_constant);
	window.first_property_id = user.user_details.first_property_id;
	window.allow_ai_access = user.user_details.allow_ai_access;
	window.username = user.user_details.given_name+' '+user.user_details.family_name;
	window.user_email = user.user_details.email;
	window.dwolla_customer_status = user.user_details.dwolla_customer_status;
	window.payment_type_transfer = user.user_details.payment_type_transfer;
	window.zoho_signature_enabled = user.zoho_signature_enabled;
	window.accessToken = user.authentication.AccessToken;
} else {
	if(domain != window.location.href) {
		//window.location.href = window.location.protocol+'//'+window.location.hostname;
	}
}

/* When user access page in new tab check if user is already logged in or not */
window.checkIfSessionAvailable = function() {
	var page_name = localStorage.getItem('page_name');
	if(page_name == 'dashboard') {
		window.location.href = site_url+page_name+'.html';
	} else {
		window.location.href = site_url+'dashboard.html?action='+page_name;
	}
}

/* Below code is used to display modal in middle of screen */
/*window.centerModal = function() {
    $(this).css('display', 'block');
    var $dialog  = $(this).find(".modal-dialog"),
    offset       = ($(window).height() - $dialog.height()) / 2,
    bottomMargin = parseInt($dialog.css('marginBottom'), 10);

    // Make sure you don't hide the top part of the modal w/ a negative margin if it's longer than the screen height, and keep the margin equal to the bottom margin of the modal
    if(offset < bottomMargin) offset = bottomMargin;
    $dialog.css("margin-top", offset);
}
$(document).on('show.bs.modal', '.modal', centerModal);*/

/* Below function will used to display dropdown top when we click on notification ot setting icon */
window.call_mobile_query = function() {
	if($(window).width() < 767) {
		$('#notification_setting_header').find('.dropdown').addClass('dropup');
		$('#assumed_user_email').insertBefore('#main_container');
		announcementStrWord = 25;
	} else {
		$('#notification_setting_header').find('.dropdown').removeClass('dropup');
	}
}

call_mobile_query();
$(window).resize(function(){
	call_mobile_query();
	//$('.modal:visible').each(centerModal);
});

$(document).ajaxComplete(function( event, jqxhr, settings, thrownError ) {
	$('[data-toggle="tooltip"]').tooltip({
		trigger: 'hover'
	});

	$('[data-toggle="tooltip"]').on('click', function () {
    	$(this).tooltip('hide');
    	setTimeout(function(){
		    $('.tooltip').removeClass('show');
		}, 1000);
  	});
});

// Check if cogito incoming token expired
$(document).ajaxError(function( event, jqxhr, settings, thrownError ) {
	var obj = JSON.parse(jqxhr.responseText);
	var msg = obj.response_data.message;
	var status = obj.response_data.error_code;
	if(msg == 'The incoming token has expired' && status == 401) {
		if(domain != window.location.href) {
			var response = {
	            "response_data": {
	            	"success": false,
	              	"message": 'Session Expired'
	            }
	        };
	        display_error_message(response);
			setTimeout(function(){
			    localStorage.clear();
				window.location.href = window.location.protocol+'//'+window.location.hostname;
			}, 5000);
		} else {
			localStorage.clear();
		}
	}
});


$(document).ready(function(){

	// Jquery validate : override checkForm function to working with array field validation
	$.validator.prototype.checkForm = function () {
	    this.prepareForm();
	 
	    for (var i = 0, elements = (this.currentElements = this.elements()) ; elements[i]; i++) {
	        if (this.findByName(elements[i].name).length != undefined && this.findByName(elements[i].name).length > 1) {
	            for (var cnt = 0; cnt < this.findByName(elements[i].name).length; cnt++) {
	                this.check(this.findByName(elements[i].name)[cnt]);
	            }
	        } else {
	            this.check(elements[i]);
	        }
	    }
	    return this.valid();
	}

	$('.phone_us').mask('(000) 000-0000');

	$(".settings-toggle").hide();
	$('#footer').load('footer.html');

	/* Disable right click context menu in browsers */
    /* Disable F12, Ctrl+Shift+i, Ctrl+Shift+j, Ctrl+Shift+c hot key combination in browser to prevent users from opening Fire bug / Developer tools. */

    /*if(user.user_details.user_service_name != sa_role) {
        
        $(document).bind("contextmenu",function(e) {
            e.preventDefault();
        });

        $(document).keydown(function(event){
            if(event.keyCode==123){
                return false;//Prevent from F12
            } else if(event.ctrlKey && event.shiftKey && event.keyCode==73){
                return false; //Prevent from ctrl+shift+i
            } else if(event.ctrlKey && event.shiftKey && event.keyCode==74){
                return false; //Prevent from ctrl+shift+j
            } else if(event.ctrlKey && event.shiftKey && event.keyCode==67){
                return false; //Prevent from ctrl+shift+c
            }
        });
    }*/

    /* End : Disabled Right CLick */

    $('.allow_amount').on('change, keyup', function() {
	    var currentInput = $(this).val();
	    var fixedInput = currentInput.replace(/[A-Za-z!@#$%^&*()]/g, '');
	    $(this).val(fixedInput);
	});

	$('#user_signin').on('click', function(){

		$('.navbar-toggler').trigger('click');

		// Do not allow to access landing page if user already logged
		if(localStorage.getItem("user") !== null) {
			checkIfSessionAvailable();
        } else {
        	$('#signinModal').modal('show');
        	openLoadingDialog($('#user_login_form'));
        	$('#user_login_form').load('components/login.html');
        	reset_form();
        	$('.loader_container').css('display','none');
		    $('.hide_container').hide();
        }

        setTimeout(function(){
		    $('#username').focus();
		}, 500);
 
	});

	$(document).off('click', '.open_signup_modal').on('click', '.open_signup_modal', function() {

		$('.navbar-toggler').trigger('click');

		// Remove selected green color when close modal popup
        var i;
        for (i = 1; i < 5; i++) {
            $('head').append('<style>#signup_checkilist_step_'+i+':before{background:white !important;}</style>');
            $('#signup_checkilist_step_'+i).removeClass('orange');
        }
		
		$('#signup_checkilist_step_1').addClass('orange');
		if(localStorage.getItem("user") !== null) {
			checkIfSessionAvailable();
        } else {
			$('#signupModal').modal('show');
			reset_form();

			// Below if condition will check when tenant signup using invitation URL
			var invitation_code = getUrlParameter('invitation_code');
		    if (typeof invitation_code !== "undefined") {
				$('#create_account_service_input').val(4);
				$('#service_constant').val(t_role);
				$('#user-service-form').addClass('d-none');
				$('#service_container').addClass('d-none');
				$('#signup-form').removeClass('d-none');
				$('#signup-step-4').text('Property Owner Detail');
				getInvitedTenantDetail(invitation_code);
			} else {
				$('#user-service-form').removeClass('d-none');
				$('#service_container').removeClass('d-none');
				$('#signup-form').addClass('d-none');
				$('#signup-step-4').text('Account Detail');

				//$('#choose_account_type').removeClass('d-none');
				$('#create_account_service_input').val(3);
				$('#service_constant').val(po_role);
				$('#signinModal').modal('hide');
			}
		}
	});

	$(document).off('click', '.open_signin_modal').on('click', '.open_signin_modal', function() {

		$('.navbar-toggler').trigger('click');

		if(localStorage.getItem("user") !== null) {
			checkIfSessionAvailable();
        } else {
			$('#signinModal').modal('show');
			openLoadingDialog($('#user_login_form'));
			$('#user_login_form').load('components/login.html');
			reset_form();
			$('.hide_container').hide();
			$('#signupModal').modal('hide');

			setTimeout(function(){
			    $('#username').focus();
			}, 500);
		}
	});

	// Login send passcode api
	$(document).on('click', '.send_passcode', function(e) {
		if(!$loginForm.valid()){
			return false;
		} else {
			$(this).attr('disabled', true);
			$('#login-phone-digit-1').val('');
	   		$('#login-phone-digit-2').val('');
	   		$('#login-phone-digit-3').val('');
	   		$('#login-phone-digit-4').val('');

	   		// If condition used when user login from signup workflow
	   		if($('#user_login_container').children().length > 0 && $.trim($('#user_login_container').text()) != '') {
	   			var username = $('#user_login_container').find('#username').val();
	   		} else {
	   			var username = $('#username').val();	
	   		}
	   		
	   		var data = 'username='+encodeURIComponent(username);

	   		$('.loader_container').css('display','inline-block');

	   		$.ajax({
		        method: 'POST',
		        url: api_url+"auth/login",
		        data: data,
		        async: false,
		    }).then(function(response) {
		    	$('.loader_container').css('display','none');
		    	if(response.response_data.success == false) {
		    		$('.send_passcode').attr('disabled', false);
		    		$('.hide_container').hide();
		    	} else {
		    		$('.hide_container').show();
		    		$('#login_challenge').val(response.challenge);
					$('#login_email').val(response.challengeParameters.email);
					$('#login_session').val(response.session);
					$('#login_username').val(response.challengeParameters.USERNAME);
					$('#login_phone_number').val(response.challengeParameters.phone_number);
					$('#login-phone-digit-1').focus();

					if(response.userData.optinout == 1) {
						$('#login_otp_optin').removeClass('d-none');
						$('#login_otp_optout').addClass('d-none');
					} else {
						$('#login_otp_optout').removeClass('d-none');
						$('#login_otp_optin').addClass('d-none');
					}
		    	}
		    	display_error_message(response);
		    }, function() {
		        // hang on this step if the error occur
		    });
		    return false;
		}
	});

	// Submit passcode api / Login api
	$(document).on('click', '.login_button', function() {
		if(!$passcodeForm.valid()){
			return false;
		} else {
			$('.login_button').attr('disabled', true);
			var challenge = $('#login_challenge').val();
			var email = $('#login_email').val();
			var session = $('#login_session').val();
			var username = $('#login_username').val();
			var phone_number = $('#login_phone_number').val();

			// If condition used when user login from signup workflow
			if($('#user_login_container').children().length > 0 && $.trim($('#user_login_container').text()) != '') {
	   			var username = $('#user_login_container').find('#username').val();
	   			var phone_digit_1 = $('#user_login_container').find('#login-phone-digit-1').val();
		   		var phone_digit_2 = $('#user_login_container').find('#login-phone-digit-2').val();
		   		var phone_digit_3 = $('#user_login_container').find('#login-phone-digit-3').val();
		   		var phone_digit_4 = $('#user_login_container').find('#login-phone-digit-4').val();
		   		var answer = phone_digit_1+phone_digit_2+phone_digit_3+phone_digit_4;
	   		} else {
	   			var phone_digit_1 = $('#login-phone-digit-1').val();
		   		var phone_digit_2 = $('#login-phone-digit-2').val();
		   		var phone_digit_3 = $('#login-phone-digit-3').val();
		   		var phone_digit_4 = $('#login-phone-digit-4').val();
		   		var answer = phone_digit_1+phone_digit_2+phone_digit_3+phone_digit_4;
	   		}

	   		var data = 'challenge='+challenge+'&email='+email+'&session='+session+'&username='+username+'&phone_number='+phone_number+'&answer='+answer;

	   		var url = api_url+"auth/verify";
	   		var credential = true;

	   		ajaxInvoke = true;
		    $.ajax({
		        method: 'POST',
		        url: url,
		        data: data,
		        async: false,
		        xhrFields: {
			        withCredentials: credential
			    },
			    success: function(response) {
	            	if(response.response_data.success == false) {
			    		$('.login_button').attr('disabled', false);
			    		display_error_message(response);
			    	} else {
			    		localStorage.setItem('user', JSON.stringify(response));
			    		localStorage.setItem('page_name', 'dashboard');

			    		window.user = JSON.parse(localStorage.getItem('user'));
						window.status_code = user.user_details.status_code;
						window.user_service_name = $.trim(user.user_details.service_constant);
			    		if(user_service_name == po_role || user_service_name == t_role) {
							var checklist_seq_no = user.user_details.cheklist_seq_no;
							var total_steps = user.user_details.total_steps;
							if((checklist_seq_no < total_steps) && status_code == 1 && (user_service_name == po_role || user_service_name == t_role)) {
								window.location.href = site_url+'dashboard.html';	
							} else {
								window.location.href = site_url+'dashboard.html?action=properties';
							}
						} else {
							window.location.href = site_url+'dashboard.html?action=properties';	
						}
			    	}
		        }
		    });
		    return false;
		}
	});

	// Signout api
	$(document).on('click', '#signout', function() {
		var user = JSON.parse(localStorage.getItem('user'));
		var accessToken = user.authentication.AccessToken;
		var user_id = user.user_details.user_id;
		$.ajax({
	        method: 'POST',
	        url: api_url+"auth/signout",
	        data:"user_id="+user_id,
	        xhrFields: {
		        withCredentials: true
		    },
	        beforeSend: function(xhr) {
		        xhr.setRequestHeader('Authorization', accessToken);
		    },
	        success: function(response) {
            	display_error_message(response);
				localStorage.clear();
		    	if(response.response_data.success == false) {
		    		if(response.redirect_step == 'login') {
		    			window.location.href = site_url;	
		    		}
		    	} else {
		    		window.location.href = site_url;
		    	}
	        }
	    });
	});

	// Start : Signup api

	// Validation
	var $signupForm = $("#create-account-wizard");

	var ajaxInvoke = false;
	$signupForm.smartWizard({
		selected: 0, // Initial selected step, 0 = first step
		theme: "dots",
		contentCache:true,
		autoAdjustHeight: false,
		backButtonSupport: false,
		useURLhash: false,
		showStepURLhash: false,
		enableURLhash: false,
		keyboardSettings: {
	      keyNavigation: false, // Enable/Disable keyboard navigation(left and right keys are used if enabled)
	  	}
	}).on("leaveStep", function(e, anchorObject, stepNumber, stepDirection) {
		// Check validation only when next button click, DO NOT validate on previous button click
		if(stepNumber < stepDirection) {
			if($signupForm.valid()){
				if (stepDirection == 1 && ajaxInvoke == false)
				{
					var tenant_id = $('#tenant_id').val();
					if(tenant_id == null) {
						tenant_id = 0;
					}

					var first_name = $('#firstname').val();
					var last_name = $('#lastname').val();
					var email = $('#email_address').val();
					var country_code = $('#country_code').val();
					var phone = $.trim($('#phone_number').val());
					phone = phone.replace('-', '');
					phone = phone.replace('(', '');
					phone = phone.replace(')', '');
					phone = phone.replace(/\s/g, '');
					phone = country_code+phone;
					var service_ids = $('#create_account_service_input').val();
					var service_constant = $('#service_constant').val();

					if($('#verify_mobile_checkbox').is(":checked")) {
						var verify_mobile = 1;
					} else {
						var verify_mobile = 0;
					}

					if($('#optinout_checkbox').is(":checked")) {
						var optinout = 1;
					} else {
						var optinout = 0;
					}

					if($('#agree').is(":checked")) {
						var agree = 1;
					} else {
						var agree = 0;
					}

					var data = 'first_name='+first_name+'&last_name='+last_name+'&email='+email+'&phone='+encodeURIComponent(phone)+'&service_ids='+service_ids+'&service_constant='+service_constant+'&tenant_id='+tenant_id+'&optinout='+optinout+'&agree='+agree+'&verify_mobile='+verify_mobile;

				    ajaxInvoke = true;
				    $.ajax({
				        method: 'POST',
				        url: api_url+"auth/signup",
				        data: data,
				        success: function(response) {
				        	display_error_message(response);
					    	if(response.response_data.success == false) {
					    		ajaxInvoke = false;
					    		return false;
					    	} else {

					    		// Below if condition will check when tenant signup using invitation URL
								var invitation_code = getUrlParameter('invitation_code');
								if (typeof invitation_code !== "undefined") {
						    		// Checkilist step 1
						    		getSignupNextStep(1,2);
								} else {
									// Checkilist step 2
						    		getSignupNextStep(2,3);
								}

					    		$signupForm.smartWizard("next");
					    		//$(".sw-btn-prev").show();
								$(".sw-btn-next").text('Next');
					    		// Set value for second step
					    		$('#usersub').val(response.user_details.usersub);

					    		$('#destination_email').text(censorEmail(email));
					    		$('#email_verified').val(response.user_details.email_verified);
					    		$('#phone_verified').val(response.user_details.phone_verified);
					    		$('#optinout').val(response.user_details.optinout);

					    		$('#signup-email-digit-1').focus();

					    		if(response.user_details.email_verified == 1) {
					    			$('.enter_email_code_container').html('<h1 class="green"><i class="align-middle mr-2 fas fa-fw fa-check"></i> Your email has been verified. Thank You!</h1>');
					    		}

					    		ajaxInvoke = false;
					    	}
				        }
				    });
				    return false;
				}
				else if(stepDirection == 2 && ajaxInvoke == false)
				{
			   		var email_digit_1 = $('#signup-email-digit-1').val();
			   		var email_digit_2 = $('#signup-email-digit-2').val();
			   		var email_digit_3 = $('#signup-email-digit-3').val();
			   		var email_digit_4 = $('#signup-email-digit-4').val();
			   		var email_digit_5 = $('#signup-email-digit-5').val();
			   		var email_digit_6 = $('#signup-email-digit-6').val();

			   		var usersub = $('#usersub').val();
			   		var email_verified = $('#email_verified').val();
			   		var phone_verified = $('#phone_verified').val();
			   		var confirmation_code = email_digit_1+email_digit_2+email_digit_3+email_digit_4+email_digit_5+email_digit_6;
			   		
			   		var data = 'usersub='+usersub+'&confirmation_code='+confirmation_code+'&email_verified='+email_verified+'&phone_verified='+phone_verified;

				    ajaxInvoke = true;
				    $.ajax({
				        method: 'POST',
				        url: api_url+"auth/confirm-signup",
				        data: data,
				        success: function(response) {
				        	display_error_message(response);
					    	if(response.response_data.success == false) {
					    		ajaxInvoke = false;
					    		return false;
					    	} else {

					    		// Below if condition will check when tenant signup using invitation URL
								var invitation_code = getUrlParameter('invitation_code');
								if (typeof invitation_code !== "undefined") {
						    		// Checkilist step 2
						    		getSignupNextStep(2,3);
								} else {
									// Checkilist step 3
						    		getSignupNextStep(3,4);
								}

					    		$signupForm.smartWizard("next");
					    		//$(".sw-btn-prev").show();
								$(".sw-btn-next").text('Next');
					    		// Set value for third step
					    		$('#usersub').val(response.user_details.usersub);
					    		$('#phone_verified').val(response.user_details.phone_verified);
					    		$('#destination_phone').text(response.user_details.destination);
					    		$('#access_token').val(response.user_details.accessToken);

					    		if(response.user_details.phone_verified == 1) {
					    			$('.enter_phone_code_container').html('<h1 class="green"><i class="align-middle mr-2 fas fa-fw fa-check"></i> Your phone has been verified. Thank You!</h1>');
					    		}

					    		/*if($('#optinout').val() == 0) {
					    			$('#optout').removeClass('d-none');
					    			$('#optin').addClass('d-none');
					    			$('.enter_phone_code_container').addClass('add_opacity');
					    		} else {
					    			$('#signup-phone-digit-1').focus();
					    			$('.enter_phone_code_container').removeClass('add_opacity');
					    			$('#optin').removeClass('d-none');
					    			$('#optout').addClass('d-none');
					    		}*/

					    		ajaxInvoke = false;
					    	}
				        }
				    });
				    return false;
			   	}
			   	else if(stepDirection == 3 && ajaxInvoke == false)
			   	{
		   			var phone_digit_1 = $('#signup-phone-digit-1').val();
			   		var phone_digit_2 = $('#signup-phone-digit-2').val();
			   		var phone_digit_3 = $('#signup-phone-digit-3').val();
			   		var phone_digit_4 = $('#signup-phone-digit-4').val();
			   		var phone_digit_5 = $('#signup-phone-digit-5').val();
			   		var phone_digit_6 = $('#signup-phone-digit-6').val();

			   		var usersub = $('#usersub').val();
			   		var accessToken = $('#access_token').val();
			   		var confirmation_code = phone_digit_1+phone_digit_2+phone_digit_3+phone_digit_4+phone_digit_5+phone_digit_6;

			   		var data = 'usersub='+usersub+'&confirmation_code='+confirmation_code+'&wizard_name=signup&seq_no=4';

			   		ajaxInvoke = true;
				    $.ajax({
				        method: 'POST',
				        url: api_url+"auth/verify-phone",
				        data: data,
				        beforeSend: function(xhr) {
					        xhr.setRequestHeader('Authorization', accessToken);
					    },
					    success: function(response) {
					    	display_error_message(response);
					    	if(response.response_data.success == false) {
					    		ajaxInvoke = false;
					    		return false;
					    	} else {

					    		// Below if condition will check when tenant signup using invitation URL
								var invitation_code = getUrlParameter('invitation_code');
								if (typeof invitation_code !== "undefined") {
						    		// Checkilist step 3
						    		getSignupNextStep(3,4);

									// Display Login Form
						    		$('.toolbar-bottom').hide();
						    		$('.enter_phone_code_container').remove();
						    		openLoadingDialog($('#user_login_container'));
						    		$('#user_login_container').load('components/login.html', function(){
						    			//$(this).find('#login-form').addClass('popup-card-fixed-width');
						    		});
								} else {
									// Checkilist step 4
						    		getSignupNextStep(4,5);

									$signupForm.smartWizard("next");
						    		//$(".sw-btn-prev").show();
									$(".sw-btn-next").text('Next');
						    		ajaxInvoke = false;
								}
					    	}
					    }
				    });
					return false;
			   	}
			   	else if(stepDirection == 4 && ajaxInvoke == false)
			   	{
			   		
			   	}
			} else {
				return false;
			}
		} else {
			$(".sw-btn-next").show();
			$(".complete_registration").remove();
			//Click on preview button from service
			if (stepDirection == 1 && ajaxInvoke == false)
			{
				var first_name = $('#firstname').val();
				var last_name = $('#lastname').val();
				var email = $('#email_address').val();
				var country_code = $('#country_code').val();
				var phone = $.trim($('#phone_number').val());
				phone = phone.replace('-', '');
				phone = phone.replace('(', '');
				phone = phone.replace(')', '');
				phone = phone.replace(/\s/g, '');
				phone = country_code+phone;
				var service_ids = $('#create_account_service_input').val();
				var service_constant = $('#service_constant').val();

				if($('#optinout').val() == 1) {
					$('#optinout_checkbox').prop('checked', true); 
					var optinout = 1;
				} else {
					$('#optinout_checkbox').prop('checked', false);
					var optinout = 0;
				}

				var data = 'first_name='+first_name+'&last_name='+last_name+'&email='+email+'&phone='+encodeURIComponent(phone)+'&service_ids='+service_ids+'&optinout='+optinout;

			    ajaxInvoke = true;
			    $.ajax({
			        method: 'POST',
			        url: api_url+"auth/signup",
			        data: data,
			        success: function(response) {
			        	display_error_message(response);
				    	if(response.response_data.success == false) {
				    		ajaxInvoke = false;
				    		return false;
				    	} else {
				    		// Set value for second step
				    		$('#usersub').val(response.user_details.usersub);
				    		$('#destination_email').text(censorEmail(email));
				    		$('#email_verified').val(response.user_details.email_verified);
				    		$('#phone_verified').val(response.user_details.phone_verified);
				    		$('#optinout').val(response.user_details.optinout);

				    		if(response.user_details.email_verified == 1) {
				    			$('.enter_email_code_container').html('<h1 class="green"><i class="align-middle mr-2 fas fa-fw fa-check"></i> Your email has been verified. Thank You!</h1>');
				    		}

				    		ajaxInvoke = false;
				    	}
			        }
			    });
			}
			else if(stepDirection == 2 && ajaxInvoke == false)
			{
				var email_digit_1 = $('#signup-email-digit-1').val();
		   		var email_digit_2 = $('#signup-email-digit-2').val();
		   		var email_digit_3 = $('#signup-email-digit-3').val();
		   		var email_digit_4 = $('#signup-email-digit-4').val();
		   		var email_digit_5 = $('#signup-email-digit-5').val();
		   		var email_digit_6 = $('#signup-email-digit-6').val();

		   		var usersub = $('#usersub').val();
		   		var email_verified = $('#email_verified').val();
		   		var phone_verified = $('#phone_verified').val();
		   		var confirmation_code = email_digit_1+email_digit_2+email_digit_3+email_digit_4+email_digit_5+email_digit_6;
		   		
		   		var data = 'usersub='+usersub+'&confirmation_code='+confirmation_code+'&email_verified='+email_verified+'&phone_verified='+phone_verified;

			    ajaxInvoke = true;
			    $.ajax({
			        method: 'POST',
			        url: api_url+"auth/confirm-signup",
			        data: data,
			        success: function(response) {
			        	display_error_message(response);
				    	if(response.response_data.success == false) {
				    		ajaxInvoke = false;
				    		return false;
				    	} else {
				    		// Set value for third step
				    		$('#usersub').val(response.user_details.usersub);
				    		$('#phone_verified').val(response.user_details.phone_verified);
				    		$('#destination_phone').text(response.user_details.destination);
				    		$('#access_token').val(response.user_details.accessToken);

				    		if(response.user_details.phone_verified == 1) {
				    			$('.enter_phone_code_container').html('<h1 class="green"><i class="align-middle mr-2 fas fa-fw fa-check"></i> Your phone has been verified. Thank You!</h1>');
				    		}

				    		/*if($('#optinout').val() == 0) {
				    			$('#optout').removeClass('d-none');
				    			$('#optin').addClass('d-none');
				    			$('.enter_phone_code_container').addClass('add_opacity');
				    		} else {
				    			$('#signup-phone-digit-1').focus();
				    			$('.enter_phone_code_container').removeClass('add_opacity');
				    			$('#optin').removeClass('d-none');
				    			$('#optout').addClass('d-none');
				    		}*/

				    		ajaxInvoke = false;
				    	}
			        }
			    });
			}
		}
	}).on("stepContent", function(e, anchorObject, stepIndex, stepDirection) {
		if(stepIndex == 3) {

			openLoadingDialog($('#business_or_property_detail_container'));

			// Below if condition will check when tenant signup using invitation URL
			/*var invitation_code = getUrlParameter('invitation_code');
		    var selected_service_id = $('#create_account_service_input').val();
		    if (typeof invitation_code !== "undefined") {
				$('#business_or_property_detail_container').load('components/signup_property_detail.html', function(){
					getInvitedTenantDetail(invitation_code);
					$('#property_address_line1, #property_address_line2, #property_city, #property_state, #property_zip_code, #property_country').attr('readonly',true);
					$('#signup_address').html('');
				});
			} else {
				if(selected_service_id == 2) {
					$('#business_or_property_detail_container').load('components/signup_property_detail.html', function() {
						$('#signup_address').html('(optional)');
						getStateDropdownForSignup();
					});
				} else {
					$('#business_or_property_detail_container').load('components/signup_business_detail.html', function(){
						getStateDropdownForSignup();
					});
				}
			}*/

			$(".sw-btn-next").remove();
			$('#business_or_property_detail_container').load('components/signup_business_detail.html', function(){
				var selected_state = '';
				getStateDropdownForSignup(selected_state);
				$('#business_or_property_detail_container').append('<div class="col-sm-12 col-md-10 col-lg-12 mx-auto d-table h-100"><div class="row justify-content-center"><div class="col-auto ml-auto text-right mt-2 mb-2 mr-2"><button type="button" class="btn btn-orange complete_registration">Next</button></div></div></div>');
			});
		}
	});
	
	$signupForm.validate({
		errorPlacement: function errorPlacement(error, element) {
			$(element).parents(".form-group").append(error.addClass("invalid-feedback small d-block"))
		},
		highlight: function(element) {
			$(element).addClass("is-invalid");
		},
		unhighlight: function(element) {
			$(element).removeClass("is-invalid");
		},
		rules: {
			"firstname": { required: true, lettersonly: true },
			"lastname": { required: true, lettersonly: true },
			"email_address": { required: true, email: true },
			"country_code": { required: true },
			"phone_number": { required: true },
			"verify_mobile_checkbox": { required: true },
			"agree": { required: true },
			"signup-email-digit-1": { required: true },
			"signup-email-digit-2": { required: true },
			"signup-email-digit-3": { required: true },
			"signup-email-digit-4": { required: true },
			"signup-email-digit-5": { required: true },
			"signup-email-digit-6": { required: true },
			"signup-phone-digit-1": { required: true },
			"signup-phone-digit-2": { required: true },
			"signup-phone-digit-3": { required: true },
			"signup-phone-digit-4": { required: true },
			"signup-phone-digit-5": { required: true },
			"signup-phone-digit-6": { required: true },
			"address_line1": { required: true },
			"city": { required: true },
			"state": { required: true },
			"zip_code": { required: true },
			"country": { required: true },
			//"property_owner_firstname": { required: true, lettersonly: true },
			//"property_owner_lastname": { required: true, lettersonly: true },
			//"property_owner_email_address": { required: true, email: true },
		},
		messages: {
			"firstname": { required: '', lettersonly: 'Please enter alphabets only' },
			"lastname": { required: '', lettersonly: 'Please enter alphabets only' },
			"email_address": { required: '', email: 'Please enter valid email address' },
			"country_code": { required: '' },
			"phone_number": { required: '' },
			"verify_mobile_checkbox": { required: '' },
			"agree": { required: '' },
			"signup-email-digit-1": { required: '' },
			"signup-email-digit-2": { required: '' },
			"signup-email-digit-3": { required: '' },
			"signup-email-digit-4": { required: '' },
			"signup-email-digit-5": { required: '' },
			"signup-email-digit-6": { required: '' },
			"signup-phone-digit-1": { required: '' },
			"signup-phone-digit-2": { required: '' },
			"signup-phone-digit-3": { required: '' },
			"signup-phone-digit-4": { required: '' },
			"signup-phone-digit-5": { required: '' },
			"signup-phone-digit-6": { required: '' },
			"address_line1": { required: '' },
			"city": { required: '' },
			"state": { required: '' },
			"zip_code": { required: '' },
			"country": { required: '' },
			//"property_owner_firstname": { required: '', lettersonly: 'Please enter alphabets only' },
			//"property_owner_lastname": { required: '', lettersonly: 'Please enter alphabets only' },
			//"property_owner_email_address": { required: '', email: 'Please enter valid email address' },
		}
	});

	jQuery.validator.addMethod("lettersonly", function(value, element) 
	{
		return this.optional(element) || /^[a-z," "]+$/i.test(value);
	}, "Please enter alphabets only"); 

	$(document).off('click', '.resend_email_verification_code').on('click', '.resend_email_verification_code', function(e) {
		// Clear old verification code from input
		$('#signup-email-digit-1').val('');
   		$('#signup-email-digit-2').val('');
   		$('#signup-email-digit-3').val('');
   		$('#signup-email-digit-4').val('');
   		$('#signup-email-digit-5').val('');
   		$('#signup-email-digit-6').val('');

   		$('#signup-email-digit-1').focus();

		var email = $('#email_address').val();
		var data = 'email='+email;

	    $.ajax({
	        method: 'POST',
	        url: api_url+"auth/resend-verify-email",
	        data: data
	    }).then(function(response) {
	    	display_error_message(response);
	    	if(response.response_data.success != false) {
	    		if(response.user_details.email_verified == 1) {
	    			$('.enter_email_code_container').html('<h1><i class="align-middle mr-2 fas fa-fw fa-check"></i> Email already verified</h1>');
	    		}
	    	}
	    }, function() {
	        // hang on this step if the error occur
	    });
	});

	$(document).off('click', '.resend_phone_verification_code').on('click', '.resend_phone_verification_code', function(e) {
		$('#signup-phone-digit-1').val('');
   		$('#signup-phone-digit-2').val('');
   		$('#signup-phone-digit-3').val('');
   		$('#signup-phone-digit-4').val('');
   		$('#signup-phone-digit-5').val('');
   		$('#signup-phone-digit-6').val('');

   		$('#signup-phone-digit-1').focus();

		var country_code = $('#country_code').val();
		var phone = $.trim($('#phone_number').val());
		phone = phone.replace('-', '');
		phone = phone.replace('(', '');
		phone = phone.replace(')', '');
		phone = phone.replace(/\s/g, '');
		phone = country_code+phone;
		var data = 'phone='+encodeURIComponent(phone);

	    $.ajax({
	        method: 'POST',
	        url: api_url+"auth/resend-verify-phone",
	        data: data,
	        beforeSend: function(xhr) {
	        	if(accessToken != '') {
	        		xhr.setRequestHeader('Authorization', accessToken);
	        	} else {
	        		xhr.setRequestHeader('Authorization', $('#access_token').val());
	        	}
		    },
	    }).then(function(response) {
	    	display_error_message(response);
	    	if(response.response_data.success != false) {
	    		if(response.user_details.email_verified == 1) {
	    			$('.enter_email_code_container').html('<h1><i class="align-middle mr-2 fas fa-fw fa-check"></i> Email already verified</h1>');
	    		}
	    	}
	    }, function() {
	        // hang on this step if the error occur
	    });
	});

	/*$(document).on('click', '.verify_address', function() {
		var type = $(this).attr('data-type');
		if(type == 'signup') {
			var address1 = $('#address_line1').val();
	   		var address2 = $('#address_line2').val();
	   		var city = $('#city').val();
	   		var state = $('#state').val();
	   		var zipcode = $('#zip_code').val();
	   	} else if(type == 'property_add') {
	   		var address1 = $('#add_property_address_line1').val();
	   		var address2 = $('#add_property_address_line2').val();
	   		var city = $('#add_property_city').val();
	   		var state = $('#add_property_state').val();
	   		var zipcode = $('#add_property_zip_code').val();
	   	} else if(type == 'property_edit') {
	   		var address1 = $('#edit_property_address_line1').val();
	   		var address2 = $('#edit_property_address_line2').val();
	   		var city = $('#edit_property_city').val();
	   		var state = $('#edit_property_state').val();
	   		var zipcode = $('#edit_property_zip_code').val();
	   	} else if(type == 'property_primary_contact') {
	   		var address1 = $('#primary_contact_address_line1').val();
	   		var address2 = $('#primary_contact_address_line2').val();
	   		var city = $('#primary_contact_city').val();
	   		var state = $('#primary_contact_state').val();
	   		var zipcode = $('#primary_contact_zip_code').val();
	   	}
   		verify_usps_address(type,address1,address2,city,state,zipcode);
	});*/

	/*$(document).on('change', '#create-account-wizard #address_line1, #create-account-wizard #address_line2, #create-account-wizard #city, #create-account-wizard #state, #create-account-wizard #zip_code,#property-primary-contact-address #primary_contact_address_line1, #property-primary-contact-address #primary_contact_address_line2, #property-primary-contact-address #primary_contact_city, #property-primary-contact-address #primary_contact_state, #property-primary-contact-address #primary_contact_zip_code, #property_add_form #add_property_address_line1, #property_add_form #add_property_address_line2, #property_add_form #add_property_city, #property_add_form #add_property_state, #property_add_form #add_property_zip_code, #property_edit_form #edit_property_address_line1, #property_edit_form #edit_property_address_line2, #property_edit_form #edit_property_city, #property_edit_form #edit_property_state, #property_edit_form #edit_property_zip_code', function() {

		if($('.verify_address').is('[disabled=disabled]') && $('.verify_address').text() == 'Verified') {
			if($('#property-primary-contact-address').length > 0) {
				$(".save_property_primary_contact_detail").attr('disabled', true);
			} else if($('#property_add_form').length > 0) {
				$(".save_property_data").attr('disabled', true);
			} else if($('#property_edit_form').length > 0) {
				$(".update_property_address").attr('disabled', true);
			} else {
				$(".complete_registration").attr('disabled', true);
	        }

	        $(".verify_address").text('Verify Address');
	        $(".verify_address").attr('disabled',false);
		}
	});*/

	$(document).on('click', '#agree, #verify_mobile_checkbox', function() {
		if($('#verify_mobile_checkbox').is(":checked") && $('#agree').is(":checked")) {
   			$(".sw-btn-next").attr('disabled',false);
   		} else {
   			$(".sw-btn-next").attr('disabled',true);
   		}
	});

	$(document).on('click', '.complete_registration', function() {
		var type = "signup";
		var address1 = $('#address_line1').val();
   		var address2 = $('#address_line2').val();
   		var city = $('#city').val();
   		var state = $('#state').val();
   		var zipcode = $('#zip_code').val();

   		if($signupForm.valid()){
   			$('.complete_registration').attr('disabled', true);
   			var invitation_code = getUrlParameter('invitation_code');
			if (typeof invitation_code !== "undefined") {
   				completeRegistration();
   			} else {
   				// when tenants directly signup from our ui
   				var tenant_user_id = $('#tenant_user_id').val();
				var service_id = $('#create_account_service_input').val();
				var service_constant = $('#service_constant').val();
   				if(service_constant == t_role && tenant_user_id == '') {
   					var address1 = $('#property_address_line1').val();
			   		var address2 = $('#property_address_line2').val();
			   		var city = $('#property_city').val();
			   		var state = $('#property_state').val();
			   		var zipcode = $('#property_zip_code').val();
			   		if(address1 != "" || address2 != "" || city != "" || state != "" || zipcode != "") {
			   			verify_usps_address(type,address1,address2,city,state,zipcode);
			   		} else {
			   			completeRegistration();	
			   		}
   				} else {
   					verify_usps_address(type,address1,address2,city,state,zipcode);
   				}
   			}
   		}
	});

	$(document).on('click', '.create_account_service_card', function() {
		var checklist_step = $(this).attr('data-checklist-step');
		var service_id = $(this).attr('data-service-id');
		var service_constant = $(this).attr('data-service-constant');

		getSignupNextStep(1,2);

		$('#create_account_service_input').val(service_id);
		$('#service_constant').val(service_constant);
		$('#user-service-form').addClass('d-none');
		$('#signup-form').removeClass('d-none');
		if(service_constant == t_role) {
			$('#signup-step-4').text('Property Owner Detail');
		}
	});

	// End : Signup api

	// OTP input
	$('.digit-group').find('input').each(function() {
		$(this).attr('maxlength', 1);
		$(this).on('keyup', function(e) {
			var parent = $($(this).parent());
			
			if(e.keyCode === 8 || e.keyCode === 37) {
				var prev = parent.find('input#' + $(this).data('previous'));
				
				if(prev.length) {
					$(prev).select();
				}
			} else if((e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 65 && e.keyCode <= 90) || (e.keyCode >= 96 && e.keyCode <= 105) || e.keyCode === 39) {
				var next = parent.find('input#' + $(this).data('next'));
				
				if(next.length) {
					$(next).select();
				} else {
					if(parent.data('autosubmit')) {
						parent.submit();
					}
				}
			}
		});
	});

	$(document).off('keypress', '.allow-number').on('keypress', '.allow-number', function(e) {
		//if the letter is not digit then display error and don't type anything
	    if (e.which != 8 && e.which != 0 && (e.which < 48 || e.which > 57)) {
	        return false;
	    }
	});

	$(document).off('keypress', '.allow-number-dash').on('keypress', '.allow-number-dash', function(e) {
		var txt = String.fromCharCode(e.which)
	    var pattern = /^[0-9\-]+$/;
	    if (!(pattern.test(txt) || e.keyCode == 8)){
	    	return false;
	    }
	});

	// LEFT MENU CODE

	if(localStorage.getItem("user") !== null) {
		loadUserDetail(user.user_details.user_id);
		loadLeftMenu();
		$('#search_form_container').load('components/search.html');		
		loadDashboardPage();
		initialize_datepicker('vacant_date');
	}

	if(localStorage.getItem("primary") !== null) {
		$('#assumed_user_email').removeClass('d-none');
		$('#assumed_user_email').append('<div class="alert-message"><strong>'+username+' ('+user.user_details.email+')</strong></div>');
	}

	// HEADER SWITCH USER
	$(document).off('click', '.switch_user').on('click', '.switch_user', function(e) {
		e.stopPropagation();
		var linked_account_id = $(this).attr('data-id');
		var email = $(this).attr('data-email');
		var arrow = $(this).find('i').attr('data-type');
		if(arrow == 'down') {
			$(this).find('span').html('<i data-type="up" class="float-right fas fa-fw fa-angle-up"></i>');
			$(this).append('<div class="child-sec pt-3 pb-3"><button data-email="'+email+'" type="button" class="btn btn-primary mr-1 switch_btn">Switch</button></div>');
			$(this).addClass('bg-light');
		} else {
			$(this).find('span').html('<i data-type="down" class="float-right fas fa-fw fa-angle-down"></i>');
			$(this).find('.child-sec').remove();
			$(this).removeClass('bg-light');
		}
	});

	$(document).off('click', '.switch_btn').on('click', '.switch_btn', function(e) {
		e.stopPropagation();
		$(this).attr('disabled', true);
		var email = $(this).attr('data-email');
		var type = 'linked_service';
		getAssumedUserData('linked_service', email);
	});

	$(document).off('click', '.child-sec').on('click', '.child-sec', function(e) {
		e.stopPropagation();
	});
	
	// LEFT MENU SIDE BAR CLICK EVENT API
	$(document).off('click', '.sidebar-item').on('click', '.sidebar-item', function() {
		$('.sidebar-item').find('a.sidebar-link').removeClass('bg-light');
		$('.sidebar-item').find('a.sidebar-link').removeClass('orange');
		$(this).find('a.sidebar-link').addClass('bg-light');
		$(this).find('a.sidebar-link').addClass('orange');

		var page_name = $(this).attr('data-page-name');
		load_selected_page(page_name,'left_menu');

		// When click on left side menu item, check if search unpin then clear search data and display selected page
		var search = localStorage.getItem('search');
		if(search == 'unpin') {
			if(localStorage.getItem("filter_data") !== null) {
				$('.clear_search_property_data').trigger('click');
				$('#global_search_form .close').trigger('click');
			}
		}
	});

	/* KYC field validation */

	$(document).on('click', '#kyc_checkbox_1, #kyc_checkbox_2', function() {
		if(!$('#kyc_checkbox_1').is(":checked") || !$('#kyc_checkbox_2').is(":checked")) {
			$('.submit_kyc_detail').attr('disabled', true);
		} else {
			if($('#kyc-detail-wizard #user_first_name').val() == '' || $('#kyc-detail-wizard #user_last_name').val() == '' || $('#kyc-detail-wizard #user_email_id').val() == '' || $('#kyc-detail-wizard #user_address_line1').val() == '' || $('#kyc-detail-wizard #user_city').val() == '' || $('#kyc-detail-wizard #user_state').val() == '' || $('#kyc-detail-wizard #user_zip_code').val() == '' || $('#kyc-detail-wizard #user_country').val() == '' || $('#kyc-detail-wizard #ssn').val() == '' || $('#kyc-detail-wizard input[name="date_of_birth"]').val() == '' ) {
				$('.submit_kyc_detail').attr('disabled', true);
			} else {
				$('.submit_kyc_detail').attr('disabled', false);
			}
		}
	});

	$(document).on('keyup change', '#kyc-detail-wizard #user_first_name, #kyc-detail-wizard #user_last_name, #kyc-detail-wizard #user_email_id, #kyc-detail-wizard #user_address_line1, kyc-detail-wizard #user_city, #kyc-detail-wizard #user_state, #kyc-detail-wizard #user_zip_code, #kyc-detail-wizard #user_country, #kyc-detail-wizard #ssn, #kyc-detail-wizard input[name="date_of_birth"]', function() {

		if($('#kyc-detail-wizard #user_first_name').val() == '' || $('#kyc-detail-wizard #user_last_name').val() == '' || $('#kyc-detail-wizard #user_email_id').val() == '' || $('#kyc-detail-wizard #user_address_line1').val() == '' || $('#kyc-detail-wizard #user_city').val() == '' || $('#kyc-detail-wizard #user_state').val() == '' || $('#kyc-detail-wizard #user_zip_code').val() == '' || $('#kyc-detail-wizard #user_country').val() == '' || $('#kyc-detail-wizard #ssn').val() == '' || $('#kyc-detail-wizard input[name="date_of_birth"]').val() == '' ) {
			$('.submit_kyc_detail').attr('disabled', true);
		} else {
			if(!$('#kyc_checkbox_1').is(":checked") || !$('#kyc_checkbox_2').is(":checked")) {
				$('.submit_kyc_detail').attr('disabled', true);
			} else {
				$('.submit_kyc_detail').attr('disabled', false);
			}
		}
	});

	// Submit KYC Detail
	$(document).off('click', '.submit_kyc_detail').on('click', '.submit_kyc_detail', function() {
		if($kycForm.valid()){
			var ssn = $('#ssn').val();
			var dwolla_customer_status = $('#dwolla_customer_status').val();
			if(dwolla_customer_status == 0 && ssn.length < 4) {
				var res = {
	                "response_data": {
	                    "success": false,
	                    "message": 'Please enter last four digit of SSN'
	                }
	            };
	            display_error_message(res);
	            return false;
			}

			if(dwolla_customer_status == 2 && ssn.length < 11) {
				var res = {
	                "response_data": {
	                    "success": false,
	                    "message": 'Please enter full 9-digits SSN'
	                }
	            };
	            display_error_message(res);
	            return false;
			}

			var type = 'kyc';
			var address1 = $('#user_address_line1').val();
			var address2 = $('#user_address_line2').val();
			var city = $('#user_city').val();
			var state = $('#user_state').val();
			var zipcode = $('#user_zip_code').val();
			var country = $('#user_country').val();

			verify_usps_address(type,address1,address2,city,state,zipcode);
	    }
	});

	// Submit KYC Detail
	$(document).off('click', '.update_dwolla_customer').on('click', '.update_dwolla_customer', function() {
		if($updateCustomerForm.valid()){
			var ssn = $('#customer_ssn').val();
			if(ssn.length < 4) {
				var res = {
	                "response_data": {
	                    "success": false,
	                    "message": 'Please enter last four digit of SSN'
	                }
	            };
	            display_error_message(res);
	            return false;
			}

			var type = 'update_dwolla_customer';
			var address1 = $('#customer_address_line1').val();
			var address2 = $('#customer_address_line2').val();
			var city = $('#customer_city').val();
			var state = $('#customer_state').val();
			var zipcode = $('#customer_zip_code').val();
			var country = $('#customer_country').val();

			verify_usps_address(type,address1,address2,city,state,zipcode);
	    }
	});

	/* Add Bank Field validation */

	$(document).on('click', '#electronic_checkbox', function() {
		if(!$('#electronic_checkbox').is(":checked")) {
			$('.save_payment_detail').attr('disabled', true);
		} else {
			if($('#payment-detail-wizard #bank_name').val() == '' || $('#payment-detail-wizard #account_type').val() == '' || $('#payment-detail-wizard #routing_number').val() == '' || $('#payment-detail-wizard #account_number').val() == '' || $('#payment-detail-wizard #confirm_account_number').val() == '') {
				$('.save_payment_detail').attr('disabled', true);
			} else {
				$('.save_payment_detail').attr('disabled', false);
			}
		}
	});

	$(document).on('keyup change', '#payment-detail-wizard #bank_name, #payment-detail-wizard #account_type, #payment-detail-wizard #routing_number, #payment-detail-wizard #account_number, #payment-detail-wizard #confirm_account_number', function() {

		if($('#payment-detail-wizard #bank_name').val() == '' || $('#payment-detail-wizard #account_type').val() == '' || $('#payment-detail-wizard #routing_number').val() == '' || $('#payment-detail-wizard #account_number').val() == '' || $('#payment-detail-wizard #confirm_account_number').val() == '') {
			$('.save_payment_detail').attr('disabled', true);
		} else {
			if(!$('#electronic_checkbox').is(":checked")) {
				$('.save_payment_detail').attr('disabled', true);
			} else {
				$('.save_payment_detail').attr('disabled', false);
			}
		}
	});

	$(document).off('mousedown', '#kyc-detail-wizard .view_icon, #payment-detail-wizard .view_icon').on('mousedown', '#kyc-detail-wizard .view_icon, #payment-detail-wizard .view_icon', function() {
		var element = $(this).attr('data-type');
		$('#'+element).attr('type','text');
	}).off('mouseup mouseleave', '#kyc-detail-wizard .view_icon, #payment-detail-wizard .view_icon').on('mouseup mouseleave', '#kyc-detail-wizard .view_icon, #payment-detail-wizard .view_icon', function() {
		var element = $(this).attr('data-type');
		$('#'+element).attr('type','password');
	});

	// Get zoho document status
	$(document).off('click', '.check_zoho_document_status').on('click', '.check_zoho_document_status', function(){
		var user_id = user.user_details.user_id;
	    checkZohoDocumentStatus(user_id);
	});

	// Search list
	$(document).off('keyup', '.search_input').on('keyup', '.search_input', function(){
	    var text = $(this).val().toLowerCase();
	    $('.list-group-item').hide();
	    $('.list-group-item .media').each(function(){
	        if($(this).text().toLowerCase().indexOf(""+text+"") != -1 ){
	            $(this).closest('.list-group-item').show();
	        }
	    });
	});

	// Applications left side user click event api
	$(document).off('click', '.pending_verification_user').on('click', '.pending_verification_user', function(e) {
		if(user.permissionData['USERS']["feature_items['detail']"]) {
			e.stopPropagation();
			$('.card_list_heading').hide();
		    var user_id = $(this).attr('data-id');
		    var user_type = $(this).attr('data-type');
		    var user_name = $('#u_name_'+user_id).html();
		    openLoadingDialog($('#user_detail_info_container'));
		    setTimeout(function(){
		    	$('#user_detail_info_container').load('components/user/user_detail.html', user_id , function(){
		    		var layout = $('#user_list_layout').val();
			        getPendingUserData(user_id);
			        $('.user_name').html(user_name);
			        $('#navbar_role_name').html(user_name+' <small class="fs-14">('+user_type+')</small>');
			        $('.back_button').attr('data-layout',layout);
			    });
			}, 500);
		}
	});

	// Delete user
	$(document).off('click', '.delete_user').on('click', '.delete_user', function(e) {
		e.stopPropagation();
	    var user_id = $(this).attr('data-id');
	    ConfirmDelete('User', user_id); 
	});

	// Applications left side user click event api
	/*$(document).off('click', '.approve_user, .reject_user').on('click', '.approve_user, .reject_user', function() {
	    var user_id = $('#selected_user_id').val();
	    var status = $(this).attr('data-status');
	    approveRejectUserApplication(user_id,status);
	});*/

	// Delete Background and Credit Report file
	$(document).off('click', '.delete_report_file').on('click', '.delete_report_file', function() {
	    var id = $(this).attr('data-id');
	    var module_name = $(this).attr('data-module');
	    var type = '';
	    if(module_name == 'BackgroundCheckReport') {
            type = 'Background Check Report';
        } else if(module_name == 'CreditReport') {
            type = 'Credit Report';
        } 
	    ConfirmDelete(type, id);
	});

	// Skip Payment Detail
	$(document).off('click', '.skip_payment').on('click', '.skip_payment', function() {
		var user_id = user.user_details.user_id;
		skipPaymentDetail(user_id);
	});

	// Save Payment Detail
	$(document).off('click', '.save_payment_detail').on('click', '.save_payment_detail', function() {
		if($paymentForm.valid()){
	    	savePaymentDetail();
	    }
	});

	// Verify Bank Detail
	$(document).off('click', '.verify_bank_detail').on('click', '.verify_bank_detail', function() {
		var payment_detail_id = $(this).attr('data-id');
		$('#microDepositModal #micro_deposit_form_container').load('components/bank/verify_micro_deposit_form.html', payment_detail_id, function(){
			$('#payment_detail_id').val(payment_detail_id);
		});
	});

	// Verify Micro Deposit
	$(document).off('click', '.verify_micro_deposit').on('click', '.verify_micro_deposit', function() {
		var payment_detail_id = $('#payment_detail_id').val();
		var micro_deposit_1 = $('#micro_deposit_1').val();
		var micro_deposit_2 = $('#micro_deposit_2').val();
		if($microDepositAddForm.valid()){
			verify_micro_deposit(payment_detail_id,micro_deposit_1,micro_deposit_2);
		}
	});

	// Delete Bank Detail
	$(document).off('click', '.delete_bank_detail').on('click', '.delete_bank_detail', function() {
	    var id = $(this).attr('data-id');
	    var message = $(this).attr('data-message');
	    if(message.trim() != '') {
	    	var response = {
                "response_data": {
                	"success": false,
                  	"message": message
                }
            };
            display_error_message(response);
            return false;
	    } else {
	    	ConfirmDelete('Bank', id);
	    }
	});

	/*$(document).off('click', '.restore_bank_detail').on('click', '.restore_bank_detail', function() {
	    var id = $(this).attr('data-id');
	    ConfirmRestore('Bank', id);
	});*/

	$(document).off('click', '.initiate_micro_deposit').on('click', '.initiate_micro_deposit', function() {
	    var id = $(this).attr('data-id');
	    initiateMicroDeposit(id);
	});

	// Lease Extension button
	$(document).off('click', '.lease_extension_btn').on('click', '.lease_extension_btn', function(){
		var key = $(this).attr('data-key');
		var type = $(this).attr('data-value');
		var tenant_lease_id = $(this).attr('data-tenant-lease-id');
		if(type == 'Yes') {
			var vacant_date = ''
			sendLeaseExtensionNotification(key, type, tenant_lease_id, vacant_date);
		} else if(type == 'No') {
			$('#vacantDateModal').modal('show');
			$('#e_key').val(key);
			$('#e_type').val(type);
			$('#e_tenant_lease_id').val(tenant_lease_id);
			addVacantDateValidation();
		}
	});

	// Add vacanting date
	$(document).off('click', '.add_vacant_date').on('click', '.add_vacant_date', function(){
		if($vacantForm.valid()){
			$(this).attr('disabled', true);
			var key = $('#e_key').val();
			var type = $('#e_type').val();
			var tenant_lease_id = $('#e_tenant_lease_id').val();
			var vacant_date = $('#vacant_date > input').val();
			sendLeaseExtensionNotification(key, type, tenant_lease_id, vacant_date);
		}
	});

	// Refund Security Deposit
	$(document).off('click', '.refund_security_deposit').on('click', '.refund_security_deposit', function(){
		$('#refundSecurityAmountModal').modal('show');
		var lease_id = $(this).attr('data-id');
		var sd_amount = $(this).attr('data-sd-amount');
		var tenants_table_id = $(this).attr('data-tenants-table-id');
		var tenants_user_id = $(this).attr('data-tenant-user-id');
		var payment_method_id = $(this).attr('data-payment-id');
		$('#refundSecurityAmountModal #refund_security_deposit_container').load('components/property/refund_security_deposit.html', function(){
			$('#selected_lease_id').val(lease_id);
			$('#tenants_table_id').val(tenants_table_id);
			$('#tenants_user_id').val(tenants_user_id);
			$('#security_refunded_amount').val(sd_amount);
			getUserPaymentMethod(tenants_user_id, payment_method_id);
		});
	});

	// Save Refund Security deposit data
	$(document).off('click', '.save_security_deposit_refund_data').on('click', '.save_security_deposit_refund_data', function(){
		if($refundSDForm.valid()){
			$(this).attr('disabled', true);
			saveSecurityDepositRefundData();
		}
	});

	// Authorize securituy deposit refund data
	$(document).off('change', '#authorize_security_deposit_refund').on('change', '#authorize_security_deposit_refund', function(){
		if($('#authorize_security_deposit_refund').is(":checked")) {
			$('.save_security_deposit_refund_data').attr('disabled', false);
		} else {
			$('.save_security_deposit_refund_data').attr('disabled', true);
		}
	});

	/* Start : Myaccount */

	// Load add New lease page
	$(document).off('click', '.edit_profile').on('click', '.edit_profile', function(){
		var field = $(this).attr('data-field');
		var open_from = 'edit_link';
		$('#editProfileModal').modal('show');
		getProfileEditForm(open_from, field, '');
	});

	// Load edit address
	$(document).off('click', '.edit_address').on('click', '.edit_address', function(){
		var user_service_id = $(this).attr('data-user-service-id');
		var user_service_address_id = $(this).attr('data-user-service-address-id');
		$('#editAddressModal').modal('show');
		getAddressEditForm(user_service_id, user_service_address_id);
	});

	// Load add New payment page
	$(document).off('click', '.add_new_payment').on('click', '.add_new_payment', function(){
		checkKycDetailStatus();
	});

	// Authorize first month payment
	$(document).off('click', '.authorize_fm_rent_btn').on('click', '.authorize_fm_rent_btn', function(){
		var user_id = user.user_details.user_id;
		var property_id = $(this).attr('data-property-id');
		var lease_id = $(this).attr('data-lease-id');
		openLoadingDialog($('#review_first_month_payment_container'));
		setTimeout(function(){
			getFMPaymentDetail(user_id, property_id, lease_id);
		}, 500);
	});

	$(document).off('click', '#fm_payment_authorize_checkbox').on('click', '#fm_payment_authorize_checkbox', function(){
		if(!$('#fm_payment_authorize_checkbox').is(":checked")) {
			$('.authorize_first_month_payment').attr('disabled', true);
		} else {
			$('.authorize_first_month_payment').attr('disabled', false);
		}
	});

	$(document).off('click', '.authorize_first_month_payment').on('click', '.authorize_first_month_payment', function(){
		$('.authorize_first_month_payment').attr('disabled', true);
		var paymentArray = [];
	    $('.first_month_payment_hidden_input').each(function(key, value) {
		    var fmp_id = $('#first_month_payment_'+key).val();
		    var payment_id = $('#fm_payment_detail_'+key).val();
		    paymentArray.push({
				'sp_id' : fmp_id,
				'payment_id' : payment_id
			});
		});

		var is_payment_authorized = $('#is_payment_authorized').val();
		var is_allowed_to_authorized = $('#is_allowed_to_authorized').val();
		if(is_payment_authorized == 0 || is_allowed_to_authorized == 1) {
			if($fmPaymentForm.valid()){
				authorizeFirstMonthPaymentData(JSON.stringify(paymentArray));
			} else {
				$('.authorize_first_month_payment').attr('disabled', false);
			}
		} else {
			authorizeFirstMonthPaymentData(JSON.stringify(paymentArray));
		}
	});

	// Load Rent collection page
	$(document).off('click', '.authorize_rent_btn').on('click', '.authorize_rent_btn', function(){
		var tenant_id = user.user_details.user_id;
		var property_id = $(this).attr('data-property-id');
		var scheduled_payment_id = $(this).attr('data-scheduled-payment-id');
		openLoadingDialog($('#rent_collection_container'));
		setTimeout(function(){
			//$('#payNowModal #rent_collection_container').load('components/myaccount/rent_collection.html');
			getSchedulePaymentDetail(tenant_id, property_id, scheduled_payment_id);
		}, 500);
	});

	$(document).off('click', '#payment_authorize_checkbox').on('click', '#payment_authorize_checkbox', function(){
		if(!$('#payment_authorize_checkbox').is(":checked")) {
			$('.save_rent_collection_data').attr('disabled', true);
		} else {
			$('.save_rent_collection_data').attr('disabled', false);
		}
	});

	$(document).off('click', '.enable_disable_autopay_btn').on('click', '.enable_disable_autopay_btn', function(){
		var user_id = user.user_details.user_id;
		var type = $(this).attr('data-type');
		var lease_id = $(this).attr('data-id');
		var property_id = $(this).attr('data-property-id');
		$('#enableAutopayModal').modal('show');
		if(type == 'enable') {
			$('#autopay_enable_disable').val(1);
			$('#autopay_title').html('Enable Autopay');
		} else if(type == 'disable') {
			$('#autopay_enable_disable').val(0);
			$('#autopay_title').html('Disable Autopay');
		}
		openLoadingDialog($('#autopay_container'));
		getLeaseDetailById('autopay', lease_id);
	});

	$(document).off('click', '#autopay_authorize_checkbox').on('click', '#autopay_authorize_checkbox', function(){
		if(!$('#autopay_authorize_checkbox').is(":checked")) {
			$('.save_autopay_data').attr('disabled', true);
		} else {
			$('.save_autopay_data').attr('disabled', false);
		}
	});

	$(document).off('click', '.save_autopay_data').on('click', '.save_autopay_data', function(){
		var autopay = $('#autopay_enable_disable').val();
		var lease_id = $('#autopay_lease_id').val();
		var property_id = $('#autopay_property_id').val();
		var day = $('#autopay_date').val();
		var payment_detail_id = $('#autopay_payment_detail_id').val();
		//alert(autopay); alert(lease_id); alert(property_id); alert(day); alert(payment_detail_id); return false;
		if(autopay == 1) {
			if($autopayForm.valid()){
				$('.save_autopay_data').attr('disabled', true);
				saveAutopayData('autopay', lease_id, property_id, payment_detail_id, day, autopay);
			}
		} else {
			$('.save_autopay_data').attr('disabled', true);
			saveAutopayData('autopay', lease_id, property_id, 0, 0, autopay);
		}
	});

	/*$(document).off('change', '.bank_dd').on('change', '.bank_dd', function(){
		if($(this).val() > 0) {
			var selected_bank = $(this).children(':selected').text();
			$('#lf_payment_method_container').removeClass('d-none');	
			$('#late_fee_payment_method').text(selected_bank);
		} else {
			$('#lf_payment_method_container').addClass('d-none');
			$('#late_fee_payment_method').text('');
		}
	});*/

	$(document).off('click', '.save_rent_collection_data').on('click', '.save_rent_collection_data', function(){
		$('.save_rent_collection_data').attr('disabled', true);
		var paymentArray = [];
	    $('.schedule_payment_hidden_input').each(function(key, value) {
	        var sp_id = $('#scheduled_payment_'+key).val();
		    var payment_id = $('#payment_detail_'+key).val();
		    var payment_due_date = $('#payment_due_date_'+key).val();
		    paymentArray.push({
				'sp_id' : sp_id,
				'payment_id' : payment_id,
				'payment_due_date':payment_due_date
			});
		});
		saveRentCollectionData(JSON.stringify(paymentArray));
	});

	/* Link property to bank */
	$(document).off('click', '.bank_property_dd, #property_bank_authorize_checkbox').on('click', '.bank_property_dd, #property_bank_authorize_checkbox', function(){
		if($('#property_bank_authorize_checkbox').is(":checked") && $('.bank_property_dd').val() > 0) {
			$('.authorize_rent_subscription_button').attr('disabled', false);
		} else {
			$('.authorize_rent_subscription_button').attr('disabled', true);
		}
	});

	$(document).off('click', '.link_bank_and_property').on('click', '.link_bank_and_property', function(e){
		e.stopPropagation();
		var module_name = $(this).attr('data-module');
		var user_id = user.user_details.user_id;
		if(module_name == 'Bank') {
			var payment_method_id = $(this).attr('data-id');
			var payment_method_name = $(this).attr('data-payment-method');
			$('#linkPropertyModal').modal('show');
			openLoadingDialog($('#bp_dropdown_container'));
			setTimeout(function(){
				getPropertyListForPayment(user_id, payment_method_id, payment_method_name);
			}, 500);
		} else if(module_name == 'Property') {
			var property_id = $(this).attr('data-id');
			var property_name = $(this).attr('data-name');
			var lease_count = $(this).attr('data-lease-count');
			var lease_message = $(this).attr('data-lease-message');
			var payment_count = $(this).attr('data-payment-id');
			var payment_message = $(this).attr('data-payment-message');

			$('#linkPropertyModal').modal('show');
			openLoadingDialog($('#bp_dropdown_container'));
			setTimeout(function(){
				getBankListForProperty(user_id, property_id, property_name);
			}, 500);
		}
	});

	/*$(document).off('change', '.bank_property_dd').on('change', '.bank_property_dd', function(e){
		e.stopPropagation();
		var user_id = user.user_details.user_id;

		var module_name = $('option:selected', this).attr('data-module');
		var id = $(this).val();
		if(module_name == 'Bank') {
			var property_id = $(this).val();
			var payment_method_id = $('#selected_payment_method_id').val();
			var payment_method_name = $('#selected_payment_method_name').val();
		} else if(module_name == 'Property') {
			var payment_method_id = $(this).val();
			var property_id = $('#selected_property_id').val();
			var payment_method_name = $('option:selected', this).attr('data-payment-method');
		}

		openLoadingDialog($('#rent_deposit_subscription_container'));

		setTimeout(function(){
			if(id > 0) {
				getRentDepositAndSubscription(user_id, property_id, payment_method_id, payment_method_name);
			} else {
				$('.authorize_rent_subscription_button').attr('disabled', true);
				$('#rent_deposit_subscription_container').empty();
				return false;
			}
		}, 500);
	});*/

	$(document).off('click', '.authorize_rent_subscription_button').on('click', '.authorize_rent_subscription_button', function(){
		if($('.bank_property_dd').val() > 0) {
			var is_authorized = 0;
			if($('#property_bank_authorize_checkbox').is(":checked")) {
				is_authorized = 1;
			} else {
				var response = {
	                "response_data": {
	                	"success" : false,
	                  	"message": 'Please select agree checkbox.'
	                }
	            };
	            display_error_message(response);
				return false;
			}

			var module_name = $(this).attr('data-module');
			if(module_name == 'Bank') {
				var property_id = $('.bank_property_dd').val();
				var payment_method_id = $('#selected_payment_method_id').val();
			} else if(module_name == 'Property') {
				var payment_method_id = $('.bank_property_dd').val();
				var property_id = $('#selected_property_id').val();
			}

			authorizeRentDepositData(module_name, property_id, payment_method_id, is_authorized);
		} else {
			var response = {
                "response_data": {
                	"success" : false,
                  	"message": 'Select payment method for rent deposit.'
                }
            };
            display_error_message(response);
			return false;
		}
	});

	/* End : Myaccount */

	$(document).off('click', '.get_assumed_user_data').on('click', '.get_assumed_user_data', function() {
		var id = $(this).attr('data-id');
	    var name = $(this).attr('data-name');
	    var email = $(this).attr('data-email');
	    getAssumedUserData('add', email);
	});

	$(document).off('click', '.remove_assumed_identity').on('click', '.remove_assumed_identity', function() {
		var email = localStorage.getItem("primary");
		getAssumedUserData('remove', email);
	});


	/* Properties APIS */

	// Get properties chat window
	$(document).off('click', '.get_property_chat').on('click', '.get_property_chat', function() {
		var user_id = user.user_details.user_id;
	    var property_id = $(this).attr('data-id');
	    var property_name = $(this).attr('data-name');
	    $('#main_container').load('components/chat.html', function() {
	    	$('#chatroom_property_id').val(property_id);
	    	$('.property_name_span').html(' - '+property_name);
			loadChatroomData(user_id,property_id);
		});
	});

	// Load add New property page
	$(document).off('click', '.add_new_chatroom').on('click', '.add_new_chatroom', function(){
		var property_user_datatable = initDatatable('property-user-datatable');
		var chatroom_property_id = $('#chatroom_property_id').val();
		getPropertyUserList(chatroom_property_id);
	});

	// Save selected chatroom data for user
	$(document).off('click', '.save_chatroom_data').on('click', '.save_chatroom_data', function() {

		if($("input:checkbox[name=property_user_name]:checked").length == 0) {
			var response = {
                "response_data": {
                	"success" : false,
                  	"message": 'Please select atleast one user.'
                }
            };
            display_error_message(response);
			return false;
		}

	    var property_id = $('#chatroom_property_id').val();
	    var user_id = user.user_details.user_id;
	    var chatUserArray = [];
	    //add logged in user to chatrrom members 
	    chatUserArray.push({
			'user_id' : user_id
		});

		// add selected user to chatroom members
		$("input:checkbox[name=property_user_name]:checked").each(function() { 
        	var chat_user_id = $(this).attr('data-user-id');
        	var user_name = $(this).attr('data-username');
			chatUserArray.push({
				'user_id' : chat_user_id
			}); 
        }); 

		// Create chatroom name
        var name = $("input:checkbox[name=property_user_name]:checked").map(function(i, opt) {
		  return $(this).attr('data-user-id');;
		}).toArray().join(',');
		name += ','+user_id;

		saveChatroomData(user_id,property_id,name,JSON.stringify(chatUserArray));
	});

	// Applications left side user click event api
	$(document).off('click', '.chatroom_list').on('click', '.chatroom_list', function() {
		var chatroom_id = $(this).attr('data-id');
		$(".chatroom_list").removeClass('active');
    	$(this).addClass('active');
    	getChatroomMessage(chatroom_id);
	});

	// Applications left side user click event api

	$(document).off('keypress', '#chatroom_message').on('keypress', '#chatroom_message', function(event) {
		var key = event.which;
		if(key == 13)  // the enter key code
		{
		    $('#save_chatroom_message').trigger('click');
		    return false;  
		}
	}); 

	$(document).off('click', '#save_chatroom_message').on('click', '#save_chatroom_message', function() {
		var user_id = user.user_details.user_id;
		var chatroom_id = $('#chatroom_id').val();
		var chatroom_message = $('#chatroom_message').val();
    	saveChatroomMessage(user_id,chatroom_id,chatroom_message);
	});

	// Change property layout

	$(document).off('click', '.change_layout').on('click', '.change_layout', function(){
		var module_name = $(this).attr('data-module');
	    var layout = $(this).attr('id');
	    changeLayout(layout,module_name);
	});

	// Get user properties list
	$(document).off('click', '.back_button').on('click', '.back_button', function() {
		$('.card_list_heading').show();
		var module_name = $(this).attr('data-module');
		if(module_name == 'users') {
			var layout = $(this).attr('data-layout');
			$('#navbar_role_name').html('Users');
			openLoadingDialog($('#user_detail_info_container'));

			if(localStorage.getItem('filter_data') != null) {
				var open_for = 'search';
				setTimeout(function(){
				    $('#main_container').load('components/users.html', open_for, function(){
				    	$('#open_for').val(open_for);
				    	getUsersFilterData(localStorage.getItem('filter_data'))
				    });
				}, 500);
			} else {
				if(layout == 'table_layout') {
					setTimeout(function(){
					    $('#user_detail_info_container').load('components/user/user_table.html');
					}, 500);
				} else {
					setTimeout(function(){
					    $('#main_container').load('components/users.html', function(){
					    	getPendingUserList('card_layout');
					    });
					}, 500);
				}
			}
		} else if(module_name == 'property') {
			openLoadingDialog($('#main_container'));
			var layout = $(this).attr('data-layout');
			$('#navbar_role_name').html('Properties');
			if(localStorage.getItem('filter_data') != null) {
				var open_for = 'search';
		        $('#main_container').load('components/properties.html', open_for, function() {
		        	$('#open_for').val(open_for);
		        	getPropertiesFilterData(localStorage.getItem('filter_data'));
	            });
			} else {
				openLoadingDialog($('#main_container'));
				if(layout == 'table') {
					setTimeout(function(){
					    $('#main_container').load('components/property/property_table.html');
					}, 500);
				} else {
					setTimeout(function(){
					    $('#main_container').load('components/properties.html');
					}, 500);
				}
				window.history.pushState("", "", '?action=properties');
			}
		}
	});

	// Get user properties list
	$(document).off('click', '.user_properties_list').on('click', '.user_properties_list', function() {
		var property_id = $(this).attr('data-id');
		var payment_status = $(this).attr('data-status');
		if(user_service_name == sa_role || payment_status == 4) {
			var rand_num1 = random_number(11);
			var rand_num2 = random_number(20);
			var pid = rand_num1+property_id+rand_num2;
			window.history.pushState("", "", '?action=properties&pid='+pid);
			loadPropertyDetailLayout(property_id, 'vertical', '');
		}
	});

	// Load add New lease page
	$(document).off('click', '.edit_property_detail').on('click', '.edit_property_detail', function(){
		$('#PropertyDetailModal').modal('show');
		var module_name = $(this).attr('data-module');
		var property_id = $(this).attr('data-id');
		$('#PropertyDetailModal #property_detail_container_update').load('components/property/property_detail_edit_form.html', function(){
			getPropertyDetailEditForm();
		});
	});

	// When click on property table row display property detail
	$(document).off('click', '.property_table_row').on('click', '.property_table_row', function(){
		var property_id = $(this).attr('data-id');
		var payment_status = $(this).attr('data-status');
	    if(user_service_name == sa_role || payment_status == 4) {
	    	var rand_num1 = random_number(11);
			var rand_num2 = random_number(20);
			var pid = rand_num1+property_id+rand_num2;
			window.history.pushState("", "", '?action=properties&pid='+pid);
			loadPropertyDetailLayout(property_id, 'table', '');
		}
	});

	$(document).off('click', '.late_fee_checkbox').on('click', '.late_fee_checkbox', function(){
		var property_id = $(this).attr('data-id');
		if($(this).is(":checked")) {
			var late_fee_flag = 1;
		} else {
			var late_fee_flag = 0;
		}
		updateLateFeeValue(property_id,late_fee_flag);
	});

	$(document).off('mouseover', '.dz-image-preview').on('mouseover', '.dz-image-preview', function(){
		$('.dz-error-mark').css('opacity',1);
	}).off('mouseout', '.dz-image-preview').on('mouseout', '.dz-image-preview', function(){
		$('.dz-error-mark').css('opacity',0);
	});

	// Load Property setup page
	$(document).off('click', '.property_setup_fee').on('click', '.property_setup_fee', function(){
		var property_id = $(this).attr('data-id');
		var user_id = $(this).attr('data-user-id');
		var payment_details_id = $(this).attr('data-payment-id');
		var property_name = $(this).attr('data-property-name');
		var property_setup_fee_authorized = $(this).attr('data-property-setup-fee_authorized');
		if(property_setup_fee_authorized == 1 && payment_details_id == 0) {
			$('.modal-backdrop').remove();
			$('#linkPropertyModal').modal('show');
			openLoadingDialog($('#bp_dropdown_container'));
			setTimeout(function(){
				getBankListForProperty(user_id, property_id, property_name);
			}, 500);
		} else {	
			checkIfPropertySetupPlanAvailable(property_id);
		}
	});

	// Load Property Pending Review
	$(document).off('click', '.property_pending_review .card').on('click', '.property_pending_review .card', function(e){
		e.preventDefault();
		if(user_service_name != sa_role) {
			var property_id = $(this).parent('.property_pending_review').attr('data-id');
			var response = {
	            "response_data": {
	                "success": false,
	                "message": 'Property is under review by admin'
	            }
	        };
	        display_error_message(response);
	    }
	});

	/*$(document).off('keyup', '#coupon_code').on('keyup', '#coupon_code', function(){
		var coupon_code = $(this).val();
		if($.trim(coupon_code) == '') {
			$('.apply_coupon_code').attr('disabled',true);
		} else {
			$('.apply_coupon_code').attr('disabled',false);
		}
	});*/

	// Coupon code
	$(document).off('click', '.apply_coupon_code').on('click', '.apply_coupon_code', function(){
		var coupon_code = $('#coupon_code').val();
		if($.trim(coupon_code) == '') {
			var response = {
                "response_data": {
                	"success": false,
                  	"message": 'Coupon code required'
                }
            };
            display_error_message(response);
		} else {
			var trigger_from = "apply_button_clicked";
			applyCouponCode('property_setup_fee', coupon_code, trigger_from);
		}
	});

	// Remove Coupon code
	$(document).off('click', '.remove_coupon').on('click', '.remove_coupon', function(){
		$('#coupon_code').attr('readonly',false);
		$('#coupon_code').val('');
		$('#applied_coupon').html('');
		$('#apply_coupon_code').text('Apply');
		$('#apply_coupon_code').addClass('apply_coupon_code');
		$('#apply_coupon_code').removeClass('remove_coupon');
		$("#property_coupon_id").val(0);
		//$("#property_plan_id").val(0);

		//var property_id = $('#newly_added_property_id').val();
		//checkIfPropertySetupPlanAvailable(property_id);

		/*var property_id = $('#edit_property_id').val();
		var subscription_plan_id = $('#subscription_plan_id').val();
		if(property_id > 0) {
			removeCouponCode(subscription_plan_id);
		}*/

		$('#subscription_plan_id').val(0);
	});

	// Property complete transaction
	$(document).off('click', '#property_payment_agree').on('click', '#property_payment_agree', function(){
		if($('#property_payment_agree').is(":checked")) {
			$('.complete_property_transaction').attr('disabled', false);
		} else {
			$('.complete_property_transaction').attr('disabled', true);
		}
	});

	// Property complete transaction
	$(document).off('click', '.complete_property_transaction').on('click', '.complete_property_transaction', function(){
		if($propertySetupCheckoutForm.valid()){
			completePropertyTransaction();
		}
	});

	// Process Property setup fees
	$(document).off('click', '.process_property_setup_fee').on('click', '.process_property_setup_fee', function(e){
		e.stopPropagation();
		$('.process_property_setup_fee').attr('disabled', true);
		var property_id = $(this).attr('data-id');
		processPropertySetupFees(property_id);
	});

	// Open waive late fee modal
	$(document).off('click', '.open_waive_late_fee_modal').on('click', '.open_waive_late_fee_modal', function(e){
		e.stopPropagation();
		var property_id = $(this).attr('data-id');
		var property_name = $('#p_name_'+property_id).attr('title');
		
		$('#waiveLateFeeModal').modal('show');
		openLoadingDialog($('#waive_late_fee_container'));
		setTimeout(function(){
			getPropertyLateFeeDetail(property_id, property_name);
		}, 500);
	});

	$(document).off('click', '.waive_late_fee').on('click', '.waive_late_fee', function(){
		$('.waive_late_fee').attr('disabled', true);
		var spArray = [];
	    $('.waived_late_fee_input').each(function(key, value) {
		    var sp_id = $(this).attr('data-id');
		    var outstanding_amount = $('#late_fee_amount_'+sp_id).val();
		    var amount = $(this).val();
	        spArray.push({
				'sp_id' : sp_id,
				'amount' : amount,
				'outstanding_amount' : outstanding_amount,
			});
		});
		saveWaivedLateFeeData(JSON.stringify(spArray));
	});

	// Open request to pay form
	/*$(document).off('click', '.open_request_to_pay_form').on('click', '.open_request_to_pay_form', function(e){
		e.stopPropagation();
		var property_id = $(this).attr('data-id');
		var property_name = $('#p_name_'+property_id).attr('title');
		
		$('#requestToPayModal').modal('show');
		openLoadingDialog($('#lease_dropdown_container'));
		setTimeout(function(){
			getLeaseListForProperty(property_id, property_name);
		}, 500);
	});*/

	// Change lease dropdown and get tenants
	$(document.body).off('change', '.lease_property_dd').on('change', '.lease_property_dd', function(e){
		e.stopPropagation();
		var lease_id = $(this).val();
		openLoadingDialog($('#tenant_dropdown_container'));
		setTimeout(function(){
			if(lease_id > 0) {
				getTenantListForLease(lease_id);
			} else {
				$('.save_first_month_rent_collection_data').attr('disabled', true);
				$('#tenant_dropdown_container').empty();
				return false;
			}
		}, 500);
	});

	// Change Tenant dropdown and get lease detial
	$(document.body).off('change', '.tenant_property_dd').on('change', '.tenant_property_dd', function(e){
		e.stopPropagation();
		var property_id = $("#selected_property_id").val();
		var lease_id = $("#selected_lease_id").val();
		var tenant_id = $(this).val();
		
		if(tenant_id != "") {
			$('.save_first_month_rent_collection_data').attr('disabled', false);
			if($('#first_month_rent_collection_container').is(':empty')) {
				openLoadingDialog($('#first_month_rent_collection_container'));
				getFirstMonthPaymentDetail(property_id,lease_id);
			}
		} else {
			$('.save_first_month_rent_collection_data').attr('disabled', true);
			return false;
		}
	});

	$(document).off('change', '.request_to_pay_amount').on('change', '.request_to_pay_amount', function(){
		var currentInput = $(this).val();
	    var fixedInput = currentInput.replace(/[A-Za-z!@#$%^&*()]/g, '');
	    $(this).val(fixedInput);

	    var key = $(this).attr('data-key');
	    var changed_amount = $(this).val();
	    changed_amount = changed_amount.replace(',','');
		var main_amount = $('#main_amount_'+key).val();
	    var type = getPaymentType(key);
	    if(Number(changed_amount) > Number(main_amount)) {
	    	var res = {
                "response_data": {
                    "success": false,
                    "message": type+' amount should be less than or equal to $'+main_amount,
                }
            };
            display_error_message(res);
		} else {
			var sum = 0;
		    $('.request_to_pay_amount').each(function() {
		    	var amount = $(this).val();
		    	var amt = amount.replace(',','');
		        sum += Number(amt);
		        // FOrmat number again after sum
		        $(this).val(currencyFormat(Number(amt)));
		    });
		    $('.request_to_pay_total').val(sum);
		    $('#request_to_pay_total').html('$ '+currencyFormat(sum));
		}
	});

	// Save first month payment data
	$(document).off('click', '.save_first_month_rent_collection_data').on('click', '.save_first_month_rent_collection_data', function(){

		// Get current date
		var fullDate = new Date() 
		var twoDigitMonth = ((fullDate.getMonth().length+1) === 1)? (fullDate.getMonth()+1) : '0' + (fullDate.getMonth()+1);
		var currentDate = twoDigitMonth + "/" + fullDate.getDate() + "/" + fullDate.getFullYear();
		
		// Check due date cannot be less than current date
		var d_cnt = 0;
		var a_cnt = 0;
		var t_cnt = 0;
		var total_due_date_record = $("input[class*='fm_due_date_']").length;
		var total_amount_record = $(".request_to_pay_amount").length;
		$("input[class*='fm_due_date_']").each(function(key, value) {
			var type = getDateType(key);
			var due_date = $(this).val();
		    if(new Date(due_date) >= new Date(currentDate)) {
				d_cnt++;
			} else {
				var res = {
	                "response_data": {
	                    "success": false,
	                    "message": type+' due date cannot be less than current date',
	                }
	            };
	            display_error_message(res);
	            return false;
			}
		});

		// Check amount cannot be gretar than main amount
		$(".request_to_pay_amount").each(function(key, value) {
		    var changed_amount = $(this).val();
		    changed_amount = changed_amount.replace(',','');
		    var key = $(this).attr('data-key');
		    var main_amount = $('#main_amount_'+key).val();
		    var type = getPaymentType(key);
		    if(Number(changed_amount) <= Number(main_amount)) {
				a_cnt++;
			} else {
				var res = {
	                "response_data": {
	                    "success": false,
	                    "message": type+' amount should be less than or equal to $'+main_amount,
	                }
	            };
	            display_error_message(res);
	            return false;
			}

			if(Number(changed_amount) <= dwolla_transaction_amount) {
				t_cnt++;
			} else {
				var res = {
	                "response_data": {
	                    "success": false,
	                    "message": type+' Single transaction amount should be less than $'+dwolla_transaction_amount,
	                }
	            };
	            display_error_message(res);
	            return false;
			}
		});

		if(d_cnt == total_due_date_record && a_cnt == total_amount_record && t_cnt == total_amount_record) {
			var property_id = $('#selected_property_id').val();
			var lease_id = $('#selected_lease_id').val();
			var tenant_id = $('.tenant_property_dd').val();
			var user_id = $('.tenant_property_dd').find(':selected').map(function() { 
			    return $(this).attr("data-user-id"); 
			}).get().join(',');

			if(tenant_id == '') {
				var res = {
	                "response_data": {
	                    "success": false,
	                    "message": 'Please select tenant',
	                }
	            };
	            display_error_message(res);
	            return false;
	        }

	        $('.save_first_month_rent_collection_data').attr('disabled', true);
			saveFirstMonthPaymentDetail(property_id,lease_id,tenant_id,user_id);
		}
	});

	// Load add New property page
	$(document).off('click', '.add_new_property').on('click', '.add_new_property', function(){
		$('#propertyAddModal').modal('show');
		$('#propertyAddModal #property_detail_container').load('components/property/add_new_property.html', function(){
			getStateDropdown();
			//var user_id = user.user_details.user_id
			//getLinkedAccountArray(user_id);
		});
	});

	//Load expense detailed report
	/*$(document).off('click','.get_expense_detailed_report').on('click','.get_expense_detailed_report', function(){
		openLoadingDialog($('#main_container'));
		setTimeout(function(){
		    getExpenseDetailedReport();
		}, 500);
	})*/

	//Load add New Expense page
	$(document).off('click','.add_new_expense').on('click','.add_new_expense', function(){
		$('#addExpenseModal').modal('show');
		$('#addExpenseModal #add_expense_container').load('components/expenses/new_expense.html',function(){
			getStateDropdownForSignup();
		})
	})

	$(document).off('click','.add_new_vendor').on('click','.add_new_vendor', function(){
		$('#masterDataModal').modal('show');
		$('#masterDataModal #master_data_modal_container').load('components/master-data/new_vendor.html', function(){
			getStateDropdownForSignup();
		});
	})

	$(document).off('click','.edit_vendor').on('click','.edit_vendor', function(){
		var vendor_id = $(this).attr('data-id');
		$('#masterDataModal').modal('show');
		$('#masterDataModal #master_data_modal_container').load('components/master-data/edit_vendor.html', vendor_id, function(){
			getVendorDetails(vendor_id);
			// getStateDropdownForSignup();
		});
	})

	
	$(document).off('click','.delete_vendor').on('click','.delete_vendor', function(){
		var vendor_id = $(this).attr('data-id');
		ConfirmDelete('Pay To User', vendor_id);
	})

	$(document).off('click','.add_new_category').on('click','.add_new_category', function(){
		$('#masterDataModal').modal('show');
		$('#masterDataModal #master_data_modal_container').load('components/master-data/new_category.html', function(){
		});
	})

	$(document).off('click','.add_new_sub_category').on('click','.add_new_sub_category', function(){
		var category_id = $(this).attr('data-id');
		$('#masterDataModal').modal('show');
		$('#masterDataModal #master_data_modal_container').load('components/master-data/new_sub_category.html',category_id, function(){
			$('#categoryId').val(category_id);
		});
	})

	$(document).off("click", ".view_category").on('click', '.view_category', function () {	
		$('#masterDataModal').modal('show');
		$('#masterDataModal #master_data_modal_container').load('components/master-data/edit_category.html', function(){});
		var category_id = $(this).attr('data-id');
		getCategoryById(category_id);
	});

	$(document).off("click", ".view_sub_category").on('click', '.view_sub_category', function () {	
		$('#masterDataModal').modal('show');
		$('#masterDataModal #master_data_modal_container').load('components/master-data/edit_sub_category.html', function(){});
		var sub_category_id = $(this).attr('data-id');
		getSubCategoryById(sub_category_id);
	});

	$(document).off("click", ".delete_category").on('click', '.delete_category', function (e) {	
		e.preventDefault();
		var category_id = $(this).attr('data-id');
		ConfirmDelete('Category', category_id);
		return false;
	});

	$(document).off("click", ".delete_sub_category").on('click', '.delete_sub_category', function (e) {	
		e.preventDefault();
		var sub_category_id = $(this).attr('data-id');
		ConfirmDelete('Sub Category', sub_category_id);
		return false;
	});

	$(document).off('click','.submit_vendor').on('click','.submit_vendor', function(){
		if($validateVendor.valid()){
			submitVendor();
		}
	});

	$(document).off('click','.update_vendor').on('click','.update_vendor', function(){
		if($validateVendor.valid()){
			updateVendor();
		}
	});

	$(document).off('click','.submit_category').on('click','.submit_category', function(){		
		if($validateCategory.valid()){			
			submitCategory();
		}
	});

	$(document).off('click','.submit_sub_category').on('click','.submit_sub_category', function(){		
		if($validateSubCategory.valid()){			
			submitSubCategory();
		}
	});

	$(document).off('click','.edit_category').on('click','.edit_category', function(){		
		if($validateCategory.valid()){			
			editCategory();
		}
	});

	$(document).off('click','.edit_sub_category').on('click','.edit_sub_category', function(){		
		if($validateSubCategory.valid()){			
			editSubCategory();
		}
	});

	$(document).off('change').on('change', '.fileToUpload', function(e)
    {    
		const file = e.target.files[0];
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.addEventListener('load',  () => {
			const imageContent = reader.result.replace('data:', '').replace(/^.+,/, '');
			//console.log(imageContent);return false;
			var user_id = user.user_details.user_id;
			
			var obj = {};
			obj.user_id = user_id;
			obj.file_content = imageContent;
			$('#addExpenseItemModal').modal('show');
			openLoadingDialog($('#add_expense_item_container'));
			$.ajax({
				url: expense_api_url+"scanexpense",
				//dataType: "json",
				data: JSON.stringify(obj),
				type: 'POST',
				beforeSend: function(xhr) {
					xhr.setRequestHeader('x-api-key', expense_x_api_key);
					xhr.setRequestHeader('customer-id', expense_customer_id);
					xhr.setRequestHeader('Accept', 'application/json');
					xhr.setRequestHeader('Content-Type', 'application/json');
				},
				success: function(response) {
					if(Object.keys(response.data.summaryFields).length != 0 && Object.keys(response.data.lineItems).length != 0) {
						// console.log(response);return false;
						// openLoadingDialog($('#add_expense_item_container'));
						$('#addExpenseItemModal #add_expense_item_container').load('components/expenses/expense_item.html',function(){
							var page = "add_expense";
							getExpenseCategories(page);
							var category_id = $('#inputCategory').val();
							if(category_id != ''){
								getExpenseSubCategories(page,category_id);
								$('#inputSubCategory').attr('disabled',false);
							}
							getExpenseTaxes(page);
							datepicker('inputDate');
							var x = createScannedExpense(response);							
							$('#inputMerchant').val(x['merchant_name']);
							$('#inputAmount').val(x['total']);
							$('#inputInvoice').val(x['invoice_number']);
							$('#inputDate').val(x['date']);
							var source = document.getElementById('inputFile');
							var input = document.getElementById('fileToUpload');
							source.files = input.files;
							var output = document.getElementById('scannedFileList');
							var children = "";
							for (var i = 0; i < input.files.length; ++i) {
								children += '<li>' + input.files.item(i).name + '</li>';
							}
							output.innerHTML = '<ul>'+children+'</ul>';
						})
						// saveScannedExpense(response);
					} else {
						alert("please upload valid reciept");
					}
				}
			});
		});
    });

	$(document).off('click','.delete_expense').on('click','.delete_expense', function(e){
		e.preventDefault();
		var expense_id = $(this).attr('data-id');
		ConfirmDelete('Expense Report', expense_id);
		return false;
	});

	$(document).off('click','.add_expense_item').on('click','.add_expense_item', function(){
		$('#addExpenseItemModal').modal('show');
		$('#addExpenseItemModal #add_expense_item_container').load('components/expenses/expense_item.html',function(){
			var page = "add_expense";
		    getExpenseCategories(page);
			var category_id = $('#inputCategory').val();
			if(category_id != ''){
				getExpenseSubCategories(page,category_id);
				// $('#inputSubCategory').attr('disabled',false);
			}
		    // getExpenseTaxes(page);
			datepicker('inputDate');
		})
	})

	$(document).off('click','.edit_line_item').on('click','.edit_line_item', function(){
		var line_item_id = $(this).attr('data-id');
		$('#addExpenseItemModal').modal('show');
		$('#addExpenseItemModal #add_expense_item_container').load('components/expenses/expense_item.html', line_item_id, function(){
			$('#line_item_id').val(line_item_id);
			var expense_id = $('#selected_expense_id').val();
			getLineItemDetail(expense_id, line_item_id);
		})
	})

	$(document).off('click','.delete_line_item').on('click','.delete_line_item', function(e){
		e.preventDefault();
		var line_item_id = $(this).attr('data-id');
		ConfirmDelete('Expense', line_item_id);
		return false;
	});

	$(document).off('change', '.image-upload').on('change', '.image-upload', function(){
		var input = document.getElementById('inputFile');
	    var output = document.getElementById('inputFileList');
	    var children = "";
	    for (var i = 0; i < input.files.length; ++i) {
	        children += '<li>' + input.files.item(i).name + '</li>';
	    }
		output.innerHTML = '<ul>'+children+'</ul>';
	});

	$(document).off('change', '#inputCategory').on('change', '#inputCategory', function(){
		var page = '';
		var category_id = $('#inputCategory').val();
		if(category_id != ''){
			getExpenseSubCategories(page,category_id);
			// $('#inputSubCategory').attr('disabled',false);
		} else {
			$('#inputSubCategory').attr('disabled',true);
			var html = '';
            html += "<option value=''>Select Sub Category</option>";
			$('#inputSubCategory').html(html);
			$('#inputSubCategory').val('');
		}
	})
	
	$(document).off('click','.show_expense_report_row').on('click','.show_expense_report_row', function(){
		var data_number = $(this).attr('data-number');
		$('.sub_cat'+data_number).each(function(){
			if(!$(this).hasClass('d-none')){
				$(this).addClass('d-none');
				$('.category'+data_number).each(function(){
					var val = $(this).attr('data-val');
					if(val == 0){
						$(this).html('-');
					} else {
						$(this).html(currency_sign+' '+currencyFormat(Number(val)));
					}
				});
			} else {
				$(this).removeClass('d-none');
				$('.category'+data_number).each(function(){
					$(this).html('-');
				});
				var show_all_check = document.getElementById('show_all_categories');
				if(show_all_check.checked){
					var data_cnt = $(this).attr('data-cnt');
					if(data_cnt != "total"){
						var flag = 0;
						$('.sub_calc'+data_cnt).each(function(){
							flag = flag + Number($(this).attr('data-val'));
						});
						if(flag == 0){
							if(!$(this).hasClass('d-none')){
								$(this).addClass('d-none');
							}
						}
					}
				}
			}
		});
	});

	$(document).off('click','.open_expense_item').on('click','.open_expense_item', function(){
		var title = $(this).attr('data-title');
		var id = $(this).attr('data-id');
		$('#showExpenseModal').modal('show');
		$('#showExpenseModal #show_expense_item_container').load('components/reports/show_expenses.html', function(){	
			$('.show_expenses_title').html(title);
			openLoadingDialog($('#show_expenses_media'));
			if(id != ''){
				showExpenseItems(id);
			} else {
				var html = '<h3 class="text-center mt-3"> No Data Available </h3>';
				$('#show_expenses_media').html(html);
			}
		});

	});

	$(document).off('change', '#expand_collapse').on('change','#expand_collapse', function() {
        if(this.checked) {
            $(this).next('label').text("Collapse");
			var show_all_check = document.getElementById('show_all_categories');
			if(show_all_check.checked){
				$('.show_expense_report_row').each(function(){
					var data_number = $(this).attr('data-number');
					var sum = 0;
					$('.category'+data_number).each(function(){
						sum = sum + $(this).attr('data-val');
					});
					
					if(sum == 0){
						if(!$(this).hasClass('d-none')){
							$(this).addClass('d-none');
							if(!$('.sub_cat'+data_number).hasClass('d-none')){
								$('.sub_cat'+data_number).addClass('d-none');
							}
						}
					} else {
						$(this).removeClass('d-none');
						$('.sub_cat'+data_number).removeClass('d-none');
						$('.category'+data_number).html('-');
						$('.calc_sub').each(function(){
							var data_cnt = $(this).attr('data-cnt');
							var flag = 0;
							$('.sub_calc'+data_cnt).each(function(){
								flag = flag + Number($(this).attr('data-val'));
							});
							if(flag == 0){
								if(!$(this).hasClass('d-none')){
									$(this).addClass('d-none');
								}
							} else {
								$(this).removeClass('d-none');
							}
						})
					}
				});
			} else {
				$('.exp_col').removeClass('d-none');
				$('.val_total_on_collaps').each(function(){
					$(this).html('-');
				});
			}
        } else {
            $(this).next('label').text("Expand");

			var show_all_check = document.getElementById('show_all_categories');
			if(show_all_check.checked){
				$('.show_expense_report_row').each(function(){
					var data_number = $(this).attr('data-number');
					var sum = 0;
					$('.category'+data_number).each(function(){
						sum = sum + $(this).attr('data-val');
					});
					
					if(sum == 0){
						if(!$(this).hasClass('d-none')){
							$(this).addClass('d-none');
							if(!$('.sub_cat'+data_number).hasClass('d-none')){
								$('.sub_cat'+data_number).addClass('d-none');
							}
						}
					} else {
						$(this).removeClass('d-none');
						$('.sub_cat'+data_number).addClass('d-none');
						$('.category'+data_number).each(function(){
							var val = $(this).attr('data-val');
							if(val == 0){
								$(this).html('-');
							} else {
								$(this).html(currency_sign+' '+currencyFormat(Number(val)));
							}
						});
					}
				});
			} else {
				$('.exp_col').addClass('d-none');
				$('.val_total_on_collaps').each(function(){
					var val = $(this).attr('data-val');
					if(val == 0){
						$(this).html('-');
					} else {
						$(this).html(currency_sign+' '+currencyFormat(Number(val)));
					}
				});
			}
        }
    });

	$(document).off('change', '#show_all_categories').on('change','#show_all_categories', function() {
        if(this.checked) {
			$(this).next('label').text("Show all");
			$('.income_col').addClass('d-none');
			$('.show_expense_report_row').each(function(){
				var data_number = $(this).attr('data-number');
				var sum = 0;
				$('.category'+data_number).each(function(){
					sum = sum + $(this).attr('data-val');
				});
				
				if(sum == 0){
					if(!$(this).hasClass('d-none')){
						$(this).addClass('d-none');
						if(!$('.sub_cat'+data_number).hasClass('d-none')){
							$('.sub_cat'+data_number).addClass('d-none');
						}
					}
				} else {
					$('.calc_sub').each(function(){
						var data_cnt = $(this).attr('data-cnt');
						var flag = 0;
						$('.sub_calc'+data_cnt).each(function(){
							flag = flag + Number($(this).attr('data-val'));
						});
						if(flag == 0){
							if(!$(this).hasClass('d-none')){
								$(this).addClass('d-none');
							}
						}
					})
				}
			});
        } else {
            $(this).next('label').text("Show all");
			$('.income_col').removeClass('d-none');
			var expand_collaps_check = document.getElementById('expand_collapse');
			$('.show_expense_report_row').each(function(){
				var data_number = $(this).attr('data-number');
				$(this).removeClass('d-none');
				if(expand_collaps_check.checked){
					$('.sub_cat'+data_number).removeClass('d-none');
				}
			});
        }
    });

	$(document).off('click','.view_expense_document').on('click','.view_expense_document', function(){
		var document_id = $(this).attr('data-id');
		var display_name = $(this).attr('data-value');
		$('#viewExpenseDocumentModel').modal('show');
		$('#viewExpenseDocumentModel #view_expense_document_container').load('components/expenses/view_expense_document.html', function(){
			$('.expense_document_title').html(display_name);
			openLoadingDialog($('#expense_document_media'));
			viewExpenseDocument(document_id,display_name);
		});
	}) 

	//load add new Expense Line item
	$(document).off('click','.add_line_item').on('click','.add_line_item', function(){
		getLineItems();
	}) 

	//remove Expense Line item
	$(document).off('click','.remove_line_item').on('click','.remove_line_item', function(r){
    
        if($('#line_item_table tbody tr').length > 1){
			if (confirm("Are you sure, you want to delete this line item ?"))
        	{
				$(this).closest('tr').animate( {backgroundColor:'rgb(230,239,194)'}, 500).fadeOut(500,function()
            	{
					var amount = $(this).closest('tr').find("input.inputAmount").val();
					var f_amount = amount.replace(/,/g, '');

					var updatedAmt = ($("#totalAmountInput").val())-f_amount;
					$("#expenseTotalAmount").html(currency_sign+' '+currencyFormat((updatedAmt)));
					$("#totalAmountInput").val(updatedAmt.toFixed(2));
					var tableID = $(this).closest('table').attr('id'); 
					$(this).closest('tr').remove();
					arrangeslno(tableID);

					// Add deleted line item id in hidden input for update expense api data
					var deletedRowId = $(this).closest('tr').attr('data-id');
					if(deletedRowId != null) {
						var delimeter = '';
						if($('#deletedLineItemId').val() != "") { delimeter = ','; }
						var deletedExpenseID = $('#deletedLineItemId').val($('#deletedLineItemId').val()+delimeter+deletedRowId);
					}
				});
			}
        }

    })

	$(document).off('click', '.remove_exp_documents').on('click','.remove_exp_documents', function(){
		var dataDocId = $(this).attr('data-id');
		if(dataDocId != null){
			var delimeter = '';
			if($('#deletedDocuments').val() != "") { delimeter = ','; }
			var deletedDocumentId = $('#deletedDocuments').val($('#deletedDocuments').val()+delimeter+dataDocId);
			$('#doc_'+dataDocId).remove();
		}
	})

	$(document).off('click','.submit_expense').on('click','.submit_expense', function(r){
		if($validateExpense.valid()){
			saveExpenses();
		}
	})

	$(document).off('click','.send_expense').on('click','.send_expense', function(r){
		if($('#missingItems').html() != ''){
			MissingFields($('#missingItems').html());
		} else {
			var expense_id = $('#selected_expense_id').val();
			var type = 'expense_submit';
			updateExpense(expense_id, type);
		}
	})

	$(document).off('click','.cancel_expense').on('click','.cancel_expense', function(r){
		$('#main_container').load('components/expenses.html',function(){
			//alert('here');
		})
	})

	$(document).off('change', '.inputAmount').on('change', '.inputAmount', function(){
    //     // console.log($("#inputAmount").val());
    //     calculateTotal();
		var val = $(this).val();
		var total = parseFloat(val.replace(/,/g, ''));

		var amount = $(this).val();
		var amt = amount.replace(/,/g, '');
		$(this).val(currencyFormat(Number(amt)));
    });

	$(document).off('change', '.tax_calculate').on('change', '.tax_calculate', function(){
		if($('#expenseAmount').val() != undefined || $('#expenseAmount').val() != null){			
			var amount = $('#expenseAmount').val().replace(/,/gi, '');
		} else {
			var amount = 0;
		}

		if($('#taxPercentage').val() != undefined || $('#taxPercentage').val() != null){			
			var tax = $('#taxPercentage').val().replace(/,/gi, '');
		} else {
			var tax = 0;
		}

		var taxAmount = amount * ( tax / 100 );
		taxAmount = Math.round((taxAmount + Number.EPSILON) * 100) / 100;
		var taxTotal = amount * (1 + (tax/100));
		taxTotal = Math.round((taxTotal + Number.EPSILON) * 100) / 100;
		$('#expenseAmount').val(currencyFormat(Number(amount)));
		$('#taxAmount').val(currencyFormat(Number(taxAmount)));
		$('#inputAmount').val(currencyFormat(Number(taxTotal)));
    });

	
	$(document).off('change', '.expense_tax_dd').on('change', '.expense_tax_dd', function(){
		var val = $('#inputTaxes option:selected').attr('data-percentage');
		$('#taxPercentage').val(val);
        if($('#expenseAmount').val() != undefined || $('#expenseAmount').val() != null){
			var amount = $('#expenseAmount').val().replace(/[`~!@$%^|+\=?;'"<>\{\}\[\]\\\/]/gi, '');
		} else {
			var amount = 0;
		}
		var taxAmount = amount * ( val / 100 );
		taxAmount = Math.round((taxAmount + Number.EPSILON) * 100) / 100;
		var taxTotal = amount * (1 + (val/100));
		taxTotal = Math.round((taxTotal + Number.EPSILON) * 100) / 100;
		$('#taxAmount').val(currencyFormat(Number(taxAmount)));
		$('#inputAmount').val(currencyFormat(Number(taxTotal)));
    });

    $(document).off('click','.save_expense_item').on('click','.save_expense_item', function(r){
		if($validateExpenseItem.valid()){
			//saveExpenses();
			alert('dev');
		}
	})

	$(document).off('keypress', '.inputContact').on('keypress', '.inputContact', function(){
        // console.log($("#inputContact").val());
		$('.inputContact').mask('(000) 000-0000');
    });

	$(document).off('keypress', '.vendorPhone').on('keypress', '.vendorPhone', function(){
        // console.log($("#vendorPhone").val());
		$('.vendorPhone').mask('(000) 000-0000');
    });

	$(document).off('click','.update_expense').on('click','.update_expense', function(r){
		var type = $(this).attr('data-type');
		if(type == 'expense_header') {
			if($validateExpense.valid()){
				var expense_id = $('#selected_expense_id').val();
				updateExpense(expense_id, type);
			}
		} else {
			if($validateExpenseItem.valid()){
				var expense_id = $('#selected_expense_id').val();
				updateExpense(expense_id, type);
			}
		}
	})

	$(document).off('click','.view_expense').on('click','.view_expense', function(){
		var expense_id = $(this).attr('data-id');
		$('#main_container').load('components/expenses/view_expense.html', expense_id, function(){
			$('#selected_expense_id').val(expense_id);
			setTimeout(function(){
				viewExpense(expense_id);
				getStateDropdownForSignup();
			}, 500);
		})
	})

	$(document).off('click','.edit_expense').on('click','.edit_expense', function(){
		var expense_id = $('#selected_expense_id').val();
		$('#expense_header_detail').find('select').attr('disabled', false);
		$('.edit_expense').addClass('d-none');
		$('.update_expense, .cancel_update').removeClass('d-none');
		$('#expense_header_detail').find('input').attr('disabled', false);
	})

	$(document).off('click','.cancel_update').on('click','.cancel_update', function(){
		var expense_id = $('#selected_expense_id').val();
		$('#expense_header_detail').find('select').attr('disabled', true);
		$('.update_expense, .cancel_update').addClass('d-none');
		$('.edit_expense').removeClass('d-none');
		$('#expense_header_detail').find('input').attr('disabled', true);
	})
	
	// Property document upload dropzone
	$(document).off('click', '.get_property_image_dropzone').on('click', '.get_property_image_dropzone', function(){
		$('#propertyImageDropzoneModal').modal('show');
	});

	// Property document upload dropzone
	$(document).off('click', '.get_property_document_dropzone').on('click', '.get_property_document_dropzone', function(){
		$('#propertyDocumentDropzoneModal').modal('show');
	});

	// Assign lease agreement document between peoperty owner and rent synergy admin
	$(document).off('click', '.add_lease_aggrement_signature_document').on('click', '.add_lease_aggrement_signature_document', function(){
		$('#templateListModal').modal('show');
		if(zoho_signature_enabled == "true") {
			getZohoTemplateList();
		} else {
			var open_for = 'signature';
			getContractAndAgreements(open_for);
		}
	});

	$(document).off('change', '.property_user_type').on('change', '.property_user_type', function(){
		$('#zohoSignerListContainer').remove();
		$("input:radio[name='document_name']").prop('checked', false);
		$("div[class*='account_type_']").addClass('d-none');
		var type = $("input[name='property_user_type']:checked").attr('data-type');
		$('.account_type_'+type).removeClass('d-none');
		$('.document_title').removeClass('d-none');
		$('.save_template_data').attr('disabled', true);
	});

	$(document).off('change', '.contract_template_radio').on('change', '.contract_template_radio', function(){
		$('#zohoSignerListContainer').remove();
		var zoho_template_id = $("input:radio[name='document_name']:checked").attr('data-id');
	    var user_type = $("input:radio[name='property_user_type']:checked").val();
	    var property_id = $("#selected_property_id").val();
	    $('.save_template_data').attr('disabled', false);
	});

	$(document).off('click', '.edit_property_contract').on('click', '.edit_property_contract', function(){
		var contract_id = $(this).attr('data-contract-id');
		var user_contract_id = $(this).attr('data-user-contract-id');
		var html_form = $(this).attr('data-form');
		var table_name = $(this).attr('data-table');
		$('#editUserContract').modal('show');
		openLoadingDialog($('#editUserContract .user_contract_edit_form'));
		setTimeout(function(){
		    $('#editUserContract .user_contract_edit_form').load('components/contract/forms/'+html_form, function() {
		    	$('#selected_contract_id').val(contract_id);
		    	$('#selected_user_contract_id').val(user_contract_id);
		    	$('#selected_contract_table').val(table_name);
		    	var property_id = $('#selected_property_id').val();
		    	getFormData(contract_id, property_id);
		    });
		}, 500);
	});

	$(document).off('click', '.contract_send_for_review').on('click', '.contract_send_for_review', function(){
		$(this).addClass('add_opacity');
		var contract_id = $(this).attr('data-contract-id');
		var user_contract_id = $(this).attr('data-user-contract-id');
		sendContractForReview(user_contract_id);
	});	

	$(document).off('click', '.review_contract_document').on('click', '.review_contract_document', function(){
		var ticket_id = $(this).attr('data-ticket-id');
		var property_id = $(this).attr('data-property-id');
		var user_contract_id = $(this).attr('data-user-contract-id');
		var user_contract_recipient_id = $(this).attr('data-user-contract-recipient-id');

		$('#viewDocumentModal').modal('show');
		openLoadingDialog($('#document_preview_container'));
		$('#viewDocumentModal #document_preview_container').load('components/contract/view_draft_document.html', function(){
			$('#selected_user_contract_recipient_id').val(user_contract_recipient_id);
			$('#selected_user_contract_id').val(user_contract_id);
			$('#selected_property_id').val(property_id);
			$('#selected_ticket_id').val(ticket_id);
			getContractForReview(property_id,user_contract_id);

			// Load Ticket Detail
			openLoadingDialog($('#ticket_detail_container'));
			setTimeout(function(){
				$('#ticket_detail_container').load('components/ticket/ticket_detail.html', ticket_id , function(){
					loadTicketDetail(ticket_id,'table');
			        $('.close_ticket_comment').addClass('d-none');
			        $('.reopen_ticket_comment').addClass('d-none');
			        $('#ticket_detail_container .card-header').remove();
			    });
			}, 1000);
		});
	});

	$(document).off('click', '.accept_contract').on('click', '.accept_contract', function(){
		var user_contract_recipient_id = $('#selected_user_contract_recipient_id').val();
		var user_contract_id = $('#selected_user_contract_id').val();
		var property_id = $('#selected_property_id').val();
		acceptContract(user_contract_recipient_id,user_contract_id,property_id);
	});

	$(document).off('click', '.download_draft_contract_document').on('click', '.download_draft_contract_document', function(){
		var user_contract_id = $('#selected_user_contract_id').val();
		downloadDraftContractDocument(user_contract_id);
	});

	$(document).off('click', '.contract_send_for_signature').on('click', '.contract_send_for_signature', function(){
		$(this).addClass('add_opacity');
		var contract_id = $(this).attr('data-contract-id');
		var user_contract_id = $(this).attr('data-user-contract-id');
		sendContractForSignature(user_contract_id);
	});	

	$(document).off('click', '.sign_contract_document').on('click', '.sign_contract_document', function(){
		var ticket_id = $(this).attr('data-ticket-id');
		var property_id = $(this).attr('data-property-id');
		var user_contract_id = $(this).attr('data-user-contract-id');
		var user_contract_recipient_id = $(this).attr('data-user-contract-recipient-id');

		$('#viewDocumentModal').modal('show');
		openLoadingDialog($('#document_preview_container'));
		$('#viewDocumentModal #document_preview_container').load('components/contract/view_final_document.html', function(){
			$('#selected_user_contract_recipient_id').val(user_contract_recipient_id);
			$('#selected_user_contract_id').val(user_contract_id);
			$('#selected_property_id').val(property_id);
			getContractForSignature(property_id,user_contract_id,user_contract_recipient_id);

			// Load Ticket Detail
			openLoadingDialog($('#ticket_detail_container'));
			setTimeout(function(){
				$('#ticket_detail_container').load('components/contract/signature_form.html', function(){
					
			    });
			}, 1000);
		});
	});

	$(document).off('click', '.delete_property_contract').on('click', '.delete_property_contract', function(){
		var user_contract_id = $(this).attr('data-id');
		ConfirmDelete('PropertyContract', user_contract_id);
	});	

	$(document).off('change', '.zoho_template_radio').on('change', '.zoho_template_radio', function(){
		$('#zohoSignerListContainer').remove();
		var zoho_template_id = $("input:radio[name='document_name']:checked").attr('data-id');
	    var user_type = $("input:radio[name='property_user_type']:checked").val();
	    var property_id = $("#selected_property_id").val();
	    var signature_required = $(this).attr('data-signature-required');
	    getZohoTemplateSignerList(zoho_template_id,user_type,property_id,signature_required);
	});

	// Save selected template data for user
	$(document).off('click', '.save_template_data').on('click', '.save_template_data', function() {
		if(zoho_signature_enabled == "true") {
			$(this).attr('disabled', true);
			var rs_invite_tenant = $('#rs_invite_tenant').val();
			if(rs_invite_tenant == 1) {
				$(this).html('<div class="loader"></div><div class="ml-2 float-right">Sending</div>');
			}
			
			var zoho_template_id = $("input[name='document_name']:checked").attr('data-id');
		    var user_type = $("input[name='property_user_type']:checked").val();
		    var property_id = $("#selected_property_id").val();
		    
		    var userArray = [];
		    $("select[name^='property_tenant_dd']").each(function(key, value) {
			    var user_id = $(this).val();
			    userArray.push({
					'user_id' : user_id
				});
			});

			assignedZohoTemplate(zoho_template_id,user_type,property_id,JSON.stringify(userArray));
		} else {
			$(this).attr('disabled', true);
			var user_type = $("input[name='property_user_type']:checked").val();
		    var property_id = $("#selected_property_id").val();
		    var contract_id = $("input:radio[name='document_name']:checked").attr('data-id');
		    saveUserContract(user_type, property_id, contract_id);
		}
	});

	// Send for Signatures
	$(document).off('click', '.send_for_signatures').on('click', '.send_for_signatures', function() {
		$(this).addClass('add_opacity');
		var zoho_template_id = $(this).attr('data-zoho-template-id');
	    var property_id = $("#selected_property_id").val();
	    sendForSignature(zoho_template_id,property_id);
	});

	// Delete user template
	$(document).off('click', '.delete_property_template').on('click', '.delete_property_template', function() {
	    var user_template_id = $(this).attr('data-id');
	    ConfirmDelete('Property Document', user_template_id);
	});

	// Invite Tenant go to tenant lease document section
	$(document).off('click', '.go_to_tenant_lease_section').on('click', '.go_to_tenant_lease_section', function(e){
		getSignupNextStep(1,2);
		var type = 'T';
		getInviteTenantZohoTemplateList(type);
	});

	// Invite Tenant go to first month payment section
	$(document).off('click', '.go_to_fm_payment_section').on('click', '.go_to_fm_payment_section', function(e){
		getSignupNextStep(2,3);
		openLoadingDialog($('#invite_tenant_data_container'));
		setTimeout(function(){
			var property_id = $('#selected_property_id').val();
			var property_name = $('#navbar_role_name').text();
			getLeaseListForProperty(property_id, property_name);
		}, 500);
	});

	// Invite Tenant go to invite tenant last step section
	$(document).off('click', '.go_to_invite_tenant_section').on('click', '.go_to_invite_tenant_section', function(e){
		getSignupNextStep(3,4);
		openLoadingDialog($('#invite_tenant_data_container'));
		$('#invite_tenant_data_container').html('<div id="brd-top" class="mt-5 pt-5"><div class="row justify-content-center"><div class="col-auto mt-2 mb-2 mr-2"><button type="button" class="btn btn-orange rs_admin_invite_tenant">Invite Tenant</button></div></div></div>');
	});
		
	// Load edit property page
	$(document).off('click', '.edit_property_address').on('click', '.edit_property_address', function(e){
		e.stopPropagation();
		var property_id = $(this).attr('data-id');
		$('#propertyEditModal #edit_property_address_container').load('components/property/edit_property_address.html', function() {
			getPropertyAddressDetail(property_id,'vertical');
			getStateDropdown();
		});
	});

	// Update property address
	$(document).off('click', '.update_property_address').on('click', '.update_property_address', function() {
		var type = "property_edit";
		var address1 = $('#edit_property_address_line1').val();
   		var address2 = $('#edit_property_address_line2').val();
   		var city = $('#edit_property_city').val();
   		var state = $('#edit_property_state').val();
   		var zipcode = $('#edit_property_zip_code').val();
   		if($editPropertyForm.valid()){
	   		verify_usps_address(type,address1,address2,city,state,zipcode)
			//updatePropertyAddress();
		}
	});

	// Update property detail
	$(document).off('click', '.update_property_data').on('click', '.update_property_data', function() {
		var property_id = $('#selected_property_id').val();
		var user_id = user.user_details.user_id;
	    var property_type_id = $('#property_types').val();
	    var no_of_units = $('#property_no_of_units').val();
	    var year_built = $('#property_year_built').val();
	    var square_feet = $('#property_square_feet').val();
	    var bedrooms = $('#property_bedrooms').val();
	    var bathrooms = $('#property_bathrooms').val();
	    var parking_spaces = $('#property_parking_spaces').val();
	    var swimming_pool = $('#property_swimming_pool').val();
	    var type_of_cooling = $('#property_type_of_colling').val();
	    var type_of_heating = $('#property_type_of_heating').val();
	    var type_of_water_heater = $('#property_type_of_water_heater').val();
	    var ac_filter_size = $('#property_ac_filter_size').val();
	    var ac_model = $('#property_ac_make_and_model').val();
	    var water_heater_model = $('#property_water_heater_make_and_model').val();
	    var additional_information = $('#property_additional_information').val();

	    var hoa = $('#hoa').val();
	    var flooring = $('#flooring').val();
	    var fireplace = $('#fireplace').val();
	    var water_heater = $('#water_heater').val();
	    var fans = $('#fans').val();
	    var pergola = $('#pergola').val();
	    var exterior = $('#exterior').val();
	    var foundation = $('#foundation').val();
	    var roof = $('#roof').val();
	    var fence = $('#fence').val();
	    var parcel_number = $('#parcel_number').val();
	    var lot_size = $('#lot_size').val();	    

	    var data = 'user_id='+user_id+'&property_type_id='+property_type_id+'&no_of_units='+no_of_units+'&built_year='+year_built+'&square_feet='+square_feet+'&no_of_bed='+bedrooms+'&no_of_bath='+bathrooms+'&no_of_parking='+parking_spaces+'&swimming_pool='+swimming_pool+'&type_of_cooling='+type_of_cooling+'&type_of_heating='+type_of_heating+'&type_of_water_heater='+type_of_water_heater+'&ac_filter_size='+ac_filter_size+'&ac_model='+ac_model+'&water_heater_model='+water_heater_model+'&additional_information='+additional_information+'&hoa='+hoa+'&flooring='+flooring+'&fireplace='+fireplace+'&water_heater='+water_heater+'&fans='+fans+'&pergola='+pergola+'&exterior='+exterior+'&foundation='+foundation+'&roof='+roof+'&fence='+fence+'&parcel_number='+parcel_number+'&lot_size='+lot_size;

	    $.ajax({
	        method: 'POST',
	        url: api_url+"properties/edit/"+property_id,
	        data: data,
	        beforeSend: function(xhr) {
		        xhr.setRequestHeader('Authorization', accessToken);
		    },
	        success: function(response) {
	            display_error_message(response);
	            if(response.response_data.success == true) {
	                getPropertyDetail(property_id,'vertical');

	                getPropertyDetailWidgetInfo();
	                $('#PropertyDetailModal').modal('hide');
	            }
	        }
	    });
	});

	// Delete property data
	$(document).off('click', '.delete_property_data').on('click', '.delete_property_data', function(e) {
		e.stopPropagation();
	    var property_id = $(this).attr('data-id');
	    ConfirmDelete('Property', property_id); 
	});

	// Property image hover
	$(document).off('mouseenter', '.property_image').on('mouseenter', '.property_image', function() {
		$(this).find('.property_cover_image').removeClass('d-none');
		$(this).find('.delete_property_image').removeClass('d-none');
	}).off('mouseleave', '.property_image').on('mouseleave', '.property_image', function() {
		$(this).find('.property_cover_image').addClass('d-none');
		$(this).find('.delete_property_image').addClass('d-none');
	});

	$(document).off('click', '.property_image').on('click', '.property_image', function(e) {
		e.stopPropagation();
		var id = $(this).attr('data-id');
		var property_id = $(this).attr('data-property-id');
		$('#imagePreviewModal').modal('show');
		$('.modal-dialog').css('max-width', '100%');
		$('.modal-dialog').css('top', '45%');
		$('.modal-backdrop.show').css('opacity', '0.97');
		openLoadingDialog($('#image_preview_container'));	
		loadPropertyImagePreview(id, property_id);
	});

	// Delete property image
	$(document).off('click', '.delete_property_image').on('click', '.delete_property_image', function(e) {
		e.stopPropagation();
	    var property_id = $('#selected_property_id').val();
	    var attachment_id = $(this).attr('data-id');
	    ConfirmDelete('Property Image', attachment_id);
	});

	// Delete property image
	$(document).off('click', '.undelete_property_image').on('click', '.undelete_property_image', function() {
	    var property_id = $('#selected_property_id').val();
	    var attachment_id = $(this).attr('data-id');
	    unDeletePropertyImage(attachment_id);
	});

	// Set property cover image
	$(document).off('click', '.property_cover_image').on('click', '.property_cover_image', function(e){
		e.stopPropagation();
		var attachment_id = $(this).attr('data-id');
		updatePropertyCoverImage(attachment_id);
	});

	// View document preview
	$(document).off('click', '.view_document_preview').on('click', '.view_document_preview', function(){
		var type = $(this).attr('data-type');
		var file_url = $(this).attr('data-url');
		var document_name = $(this).attr('data-name');
		var request_id = $(this).attr('data-request-id');

		$('#viewDocumentModal').modal('show');
		$('#viewDocumentModal #document_preview_container').load('components/property/view_document.html', function(){
			$('#file_type').val(type);
			$('#document_request_id').val(request_id);
			$('#document_url').val(file_url);
			$('#document_name').val(document_name);
		});
	});

	// Download Zoho Document
	$(document).off('click', '.download_zoho_document').on('click', '.download_zoho_document', function(){
		$('.download_zoho_document').addClass('add_opacity');
		var type = $(this).attr('data-type');
		var filename = $(this).attr('data-filename');
		var request_id = $(this).attr('data-request-id');
		downloadZohoDocument(filename, type, request_id);
	});

	// Save Property
	$(document).off('click', '.save_property_data').on('click', '.save_property_data', function() {
		var type = "property_add";
		var address1 = $('#add_property_address_line1').val();
   		var address2 = $('#add_property_address_line2').val();
   		var city = $('#add_property_city').val();
   		var state = $('#add_property_state').val();
   		var zipcode = $('#add_property_zip_code').val();
   		if($propertyForm.valid()){
	   		verify_usps_address(type,address1,address2,city,state,zipcode);
	   		//savePropertyData();
	   	}
	});

	$(document).off('click', '.same_as_owner_address').on('click', '.same_as_owner_address', function() {
		var property_id = $('#selected_property_id').val();
		var same_as_owner_address = 0;
	    if($(this).is(":checked")) {
	    	$('#primary_contact_firstname,#primary_contact_lastname,#primary_contact_businessname,#primary_contact_address_line1,#primary_contact_address_line2,#primary_contact_city,#primary_contact_state,#primary_contact_zip_code,#primary_contact_country').attr('disabled', true);
	    	$('.save_property_primary_contact_detail').attr('disabled', true);
	    	same_as_owner_address = 1;
	    } else {
	    	$('#primary_contact_firstname,#primary_contact_lastname,#primary_contact_businessname,#primary_contact_address_line1,#primary_contact_address_line2,#primary_contact_city,#primary_contact_state,#primary_contact_zip_code,#primary_contact_country').attr('disabled', false);
	    	$('.save_property_primary_contact_detail').attr('disabled', false);
	    	same_as_owner_address = 0;
	    }

	    $.ajax({
	        method: 'POST',
	        url: api_url+"properties/primary-contact-address",
	        data: 'property_id='+property_id+'&same_as_owner_address='+same_as_owner_address,
	        beforeSend: function(xhr) {
		        xhr.setRequestHeader('Authorization', accessToken);
		    },
	        success: function(response) {
	            if(response.response_data.success == false) {
	            	display_error_message(response);
	            }
	        }
	    });
	});

	$(document).off('click', '.save_property_primary_contact_detail').on('click', '.save_property_primary_contact_detail', function() {
		var type = "property_primary_contact";
		var address1 = $('#primary_contact_address_line1').val();
        var address2 = $('#primary_contact_address_line2').val();
        var city = $('#primary_contact_city').val();
        var state = $('#primary_contact_state').val();
        var zipcode = $('#primary_contact_zip_code').val();
        if($propertyContactForm.valid()){
        	verify_usps_address(type,address1,address2,city,state,zipcode);
        	//var property_id = $('#selected_property_id').val();
			//savePropertyPrimaryContactDetail(property_id);
		}
	});
	
	$(document).off('click', '.module_tab_container .nav-link').on('click', '.module_tab_container .nav-link', function() {
	    var href = $(this).attr('href');
	    if(href == "#p-lease") {
	    	openLoadingDialog($('#lease_data_container'));
	    	$('#lease_data_container').load('components/property/lease_detail_table.html');
	    } else if(href == "#p-documents") {
	    	openLoadingDialog($('#document_data_container'));
	    	$('#document_data_container').load('components/property/document_detail.html');
	    } else if (href == "#p-accounting") {
	    	openLoadingDialog($('#accounting_data_container'));
	    	$('#accounting_data_container').load('components/property/accounting_detail_table.html');
	    } else if (href == "#p-tenants") {
	    	openLoadingDialog($('#tenant_data_container'));
	    	$('#tenant_data_container').load('components/property/tenant_detail_table.html');
	    } else if (href == "#p-service-requests") {
	    	$('#ticket_open_from').val('property_tab');
	    	openLoadingDialog($('#service_request_data_container'));
	    	$('#service_request_data_container').load('components/support.html');
	    } else if (href == "#a-overview") {
	    	openLoadingDialog($('#property_block_container'));
	    	if(user_service_name == po_role) {
	    		$('#property_block_container').load('components/myaccount/po_overview.html');
	    	} else if(user_service_name == t_role) {
	    		$('#property_block_container').load('components/myaccount/t_overview.html');
	    	} else {
	    		$('#property_block_container').html('No data available');
	    	}
	    } else if (href == "#a-payment" || href == "#u-payment") {
	    	openLoadingDialog($('#payment_data_container'));
	    	$('#payment_data_container').load('components/myaccount/payment_detail_table.html');
	    } else if (href == "#a-invoice") {
	    	openLoadingDialog($('#invoice_data_container'));
	    	$('#invoice_data_container').load('components/myaccount/invoice_detail_table.html');
	    } else if (href == "#a-audit") {
	    	openLoadingDialog($('#audit_data_container'));
	    	var user_id = user.user_details.user_id;
	    	getUserActivities(user_id);
	    	//$('#audit_data_container').load('components/myaccount/payment_detail_table.html');
	    }  else if (href == "#a-linked-account") {
	    	openLoadingDialog($('#linked_account_data_container'));
	    	getLinkedAccount();
	    } else if (href == "#u-overview" || href == "#u-details" || href == "#u-billing") {
	    	var user_id = $('#selected_user_id').val();
	    	getPendingUserData(user_id);
	    } else if(href == "#u-background") {
	    	getUserBackCreditReportFile('BackgroundCheckReport');
	    } else if(href == "#u-credit") {
	    	getUserBackCreditReportFile('CreditReport');
	    } else if(href == "#p-coupons") {
	    	loadCouponData();
	    } else if(href == "#p-plans") {
	    	$('#plan_pricing_data_section').addClass('d-none');
	    	loadPlanData();
	    } else if(href == "#p-subscriptions") {
	    	loadSubscriptionData();
	    } else if(href == "#p-groups") {
	    	loadGroupData();
	    } else if(href == "#p-features") {
	    	loadFeaturesData();
	    } else if(href == "#p-services") {
	    	loadServicesData();
	    } else if(href == "#p-service-permissions") {
	    	loadFeaturePermissionsData();
	    } else if(href == "#p-inbox") {
	    	getSystemEmailDatas('inbox');
	    } else if(href == "#p-sent-emails") {
	    	getSystemEmailDatas('sent-email');
	    } else if(href == "#p-email-templates") {
	    	getEmailTemplates();
	    } else if(href == "#r-summary") {
	    	getPaymentSummaryReport();
	    } else if(href == "#r-transaction") {
	    	getTransactionReport();
	    } else if(href == "#r-payment-receipt") {
	    	var date_macro = '';
	    	//getPaymentReceiptReport(date_macro);
	    	getPaymentReceiptCardReport(date_macro);
	    } else if(href == "#r-expenses") {
	    	openLoadingDialog($('#expense_report_data_container'));
	    	setTimeout(function(){
		    	getExpenseDetailedReport();
		    }, 200);
	    } else if(href == "#t-zoho-template") {
	    	openLoadingDialog($('#zoho_data_container'));
	    	setTimeout(function(){
			    getZohoTemplates();
			}, 2000);
	    } else if(href == "#t-contracts") {
	    	var open_for = 'main_list';
	    	getContractAndAgreements(open_for);
	    } else if(href == "#t-master-agreements") {
	    	openLoadingDialog($('#master_agreement_data_container'));
	    	setTimeout(function(){
		    	var open_for = 'main_list';
		    	getMasterAgreements(open_for);
		    }, 1000);
	    } else if(href == "#t-agreements") {
	    	openLoadingDialog($('#agreement_data_container'));
	    	setTimeout(function(){
		    	var open_for = 'main_list';
		    	getAgreements(open_for);
		    }, 1000);
	    } else if(href == "#r-vendor") {
	    	loadVendorData();
	    } else if(href == "#r-category") {
	    	loadCategoryData();
	    } 
	});

	/* Start : Invoice */

	$(document).off('click', '.view_invoice').on('click', '.view_invoice', function(){
		var invoice_id = $(this).attr('data-invoice-id');
		$('#invoiceModal').modal('show');
		openLoadingDialog($('#invoice_content_container'));
		setTimeout(function(){
		    getInvoiceData(invoice_id);
		}, 500);
	});

	$(document).off('click', '.download_invoice').on('click', '.download_invoice', function(){
		var invoice_id = $(this).attr('data-invoice-id');
		var open_from = $(this).attr('data-from');

		DownloadPdf('Downloading Invoice Copy ...');
		
		if(open_from == 'invoice_list') {
			getInvoiceData(invoice_id);			
		} else if(open_from == 'invoice_preview') {
			$('#invoiceModal').modal('hide');
		}

		setTimeout(function(){
			$('.hide_download_invoice').remove();
			var element = document.getElementById("invoice_content_container");
			html2pdf(element, {
			  	margin:       10,
			  	filename:     'invoice.pdf',
			  	image:        { type: 'jpeg', quality: 1 },
			  	html2canvas:  { scale: 2, logging: false, dpi: 192, letterRendering: true },
			  	jsPDF:        { unit: 'mm', format: 'a4', orientation: 'portrait' }
			});  
			$('.dialog-ovelay').remove();  
		}, 1000);
	});

	$(document).off('click', '.email_invoice').on('click', '.email_invoice', function(){
		var invoice_id = $(this).attr('data-invoice-id');
		DownloadPdf('Sending Invoice Copy ...');
		getInvoiceData(invoice_id);
		setTimeout(function(){
			$('.hide_download_invoice').remove();
			var element = document.getElementById("invoice_content_container");
			
			var opt = {
				margin:       10,
			  	filename:     'invoice.pdf',
			  	image:        { type: 'jpeg', quality: 1 },
			  	html2canvas:  { scale: 2, logging: false, dpi: 192, letterRendering: true },
			  	jsPDF:        { unit: 'mm', format: 'a4', orientation: 'portrait' }
			};

			html2pdf().set(opt).from(element).toPdf().output('datauristring').then(function (pdfAsString) {
			    // The PDF has been converted to a Data URI string and passed to this function.
			    // Use pdfAsString however you like (send as email, etc)! For instance:
			    //console.log(pdfAsString);
			    //return false;
			    sendInvoiceEmail(pdfAsString);
			});
			$('.dialog-ovelay').remove();  
		}, 1000);
	});

	/* End : Invoice */

	$(document).off('change', '#pet_policy').on('change', '#pet_policy', function(){
		var val = $(this).val();
		if(val == 2) {
			$('#pet_deposit_amount').attr('readonly', false); 
			$('#pd_due_date > input').attr('disabled', false); 
		} else {
			$('#pet_deposit_amount').val('');
			$('#pd_due_date > input').val('');
			$('#pet_deposit_amount').attr('readonly', true);
			$('#pd_due_date > input').attr('disabled', true); 
		}
	});

	// Load add New lease page
	$(document).off('click', '.add_new_lease').on('click', '.add_new_lease', function(){
		$('#leaseAddModal').modal('show');
		var module_name = $(this).attr('data-module');
		var lease_tenant_id = $(this).attr('data-id');
		$('#leaseAddModal #lease_detail_container_add').load('components/property/add_new_lease.html', function(){
			$('#module_name').val(module_name);
			if(typeof lease_tenant_id === 'undefined') {
				$('#lease_tenant_id').val(0);
			} else {
				$('#lease_tenant_id').val(lease_tenant_id);
			}
		});
	});

	// Format amount
	$(document).off('change', '.format_amount').on('change', '.format_amount', function(e){
		var amount = $(this).val();
		var amt = amount.replace(',','');
		$(this).val(currencyFormat(Number(amt)));
	});

	// Load edit lease page
	$(document).off('click', '.edit_lease_detail').on('click', '.edit_lease_detail', function(e){
		e.stopPropagation();
		var lease_id = $(this).attr('data-id');
		$('#leaseEditModal #lease_detail_container_edit').load('components/property/edit_lease.html', function() {
			getLeaseDetailById('edit', lease_id);
		});
	});

	// Save Lease
	$(document).off('click', '.save_lease_data').on('click', '.save_lease_data', function() {
	    saveLeaseData();
	});

	// Update Lease
	$(document).off('click', '.update_lease_data').on('click', '.update_lease_data', function() {
	    updateLeaseData();
	});

	// Load existing tenant
	$(document).off('click', '.list_existing_tenant').on('click', '.list_existing_tenant', function() {
		var tenant_ids = $(this).attr('data-tenant-id');
		var lease_id = $(this).attr('data-id');
		var module_name = $(this).attr('data-module');
		$('#tenantListModal #tenant_list_container').load('components/property/existing_tenant_detail.html', function(){
			$('#selected_lease_id').val(lease_id);
			getExistingTenantDetail(tenant_ids);
		});
	});

	// delete lease
	$(document).off('click', '.delete_lease_detail').on('click', '.delete_lease_detail', function(e){
		e.stopPropagation();
		var lease_id = $(this).attr('data-id');
		ConfirmDelete('Lease', lease_id); 
	});

	// Assign existing lease to tenent
	$(document).off('click', '.assign_tenant_to_lease').on('click', '.assign_tenant_to_lease', function() {
		$(this).attr('disabled', true);
		var property_id = $('#selected_property_id').val();
		var lease_id = $('#selected_lease_id').val();
		var tenant_id = 0;

		var tenant_lease_array = [];
	    
		// add selected user to chatroom members
		$("input:checkbox[name=existing_tenant_checkbox]:checked").each(function() { 
        	var selected_tenant_id = $(this).attr('data-id');
        	tenant_lease_array.push({
				'id' : selected_tenant_id
			}); 
        }); 

        assignTenantToLease(property_id,lease_id,tenant_id,JSON.stringify(tenant_lease_array))
	});

	// Accountint cancel transfer
	$(document).off('click', '.cancel_payment').on('click', '.cancel_payment', function() {
	    var id = $(this).attr('data-id');
	    var cancel_type = $(this).attr('data-type');
	    ConfirmCancel('Transaction', id, cancel_type);
	});

	// Regenerate cancelled transaction
	$(document).off('click', '.regenerate_transaction').on('click', '.regenerate_transaction', function() {
	    var id = $(this).attr('data-id');
	    var payment_type = $(this).attr('data-payment-type');
	    ConfirmRegenerate('Transaction', id, payment_type);
	});

	// Hide show transaction detail
	$(document).off('click', '.show_hide_transaction_detail').on('click', '.show_hide_transaction_detail', function() {
		var element = $(this);

		/*$("div[id^='tran_card_']").each(function() { 
        	if(!$(this).hasClass('d-none')) {
        		$(this).addClass('d-none');
        		$(this).html('Show More <i class="align-middle fas fa-fw fa-angle-down"></i>');
        	}
        }); */

		var id = element.attr('data-id');
		$('#tran_card_'+id).toggleClass('d-none');
		if($('#tran_card_'+id).hasClass('d-none')) {
			element.html('Show More <i class="align-middle fas fa-fw fa-angle-down"></i>');
		} else {
			element.html('Hide More <i class="align-middle fas fa-fw fa-angle-up"></i>');
		}
	});

	// Authorize Subscription Fee
	$(document).off('click', '.authorize_subscription_fee').on('click', '.authorize_subscription_fee', function() {
	    var id = $(this).attr('data-id');
	    var property_id = $(this).attr('data-property-id');
	    var payment_type = $(this).attr('data-payment-type');
	    var payment_details_id = $(this).attr('data-payment-id');
	    var rent_month = $(this).attr('data-month');
	    var open_from = $(this).attr('data-open-from');
	    //alert(id); alert(property_id); alert(payment_type); alert(payment_details_id); alert(rent_month); alert(open_from); return false;
	    $('#authorizeSubscriptionFeeModal').modal('show');
	    getSubscriptionDetail(id, property_id, payment_type, payment_details_id, rent_month, open_from);
	});

	$(document).off('click', '#subscription_fee_authorize_checkbox').on('click', '#subscription_fee_authorize_checkbox', function(){
		if(!$('#subscription_fee_authorize_checkbox').is(":checked")) {
			$('.save_subscription_fee_data').attr('disabled', true);
		} else {
			$('.save_subscription_fee_data').attr('disabled', false);
		}
	});

	$(document).off('click', '.save_subscription_fee_data').on('click', '.save_subscription_fee_data', function(){
		var open_from = $('#subscription_fee_open_from').val();
		var scheduled_payment_id = $('#subscription_scheculed_payment_id').val();
		var property_id = $('#subscription_property_id').val();
		var payment_detail_id = $('#subscription_payment_detail_id').val();
		
		$('.save_subscription_fee_data').attr('disabled', true);
		authorizeSubscriptionFee(scheduled_payment_id, property_id, payment_detail_id, open_from);
	});

	// Start : Tenants

	// Load add New tenants page
	$(document).off('click', '.add_new_tenant').on('click', '.add_new_tenant', function(){
		$('#tenantAddModal').modal('show');
		var module_name = $(this).attr('data-module');
		var tenant_lease_id = $(this).attr('data-id');
		$('#tenantAddModal #tenant_detail_container_add').load('components/property/add_new_tenant.html', function(){
			$('#module_name').val(module_name);
			if(typeof tenant_lease_id === 'undefined') {
				$('#tenant_lease_id').val(0);
			} else {
				$('#tenant_lease_id').val(tenant_lease_id);
			}
		});
	});

	// Save Tenant
	$(document).off('click', '.save_tenant_data').on('click', '.save_tenant_data', function() {
		var button_type = $(this).attr('data-button');
	    saveTenantData(button_type);
	});

	// Save and Submit Tenant
	$(document).off('click', '.save_and_submit_tenant_data').on('click', '.save_and_submit_tenant_data', function() {
	    var button_type = $(this).attr('data-button');
	    saveTenantData(button_type);
	});

	// Load edit tenant page
	$(document).off('click', '.edit_tenant_detail').on('click', '.edit_tenant_detail', function(e){
		e.stopPropagation();
		var tenant_id = $(this).attr('data-id');
		$('#tenantEditModal #tenant_detail_container_edit').load('components/property/edit_tenant.html', function() {
			getTenantDetailById(tenant_id);
		});
	});

	// delete tenant
	$(document).off('click', '.delete_tenant_detail').on('click', '.delete_tenant_detail', function(e){
		e.stopPropagation();
		var tenant_id = $(this).attr('data-id');
		ConfirmDelete('Tenant',tenant_id);
	});

	// delete tenant
	$(document).off('click', '.undelete_tenant_detail').on('click', '.undelete_tenant_detail', function(e){
		e.stopPropagation();
		var tenant_id = $(this).attr('data-id');
		ConfirmDelete('Tenant',tenant_id);
	});

	// Update tenant
	$(document).off('click', '.update_tenant_data').on('click', '.update_tenant_data', function() {
		var button_type = $(this).attr('data-button');
	    updateTenantData(button_type);
	});

	// Update and submit tenant
	$(document).off('click', '.update_and_submit_tenant_data').on('click', '.update_and_submit_tenant_data', function() {
		var button_type = $(this).attr('data-button');
	    updateTenantData(button_type);
	});

	// Submit tenant detail
	$(document).off('click', '.submit_tenant_detail').on('click', '.submit_tenant_detail', function(e) {
		e.stopPropagation();
		var property_id = $('#selected_property_id').val();
		var tenant_id = $(this).attr('data-id');
		submitTenantDetail(property_id, tenant_id);
	});

	// Invite tenant detail
	$(document).off('click', '.invite_tenant').on('click', '.invite_tenant', function(e) {
		e.stopPropagation();
		var property_id = $('#selected_property_id').val();
		var tenant_id = $(this).attr('data-id');

		checkIfTenantLinkedWithLease(property_id,tenant_id);
	});

	// Invite tenant detail
	$(document).off('click', '.rs_admin_invite_tenant').on('click', '.rs_admin_invite_tenant', function(e) {
		e.stopPropagation();
		$('.rs_admin_invite_tenant').attr('disabled', true);
		var property_id = $('#selected_property_id').val();
		var tenant_id = $('#rs_invite_tenant_id').val();
		inviteTenant(property_id, tenant_id);
	});

	// Load existing lease
	$(document).off('click', '.list_existing_lease').on('click', '.list_existing_lease', function() {
	    var tenant_id = $(this).attr('data-id');
	    var lease_id = $(this).attr('data-l-id');
	    var lease_ids = $(this).attr('data-lease-id');
		var module_name = $(this).attr('data-module');
		$('#leaseListModal #lease_list_container').load('components/property/existing_lease_detail.html', function(){
			$('#selected_tenant_id').val(tenant_id);
			getExistingLeaseDetail(lease_ids, lease_id);
		});
	});

	// Assign existing tenant to lease
	$(document).off('click', '.assign_lease_to_tenant').on('click', '.assign_lease_to_tenant', function() {
		$(this).attr('disabled',true);
		var property_id = $('#selected_property_id').val();
		var tenant_id = $('#selected_tenant_id').val();
		var lease_id = 0;

		var tenant_lease_array = [];
	    
		// add selected user to chatroom members
		$("input:checkbox[name=existing_lease_checkbox]:checked").each(function() { 
        	var selected_lease_id = $(this).attr('data-id');
        	tenant_lease_array.push({
				'id' : selected_lease_id
			}); 
        }); 

        assignLeaseToTenant(property_id,lease_id,tenant_id,JSON.stringify(tenant_lease_array))
	});

	// Load existing documents
	$(document).off('click', '.list_tenant_document').on('click', '.list_tenant_document', function() {
	    var tenant_ids = $(this).attr('data-tenant-id');
		$('#documentListModal #document_list_container').load('components/property/existing_document_detail.html', tenant_ids, function(){
			getTenantSignedDocument(tenant_ids);
		});
	});

	// Ticket
	
	// Get user properties list
	$(document).off('click', '.ticket_list').on('click', '.ticket_list', function() {
	    var ticket_id = $(this).attr('data-id');
	    var status = $(this).attr('data-status');
	    $(".ticket_list").removeClass('active');
    	$(this).addClass('active');
	    $('#open_from').val('vertical');
	    loadTicketDetail(ticket_id,'vertical',status);
	});

	// When click on ticket table row display ticket detail
	$(document).off('click', '.ticket_table_row').on('click', '.ticket_table_row', function(){
	    var ticket_id = $(this).attr('data-id');
	    $('#ticketDetailModal').modal('show');
		$('#ticket_detail_container').load('components/ticket/ticket_detail.html', ticket_id , function(){
			openLoadingDialog($('#ticket_edit_form'));
	        $('#open_from').val('table');
	        loadTicketDetail(ticket_id,'table');
	        if(!user.permissionData['SUPPORT']["feature_items['close']"]) {
	        	$('.close_ticket_comment').addClass('d-none');
	        }
	        if(!user.permissionData['SUPPORT']["feature_items['reopen']"]) {
	        	$('.reopen_ticket_comment').addClass('d-none');
	        }
	        if(!user.permissionData['SUPPORT']["feature_items['add_comment']"]) {
	        	$('.add_ticket_comment').addClass('d-none');
	        }
	    });
	});

	// Load add New Tciket as Draft
	$(document).off('click', '.add_new_ticket').on('click', '.add_new_ticket', function(){
		saveDraftTicket();
	});

	// Update ticket data as Draft
	$(document).off('change', '#tickets-property-id').on('change', '#tickets-property-id', function(){
		updateDraftTicket('tickets-property-id');

		if(user_service_name == sa_role) {
			var property_id = $(this).val();
			if(property_id > 0) {
				getPropertyRelatedUsers(property_id);
			} else {
				$('#to_whom_container').html('');
			}
		}
	});

	// Update ticket data as Draft
	$(document).off('change', '.to_whom_dd').on('change', '.to_whom_dd', function(){
		updateDraftTicket('to_whom_dd');
	});

	// Update ticket data as Draft
	$(document).off('keyup', '#tickets-title').on('keyup', '#tickets-title', function(){
		updateDraftTicket('tickets-title');
	});

	// Add New Ticket
	$(document).off('click', '.save_ticket_data').on('click', '.save_ticket_data', function() {
	    saveTicketData();
	});

	// Delete Draft Ticket
	$(document).off('click', '.delete_ticket_data').on('click', '.delete_ticket_data', function() {
		var seletedLayout = $('#selected_layout').val();
		var property_id = $("#selected_property_id").val();
		if(typeof property_id === 'undefined') {
			if(seletedLayout == 'card_layout' || seletedLayout == 'table_layout') {
				var ticket_id = $(this).attr('data-id');
			} else {
				var ticket_id = $('#ticket_id').val();	
			}
		} else {
			var ticket_id = $(this).attr('data-id');
		}
		ConfirmDelete('Ticket',ticket_id);
	});

	// Close Ticket
	$(document).off('click', '.reopen_ticket_comment').on('click', '.reopen_ticket_comment', function() {
		var ticket_id = $('#ticket_id').val();
		var status = $(this).attr('data-status');
		$('.close_ticket_comment, .reopen_ticket_comment').attr('disabled', true);
	    updateTicketStatus(ticket_id,status);
	});

	// Close Ticket
	$(document).off('click', '.close_ticket_comment').on('click', '.close_ticket_comment', function() {
		var ticket_id = $('#ticket_id').val();
		var status = $(this).attr('data-status');
		$('.close_ticket_comment, .reopen_ticket_comment').attr('disabled', true);
	    updateTicketStatus(ticket_id,status);
	});

	// Post ticket comment
	$(document).off('click', '.add_ticket_comment').on('click', '.add_ticket_comment', function() {
		var ticket_id = $('#ticket_id').val();
	    addTicketComment(ticket_id);
	});

	// Edit ticket comment
	$(document).off('click', '.edit_comment').on('click', '.edit_comment', function() {
		var comment_id = $(this).attr('data-id');
		editTicketComment(comment_id);
	});

	// Update ticket comment
	$(document).off('click', '.update_comment').on('click', '.update_comment', function() {
		var comment_id = $('#edit_comment_id').val();
		updateTicketComment(comment_id);
	});

	// Cancel ticket comment edit
	$(document).off('click', '.cancel_comment').on('click', '.cancel_comment', function() {
		var comment_id = $('#edit_comment_id').val();
		cancelTicketComment(comment_id);
	});

	// Delete ticket comment
	$(document).off('click', '.delete_comment').on('click', '.delete_comment', function() {
		var comment_id = $(this).attr('data-id');
		ConfirmDelete('Comment', comment_id);
	});

	/*$(document).off('keyup', '#property_additional_information').on('keyup', '#property_additional_information', function() {
		var textlen = maxLength - $(this).val().length;
  		$('#rchars').text(textlen);
	});*/

	// Process Pending Payment
	$(document).off('click', '.process_pending_payment').on('click', '.process_pending_payment', function(e) {
		e.stopPropagation();
		var property_id = $(this).attr('data-id');
		$(this).attr('disabled', true);
		processPendingPayment(property_id);
	});

	// Pay to Property Owner
	$(document).off('click', '.pay_to_property_owner').on('click', '.pay_to_property_owner', function(e) {
		e.stopPropagation();
		var property_id = $(this).attr('data-id');
		$(this).attr('disabled', true);
		payToPropertyOwner(property_id);
	});

	// Zoho Template Create UI
	$(document).off("click", ".create_zoho_template").on('click', '.create_zoho_template', function (e) {
		openLoadingDialog($('#main_container'));
		setTimeout(function(){
		    $('#main_container').load('components/template/create_template.html', function(){
		    	//getFloatingActionButton('Template', '#create-template-wizard');
		    	setTimeout(function () {
		            getDocumentTypes();
		        }, 300);
		    	
		    	var html = '';
				$.each(role_dd, function( key, value ) {
					html += '<option value="'+value+'">'+value+'</option>';
				});
				$('#role1').html(html);
		    });
		}, 500);
	});

	// Zoho Template Edit UI
	$(document).off("click", ".edit_zoho_template").on('click', '.edit_zoho_template', function (e) {
		var template_id = $(this).attr('data-template-id');
		openLoadingDialog($('#main_container'));
		setTimeout(function(){
		    $('#main_container').load('components/template/update_template.html', template_id, function(){
		    	var rand_num1 = random_number(11);
				var rand_num2 = random_number(20);
				var id = rand_num1+template_id+rand_num2;
		    	window.history.pushState("", "", '?action=template&id='+id);
		    	getZohoTemplateDetailById(template_id);
		    	//getFloatingActionButton('Template', '#update-template-wizard');
		    });
		}, 2000);
	});

	// Zoho Template Delete
	$(document).off("click", ".delete_zoho_template").on('click', '.delete_zoho_template', function (e) {
    	var template_id = $(this).attr('data-template-id');
    	ConfirmDelete('ZohoTemplate', template_id);
	});

	// master Agreement Create UI
	$(document).off("click", ".create_master_agreement").on('click', '.create_master_agreement', function (e) {
		openLoadingDialog($('#main_container'));
		setTimeout(function(){
		    $('#main_container').load('components/agreement/create_master_agreement.html');
		}, 500);
	});

	// Master Agreement submit create agreement detail
	$(document).off("click", ".submit_master_agreement_detail").on('click', '.submit_master_agreement_detail', function (e) {
		if($createMasterAgreementForm.valid()){
			createMasterAgreement();
		}
	});

	// cancel create master_agreement
	$(document).off("click", ".cancel_create_master_agreement").on('click', '.cancel_create_master_agreement', function (e) {
		openLoadingDialog($('#main_container'));
		$('#main_container').load('components/template.html');
	});

	// master Agreement Disabled
	$(document).off("click", ".disable_master_agreement").on('click', '.disable_master_agreement', function (e) {
		var master_agreement_id = $(this).attr('data-master-agreement-id');
	    ConfirmDisable('MasterAgreement', master_agreement_id);
	});

	// Add signature agreement to property
	$(document).off("click", ".add_signature_agreement").on('click', '.add_signature_agreement', function (e) {
		var master_agreement_id = $(this).attr('data-master-agreement-id');
		var html_form = $(this).attr('data-form');
		var table_name = $(this).attr('data-table');
		var agreement_url = $(this).attr('data-url');
		$('#addSignatureAgreement').modal('show');
		openLoadingDialog($('#addSignatureAgreement .agreement_data_form'));
		setTimeout(function(){
		    $('#addSignatureAgreement .agreement_data_form').load('components/agreement/forms/'+html_form, function() {
		    	$('#selected_master_agreement_id').val(master_agreement_id);
		    	$('#selected_agreement_table').val(table_name);
		    	$('#selected_agreement_url').val(agreement_url);
		    	var service_id = 0;
		    	getServices(service_id, 'agreement');
		    });
		}, 500);
	});

	$(document).off('click', '.save_empty_agreement_data').on('click', '.save_empty_agreement_data', function() {
	    if($agreementForm.valid()){
	    	$('.save_empty_agreement_data').attr('disabled', true);
	        var master_agreement_id = $('#selected_master_agreement_id').val();
	        var agreement_property_id = $('#agreement_property_id').val();
	        var agreement_service_id = $('#agreement_service_id').val();
	        var property_owner_name = $('#property_owner_name').val();
	        var property_address = $('#property_address').val();
	        var tenant_name = $('#tenant_name').val();
	        var monthly_rent = $('#monthly_rent').val();
	        var selected_agreement_table = $('#selected_agreement_table').val();

	        var data = 'user_id='+user_id+'&master_agreement_id='+master_agreement_id+'&property_id='+agreement_property_id+'&agreement_service_id='+agreement_service_id+'&property_owner_name='+property_owner_name+'&property_address='+property_address+'&tenant_name='+tenant_name+'&monthly_rent='+monthly_rent+'&selected_agreement_table='+selected_agreement_table;

	        var pdfBase64data = '';
	        const { PDFDocument } = PDFLib
	        async function fillForm() {
	            // Fetch the PDF with form fields
	            const formUrl = $('#selected_agreement_url').val();
	            const formPdfBytes = await fetch(formUrl).then(res => res.arrayBuffer())

	            // Load a PDF with form fields
	            const pdfDoc = await PDFDocument.load(formPdfBytes)

	            // Get the form containing all the fields
	            const form = pdfDoc.getForm()

	            // Get all fields in the PDF by their names
	            form.getTextField('landlord_field').setText(property_owner_name);
	            form.getTextField('tenant_field').setText(tenant_name);
	            form.getTextField('address_field').setText(property_address);
	            form.getTextField('rent_field').setText(monthly_rent);

	            // Flatten the form's fields
	            form.flatten();

	            // Serialize the PDFDocument to bytes (a Uint8Array)
	            const pdfBytes = await pdfDoc.save()
	            const bytes  = new Uint8Array( pdfBytes ); 
	            const blob   = new Blob( [ bytes ], { type: "application/pdf" } );

	            var reader = new FileReader();
	            reader.readAsDataURL(blob); 
	            reader.onloadend = function() {
	                pdfBase64data = reader.result;
	                data = data+'&document='+encodeURIComponent(pdfBase64data);

	                $.ajax({
	                    method: 'POST',
	                    url: api_url+"master-agreements/add-signature-agreement",
	                    data: data,
	                    beforeSend: function(xhr) {
	                        xhr.setRequestHeader('Authorization', accessToken);
	                    },
	                    success: function(response) {
	                        display_error_message(response);
	                        if(response.response_data.success == true) {
	                            $('#addSignatureAgreement').modal('hide');
	                            $('.modal-backdrop').remove();
	                            $('.module_tab_container .nav-link').removeClass('active');
	                            $('a.nav-link[href="#t-agreements"]').addClass('active');
	                            var agreement_id = response.agreement.id;
	                            openLoadingDialog($('#main_container'));
								setTimeout(function(){
								    $('#main_container').load('components/agreement/update_agreement.html', agreement_id, function(){
								    	var rand_num1 = random_number(11);
										var rand_num2 = random_number(20);
										var eaid = rand_num1+agreement_id+rand_num2;
								    	window.history.pushState("", "", '?action=template&eaid='+eaid);
								    	getAgreementDetailById(agreement_id);
								    });
								}, 2000);
	                        } else {
	                        	$('.save_empty_agreement_data').attr('disabled', false);
	                        }
	                    }
	                });
	            }

	            // Trigger the browser to download the PDF document
	            //download(pdfBytes, "edited.pdf", "application/pdf");
	        }

	        fillForm();
	    }
	});

	/* Start : Agreements */

	// Agreement Create UI
	$(document).off("click", ".create_agreement").on('click', '.create_agreement', function (e) {
		openLoadingDialog($('#main_container'));
		setTimeout(function(){
		    $('#main_container').load('components/agreement/create_agreement.html', function(){
		    	//getFloatingActionButton('Template', '#create-template-wizard');
		    	setTimeout(function () {
		            getDocumentTypes();
		        }, 300);
		    	
		    	var html = '';
				$.each(role_dd, function( key, value ) {
					html += '<option value="'+value+'">'+value+'</option>';
				});
				$('#role1').html(html);
		    });
		}, 500);
	});

	// cancel create agreement
	$(document).off("click", ".cancel_create_agreement").on('click', '.cancel_create_agreement', function (e) {
		openLoadingDialog($('#main_container'));
		$('#main_container').load('components/template.html');
	});

	// Agreement submit create agreement detail
	$(document).off("click", ".submit_agreement_detail").on('click', '.submit_agreement_detail', function (e) {
		if($createAgreementForm.valid()){
			createNewAgreement();
		}
	});

	// Agreement Edit UI
	$(document).off("click", ".edit_agreement").on('click', '.edit_agreement', function (e) {
		var agreement_id = $(this).attr('data-agreement-id');
		openLoadingDialog($('#main_container'));
		setTimeout(function(){
		    $('#main_container').load('components/agreement/update_agreement.html', agreement_id, function(){
		    	var rand_num1 = random_number(11);
				var rand_num2 = random_number(20);
				var eaid = rand_num1+agreement_id+rand_num2;
		    	window.history.pushState("", "", '?action=template&eaid='+eaid);
		    	getAgreementDetailById(agreement_id);
		    });
		}, 2000);
	});

	// submit agreement template detail
	$(document).off("click", ".update_agreement_detail").on('click', '.update_agreement_detail', function (e) {
		if($updateAgreementForm.valid()){
			updateAgreement();
		}
	});

	$(document).off("click", ".back_to_edit_agreement").on('click', '.back_to_edit_agreement', function (e) {
		var agreement_id = $('#pdf_agreement_id').val();
		openLoadingDialog($('#main_container'));
		setTimeout(function(){
			$('#main_container').load('components/agreement/update_agreement.html', agreement_id, function(){
				var rand_num1 = random_number(11);
				var rand_num2 = random_number(20);
				var eaid = rand_num1+agreement_id+rand_num2;
		    	window.history.pushState("", "", '?action=template&eaid='+eaid);
				getAgreementDetailById(agreement_id);
			});
		}, 2000);
	});

	// Agreement document image hover
	$(document).off('mouseenter', '.preview_agreement_document').on('mouseenter', '.preview_agreement_document', function() {
		$(this).find('.delete_agreement_document').removeClass('d-none');
	}).off('mouseleave', '.preview_agreement_document').on('mouseleave', '.preview_agreement_document', function() {
		$(this).find('.delete_agreement_document').addClass('d-none');
	});

	// Delete property image
	$(document).off('click', '.delete_agreement_document').on('click', '.delete_agreement_document', function() {
	    var agreement_id = $(this).attr('data-agreement-id');
	    var document_id = $(this).attr('data-document-id');
	    var id = agreement_id+'&&'+document_id;
	    ConfirmDelete('Agreement', id);
	});

	// Save document data with coordibnate
	$(document).off("click", ".save_agreement_document_field_data").on('click', '.save_agreement_document_field_data', function (e) {

		//$('.save_agreement_document_data').attr('disabled',true);

		var recipients = [];
	  	$( "div.recipient_row" ).each(function( index ) {
	  		var recipient_action_id = $(this).attr('data-action-id');
	  		var recipient_role = $(this).attr('data-role');
	  		var recipient_email = $(this).attr('data-email');
	  		var recipient_name = $(this).attr('data-name');
	  		var signing_order = $(this).attr('data-signing-order');

	  		var recipient_row = {
				'agreement_recipient_id' : recipient_action_id,
				'signing_order': signing_order,
				'role' : recipient_role,
				'recipient_email' : '',
				'recipient_name' : '',
				'deleted_fields': [],
				'deleted_radio_fields': [],
				'fields' : {
					"checkbox" : [],
					"radiogroup" : [],
					"signature" : [],
					"image" : [],
					"text" : [],
					"email" : [],
					"date" : [],
					"name" : [],
					"company" : [],
					"jobtitle" : [],
				},
			};

			/* Create deleted field array and pass dynamic value */
	  		var deleted_fields_arr = [];
			if($('#'+recipient_action_id).val()) {
				var deleted_fields = $('#'+recipient_action_id).val();
	    		deleted_fields_arr =  deleted_fields.split(',');
	    		recipient_row['deleted_fields'] = deleted_fields_arr;
	    	}

	  		// Create field array and append to recipients

	  		var field_label = '';
		  	var field_array = [];
		  	
			$( ".viewer-body .image-header .item_element" ).each(function( index ) {
			  	
			  	var action_id = $(this).attr('data-action-id');

			  	if(recipient_action_id == action_id) {
		  		
			  		var field_category = $(this).attr('field-category');
			  		field_label = field_category;
			  		
			  		/* Left and Top position calculation */

			  		var leftPixel = parseFloat($(this).css('left'));
			  		var topPixel = parseFloat($(this).css('top'));

			  		/*console.log(canvas_width);
			  		console.log(canvas_height);*/

			  		// Percentage with 817 * dynamic
			  		var leftPercentage = Math.round(((leftPixel / canvas_width) * 100));
			  		var topPercentage = Math.round(((topPixel / canvas_height) * 100));

			  		/*console.log('before');
			  		console.log(leftPercentage);
			  		console.log(topPercentage);*/

			  		// Created field data array
				    var field_data = {
			  			'fieldtype_id' : $(this).attr('field-type-id'),
			  			'fieldtype_name' : $(this).attr('id'),
			  			'fieldtype_category' : $(this).attr('field-category'),
			  			'agreement_document_id' : $(this).closest('div.image-header').attr('document-id'),
			  			'agreement_recipient_id' : action_id,
			  			'page_no' : $(this).attr('page-no'),
			  			'is_mandatory': true,
			  			'x_coord' : leftPercentage,
			  			'y_coord' : topPercentage,
			  			'abs_width' : 100,
			  			'abs_height' : 25,
			  		};

			  		if($(this).attr('field-id') > 0) {
			  			field_data['field_id'] = $(this).attr('field-id');
			  		} else {
			  			field_data['field_id'] = 0;
			  		}

				  	recipient_row['fields'][''+field_label+''].push(field_data);
				}
			});

			recipients.push(recipient_row);
	  	});

	  	//console.log(recipients);
	  	//console.log(JSON.stringify(recipients));
	  	//return false;

	  	var agreement_id = $('#pdf_agreement_id').val();
    	saveAgreementDocumentFieldData(agreement_id, JSON.stringify(recipients));
	});

	$(document).off('click', '.document_send_for_review').on('click', '.document_send_for_review', function(){
		$(this).addClass('add_opacity');
		var agreement_id = $(this).attr('data-agreement-id');
		sendDocumentForReview(agreement_id);
	});	

	$(document).off('click', '.review_document').on('click', '.review_document', function(){
		var ticket_id = $(this).attr('data-ticket-id');
		var property_id = $(this).attr('data-property-id');
		var agreement_id = $(this).attr('data-agreement-id');
		var agreement_recipient_id = $(this).attr('data-agreement-recipient-id');

		$('#viewDocumentModal').modal('show');
		openLoadingDialog($('#document_preview_container'));
		$('#viewDocumentModal #document_preview_container').load('components/agreement/view_draft_document.html', function(){
			$('#selected_agreement_recipient_id').val(agreement_recipient_id);
			$('#selected_agreement_id').val(agreement_id);
			$('#selected_property_id').val(property_id);
			$('#selected_ticket_id').val(ticket_id);
			getDocumentForReview(property_id,agreement_id);

			// Load Ticket Detail
			openLoadingDialog($('#ticket_detail_container'));
			setTimeout(function(){
				$('#ticket_detail_container').load('components/ticket/ticket_detail.html', ticket_id , function(){
					loadTicketDetail(ticket_id,'table');
			        $('.close_ticket_comment').addClass('d-none');
			        $('.reopen_ticket_comment').addClass('d-none');
			        $('#ticket_detail_container .card-header').remove();
			    });
			}, 1000);
		});
	});

	$(document).off('click', '.accept_document').on('click', '.accept_document', function(){
		var agreement_recipient_id = $('#selected_agreement_recipient_id').val();
		var agreement_id = $('#selected_agreement_id').val();
		var property_id = $('#selected_property_id').val();
		acceptDocument(agreement_recipient_id,agreement_id,property_id);
	});

	$(document).off('click', '.download_draft_document').on('click', '.download_draft_document', function(){
		var agreement_id = $('#selected_agreement_id').val();
		var property_id = $('#selected_property_id').val();
		downloadDraftDocument(agreement_id, property_id);
	});

	$(document).off('click', '.document_send_for_signature').on('click', '.document_send_for_signature', function(){
		$(this).addClass('add_opacity');
		var agreement_id = $(this).attr('data-agreement-id');
		var property_id = $(this).attr('data-property-id');
		sendDocumentForSignature(agreement_id, property_id);
	});	

	$(document).off('click', '.sign_document').on('click', '.sign_document', function(){
		var ticket_id = $(this).attr('data-ticket-id');
		var property_id = $(this).attr('data-property-id');
		var agreement_id = $(this).attr('data-agreement-id');
		var agreement_recipient_id = $(this).attr('data-agreement-recipient-id');

		$('#viewDocumentModal').modal('show');
		openLoadingDialog($('#document_preview_container'));
		$('#viewDocumentModal #document_preview_container').load('components/agreement/view_final_document.html', function(){
			$('#selected_agreement_recipient_id').val(agreement_recipient_id);
			$('#selected_agreement_id').val(agreement_id);
			$('#selected_property_id').val(property_id);
			$('#selected_ticket_id').val(ticket_id);
			getDocumentForSignature(property_id,agreement_id,agreement_recipient_id);

			// Load Ticket Detail
			openLoadingDialog($('#ticket_detail_container'));
			setTimeout(function(){
				$('#ticket_detail_container').load('components/agreement/signature_form.html', function(){
					
			    });
			}, 1000);
		});
	});

	/* End : Agreemnts */

	// Zoho Template UI > Add Receipents
	$(document).off("click", ".addRowButton").on('click', '.addRowButton', function (e) {
		var current_sno = $('#recipients_container .recipients_data').length;
		var next_sno = $('#recipients_container .recipients_data').length + 1;

		$('.addRowButton[data-row-id='+current_sno+']').remove();
		$('.removeRowButton[data-row-id='+current_sno+']').removeClass('d-none');

		var data = '';
		data += '<div id="recipients_'+next_sno+'" class="form-row recipients_data">';
            data += '<div class="form-group col-md-5 col-xl-5">';
            	data += '<div class="input-group mb-2 mr-sm-2">';
					data += '<div class="input-group-prepend">';
						data += '<input type="hidden" name="action_id[]" id="action_id'+next_sno+'" class="action_input" value="">';
						data += '<input type="hidden" name="seq_no[]" id="seq_no'+next_sno+'" class="sequenceNo" value="'+next_sno+'">';
						data += '<div class="input-group-text sequenceNo">'+next_sno+'</div>';
					data += '</div>';
					//data += '<input type="text" name="role[]" id="role'+next_sno+'" class="form-control" placeholder="Role">';
					data += '<select name="role[]" id="role'+next_sno+'" class="form-control">';
						var selected = '';
						$.each(role_dd, function( key, value ) {
							data += '<option value="'+value+'">'+value+'</option>';
						});
					data += '</select>';
				data += '</div>';
            data += '</div>';
            data += '<div class="form-group col-md-5 col-xl-5">';
            	data += '<input type="text" name="recipient_email[]" id="recipient_email'+next_sno+'" class="form-control" placeholder="Email">';
            data += '</div>';
            /*data += '<div class="form-group col-md-3 col-xl-3">';
            	data += '<input type="text" name="recipient_name[]" id="recipient_name'+next_sno+'" type="text" class="form-control" placeholder="Name">';
	        data += '</div>';*/
	        data += '<div class="action_btn_group form-group col-md-2 col-xl-2">';
            	data += '<a href="javascript:void(0)" data-row-id="'+next_sno+'" class="removeRowButton btn btn-default mr-2"><i class="fas fa-minus"></i></a>';
            	data += '<a href="javascript:void(0)" data-row-id="'+next_sno+'" class="addRowButton btn btn-default"><i class="fas fa-plus"></i></a>';
	        data += '</div>';
        data += '</div>';

        $('#recipients_container').append(data);

        if($('.recipients_data').length > 1) {
        	$('.recipients_data:first').find('.removeRowButton').remove();
        }
	});

	// Zoho Template UI > Remove Receipents
	$(document).off("click", ".removeRowButton").on('click', '.removeRowButton', function (e) {
		var seq_no = $(this).attr('data-row-id');
		
		$('#recipients_'+seq_no).remove();

		var deletedRowId = $(this).closest('.recipients_data').find('input.action_input').val();
		if(deletedRowId != null) {
			var delimeter = '';
			if($('#deletedZohoActionId').val() != "") { delimeter = ','; }
			var deletedActionIds = $('#deletedZohoActionId').val($('#deletedZohoActionId').val()+delimeter+deletedRowId);

			if($('#deletedAgreementRecipientId').val() != "") { delimeter = ','; }
			var deletedAgreementRecipientIds = $('#deletedAgreementRecipientId').val($('#deletedAgreementRecipientId').val()+delimeter+deletedRowId);
		}
		
		if($('#recipients_container .recipients_data').length == 1) {
			$('.action_btn_group').html('');
			$('.action_btn_group').append('<a href="javascript:void(0)" data-row-id="1" class="removeRowButton btn btn-default mr-2 d-none"><i class="fas fa-minus"></i></a>');
			$('.action_btn_group').append('<a href="javascript:void(0)" data-row-id="1" class="addRowButton btn btn-default"><i class="fas fa-plus"></i></a>');
		} else if($('#recipients_container .recipients_data').length > 1 && $('#recipients_container .recipients_data .addRowButton').length == 0) {
			var last_seq_no = $('#recipients_container .recipients_data:last').find('input.sequenceNo').val();
			$('#recipients_'+last_seq_no+' .action_btn_group').append('<a href="javascript:void(0)" data-row-id="'+last_seq_no+'" class="addRowButton btn btn-default"><i class="fas fa-plus"></i></a>');
		}

		arrangeSeqNo(seq_no);
	});

	// User Contract Form UI > Add Receipents
	$(document).off("click", ".addRecipientButton").on('click', '.addRecipientButton', function (e) {
		var current_sno = $('#recipients_container .recipients_data').length;
		var next_sno = $('#recipients_container .recipients_data').length + 1;

		$('.addRecipientButton[data-row-id='+current_sno+']').remove();
		$('.removeRecipientButton[data-row-id='+current_sno+']').removeClass('d-none');

		var data = '';
		data += '<div id="recipients_'+next_sno+'" class="form-row recipients_data">';
			data += '<div class="form-group col-md-4 col-xl-4">';
                data += '<div class="applicantNo">Primary Applicant</div>';
            data += '</div>';
            data += '<div class="form-group col-md-6 col-xl-6">';
            	data += '<div class="input-group mb-2 mr-sm-2">';
					data += '<div class="input-group-prepend">';
						data += '<input type="hidden" name="action_id[]" id="action_id'+next_sno+'" class="action_input" value="">';
						data += '<input type="hidden" name="seq_no[]" id="seq_no'+next_sno+'" class="sequenceNo" value="'+next_sno+'">';
						data += '<div class="input-group-text sequenceNo">'+next_sno+'</div>';
					data += '</div>';
					data += '<select name="role[]" id="role'+next_sno+'" class="form-control">';
						var selected = '';
						$.each(role_dd, function( key, value ) {
							data += '<option value="'+value+'">'+value+'</option>';
						});
					data += '</select>';
				data += '</div>';
            data += '</div>';
            data += '<div class="action_btn_group form-group col-md-2 col-xl-2">';
            	data += '<a href="javascript:void(0)" data-row-id="'+next_sno+'" class="removeRecipientButton btn btn-default mr-2"><i class="fas fa-minus"></i></a>';
            	data += '<a href="javascript:void(0)" data-row-id="'+next_sno+'" class="addRecipientButton btn btn-default"><i class="fas fa-plus"></i></a>';
	        data += '</div>';
        data += '</div>';

        $('#recipients_container').append(data);

        if($('.recipients_data').length > 1) {
        	$('.recipients_data:first').find('.removeRecipientButton').remove();
        }
	});

	// Zoho document image hover
	$(document).off('mouseenter', '.preview_zoho_document').on('mouseenter', '.preview_zoho_document', function() {
		$(this).find('.delete_zoho_document').removeClass('d-none');
	}).off('mouseleave', '.preview_zoho_document').on('mouseleave', '.preview_zoho_document', function() {
		$(this).find('.delete_zoho_document').addClass('d-none');
	});

	// Delete property image
	$(document).off('click', '.delete_zoho_document').on('click', '.delete_zoho_document', function() {
	    var template_id = $(this).attr('data-template-id');
	    var document_id = $(this).attr('data-document-id');
	    var id = template_id+'&&'+document_id;
	    ConfirmDelete('Document', id);
	});

	// Zoho toggle more setting menu
	$(document).off("click", ".more_setting").on('click', '.more_setting', function (e) {
		$('#more_setting').toggleClass('d-none');
	});

	// Zoho cancel create template
	$(document).off("click", ".cancel_create_template").on('click', '.cancel_create_template', function (e) {
		openLoadingDialog($('#main_container'));
		$('#main_container').load('components/template.html');
	});

	// Zoho submit zoho create template detail
	$(document).off("click", ".submit_zoho_template_detail").on('click', '.submit_zoho_template_detail', function (e) {
		if($createTemplateForm.valid()){
			$(this).attr('disabled', true);
			zohoCreateNewTemplate();
		}
	});

	// Zoho submit zoho create template detail
	$(document).off("click", ".update_zoho_template_detail").on('click', '.update_zoho_template_detail', function (e) {
		if($updateTemplateForm.valid()){
			$(this).attr('disabled', true);
			zohoUpdateTemplate();
		}
	});

	// Zoho recipient row detail
	$(document).off("click", ".recipient_row").on('click', '.recipient_row', function (e) {
		$('.recipient_row').removeClass("recipient_row_bg");
		var bg_color = $(this).attr('data-bg-color');
		var border_color = $(this).attr('data-border-color');
		$("<style type='text/css'> .recipient_row_bg { background-color: "+bg_color+"; border-left: 3px solid "+border_color+" !important; border-right: 3px solid "+border_color+" !important; } </style>").appendTo("head");
		$(this).addClass("recipient_row_bg");

		// Change color of fields section
		/*$('#field_header').css({'background': border_color, 'color': 'white'});
		$('.field-li-view').css({'border': '1px solid '+border_color});
		$('.field-li-view .drag-icon').css({'color': border_color});
		$('.field-li-view .field-icon').css({'background-color': border_color});*/
	});

	$(document).off("click", ".item_element").on('click', '.item_element', function (e) {
		var field_id = $(this).attr('field-id');
		var action_id = $(this).attr('data-action-id');
		var id = $(this).attr('data-id');
		var type = $(this).attr('name');
		ConfirmFieldDelete(type, id, field_id, action_id);
	});


	$(document).off("click", ".back_to_edit_template").on('click', '.back_to_edit_template', function (e) {
		var template_id = $('#pdf_template_id').val();
		var rand_num1 = random_number(11);
		var rand_num2 = random_number(20);
		var tid = rand_num1+template_id+rand_num2;
		window.history.pushState("", "", '?action=template&id='+tid);
		openLoadingDialog($('#main_container'));
		setTimeout(function(){
			$('#main_container').load('components/template/update_template.html', template_id, function(){
				getZohoTemplateDetailById(template_id);
		    	//getFloatingActionButton('Template', '#update-template-wizard');
			});
		}, 2000);
	});

	// Save document data with coordibnate
	$(document).off("click", ".save_document_data").on('click', '.save_document_data', function (e) {

		$('.save_document_data').attr('disabled',true);

		var actions = [];
	  	$( "div.recipient_row" ).each(function( index ) {
	  		var recipient_action_id = $(this).attr('data-action-id');
	  		var recipient_role = $(this).attr('data-role');
	  		var recipient_email = $(this).attr('data-email');
	  		var recipient_name = $(this).attr('data-name');

	  		var action_type = $(this).attr('data-action-type');
	  		var signing_order = $(this).attr('data-signing-order');
	  		var in_person_name = $(this).attr('data-in-person-name');
	  		var verify_recipient = $(this).attr('data-verify-recipient');
	  		var verification_type = $(this).attr('data-verification-type');

	  		var recipient_row = {
				'action_id' : recipient_action_id,
				'action_type': action_type,
				'signing_order': signing_order,
				'role' : recipient_role,
				'recipient_email' : '',
				'recipient_name' : '',
				'in_person_name': in_person_name,
				'verify_recipient': verify_recipient,
				'verification_type': verification_type,
				'deleted_fields': [],
				'deleted_radio_fields': [],
				'fields' : {
					"text_fields" : [],
					"check_boxes" : [],
					"image_fields" : [],
					"radio_groups" : [],
					"date_fields" : []
				},
			};

			/* Create deleted field array and pass dynamic value */
	  		var deleted_fields_arr = [];
			if($('#'+recipient_action_id).val()) {
				var deleted_fields = $('#'+recipient_action_id).val();
	    		deleted_fields_arr =  deleted_fields.split(',');
	    		recipient_row['deleted_fields'] = deleted_fields_arr;
	    	}

	  		// Create field array and append to actions

	  		var field_label = '';
		  	var field_array = [];
		  	
			$( ".viewer-body .image-header .item_element" ).each(function( index ) {
			  	
			  	var action_id = $(this).attr('data-action-id');

			  	if(recipient_action_id == action_id) {
		  		
			  		var field_category = $(this).attr('field-category');
			  		if(field_category == 'textfield') {
			  			field_label = 'text_fields';
			  		} else if(field_category == 'checkbox') {
			  			field_label = 'check_boxes';
			  		}else if(field_category == 'image') {
			  			field_label = 'image_fields';
			  		} else if(field_category == 'radiogroup') {
			  			field_label = 'radio_groups';
			  		} else if(field_category == 'datefield') {
			  			field_label = 'date_fields';
			  		}

			  		/* Left and Top position calculation */

			  		var leftPixel = parseFloat($(this).css('left'));
			  		var topPixel = parseFloat($(this).css('top'));

			  		/*console.log(canvas_width);
			  		console.log(canvas_height);*/

			  		// Percentage with 817 * dynamic
			  		var leftPercentage = Math.round(((leftPixel / canvas_width) * 100));
			  		var topPercentage = Math.round(((topPixel / canvas_height) * 100));

			  		/*console.log('before');
			  		console.log(leftPercentage);
			  		console.log(topPercentage);*/

			  		// original_width and original_height
			  		var original_width = $(this).closest('.image-header').attr('original-width');
			  		var original_height = $(this).closest('.image-header').attr('original-height');
			  		leftPercentage = Math.round(((original_width * leftPercentage) / 100));
			  		topPercentage = Math.round(((original_height * topPercentage) / 100));

			  		/*console.log('after');
			  		console.log(leftPercentage);
			  		console.log(topPercentage);*/

			  		// Created field data array
				    var field_data = {
			  			'field_type_name' : $(this).attr('id'),
			  			'field_name' : $(this).attr('id'),
			  			'document_id' : $(this).closest('div.image-header').attr('document-id'),
			  			'action_id' : action_id,
			  			'page_no' : $(this).attr('page-no'),
			  			'is_mandatory': true,
			  			'x_coord' : leftPercentage,
			  			'y_coord' : topPercentage,
			  			'abs_width' : 100,
			  			'abs_height' : 25,
			  		};

			  		if($(this).attr('field-id') > 0) {
			  			field_data['field_id'] = $(this).attr('field-id');
			  		}

				  	recipient_row['fields'][''+field_label+''].push(field_data);
				}
			});

			actions.push(recipient_row);
	  	});

	  	/*console.log(actions);
	  	console.log(JSON.stringify(actions));
	  	return false;*/

	  	var template_id = $('#pdf_template_id').val();
    	saveZohoDocumentData(template_id, JSON.stringify(actions));
	});

	// Create Contract Page
	$(document).off("click", ".create_contract").on('click', '.create_contract', function (e) {
		openLoadingDialog($('#main_container'));
		setTimeout(function(){
		    $('#main_container').load('components/contract/create_contract.html', function() {
		    	var service_id = 0;
		    	getServices(service_id, 'contract');
		    });
		}, 500);
	});

	// Create Contract Page
	$(document).off("click", ".edit_contract").on('click', '.edit_contract', function (e) {
		var contract_id = $(this).attr('data-id');
		var service_id = $(this).attr('data-service-id');
		openLoadingDialog($('#main_container'));
		setTimeout(function(){
		    $('#main_container').load('components/contract/create_contract.html', function() {
		    	$('#selected_contract_id').val(contract_id);
		    	getContractDetail(contract_id);
		    	getServices(service_id, 'contract');
		    });
		}, 500);
	});

	// cancel create contract
	$(document).off("click", ".cancel_create_contract").on('click', '.cancel_create_contract', function (e) {
		openLoadingDialog($('#main_container'));
		$('#main_container').load('components/template.html');
	});

	// Save contract datas
	$(document).off("click", ".submit_contract_detail").on('click', '.submit_contract_detail', function (e) {
		saveContractData();
   	});

	// Add New Linking account
	$(document).off('click', '.add_new_account').on('click', '.add_new_account', function(){
	    $('#linkAccountModal').modal('show');
	    openLoadingDialog($('#link_form_container'));
		$('#link_form_container').load('components/myaccount/verify.html', function(){
			
	    });
	});

	// Link Account button
	$(document).off('click', '.link_account_btn').on('click', '.link_account_btn', function(){
		if(!$passcodeForm.valid()){
			return false;
		} else {
			linkAccount();
		}
	});

	// Link Account button
	$(document).off('click', '.unlink_account').on('click', '.unlink_account', function(){
		var user_id = user.user_details.user_id;
		var id = $(this).attr('data-id');
		unlinkAccount(id, user_id);
		$(this).closest('.switch_user').remove();
	});

	$(document).off("keypress keyup blur", ".no_special_char_allow").on('keypress keyup blur', '.no_special_char_allow', function (e) {
       	var report_input = $(this).val();
       	var regExp = /[`~!@%^|+\=?;'"<>\{\}\[\]\\\/]/gi;
       	var isSplChar = regExp.test(report_input);
       	if(isSplChar)
       	{
           	var no_spl_char = report_input.replace(/[`~!@%^|+\=?;'"<>\{\}\[\]\\\/]/gi, '');
           	$(this).val(no_spl_char);
       	}
   	});

	   $(document).off("keypress keyup blur", ".invoice_number_char_allow").on('keypress keyup blur', '.invoice_number_char_allow', function (e) {
		var report_input = $(this).val();
		var regExp = /[`~!@%^|+=?;'"<>\{\}\[\]]/gi;
		var isSplChar = regExp.test(report_input);
		if(isSplChar)
		{
			var no_spl_char = report_input.replace(/[`~!@%^|+=?;'"<>\{\}\[\]]/gi, '');
			$(this).val(no_spl_char);
		}
	});

   	$(document).off("keypress keyup blur", ".allow_numeric_with_decimal").on('keypress keyup blur', '.allow_numeric_with_decimal', function (e) {
       	$(this).val($(this).val().replace(/[^0-9\.|\,]/g,''));
        if(event.which == 44)
        {
        	return true;
        }
        if ((event.which != 46 || $(this).val().indexOf('.') != -1) && (event.which < 48 || event.which > 57  )) {        
        	event.preventDefault();
        }
   	});

   	$(document).off("keypress keyup blur", ".numbersOnly").on('keypress keyup blur', '.numbersOnly', function (e) {
       	this.value = this.value.replace(/[^0-9]/g,'');
   	});

   	// Subscription Section

   	$(document).off("change", ".get_pricing_data").on('change', '.get_pricing_data', function (e) {
   		var plan_id = $('input[name="plan-radio"]:checked').attr('data-id');
   		if(plan_id > 0) {
   			loadPlanPricingData(plan_id);
   		}
   	});

   	// Start : Coupon Section

   	$(document).off("click", ".add_new_coupon").on('click', '.add_new_coupon', function (e) {
   		$('#coupon_form_container_edit').html('');
   		$('#couponAddModal').modal('show');
		openLoadingDialog($('#coupon_form_container_add'));
		$('#couponAddModal #coupon_form_container_add').load('components/subscription/add_coupon.html');
   	});

   	$(document).off("click", ".edit_coupon").on('click', '.edit_coupon', function (e) {
   		$('#coupon_form_container_add').html('');
   		var coupon_id = $(this).attr('data-id');
   		$('#couponEditModal').modal('show');
		openLoadingDialog($('#coupon_form_container_edit'));
		$('#couponEditModal #coupon_form_container_edit').load('components/subscription/edit_coupon.html', coupon_id, function(){
			$('#selected_coupon_id').val(coupon_id);
			setTimeout(function(){
				getCouponDetailById(coupon_id);
			}, 500);
		});
   	});

   	$(document).off('click', '.save_coupon_data').on('click', '.save_coupon_data', function() {
		var type = $(this).attr('data-type');
	    saveCouponData(type);
	});

	// Start : Plan Section

	$(document).off("click", ".add_new_plan").on('click', '.add_new_plan', function (e) {
   		$('#plan_detail_container_edit').html('');
   		$('#planAddModal').modal('show');
		openLoadingDialog($('#plan_detail_container_add'));
		$('#planAddModal #plan_detail_container_add').load('components/subscription/add_plan.html');
   	});

   	$(document).off("click", ".edit_plan").on('click', '.edit_plan', function (e) {
   		$('#plan_detail_container_add').html('');
   		var plan_id = $(this).attr('data-id');
   		$('#planEditModal').modal('show');
		openLoadingDialog($('#plan_detail_container_edit'));
		$('#planEditModal #plan_detail_container_edit').load('components/subscription/edit_plan.html', plan_id, function(){
			$('#selected_plan_id').val(plan_id);
			setTimeout(function(){
				getPlanDetailById(plan_id);
			}, 500);
		});
   	});

   	$(document).off('click', '.save_plan_data').on('click', '.save_plan_data', function() {
		var type = $(this).attr('data-type');
	    savePlanData(type);
	});

	// Start : Plan Pricing Section

	$(document).off("click", ".add_new_plan_pricing").on('click', '.add_new_plan_pricing', function (e) {
   		$('#plan_pricing_detail_container_edit').html('');
   		$('#planPricingAddModal').modal('show');
		openLoadingDialog($('#plan_pricing_detail_container_add'));
		$('#planPricingAddModal #plan_pricing_detail_container_add').load('components/subscription/add_plan_pricing.html');
   	});

   	$(document).off("click", ".edit_plan_pricing").on('click', '.edit_plan_pricing', function (e) {
   		$('#plan_pricing_detail_container_add').html('');
   		var plan_pricing_id = $(this).attr('data-id');
   		$('#planPricingEditModal').modal('show');
		openLoadingDialog($('#plan_pricing_detail_container_edit'));
		$('#planPricingEditModal #plan_pricing_detail_container_edit').load('components/subscription/edit_plan_pricing.html', plan_pricing_id, function(){
			$('#selected_plan_pricing_id').val(plan_pricing_id);
			setTimeout(function(){
				getPlanPricingDetailById(plan_pricing_id);
			}, 500);
		});
   	});

   	$(document).off('click', '.save_plan_pricing_data').on('click', '.save_plan_pricing_data', function() {
		var type = $(this).attr('data-type');
	    savePlanPricingData(type);
	});

	/* Permission Groups and Roles */

	$(document).off("click", ".add_new_group").on('click', '.add_new_group', function (e) {
   		$('#group_form_container_edit').html('');
   		$('#groupAddModal').modal('show');
		openLoadingDialog($('#group_form_container_add'));
		$('#groupAddModal #group_form_container_add').load('components/permission/add_group.html');
   	});

   	$(document).off("click", ".edit_group").on('click', '.edit_group', function (e) {
   		$('#group_form_container_add').html('');
   		var group_id = $(this).attr('data-id');
   		$('#groupEditModal').modal('show');
		openLoadingDialog($('#group_form_container_edit'));
		$('#groupEditModal #group_form_container_edit').load('components/permission/edit_group.html', group_id, function(){
			$('#selected_group_id').val(group_id);
			setTimeout(function(){
				getGroupDetailById(group_id);
			}, 500);
		});
   	});

	$(document).off('click', '.save_group_data').on('click', '.save_group_data', function() {
		var type = $(this).attr('data-type');
	    saveGroupData(type);
	});

	// Delete group data
	$(document).off('click', '.delete_group').on('click', '.delete_group', function(e) {
		e.stopPropagation();
	    var group_id = $(this).attr('data-id');
	    ConfirmDelete('Group', group_id); 
	});

	/* Features code start */

	$(document).off("click", ".add_new_feature").on('click', '.add_new_feature', function (e) {
   		$('#feature_form_container_edit').html('');
   		$('#featureAddModal').modal('show');
		openLoadingDialog($('#feature_form_container_add'));
		$('#featureAddModal #feature_form_container_add').load('components/permission/add_feature.html');
   	});

   	$(document).off("click", ".edit_feature").on('click', '.edit_feature', function (e) {
   		$('#feature_form_container_add').html('');
   		var feature_id = $(this).attr('data-id');
   		$('#featureEditModal').modal('show');
		openLoadingDialog($('#feature_form_container_edit'));
		$('#featureEditModal #feature_form_container_edit').load('components/permission/edit_feature.html', feature_id, function(){
			$('#selected_feature_id').val(feature_id);
			setTimeout(function(){
				getFeatureDetailById(feature_id);
			}, 500);
		});
   	});

	$(document).off('click', '.save_feature_data').on('click', '.save_feature_data', function() {
		var type = $(this).attr('data-type');
	    saveFeatureData(type);
	});

	// Get Feature Item
	$(document).off("change", ".get_feature_item").on('change', '.get_feature_item', function (e) {
   		var feature_id = $('input[name="feature-radio"]:checked').attr('data-id');
   		if(feature_id > 0) {
   			loadFeatureItemData(feature_id);
   		}
   	});

   	$(document).off("click", ".add_new_feature_item").on('click', '.add_new_feature_item', function (e) {
   		$('#feature_item_form_container_edit').html('');
   		$('#featureItemAddModal').modal('show');
		openLoadingDialog($('#feature_item_form_container_add'));
		$('#featureItemAddModal #feature_item_form_container_add').load('components/permission/add_feature_item.html');
   	});

   	$(document).off("click", ".edit_feature_item").on('click', '.edit_feature_item', function (e) {
   		$('#feature_item_form_container_add').html('');
   		var feature_item_id = $(this).attr('data-id');
   		$('#featureItemEditModal').modal('show');
		openLoadingDialog($('#feature_item_form_container_edit'));
		$('#featureItemEditModal #feature_item_form_container_edit').load('components/permission/edit_feature_item.html', feature_item_id, function(){
			$('#selected_feature_item_id').val(feature_item_id);
			setTimeout(function(){
				getFeatureItemDetailById(feature_item_id);
			}, 500);
		});
   	});

	$(document).off('click', '.save_feature_item_data').on('click', '.save_feature_item_data', function() {
		var type = $(this).attr('data-type');
	    saveFeatureItemData(type);
	});

	/* Feature code end */

	$(document).off("click", ".add_new_service").on('click', '.add_new_service', function (e) {
   		$('#service_form_container_edit').html('');
   		$('#serviceAddModal').modal('show');
		openLoadingDialog($('#service_form_container_add'));
		$('#serviceAddModal #service_form_container_add').load('components/permission/add_service.html');
   	});

   	$(document).off("click", ".edit_service").on('click', '.edit_service', function (e) {
   		$('#service_form_container_add').html('');
   		var service_id = $(this).attr('data-id');
   		$('#serviceEditModal').modal('show');
		openLoadingDialog($('#service_form_container_edit'));
		$('#serviceEditModal #service_form_container_edit').load('components/permission/edit_service.html', service_id, function(){
			$('#selected_service_id').val(service_id);
			setTimeout(function(){
				getServiceDetailById(service_id);
			}, 500);
		});
   	});

	$(document).off('click', '.save_service_data').on('click', '.save_service_data', function() {
		var type = $(this).attr('data-type');
	    saveServiceData(type);
	});

	// Delete service data
	$(document).off('click', '.delete_service').on('click', '.delete_service', function(e) {
		e.stopPropagation();
	    var service_id = $(this).attr('data-id');
	    ConfirmDelete('Service', service_id); 
	});

	// Get Service Member
	$(document).off("change", ".get_service_member").on('change', '.get_service_member', function (e) {
   		var service_id = $('input[name="service-radio"]:checked').attr('data-id');
   		if(service_id > 0) {
   			loadServiceMemberData(service_id);
   		}
   	});

	//get sub-categories-data
	$(document).off("change", ".get_sub_cat_data").on('change', '.get_sub_cat_data', function (e) {
		var user_id = user.user_details.user_id;
		var category_id = $('input[name="category-radio"]:checked').attr('data-id');		
		loadSubCategoryData(category_id, user_id);
	});

   	// Add new service member
   	$(document).off("click", ".add_new_service_member").on('click', '.add_new_service_member', function (e) {
   		$('#service_member_form_container_edit').html('');
   		$('#serviceMemberAddModal').modal('show');
		openLoadingDialog($('#service_member_form_container_add'));
		$('#serviceMemberAddModal #service_member_form_container_add').load('components/permission/add_service_member.html', function() {
			getUserList();
		});
   	});

   	// Save service member data
   	$(document).off('click', '.save_service_member_data').on('click', '.save_service_member_data', function() {
		saveServiceMemberData();
	});

   	// Delete user service data
	$(document).off('click', '.delete_user_service').on('click', '.delete_user_service', function(e) {
		e.stopPropagation();
	    var user_service_id = $(this).attr('data-id');
	    ConfirmDelete('ServiceMember', user_service_id); 
	});

	// Save Permissions data
   	$(document).off('click', '.save_permission_data').on('click', '.save_permission_data', function() {
		var permissionArray = [];
		$('.permission_checkbox').each(function(key, value) {
			var service_name = $(this).html();
			var service_id = $(this).attr('data-service-id');
			var feature_id = $(this).attr('data-feature-id');
			var feature_item_id = $(this).attr('data-feature-item-id');
			if($(this).is(":checked")) {
				permissionArray.push({
					'service_id' : service_id,
					'feature_id' : feature_id,
					'feature_item_id' : feature_item_id,
				});
			}
		});

		//console.log(JSON.stringify(permissionArray));
		saveFeaturePermissionData(JSON.stringify(permissionArray));
	});
	/* End */

	$(document).off('click', '.close_transaction_detail_modal').on('click', '.close_transaction_detail_modal', function() {
		$(this).closest('div.popover').popover('hide');
	});

	$(document).off('click', '.overview_detail').on('click', '.overview_detail', function() {
	    var container = '';
	    var type = $(this).attr('data-id');
	    if(type == "po_current_monthly_bill") {
	        container = '#current_monthly_bill_data_container';
	    } else if(type == "po_total_property") {
	        container = '#total_property_data_container';
	    } else if(type == "t_current_month_rent") {
	        container = '#current_month_rent_data_container';
	    } else if (type == "t_total_pending_amount") {
	        container = '#total_pending_amount_data_container';
	    } else if(type == "t_total_property") {
	        container = '#total_property_data_container';
	    }

	    if($(this).hasClass('toggle')) {
	        $(this).html('<i class="align-middle fas fa-fw fa-angle-down"></i>');
	        $(container).html('');
	        $(this).removeClass('toggle');
	        return false;
	    }
	    
	    $(this).toggleClass('toggle');
	    $(this).html('<i class="align-middle fas fa-fw fa-angle-up"></i>');
	    openLoadingDialog($(container));
	    getMyAccountOverviewDetail(type, container);
	});

	/* Start : Dwolla func transfer */
	$(document).off("click", ".dwolla_transfer_fund").on('click', '.dwolla_transfer_fund', function (e) {
   		$('#transfer_fund_container').html('');
   		$('#transferFundModal').modal('show');
		openLoadingDialog($('#transfer_fund_container'));
		var fund_type = $(this).attr('data-type');
		if(fund_type == 'transfer_to_bank') {
			$('#transferFundModal #transfer_fund_container').load('components/dwolla/transfer_to_bank.html', function() {
				getFundingSource();
			});
		} else if(fund_type == 'add_to_balance') {
			$('#transferFundModal #transfer_fund_container').load('components/dwolla/add_to_balance.html', function() {
				getFundingSource();
			});
		}
   	});

   	$(document).off("click", ".dwolla_transfer_btn").on('click', '.dwolla_transfer_btn', function (e) {
   		$(this).attr('disabled', true);
   		var fund_type = $(this).attr('data-type');
		if(fund_type == 'transfer_to_bank') {
			transferToBank();
		} else if(fund_type == 'add_to_balance') {
			addToBalance();
		}
   	});

   	/* End : Dwolla func transfer */	

   	/* start : system email datas */

	$(document).off("click", ".email_preview").on('click', '.email_preview', function (e) {
   		var id = $(this).attr('data-id');
   		var type = $(this).attr('data-type');
   		if(type == 'sent') {
   			getSentEmailPreview(id);
   		} else if(type == 'main') {
   			getMainEmailPreview(id);
   		}
   	});

   	$(document).off("click", ".resend_email").on('click', '.resend_email', function (e) {
   		var id = $(this).attr('data-id');
   		resendEmail(id);
   	});

   	/* start : email templates */

   	$(document).off("click", ".add_email_template").on('click', '.add_email_template', function (e) {
   		$('#add_email_template_container').html('');
   		$('#addEmailTemplateModal').modal('show');
		openLoadingDialog($('#add_email_template_container'));
		$('#addEmailTemplateModal #add_email_template_container').load('components/email/add_email_template.html', function() {
			getEmailIdentifier();
		});
   	});

   	$(document).off("click", ".edit_email_template").on('click', '.edit_email_template', function (e) {
   		$('#edit_email_template_container').html('');
   		$('#editEmailTemplateModal').modal('show');
		openLoadingDialog($('#edit_email_template_container'));
		var email_template_id = $(this).attr('data-id');
		$('#editEmailTemplateModal #edit_email_template_container').load('components/email/edit_email_template.html', function() {
			getEmailTemplateDetail(email_template_id);
			$('#selected_email_template_id').val(email_template_id);
		});
   	});

   	$(document).off("click", ".save_email_template_data").on('click', '.save_email_template_data', function (e) {
		var type = $(this).attr('data-type');
		saveEmailTemplateData(type);
   	});

   	/* Search functionality start */   	

	$(document).off('click', '#search_pin_unpin').on('click', '#search_pin_unpin', function() {
		var type = $(this).attr('data-type');
		if(type == 'pin') {
			$(this).attr('data-type', 'unpin');
			$(this).attr('src', 'icon/unpin.png');
			localStorage.setItem('search', 'unpin');
		} else {
			$(this).attr('data-type', 'pin');
			$(this).attr('src', 'icon/pin.png');
			localStorage.setItem('search', 'pin');
		}
	});

	$(document).off('click', '.open_search_more_modal_box').on('click', '.open_search_more_modal_box', function() {
		var search_modal = $(this).attr('data-type');
		var search_data_container = $(this).attr('data-container');
		$('#'+search_modal).modal('show');
		openLoadingDialog($('#'+search_data_container));
		openSearchMoreModalBox(search_modal);
	});

	$(document).off('mouseover', '.alphabet-li').on('mouseover', '.alphabet-li', function() {
		var char = $(this).attr('data-char');
		$("ul.ul-search-modal li:not(.container_"+char+")").addClass('add_opacity');
	});

	$(document).off('mouseout', '.alphabet-li').on('mouseout', '.alphabet-li', function() {
		$("ul.ul-search-modal li").removeClass('add_opacity');
	});

	// Search list
	$(document).off('keyup', '.modal_search_input').on('keyup', '.modal_search_input', function(){
	    var text = $(this).val().toLowerCase();
	    $('ul.ul-search-modal li').hide();
	    $('ul.ul-search-modal li').each(function(){
	        if($(this).find('span').text().toLowerCase().indexOf(""+text+"") != -1 ){
	            $(this).find('span').closest('ul.ul-search-modal li').show();

	            //console.log($(this).find('span').text().charAt(0).toUpperCase());
	            var firstChar = $(this).find('span').text().charAt(0).toUpperCase();
	            $('#main_li_'+firstChar).show();
	        }
	    });
	});

	$(document).off('change', '.search_date_macro').on('change', '.search_date_macro', function() {

		$('#search_lease_start_date > input').val('');
		$('#search_lease_end_date > input').val('');
		$('#search_lease_container ul li').removeClass('add_opacity');

		var macro = $(this).val();
		// 1 = Equal To, 2 = Greater Than, 3 = Less Than, 4 = Between
		if(macro == 1 || macro == 2 || macro == 3) {
			$('#search_lease_start_date').addClass('add_opacity');
			$('#search_lease_end_date').removeClass('add_opacity');
		} else if(macro == 4) {
			$('#search_lease_start_date, #search_lease_end_date').removeClass('add_opacity');
		} else {
			$('#search_lease_start_date, #search_lease_end_date').addClass('add_opacity');
		}
	});

	$(document).off('change', '#search_lease_start_date, #search_lease_end_date').on('change', '#search_lease_start_date, #search_lease_end_date', function() {
		$('#search_lease_container ul li').removeClass('add_opacity');
	    var macro = $('.search_date_macro').val();
	    var start_date = formatDateYmd($('#search_lease_start_date > input').val());
	    var end_date = formatDateYmd($('#search_lease_end_date > input').val());
	    start_date = new Date(start_date);
	    end_date = new Date(end_date);
	    $('#search_lease_container ul li').each(function(key, value) {
	        var s_date = formatDateYmd($(this).attr('data-start'));
	        var e_date = formatDateYmd($(this).attr('data-end'));
	        s_date = new Date(s_date);
	        e_date = new Date(e_date);
	        if(macro == 1) {
	            if(!(e_date.getTime() == end_date.getTime())) {
	                $(this).addClass('add_opacity');
	            }
	        } else if(macro == 2) {
	            if(!(e_date.getTime() > end_date.getTime())) {
	                $(this).addClass('add_opacity');
	            }
	        } else if(macro == 3) {
	            if(!(e_date.getTime() < end_date.getTime())) {
	                $(this).addClass('add_opacity');
	            }
	        } else if(macro == 4) {
	        	if(!(s_date.getTime() >= start_date.getTime() && e_date.getTime() <= end_date.getTime())) {
	            	$(this).addClass('add_opacity');
	            }
	        }
	    });
	});

	$(document).off('change', '.search_modal_checkbox').on('change', '.search_modal_checkbox', function(){
		var element;
		var field = $(this).attr('data-field');
		var type = $(this).attr('data-modal');
		var value = $(this).attr('data-id');
		if(type == 'searchPropertyModal') {
			element = 'property_search_checkbox';
	    } else if(type == 'searchTenantModal') {
	    	element = 'tenant_search_checkbox';
	    } else if(type == 'searchLeaseModal') {
			element = 'lease_search_checkbox';
	    } else if(type == 'searchTransactionModal') {
	    	element = 'transaction_search_checkbox';
	    } else if(type == 'searchUserNameModal') {
	    	element = 'user_name_search_checkbox';
	    } else if(type == 'searchUserEmailModal') {
	    	element = 'user_email_search_checkbox';
	    } else if(type == 'searchUserPhoneModal') {
	    	element = 'user_phone_search_checkbox';
	    } else if(type == 'searchUserStatusModal') {
	    	element = 'user_status_search_checkbox';
	    }

	    if($(this).is(":checked")) {
    		$( "."+element+"[value='"+value+"']").prop('checked', true);
    	} else {
    		$( "."+element+"[value='"+value+"']").prop('checked', false);
    	}
	});

	$(document).off('change', '.property_search_checkbox, .tenant_search_checkbox, .transaction_search_checkbox, .lease_search_checkbox, .user_name_search_checkbox, .user_email_search_checkbox, .user_phone_search_checkbox, .user_status_search_checkbox').on('change', '.property_search_checkbox, .tenant_search_checkbox, .transaction_search_checkbox, .lease_search_checkbox, .user_name_search_checkbox, .user_email_search_checkbox, .user_phone_search_checkbox, .user_status_search_checkbox', function() {

		var field = $(this).attr('data-field');

		var html = '';

		if(field == 'property') {

		    // Get Selected proeprty data
		    var propertyIDs = "";
			$('.property_search_checkbox:checked').each(function () {
				var value = $(this).val();
				var arr = propertyIDs.split(",");
				if (arr.indexOf(value.trim()) == -1) {
					propertyIDs += value + ",";
					html += '<span class="badge badge-primary text-left">'+$(this).next('span').html()+'<i class="align-right ml-2 far fa-fw fa-trash-alt delete_selected_filter" data-id="'+value+'" data-type="property" data-field="property"></i><input type="hidden" name="selected_property_data" class="selected_property_data" value="'+value+'"></span>';
				}
			});
			propertyIDs = propertyIDs.slice(0, -1);

			// Get Selected tenant data
			var tenantIDs = "";
			$('.tenant_search_checkbox:checked').each(function () {
				var value = $(this).val();
				var arr = tenantIDs.split(",");
				if (arr.indexOf(value.trim()) == -1) {
					tenantIDs += value + ",";
					html += '<span class="badge badge-primary text-left">'+$(this).next('span').html()+'<i class="align-right ml-2 far fa-fw fa-trash-alt delete_selected_filter" data-id="'+value+'" data-type="tenant" data-field="property"></i><input type="hidden" name="selected_tenant_data" class="selected_tenant_data" value="'+value+'"></span>';
				}
			});
			tenantIDs = tenantIDs.slice(0, -1);

			// Get Selected lease data
		    var leaseIDs = "";
			$('.lease_search_checkbox:checked').each(function () {
				var value = $(this).val();
				var arr = leaseIDs.split(",");
				if (arr.indexOf(value.trim()) == -1) {
					leaseIDs += value + ",";
					html += '<span class="badge badge-primary text-left">'+$(this).next('span').html()+'<i class="align-right ml-2 far fa-fw fa-trash-alt delete_selected_filter" data-id="'+value+'" data-type="lease" data-field="property"></i><input type="hidden" name="selected_lease_data" class="selected_lease_data" value="'+value+'"></span>';
				}
			});
			leaseIDs = leaseIDs.slice(0, -1);

			// Get Selected transaction data
			var transactionIDs = "";
			$('.transaction_search_checkbox:checked').each(function () {
				var value = $(this).val();
				var arr = transactionIDs.split(",");
				if (arr.indexOf(value.trim()) == -1) {
				   transactionIDs += value + ",";
			    	html += '<span class="badge badge-primary text-left">'+$(this).next('span').html()+'<i class="align-right ml-2 far fa-fw fa-trash-alt delete_selected_filter" data-id="'+value+'" data-type="transaction" data-field="property"></i><input type="hidden" name="selected_transaction_data" class="selected_transaction_data" value="'+value+'"></span>';
				}
			});
			transactionIDs = transactionIDs.slice(0, -1);

			/* Add selected filter in filter container */

			$('#filter_data_container').html(html);
			$('#filter_data_container').addClass('m-3');
			localStorage.setItem('search_fields', $('#filter_data_container').html());

		    var data = 'propertyIDs='+propertyIDs+'&tenantIDs='+tenantIDs+'&leaseIDs='+leaseIDs+'&transactionIDs='+transactionIDs+'&user_id='+user.user_details.user_id+'&user_service_name='+user_service_name;

	        var open_for = 'search';
	        $('#main_container').load('components/properties.html', open_for, function() {
	        	$('#open_for').val(open_for);
	        	getPropertiesFilterData(data);
	        });

		} else if(field == 'user') {

			// Get Selected user name data
		    var userNameIDs = "";
			$('.user_name_search_checkbox:checked').each(function () {
				var value = $(this).val();
				var arr = userNameIDs.split(",");
				if (arr.indexOf(value.trim()) == -1) {
					userNameIDs += value + ",";
					html += '<span class="badge badge-primary text-left">'+$(this).next('span').html()+'<i class="align-right ml-2 far fa-fw fa-trash-alt delete_selected_filter" data-id="'+value+'" data-type="username" data-field="user"></i><input type="hidden" name="selected_user_name_data" class="selected_user_name_data" value="'+value+'"></span>';
				}
			});
			userNameIDs = userNameIDs.slice(0, -1);

			// Get Selected user email data
		    var userEmailIDs = "";
			$('.user_email_search_checkbox:checked').each(function () {
				var value = $(this).val();
				var arr = userEmailIDs.split(",");
				if (arr.indexOf(value.trim()) == -1) {
					userEmailIDs += value + ",";
					html += '<span class="badge badge-primary text-left">'+$(this).next('span').html()+'<i class="align-right ml-2 far fa-fw fa-trash-alt delete_selected_filter" data-id="'+value+'" data-type="useremail" data-field="user"></i><input type="hidden" name="selected_user_email_data" class="selected_user_email_data" value="'+value+'"></span>';
				}
			});
			userEmailIDs = userEmailIDs.slice(0, -1);

			// Get Selected user phone data
		    var userPhoneIDs = "";
			$('.user_phone_search_checkbox:checked').each(function () {
				var value = $(this).val();
				var arr = userPhoneIDs.split(",");
				if (arr.indexOf(value.trim()) == -1) {
					userPhoneIDs += value + ",";
					html += '<span class="badge badge-primary text-left">'+$(this).next('span').html()+'<i class="align-right ml-2 far fa-fw fa-trash-alt delete_selected_filter" data-id="'+value+'" data-type="userphone" data-field="user"></i><input type="hidden" name="selected_user_phone_data" class="selected_user_phone_data" value="'+value+'"></span>';
				}
			});
			userPhoneIDs = userPhoneIDs.slice(0, -1);

			// Get Selected user status data
		    var userStatusIDs = "";
			$('.user_status_search_checkbox:checked').each(function () {
				var value = $(this).val();
				var arr = userStatusIDs.split(",");
				if (arr.indexOf(value.trim()) == -1) {
					userStatusIDs += value + ",";
					html += '<span class="badge badge-primary text-left">'+$(this).next('span').html()+'<i class="align-right ml-2 far fa-fw fa-trash-alt delete_selected_filter" data-id="'+value+'" data-type="userstatus" data-field="user"></i><input type="hidden" name="selected_user_status_data" class="selected_user_status_data" value="'+value+'"></span>';
				}
			});
			userStatusIDs = userStatusIDs.slice(0, -1);

			/* Add selected filter in filter container */

			$('#filter_data_container').html(html);
			$('#filter_data_container').addClass('m-3');
			localStorage.setItem('search_fields', $('#filter_data_container').html());

		    var data = 'userNameIDs='+userNameIDs+'&userEmailIDs='+userEmailIDs+'&userPhoneIDs='+userPhoneIDs+'&userStatusIDs='+userStatusIDs+'&user_id='+user.user_details.user_id+'&user_service_name='+user_service_name;

	        var open_for = 'search';
	        $('#main_container').load('components/users.html', open_for, function() {
	        	$('#open_for').val(open_for);
	        	getUsersFilterData(data);
	        });
		}		
	});

	$(document).off('click', '.delete_selected_filter').on('click', '.delete_selected_filter', function(){
		var id = $(this).attr('data-id');
		var type = $(this).attr('data-type');
		var field = $(this).attr('data-field');
		
		if(type == 'property') {
			$('.property_search_checkbox[value="'+id+'"]').prop('checked', false);
		} else if(type == 'tenant') {
			$('.tenant_search_checkbox[value="'+id+'"]').prop('checked', false);
		} else if(type == 'lease') {
			$('.lease_search_checkbox[value="'+id+'"]').prop('checked', false);
		} else if(type == 'transaction') {
			$('.transaction_search_checkbox[value="'+id+'"]').prop('checked', false);
		} else if(type == 'username') {
			$('.user_name_search_checkbox[value="'+id+'"]').prop('checked', false);
		} else if(type == 'useremail') {
			$('.user_email_search_checkbox[value="'+id+'"]').prop('checked', false);
		} else if(type == 'userphone') {
			$('.user_phone_search_checkbox[value="'+id+'"]').prop('checked', false);
		} else if(type == 'userstatus') {
			$('.user_status_search_checkbox[value="'+id+'"]').prop('checked', false);
		}

		// Remove clicked item and hidden input
		$(this).closest('span').remove();

		if(field == 'property') {
			// Get Selected proeprty data
		    var propertyIDs = "";
			$('.selected_property_data').each(function () {
				var value = $(this).val();
				propertyIDs += value + ",";
			});
			propertyIDs = propertyIDs.slice(0, -1);

			// Get Selected tenant data
			var tenantIDs = "";
			$('.selected_tenant_data').each(function () {
				var value = $(this).val();
				tenantIDs += value + ",";
			});
			tenantIDs = tenantIDs.slice(0, -1);

			// Get Selected lease data
		    var leaseIDs = "";
			$('.selected_lease_data').each(function () {
				var value = $(this).val();
				leaseIDs += value + ",";
			});
			leaseIDs = leaseIDs.slice(0, -1);

			// Get Selected transaction data
			var transactionIDs = "";
			$('.selected_transaction_data').each(function () {
				var value = $(this).val();
				transactionIDs += value + ",";
			});
			transactionIDs = transactionIDs.slice(0, -1);

			var data = 'propertyIDs='+propertyIDs+'&tenantIDs='+tenantIDs+'&leaseIDs='+leaseIDs+'&transactionIDs='+transactionIDs+'&user_id='+user.user_details.user_id+'&user_service_name='+user_service_name;

		    var open_for = 'search';
	        $('#main_container').load('components/properties.html', open_for, function() {
	        	$('#open_for').val(open_for);
	        	getPropertiesFilterData(data);
	        });
	    } else if(field == 'user') {
	    	// Get Selected user name
		    var userNameIDs = "";
			$('.selected_user_name_data').each(function () {
				var value = $(this).val();
				userNameIDs += value + ",";
			});
			userNameIDs = userNameIDs.slice(0, -1);

			// Get Selected user email
			var userEmailIDs = "";
			$('.selected_user_email_data').each(function () {
				var value = $(this).val();
				userEmailIDs += value + ",";
			});
			userEmailIDs = userEmailIDs.slice(0, -1);

			// Get Selected user phone
		    var userPhoneIDs = "";
			$('.selected_user_phone_data').each(function () {
				var value = $(this).val();
				userPhoneIDs += value + ",";
			});
			userPhoneIDs = userPhoneIDs.slice(0, -1);

			// Get Selected user status
		    var userStatusIDs = "";
			$('.selected_user_status_data').each(function () {
				var value = $(this).val();
				userStatusIDs += value + ",";
			});
			userStatusIDs = userStatusIDs.slice(0, -1);

			var data = 'userNameIDs='+userNameIDs+'&userEmailIDs='+userEmailIDs+'&userPhoneIDs='+userPhoneIDs+'&userStatusIDs='+userStatusIDs+'&user_id='+user.user_details.user_id+'&user_service_name='+user_service_name;

		    var open_for = 'search';
	        $('#main_container').load('components/users.html', open_for, function() {
	        	$('#open_for').val(open_for);
	        	getUsersFilterData(data);
	        });
	    }

        localStorage.setItem('search_fields', $('#filter_data_container').html());

        if($.trim($('#filter_data_container').html()) == '') {
        	$('#filter_data_container').removeClass('m-3');
        }

	});

	$(document).off('click', '.clear_search_property_data').on('click', '.clear_search_property_data', function() {
		$('#filter_data_container').html('');
		$('#filter_data_container').removeClass('m-3');
		$('.property_search_checkbox, .tenant_search_checkbox, .lease_search_checkbox, .transaction_search_checkbox, .user_name_search_checkbox, .user_email_search_checkbox, .user_phone_search_checkbox, .user_status_search_checkbox').prop('checked', false);
		
		var propertyIDs = '';
		var tenantIDs = '';
		var leaseIDs = '';
		var transactionIDs = '';
		var userNameIDs = '';
		var userEmailIDs = '';
		var userPhoneIDs = '';
		var userStatusIDs = '';
	    
	    localStorage.removeItem("filter_data");
	    localStorage.removeItem("search");
	    localStorage.removeItem('search_fields');

	    var click_from = $(this).attr('data-click');
	    if(click_from = 'form') {
	    	var html = '';
	    	html += '<a href="javascript:void(0)" class="list-group-item list-group-item-action border-0 bg-light text-left"><div class="fs-16">No Record Found</div></a>';
	    	$('#main_container').html(html);
	    }

	    $('#search_pin_unpin').attr('data-type', 'unpin');
		$('#search_pin_unpin').attr('src', 'icon/unpin.png');
		localStorage.setItem('search', 'unpin');
	});

	$(document).off('click', '.close_announcement').on('click', '.close_announcement', function() {
		$('#announcement-sticky-bar').addClass('d-none');
		$('#announcement-sticky-bar').html('');
		$('.wrapper').css('padding-top', '0px');
		$('.simplebar-content').css('padding-top', '0px');
		$('.sidebar-sticky .sidebar-content').css('top', 0);
		$('#assumed_user_email').css('top', '0.7%');
		localStorage.setItem("hide_announcement", 1);  //put the object back
	});

	var announcementStr = '';
	$("#announcement_text").each(function(){
        announcementStr = $(this).text();
        if($.trim(announcementStr).length > announcementStrWord){
            var newStr = announcementStr.substring(0, announcementStrWord);
            var removedStr = announcementStr.substring(announcementStrWord, $.trim(announcementStr).length);
            $(this).empty().html(newStr);
            //$(this).append(' <a href="javascript:void(0);" class="read-more black">read more...</a>');
        }
    });

    $(".read-more").click(function(){
    	var announcement_id = $(this).attr('data-id');
        $('#readMore').modal('show');
        openLoadingDialog($('#readMore .modal-body'));
        getAnnouncementDetail(announcement_id);        
    });

	$('body').on('click', function (e) {
	    $('[data-toggle=popover]').each(function () {
	        // hide any open popovers when the anywhere else in the body is clicked
	        if (!$(this).is(e.target) && $(this).has(e.target).length === 0 && $('.popover').has(e.target).length === 0) {
	            $(this).popover('hide');
	        }
	    });
	});
});

function reset_form() {
	$('#login-form').trigger("reset");
	$('#passcode-form').trigger("reset");
	$('#create-account-wizard').trigger("reset");
}

window.arrangeSeqNo = function() {
	var i=1;
	$('.recipients_data').each(function(){
		$(this).find("div.sequenceNo").html(i);
		$(this).find("input.sequenceNo").val(i);
		$(this).attr("id", 'recipients_'+i);

		$(this).find("input.action_input").attr('id', 'action_id'+i);
		$(this).find("input.sequenceNo").attr('id', 'seq_no'+i);

		$(this).find("select.role").attr('id', 'role'+i);
		//$(this).find("input.recipient_email").attr('id', 'recipient_email'+i);
		//$(this).find("input.recipient_name").attr('id', 'recipient_name'+i);

		$(this).find('a.addRowButton').attr("data-row-id", i);
		$(this).find('a.removeRowButton').attr("data-row-id", i);

		i++;
	});
}

window.getStateDropdownForSignup = function(selected_state = '') {
	$.ajax({
        method: 'GET',
        url: api_url+"states",
        success: function(response) {
            if(response.response_data.success == true) {
                var html = "<option value=''>State</option>";
                for (var key in response.states) {
	                if (response.states.hasOwnProperty(key)) {
	                	var selected = '';
	                	if(response.states[key].state_code == selected_state) { selected = 'selected'; }
				  		html += '<option value="'+response.states[key].state_code+'" '+selected+'>'+response.states[key].state_name+'</option>';
				  	}
				}
				$('#state').html(html);
				$('#property_state').html(html);
				$('#user_state').html(html);
				$('#inputState').html(html);
				$('#vendorState').html(html);
            } else {
            	display_error_message(response);
            }
        }
    });
}

window.getStateDropdown = function() {
	$.ajax({
        method: 'GET',
        url: api_url+"states/state2",
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
            if(response.response_data.success == true) {
                var html = "<option value=''>State</option>";
                for (var key in response.states) {
	                if (response.states.hasOwnProperty(key)) {
				  		html += '<option value="'+response.states[key].state_code+'">'+response.states[key].state_name+'</option>';
				  	}
				}
				$('#state').html(html);
				$('#property_state').html(html);
				//$('#add_property_state').html(html);
				//$('#edit_property_state').html(html);
				$('#user_state').html(html);
            } else {
            	display_error_message(response);
            }
        }
    });
}

window.completeRegistration = function() {
	var email = $('#email_address').val();
	var usersub = $('#usersub').val();

	var business_name = $('#business_name').val();
	var address_line1 = $('#address_line1').val();
	var address_line2 = $('#address_line2').val();
	var city = $('#city').val();
	var state = $('#state').val();
	var zip_code = $('#zip_code').val();
	var country = $('#country').val();

	var property_id = $('#property_id').val();
	var property_user_id = $('#property_user_id').val();
	var tenant_user_id = $('#tenant_user_id').val();
	var service_id = $('#create_account_service_input').val();
	var service_constant = $('#service_constant').val();

	// When tenants directly signup from our UI
	if(service_id == 2 && tenant_user_id == '') {
		var tenant_user_id = 0;
		var first_name = $('#property_owner_firstname').val();
		var last_name = $('#property_owner_lastname').val();
		var property_email = $('#property_owner_email_address').val();

		var address_line1 = $('#property_address_line1').val();
		var address_line2 = $('#property_address_line2').val();
		var city = $('#property_city').val();
		var state = $('#property_state').val();
		var zip_code = $('#property_zip_code').val();
		var country = $('#property_country').val();
	}
	
	var data = 'usersub='+usersub+'&email='+email+'&business_name='+business_name+'&address_line1='+address_line1+'&address_line2='+address_line2+'&city='+city+'&state='+state+'&zip_code='+zip_code+'&country='+country+'&service_id='+service_id+'&service_constant='+service_constant+'&property_user_id='+property_user_id+'&tenant_user_id='+tenant_user_id+'&property_id='+property_id+'&first_name='+first_name+'&last_name='+last_name+'&property_email='+property_email+'&wizard_name=signup&seq_no=5';

	$.ajax({
        method: 'POST',
        url: api_url+"auth/user-business",
        data: data
    }).then(function(response) {
    	display_error_message(response);
    	if(response.response_data.success == false) {
    		$('.complete_registration').attr('disabled', false);
    	} else {
    		// Checkilist step 5
    		getSignupNextStep(5,6);

    		$('.toolbar-bottom').hide();
    		$('#business_or_property_detail_container').remove();
    		openLoadingDialog($('#user_login_container'));
    		$('#user_login_container').load('components/login.html', function(){
    			//$(this).find('#login-form').addClass('popup-card-fixed-width');
    		});
    	}
    }, function(response) {
        // hang on this step if the error occur
    });
    return false;
}

window.verify_usps_address = function(type,address1,address2,city,state,zipcode) {
	var data = 'address1='+address1+'&address2='+address2+'&city='+city+'&state='+state+'&zipcode='+zipcode;
	if(type == 'signup') {
		$('.complete_registration').attr('disabled', true);
		$.ajax({
	        method: 'POST',
	        url: api_url+"properties/verify-address",
	        data: data,
            success: function(response) {
            	if(response.response_data.message != 'VERIFIED') {
            		$('.complete_registration').attr('disabled', false);
            		response.response_data.success = false;
	            	display_error_message(response);
	            }

	            if(response.response_data.success == true && response.response_data.message == 'VERIFIED') {
	                $('#address_line1').val(response.data.Address2);
	                if(typeof response.data.Address1 !== 'undefined') {
			   			$('#address_line2').val(response.data.Address1);
			   		}
			   		$('#city').val(response.data.City);
			   		$('#state').val(response.data.State);
			   		$('#zip_code').val(response.data.Zip5+'-'+response.data.Zip4);

			   		//if(typeof response.data.ReturnText === 'undefined' && response.response_data.message == 'VERIFIED') {
			   		if(response.response_data.message == 'VERIFIED') {
			   			completeRegistration();
			   		}
	            }
	        }
	    });
	} else if(type == 'kyc') {
		$('.submit_kyc_detail').attr('disabled', true);
		$.ajax({
	        method: 'POST',
	        url: api_url+"properties/verify-address",
	        data: data,
            success: function(response) {
            	if(response.response_data.message != 'VERIFIED') {
            		$('.submit_kyc_detail').attr('disabled', false);
            		response.response_data.success = false;
	            	display_error_message(response);
	            }

	            if(response.response_data.success == true && response.response_data.message == 'VERIFIED') {
	            	$('#user_address_line1').val(response.data.Address2);
	                if(typeof response.data.Address1 !== 'undefined') {
			   			$('#user_address_line2').val(response.data.Address1);
			   		}
			   		$('#user_city').val(response.data.City);
			   		$('#user_state').val(response.data.State);
			   		$('#user_zip_code').val(response.data.Zip5+'-'+response.data.Zip4);

			   		//if(typeof response.data.ReturnText === 'undefined' && response.response_data.message == 'VERIFIED') {
			   		if(response.response_data.message == 'VERIFIED') {
			   			submitKycDetail();
			   		}
	            }
	        }
	    });
	} else if(type == 'update_dwolla_customer') {
		$('.update_dwolla_customer').attr('disabled', true);
		$.ajax({
	        method: 'POST',
	        url: api_url+"properties/verify-address",
	        data: data,
            success: function(response) {
            	if(response.response_data.message != 'VERIFIED') {
            		$('.update_dwolla_customer').attr('disabled', false);
            		response.response_data.success = false;
	            	display_error_message(response);
	            }

	            if(response.response_data.success == true && response.response_data.message == 'VERIFIED') {
	            	$('#customer_address_line1').val(response.data.Address2);
	                if(typeof response.data.Address1 !== 'undefined') {
			   			$('#customer_address_line2').val(response.data.Address1);
			   		}
			   		$('#customer_city').val(response.data.City);
			   		$('#customer_state').val(response.data.State);
			   		$('#customer_zip_code').val(response.data.Zip5+'-'+response.data.Zip4);

			   		if(response.response_data.message == 'VERIFIED') {
			   			updateDwollaCustomer();
			   		}
	            }
	        }
	    });
	} else if(type == 'property_add') {
		$.ajax({
	        method: 'POST',
	        url: api_url+"properties/verify-address",
	        data: data,
	        beforeSend: function(xhr) {
		        xhr.setRequestHeader('Authorization', accessToken);
		    },
            success: function(response) {
	            if(response.response_data.message != 'VERIFIED') {
	            	display_error_message(response);
	            }
	            if(response.response_data.success == true/* && typeof response.data.ReturnText !== 'undefined'*/) {
	                
	                $('#add_property_address_line1').val(response.data.Address2);
	                if(typeof response.data.Address1 !== 'undefined') {
			   			$('#add_property_address_line2').val(response.data.Address1);
			   		}
			   		$('#add_property_city').val(response.data.City);
			   		$('#add_property_state').val(response.data.State);
			   		$('#add_property_zip_code').val(response.data.Zip5+'-'+response.data.Zip4);

			   		// typeof response.data.ReturnText === 'undefined' means response message is verified
			   		if(typeof response.data.ReturnText === 'undefined'/*&& response.response_data.message == 'VERIFIED'*/) {
			   			if(response.data.State == 'TX') {
			   				savePropertyData();
			   			} else {
			   				var response = {
		                        "response_data": {
		                            "success": false,
		                            "message": 'Address should be belongs to Texas state only'
		                        }
		                    };
		                    display_error_message(response);
			   			}
			   		}
	            }
	        }
	    });
	} else if(type == 'property_edit') {
		$.ajax({
	        method: 'POST',
	        url: api_url+"properties/verify-address",
	        data: data,
	        beforeSend: function(xhr) {
		        xhr.setRequestHeader('Authorization', accessToken);
		    },
            success: function(response) {
	            if(response.response_data.message != 'VERIFIED') {
	            	display_error_message(response);
	            }
	            if(response.response_data.success == true/* && typeof response.data.ReturnText !== 'undefined'*/) {
	                
	                $('#edit_property_address_line1').val(response.data.Address2);
	                if(typeof response.data.Address1 !== 'undefined') {
			   			$('#edit_property_address_line2').val(response.data.Address1);
			   		}
			   		$('#edit_property_city').val(response.data.City);
			   		$('#edit_property_state').val(response.data.State);
			   		$('#edit_property_zip_code').val(response.data.Zip5+'-'+response.data.Zip4);

			   		// typeof response.data.ReturnText === 'undefined' means response message is verified
			   		if(typeof response.data.ReturnText === 'undefined'/*&& response.response_data.message == 'VERIFIED'*/) {
			   			if(response.data.State == 'TX') {
			   				updatePropertyAddress();
			   			} else {
			   				var response = {
		                        "response_data": {
		                            "success": false,
		                            "message": 'Address should be belongs to Texas state only'
		                        }
		                    };
		                    display_error_message(response);
			   			}
			   		}
	            }
	        }
	    });
	} else if(type == 'property_primary_contact') {
		var property_id = $('#selected_property_id').val();
		$.ajax({
	        method: 'POST',
	        url: api_url+"properties/verify-address",
	        data: data,
	        beforeSend: function(xhr) {
		        xhr.setRequestHeader('Authorization', accessToken);
		    },
            success: function(response) {
	            if(response.response_data.message != 'VERIFIED') {
	            	display_error_message(response);
	            }
	            if(response.response_data.success == true/* && typeof response.data.ReturnText !== 'undefined'*/) {
	                
	                $('#primary_contact_address_line1').val(response.data.Address2);
	                if(typeof response.data.Address1 !== 'undefined') {
			   			$('#primary_contact_address_line2').val(response.data.Address1);
			   		}
			   		$('#primary_contact_city').val(response.data.City);
			   		$('#primary_contact_state').val(response.data.State);
			   		$('#primary_contact_zip_code').val(response.data.Zip5+'-'+response.data.Zip4);

			   		// typeof response.data.ReturnText === 'undefined' means response message is verified
			   		if(typeof response.data.ReturnText === 'undefined'/*&& response.response_data.message == 'VERIFIED'*/) {
			   			savePropertyPrimaryContactDetail(property_id);	
			   		}
	            }
	        }
	    });
	} else if(type == 'edit_address') {
		$.ajax({
	        method: 'POST',
	        url: api_url+"properties/verify-address",
	        data: data,
	        beforeSend: function(xhr) {
		        xhr.setRequestHeader('Authorization', accessToken);
		    },
            success: function(response) {
	            if(response.response_data.message != 'VERIFIED') {
	            	display_error_message(response);
	            }
	            if(response.response_data.success == true/* && typeof response.data.ReturnText !== 'undefined'*/) {
	                
	                $('#address_line1').val(response.data.Address2);
	                if(typeof response.data.Address1 !== 'undefined') {
			   			$('#address_line2').val(response.data.Address1);
			   		}
			   		$('#city').val(response.data.City);
			   		$('#state').val(response.data.State);
			   		$('#zip_code').val(response.data.Zip5+'-'+response.data.Zip4);

			   		// typeof response.data.ReturnText === 'undefined' means response message is verified
			   		if(typeof response.data.ReturnText === 'undefined'/*&& response.response_data.message == 'VERIFIED'*/) {
			   			if(response.data.State == 'TX') {
			   				updateAddress();
			   			} else {
			   				var response = {
		                        "response_data": {
		                            "success": false,
		                            "message": 'Address should be belongs to Texas state only'
		                        }
		                    };
		                    display_error_message(response);
			   			}
			   		}
	            }
	        }
	    });
	}
}

/*window.create_websocket_connection = function() {
	if (window.WebSocket === undefined) {
        console.log('Socket not supported');
    } else {
        var user_id = user.user_details.user_id;
        var websocket = new WebSocket(WEBSOCKET_URL+"?user_id="+user_id);
        websocket.onopen = function (event) {
		    console.log('connection established.');
		};
		websocket.onmessage = function (event) {
			//console.log(event.data);
		    console.log('Websocket message received.');
		    var data = JSON.parse(event.data);
			var chatroom_id = $('#chatroom_id').val();
			if(chatroom_id == data.chatroom_id) {
				var html = '<div class="'+data.align_class+' pb-4"> <div> <img src="icon/avatar.jpg" class="rounded-circle mr-1" alt="Chris Wood" width="40" height="40"> <div class="text-muted small text-nowrap mt-2">&nbsp;</div> </div> <div class="flex-shrink-1 bg-light rounded py-2 px-3 mr-3"> <div class="font-weight-bold mb-1">'+data.from+'</div>'+data.message+' <div class="text-muted small text-nowrap mt-2">'+data.created+'</div></div></div>';
				$('#chat-messages').append(html);
				$('#chat-messages').scrollTop($('#chat-messages')[0].scrollHeight);
			}
		};
    }
}*/

window.initDatatable = function(tableId) {
	var column = [];
	if(tableId == 'ticket-datatable') {
		column = [
            { className: 'all', targets: [0], width:'5%', orderable: true},
            { className: 'all', targets: [1], width:'20%', orderable: true},
            { targets: [2], width:'15%', orderable: true},
            { targets: [3], width:'15%', orderable: true},
            { targets: [4], width:'15%', orderable: true},
            { className: 'text-right', targets: [5], width:'15%', orderable: true},
            { targets: [6], width:'10%', orderable: false},
            { targets: [7], width:'10%', orderable: false}
        ]; 
	} else if(tableId == 'lease-datatable') {
		column = [
            { className: 'all', targets: [0], orderable: true},
            { className: 'all', targets: [1], orderable: true},
            { className: 'text-right', targets: [2], orderable: true},
            { className: 'text-right', targets: [3], orderable: true},
            { targets: [4], orderable: true},
            { targets: [5], orderable: true},
            { targets: [6], orderable: true},
            { targets: [7], orderable: false},
            { targets: [8], orderable: false}
        ]; 
	} else if(tableId == 'existing-tenant-datatable') {
		column = [
            { className: 'all', targets: [0], width:'5%', orderable: false},
            { className: 'all', targets: [1], width:'20%', orderable: true},
            { className: 'all', targets: [2], width:'20%', orderable: true},
            { targets: [3], width:'45%', orderable: true},
            { targets: [4], width:'10%', orderable: true},
        ]; 
	} 

	var table = $("#"+tableId).DataTable({
	    responsive: true,
	    info: false,
	    lengthChange: false,
	    searching: false,
	    columnDefs: column,
	    order: []
	});
	return table;
}

window.drag_drop_reorder = function(tableId) {
	// Drag & Drop ordering update
    $('.'+tableId).sortable({
        cursor: 'move',
        update: function (event, ui) {
            var data = $(this).sortable('serialize');
            // POST to server using $.post or $.ajax
            $.ajax({
            	url: api_url+"user-templates/order",
                data: data,
                type: 'POST',
                dataType: 'json',
                beforeSend: function(xhr) {
			        xhr.setRequestHeader('Authorization', accessToken);
			    },
                success: function(response) {
                    display_error_message(response);
			        if(response.response_data.success == true) {
			            document_datatable.clear().draw();
			            var user_id = $('#selected_user_id').val();
			            getUserZohoDocumentList(user_id);
			        }
                }
            });
        }
    });
}

window.getServices = function(service_id, type) {
	$.ajax({
        method: 'GET',
	    url: api_url+"services",
        success: function(response) {
        	if(response.response_data.success == false) {
	            display_api_error(response);
	        } else {
	            var html = '';
	            if(type == 'contract') {
            		html += '<select name="contract_service_id"  id="contract_service_id" class="form-control">';
            	} else if(type == 'agreement') {
            		html += '<select name="agreement_service_id"  id="agreement_service_id" class="form-control">';
            	}
	            	for (var key in response.services) {
	                	if (response.services.hasOwnProperty(key)) {
	                		if(response.services[key].constant_name == '1dc82947-e650-494a-b798-986a6f701d1f' || response.services[key].constant_name == 'c963a422-edb4-4bad-97ed-276c5abb3b79') {
	                			var selected = '';
			                	if(service_id == response.services[key].id) {
			                		selected = "selected";
			                	}
		                		html += '<option value="'+response.services[key].id+'" '+selected+'>'+response.services[key].service_name+'</option>';
		                	}
						}
					}
				html += '</select>';
				$('#document_type_container').html(html);
	        }
        }
    });
}

window.loadServiceData = function() {

	// Below if condition will check when tenant signup using invitation URL
	var invitation_code = getUrlParameter('invitation_code');
	if (typeof invitation_code !== "undefined") {
		$('.open_signup_modal').trigger('click');
		$('#user-service-form').addClass('d-none');
		$('#service_container').addClass('d-none');
		$('#signup-form').removeClass('d-none');
		$('#signup-step-4').text('Property Owner Detail');
		getInvitedTenantDetail(invitation_code);
	} else {
	
		// Get Active Roles
	    $.ajax({
	        method: 'GET',
	        url: api_url+"services",
	    }).then(function(response) {
	        if(response.response_data.success == false) {
	            display_api_error(response);
	        } else {
	            var html = "";
	            var cls = '';
	            var btn_class = '';
	            for (var key in response.services) {
	                if (response.services.hasOwnProperty(key)) {
	                	if(response.services[key].status == 1) {
		                    btn_class = 'create_account_service_card';
		                	if(response.services[key].constant_name == t_role) {
		                        cls = 'tenant_card';
		                    } else {
		                        cls = "other_card";
		                    }

		                    html += '<div class="col-12 col-md-6 col-lg-6"><div class="card '+cls+'"><div class="card-header px-4 pt-4 text-center"><h4>'+response.services[key].service_name+'</h4></div><div class="card-body px-4 pt-4">'+response.services[key].description+'</div><ul class="list-group list-group-flush"><li class="list-group-item px-3 pb-3 text-center"><button data-checklist-step="1" data-service-id="'+response.services[key].id+'" data-service-name="'+response.services[key].service_name+'" data-service-constant="'+response.services[key].constant_name+'" type="button" class="btn btn-orange '+btn_class+'">Sign Up as a <br> '+response.services[key].service_name+'</button></li></ul></div></div>';
		                }
	                }
	            }
	            $('#service_container').html(html);
	        }
	    }, function() {
	        // hang on this step if the error occur
	    });
	}
}

window.getUserIpAddress = function() {
	$.ajax({
        method: 'POST',
        url: api_url+"users/get-ip-address",
        success: function(response) {
        	//console.log(response);
        	localStorage.setItem('ip_address', response.source_ip);
        	window.ip_address = localStorage.getItem('ip_address');
        }
    });
}

window.loadChecklistData = function(name,user_type) {
	$.ajax({
        method: 'POST',
        url: api_url+"checklists",
        data:'name='+name+'&user_type='+user_type,
        success: function(response) {
        	if(response.response_data.success == false) {
	            display_api_error(response);
	        } else {
	        	$('#signup_total_steps').html(response.totalChecklists);
	            var html = "";
	            var pb_class = 'pb-4';
	            html += '<ul class="timeline mt-4 mb-4 ml-3">';
	            for (var key in response.checklists) {
	                if (response.checklists.hasOwnProperty(key)) {
	                	var cnt = parseInt(key)+1;
	                	if(response.totalChecklists == cnt) {
	                		pb_class = '';
	                	}
	                	html += '<li id="signup_checkilist_step_'+response.checklists[key].seq_no+'" class="timeline-item '+pb_class+'">';
	                		html += '<strong>'+response.checklists[key].steps+'</strong>';
	                	html += '</li>';
	                }
	            }
	            html += '</ul>';
	            $('#signup-checklist-steps').html(html);
	            $('#signup_step_name').html($('#signup_checkilist_step_1').html());
	            $('#signup_checkilist_step_1').addClass('orange');

	            // Completed checklist steps
	            if(localStorage.getItem("user") !== null) {
		            if(user_service_name == t_role && user.user_details.cheklist_seq_no == 3) {
		            	var checklist_completed_seq = user.user_details.cheklist_seq_no + 1;
		            } else {
		            	var checklist_completed_seq = user.user_details.cheklist_seq_no;	
		            }
		            
		            var i;
					for (i = 1; i <= checklist_completed_seq; i++) {
					  	$('head').append('<style>#signup_checkilist_step_'+i+':before{background:green !important;}</style>');
					  	$('#signup_checkilist_step_'+i).removeClass('orange');
					}
					var current_step = checklist_completed_seq + 1;
					$('#signup_step_name').html($('#signup_checkilist_step_'+current_step).html());
					$('#signup_completed_steps').html(parseInt($('#signup_completed_steps').html()) + checklist_completed_seq);

					// Add orange class to current step
					var current_step = checklist_completed_seq + 1;
				    $('#signup_checkilist_step_'+current_step).addClass('orange');

				    // Reset green and orange color
				    if(name == 'rs_invite_tenant') {
				    	var i;
						for (i = 1; i <= response.totalChecklists; i++) {
						  	$('head').append('<style>#signup_checkilist_step_'+i+':before{background:white !important;}</style>');
						  	$('#signup_checkilist_step_'+i).removeClass('orange');
						}
				    }
				}
	        }
        }
    });
}

window.getAssumedUserData = function(type, email, service_id = 0) {
	var user_id = user.user_details.user_id;
	$.ajax({
	    method: 'POST',
	    url: api_url+"auth/get-assumed-user-data",
		data: 'email='+email+'&user_id='+user_id+'&service_id='+service_id+'&type='+type,
	    beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
	    success: function(response) {
	        if(response.response_data.success == true) {
	        	if(type == 'add' || type == 'linked_service') {
	        		localStorage.setItem('primary', user_email);
	        	} else if(type == 'remove') {
	        		localStorage.removeItem("primary");
	        	}

	    		var user = JSON.parse(localStorage.user);
			   	user.user_details = response.user_details;
			   	user.permissionData = response.permissionData;
			   	user.leaseInfo = response.leaseInfo;
			   	user.announcementInfo = response.announcementInfo;
				localStorage.setItem("user", JSON.stringify(user));  //put the object back
	    		localStorage.setItem('page_name', 'dashboard');
	    		
	    		window.user = JSON.parse(localStorage.getItem('user'));
				window.status_code = user.user_details.status_code;
				window.user_service_name = $.trim(user.user_details.service_constant);
	    		if(user_service_name == po_role || user_service_name == t_role) {
					var checklist_seq_no = user.user_details.cheklist_seq_no;
					var total_steps = user.user_details.total_steps;
					if((checklist_seq_no < total_steps) && status_code == 1 && (user_service_name == po_role || user_service_name == t_role)) {
						window.location.href = site_url+'dashboard.html';	
					} else {
						window.location.href = site_url+'dashboard.html?action=properties';
					}
				} else {
					window.location.href = site_url+'dashboard.html?action=properties';	
				}
	        } else {
	        	display_error_message(response);
	        }
	    }
	});
}

window.loadUserDetail = function(user_id) {
	$.ajax({
	    method: 'POST',
	    url: api_url+"users/details",
	    data: 'user_id='+user_id,
	    beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
	    success: function(response) {
	    	if(response.response_data.success == true) {
		        localStorage.setItem('user_address', JSON.stringify(response));
		    }
	    }
	});
}

window.loadLeftMenu = function() {
	$('#navbar_role_name').html(humanize(current_page));
	$('#logged_in_as').html('<i class="far fa-user mr-1"></i> Logged in as : <span class="orange">'+user.user_details.user_type+'</span>');
	$('#profile_email').html('<i class="far fa-envelope mr-1"></i> '+user.user_details.email);
	
	// Sidebar menu api
	getMenuItem();

	var page_name = getUrlParameter('action');
	if (typeof page_name !== "undefined") {
		load_selected_page(page_name);
	}/* else {
		load_selected_page('home', 'loadLeftMenu');
	}*/	
}

window.getMenuItem = function() {
	var html = "";
    html += '<div class="sidebar-content" data-simplebar><a class="sidebar-brand" href="index.html"><span class="align-middle"><img src="icon/logo1.PNG" height="50px" /></span> </a><ul class="sidebar-nav">';
    	for (var key in user.permissionData) {
        	if (user.permissionData.hasOwnProperty(key)) {
        		var action = user.permissionData[key].action;
        		var page_class = action.split('.').slice(0, -1).join('.');

        		/*var add_opacity = 'add_opacity';
        		if(page_class == 'dashboard' || page_class == 'users' || page_class == 'properties' || page_class == 'support' || page_class == 'dwolla-webhook' || page_class == 'template' || page_class == 'plan-subscriptions' || page_class == 'activities' || page_class == 'permissions' || page_class == 'dwolla-fund-transfer') {
        			add_opacity = '';

        			html += '<li data-page-name="'+page_class+'" class="sidebar-item '+add_opacity+'"><a href="javascript:void(0)" class="sidebar-link"> <img src="'+user.permissionData[key].icon+'"/> <span class="align-middle">'+user.permissionData[key].name+'</span></a></li>';
        		}*/

        		if(page_class != 'dashboard') {
        			html += '<li data-page-name="'+page_class+'" class="sidebar-item"><a href="javascript:void(0)" class="sidebar-link"> <img src="'+user.permissionData[key].icon+'"/> <span class="align-middle">'+user.permissionData[key].name+'</span></a></li>';
        		}
            }
        }
    html += '</ul>';

    var d = new Date();
	var current_year = d.getFullYear();

    html += '<footer id="footer" class="footer">';
        html += '<div class="row text-muted">';
		    html += '<div class="col-12 text-left">';
		        html += '<ul class="list-inline text-left">';
		            html += '<li class="list-inline-item"> <a class="text-muted" href="privacy.html" target="_blank">Privacy</a> </li>';
		            html += '<li class="list-inline-item"> <a class="text-muted" href="tos.html" target="_blank">Terms</a> </li>';
		            html += '<li class="list-inline-item">  &copy; <span class="footer_year">'+current_year+'</span> </li>';
		        html += '</ul>';
		    html += '</div>';
		html += '</div>';
	html += '</footer>';

    html += '</div>';
    $('#sidebar').html(html);
}

window.loadDashboardPage = function() {
	if(user_service_name == po_role || user_service_name == t_role) {
		var user_id = user.user_details.user_id;
		var checklist_seq_no = user.user_details.cheklist_seq_no;
		var total_steps = user.user_details.total_steps;
		var first_property_id = user.user_details.first_property_id;
		var first_property_name = user.user_details.first_property_name;
		$('#first_property_id').val(first_property_id);
		$('#first_property_name').val(first_property_name);
		if((checklist_seq_no < total_steps) && status_code == 1 && user_service_name == po_role) {
			$('.sidebar-toggle').hide();
			$('#user_my_account').hide();
			$('.hamburger').addClass('d-none');
			$('#sidebar').addClass('d-none');
			$('#notification_section').addClass('d-none');

			//$('#payment_form_container').removeClass('d-none');
			//checkKycDetailStatusAtDashboard();

			//$('#first_time_login_checklist_container').removeClass('d-none');
			$('#main_container').load('components/first_time_signup_checklist_wizard.html', function(){

				loadChecklistData('signup', po_role);

				$('#first_time_signup').val(1);
				if(checklist_seq_no == 6 || checklist_seq_no == 7) {
					$('#signup_data_container').load('components/bank/add_bank.html', function(){
						$('.card-header').remove();
						$('#formAccordion .card').removeClass('border');
						if(checklist_seq_no == 6) {
							$('#payment-detail-wizard').addClass('d-none');
							$('#kyc-detail-wizard').removeClass('d-none');
						} else if(checklist_seq_no == 7) {
							$('#kyc-detail-wizard').addClass('d-none');
							$('#payment-detail-wizard').removeClass('d-none');
							$('.skip_payment').addClass('d-none');
						}
						getStateDropdown();
					});
				} else if(checklist_seq_no == 8) {
					openLoadingDialog($('#signup_data_container'));
					$('#signup_data_container').load('components/property/add_new_property.html', function(){
						$('#signup_data_container .card .fa-window-close').remove();
						//$('#signup_data_container .card').removeClass('popup-card-fixed-width');
						$('.save_property_data').text('Next');
					});
				} else if(checklist_seq_no == 9) {
					openLoadingDialog($('#signup_data_container'));
					checkIfPropertySetupPlanAvailable(first_property_id);
				} else if(checklist_seq_no == 10) {
					openLoadingDialog($('#signup_data_container'));
					$('#signup_data_container').load('components/property/add_new_tenant.html', function(){
						$('.card-header').remove();
						$('#signup_data_container .card').removeClass('border');
						$('.save_tenant_data').remove();
						$('.save_and_submit_tenant_data').removeClass('d-none');
						$('.save_and_submit_tenant_data').text('Next');
					});
				} else if(checklist_seq_no == 11) {
					openLoadingDialog($('#signup_data_container'));
					$('#signup_data_container').load('components/property/add_new_lease.html', function(){
						$('.card-header').remove();
						$('#signup_data_container .card').removeClass('border');
						$('.save_lease_data').text('Next');
					});
				}  else if(checklist_seq_no == 12) {
					openLoadingDialog($('#signup_data_container'));

	        		// Display Rent Deposit and Subscription Fee Payment Authorization Model for Linking payment method
        			setTimeout(function(){
						getBankListForProperty(user_id, first_property_id, first_property_name);
					}, 500);
				}

				$('.search-toggle').addClass('d-none');
			});

		} else if((checklist_seq_no < total_steps) && status_code == 1 && user_service_name == t_role) {
			$('.sidebar-toggle').hide();
			$('#user_my_account').hide();
			$('.hamburger').addClass('d-none');
			$('#sidebar').addClass('d-none');
			$('#notification_section').addClass('d-none');
			
			//$('#payment_form_container').removeClass('d-none');
			//checkKycDetailStatusAtDashboard();

			//$('#first_time_login_checklist_container').removeClass('d-none');
			$('#main_container').load('components/first_time_signup_checklist_wizard.html', function(){

				loadChecklistData('signup', t_role);

				$('#first_time_signup').val(1);
				if(checklist_seq_no == 3 || checklist_seq_no == 4 || checklist_seq_no == 5) {
					$('#signup_data_container').load('components/bank/add_bank.html', function(){
						$('.card-header').remove();
						$('#formAccordion .card').removeClass('border');
						if(checklist_seq_no == 3 || checklist_seq_no == 4) {
							$('#payment-detail-wizard').addClass('d-none');
							$('#kyc-detail-wizard').removeClass('d-none');
						} else if(checklist_seq_no == 5) {
							$('#kyc-detail-wizard').addClass('d-none');
							$('#payment-detail-wizard').removeClass('d-none');
							$('.skip_payment').addClass('d-none');
						}
						getStateDropdown();
					});
				} else if(checklist_seq_no == 6) { // Sign lease
					openLoadingDialog($('#signup_data_container'));
					getZohoSignUrl(user.user_details.user_id);
				} else if(checklist_seq_no == 7) { // First month payment authorization
					openLoadingDialog($('#signup_data_container'));
					var first_property_id = 0;
					var lease_id = 0;
					getFMPaymentDetail(user.user_details.user_id,first_property_id,lease_id);
				}

				$('.search-toggle').addClass('d-none');
			});

		} else {
			$('.sidebar-toggle').show();
			$('#user_my_account').show();
			$('.hamburger').removeClass('d-none');
			$('#sidebar').removeClass('d-none');
			$('#notification_section').removeClass('d-none');
			
			// Get Bank Detail
			if(user.permissionData['DASHBOARD']["feature_items['verify_bank']"]) {
				$('#unverified_bank_section').removeClass('d-none');
				$.ajax({
			        method: 'POST',
			        url: api_url+"users/details",
		    		data: 'user_id='+user.user_details.user_id,
			        beforeSend: function(xhr) {
				        xhr.setRequestHeader('Authorization', accessToken);
				    },
			        success: function(response) {
			            if(response.response_data.success == true) {
			        		var html = "";
			        		var notification_html = "";
					        if(response.userPaymentData == 0) {
					        	//html += '<a href="javascript:void(0)" class="list-group-item list-group-item-action border-0"><div class="fs-16">'+response.response_data.message+'</div></a>';
						    } else {
						    	for (var key in response.userPaymentData) {
						            if (response.userPaymentData.hasOwnProperty(key)) {

						            	var status_class = 'red';
						            	var display = '';
						            	if(response.userPaymentData[key].status == 2) {
						            		status_class = 'green';
						            		display = 'none';
						            	}

						            	/*html += '<div id="bank_card_'+response.userPaymentData[key].id+'" class="col-12 col-md-6 col-xl-3 unverified_bank property_card">';
											html += '<div class="card">';
												html += '<div class="card-header bg-light"><h5 class="card-title capitalize mb-0 mr-3">'+response.userPaymentData[key].bank_name+'</h5></div>';
												html += '<div class="text-center"><img src="img/sample/bank.jpg" height="100" width="100" alt="Unsplash"></div>';
												html += '<div class="card-body">';
													html += '<table class="table mb-0 card-table word-break">';
														html += '<tbody>';
															html += '<tr>';
																html += '<td class="nowrap">Account Number</td>';
																html += '<td class="text-right">'+response.userPaymentData[key].account_number+'</td>';
															html += '</tr>';
															html += '<tr>';
																html += '<td class="nowrap">Account Type</td>';
																html += '<td class="text-right">'+response.userPaymentData[key].account_type+'</td>';
															html += '</tr>';
															html += '<tr>';
																html += '<td class="nowrap">Status</td>';
																html += '<td class="text-right"><strong><span class="'+status_class+'">'+response.userPaymentData[key].status_label+'</span></strong></td>';
															html += '</tr>';
														html += '</tbody>';
													html += '</table>';
													html += '<div class="text-right mt-4">';
								                    	if(response.userPaymentData[key].status == 1) {
								                        	html += '<button data-id='+response.userPaymentData[key].id+' type="button" class="btn btn-orange verify_bank_detail" data-toggle="modal" data-target="#microDepositModal" data-backdrop="static" data-keyboard="false" style="display:'+display+'">Verify Bank Detail</button>';
								                        }
								                    html += '</div>';
												html += '</div>';
											html += '</div>';
										html += '</div>';*/

										notification_html += '<div id="bank_card_'+response.userPaymentData[key].id+'" class="list-group-item unverified_bank">';
										    notification_html += '<div class="row no-gutters align-items-center">';
										        notification_html += '<div class="col-2"> <img src="icon/notification/bank.svg" class="filter-7"> </div>';
										        notification_html += '<div class="col-10">';
										            notification_html += '<div class="text-dark">'+response.userPaymentData[key].bank_name+'</div>';
										            notification_html += '<div class="text-muted small mt-1">'+response.userPaymentData[key].account_number+'-'+response.userPaymentData[key].account_type+'</div>';
										            notification_html += '<div class="text-muted mb-2 mt-1 '+status_class+'">'+response.userPaymentData[key].status_label+'</div>';
										            if(response.userPaymentData[key].status == 1) {
										            	notification_html += '<button data-id='+response.userPaymentData[key].id+' type="button" class="btn btn-orange verify_bank_detail" data-toggle="modal" data-target="#microDepositModal" data-backdrop="static" data-keyboard="false" style="display:'+display+'">Verify Bank Detail</button>';
										        	}
										        notification_html += '</div>';
										    notification_html += '</div>';
										notification_html += '</div>';
						            }
						        }
						    }
					        //$('#unverified_bank_section').html(html);
					        $('#notification_container').append(notification_html);
			            } else {
			            	display_error_message(response);
			            }
			        }
			    });
			}

			// Get Upcoming Payment Detail && Review and Authorize first month payment
			if(user_service_name == t_role && user.permissionData['DASHBOARD']["feature_items['authorize_rent_payment']"]) {
				$('#upcoming_payment_section').removeClass('d-none');
				getUpcomingPaymentDetail();

				$('#review_first_payment_section').removeClass('d-none');
				getReviewFirstMonthPaymentDetail();
			}

			// Get subscription fee failed transaction
			if(user_service_name == po_role) {
				getSubscriptionFeeFailedTransaction();
			}

			getUserLeaseInfo();
			//getPendingReviewContract();
			getPendingReviewDocument();
			
			$('.search-toggle').removeClass('d-none');
		}
	} else {
		$('.sidebar-toggle').show();
		$('.hamburger').removeClass('d-none');
		$('#sidebar').removeClass('d-none');
		$('#notification_section').removeClass('d-none');
		setTimeout(function(){
        	$('.search-toggle').removeClass('d-none');
		}, 5000);
	}

	//getPendingSignatureContract();
	getPendingSignatureDocument();
	if(localStorage.getItem("hide_announcement") != '1') {
		getAnnouncement();
	}
}

window.getPendingUserList = function(layout) {
	if(user.permissionData['USERS']["feature_items['list']"]) {
	    $.ajax({
		    method: 'GET',
		    url: api_url+"users/applications",
		    beforeSend: function(xhr) {
		        xhr.setRequestHeader('Authorization', accessToken);
		    },
		}).then(function(response) {
		    if(response.response_data.success == true) {
		    	loadUserCard(layout, response);		        
		    } else {
		        
		    }
		}, function() {
		    // hang on this step if the error occur
		});
	}
}

window.loadUserCard = function(layout, response) {
	var html = "";
	if(response.TotalUsers == 0) {
		html += '<a href="javascript:void(0)" class="list-group-item list-group-item-action border-0 bg-light text-left"><div class="fs-16">'+response.response_data.message+'</div></a>';
	} else {
	    for (var key in response.users) {
	        if (response.users.hasOwnProperty(key)) {
	            var username = response.users[key].first_name+' '+response.users[key].last_name;
	            var email = response.users[key].email;
	            var phone = response.users[key].phone;
	            var address_line1 = "";
		        var address_line2 = "";
		        var city = "";
		        var state = "";
		        var zip_code = "";

		        if(response.users[key].UserService != null) {
			        if(response.users[key].UserService.address != null) {
			        	if(response.users[key].UserService.address.address_line1 != "") {
			                address_line1 = response.users[key].UserService.address.address_line1;
			                address_line1 = address_line1.toLowerCase();
			            }
			            if(response.users[key].UserService.address.address_line2 != "") {
			                address_line2 = response.users[key].UserService.address.address_line2
			                address_line2 = ', '+address_line2.toLowerCase();
			            }
			            if(response.users[key].UserService.address.city != "") {
			                city = response.users[key].UserService.address.city;
			                city = city.toLowerCase();
			            }
			            if(response.users[key].UserService.address.state != "") {
			                state = response.users[key].UserService.address.state;
			                state = state.toUpperCase();
			            }
			            if(response.users[key].UserService.address.zip_code != "") {
			                zip_code = response.users[key].UserService.address.zip_code;
			            }
			        }
			    }

			    var user_type = '';
			    var type = '';
			    var user_bg_class = '';
			    if(response.users[key].user_service_name == po_role) {
		        	user_bg_class = 'po_bg';
		        	type = 'PO';
		        } else if(response.users[key].user_service_name == t_role) {
		        	user_bg_class = 't_bg';
		        	type = 'T';
		        } else {
		        	user_bg_class = 'a_bg';
		        	type = 'A';
		        }

		        var phone_verified = '<span class="red">Unverified</span>';
		        var email_verified = '<span class="red">Unverified</span>';
		        if(response.users[key].phone_verified == 1) {
		        	phone_verified = '<span class="green">Verified</span>';
		        } 
		        if(response.users[key].email_verified == 1) {
		        	email_verified = '<span class="green">Verified</span>';
		        }

		        var status = '';
		        if(response.users[key].status == 0) {
	            	status += '<span class="badge badge-info">'+response.users[key].status_label+'</span>';
	            } else if(response.users[key].status == 1) {
	            	status += '<span class="badge badge-success">'+response.users[key].status_label+'</span>';
	            } else if(response.users[key].status == 2) {
	            	status += '<span class="badge badge-warning">'+response.users[key].status_label+'</span>';
	            } else if(response.users[key].status == 5) {
	            	status += '<span class="badge badge-danger">'+response.users[key].status_label+'</span>';
	            }

	            var user_delete_btn = '';
	            /*if(allow_ai_access == 1) {
	            	user_delete_btn = '<a class="dropdown-item delete_user" href="javascript:void(0)" data-id="'+response.users[key].id+'" data-type="'+type+'">Delete</a>';
	            }*/
	            
	            var kebab = '<div class="text-center"><div class="input-group-prepend"><i class="align-middle mr-2 fas fa-fw fa-ellipsis-v" data-toggle="dropdown" aria-expanded="false"></i><div class="dropdown-menu dropdown-menu-right" style=""><a class="dropdown-item pending_verification_user" href="javascript:void(0)" data-id="'+response.users[key].id+'" data-type="'+type+'">Detail</a>'+user_delete_btn+'</div></div></div>';

	            if(layout == 'table_layout') {
					html += '<tr data-id="'+response.users[key].id+'" data-type="'+type+'" class="pending_verification_user">';
	            		html += '<td class="bg-light">'+response.users[key].id+'</td>';
	            		html += '<td class="bg-light">'+username+'</td>';
	                    html += '<td>'+email+'</td>';
	                    html += '<td>'+email_verified+'</td>';
	                    html += '<td class="phone_number">'+phone+'</td>';
	                    html += '<td>'+phone_verified+'</td>';
	                    html += '<td><div class="capitalize">'+address_line1+address_line2+','+city+','+state+' '+zip_code+'</div></td>';
	                    html += '<td>'+type+'</td>';
	                    html += '<td>'+status+'</td>';
	                    html += '<td>'+kebab+'</td>';
	            	html += '</tr>';
				} else {
			        html += '<div data-id="'+response.users[key].id+'" data-type="'+type+'" class="col-12 col-md-6 col-xl-3 pending_verification_user">';
			        	html += '<div class="card">';
			        		html += '<div class="card-header"><span class="accordion_arrow">'+kebab+' </span><h5 id="u_name_'+response.users[key].id+'" class="card-title capitalize mb-0 mr-3">'+username+'</h5></div>';
			        		html += '<div class="'+user_bg_class+'">';
			        				html += '<div class="circle"><p class="h1 text-center grey">'+type+'</p></div>';
			        		html += '</div>';
			        		html += '<div class="card-body">';
			        			html += '<table class="table mb-0 card-table word-break">';
									html += '<tbody>';
										html += '<tr>';
											html += '<td><i class="far fa-envelope mr-1"></i></td>';
											html += '<td class="text-left bold-black">'+email+'<br>'+email_verified+'</td>';
										html += '</tr>';
										html += '<tr>';
										if(phone != null && phone != '') {
											phone = phone.split('+1');
		        							phone = phone[1];
											html += '<td><i class="fas fa-phone mr-1"></i></td>';
											html += '<td class="text-left"><span class="phone_number">'+phone+'</span><br>'+phone_verified+'</td>';
										} else {
											html += '<td>&nbsp;</td>';
											html += '<td class="text-left">&nbsp;<br>&nbsp;</td>';
										}
										html += '</tr>';
										html += '<tr>';
											if(response.users[key].UserService != null && response.users[key].UserService.address != null) {
												html += '<td><i class="far fa-address-card mr-1"></i></td>';
												html += '<td class="text-left"><div class="card-text capitalize">'+address_line1+address_line2+'<br>'+city+', '+state+' '+zip_code+'</div></td>';
											} else {
												html += '<td>&nbsp;</td>';
												html += '<td class="text-left"><div class="card-text capitalize">&nbsp;</div></td>';
											}
										html += '</tr>';
									html += '</tbody>';
								html += '</table>';
								html += '<div class="text-right mt-4">'+status+'</div>';
			        		html += '</div>';
			        	html += '</div>';
			        html += '</div>';			        
			    }
	        }
	    }
	}

	if(layout == 'table_layout') {
		$('#user_table_row').html(html);
	    var user_table = $("#user-datatable").DataTable({
	    	dom: 'Bfrtip',
			autoWidth: true,
	    	paging: false,
	    	info: false,
		    scrollY: '650px',
		    scrollX: true,
	        scrollCollapse: true,
	        order: [[0,'desc']],
	        fixedColumns:   {
	            left: 2
	        },
	        buttons: [
	           {
	              extend: 'colvis',
	              text: "Columns Visibility ",
	              collectionLayout: 'two-column',
	              postfixButtons: ['colvisRestore'],
	           }
			],
			language: {
		        buttons: {
		          colvisRestore: "Select All"
		        }
		    },
			initComplete: function () {
	          	//console.log("The Table has been initialized!")
	        }
		});

		$('.buttons-colvis').removeClass('btn-secondary').addClass('btn-primary');
	} else {
		$('#user_detail_info_container').html(html);
	}

	$('.phone_number').mask('(000) 000-0000');

	getFloatingActionButton('All-User', '#user_detail_info_container');
}

window.getPendingUserData = function(user_id) {
	$.ajax({
        method: 'POST',
        url: api_url+"users/details",
        data: 'user_id='+user_id,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
            if(response.response_data.success == true) {
	        	$('#selected_user_id').val(user_id);

	        	/* Overview */

	        	if(response.user_details.status_code == 5) {
	            	$('.reject_user_button').attr('disabled', true);
	            } else {
	            	$('.reject_user_button').attr('disabled', false);
	            }

	            var status = '';
	            if(response.user_details.status_code == 0) {
	            	status += '<span class="badge badge-info">'+response.user_details.status+'</span>';
	            } else if(response.user_details.status_code == 1) {
	            	status += '<span class="badge badge-success">'+response.user_details.status+'</span>';
	            } else if(response.user_details.status_code == 2) {
	            	status += '<span class="badge badge-warning">'+response.user_details.status+'</span>';
	            } else if(response.user_details.status_code == 5) {
	            	status += '<span class="badge badge-danger">'+response.user_details.status+'</span>';
	            }

	            var bank_added = '';
	            if(response.user_details.is_bank_added == 1) {
	            	bank_added += '<span class="badge badge-success">Available</span>';
	            } else {
	            	bank_added += '<span class="badge badge-danger">Not Available</span>';
	            }

	            $('#user_application_status').html(status);
	            $('#user_payment_status').html(bank_added);
	            $('#user_background_check_status').text(response.user_details.background_check_status);
	            
				/* User Detail */

	            $('#user_firstname').val(response.user_details.first_name);
	            $('#user_lastname').val(response.user_details.last_name);
	            var phone = response.user_details.phone;
	            if(phone != null) {
            		phone = phone.slice(2);
            		$('#user_primary_phone').val(phone);
	            }
	            setTimeout(function(){
	            	$('#user_primary_phone').mask('(000) 000-0000');
				}, 3000);

	            $('#user_email_address').val(response.user_details.email);
	            
	            $('#user_busines_name').val(response.user_details.business_name);
	            var address_line2 = "";
	            if(response.user_details.address_line2 != "") {
	                address_line2 = response.user_details.address_line2+','
	            }
	            var address = response.user_details.address_line1+', '+address_line2+response.user_details.city+', '+response.user_details.state+' '+response.user_details.zip_code;
	            $('#user_primary_contact_address').val(address);

	            // call credit report check api to get uploaded file
	            //getUserBackCreditReportFile('BackgroundCheckReport');
	            //getUserBackCreditReportFile('CreditReport');

	            if(response.user_details.user_service_name == t_role) {
	        		$("a[href='#u-billing']").parent('li').addClass('add_opacity');
	        		$("a[href='#u-background']").parent('li').addClass('add_opacity');
	        		$("a[href='#u-credit']").parent('li').addClass('add_opacity');
	        	}

	    		// Get Floating Action Button (Approve and Reject) | DO not display button for already approved user
	    		if(response.user_details.status_code == 2 || response.user_details.status_code == 5) {
					getFloatingActionButton('Application', '#u-overview');
				}
	        } else {
	            display_error_message(response);
	        }
        }
    });
}

window.deleteUser = function(user_id) {
	if(user_id > 0){
		$.ajax({
			method : 'POST',
			url : api_url+"users/delete-user",
			data : 'user_id='+user_id,
			beforeSend: function(xhr) {
		        xhr.setRequestHeader('Authorization', accessToken);
		    },
	        success: function(response) {
				display_expense_message(response);
	        	if(response.response_data.success == true) {
	        		getPendingUserList('card_layout');
	            }
	        }
		})
	}
}

/*window.approveRejectUserApplication = function(user_id,status) {
    if(user_id > 0) {
        $.ajax({
            method: 'POST',
            url: api_url+"users/user-application-action",
            data: 'user_id='+user_id+'&status='+status,
            beforeSend: function(xhr) {
		        xhr.setRequestHeader('Authorization', accessToken);
		    },
        }).then(function(response) {
            display_error_message(response);
            if(response.response_data.success == true) {
                var page = localStorage.getItem('page_name');
                $('#main_container').load('components/'+page+'.html');
                $('.user_info_container_right').addClass('add_opacity');
            }
        }, function() {
            
        });
    } else {
        alert('User not selected');
    }
}*/

window.deleteUserBackCreditReportFile = function(id,module_name) {
	if(id > 0) {
		var user_id = user.user_details.user_id;
        $.ajax({
            method: 'POST',
            url: api_url+"user-attachments/delete",
            data: 'id='+id+'&user_id='+user_id,
            beforeSend: function(xhr) {
		        xhr.setRequestHeader('Authorization', accessToken);
		    },
        }).then(function(response) {
            display_error_message(response);
            if(response.response_data.success == true) {
               if(module_name == 'Background Check Report') {
                    $('#background_check_dropzone').show();
                    $('#backgroundCollapse').find('.footer-content').html('Upload report file by dragging & dropping or selecting');
                    $('#background_check_preview').html('');
                    $('#backgroundAccordion').find('.card-header > .accordion_arrow').remove('accordion_arrow');
                } else if(module_name == 'Credit Report') {
                    $('#credit_check_dropzone').show();
                    $('#creditCollapse').find('.footer-content').html('Upload report file by dragging & dropping or selecting');
                    $('#credit_check_preview').html('');
                    $('#creditAccordion').find('.card-header > .accordion_arrow').remove();
                } 
            }
        }, function() {
            // hang on this step if the error occur
        });
    } else {
        alert('File not found');
    }
}

window.getUserBackCreditReportFile = function(module_name) {
    var selected_user = $('#selected_user_id').val();
    $.ajax({
        method: 'POST',
        url: api_url+"users/reports",
        data: 'user_id='+selected_user+'&module_name='+module_name,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
            if(response.response_data.success == true) {
            	for (var key in response.report_details) {
	                if (response.report_details.hasOwnProperty(key)) {
	                	var kebab_item = '';
		                if(module_name == 'BackgroundCheckReport') {
		                    $('#background_check_dropzone').hide();
		                    $('#background_check_preview').html('<iframe src="'+response.report_details[0].aws_url+'" width="100%" height="500px" frameborder="0"></iframe>');
		                } else if(module_name == 'CreditReport') {
		                    $('#credit_check_dropzone').hide();
		                    $('#credit_check_preview').html('<iframe src="'+response.report_details[0].aws_url+'" width="100%" height="500px" frameborder="0"></iframe>');
		                }

		                kebab_item += '<a data-id="'+response.report_details[0].id+'" data-module="'+module_name+'" class="dropdown-item delete_report_file" href="javascript:void(0)">Delete</a>';
			            var kebab = '<div class="text-center"><div class="input-group-prepend"><i class="align-middle mr-0 mt-1 fas fa-fw fa-ellipsis-v" data-toggle="dropdown" aria-expanded="false"></i><div class="dropdown-menu dropdown-menu-right" style="">'+kebab_item+'</div></div></div>';

		                if(response.report_details != null && module_name == 'BackgroundCheckReport') {
			                $('#backgroundAccordion').find('.card-header').append('<span class="accordion_arrow">'+kebab+' </span>');
			            }

			            if(response.report_details != null && module_name == 'CreditReport') {
			                $('#creditAccordion').find('.card-header').append('<span class="accordion_arrow">'+kebab+' </span>');
			            }
		            }
		        }
            } else {
                Snackbar.show({
                    text: response.response_data.message,
                    pos: 'bottom-left'
                });
            }
        }
    })
}

window.loadBankAccountType = function(container='') {
	$.ajax({
        method: 'GET',
        url: api_url+"payment-details/get-account-type",
        async: true,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
            if(response.response_data.success == true) {
                var html = "<option value=''>Select Account Type</option>";
                $.each(response.accountType, function( key, value ) {
				  	html += '<option value="'+key+'">'+value+'</option>';
				});
		        $('#'+container).html(html);
            } else {
            	display_error_message(response);
            }
        }
    });
}

window.skipPaymentDetail = function(user_id) {
	$.ajax({
        method: 'POST',
        url: api_url+"users/skip-payment-details",
        data: 'user_id='+user_id,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(data) {
            display_error_message(data);
            if(data.response_data.success == true) {
            	// Update skip_add_payment_details in json object
	    		var user = JSON.parse(localStorage.user);
			   	user.user_details.skip_add_payment_details = 1;
				localStorage.setItem("user", JSON.stringify(user));  //put the object back
	    		location.reload();
            }
        }
    });
}

/*window.checkKycDetailStatusAtDashboard = function() {
	$.ajax({
        method: 'POST',
        url: api_url+"users/details",
        data: 'user_id='+user.user_details.user_id,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
            display_error_message(response);
            if(response.response_data.success == true) {
   				openLoadingDialog($('#payment_form_container'));
				$('#payment_form_container').load('components/bank/add_bank.html', function(){
					$('.skip_payment').removeClass('d-none');
					$('#ssn').mask('0000');
					
					if(response.user_details.dwolla_customer_status == 0) {
						$('#kyc-detail-wizard').removeClass('d-none');
						$('#payment-detail-wizard').addClass('d-none');
					} else if(response.user_details.dwolla_customer_status == 1) {
						$('#kyc-detail-wizard').addClass('d-none');
						$('#payment-detail-wizard').removeClass('d-none');
					} else if(response.user_details.dwolla_customer_status == 2) {
						$('#kyc_status').html('<span class="orange">Retry</span>');
						$('#ssn').mask('000-00-0000');
	    				$('#ssn_note_4').addClass('d-none');
	    				$('#ssn_note_9').removeClass('d-none');
						$('#kyc-detail-wizard').removeClass('d-none');
					} else if(response.user_details.dwolla_customer_status == 3) {
		    			$('#payment-detail-wizard').addClass('d-none');
		    			$('#kyc_status').html('<span class="red">Kba</span>');
		    		} else if(response.user_details.dwolla_customer_status == 4 || response.user_details.dwolla_customer_status == 5) {
		    			$('#kyc-detail-wizard').addClass('add_opacity');
		    			$('#payment-detail-wizard').addClass('d-none');

		    			var message = '';
						if(response.user_details.dwolla_customer_status == 4) {
							message = '<div class="card p-5"><h2 class="grey">We need additional details to complete your identity verification. Rent Synergy support team is notified and will contact and assist you in completing this process.</h2></div>';
						} else if(response.user_details.dwolla_customer_status == 5) {
							message = '<div class="card p-5"><h2 class="grey">Could not complete the verification and your account is suspended. Rent Synergy support team is notified and will contact and assist you in completing this process.</h2></div>';
						}

						$('#payment_form_container').html(message);
		    		}

					setTimeout(function(){
						$('#dwolla_customer_status').val(response.user_details.dwolla_customer_status);
						getStateDropdown();
					}, 500);
				});   
            }
        }
    });
}*/

window.checkKycDetailStatus = function() {
	$.ajax({
        method: 'POST',
        url: api_url+"users/details",
        data: 'user_id='+user.user_details.user_id,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
            display_error_message(response);
            if(response.response_data.success == true) {
       			if(response.user_details.dwolla_customer_status == 3 || response.user_details.dwolla_customer_status == 4 || response.user_details.dwolla_customer_status == 5) {
					var message = '';
					if(response.user_details.dwolla_customer_status == 4) {
						message = 'We need additional details to complete your identity verification. Rent Synergy support team is notified and will contact and assist you in completing this process.';
					} else if(response.user_details.dwolla_customer_status == 5) {
						message = 'Could not complete the verification and your account is suspended. Rent Synergy support team is notified and will contact and assist you in completing this process.';
					}

					var response = {
		                "response_data": {
		                    "success": false,
		                    "message": message
		                }
		            };
		            display_error_message(response);
				} else {
					$('#paymentAddModal').modal('show');
					openLoadingDialog($('#payment_form_container'));
					$('#paymentAddModal #payment_form_container').load('components/bank/add_bank.html', function(){
						$('.card-actions').removeClass('d-none');
						$('.skip_payment').addClass('d-none');
						$('#ssn').mask('0000');
						if(response.user_details.dwolla_customer_status == 1) {
							$('#kyc-detail-wizard').addClass('d-none');
							$('#payment-detail-wizard').removeClass('d-none');
						} else {
							if(response.user_details.dwolla_customer_status == 2) {
								$('#kyc_status').html('<span class="orange">Retry</span>');
								$('#ssn').mask('000-00-0000');
								$('#ssn_note_4').addClass('d-none');
			    				$('#ssn_note_9').removeClass('d-none');
			    			}
							$('#kyc-detail-wizard').removeClass('d-none');
						}
						setTimeout(function(){
							$('#dwolla_customer_status').val(response.user_details.dwolla_customer_status);
							getStateDropdown();
						}, 500);
					});
				}         
            }
        }
    });
}

window.submitKycDetail = function() {

	$('.submit_kyc_detail').text('Processing...');
	$('.submit_kyc_detail').attr('disabled', true);
	var tz = jstz.determine();
	var timezone = tz.name();

	var first_name = $('#user_first_name').val();
	var last_name = $('#user_last_name').val();
	var email = $('#user_email_id').val();
	var address1 = $('#user_address_line1').val();
	var address2 = $('#user_address_line2').val();
	var city = $('#user_city').val();
	var state = $('#user_state').val();
	var zipcode = $('#user_zip_code').val();
	var country = $('#user_country').val();
	var ssn = $('#ssn').val();
	var date_of_birth = $('#kyc-detail-wizard input[name="date_of_birth"]').val();
	var first_time_signup = $('#first_time_signup').val();
	
	var data = 'user_id='+user.user_details.user_id+'&first_name='+first_name+'&last_name='+last_name+'&email='+email+'&address1='+address1+'&address2='+address2+'&city='+city+'&state='+state+'&zipcode='+zipcode+'&country='+country+'&ssn='+ssn+'&date_of_birth='+date_of_birth+'&user_timezone='+timezone+'&first_time_signup='+first_time_signup;

	$.ajax({
        method: 'POST',
        url: api_url+"payment-details/submit-kyc-details",
        data: data,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
    }).then(function(response) {
    	display_error_message(response);
    	$('.submit_kyc_detail').text('Agree & Continue');
    	var dwolla_customer_status = response.response_data.dwolla_customer_status;
    	var dwolla_customer_status_lable = response.response_data.dwolla_customer_status_lable;
    	$('#dwolla_customer_status').val(dwolla_customer_status);
    	if(response.response_data.success == true) {

    		$('#kyc-detail-wizard').addClass('add_opacity');
    		$('#payment-detail-wizard').removeClass('d-none');
    		$('.submit_kyc_detail').attr('disabled', true);

    		if(dwolla_customer_status == 1) {
    			$('#kyc_status').html('<span class="green">'+humanize(dwolla_customer_status_lable)+'</span>');
    			// Below code for initial signup step
    			var first_time_signup = $('#first_time_signup').val();
				if(first_time_signup == 1) {
					$('#kyc-detail-wizard').addClass('d-none');

					if(user_service_name == po_role) {
						// Checkilist step 7
						getSignupNextStep(7,8);

						$('.skip_payment').addClass('d-none');

						var user = JSON.parse(localStorage.user);
				   		user.user_details.cheklist_seq_no = 7;
						localStorage.setItem("user", JSON.stringify(user));  //put the object back
					} else if(user_service_name == t_role) {
						// Checkilist step 5
						getSignupNextStep(5,6);

						$('.skip_payment').addClass('d-none');

						var user = JSON.parse(localStorage.user);
				   		user.user_details.cheklist_seq_no = 5;
						localStorage.setItem("user", JSON.stringify(user));  //put the object back
					}
				}
    		} else if(dwolla_customer_status == 2) {
    			$('#kyc_status').html('<span class="orange">'+humanize(dwolla_customer_status_lable)+'</span>');
    			$('#kyc-detail-wizard').removeClass('add_opacity');
    			$('#payment-detail-wizard').addClass('d-none');
    			$('.submit_kyc_detail').attr('disabled', false);
    			$('#ssn').val('');
    			$('#ssn').mask('000-00-0000');
    			$('#ssn_note_4').addClass('d-none');
    			$('#ssn_note_9').removeClass('d-none');
    		}
    	} else {
    		$('.submit_kyc_detail').attr('disabled', false);
    		if(dwolla_customer_status == 3) {
    			$('#paymentAddModal').modal('hide');
    			$('#payment-detail-wizard').addClass('d-none');
    			$('#kyc_status').html('<span class="red">'+humanize(dwolla_customer_status_lable)+'</span>');
    		} else if(dwolla_customer_status == 4 || dwolla_customer_status == 5) {
    			$('#kyc-detail-wizard').addClass('add_opacity');
    			$('#paymentAddModal').modal('hide');
    			$('#payment-detail-wizard').addClass('d-none');
    			$('#kyc_status').html('<span class="red">'+humanize(dwolla_customer_status_lable)+'</span>');
    		}
    	}
    });
}

window.savePaymentDetail = function() {
	$('.save_payment_detail').attr('disabled', true);
	var tz = jstz.determine();
	var timezone = tz.name();
	var payment_id = $('#payment_id').val();
	var bank_name = $('#bank_name').val();
	var account_type = $('#account_type').val();
	var routing_number = $('#routing_number').val();
	var account_number = $('#account_number').val();
	var first_time_signup = $('#first_time_signup').val();
	
	var data = 'user_id='+user.user_details.user_id+'&payment_id='+payment_id+'&bank_name='+bank_name+'&account_type='+account_type+'&routing_number='+routing_number+'&account_number='+account_number+'&user_timezone='+timezone+'&first_time_signup='+first_time_signup;

	$.ajax({
        method: 'POST',
        url: api_url+"payment-details/add-payment-details",
        data: data,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
    }).then(function(response) {
    	display_error_message(response);
    	if(response.response_data.success == true) {
    		var payment_open_from = $('#payment_open_from').val();
    		if(typeof payment_open_from === 'undefined') {
    			// Update skip_add_payment_details in json object
	    		//var user = JSON.parse(localStorage.user);
			   	//user.user_details.is_bank_added = 1;
				//localStorage.setItem("user", JSON.stringify(user));  //put the object back
	    		//location.reload();

	    		// Below code for initial signup step
    			var first_time_signup = $('#first_time_signup').val();
				if(first_time_signup == 1) {
					if(user_service_name == po_role) {
						// Checkilist step 8
						getSignupNextStep(8,9);

						var user = JSON.parse(localStorage.user);
				   		user.user_details.cheklist_seq_no = 8;
						localStorage.setItem("user", JSON.stringify(user));  //put the object back

						openLoadingDialog($('#signup_data_container'));
						$('#signup_data_container').load('components/property/add_new_property.html', function() {
							$('#signup_data_container .card .fa-window-close').remove();
							$('.save_property_data').text('Next');
						});
					} else if(user_service_name == t_role) {
						// Checkilist step 6
						getSignupNextStep(6,7);

						var user = JSON.parse(localStorage.user);
				   		user.user_details.cheklist_seq_no = 6;
						localStorage.setItem("user", JSON.stringify(user));  //put the object back

						openLoadingDialog($('#signup_data_container'));
						getZohoSignUrl(user.user_details.user_id);
						/*$('#signup_data_container').load('components/property/add_new_property.html', function() {
							$('#signup_data_container .card .fa-window-close').remove();
							$('.save_property_data').text('Next');
						});*/
					}
				}
    		} else {
    			if(payment_open_from == "myaccount") {
    				$('#paymentAddModal').modal('hide');
    				//openLoadingDialog($('#payment_data_container'));
	    			//$('#payment_data_container').load('components/myaccount/payment_detail_table.html');
	    			getMyAccountPaymentDetailWidget();
	    		}
    		}
    	} else {
    		$('.save_payment_detail').attr('disabled', false);
    	}
    });
}

/* Start : Update unverified to verified customer */

window.updateDwollaCustomer = function() {

	$('.update_dwolla_customer').text('Processing...');
	$('.update_dwolla_customer').attr('disabled', true);
	
	var first_name = $('#customer_first_name').val();
	var last_name = $('#customer_last_name').val();
	var email = $('#customer_email_id').val();
	var address1 = $('#customer_address_line1').val();
	var address2 = $('#customer_address_line2').val();
	var city = $('#customer_city').val();
	var state = $('#customer_state').val();
	var zipcode = $('#customer_zip_code').val();
	var country = $('#customer_country').val();
	var ssn = $('#customer_ssn').val();
	var date_of_birth = $('#kyc-detail-wizard input[name="customer_date_of_birth"]').val();
	
	var data = 'user_id='+user.user_details.user_id+'&first_name='+first_name+'&last_name='+last_name+'&email='+email+'&address1='+address1+'&address2='+address2+'&city='+city+'&state='+state+'&zipcode='+zipcode+'&country='+country+'&ssn='+ssn+'&date_of_birth='+date_of_birth;

	$.ajax({
        method: 'POST',
        url: api_url+"payment-details/update-dwolla-customer",
        data: data,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
	    success: function(response) {
	    	display_error_message(response);
	    	if(response.response_data.success == true) {
	    		$('#updateCustomerModal').modal('hide');
	    	} else {
	    		$('.update_dwolla_customer').attr('disabled', false);
	    		$('.update_dwolla_customer').text('Update Customer');
	    	}
	    }
	});
}

/* End : Update unverified to verified customer */

window.getZohoSignUrl = function(user_id) {
	$.ajax({
        method: 'POST',
        url: api_url+"user-templates/get-zoho-sign-in-link",
        data: 'user_id='+user_id,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
            if(response.response_data.success == true) {
            	var sign_url = response.sign_url.sign_url;
            	//$('#signup_data_container').html('<iframe src="'+sign_url+'" width="900" height="600" frameborder="0">');
            	var url = 'https://sign.zoho.com/';
            	$('#signup_data_container').html('<iframe src="'+sign_url+'" width="900" height="600" frameborder="0" csp="frame-ancestors \''+url+'\'">');
            	$('#signup_data_container').append('<div class="border-top"><div class="row"><div class="col-auto ml-auto text-right mt-2 mb-2 mr-2"><button type="button" class="btn btn-orange check_zoho_document_status">Next</button></div></div></div>');
            } else {
            	if(response.isTempSigned == 1) {
            		$('#signup_data_container').html('<div class="row justify-content-center" style="height:400px;"><h2 class="green">'+response.response_data.message+'</h2></div>');
            		$('#signup_data_container').append('<div class="border-top"><div class="row"><div class="col-auto ml-auto text-right mt-2 mb-2 mr-2"><button type="button" class="btn btn-orange check_zoho_document_status">Next</button></div></div></div>');
            	} else {
            		if(response.isTempSigned == 0) {
            			$('#signup_data_container').html('<div class="pt-5 p-5" style="height:400px;"><h2 class="grey">'+response.response_data.message+'</h2></div>');
            		} else {
            			display_error_message(response);
            		}
            	}
            }
        }
    });
}

window.downloadZohoDocument = function(filename, type, request_id) {
	$.ajax({
        method: 'POST',
        url: api_url+"user-templates/download-pdf",
        data: 'request_id='+request_id+'&type='+type,
        dataType: 'text',
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
	    success: function(response) {
    		const linkSource = `data:application/pdf;base64,${response}`;
			const downloadLink = document.createElement("a");
			const fileName = filename+".pdf";
			downloadLink.href = linkSource;
			downloadLink.download = fileName;
			downloadLink.click();
			$('.download_zoho_document').removeClass('add_opacity');
	    }
	});
}

window.checkZohoDocumentStatus = function(user_id) {
	var first_time_signup = $('#first_time_signup').val();
	$.ajax({
        method: 'POST',
        url: api_url+"user-templates/get-template-sign-status",
        data: 'user_id='+user_id+'&first_time_signup='+first_time_signup,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
            if(response.response_data.success == true) {
            	if(user_service_name == t_role) {
					// Checkilist step 7
					getSignupNextStep(7,8);

					var user = JSON.parse(localStorage.user);
			   		user.user_details.cheklist_seq_no = 7;
					localStorage.setItem("user", JSON.stringify(user));  //put the object back

					openLoadingDialog($('#signup_data_container'));
					var user_id = user.user_details.user_id;
					var property_id = 0;
					var lease_id = 0;
					getFMPaymentDetail(user_id,property_id,lease_id);
				}
            } else {
            	display_error_message(response);
            }
        }
    });
}

window.verify_micro_deposit = function(payment_detail_id,micro_deposit_1,micro_deposit_2) {
	$('.verify_micro_deposit').attr('disabled', true);
	var user_id = user.user_details.user_id;
	$.ajax({
        method: 'POST',
        url: api_url+"payment-details/verify-micro-deposit",
        data: 'user_id='+user_id+'&payment_id='+payment_detail_id+'&amount1='+micro_deposit_1+'&amount2='+micro_deposit_2,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(data) {
            display_error_message(data);
            if(data.response_data.success == true) {
            	var payment_open_from = $('#payment_open_from').val();
    			if(typeof payment_open_from === 'undefined') {
	            	$('#bank_card_'+payment_detail_id).remove();
	            	$('#microDepositModal').modal('hide');
	                $('#micro_deposit_form_container').html('');
	            } else {
	            	if(payment_open_from == "myaccount") {
	    				$('#microDepositModal').modal('hide');
	    				//openLoadingDialog($('#payment_data_container'));
		    			//$('#payment_data_container').load('components/myaccount/payment_detail_table.html');
		    			getMyAccountPaymentDetailWidget();
		    		}
	            }
            } else {
            	$('.verify_micro_deposit').attr('disabled', false);
            }
        }
    });
}

/* Start : First Month Payment Code */

window.getReviewFirstMonthPaymentDetail = function() {
	var tz = jstz.determine();
	var timezone = tz.name();
	$.ajax({
	    method: 'POST',
	    url: api_url+"first-month-payments/get-unauthorized-payments",
		data: 'user_id='+user.user_details.user_id+'&user_timezone='+timezone,
	    beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
	    success: function(response) {
	        if(response.response_data.success == true) {
	        	var html = "";
	        	var notification_html = "";
		        for (var key in response.arrFMP) {
		            if (response.arrFMP.hasOwnProperty(key)) {

		            	var name = stripHtml(response.arrFMP[key].property_name);
						if (name.length > 18) {
						    var shortname = name.substring(0, 18) + " ...";
						    name = shortname;
						}

	            		/*html += '<div id="first_month_payment_card_'+key+'" class="col-auto review_first_payment_section">';
				            html += '<div class="card card-fixed-width">';
				                html += '<div class="card-header">';
				                	html += '<h5 class="card-title capitalize mb-0" title="'+stripHtml(response.arrFMP[key].property_name)+'">'+name+'</h5>';
				                html += '</div>';
				                html += '<div class="card-img">';
				                	html += '<img class="card-img-top" src="img/sample/bank.jpg" height="85" width="85" alt="Unsplash"></div>';
				                	html += '<div class="card-body">';
					                	html += '<div class="payment_detail">';
						                	for (var lkey in response.arrFMP[key]) {
						            			if (response.arrFMP[key].hasOwnProperty(lkey)) {
						            				if(lkey != 'totalSum' && lkey != 'property_name' && lkey != 'property_id') {
						            					html += '<div class="mb-1">'+response.arrFMP[key][lkey].payment_type_lable+': <span class="float-right bold-black">$'+response.arrFMP[key][lkey].formated_total+'</span></div>';
						            				}
						            			}
						            		}
						            		html += '<div class="mb-1">Total: <span class="float-right bold-black">$'+response.arrFMP[key].totalSum+'</span></div>';
					                    html += '</div>';
					                    html += '<div class="text-center mb-1 mt-4">';
					                        html += '<button data-property-id='+response.arrFMP[key].property_id+' data-lease-id='+key+' type="button" class="btn btn-orange authorize_fm_rent_btn" data-toggle="modal" data-target="#reviewFirstMonthPaymentModal" data-backdrop="static" data-keyboard="false">Review and Authorize</button>';
					                    html += '</div>';
				                	html += '</div>';
				                html += '</div>';
				            html += '</div>';
				        html += '</div>';*/

				        notification_html += '<div id="first_month_payment_card_'+key+'" class="list-group-item review_first_payment_section">';
						    notification_html += '<div class="row no-gutters align-items-center">';
						        notification_html += '<div class="col-2"> <i class="text-danger" data-feather="credit-card"></i> </div>';
						        notification_html += '<div class="col-10">';
						        notification_html += '<div class="text-dark" title="'+stripHtml(response.arrFMP[key].property_name)+'">'+name+'</div>';
						        	for (var lkey in response.arrFMP[key]) {
				            			if (response.arrFMP[key].hasOwnProperty(lkey)) {
				            				if(lkey != 'totalSum' && lkey != 'property_name' && lkey != 'property_id') {
				            					notification_html += '<div class="text-dark">'+response.arrFMP[key][lkey].payment_type_lable+': <span class="float-right bold-black">$'+response.arrFMP[key][lkey].formated_total+'</span></div>';
				            				}
				            			}
				            		}
				            		notification_html += '<div class="mb-1">Total: <span class="float-right bold-black">$'+response.arrFMP[key].totalSum+'</span></div>';
						            notification_html += '<button data-property-id='+response.arrFMP[key].property_id+' data-lease-id='+key+' type="button" class="btn btn-orange authorize_fm_rent_btn" data-toggle="modal" data-target="#reviewFirstMonthPaymentModal" data-backdrop="static" data-keyboard="false">Review and Authorize</button>';
						        notification_html += '</div>';
						    notification_html += '</div>';
						notification_html += '</div>';
		            }
		        }
		        //$('#review_first_payment_section').html(html);
		        $('#notification_container').append(notification_html);
	        } else {
	        	display_error_message(response);
	        }
	    }
	});
}

window.getFMPaymentDetail = function(user_id = 0, property_id = 0, lease_id = 0) {
	var tz = jstz.determine();
	var timezone = tz.name();
	var first_time_signup = $('#first_time_signup').val();
	$.ajax({
        method: 'POST',
        url: api_url+"first-month-payments/get-unauthorized-payments",
        data: 'user_id='+user_id+'&property_id='+property_id+'&lease_id='+lease_id+'&user_timezone='+timezone+'&first_time_signup='+first_time_signup,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
            display_error_message(response);
            if(response.response_data.success == true) {

            	var cls = '';
            	if(response.isPaymentAuthorized == 1 || response.isAllowedToAuthorized == 0) {
            		cls = 'add_opacity';
            	}

            	var bank_html = "<option value=''>Select Payment Method</option>";
                for (var key in response.paymentDetails) {
			        if (response.paymentDetails.hasOwnProperty(key)) {
			        	bank_html += '<option value="'+response.paymentDetails[key].id+'">'+response.paymentDetails[key].bank_name+'</option>';
					}
				}

            	var html = '';
            	if(first_time_signup != 1) {
            		html += '<input type="hidden" id="selected_lease_id" value="'+lease_id+'">';
            	}

            	html += '<input type="hidden" id="is_payment_authorized" value="'+response.isPaymentAuthorized+'">';
            	html += '<input type="hidden" id="is_allowed_to_authorized" value="'+response.isAllowedToAuthorized+'">';
            	if(response.isPaymentAuthorized == 1 || response.isAllowedToAuthorized == 0) {
            		html += '<div id="msg_div" class="text-center" style="height:400px;"><h3 class="green">'+response.response_data.message+'</h3></div>';
            	}

            	html += '<form id="review-first-month-payment-wizard" class="wizard-primary create-account-form '+cls+'" action="javascript:void(0)">';
				    html += '<div id="payNowCollapse">';
				    	for (var key in response.arrFMP) {
		            		if (response.arrFMP.hasOwnProperty(key)) {
						    	for (var lkey in response.arrFMP[key]) {
						            if (response.arrFMP[key].hasOwnProperty(lkey)) {
						            	if(lkey != 'totalSum' && lkey != 'property_name' && lkey != 'property_id') {

						            		var line = '(One time payment)';
						            		if(response.arrFMP[key][lkey].payment_type_lable == 'Rent') {
						            			line = '';
						            			response.arrFMP[key][lkey].payment_type_lable = 'First Month Rent';
						            		}

											html += '<input type="hidden" name="first_month_payment_id[]" id="first_month_payment_'+lkey+'" class="first_month_payment_hidden_input" value="'+response.arrFMP[key][lkey].id+'">';			            		

									        html += '<div class="rent-detail-card p-3 mb-1 bg-white">';
									            html += '<div class="border-bottom">';
									                html += '<h5 class="card-title"><span class="fs-22">'+response.arrFMP[key][lkey].payment_type_lable+'</span> '+line+'</h5>';
									            html += '</div>';
									            html += '<div class="rent_collection_data mt-2">';
									                html += '<div class="row">';
									                    html += '<div class="col-12 col-md-3 col-xl-3">';
									                        html += '<div class="mt-2">';
									                            html += '<div class="black mb-2"><strong>Due Date</strong></div>';
									                            html += '<div><strong>'+response.arrFMP[key][lkey].payment_due_date+'</strong></div>';
									                        html += '</div>';
									                    html += '</div>';

									                    var disabled = '';
									                    if(response.arrFMP[key][lkey].total <= 0) {
									                    	disabled = 'add_opacity';
									                    }

									                    html += '<div class="col-12 col-md-5 col-xl-6">';
									                        html += '<div class="mt-2">';
									                            html += '<div class="black mb-2"><strong>Select Payment Method</strong></div>';
									                            html += '<div><select name="fm_payment_detail_id[]" id="fm_payment_detail_'+lkey+'" class="bank_dd form-control '+disabled+'">'+bank_html+'</select></div>';
									                        html += '</div>';
									                    html += '</div>';
									                    html += '<div class="col-12 col-md-4 col-xl-3 text-right">';
									                        html += '<div class="mt-2">';
									                            html += '<div class="black mb-2"><strong>Amount</strong></div>';
									                            html += '<div class="black fs-18"><strong>$ '+response.arrFMP[key][lkey].formated_total+'</strong></div>';
									                        html += '</div>';
									                    html += '</div>';
									                html += '</div>';
									            html += '</div>';
									        html += '</div>';
									    }
								    }
								}

						        html += '<div class="rent-detail-card pl-3 pr-3 pt-3 bg-white">';
						            html += '<div class="rent_collection_data">';
						                html += '<div class="row">';
						                    html += '<div class="col-12 col-md-6 col-xl-9">';
						                        html += '<h5 class="card-title"><span class="fs-22">Total</h5>';
						                    html += '</div>';
						                    html += '<div class="col-12 col-md-6 col-xl-3 text-right">';
						                        html += '<div class="black fs-18"><strong>$ '+response.arrFMP[key].totalSum+'</strong></div>';
						                    html += '</div>';
						                html += '</div>';
						            html += '</div>';
						        html += '</div>';
						        html += '<div class="col-auto d-sm-block mb-2 mt-4">';
						            html += '<div class="first_month_payment_checkbox">';
						                html += '<label class="custom-control custom-checkbox m-0">';
						                html += '<input name="first_month_payment_checkbox" id="fm_payment_authorize_checkbox" type="checkbox" class="custom-control-input">';
						                html += '<span class="custom-control-label">I agree and authorize future payments to Rent Synergy will be processed by the Dwolla payment system from the selected account above. In order to cancel this authorization, I will change the payment settings within my account.</span>';
						                html += '</label>';
						            html += '</div>';
						        html += '</div>';
						    }
						}
				    html += '</div>';
				html += '</form>';

				if(first_time_signup == 1) {
					$('#signup_data_container').html(html);
					if(response.isPaymentAuthorized == 1 || response.isAllowedToAuthorized == 0) {
						$('#signup_data_container').append('<div id="brd-top" class="border-top"><div class="row"><div class="col-auto ml-auto text-right mt-2 mb-2 mr-2"><button type="button" class="btn btn-orange authorize_first_month_payment">Next</button></div></div></div>');
					} else {
						$('#signup_data_container').append('<div id="brd-top" class="border-top"><div class="row"><div class="col-auto ml-auto text-right mt-2 mb-2 mr-2"><button type="button" class="btn btn-orange authorize_first_month_payment" disabled="">Agree & Continue</button></div></div></div>');
					}
				} else {
					$('#review_first_month_payment_container').html(html);
				}
				
				if(response.isPaymentAuthorized == 0 || response.isAllowedToAuthorized == 1) {
					addFMPaymentValidation();
				}

				// If data not available do not display button
				if(response.isPaymentAuthorized == 0 && response.isAllowedToAuthorized == 0) {
					$('#msg_div').addClass('mt-5 pt-5');
					$('#brd-top').removeClass('border-top');
					$('.authorize_first_month_payment').addClass('d-none');
				} else {
					$('#msg_div').removeClass('mt-5 pt-5');
					$('#brd-top').addClass('border-top');
					$('.authorize_first_month_payment').removeClass('d-none');
				}
            }
        }
    });
}

window.authorizeFirstMonthPaymentData = function(paymentArray) {
	var is_payment_authorized = $('#is_payment_authorized').val();
	var first_time_signup = $('#first_time_signup').val();
	var tz = jstz.determine();
	var timezone = tz.name();
	$.ajax({
        method: 'POST',
        url: api_url+"first-month-payments/authorized-payments",
        data: 'payments='+paymentArray+'&user_id='+user.user_details.user_id+'&user_timezone='+timezone+'&first_time_signup='+first_time_signup+'&isPaymentAuthorized='+is_payment_authorized,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	$('.authorize_first_month_payment').attr('disabled', false);
        	display_error_message(response);
            if(response.response_data.success == true) {
            	if(first_time_signup == 1) {
            		var user = JSON.parse(localStorage.user);
			   		user.user_details.cheklist_seq_no = 8;
					localStorage.setItem("user", JSON.stringify(user));  //put the object back
            		window.location.href = site_url+'dashboard.html?action=properties';	
            	} else {
	            	$('#reviewFirstMonthPaymentModal').modal('hide');
	            	var lease_id = $('#selected_lease_id').val();
	            	$('#first_month_payment_card_'+lease_id).remove();
	            }
            }
        }
    });
}

var $fmPaymentForm;
window.addFMPaymentValidation = function() {
	$fmPaymentForm = $("#review-first-month-payment-wizard");
	$fmPaymentForm.validate({
	    errorPlacement: function errorPlacement(error, element) {
	        $(element).parents(".form-group").append(error.addClass("invalid-feedback small d-block"))
	    },
	    highlight: function(element) {
	        $(element).addClass("is-invalid");
	    },
	    unhighlight: function(element) {
	        $(element).removeClass("is-invalid");
	    },
	    rules: {
	        "fm_payment_detail_id[]": { required: true }
	    },
	    messages: {
	        "fm_payment_detail_id[]": { required: '' }
	    }
	});
}

/* Start : Rent Collection Code */

var $refundSDForm;
window.addSecurityDepositRefundValidation = function() {
	$refundSDForm = $("#refund_security_deposit_form");
	$refundSDForm.validate({
	    errorPlacement: function errorPlacement(error, element) {
	        $(element).parents(".form-group").append(error.addClass("invalid-feedback small d-block"))
	    },
	    highlight: function(element) {
	        $(element).addClass("is-invalid");
	    },
	    unhighlight: function(element) {
	        $(element).removeClass("is-invalid");
	    },
	    rules: {
	        "security_refunded_amount": { required: true, notEqual: '0.00' },
	        "refund_description": { required: true },
	        "refund_payment_method_id": { required: true },
	    },
	    messages: {
	        "security_refunded_amount": { required: '', notEqual:"" },
	        "refund_description": { required: '' },
	        "refund_payment_method_id": { required: '' },
	    }
	});
}

$.validator.addMethod('notEqual', function (value, el, param) {
	return parseInt(value) > parseInt(param);
});

window.getUserPaymentMethod = function(user_ids, payment_method_id) {
	$.ajax({
       	method: 'POST',
	    url: api_url+"payment-details/user-banks",
	    data:'user_id='+user_ids,
	    beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	display_error_message(response);
            if(response.response_data.success == true) {
            	var html = '';
        		html += "<option value=''>Select Payment Method</option>";
            	for (var key in response.userBanks) {
	                if (response.userBanks.hasOwnProperty(key)) {
                		if(response.userBanks[key].status == 2) {
                			var selected;
                			if(response.userBanks[key].id == payment_method_id) {
                				selected = 'selected';
                			}
	                		var payment_method = response.userBanks[key].bank_name+'&&'+response.userBanks[key].account_number;
	                		html += '<option data-payment-method="'+payment_method+'" value="'+response.userBanks[key].id+'" '+selected+'>'+response.userBanks[key].bank_name+' ('+response.userBanks[key].account_number+')</option>';
	                	}
					}
				}
				$('#refund_payment_method_id').html(html);
            }
        }
    });
}

window.saveSecurityDepositRefundData = function() {
	$.ajax({
        method: 'POST',
        url: api_url+"leases/save-security-deposit-refund-data",
        data: $('#refund_security_deposit_form').serialize()+'&user_id='+user.user_details.user_id,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	display_error_message(response);
        	if(response.response_data.success == true) {
        		loadTableLeaseDetail();
        		$('#refundSecurityAmountModal').modal('hide');
			} else {
				$('.save_security_deposit_refund_data').attr('disabled', false);
			}
        }
    });
}

var $vacantForm;
window.addVacantDateValidation = function() {
	$vacantForm = $("#vacant_date_add_form");
	$vacantForm.validate({
	    errorPlacement: function errorPlacement(error, element) {
	        $(element).parents(".form-group").append(error.addClass("invalid-feedback small d-block"))
	    },
	    highlight: function(element) {
	        $(element).addClass("is-invalid");
	    },
	    unhighlight: function(element) {
	        $(element).removeClass("is-invalid");
	    },
	    rules: {
	        "vacant_date": { required: true },
	    },
	    messages: {
	        "vacant_date": { required: '' },
	    }
	});
}

window.sendLeaseExtensionNotification = function(key, type, tenant_lease_id, vacant_date) {
	$.ajax({
        method: 'POST',
        url: api_url+"leases/send-lease-extension-notification",
        data: 'user_id='+user.user_details.user_id+'&type='+type+'&tenant_lease_id='+tenant_lease_id+'&vacant_date='+vacant_date,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	display_error_message(response);
        	if(response.response_data.success == true) {
	        	var user = JSON.parse(localStorage.user);
				user.leaseInfo[key].lease.is_extended = type;
				localStorage.setItem("user", JSON.stringify(user));  //put the object back
				location.reload();
			} else {
				$('.add_vacant_date').attr('disabled', false);
			}
        }
    });
}

window.getUserServiceList = function() {
	if(user.userServiceList != null) {
		if(user.userServiceList.length > 1) {
			var html = '';
			html += '<a class="nav-flag dropdown-toggle" href="#" id="serviceDropdown" data-toggle="dropdown"> <img class="ninedot" src="icon/ninedot.png"/> </a>';
	        html += '<div class="dropdown-menu dropdown-menu-right dropdown-lg" aria-labelledby="serviceDropdown">';
	            html += '<div id="serviceDropdown" class="row">';
			for (var key in user.userServiceList) {
		        if (user.userServiceList.hasOwnProperty(key)) {
		        	var service_name = user.userServiceList[key].service.service_name;
	                html += '<div data-id="'+user.userServiceList[key].service.id+'" class="col-12 col-md-6 col-xl-4 d-flex role_detail change_service">';
	                    html += '<div class="card flex-fill">';
	                        html += '<div class="card-body text-center">';
	                            html += '<img src="'+user.userServiceList[key].service.image+'" alt="'+service_name+'" class="img-fluid rounded-circle mb-2" width="100" height="100">';
	                        html += '</div>';
	                        html += '<div class="text-center">';
	                            html += '<h6>'+service_name+'</h6>';
	                        html += '</div>';
	                    html += '</div>';
	                html += '</div>';
		        }
		    }
	    		html += '</div>';
	        html += '</div>';
			$('#service_dropdown_li').html(html);
		} else {
			$('#service_dropdown_li').remove();
		}
	}
}

window.getAnnouncement = function() {
	if(user.announcementInfo != null) {
		$('#announcement-sticky-bar').removeClass('d-none');
		var close_btn = '';
		if(user.announcementInfo.close_button == 'Yes') {
			close_btn = '<a href="JavaScript:void(0)" style="position: absolute; top: 5px; right: 20px;"><i style="font-size: 24px;color: initial;" class="far fa-times-circle close_announcement" aria-hidden="true"></i></a>';
		}

		var html = '';
		var read_more = '';
		if(user.announcementInfo.readmore == 'Yes') {
			read_more = '<a data-id="'+user.announcementInfo.id+'" href="javascript:void(0);" class="read-more black">More info...</a>';
		}
		var msg = user.announcementInfo.message;
    	html += '<input type="hidden" name="announcement_title" id="announcement_title" value="'+user.announcementInfo.title+'"/>';
    	html += '<span id="announcement_text" style="font-weight:bold">'+msg+'</span>&nbsp;&nbsp;'+read_more+'&nbsp;'+close_btn;

		$('#announcement-sticky-bar').html(html);
	    $('.wrapper').css('padding-top', '36px');
	    $('.simplebar-content').css('padding-top', '36px !important');
	    $('.sidebar-sticky .sidebar-content').css('top', 36);
	    $('#assumed_user_email').css('top', '4.7%');

	    if(user.announcementInfo.time_duration > 0) {
	    	setTimeout(function() {
		    	$('#announcement-sticky-bar').addClass('d-none');
			    $('#announcement-sticky-bar').html('');
				$('.wrapper').css('padding-top', '0px');
				$('.simplebar-content').css('padding-top', '0px');
				$('.sidebar-sticky .sidebar-content').css('top', 0);
				$('#assumed_user_email').css('top', '0.7%');
				localStorage.setItem("hide_announcement", 1);  //put the object back
			}, user.announcementInfo.time_duration); // <-- time in milliseconds
		}
	} else {
		$('#announcement-sticky-bar').addClass('d-none');
		$('#announcement-sticky-bar').html('');
		$('.wrapper').css('padding-top', '0px');
		$('.simplebar-content').css('padding-top', '0px');
		$('.sidebar-sticky .sidebar-content').css('top', 0);
		$('#assumed_user_email').css('top', '0.7%');
		localStorage.setItem("hide_announcement", 1);  //put the object back
	}
}

window.getUserLeaseInfo = function() {
	if(user.leaseInfo != null) {
		$('#user_lease_info_section').removeClass('d-none');
		var html = '';
		var notification_html = '';
		for (var key in user.leaseInfo) {
	        if (user.leaseInfo.hasOwnProperty(key)) {
	        	if(user.leaseInfo[key].other_lease_active == 0) {
		        	var name = stripHtml(user.leaseInfo[key].property.name);
					/*html += '<div id="user_lease_card_'+user.leaseInfo[key].id+'" class="col-12 col-md-6 col-xl-3 property_card user_lease_info_section">';
			            html += '<div class="card">';
			                html += '<div class="card-header border-bottom">';
			                	html += '<h5 class="card-title capitalize mb-0 mr-3" title="'+stripHtml(user.leaseInfo[key].property.name)+'">'+name+'</h5>';
			                html += '</div>';
			                	if(user.leaseInfo[key].status == 4) {
			                		html += '<div class="fs-16 p-4 text-center">';
				                	html += '<p>Your current lease is ended on</p> <p class="bold-black">'+user.leaseInfo[key].lease.end_date+'</p>';
				                	html += '</div>';
			                	} else {
				                	if(user.leaseInfo[key].lease.is_extended == null || user.leaseInfo[key].lease.is_extended == '') {
					                	html += '<div class="fs-16 p-4 text-center">';
					                	html += '<p>Your current lease is ending on</p> <p class="bold-black">'+user.leaseInfo[key].lease.end_date+'</p> <p>Do you want to request for lease extension ?</p>';
					                	html += '</div>';
				                		html += '<div class="card-body">';
						                	html += '<div class="text-right mt-4">';
						                        html += '<button data-value="Yes" data-key="'+key+'" data-property-name="'+user.leaseInfo[key].property.name+'" data-lease-id="'+user.leaseInfo[key].lease.id+'" data-tenant-lease-id='+user.leaseInfo[key].id+' type="button" class="btn btn-orange mr-2 lease_extension_btn">Yes</button>';
						                        html += '<button data-value="No" data-key="'+key+'" data-property-name="'+user.leaseInfo[key].property.name+'" data-lease-id="'+user.leaseInfo[key].lease.id+'" data-tenant-lease-id='+user.leaseInfo[key].id+' type="button" class="btn btn-primary mr-2 lease_extension_btn">No</button>';
						                    html += '</div>';
					                	html += '</div>';
					                } else {
					                	html += '<div class="fs-16 p-4 text-center">';
					                	html += '<p>Your current lease is ending on</p> <p class="bold-black">'+user.leaseInfo[key].lease.end_date+'</p>';
					                	html += '</div>';
					                }
					            }
				            html += '</div>';
			            html += '</div>';
			        html += '</div>';*/

			        notification_html += '<div id="user_lease_card_'+user.leaseInfo[key].id+'" class="list-group-item user_lease_info_section">';
					    notification_html += '<div class="row no-gutters align-items-center">';
					        notification_html += '<div class="col-2"> <i class="text-danger" data-feather="credit-card"></i> </div>';
					        notification_html += '<div class="col-10">';
					            notification_html += '<div class="text-dark" title="'+stripHtml(user.leaseInfo[key].property.name)+'">'+name+'</div>';
					            if(user.leaseInfo[key].status == 4) {
			                		notification_html += '<div class="fs-16 p-4 text-center">';
				                	notification_html += '<p>Your current lease is ended on</p> <p class="bold-black">'+user.leaseInfo[key].lease.end_date+'</p>';
				                	notification_html += '</div>';
			                	} else {
				                	if(user.leaseInfo[key].lease.is_extended == null || user.leaseInfo[key].lease.is_extended == '') {
					                	notification_html += '<div class="text-dark">';
					                	notification_html += '<p>Your current lease is ending on</p> <p class="bold-black">'+user.leaseInfo[key].lease.end_date+'</p> <p>Do you want to request for lease extension ?</p>';
					                	notification_html += '</div>';
			                			notification_html += '<div class="text-right mt-2">';
					                        notification_html += '<button data-value="Yes" data-key="'+key+'" data-property-name="'+user.leaseInfo[key].property.name+'" data-lease-id="'+user.leaseInfo[key].lease.id+'" data-tenant-lease-id='+user.leaseInfo[key].id+' type="button" class="btn btn-orange mr-2 lease_extension_btn">Yes</button>';
					                        notification_html += '<button data-value="No" data-key="'+key+'" data-property-name="'+user.leaseInfo[key].property.name+'" data-lease-id="'+user.leaseInfo[key].lease.id+'" data-tenant-lease-id='+user.leaseInfo[key].id+' type="button" class="btn btn-primary mr-2 lease_extension_btn">No</button>';
					                    notification_html += '</div>';
					                } else {
					                	notification_html += '<div class="text-dark">';
					                	notification_html += '<p>Your current lease is ending on</p> <p class="bold-black">'+user.leaseInfo[key].lease.end_date+'</p>';
					                	notification_html += '</div>';
					                }
					            }
					        notification_html += '</div>';
					    notification_html += '</div>';
					notification_html += '</div>';
			    }
	        }
	    }
		//$('#user_lease_info_section').html(html);
		$('#notification_container').append(notification_html);
	}
}

window.getSubscriptionFeeFailedTransaction = function() {
	var tz = jstz.determine();
	var timezone = tz.name();
	$.ajax({
	    method: 'POST',
	    url: api_url+"scheduled-payments/get-subscription-fee-failed-transaction",
		data: 'user_id='+user.user_details.user_id+'&user_timezone='+timezone,
	    beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
	    success: function(response) {
	        if(response.response_data.success == true) {
	        	var html = "";
	        	var notification_html = "";
		        if(response.arrSP == null) {
		        	//html += '<a href="javascript:void(0)" class="list-group-item list-group-item-action border-0"><div class="fs-16">'+response.response_data.message+'</div></a>';
			    } else {
			    	for (var key in response.arrSP) {
			            if (response.arrSP.hasOwnProperty(key)) {

			            	var name = stripHtml(response.arrSP[key].property.name);
			            	var address = '';
			                if(response.arrSP[key].property != null && response.arrSP[key].property.address != null) {
			                	address = response.arrSP[key].property.address.address_line1+', '+response.arrSP[key].property.address.address_line2+'<br>'+response.arrSP[key].property.address.city+', '+response.arrSP[key].property.address.state+' '+response.arrSP[key].property.address.zip_code;
			                }

			                var add_opacity = '';
			                var exclamation = '';
			                if(response.arrSP[key].is_disabled == 1) {
			                	var auth_msg = 'Authorize Payment button is not <br> enabled since the previous month <br> payment is still pending for authorization.';
			                	add_opacity = 'add_opacity';
			                	exclamation = '<i class="align-middle ml-2 mt-2 fas fa-fw fa-exclamation-circle" title="" data-container="body" data-toggle="popover" data-placement="top" data-content="'+auth_msg+'"></i>';
			                }

					        notification_html += '<div id="subscription_fee_card_'+response.arrSP[key].id+'" class="list-group-item subscription_fee_section">';
                                notification_html += '<div class="row no-gutters align-items-center">';
                                    notification_html += '<div class="col-2"> <img src="icon/tiles/rent.svg" class="filter-1"> </div>';
                                    notification_html += '<div class="col-10">';
                                        notification_html += '<div class="text-dark">'+response.arrSP[key].payment_type_lable+' <strong>($'+response.arrSP[key].formated_total+')</strong> for '+response.arrSP[key].rent_month_formatted+'</div>';
                                        notification_html += '<div class="text-muted small mt-1">'+name+'</div>';
                                        notification_html += '<button data-id="'+response.arrSP[key].id+'" data-property-id="'+response.arrSP[key].property_id+'" data-payment-type="'+response.arrSP[key].payment_type+'" data-payment-id="'+response.arrSP[key].payment_details_id+'" data-month="'+response.arrSP[key].rent_month+'" data-open-from="notification" type="button" class="btn btn-orange mt-2 authorize_subscription_fee '+add_opacity+'">Authorize Payment</button>'+exclamation;
                                    notification_html += '</div>';
                                notification_html += '</div>';
                            notification_html += '</div>';
			            }
			        }
			    }
		        
		        $('#notification_container').append(notification_html);  
		        $('[data-toggle="popover"]').popover({
		        	html: true,
				    animation:false,
				    sanitize: false,
				    placement: function() { return $(window).width() < 975 ? 'top' : 'bottom'; },
		        });  	
	        } else {
	        	display_error_message(response);
	        }
	    }
	});
}

window.getUpcomingPaymentDetail = function() {
	var tz = jstz.determine();
	var timezone = tz.name();
	$.ajax({
	    method: 'POST',
	    url: api_url+"scheduled-payments/get-unauthorized-payments",
		data: 'tenant_id='+user.user_details.user_id+'&user_timezone='+timezone,
	    beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
	    success: function(response) {
	        if(response.response_data.success == true) {
	        	var html = "";
	        	var notification_html = "";
		        if(response.arrSP == null) {
		        	//html += '<a href="javascript:void(0)" class="list-group-item list-group-item-action border-0"><div class="fs-16">'+response.response_data.message+'</div></a>';
			    } else {
			    	//$('<h3>Upcoming Payment Detail</h3>').insertBefore($('#upcoming_payment_section'));
			        for (var key in response.arrSP) {
			            if (response.arrSP.hasOwnProperty(key)) {

			            	var name = stripHtml(response.arrSP[key].property.name);
			            	var address = '';
			                if(response.arrSP[key].property != null && response.arrSP[key].property.address != null) {
			                	address = response.arrSP[key].property.address.address_line1+', '+response.arrSP[key].property.address.address_line2+'<br>'+response.arrSP[key].property.address.city+', '+response.arrSP[key].property.address.state+' '+response.arrSP[key].property.address.zip_code;
			                }

			                var add_opacity = '';
			                var exclamation = '';
			                if(response.arrSP[key].is_disabled == 1) {
			                	var auth_msg = 'Authorize Payment button is not <br> enabled since the previous month <br> payment is still pending for authorization.';
			                	add_opacity = 'add_opacity';
			                	exclamation = '<i class="align-middle ml-2 mt-2 fas fa-fw fa-exclamation-circle" title="" data-container="body" data-toggle="popover" data-placement="top" data-content="'+auth_msg+'"></i>';
			                }

					        // Card based UI
							/*html += '<div id="upcoming_payment_card_'+response.arrSP[key].id+'" class="col-12 col-md-6 col-xl-3 property_card upcoming_payment_section">';
								html += '<div class="card">';
									html += '<div class="card-header bg-light"><h5 class="card-title capitalize mb-0 mr-3" title="'+stripHtml(response.arrSP[key].property.name)+'">'+name+'</h5></div>';
									html += '<div class="text-center"><img src="img/sample/bank.jpg" height="100" width="100" alt="Unsplash"></div>';
									html += '<div class="card-body">';
										html += '<table class="table mb-0 card-table word-break">';
											html += '<tbody>';
												html += '<tr>';
													html += '<td class="nowrap">'+response.arrSP[key].payment_type_lable+'</td>';
													html += '<td class="text-right">$'+response.arrSP[key].formated_total+'</td>';
												html += '</tr>';
												html += '<tr>';
													html += '<td class="nowrap">Month</td>';
													html += '<td class="text-right">'+response.arrSP[key].payment_due_date+'</td>';
												html += '</tr>';
												html += '<tr>';
													html += '<td class="nowrap">Address</td>';
													html += '<td class="text-right">'+address+'</td>';
												html += '</tr>';
											html += '</tbody>';
										html += '</table>';
										html += '<div class="text-right mt-4">';
											html += exclamation+'<button data-property-id='+response.arrSP[key].property_id+' data-scheduled-payment-id='+response.arrSP[key].id+' type="button" class="btn btn-orange authorize_rent_btn '+add_opacity+'" data-toggle="modal" data-target="#payNowModal" data-backdrop="static" data-keyboard="false">Authorize Payment</button>';
										html += '</div>';
									html += '</div>';
								html += '</div>';
							html += '</div>';*/

							notification_html += '<div id="upcoming_payment_card_'+response.arrSP[key].id+'" class="list-group-item upcoming_payment_section">';
                                notification_html += '<div class="row no-gutters align-items-center">';
                                    notification_html += '<div class="col-2"> <img src="icon/tiles/rent.svg" class="filter-1"> </div>';
                                    notification_html += '<div class="col-10">';
                                        notification_html += '<div class="text-dark">'+response.arrSP[key].payment_type_lable+' <strong>($'+response.arrSP[key].formated_total+')</strong> for '+response.arrSP[key].payment_due_date+'</div>';
                                        notification_html += '<div class="text-muted small mt-1">'+name+'</div>';
                                        notification_html += '<button data-property-id='+response.arrSP[key].property_id+' data-scheduled-payment-id='+response.arrSP[key].id+' type="button" class="btn btn-orange mt-2 authorize_rent_btn '+add_opacity+'" data-toggle="modal" data-target="#payNowModal" data-backdrop="static" data-keyboard="false">Authorize Payment</button>'+exclamation;
                                    notification_html += '</div>';
                                notification_html += '</div>';
                            notification_html += '</div>';
			            }
			        }
			    }
		        //$('#upcoming_payment_section').html(html);  
		        $('#notification_container').append(notification_html);  
		        $('[data-toggle="popover"]').popover({
		        	html: true,
				    animation:false,
				    sanitize: false,
				    placement: function() { return $(window).width() < 975 ? 'top' : 'bottom'; },
		        });  	
	        } else {
	        	display_error_message(response);
	        }
	    }
	});
}

window.getSchedulePaymentDetail = function(tenant_id, property_id, scheduled_payment_id) {
	var tz = jstz.determine();
	var timezone = tz.name();
	$.ajax({
        method: 'POST',
        url: api_url+"scheduled-payments/get-scheduled-payments",
        data: 'tenant_id='+tenant_id+'&property_id='+property_id+'&scheduled_payment_id='+scheduled_payment_id+'&user_timezone='+timezone,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
            display_error_message(response);
            if(response.response_data.success == true) {

            	var bank_html = "<option value=''>Select Payment Method</option>";
                for (var key in response.paymentDetails) {
			        if (response.paymentDetails.hasOwnProperty(key)) {
			        	bank_html += '<option value="'+response.paymentDetails[key].id+'">'+response.paymentDetails[key].bank_name+'</option>';
					}
				}

            	var html = '';
            	html += '<input type="hidden" id="selected_payment_id" value="'+scheduled_payment_id+'">';
            	html += '<form id="rent-collection-wizard" class="wizard-primary create-account-form" action="javascript:void(0)">';
				    html += '<div id="payNowCollapse">';
				    	if(response.scheduledPayment != null) {
		            		var line = '(One time payment)';
		            		if(response.scheduledPayment.payment_type == 2 || response.scheduledPayment.payment_type == 7) {
		            			line = 'for the month of '+response.scheduledPayment.payment_due_date2;
		            		}

		            		var current_date = new Date();
							var current_date = ((current_date.getMonth() > 8) ? (current_date.getMonth() + 1) : ('0' + (current_date.getMonth() + 1))) + '/' + ((current_date.getDate() > 9) ? current_date.getDate() : ('0' + current_date.getDate())) + '/' + current_date.getFullYear();

							var payment_due_date = response.scheduledPayment.payment_due_date;
		            		var formatted_payment_due_date = getFormattedDate(payment_due_date);

		            		html += '<input type="hidden" name="scheduled_payment_id[]" id="scheduled_payment_0" class="schedule_payment_hidden_input" value="'+response.scheduledPayment.id+'">';			            		

					        html += '<div class="rent-detail-card p-3 mb-1 bg-white">';
					            html += '<div class="border-bottom">';
					                html += '<h5 class="card-title"><span class="fs-22">'+response.scheduledPayment.payment_type_lable+'</span> '+line+'</h5>';
					            html += '</div>';
					            html += '<div class="rent_collection_data mt-2">';
					                html += '<div class="row">';
					                	// check if due date is less then or equal to current date, then add class add_opacity
					                	var d1 = new Date(formatted_payment_due_date);
					                	var d2 = new Date(current_date);
					                	
					                	var add_opacity = '';
					            		if(d1.getTime() <= d2.getTime()) {
					            			add_opacity = 'add_opacity';
		    							}
		    							
	    								html += '<div class="col-12 col-md-4 col-xl-4">';
										    html += '<div class="mt-2">';
										        html += '<div class="black mb-2"><strong>Due Date</strong></div>';
										        html += '<div class="input-group date mb-2 other_month_due_date '+add_opacity+'" data-target-input="nearest">';
										            html += '<input type="text" name="payment_due_date[]" id="payment_due_date_0" class="payment_due_date form-control readonly_white" autocomplete="off" readonly="" value="'+formatted_payment_due_date+'" />';
										            html += '<div class="input-group-append">';
										                html += '<div class="input-group-text"><i class="fa fa-calendar"></i></div>';
										            html += '</div>';
										        html += '</div>';
										    html += '</div>';
										html += '</div>';

										html += '<div class="col-12 col-md-4 col-xl-4">';
					                        html += '<div class="mt-2">';
					                            html += '<div class="black mb-2"><strong>Select Payment Method</strong></div>';
					                            html += '<div><select name="payment_detail_id[]" id="payment_detail_0" class="bank_dd form-control">'+bank_html+'</select></div>';
					                        html += '</div>';
					                    html += '</div>';
					                    html += '<div class="col-12 col-md-4 col-xl-4 text-right">';
					                        html += '<div class="mt-2">';
					                            html += '<div class="black mb-2"><strong>Amount</strong></div>';
					                            html += '<div class="black fs-18"><strong>$ '+response.scheduledPayment.formatted_total+'</strong></div>';
					                        html += '</div>';
					                    html += '</div>';
					                html += '</div>';
					            html += '</div>';
					        html += '</div>';
						}

						if(response.scheduledPayment.property.is_late_fee_enabled == 1 && response.scheduledPayment.waive_late_fee > 0) {
							html += '<div class="rent-detail-card p-3 mb-1 bg-white">';
					        	html += '<div class="border-bottom">';
					                html += '<h5 class="card-title"><span class="fs-22">Late Fee</span></h5><p>The late fee is $'+response.scheduledPayment.latePricePerDay+' every day until the late fee is the equivalent of '+response.scheduledPayment.lateMaxPercentag+'% of the rent.</p>';
					            html += '</div>';
                                html += '<div class="rent_collection_data mt-2">';
                                	html += '<div class="row">';
					                	html += '<div class="col-12 col-md-8 col-xl-8">';
										    html += '<div class="mt-2">';
										        html += '<div class="mb-2">(A) Rent Due by '+response.scheduledPayment.rent_month+'</div>';
										    html += '</div>';
										html += '</div>';
										html += '<div class="col-12 col-md-4 col-xl-4 text-right">';
					                        html += '<div class="mt-2">';
					                            html += '<div class="black"><strong>$ '+response.scheduledPayment.formatted_total+'</strong></div>';
					                        html += '</div>';
					                    html += '</div>';
					                html += '</div>';
                                    html += '<div class="row">';
                                        html += '<div class="col-12 col-md-8 col-xl-8">';
                                            html += '<div class="mt-2">';
                                                html += '<div class="mb-2 ml-4">(1) No. of days late after '+response.scheduledPayment.lateFeeafterDays+' day of the month</div>';
                                            html += '</div>';
                                        html += '</div>';
                                        html += '<div class="col-12 col-md-4 col-xl-4 text-right">';
                                            html += '<div class="mt-2">';
                                                html += '<div>'+response.scheduledPayment.no_of_days+' day(s)</div>';
                                            html += '</div>';
                                        html += '</div>';
                                    html += '</div>';
                                    html += '<div class="row">';
                                        html += '<div class="col-12 col-md-8 col-xl-8">';
                                            html += '<div class="mt-2">';
                                                html += '<div class="mb-2 ml-4">(2) Late fee applied per day</div>';
                                            html += '</div>';
                                        html += '</div>';
                                        html += '<div class="col-12 col-md-4 col-xl-4 text-right">';
                                            html += '<div class="mt-2">';
                                                html += '<div>$ '+response.scheduledPayment.price_per_day+'</div>';
                                            html += '</div>';
                                        html += '</div>';
                                    html += '</div>';
                                    html += '<div class="row">';
                                        html += '<div class="col-12 col-md-8 col-xl-8">';
                                            html += '<div class="mt-2">';
                                                html += '<div class="mb-2">(B) Outstanding late fee (1) x (2)</div>';
                                            html += '</div>';
                                        html += '</div>';
                                        html += '<div class="col-12 col-md-4 col-xl-4 text-right">';
                                            html += '<div class="mt-2">';
                                                html += '<div class="black"><strong>$ '+response.scheduledPayment.formatted_late_fee_amount+'</strong></div>';
                                            html += '</div>';
                                        html += '</div>';
                                    html += '</div>';
                                    html += '<div class="row">';
                                        html += '<div class="col-12 col-md-8 col-xl-8">';
                                            html += '<div class="mt-2">';
                                                html += '<div class="mb-2">(C) Late fee waived</div>';
                                            html += '</div>';
                                        html += '</div>';
                                        html += '<div class="col-12 col-md-4 col-xl-4 text-right">';
                                            html += '<div class="mt-2">';
                                                html += '<div class="black"><strong>$ '+response.scheduledPayment.formatted_waived_amount+'</strong></div>';
                                            html += '</div>';
                                        html += '</div>';
                                    html += '</div>';
                                html += '</div>';
                            html += '</div>';
						}

				        html += '<div class="rent-detail-card pl-3 pr-3 pt-3 bg-white">';
				            html += '<div class="rent_collection_data">';
				                html += '<div class="row">';
				                    html += '<div class="col-12 col-md-6 col-xl-9">';
				                    	if(response.scheduledPayment.property.is_late_fee_enabled == 1 && response.scheduledPayment.waive_late_fee > 0) {
				                        	html += '<h5 class="card-title"><span class="fs-16"><strong>Total</strong> (A) + (B) - (C)</h5>';
				                        } else {
				                        	html += '<h5 class="card-title"><span class="fs-16"><strong>Total</strong></h5>';
				                        }
				                    html += '</div>';
				                    html += '<div class="col-12 col-md-6 col-xl-3 text-right">';
				                    	if(response.scheduledPayment.property.is_late_fee_enabled == 1 && response.scheduledPayment.waive_late_fee > 0) {
				                        	html += '<div class="black fs-18"><strong>$ '+response.scheduledPayment.formatted_totalSum+'</strong></div>';
				                        } else {
				                        	html += '<div class="black fs-18"><strong>$ '+response.scheduledPayment.formatted_total+'</strong></div>';
				                        }
				                    html += '</div>';
				                html += '</div>';
				            html += '</div>';
				        html += '</div>';
				        html += '<div class="col-auto d-sm-block mb-2 mt-4">';
				            html += '<div class="pay_now_checkbox">';
				                html += '<label class="custom-control custom-checkbox m-0">';
				                html += '<input name="pay_now_checkbox" id="payment_authorize_checkbox" type="checkbox" class="custom-control-input">';
				                html += '<span class="custom-control-label">I agree and authorize future payments to Rent Synergy will be processed by the Dwolla payment system from the selected account above. In order to cancel this authorization, I will change the payment settings within my account.</span>';
				                html += '</label>';
				            html += '</div>';
				        html += '</div>';
				    html += '</div>';
				html += '</form>';

				$('#rent_collection_container').html(html);

				tenant_authorize_datepicker("other_month_due_date", formatted_payment_due_date);

				addRentCollectionValidation();
            }
        }
    });
}

window.saveRentCollectionData = function(paymentArray) {
	if($rentCollectionForm.valid()){
		var tz = jstz.determine();
		var timezone = tz.name();
		$.ajax({
	        method: 'POST',
	        url: api_url+"scheduled-payments/authorized-payments",
	        data: 'payments='+paymentArray+'&user_timezone='+timezone+'&user_id='+user.user_details.user_id,
	        beforeSend: function(xhr) {
		        xhr.setRequestHeader('Authorization', accessToken);
		    },
	        success: function(response) {
	        	$('.save_rent_collection_data').attr('disabled', false);
	        	display_error_message(response);
	            if(response.response_data.success == true) {
	            	$('#payNowModal').modal('hide');
	            	var scheduled_payment_id = $('#selected_payment_id').val();
	            	// Enable next card
	            	$('#upcoming_payment_card_'+scheduled_payment_id).next('div.upcoming_payment_section').find('.authorize_rent_btn').removeClass('add_opacity');
	            	// Remove current card
	            	$('#upcoming_payment_card_'+scheduled_payment_id).remove();
	            	// When authorized payment from detail page below function call and reload monthly card
	            	getMonthlyRentWidgetInfo();
	            	var notification = parseInt($('#notification_count').text());
	            	var total_notification = notification - 1;
	            	$('#notification_indicator, #notification_count').text(total_notification);
	            }
	        }
	    });
	} else {
		$('.save_rent_collection_data').attr('disabled', false);
	}
}

var $rentCollectionForm;
window.addRentCollectionValidation = function() {
	$rentCollectionForm = $("#rent-collection-wizard");
	$rentCollectionForm.validate({
	    errorPlacement: function errorPlacement(error, element) {
	        $(element).parents(".form-group").append(error.addClass("invalid-feedback small d-block"))
	    },
	    highlight: function(element) {
	        $(element).addClass("is-invalid");
	    },
	    unhighlight: function(element) {
	        $(element).removeClass("is-invalid");
	    },
	    rules: {
	        "payment_due_date[]": { required: true },
	        "payment_detail_id[]": { required: true }
	    },
	    messages: {
	        "payment_due_date[]": { required: '' },
	        "payment_detail_id[]": { required: '' }
	    }
	});
}

var $autopayForm;
window.addAutopayValidation = function() {
	$autopayForm = $("#autopay-collection-wizard");
	$autopayForm.validate({
	    errorPlacement: function errorPlacement(error, element) {
	        $(element).parents(".form-group").append(error.addClass("invalid-feedback small d-block"))
	    },
	    highlight: function(element) {
	        $(element).addClass("is-invalid");
	    },
	    unhighlight: function(element) {
	        $(element).removeClass("is-invalid");
	    },
	    rules: {
	        "autopay_date": { required: true },
	        "autopay_payment_detail_id": { required: true }
	    },
	    messages: {
	        "autopay_date": { required: '' },
	        "autopay_payment_detail_id": { required: '' }
	    }
	});
}

/*  LInk Bank to Property*/

window.getPropertyListForPayment = function(user_id, payment_method_id, payment_method_name) {
	var payment_method_name = payment_method_name.split('&&');
	$.ajax({
        method: 'POST',
        url: api_url+"properties/link-payment-property-list",
        data: 'user_id='+user_id,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	display_error_message(response);
            if(response.response_data.success == true) {
            	var html = '';
            	html += '<div class="rent-detail-card text-center p-3 bg-white"><h5 class="card-title mb-0 grey">Select property for payment method</h5><div class="fs-18 mt-2 bold-black">'+payment_method_name[0]+' ('+payment_method_name[1]+')</div></div>';
            	html += '<input type="hidden" name="selected_payment_method_id" id="selected_payment_method_id" value="'+payment_method_id+'">';
            	html += '<input type="hidden" name="selected_payment_method_name" id="selected_payment_method_name" value="'+payment_method_name+'">';
            	html += '<select name="bank_property_dd" class="form-control bank_property_dd mt-3">';
	            	html += "<option value=''>Select Property</option>";
	                $.each( response.propertyList, function( key, value ) {
					  	html += '<option data-module="Bank" value="'+key+'">'+value+'</option>';
					});
				html += '</select>';
				html += '<div id="rent_deposit_subscription_container" class="mt-3"></div>';

				$('#bp_dropdown_container').html(html);
            }
        }
    });
}

window.getBankListForProperty = function(user_id, property_id, property_name) {
	$.ajax({
       	method: 'POST',
	    url: api_url+"payment-details/user-banks",
	    data:'user_id='+user_id,
	    beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	display_error_message(response);
            if(response.response_data.success == true) {
            	var html = '';
            	html += '<div class="rent-detail-card text-center p-3 bg-white"><h5 class="card-title mb-0 grey">Select bank account to deposit monthly rent for</h5><div class="fs-18 mt-2 bold-black">'+property_name+'</div></div>';
            	html += '<input type="hidden" name="selected_property_id" id="selected_property_id" value="'+property_id+'">';
            	html += '<select name="bank_property_dd" class="form-control bank_property_dd mt-3">';
	            	html += "<option value=''>Select Payment Method</option>";
	            	for (var key in response.userBanks) {
		                if (response.userBanks.hasOwnProperty(key)) {
		                	var first_time_signup = $('#first_time_signup').val();
		                	if(first_time_signup == 1) {
	                			var payment_method = response.userBanks[key].bank_name+'&&'+response.userBanks[key].account_number;
		                		html += '<option data-module="Property" data-payment-method="'+payment_method+'" value="'+response.userBanks[key].id+'">'+response.userBanks[key].bank_name+' ('+response.userBanks[key].account_number+')</option>';
		                	} else {
		                		if(response.userBanks[key].status == 2) {
			                		var payment_method = response.userBanks[key].bank_name+'&&'+response.userBanks[key].account_number;
			                		html += '<option data-module="Property" data-payment-method="'+payment_method+'" value="'+response.userBanks[key].id+'">'+response.userBanks[key].bank_name+' ('+response.userBanks[key].account_number+')</option>';
			                	}
		                	}
						}
					}
				html += '</select>';

				//html += '<div id="rent_deposit_subscription_container" class="mt-3"></div>';

				html += '<form id="rent-collection-wizard" class="wizard-primary create-account-form mt-5 mb-5" action="javascript:void(0)">';
				    html += '<div id="linkPropertyCollapse">';
						html += '<div class="col-auto d-sm-block mb-2 mt-4">';
				            html += '<div class="rent_deposit_checkbox">';
				                html += '<label class="custom-control custom-checkbox m-0">';
				                html += '<input name="is_authorized" id="property_bank_authorize_checkbox" type="checkbox" class="custom-control-input">';
				                html += '<span class="custom-control-label">I agree and authorize this above bank account to be linked with the selected proeprty, process and deposit monthly rent by the Dwolla payment system. In order to cancel this authorization, I will change the payment settings within my account.</span>';
				                html += '</label>';
				            html += '</div>';
				        html += '</div>';
	                html += '</div>';
				html += '</form>';

				var first_time_signup = $('#first_time_signup').val();
				if(first_time_signup == 1) {

					html += '<div class="border-top">';
	                    html += '<div class="row">';
	                        html += '<div class="col-auto ml-auto text-right mt-2 mb-2 mr-2">';
	                            html += '<button type="button" data-module="Property" class="btn btn-orange authorize_rent_subscription_button" disabled="">Agree & Continue</button>';
	                        html += '</div>';
	                    html += '</div>';
	                html += '</div>';

					$('#signup_data_container').html('<div class="row mt-5"><div class="col-sm-12 col-md-12 col-lg-6 mx-auto border">'+html+'</div></div>');
				} else {
					$('#bp_dropdown_container').html(html);
				}
            }
        }
    });
}

/*window.getRentDepositAndSubscription = function(user_id, property_id, payment_method_id, payment_method_name) {
	var payment_method_name = payment_method_name.split('&&');
	var tz = jstz.determine();
	var timezone = tz.name();
	$.ajax({
        method: 'POST',
        url: api_url+"properties/get-property-lease-details",
        data: 'user_id='+user_id+'&property_id='+property_id+'&user_timezone='+timezone,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
            display_error_message(response);
            if(response.response_data.success == true) {
            	var first_time_signup = $('#first_time_signup').val();
            	var html = '';
        		html += '<form id="rent-collection-wizard" class="wizard-primary create-account-form" action="javascript:void(0)">';
				    html += '<div id="linkPropertyCollapse">';
				    	html += '<div class="rent-detail-card p-3 mb-1 bg-white">';
				            html += '<div class="border-bottom">';
				                html += '<h5 class="card-title"><span class="fs-22">Subscription Fees</span></h5>';
				            html += '</div>';
				            html += '<div class="rent_collection_data mt-2">';
				                html += '<div class="row">';
				                    html += '<div class="col-6 col-md-6 col-xl-6">';
				                        html += '<div class="mt-2">';
				                            html += '<div class="black mb-2"><strong>Due Date</strong></div>';
				                            html += '<div><strong>1<sup>st</sup> of month</strong></div>';
				                        html += '</div>';
				                    html += '</div>';
				                    html += '<div class="col-6 col-md-6 col-xl-6 text-right">';
				                        html += '<div class="mt-2">';
				                            html += '<div class="black mb-2"><strong>Amount</strong></div>';
				                            html += '<div class="fs-18"><strong>$ '+response.price_per_month+'</strong></div>';
				                        html += '</div>';
				                    html += '</div>';
				                html += '</div>';
				            html += '</div>';
				        html += '</div>';

				        html += '<div class="rent-detail-card p-3 mb-1 bg-white">';
				            html += '<div class="rent_collection_data mt-2">';
				                html += '<div class="row">';
				                    html += '<div class="col-6 col-md-6 col-xl-6">';
				                        html += '<div class="mt-2">';
				                            html += '<div class="green mb-2"><strong>Coupon Applied</strong></div>';
				                            html += '<div><strong>'+response.coupon_code+'</strong></div>';
				                        html += '</div>';
				                    html += '</div>';
				                    html += '<div class="col-6 col-md-6 col-xl-6 text-right">';
				                        html += '<div class="mt-2">';
				                            html += '<div class="black mb-2"><strong>Discount Amount</strong></div>';
				                            html += '<div class="fs-18"><strong>$ '+response.couponAmount+'</strong></div>';
				                        html += '</div>';
				                    html += '</div>';
				                html += '</div>';
				            html += '</div>';
				        html += '</div>';

				        html += '<div class="rent-detail-card pl-3 pr-3 pt-3 bg-white">';
				            html += '<div class="rent_collection_data">';
				                html += '<div class="row">';
				                    html += '<div class="col-6 col-md-6 col-xl-6">';
				                        html += '<h5 class="card-title"><span class="fs-22">Total</h5>';
				                    html += '</div>';
				                    html += '<div class="col-6 col-md-6 col-xl-6 text-right">';
				                    	html += '<input type="hidden" name="rent_subscription_total" id="rent_subscription_total" value="'+response.totalSum+'">';
				                    		html += '<div class="black fs-18"><strong>$ '+response.finalAmont+'</strong></div>';
				                    html += '</div>';
				                html += '</div>';
				            html += '</div>';
				        html += '</div>';
				        html += '<div class="col-auto d-sm-block mb-2 mt-4">';
				            html += '<div class="rent_deposit_checkbox">';
				                html += '<label class="custom-control custom-checkbox m-0">';
				                html += '<input name="is_authorized" id="property_bank_authorize_checkbox" type="checkbox" class="custom-control-input">';
				                html += '<span class="custom-control-label">I agree and authorize this above rent deposit and subscription fees will be processed by the Dwolla payment system from the selected account(s) above. In order to cancel this authorization, I will change the payment settings within my account.</span>';
				                html += '</label>';
				            html += '</div>';
				        html += '</div>';
				    html += '</div>';
				html += '</form>';

				$('#rent_deposit_subscription_container').html(html);
            }
        }
    });
}*/

/* Authorized Rent and Subscription */

window.authorizeRentDepositData = function(module_name, property_id, payment_method_id, is_authorized) {
	$('.authorize_rent_subscription_button').attr('disabled',true);
	$('.save_link_bank_to_property').attr('disabled',true);
	var user_id = user.user_details.user_id;
	var first_time_signup = $('#first_time_signup').val();
	//var total = $('#rent_subscription_total').val();
	//var is_negative_total = $('#is_negative_total').val();
	
	$.ajax({
        method: 'POST',
        url: api_url+"properties/authorized-link-property-payment",
        //data: 'user_id='+user_id+'&property_id='+property_id+'&payment_details_id='+payment_method_id+'&is_authorized='+is_authorized+'&total='+total+'&is_negative_total='+is_negative_total,
        data: 'user_id='+user_id+'&property_id='+property_id+'&payment_details_id='+payment_method_id+'&is_authorized='+is_authorized+'&first_time_signup='+first_time_signup,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	display_error_message(response);
            if(response.response_data.success == true) {
            	var first_time_signup = $('#first_time_signup').val();
            	if(first_time_signup == 1) {
            		// Checkilist step 13
					getSignupNextStep(13,14);

					var user = JSON.parse(localStorage.user);
					user.user_details.cheklist_seq_no = 13;
					localStorage.setItem("user", JSON.stringify(user));  //put the object back
					window.location.href = site_url+'dashboard.html?action=properties';	
            	} else {
	            	$('#linkPropertyModal').modal('hide');
	            	if(module_name == 'Bank') {
	            		loadTablePaymentDetail();
	            	} else if(module_name == 'Property') {
	            		$('#main_container').load('components/properties.html');
	            	}
	            }
            } else {
            	$('.authorize_rent_subscription_button').attr('disabled',false);
            	$('.save_link_bank_to_property').attr('disabled',false);
            }
        }
    });
}

/* End : Rent Collection Code End here */

window.checkIfTenantLinkedWithLease = function(property_id,tenant_id) {
	$.ajax({
        method: 'POST',
        url: api_url+"tenants/get-lease-detail",
        data: 'property_id='+property_id+'&tenant_id='+tenant_id,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	//display_error_message(response);
            if(response.response_data.success == true) {
            	$('#inviteTenantModal #invite_tenant_step_container').load('components/property/invite_tenant.html', function(){
					$('#rs_invite_tenant_id').val(tenant_id);
					$('#rs_invite_tenant').val(1);
					var type = 'PO';
					getInviteTenantZohoTemplateList(type);
				});
            } else {
            	$('#inviteTenantModal #invite_tenant_step_container').html('<div class="text-center mt-5 pt-5"><h3 class="red">'+response.response_data.message+'</h3></div>');
            }
        }
    });
}

window.getInviteTenantZohoTemplateList = function(type) {
	openLoadingDialog($('#invite_tenant_data_container'));
    var property_id = $('#selected_property_id').val();
    $.ajax({
        method: 'POST',
        url: api_url+"zoho-templates",
        data: 'property_id='+property_id,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
    }).then(function(response) {
        if(response.response_data.success == true) {
        	var po_checked = '';
        	var t_checked = '';
        	if(type == 'PO') {
        		var po_checked = 'checked';
        	} else if(type == 'T') {
        		var t_checked = 'checked';
        	}

        	var html = "";
        	var dd_html = "";

        	dd_html += '<div class="row pt-4 bg-white d-none">';
	        	dd_html += '<div class="form-group col-md-12 col-xl-12">';
				    dd_html += '<div class="form-row">';
				        dd_html += '<div class="col-md-6">';
				            dd_html += '<div class="custom-controls-stacked">';
				                dd_html += '<label class="custom-control custom-radio">';
				                    dd_html += '<input name="property_user_type" type="radio" class="custom-control-input property_user_type" data-type="po" value="'+po_role+'" '+po_checked+'>';
				                    dd_html += '<span class="custom-control-label">Property Owner</span>';
				                dd_html += '</label>';
				            dd_html += '</div>';
				        dd_html += '</div>';
				        dd_html += '<div class="col-md-6">';
				            dd_html += '<div class="custom-controls-stacked">';
				                dd_html += '<label class="custom-control custom-radio">';
				                    dd_html += '<input name="property_user_type" type="radio" class="custom-control-input property_user_type" data-type="t" value="'+t_role+'" '+t_checked+'>';
				                    dd_html += '<span class="custom-control-label">Tenant</span>';
				                dd_html += '</label>';
				            dd_html += '</div>';
				        dd_html += '</div>';
				    dd_html += '</div>';
				dd_html += '</div>';
			dd_html += '</div>';

			html += dd_html;
        	
        	/* Document List */

        	html += '<div class="row mt-2 bg-white">';
        	html += '<h4 class="m-3 document_title">Document List</h4>';
            for (var key in response.zohoTemplates) {
	            if (response.zohoTemplates.hasOwnProperty(key)) {

	            	var user_class = '';
	            	var template_disabled = 'add_opacity';
	            	if(response.zohoTemplates[key].account_type_label == '1dc82947-e650-494a-b798-986a6f701d1f') { // PO
	            		user_class = 'po';
	            		template_disabled = '';
	            	} else if(response.zohoTemplates[key].account_type_label == 'c963a422-edb4-4bad-97ed-276c5abb3b79') { // TENANT
	            		user_class = 't';
	            		if(response.zohoTemplates[key].send_in_order == 0) {
							template_disabled = '';
	            		}
	            	}

	            	if(type == 'PO') {
	            		var account_type = '1dc82947-e650-494a-b798-986a6f701d1f';
	            	} else if(type == 'T') {
	            		var account_type = 'c963a422-edb4-4bad-97ed-276c5abb3b79';
	            	}

	            	var signature_required = response.zohoTemplates[key].signature_rquired;
					var msg = '<small class="grey">('+signature_required+' Signature Required)</small>';

					var add_opacity = '';
					if(response.zohoTemplates[key].disabled == 1) {
						msg = '<small class="red">('+response.zohoTemplates[key].message+')</small>';
						add_opacity = 'add_opacity';
					}

	            	if(response.zohoTemplates[key].account_type == account_type) {
		            	html += '<div class="col-auto col-md-12 col-xl-12 flex account_type_'+user_class+' '+template_disabled+' '+add_opacity+'">';
		                    html += '<div class="flex-fill">';
		                        html += '<div class="mt-2 mb-2">';
		                            html += '<div class="black"><label class="custom-control custom-radio" style="word-break:break-all"><input name="document_name" type="radio" class="custom-control-input zoho_template_radio" data-id='+response.zohoTemplates[key].id+' data-signature-required="'+signature_required+'"><span class="custom-control-label">'+response.zohoTemplates[key].zoho_templateName+' '+msg+'</span></label></div>';
		                        html += '</div>';
		                    html += '</div>';
		                html += '</div>';
		            }
			    }
			}
			html += '</div>';

			if(type == 'PO' && response.POTemplateStatus > 0) {
				$('#invite_tenant_data_container').html('<div id="msg_div" class="text-center mt-5 pt-5"><h3 class="green">'+response.poMessage+'</h3></div>');
				$('#invite_tenant_data_container').append('<div id="brd-top" class="mt-5 pt-5"><div class="row"><div class="col-auto ml-auto text-right mt-2 mb-2 mr-2"><button type="button" class="btn btn-orange go_to_tenant_lease_section">Next</button></div></div></div>');
			//} else if(type == 'T' && response.TTemplateStatus > 1) {
			} else if(type == 'T' && response.tMessage != '') {
				$('#invite_tenant_data_container').html('<div id="msg_div" class="text-center mt-5 pt-5"><h3 class="green">'+response.tMessage+'</h3></div>');
				$('#invite_tenant_data_container').append('<div id="brd-top" class="mt-5 pt-5"><div class="row"><div class="col-auto ml-auto text-right mt-2 mb-2 mr-2"><button type="button" class="btn btn-orange go_to_fm_payment_section">Next</button></div></div></div>');
			} else {
				$('#invite_tenant_data_container').html(html);	
			}
        } else {
            display_error_message(response);
        }
    }, function() {
        // hang on this step if the error occur
    });
}

window.getZohoTemplateList = function() {

	$('.save_template_data').attr('disabled', true);
	openLoadingDialog($('#template_list_container'));
	
    var property_id = $('#selected_property_id').val();
    $.ajax({
        method: 'POST',
        url: api_url+"zoho-templates",
        data: 'property_id='+property_id,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
    }).then(function(response) {
        if(response.response_data.success == true) {

        	var html = "";
        	var dd_html = "";

        	dd_html += '<div class="row pt-4 bg-white">';
	        	dd_html += '<div class="form-group col-md-12 col-xl-12">';
				    dd_html += '<div class="form-row">';
				        dd_html += '<div class="col-md-6">';
				            dd_html += '<div class="custom-controls-stacked">';
				                dd_html += '<label class="custom-control custom-radio">';
				                    dd_html += '<input name="property_user_type" type="radio" class="custom-control-input property_user_type" data-type="po" value="'+po_role+'">';
				                    dd_html += '<span class="custom-control-label">Property Owner</span>';
				                dd_html += '</label>';
				            dd_html += '</div>';
				        dd_html += '</div>';
				        dd_html += '<div class="col-md-6">';
				            dd_html += '<div class="custom-controls-stacked">';
				                dd_html += '<label class="custom-control custom-radio">';
				                    dd_html += '<input name="property_user_type" type="radio" class="custom-control-input property_user_type" data-type="t" value="'+t_role+'">';
				                    dd_html += '<span class="custom-control-label">Tenant</span>';
				                dd_html += '</label>';
				            dd_html += '</div>';
				        dd_html += '</div>';
				    dd_html += '</div>';
				dd_html += '</div>';
			dd_html += '</div>';

			html += dd_html;

        	/* Document List */

        	html += '<div class="row mt-2 bg-white">';
        	html += '<h4 class="m-3 document_title d-none">Document List</h4>';
            for (var key in response.zohoTemplates) {
	            if (response.zohoTemplates.hasOwnProperty(key)) {

	            	var user_class = '';
	            	var template_disabled = 'add_opacity';
	            	if(response.zohoTemplates[key].account_type_label == '1dc82947-e650-494a-b798-986a6f701d1f') { // PO
	            		user_class = 'po';
	            		template_disabled = '';
	            	} else if(response.zohoTemplates[key].account_type_label == 'c963a422-edb4-4bad-97ed-276c5abb3b79') { // TENANT
	            		user_class = 't';
	            		if(response.zohoTemplates[key].send_in_order == 0) {
							template_disabled = '';
	            		}
	            	}

	            	var signature_required = response.zohoTemplates[key].signature_rquired;
	            	var msg = '<small class="grey">('+signature_required+' Signature Required)</small>';

	            	var add_opacity = '';
	            	if(response.zohoTemplates[key].disabled == 1) {
	            		msg = '<small class="red">('+response.zohoTemplates[key].message+')</small>';
	            		add_opacity = 'add_opacity';
	            	}

	            	html += '<div class="col-auto col-md-12 col-xl-12 flex d-none account_type_'+user_class+' '+template_disabled+' '+add_opacity+'">';
	                    html += '<div class="flex-fill">';
	                        html += '<div class="mt-2 mb-2">';
	                            html += '<div class="black"><label class="custom-control custom-radio" style="word-break:break-all"><input name="document_name" type="radio" class="custom-control-input zoho_template_radio" data-id='+response.zohoTemplates[key].id+' data-signature-required="'+signature_required+'"><span class="custom-control-label">'+response.zohoTemplates[key].zoho_templateName+' '+msg+'</span></label></div>';
	                        html += '</div>';
	                    html += '</div>';
	                html += '</div>';
			    }
			}
			html += '</div>';

			/*if(response.zohoTemplates != null) {
	        	$('.save_template_data').attr('disabled', false);
	        }*/

	        $('#template_list_container').html(html);
        } else {
            display_error_message(response);
        }
    }, function() {
        // hang on this step if the error occur
    });
}

window.getZohoTemplateSignerList = function(zoho_template_id,user_type,property_id,signature_required) {

	$('.save_template_data').attr('disabled', false);

	var data = 'zoho_template_id='+zoho_template_id+'&user_type='+user_type+'&property_id='+property_id;
	var url = api_url+"user-templates/get-template-signature-details";

	$.ajax({
        method: 'POST',
        url: url,
        data: data,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
            display_error_message(response);
            if(response.response_data.success == true) {
            	var allRecipients = response.arrRecipints;
            	var totalRecipients = Object.keys(allRecipients).length + 1;
            	if(signature_required != totalRecipients) {
            		$('.save_template_data').addClass('d-none');
            		var response = {
                        "response_data": {
                            "success": false,
                            "message": 'Please select document with '+totalRecipients+' signature required'
                        }
                    };
                    display_error_message(response);
            	} else {
            		$('.save_template_data').removeClass('d-none');

	            	var html = "";
	            	var dd_html = "";

	            	/* Create Tenants Dropdown */

		    		dd_html += "<option value=''>--- Select User ---</option>";
				    $.each( response.arrRecipints, function( key, value ) {
			        	dd_html += '<option value="'+key+'">'+value+'</option>';
					});

				    /* Create Roles */
	            	
	            	html += '<div id="zohoSignerListContainer" class="row mt-2 bg-white">';
		            	html += '<h4 class="m-3">Signer List</h4>';
		            	html += '<form id="document-signer-list">';
			            	for (var key in response.arrSignatureDetails) {
				            	if (response.arrSignatureDetails.hasOwnProperty(key)) {
				            		html += '<div class="form-group col-md-12 col-xl-12">';
									    html += '<div class="form-row">';
									        html += '<div class="col-md-6">';
									            html += '<input name="role_name" type="text" class="form-control" value="'+response.arrSignatureDetails[key].recipients+'" disabled>';
									        html += '</div>';
									        html += '<div class="col-md-6">';
									        	if(key == 0) {
									            	html += response.arrSignatureDetails[key].label;
									            } else {
									            	html += '<select name="property_tenant_dd[]" id="property_tenant_dd_'+key+'" class="form-control property_tenant_dd">'+dd_html+'</select>';
									            }
									        html += '</div>';
									    html += '</div>';
									html += '</div>';
								}
							}
						html += '</form>';
					html += '</div>';

					var rs_invite_tenant = $('#rs_invite_tenant').val();
					if(rs_invite_tenant == 1) {
						$('#invite_tenant_data_container').append(html);
						$('#save_btn').remove();
						if(user_type == po_role) {
							$('#invite_tenant_data_container').append('<div id="save_btn" class="row"><div class="col-auto ml-auto text-right mt-3 mb-2"><button type="button" class="btn btn-orange save_template_data">Send for Signature</button></div></div>');
						} else if(user_type == t_role) {
							$('#invite_tenant_data_container').append('<div id="save_btn" class="row"><div class="col-auto ml-auto text-right mt-3 mb-2"><button type="button" class="btn btn-orange save_template_data">Next</button></div></div>');
						}
					} else {
						$('#template_list_container').append(html);
					}

					documentSignerValidation();
				}
            }
        }
    });
}

var $documentSignerForm;
window.documentSignerValidation = function() {
	$documentSignerForm = $("#document-signer-list");
	$documentSignerForm.validate({
	    errorPlacement: function errorPlacement(error, element) {
	        $(element).parents(".form-group").append(error.addClass("invalid-feedback small d-block"))
	        $(element).parents(".form-group").addClass('text-right');
	    },
	    highlight: function(element) {
	        $(element).addClass("is-invalid");
	    },
	    unhighlight: function(element) {
	        $(element).removeClass("is-invalid");
	    },
	    rules: {
	        "property_tenant_dd[]": { required: true, check_duplicate_tenant: true }
	    },
	    messages: {
	        "property_tenant_dd[]": { required: '', check_duplicate_tenant: 'Tenant should not be same' }
	    }
	});

	$.validator.addMethod("check_duplicate_tenant", function(value, element) {
		var flag = true;
		var current_value = $(element).val();
		var current_id = $(element).attr('id');
		$("select[name^='property_tenant_dd']").each(function(key, value) {
		    var element_value = $(this).val();
		    var element_id = $(this).attr('id');
		    if(current_id != element_id) {
		    	if(current_value == element_value) {
		    		flag = false;
		    	}
		    }
		});
		return flag;
	});
}

window.assignedZohoTemplate = function(zoho_template_id,user_type,property_id,userArray) {
	if($documentSignerForm.valid()){
		var user_id = user.user_details.user_id;
		var data = 'user_id='+user_id+'&zoho_template_id='+zoho_template_id+'&user_type='+user_type+'&property_id='+property_id+'&users='+userArray;
		var url = api_url+"user-templates/assign-property-template";
		$.ajax({
	        method: 'POST',
	        url: url,
	        data: data,
	        beforeSend: function(xhr) {
		        xhr.setRequestHeader('Authorization', accessToken);
		    },
	        success: function(data) {
	            display_error_message(data);
	            if(data.response_data.success == true) {
	            	// Below code will check that from where function is called based on that it will load data
	            	var rs_invite_tenant = $('#rs_invite_tenant').val();
					if(rs_invite_tenant == 1) {
						if(user_type == po_role) {
							sendForSignature(zoho_template_id,property_id);
						} else if(user_type == t_role) {
							getSignupNextStep(2,3);
							openLoadingDialog($('#invite_tenant_data_container'));
							setTimeout(function(){
								var property_name = $('#navbar_role_name').text();
								getLeaseListForProperty(property_id, property_name);
							}, 500);
						}
					} else {
						$('#templateListModal').modal('hide');
	            		getPropertyDocument();
					}
					getPropertyDocumentWidgetInfo();
	            } else {
	            	$('.save_template_data').attr('disabled',false);
	            }
	        }
	    });
	} else {
		$('.save_template_data').attr('disabled',false);
		var rs_invite_tenant = $('#rs_invite_tenant').val();
		if(rs_invite_tenant == 1) {
			if(user_type == po_role) {
				$('.save_template_data').html('Send for Signature');
			} else {
				$('.save_template_data').html('Next');
			}
		}
	}
}

window.sendForSignature = function(zoho_template_id,property_id) {
	var user_id = user.user_details.user_id;
	$.ajax({
        method: 'POST',
        url: api_url+"user-templates/doc-sign",
        data: 'zoho_template_id='+zoho_template_id+'&property_id='+property_id+'&user_id='+user_id,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(data) {
            display_error_message(data);
            if(data.response_data.success == true) {
            	// Below code will check that from where function is called based on that it will load data
            	var rs_invite_tenant = $('#rs_invite_tenant').val();
				if(rs_invite_tenant == 1) {
					getSignupNextStep(1,2);
					var type = 'T';
					getInviteTenantZohoTemplateList(type);
				} else {
					getPropertyDocument();
				}
            } else {
            	$('.send_for_signatures').removeClass('add_opacity');
            }
        }
    });
}

window.deleteUserTemplate = function(user_template_id) {
	var logged_in_user = user.user_details.user_id;
	var user_id = $('#selected_propoerty_user_id').val();
	var url = api_url+"user-templates/delete";
    var data = 'id='+user_template_id+'&logged_in_user='+logged_in_user;
	$.ajax({
	    method: 'POST',
	    url: url,
	    data: data,
	    beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
	}).then(function(response) {
	    display_error_message(response);
	    if(response.response_data.success == true) {
	        $('#user_template_'+user_template_id).remove();
	    }
	}, function() {
	    // hang on this step if the error occur
	});
}

window.uploadFile = function(file,module_name) {
	$.ajax({
        method: 'POST',
        url: api_url+"user-attachments/reports",
        data: getFileObj(module_name, file),
        processData: false,
        contentType: false,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	display_error_message(response);
        	if(module_name == 'BackgroundCheckReport') {
            	backgroundCheckDropzone.removeFile(file);
            } else if(module_name == 'CreditReport') {
            	creditCheckDropzone.removeFile(file);
            }
            getUserBackCreditReportFile(module_name);
        }
    });
}

window.getFileObj = function(module_name, file) {
	var user_id = user.user_details.user_id;
    var formData = new FormData();
    formData.append('module_name', module_name);
    
    if(module_name == 'BackgroundCheckReport') {
    	var selected_user = $('#selected_user_id').val();
    	formData.append('attachment', file); // attach dropzone image element	
    	formData.append('user_id', selected_user);
    	formData.append('user_id', user_id);
    	formData.append('module_name', 'background_check_report');
    } else if(module_name == 'CreditReport') {
    	var selected_user = $('#selected_user_id').val();
    	formData.append('attachment', file); // attach dropzone image element	
    	formData.append('user_id', selected_user);
    	formData.append('user_id', user_id);
    	formData.append('module_name', 'credit_report');
    } else if(module_name == 'Property') {
    	var property_id = $('#selected_property_id').val();
    	formData.append("attachment[]", file);
	    formData.append('property_id', property_id);
	    formData.append('user_id', user_id);
	    formData.append('module_name', 'property_image');
    } else if(module_name == 'Property Checklist') {
    	var property_id = $('#selected_property_id').val();
    	formData.append("checklist_attachment[]", file);
	    formData.append('property_id', property_id);
	    formData.append('user_id', user_id);
	    formData.append('module_name', 'property_document');
    }

    return formData;
}

window.loadUserLists = function() {
	var user = JSON.parse(localStorage.getItem('user'));
	var user_service_name = user.user_details.service_constant;
	var user_id = user.user_details.user_id;
	var ui = 'ai';
	
	if(user_service_name == sa_role && allow_ai_access == 1) {
		$('#assume_identity_section').removeClass('d-none');
	} else {
		$('#assume_identity_section').remove();
	}

	$.ajax({
	    method: 'GET',
	    url: api_url+"users/list",
	    data: 'user_id='+user_id+'&ui='+ui,
	    beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
	    success: function(response) {
	    	if(response.response_data.success == true) {
	    		var html = "";
		        if(response.TotalUsers == 0) {
		        	html += '<a href="javascript:void(0)" class="list-group-item list-group-item-action border-0 bg-light text-left"><div class="fs-16">'+response.response_data.message+'</div></a>';
			    } else {
			    	for (var key in response.users) {
			            if (response.users.hasOwnProperty(key)) {
			                var name = response.users[key].first_name+' '+response.users[key].last_name;
			                var email = response.users[key].email;
			                html += '<a data-id="'+response.users[key].id+'" data-name="'+name+'" data-email="'+response.users[key].email+'" href="javascript:void(0)" class="list-group-item get_assumed_user_data no_underline"><div class="row no-gutters align-items-center media"><div class="col-1"><i class="far fa-user"></i></div><div class="col-11 pl-2"><div class="bold-black">'+name+'<br><small>'+email+'</small></div></div></div></a>';
			            }
			        }
			    }
			    $('#user_info_container').html(html);
		    } else {
		        display_error_message(response);
		    }
	    }
	});
}

window.getUserData = function() {
	$.ajax({
	    method: 'GET',
	    url: api_url+"users/list",
	    beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
	    success: function(response) {
	    	if(response.response_data.success == true) {
	    		var html = "";
		        if(response.TotalUsers == 0) {
		        	html += '<a href="javascript:void(0)" class="list-group-item list-group-item-action border-0 bg-light text-left"><div class="fs-16">'+response.response_data.message+'</div></a>';
			    } else {
			    	var html = '';
	    			html += "<option value=''>Select Owner</option>";
			    	for (var key in response.users) {
			            if (response.users.hasOwnProperty(key)) {
			                var name = response.users[key].first_name+' '+response.users[key].last_name;
			                var email = response.users[key].email;
			                var id = response.users[key].id;
			                var selected = '';
			                html += '<option value="'+id+'" '+selected+'>'+name+' ('+email+')</option>';
			            }
			        }
			    }
			    $('#group_owner').html(html);
		    } else {
		        display_error_message(response);
		    }
	    }
	});
}

window.loadHeaderPropertiesList = function(module_name = '') {
	var user = JSON.parse(localStorage.getItem('user'));
	var user_service_name = user.user_details.service_constant;

	if(user_service_name == sa_role) {
		var method = 'GET';
		var url = api_url+"properties";
		var data = '';
	} else {
		var method = 'POST';
		var url = api_url+"properties/details";
		var data = 'user_id='+user.user_details.user_id+'&user_service_name='+user_service_name;
	}

	$.ajax({
	    method: method,
	    url: url,
	    data:data,
	    beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
	}).then(function(response) {
	    if(response.response_data.success == true) {
	    	if(module_name == 'add_new_ticket') {
	    		var property_id = $('#selected_property_id').val();
	    		var ticket_property_id = $('#ticket_property_id').val();
	    		var html = '';
	    		html += "<option value=''>Select Property</option>";
			    for (var key in response.Properties) {
			        if (response.Properties.hasOwnProperty(key)) {
			            var id = response.Properties[key].id;
			            var name = response.Properties[key].name;
			            var selected = '';
			            if(ticket_property_id == id) {
			            	selected = "selected";
			            }
			            html += '<option value="'+id+'" '+selected+'>'+name+'</option>';
			        }
			    }
			    $('#tickets-property-id').html(html);
			    if(user_service_name == sa_role) {
			    	getPropertyRelatedUsers(ticket_property_id);
			    }
	    	} else if(module_name == 'expenses') {
				var html = '';
	    		html += "<option value=''>Select Property</option>";
			    for (var key in response.Properties) {
			        if (response.Properties.hasOwnProperty(key)) {
			            var id = response.Properties[key].id;
			            var name = response.Properties[key].name;
			            html += '<option value="'+id+'">'+name+'</option>';
			        }
			    }
			    $('#inputProperty').html(html);
			} else {
	    		var html = "";
		        if(response.TotalProperties == 0) {
		        	html += '<a href="javascript:void(0)" class="list-group-item list-group-item-action border-0 bg-light text-left"><div class="fs-16">'+response.response_data.message+'</div></a>';
			    } else {
			    	for (var key in response.Properties) {
			            if (response.Properties.hasOwnProperty(key)) {
			                var name = response.Properties[key].name;
			                html += '<a data-id="'+response.Properties[key].id+'" data-name="'+name+'" href="javascript:void(0)" class="list-group-item get_property_chat"><div class="row no-gutters align-items-center media"><div class="col-2"><img src="icon/property.svg" class="rounded-circle mr-1" alt="'+name+'" width="50" height="50"></div><div class="col-10 pl-2"><div class="text-dark">'+name+'</div></div></div></a>';
			            }
			        }
			    }
			    $('#properties_indicator').html(response.TotalProperties);
			    $('#header_properties_count').html('Total '+response.TotalProperties+' Properties');
		        $('#header_property_info_container').html(html);
	    	}
	    } else {
	        display_error_message(response);
	    }
	}, function() {
	    // hang on this step if the error occur
	});
}

window.loadVerticalProperty = function() {

	localStorage.removeItem('property_name');

	// Get list of user properties
	var user = JSON.parse(localStorage.getItem('user'));
	var user_service_name = user.user_details.service_constant;

	if(user_service_name == sa_role) {
		var method = 'GET';
		var url = api_url+"properties";
		var data = '';
	} else {
		var method = 'POST';
		var url = api_url+"properties/details";
		var data = 'user_id='+user.user_details.user_id+'&user_service_name='+user_service_name;
	}

	$.ajax({
	    method: method,
	    url: url,
	    data:data,
	    beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
	    success: function(response) {
	    	if(response.response_data.success == true) {
	    		if(response.TotalProperties == 1) {
		    		var property_id = response.Properties[0].id;
		    		var payment_status = response.Properties[0].payment_status;
		    		var property_name = response.Properties[0].name;
		    		if(user_service_name == sa_role || payment_status == 4) {
						var rand_num1 = random_number(11);
						var rand_num2 = random_number(20);
						var pid = rand_num1+property_id+rand_num2;
						window.history.pushState("", "", '?action=properties&pid='+pid);
						loadPropertyDetailLayout(property_id, 'vertical', eachWordFirstLetterCapital(property_name));
					} else {
						loadPropertyCard(response, 'leftmenu');
					}
		    	} else {
		    		loadPropertyCard(response, 'leftmenu');
		    	}
		    } else {
		        display_error_message(response);
		    }
	    }
	});
}

window.loadPropertyCard = function(response, open_from) {
	var html = "";
    if(response.TotalProperties == 0) {
    	html += '<a href="javascript:void(0)" class="list-group-item list-group-item-action border-0 bg-light text-left"><div class="fs-16">'+response.response_data.message+'</div></a>';
    } else {
    	for (var key in response.Properties) {
		    if (response.Properties.hasOwnProperty(key)) {
		    	var p_id = response.Properties[key].id;
		        var name = response.Properties[key].name;
		        var property_name = name.toLowerCase();
		        var address_line1 = "";
		        var address_line2 = "";
		        var city = "";
		        var state = "";
		        var zip_code = "";
		        var payment_status = "";
		        var kebab = "";
		        
		        if(response.Properties[key].address != null) {
		        	if(response.Properties[key].address.address_line1 != "") {
		                address_line1 = response.Properties[key].address.address_line1;
		                address_line1 = address_line1.toLowerCase();
		            }
		            if(response.Properties[key].address.address_line2 != "") {
		                address_line2 = response.Properties[key].address.address_line2
		                address_line2 = ', '+address_line2.toLowerCase();
		            }
		            if(response.Properties[key].address.city != "") {
		                city = response.Properties[key].address.city;
		                city = city.toLowerCase();
		            }
		            if(response.Properties[key].address.state != "") {
		                state = response.Properties[key].address.state;
		                state = state.toUpperCase();
		            }
		            if(response.Properties[key].address.zip_code != "") {
		                zip_code = response.Properties[key].address.zip_code;
		            }
		        }

		        // Set kebab menu conditions
		        var kebab_item = '';
                
		        if(user_service_name == sa_role) {
		        	if(response.Properties[key].payment_details_id > 0 && response.Properties[key].payment_status == 2) {
		        		kebab_item += '<a class="dropdown-item process_property_setup_fee" href="javascript:void(0)" data-id="'+p_id+'">Process Setup Fee</a>';
		        	}

		        	if(user.permissionData['PROPERTIES']["feature_items['waive_late_fee']"] && response.Properties[key].is_late_fee_enabled == 1 && response.Properties[key].waive_late_fee > 0) {
		        		kebab_item += '<a class="dropdown-item open_waive_late_fee_modal" href="javascript:void(0)" data-id="'+p_id+'">Waive Late Fee</a>';
		        	}

		        	/*if(response.Properties[key].request_to_pay == 1) {
			        	kebab_item += '<a class="dropdown-item open_request_to_pay_form" href="javascript:void(0)" data-id="'+p_id+'">Request to Pay</a>';
			        }*/
		        }

		        /*if(user_service_name == po_role && response.Properties[key].payment_status == 4) {
		        	if(user.permissionData['PROPERTIES']["feature_items['link_payment_method']"]) {
            			kebab_item += '<a class="dropdown-item link_bank_and_property" data-module="Property" data-id="'+response.Properties[key].id+'" data-name="'+name+'" data-lease-count="'+response.Properties[key].lease+'" data-lease-message="'+response.Properties[key].message+'" data-payment-id="'+response.Properties[key].payment_details_id+'" data-payment-message="'+response.Properties[key].pm_link_message+'" href="javascript:void(0)">Link Payment Method</a>';	
            		}
            	}*/

            	if(user_service_name == sa_role || (user_service_name == po_role && response.Properties[key].payment_status == 1)) {
            		if(user.permissionData['PROPERTIES']["feature_items['property_edit']"]) {
                		kebab_item += '<a class="dropdown-item edit_property_address" href="javascript:void(0)" data-id="'+p_id+'" data-toggle="modal" data-target="#propertyEditModal" data-backdrop="static" data-keyboard="false">Edit</a>';
                	}
                }

                if(user.permissionData['PROPERTIES']["feature_items['property_delete']"]) {
		        	kebab_item += '<a data-id="'+response.Properties[key].id+'" data-module="Property" class="dropdown-item delete_property_data" href="javascript:void(0)">Delete</a>';
		        }

		        // Process Pending Payment
            	/*if(user_service_name == sa_role) {
            		kebab_item += '<a class="dropdown-item process_pending_payment" data-module="Property" data-id="'+response.Properties[key].id+'" href="javascript:void(0)">Process Pending Payment</a>';

            		var payment_type_transfer = user.user_details.payment_type_transfer;
            		if(payment_type_transfer == 2) {
            			kebab_item += '<a class="dropdown-item pay_to_property_owner" data-module="Property" data-id="'+response.Properties[key].id+'" href="javascript:void(0)">Pay to Property Owner</a>';	
            		}
            	}*/

		        if($.trim(kebab_item) != '' && user_service_name != t_role) {
		        	kebab = '<div class="text-center"><div class="input-group-prepend"><i class="align-middle mr-2 fas fa-fw fa-ellipsis-v" data-toggle="dropdown" aria-expanded="false"></i><div class="dropdown-menu dropdown-menu-right" style="">'+kebab_item+'</div></div></div>';
		        }

		        // Payment status conditions
		        if(user_service_name == sa_role) {
		        	if(response.Properties[key].payment_status == 1) {
			        	payment_status += '<div class="badge badge-danger">'+response.Properties[key].payment_status_label+'</div>';
			        } else if(response.Properties[key].payment_status == 2) {
			        	payment_status += '<div class="badge badge-warning">'+response.Properties[key].payment_status_label+'</div>';
			        } else if(response.Properties[key].payment_status == 3) {
			        	payment_status += '<div class="badge badge-primary">'+response.Properties[key].payment_status_label+'</div>';
			        } else if(response.Properties[key].payment_status == 4) {
			        	payment_status += '<div class="badge badge-success">'+response.Properties[key].payment_status_label+'</div>';
			        }
			        html += '<div data-id="'+p_id+'" data-user-id="'+response.Properties[key].user_id+'" data-status="'+response.Properties[key].payment_status+'" class="col-12 col-md-6 col-xl-3 property_card user_properties_list">';
		        } else {
			        if(response.Properties[key].payment_status == 1) {
			        	payment_status += '<div class="badge badge-danger">'+response.Properties[key].payment_status_label+'</div>';
			        	html += '<div data-property-setup-fee_authorized="'+response.Properties[key].property_setup_fee_authorized+'" data-payment-id="'+response.Properties[key].payment_details_id+'" data-property-name="'+response.Properties[key].name+'" data-id="'+p_id+'" data-user-id="'+response.Properties[key].user_id+'" data-status="'+response.Properties[key].payment_status+'" data-toggle="modal" data-target="#propertyCheckoutModal" data-backdrop="static" data-keyboard="false" class="col-12 col-md-6 col-xl-3 property_card property_setup_fee">';
			        } else if(response.Properties[key].payment_status == 2) {
			        	payment_status += '<div class="badge badge-warning">'+response.Properties[key].payment_status_label+'</div>';
			        	html += '<div data-id="'+p_id+'" data-user-id="'+response.Properties[key].user_id+'" data-status="'+response.Properties[key].payment_status+'" class="col-12 col-md-6 col-xl-3 property_card property_pending_review">';
			        } else if(response.Properties[key].payment_status == 3) {
			        	payment_status += '<div class="badge badge-primary">'+response.Properties[key].payment_status_label+'</div>';
			        	html += '<div data-id="'+p_id+'" data-user-id="'+response.Properties[key].user_id+'" data-status="'+response.Properties[key].payment_status+'" class="col-12 col-md-6 col-xl-3 property_card processing_setup_fee">';
			        } else if(response.Properties[key].payment_status == 4) {
			        	payment_status += '<div class="badge badge-success">'+response.Properties[key].payment_status_label+'</div>';
			        	html += '<div data-id="'+p_id+'" data-user-id="'+response.Properties[key].user_id+'" data-status="'+response.Properties[key].payment_status+'" class="col-12 col-md-6 col-xl-3 property_card user_properties_list">';
			        }
			    }

			    var name = stripHtml(property_name);
				/*if (name.length > 20) {
				    var shortname = name.substring(0, 20) + " ...";
				    name = shortname;
				}*/

				var po_detail = '';
				if(user_service_name != 'c963a422-edb4-4bad-97ed-276c5abb3b79') {
			        po_detail = '<h5>Property owner detail:</h5>'+response.Properties[key].user.first_name+' '+response.Properties[key].user.last_name+'<br>'+response.Properties[key].user.email;
			    }
		        
		        //html += '<div class="card"><div class="card-header"><span class="accordion_arrow">'+kebab+' </span><h5 id="p_name_'+p_id+'" class="card-title capitalize mb-0 mr-3" title="'+stripHtml(property_name)+'">'+name+'</h5></div><img class="card-img-top" src="'+cover_image+'" alt="Unsplash"><div class="card-body"><div class="capitalize">'+stripHtml(address_line1)+stripHtml(address_line2)+'<br>'+city+'<br>'+state+' '+zip_code+'</div><div class="mt-4">'+po_detail+'</div><div class="text-right mt-4">'+payment_status+'</div></div></div></div>';

		        var cover_image = '';
		        if(response.Properties[key].cover_image != null) {
		        	cover_image = response.Properties[key].cover_image.aws_url;
		        } else {
		        	cover_image = "img/sample/home-placeholder-profile.png";
		        }

		        var phone = response.Properties[key].user.phone;
		        if(phone != null) {
		        	phone = phone.split('+1');
		        	phone = phone[1];
		        } else {
		        	phone = '&nbsp';
		        }

		        var lease_status = '';
		        var bg_class = '';
		        if(response.Properties[key].lease_status == 4) {
		        	lease_status += '<div class="badge badge-danger">Lease Expired</div>';
		        	bg_class = 'bg-azure';
		        }

		        html += '<div class="card">';
					html += '<div class="card-header"><span class="accordion_arrow">'+kebab+' </span><h5 id="p_name_'+p_id+'" class="card-title capitalize mb-0 mr-3" title="'+stripHtml(property_name)+'">'+name+'</h5></div>';
					html += '<div class="img-container b-top b-bottom">';
						html += '<img src="'+cover_image+'" alt="Property Image">';
					html += '</div>';
					html += '<div class="card-body '+bg_class+'">';
						html += '<table class="table mb-0 card-table word-break">';
							html += '<tbody>';
								if(user_service_name != 'c963a422-edb4-4bad-97ed-276c5abb3b79') {
									html += '<tr>';
										html += '<td class="nowrap"><i class="far fa-user mr-1"></i></td>';
										html += '<td class="text-left bold-black">'+response.Properties[key].user.first_name+' '+response.Properties[key].user.last_name+'</td>';
									html += '</tr>';
								}
									html += '<tr>';
										html += '<td class="nowrap"><i class="far fa-address-card mr-1"></i></td>';
										html += '<td class="text-left"><div class="capitalize">'+stripHtml(address_line1)+stripHtml(address_line2)+'<br>'+city+', '+state+' '+zip_code+'</div></td>';
									html += '</tr>';
								if(user_service_name != 'c963a422-edb4-4bad-97ed-276c5abb3b79') {
									html += '<tr>';
										html += '<td class="nowrap"><i class="far fa-envelope mr-1"></i></td>';
										html += '<td class="text-left">'+response.Properties[key].user.email+'</td>';
									html += '</tr>';
									html += '<tr>';
										html += '<td class="nowrap"><i class="fas fa-phone mr-1"></i></td>';
										html += '<td class="text-left phone_number">'+phone+'</td>';
									html += '</tr>';
								}
							html += '</tbody>';
						html += '</table>';
						html += '<div class="text-right mt-4">'+lease_status+' '+payment_status+'</div>';
					html += '</div>';
				html += '</div>';
				html += '</div>';
		    }
		}
    }

    $('.property_data_container').html(html);
    $('.phone_number').mask('(000) 000-0000');
    // Get Floating Action Button
	getFloatingActionButton('Property', '.property_data_container');
}

window.loadTablePropertiesDetail = function() {
	localStorage.removeItem('property_name');
	var user = JSON.parse(localStorage.getItem('user'));
	var user_service_name = user.user_details.service_constant;
	
	if(user_service_name == sa_role) {
		var method = 'GET';
		var url = api_url+"properties";
		var data = '';
	} else {
		var method = 'POST';
		var url = api_url+"properties/details";
		var data = 'user_id='+user.user_details.user_id+'&user_service_name='+user_service_name;
	}

    $.ajax({
        method: method,
        url: url,
        data:data,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
    }).then(function(response) {
        if(response.response_data.success == true) {
        	var html = '';
        	var table_html = '';
			if(response.TotalProperties == 0) {
	        	html += '<a href="javascript:void(0)" class="list-group-item list-group-item-action border-0 bg-light text-center"><div class="fs-16">'+response.response_data.message+'</div></a>';
		    } else {
	            for (var key in response.Properties) {
	                if (response.Properties.hasOwnProperty(key)) {

	                	// Cover Image
	                	var cover_image = '';
		                if(response.Properties[key].cover_image != null) {
		                	cover_image = response.Properties[key].cover_image.aws_url;
		                } else {
		                	cover_image = "icon/property.svg";
		                }

		                // Address detail
	                	var p_id = response.Properties[key].id;
	                    var name = response.Properties[key].name;
	                    var property_name = name.toLowerCase();
	                    var name = stripHtml(property_name);
	                    var address_line1 = "";
				        var address_line2 = "";
				        var city = "";
				        var state = "";
				        var zip_code = "";
				        var payment_status = "";
				        var kebab = "";

				        if(response.Properties[key].address != null) {
				        	if(response.Properties[key].address.address_line1 != "") {
				                address_line1 = response.Properties[key].address.address_line1;
				                address_line1 = address_line1.toLowerCase();
				            }
				            if(response.Properties[key].address.address_line2 != "") {
				                address_line2 = response.Properties[key].address.address_line2
				                address_line2 = ', '+address_line2.toLowerCase();
				            }
				            if(response.Properties[key].address.city != "") {
				                city = response.Properties[key].address.city;
				                city = city.toLowerCase();
				            }
				            if(response.Properties[key].address.state != "") {
				                state = response.Properties[key].address.state;
				                state = state.toUpperCase();
				            }
				            if(response.Properties[key].address.zip_code != "") {
				                zip_code = response.Properties[key].address.zip_code;
				            }
				        }

				        // PO detail
				        var po_detail = response.Properties[key].user.first_name+' '+response.Properties[key].user.last_name+'<br>'+response.Properties[key].user.email;

				        // Set kebab menu conditions
				        var kebab_item = '';
		                if(user_service_name == sa_role || (user_service_name == po_role && response.Properties[key].payment_status == 1)) {
		                	kebab_item += '<a class="dropdown-item edit_property_address" href="javascript:void(0)" data-id="'+p_id+'" data-toggle="modal" data-target="#propertyEditModal" data-backdrop="static" data-keyboard="false">Edit</a>';
		                }

				        if(user_service_name == sa_role && response.Properties[key].payment_status == 2) {
				        	kebab_item = '<a class="dropdown-item process_property_setup_fee" href="javascript:void(0)" data-id="'+p_id+'">Process Setup Fee</a>';
				        }

				        kebab_item += '<a data-id="'+response.Properties[key].id+'" data-module="Property" class="dropdown-item delete_property_data" href="javascript:void(0)">Delete</a>';

				        // Payment status conditions
				        var table_row = '';
				        if(user_service_name == sa_role) {
				        	if(response.Properties[key].payment_status == 1) {
					        	payment_status += '<div class="badge badge-danger">'+response.Properties[key].payment_status_label+'</div>';
					        } else if(response.Properties[key].payment_status == 2) {
					        	payment_status += '<div class="badge badge-warning">'+response.Properties[key].payment_status_label+'</div>';
					        } else if(response.Properties[key].payment_status == 3) {
					        	payment_status += '<div class="badge badge-primary">'+response.Properties[key].payment_status_label+'</div>';
					        } else if(response.Properties[key].payment_status == 4) {
					        	payment_status += '<div class="badge badge-success">'+response.Properties[key].payment_status_label+'</div>';
					        }
					        table_html += '<tr>';
					        kebab_item += '<a data-id="'+response.Properties[key].id+'" data-user-id="'+response.Properties[key].user_id+'" data-status="'+response.Properties[key].payment_status+'" class="dropdown-item property_table_row" href="javascript:void(0)">View</a>';
				        } else {
			                if(response.Properties[key].payment_status == 1) {
					        	payment_status += '<div class="badge badge-danger">'+response.Properties[key].payment_status_label+'</div>';
					        	table_html += '<tr>';
					        	kebab_item += '<a data-id="'+response.Properties[key].id+'" data-user-id="'+response.Properties[key].user_id+'" data-status="'+response.Properties[key].payment_status+'" class="dropdown-item property_setup_fee" href="javascript:void(0)" data-toggle="modal" data-target="#propertyCheckoutModal" data-backdrop="static" data-keyboard="false">View</a>';
					        } else if(response.Properties[key].payment_status == 2) {
					        	payment_status += '<div class="badge badge-warning">'+response.Properties[key].payment_status_label+'</div>';
					        	table_html += '<tr>';
					        	kebab_item += '<a data-payment-id="'+response.Properties[key].payment_details_id+'" data-property-name="'+response.Properties[key].name+'" data-id="'+response.Properties[key].id+'" data-user-id="'+response.Properties[key].user_id+'" data-status="'+response.Properties[key].payment_status+'" class="dropdown-item property_pending_review" href="javascript:void(0)">View</a>';
					        } else if(response.Properties[key].payment_status == 3) {
					        	payment_status += '<div class="badge badge-primary">'+response.Properties[key].payment_status_label+'</div>';
					        	table_html += '<tr>';
					        	kebab_item += '<a data-id="'+response.Properties[key].id+'" data-user-id="'+response.Properties[key].user_id+'" data-status="'+response.Properties[key].payment_status+'" class="dropdown-item processing_setup_fee" href="javascript:void(0)">View</a>';
					        } else if(response.Properties[key].payment_status == 4) {
					        	payment_status += '<div class="badge badge-success">'+response.Properties[key].payment_status_label+'</div>';
					        	table_html += '<tr>';
					        	kebab_item += '<a data-id="'+response.Properties[key].id+'" data-user-id="'+response.Properties[key].user_id+'" data-status="'+response.Properties[key].payment_status+'" class="dropdown-item property_table_row" href="javascript:void(0)">View</a>';
					        }
					    }

					    if($.trim(kebab_item) != '' && user_service_name != t_role) {
				        	kebab = '<div class="text-center"><div class="input-group-prepend"><i class="align-middle mr-2 fas fa-fw fa-ellipsis-v" data-toggle="dropdown" aria-expanded="false"></i><div class="dropdown-menu dropdown-menu-right" style="">'+kebab_item+'</div></div></div>';
				        }

				        if(user_service_name == t_role) {
				        	kebab = '<div class="text-center"><div class="input-group-prepend"><i class="align-middle mr-2 fas fa-fw fa-ellipsis-v" data-toggle="dropdown" aria-expanded="false"></i><div class="dropdown-menu dropdown-menu-right" style=""><a data-id="'+response.Properties[key].id+'" data-status="'+response.Properties[key].payment_status+'" class="dropdown-item property_table_row" href="javascript:void(0)">View</a></div></div></div>';	
				        }

					    var checked = '';
					    if(response.Properties[key].is_late_fee_enabled == 1) {
					    	checked = 'checked';
					    }

					    var checkbox = '';
	                    checkbox += '<label class="custom-control custom-checkbox">';
			            checkbox += '<input type="checkbox" name="late_fee_checkbox" class="custom-control-input late_fee_checkbox" data-id="'+response.Properties[key].id+'" '+checked+'>';
			            checkbox += '<span class="custom-control-label text-hide">Checkbox</span>';
			            checkbox += '</label>';

					    // Table based UI
	                		table_html += '<td class="bg-light">'+response.Properties[key].id+'</td>';
	                		table_html += '<td class="bg-light"><h5 id="p_name_'+response.Properties[key].id+'" title="'+property_name+'" class="card-title my-2 fs-16">'+name+'</h5><div class="small">'+address_line1+', '+address_line2+'<br>'+city+', '+state+' '+zip_code+'</div></td>';
	                		table_html += '<td>'+po_detail+'</td>';
                            table_html += '<td class="text-center"><img src="'+cover_image+'" class="rounded-circle mr-1" alt="property name" width="50" height="50"></td>';
                            table_html += '<td class="text-center">'+payment_status+'</td>';
                            table_html += '<td class="text-center">'+kebab+'</td>';
                            if(user_service_name == sa_role) {
                            	table_html += '<td class="text-center">'+checkbox+'</td>';
                            	$('#late_fee_column').removeClass('d-none');
                            } else {
                            	$('#late_fee_column').remove();
                            }
                        table_html += '</tr>';

	                }
	            }
        	}

        	// Table UI

			$('#property_table_row').html(table_html);
			$('#propertyDataContainer').html(html);
        	// Get Floating Action Button
			getFloatingActionButton('Property', '#propertyDataContainer');

			var property_table = $("#property-datatable").DataTable({
				dom: 'Bfrtip',
    			autoWidth: true,
            	paging: false,
            	info: false,
			    scrollY: '550px',
			    scrollX: true,
		        scrollCollapse: true,
		        order: [[0,'desc']],
		        fixedColumns:   {
		            left: 2
		        },
		        buttons: [
		           {
		              extend: 'colvis',
		              text: "Columns Visibility ",
		              collectionLayout: 'two-column',
		              postfixButtons: ['colvisRestore'],
		           }
				],
				language: {
			        buttons: {
			          colvisRestore: "Select All"
			        }
			    },
				initComplete: function () {
		          	//console.log("The Table has been initialized!")
		        }
			});

			$('.buttons-colvis').removeClass('btn-secondary').addClass('btn-primary');
        	
        } else {
            display_error_message(response);
        }
    }, function() {
        // hang on this step if the error occur
    });
}

window.loadPropertyDetailLayout = function(property_id, open_from, property_name) {
	$('.card_list_heading').hide();
	if(property_name == '') {
		var property_name = $('#p_name_'+property_id).attr('title');
	}    
    if (localStorage.getItem("property_name") === null) {
    	localStorage.setItem('property_name',property_name);
    }
    $('#open_from').val(open_from);
    openLoadingDialog($('#main_container'));
    setTimeout(function(){
	    $('#main_container').load('components/property/detail.html', property_id , function(){
	    	$('#selected_property_id').val(property_id);
	        $('#open_from').val(open_from);
	        //getPropertyDetail(property_id,open_from);
	        $('.property_name').html(property_name);
	        $('#navbar_role_name').html(property_name);
	        $('.back_button').attr('data-layout',open_from);

	        if(!user.permissionData['PROPERTIES']["feature_items['lease_list']"]) {
	        	$('a[href="#p-lease"]').closest('li').remove();
				$('#p-lease').remove();
	        }

	        if(!user.permissionData['PROPERTIES']["feature_items['accounting_list']"]) {
	        	$('a[href="#p-accounting"]').closest('li').remove();
	        	$('#p-accounting').remove();
	        }

	        if(!user.permissionData['PROPERTIES']["feature_items['tenant_list']"]) {
	        	$('a[href="#p-tenants"]').closest('li').remove();
	        	$('#p-tenants').remove();
	        }

	        if(!user.permissionData['SUPPORT']["feature_items['list']"]) {
	        	$('a[href="#p-service-requests"]').closest('li').remove();
	        	$('#p-service-requests').remove();
	        }

	        getFloatingActionButton('PropertyDetail', '#main_container');
	    });
	}, 500);
}

window.updateLateFeeValue = function(property_id, late_fee_flag) {
	var user_id = user.user_details.user_id;
	$.ajax({
        method: 'POST',
        url: api_url+"properties/update-late-fee-value",
        data: 'user_id='+user_id+'&property_id='+property_id+'&late_fee_flag='+late_fee_flag,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	display_error_message(response);
        }
    });
}

window.checkIfPropertySetupFeeAuthorized = function(property_id) {
	var user_id = user.user_details.user_id;
	$.ajax({
        method: 'POST',
        url: api_url+"properties/check-if-property-setup-fee-authorized",
        data: 'user_id='+user_id+'&property_id='+property_id,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	if(response.isSetupFeeAuthorized == 1) {
				openLoadingDialog($('#signup_data_container'));

        		// Display Rent Deposit and Subscription Fee Payment Authorization Model for Linking payment method
        		openLoadingDialog($('#signup_data_container'));
				setTimeout(function(){
					getBankListForProperty(user_id, property_id, response.property_name);
				}, 500);
            } else {
    			checkIfPropertySetupPlanAvailable(property_id);        	
            }
        }
    });
}

window.checkIfPropertySetupPlanAvailable = function(property_id) {
	var user_id = user.user_details.user_id;
	$.ajax({
        method: 'POST',
        url: api_url+"properties/get-setup-checkout-data",
        data: 'user_id='+user_id+'&property_id='+property_id,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	if(response.response_data.success == true) {

        		// Below code for initial signup step
				var first_time_signup = $('#first_time_signup').val();
				if(first_time_signup == 1) {
					// Checkilist step 9
					getSignupNextStep(9,10);

					var user = JSON.parse(localStorage.user);
					user.user_details.cheklist_seq_no = 9;
					localStorage.setItem("user", JSON.stringify(user));  //put the object back

					openLoadingDialog($('#signup_data_container'));
					var container = '#signup_data_container';
				} else {
					var container = '#propertyCheckoutModal #property_setup_checkout_container';
				}

        		if(response.plan == null) {
        			$(container).load('components/property/property_setup_fees.html', property_id, function(){
        				var message = '<a href="javascript:void(0)" class="list-group-item list-group-item-action border-0 bg-light text-center"><div class="fs-16">'+response.response_data.message+'</div></a>';
        				$('#property_setup_checkout_form').html(message);

        				if(first_time_signup == 1) {
		        			$('#signup_data_container .fa-window-close').remove();
		        			$('#signup_data_container .card').addClass('popup-card-fixed-width');
		        			$('#signup_data_container .accordion').removeAttr('id');
		        		}
        			});	
        		} else {
        			openLoadingDialog($(container));
        			setTimeout(function(){
	        			$(container).load('components/property/property_setup_fees.html', property_id, function(){
							$('#newly_added_property_id').val(property_id);

							$("#plan_id").val(response.plan.id);
							$("#plan_name").html(response.plan.name);
		        			$('#property_setup_fee_container').html('$'+response.plan.plan_pricings[0].property_setup_fee);
		        			$('#property_setup_fee_input').val(response.plan.plan_pricings[0].property_setup_fee);

		        			$("#coupon_id").val(0);
		        			$("#coupon_amount").val(0.00);

		        			$('#property_setup_fee_subtotal_container').html('$'+response.plan.plan_pricings[0].property_setup_fee);
		        			$('#property_setup_fee_subtotal_input').val(response.plan.plan_pricings[0].property_setup_fee);

		        			$('#property_setup_fee_tax_container').html('$0.00');
		        			$('#property_setup_fee_tax_amount_input').val(0.00);
		        			$('#property_setup_fee_tax_percentage_input').val(0);

		        			$('#property_setup_fee_total_container').html('$'+response.plan.plan_pricings[0].property_setup_fee);
		        			$('#property_setup_fee_total_input').val(response.plan.plan_pricings[0].property_setup_fee);

		        			var html = "<option value=''>Select Payment Method</option>";
					        if(response.paymentDetails != null) {
				            	for (var key in response.paymentDetails) {
					                if (response.paymentDetails.hasOwnProperty(key)) {
				                		html += '<option value="'+response.paymentDetails[key].id+'">'+response.paymentDetails[key].bank_name+' ('+response.paymentDetails[key].account_number+')</option>';
									}
								}
							}

					        $('#property_setup_payment_method').html(html);

					        // Apply coupon code which applied when added new property
					        if(response.subscriptionDetail != null && response.subscriptionDetail.coupon != null) {
					        	if(response.subscriptionDetail.coupon.type == 2 || response.subscriptionDetail.coupon.type == 3) {
					        		var coupon_code = response.subscriptionDetail.coupon.code;
					        		var trigger_from = "after_property_add";
					        		applyCouponCode('property_setup_fee', coupon_code, trigger_from);
					        	}
					        }

							if(first_time_signup == 1) {
			        			$('#signup_data_container .fa-window-close').remove();
			        			$('#signup_data_container .card').addClass('popup-card-fixed-width');
			        			$('#signup_data_container .accordion').removeAttr('id');
			        		}
						});
					}, 500);
        		}
            } else {
            	display_error_message(response);
            }
        }
    });
}

window.applyCouponCode = function(type, coupon_code, trigger_from) {
	//$('.apply_coupon_code').attr('disabled',true);
	var user_id = user.user_details.user_id;
	var property_name = '';
	if($('#add_property_address_line1').val() != null) {
		property_name =  $('#add_property_address_line1').val()+' '+$('#add_property_address_line2').val();	
	}
	
	$.ajax({
        method: 'POST',
        url: api_url+"properties/apply-coupon",
        data: 'user_id='+user_id+'&type='+type+'&coupon_code='+coupon_code+'&property_name='+property_name+'&trigger_from='+trigger_from,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	//$('.apply_coupon_code').attr('disabled',false);
        	if(response.response_data.success == true) {

        			$("#property_coupon_id").val(response.coupon_id);
        			$("#property_plan_id").val(response.plan_id);

        			$('#coupon_code').attr('readonly',true);
        			$('#apply_coupon_code').text('Clear');
        			$('#apply_coupon_code').removeClass('apply_coupon_code');
        			$('#apply_coupon_code').addClass('remove_coupon');

        			$("#coupon_id").val(response.coupon_id);
        			$("#coupon_amount").val(response.couponAmount);
        			$("#property_setup_fee_coupon_container").html('$'+response.couponAmount);
        			$("#applied_coupon").html('<strong>Coupon Applied '+coupon_code);
        			
        			$('#property_setup_fee_subtotal_container').html('$'+response.finalAmont);
        			$('#property_setup_fee_subtotal_input').val(response.finalAmont);

        			$('#property_setup_fee_total_container').html('$'+response.finalAmont);
        			$('#property_setup_fee_total_input').val(response.finalAmont);

        			if(response.finalAmont <= 0) {
        				$('#property_setup_payment_method').attr('disabled',true);
        			} else {
        				$('#property_setup_payment_method').attr('disabled',false);
        			}
            } else {
            	display_error_message(response);
            }
        }
    });
}

/*window.removeCouponCode = function(subscription_plan_id) {
	var user_id = user.user_details.user_id;
	var property_name =  $('#add_property_address_line1').val()+' '+$('#add_property_address_line2').val();
	$.ajax({
        method: 'POST',
        url: api_url+"properties/remove-coupon",
        data: 'subscription_plan_id='+subscription_plan_id,
        data: 'user_id='+user_id+'&subscription_plan_id='+subscription_plan_id+'&property_name='+property_name,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	display_error_message(response);
        	if(response.response_data.success == true) {

            }
        }
    });
}*/

window.completePropertyTransaction = function() {

	$('.complete_property_transaction').attr('disabled',true);

	var user_id = user.user_details.user_id;
	var property_id = $('#newly_added_property_id').val();
	var plan_id = $('#plan_id').val();
	var payment_type = 1;
	var item_name = $('#plan_name').text();
	var price = $('#property_setup_fee_input').val();
	var coupon_id = $('#coupon_id').val();
	var coupon_amount = $('#coupon_amount').val();
	var subtotal = $('#property_setup_fee_subtotal_input').val();
	var tax_percentage = $('#property_setup_fee_tax_percentage_input').val();
	var tax_amount = $('#property_setup_fee_tax_amount_input').val();
	var total = $('#property_setup_fee_total_input').val();
	var payment_details_id = $('#property_setup_payment_method').val();

	if($('#property_payment_agree').is(":checked")) {
		var status = 1;
	} else {
		var status = 0;
	}

	var first_time_signup = $('#first_time_signup').val();

	var data = 'user_id='+user_id+'&property_id='+property_id+'&plan_id='+plan_id+'&payment_type='+payment_type+'&price='+price+'&coupon_id='+coupon_id+'&coupon_amount='+coupon_amount+'&sub_total='+subtotal+'&tax_percentage='+tax_percentage+'&tax_amount='+tax_amount+'&total='+total+'&payment_details_id='+payment_details_id+'&status='+status+'&first_time_signup='+first_time_signup;

	$.ajax({
        method: 'POST',
        url: api_url+"properties/save-setup-checkout-data",
        data: data,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	display_error_message(response);
        	if(response.response_data.success == true) {
        		var property_name = $('#p_name_'+property_id).attr('title');
        		// Below code for initial signup step
				var first_time_signup = $('#first_time_signup').val();
				if(first_time_signup == 1) {

					// Checkilist step 10
					getSignupNextStep(10,11);

					var user = JSON.parse(localStorage.user);
					user.user_details.cheklist_seq_no = 10;
					localStorage.setItem("user", JSON.stringify(user));  //put the object back

					openLoadingDialog($('#signup_data_container'));
					$('#signup_data_container').load('components/property/add_new_tenant.html', function() {
						$('.card-header').remove();
						$('#signup_data_container .card').removeClass('border');
						$('.save_tenant_data').remove();
						$('.save_and_submit_tenant_data').removeClass('d-none');
						$('.save_and_submit_tenant_data').text('Next');
					});
				} else {
	        		$('#propertyCheckoutModal').modal('hide');
	        		$('.modal-backdrop').remove();

	        		var layout = $('#selected_layout').val();
	            	if(layout == 'card_layout') {
						$('#main_container').load('components/properties.html');
					} else {
						$('#main_container').load('components/property/property_table.html');
					}

	        		// Display Rent Deposit and Subscription Fee Payment Authorization Model for Linking payment method
	        		setTimeout(function(){
		        		if(response.proeprty_payment_details_id == 0) {
		        			$('#linkPropertyModal').modal('show');
							openLoadingDialog($('#bp_dropdown_container'));
							setTimeout(function(){
								getBankListForProperty(response.property_user_id, property_id, property_name);
							}, 500);
		        		}
		        	}, 1000);
				}
            } else {
            	$('.complete_property_transaction').attr('disabled',false);
            }
        }
    });
}

window.processPropertySetupFees = function(property_id) {
	var user_id = user.user_details.user_id;
	$.ajax({
        method: 'POST',
        url: api_url+"payment-details/process-setup-fee",
        data: 'user_id='+user_id+'&property_id='+property_id,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	display_error_message(response);
        	if(response.response_data.success == true) {
        		var layout = $('#selected_layout').val();
            	if(layout == 'card_layout') {
					$('#main_container').load('components/properties.html');
				} else {
					$('#main_container').load('components/property/property_table.html');
				}
            }

            $('.process_property_setup_fee').attr('disabled', false);
        }
    });
}

window.getPropertyLateFeeDetail = function(property_id, property_name) {
	var user_id = user.user_details.user_id;
	var tz = jstz.determine();
	var timezone = tz.name();
	$.ajax({
        method: 'POST',
        url: api_url+"scheduled-payments/get-late-fee-data",
        data: 'user_id='+user_id+'&property_id='+property_id+'&user_timezone='+timezone,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	display_error_message(response);
        	if(response.response_data.success == true) {
        		var html = '';
            	html += '<form id="rent-collection-wizard" class="wizard-primary create-account-form" action="javascript:void(0)">';
				    html += '<div id="payNowCollapse">';
				    for (var key in response.scheduledPayments) {
	                	if (response.scheduledPayments.hasOwnProperty(key)) {

				    		/*var line = '(One time payment)';
		            		if(response.scheduledPayments[key].payment_type == 2 || response.scheduledPayments[key].payment_type == 7) {
		            			line = 'for the month of '+response.scheduledPayments[key].payment_due_date2;
		            		}*/

		            		var payment_due_date = response.scheduledPayments[key].payment_due_date;
		            		var formatted_payment_due_date = getFormattedDate(payment_due_date);

		            		html += '<input type="hidden" name="scheduled_payment_id[]" id="scheduled_payment_'+key+'" class="schedule_payment_hidden_input" value="'+response.scheduledPayments[key].id+'">';

					        html += '<div class="rent-detail-card p-3 mb-1 bg-white">';
					            html += '<div class="border-bottom">';
					                html += '<h5 class="card-title"><span class="fs-22">'+response.scheduledPayments[key].payment_due_date2+'</span></h5>';
					            html += '</div>';
					            html += '<div class="rent_collection_data mt-2">';
					                html += '<div class="row">';
					                	html += '<div class="col-12 col-md-8 col-xl-8">';
										    html += '<div class="mt-2">';
										        html += '<div class="mb-2">(A) Rent Due by '+formatted_payment_due_date+'</div>';
										    html += '</div>';
										html += '</div>';
										html += '<div class="col-12 col-md-4 col-xl-4 text-right">';
					                        html += '<div class="mt-2">';
					                            html += '<div class="black"><strong>$ '+response.scheduledPayments[key].formatted_total+'</strong></div>';
					                        html += '</div>';
					                    html += '</div>';
					                html += '</div>';
					                html += '<div class="row">';
					                	html += '<div class="col-12 col-md-8 col-xl-8">';
										    html += '<div class="mt-2">';
										        html += '<div class="mb-2 ml-4">(1) No. of days late after '+response.scheduledPayments[key].lateFeeafterDays+' day of the month</div>';
										    html += '</div>';
										html += '</div>';
										html += '<div class="col-12 col-md-4 col-xl-4 text-right">';
					                        html += '<div class="mt-2">';
					                            html += '<div>'+response.scheduledPayments[key].no_of_days+' day(s)</div>';
					                        html += '</div>';
					                    html += '</div>';
					                html += '</div>';
					                html += '<div class="row">';
					                	html += '<div class="col-12 col-md-8 col-xl-8">';
										    html += '<div class="mt-2">';
										        html += '<div class="mb-2 ml-4">(2) Late fee applied per day</div>';
										    html += '</div>';
										html += '</div>';
										html += '<div class="col-12 col-md-4 col-xl-4 text-right">';
					                        html += '<div class="mt-2">';
					                            html += '<div>$ '+response.scheduledPayments[key].price_per_day+'</div>';
					                        html += '</div>';
					                    html += '</div>';
					                html += '</div>';
					                html += '<div class="row">';
					                	html += '<div class="col-12 col-md-8 col-xl-8">';
										    html += '<div class="mt-2">';
										        html += '<div class="mb-2">(B) Outstanding late fee (1) x (2)</div>';
										    html += '</div>';
										html += '</div>';
										html += '<div class="col-12 col-md-4 col-xl-4 text-right">';
					                        html += '<div class="mt-2">';
					                            html += '<div class="black"><strong>$ '+response.scheduledPayments[key].formatted_late_fee_amount+'</strong></div>';
					                        html += '</div>';
					                    html += '</div>';
					                html += '</div>';
					                html += '<div class="row">';
					                	html += '<div class="col-12 col-md-8 col-xl-8">';
										    html += '<div class="mt-2">';
										        html += '<div class="mb-2">(C) Late fee waived</div>';
										    html += '</div>';
										html += '</div>';
										html += '<div class="col-12 col-md-4 col-xl-4 text-right">';
					                        html += '<div class="mt-2">';
					                            html += '<div class="black"><strong>$ '+response.scheduledPayments[key].formatted_waived_amount+'</strong></div>';
					                        html += '</div>';
					                    html += '</div>';
					                html += '</div>';
					                html += '<div class="row border-top">';
					                	html += '<div class="col-12 col-md-8 col-xl-8">';
										    html += '<div class="mt-2">';
										        html += '<div class="mb-2"><strong>Total due as of '+response.scheduledPayments[key].current_date+'</strong> (A) + (B) - (C)</div>';
										    html += '</div>';
										html += '</div>';
										html += '<div class="col-12 col-md-4 col-xl-4 text-right">';
					                        html += '<div class="mt-2">';
					                            html += '<div class="black fs-18"><strong>$ '+response.scheduledPayments[key].formatted_totalSum+'</strong></div>';
					                        html += '</div>';
					                    html += '</div>';
					                html += '</div>';
					                html += '<div class="row">';
					                	html += '<div class="col-12 col-md-8 col-xl-8">';
										    html += '<div class="mt-2">';
										        html += '<div class="black mb-2"><strong>Waive Late Fee</strong></div>';
										    html += '</div>';
										html += '</div>';
										html += '<div class="col-12 col-md-4 col-xl-4 text-right">';
					                        html += '<div class="mt-2">';
					                            html += '<div class="black"><input type="hidden" name="late_fee_amount[]" id="late_fee_amount_'+response.scheduledPayments[key].id+'" value="'+response.scheduledPayments[key].late_fee_amount+'" class="form-control"><input type="text" name="waive_late_fee[]" id="waive_late_fee_'+key+'" data-id="'+response.scheduledPayments[key].id+'" class="form-control border allow_numeric_with_decimal format_amount waived_late_fee_input" placeholder="Enter amount"></div>';
					                        html += '</div>';
					                    html += '</div>';
					                html += '</div>';
					            html += '</div>';
					        html += '</div>';
					    }
					}
				    html += '</div>';
				html += '</form>';
        		$('#waive_late_fee_container').html(html);
            }
        }
    });
}

window.saveWaivedLateFeeData = function(spArray) {
	var tz = jstz.determine();
	var timezone = tz.name();
	$.ajax({
        method: 'POST',
        url: api_url+"scheduled-payments/waive-late-fee",
        data: 'sp_array='+spArray+'&user_timezone='+timezone+'&user_id='+user.user_details.user_id,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	$('.waive_late_fee').attr('disabled', false);
        	display_error_message(response);
            if(response.response_data.success == true) {
            	$('#waiveLateFeeModal').modal('hide');
            	loadVerticalProperty();
            }
        }
    });
}

window.getLeaseListForProperty = function(property_id, property_name) {
	$.ajax({
       	method: 'POST',
	    url: api_url+"leases",
	    data:'property_id='+property_id,
	    beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	display_error_message(response);
            if(response.response_data.success == true) {
            	if(response.isFMPAuthReqSent == 0) {
	            	var html = '';
	            	html += '<div class="rent-detail-card text-center p-3 bg-white"><h5 class="card-title mb-0 grey">Select lease for property</h5><div class="fs-18 mt-2 bold-black capitalize">'+property_name+'</div></div>';
	            	html += '<input type="hidden" name="selected_property_id" id="selected_property_id" value="'+property_id+'">';
	            	html += '<select name="lease_property_dd" class="form-control lease_property_dd mt-3">';
		            	html += "<option value=''>Select Lease</option>";
		            	for (var key in response.leases) {
			                if (response.leases.hasOwnProperty(key)) {
			                	if(response.leases[key].status == 1) {
			                		var lease_date = response.leases[key].start_date+' - '+response.leases[key].end_date+' ('+response.leases[key].status_label+')'+' ($'+response.leases[key].monthly_rent_amount+')'+' ('+response.leases[key].tenant+')';
			                		html += '<option value="'+response.leases[key].id+'">'+lease_date+'</option>';
			                	}
							}
						}
					html += '</select>';
					html += '<div id="tenant_dropdown_container" class="mt-3"></div>';

					// Below code will check that from where function is called based on that it will load data
	        		var rs_invite_tenant = $('#rs_invite_tenant').val();
					if(rs_invite_tenant == 1) {
						$('#invite_tenant_data_container').html(html);
					} else {
						$('#lease_dropdown_container').html(html);
					}
				} else {
					$('#invite_tenant_data_container').html('<div id="msg_div" class="text-center mt-5 pt-5"><h3 class="green">'+response.FMPmessage+'</h3></div>');
					$('#invite_tenant_data_container').append('<div id="brd-top" class="mt-5 pt-5"><div class="row"><div class="col-auto ml-auto text-right mt-2 mb-2 mr-2"><button type="button" class="btn btn-orange go_to_invite_tenant_section">Next</button></div></div></div>');
				}
            }
        }
    });
}

window.getTenantListForLease = function(lease_id) {
	var property_id = $('#selected_property_id').val();
	$.ajax({
       	method: 'POST',
	    url: api_url+"tenants/get-tenants",
	    data:'property_id='+property_id+'&lease_id='+lease_id,
	    beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	display_error_message(response);
            if(response.response_data.success == true) {
            	var html = '';
            	html += '<input type="hidden" name="selected_lease_id" id="selected_lease_id" value="'+lease_id+'">';
            	html += '<select name="tenant_property_dd" class="form-control tenant_property_dd mt-3" data-toggle="select2" multiple>';
	            	//html += "<option value=''>Select Tenant</option>";
	            	for (var key in response.tenantData) {
		                if (response.tenantData.hasOwnProperty(key)) {
	                		var tenant_name = response.tenantData[key].name+' ('+response.tenantData[key].email+')';
	                		html += '<option data-user-id="'+response.tenantData[key].user_id+'" value="'+response.tenantData[key].tenant_id+'">'+tenant_name+'</option>';
						}
					}
				html += '</select>';
				html += '<div id="first_month_rent_collection_container" class="mt-3"></div>';
				$('#tenant_dropdown_container').html(html);

				$(".tenant_property_dd").each(function() {
				    $(this)
				        .wrap("<div class=\"position-relative\"></div>")
				        .select2({
				            placeholder: "Select Tenant",
				            dropdownParent: $(this).parent()
				        });
				})
            }
        }
    });
}

window.getFirstMonthPaymentDetail = function(property_id,lease_id) {
	$.ajax({
        method: 'POST',
        url: api_url+"leases/get-first-month-rent-details",
        data: 'property_id='+property_id+'&lease_id='+lease_id,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
            display_error_message(response);
            if(response.response_data.success == true) {

            	$('.save_first_month_rent_collection_data').attr('disabled', false);

            	var html = '';
            	html += '<form id="first-month-rent-collection-wizard" class="wizard-primary create-account-form" action="javascript:void(0)">';
				    html += '<div id="payNowCollapse">';

				    	for (var key in response.leaseData) {
			            	if (response.leaseData.hasOwnProperty(key)) {

			            		var line = '(One time payment)';
			            		if(key == 0) {
			            			line = ''; //'for the month of '+response.leaseData[key].payment_due_date;
			            		}

			            		var amount_field = '';
			            		var date_field = '';
								if(response.leaseData[key].type == 'rent') {
									amount_field = 'rent_amount';
									date_field = 'rent';
								} else if(response.leaseData[key].type == 'sd') {
									amount_field = 'sd_amount';
									date_field = 'sd';
								} else if(response.leaseData[key].type == 'pd') {
									amount_field = 'pd_amount';
									date_field = 'pd';
								}

								if(response.leaseData[key].main_amount > 0) {
									html += '<div class="rent-detail-card p-3 mb-1 bg-white">';
							            html += '<div class="border-bottom">';
							                html += '<h5 class="card-title"><span class="fs-22">'+response.leaseData[key].payment_type_lable+'</span> '+line+'</h5>';
							            html += '</div>';
							            html += '<div class="rent_collection_data mt-2">';
							                html += '<div class="row">';
							                    html += '<div class="col-12 col-md-6 col-xl-6">';
							                        html += '<div class="mt-2">';
							                            html += '<div class="black mb-2"><strong>Due Date</strong></div>';
							                            html += '<input data-type="'+response.leaseData[key].type+'" type="hidden" name="original_'+date_field+'_due_date" class="form-control readonly_white" autocomplete="off" readonly="" value="'+response.leaseData[key].payment_due_date+'" />';
							                            html += '<div class="input-group date mb-2 first_month_due_date" id="due_date_'+key+'" data-target-input="nearest">';
							                            	html += '<input data-type="'+response.leaseData[key].type+'" type="text" name="'+date_field+'_due_date" class="form-control fm_due_date_'+key+' readonly_white" autocomplete="off" readonly="" value="'+response.leaseData[key].payment_due_date+'" />';

					                                        html += '<div class="input-group-append">';
					                                            html += '<div class="input-group-text"><i class="fa fa-calendar"></i></div>';
					                                        html += '</div>';
					                                    html += '</div>';

					                                    //html += '<small class="fs-12 grey">'+response.leaseData[key].payment_due_date+'</small>';

							                        html += '</div>';
							                    html += '</div>';
							                    html += '<div class="col-12 col-md-6 col-xl-6 text-right">';
							                        html += '<div class="mt-2">';
							                            html += '<div class="black mb-2"><strong>Amount</strong></div>';
							                            //html += '<div class="black fs-18"><strong>$ '+response.leaseData[key].amount+'</strong></div>';
							                            html += '<div class="black fs-18">';
							                            		html += '<div class="input-group mb-2">';
																	html += '<div class="input-group-prepend">';
																		html += '<span class="input-group-text">$</span>';
																	html += '</div>';
																	html += '<input data-type="'+response.leaseData[key].type+'" id="main_amount_'+key+'" type="hidden" class="fm_main_amount form-control text-right" value="'+response.leaseData[key].main_amount+'">';
																	html += '<input data-type="'+response.leaseData[key].type+'" data-key="'+key+'" type="text" name='+amount_field+' class="form-control text-right request_to_pay_amount" value="'+response.leaseData[key].amount+'">';
																html += '</div>';
														html += '</div>';
														//html += '<small class="fs-12 grey">$'+response.leaseData[key].amount+'</small>';
							                        html += '</div>';
							                    html += '</div>';
							                html += '</div>';
							            html += '</div>';
							        html += '</div>';
						    	}
						    }
						}

				        html += '<div class="rent-detail-card pl-3 pr-3 pt-3 bg-white">';
				            html += '<div class="rent_collection_data">';
				                html += '<div class="row">';
				                    html += '<div class="col-6 col-md-6 col-xl-9">';
				                        html += '<h5 class="card-title"><span class="fs-22">Total</h5>';
				                    html += '</div>';
				                    html += '<div class="col-6 col-md-6 col-xl-3 text-right">';
				                    	html += '<input type="hidden" class="form-control text-right request_to_pay_total" value="'+response.totalSum+'">';
				                        html += '<strong><div id="request_to_pay_total" class="black fs-18">$ '+response.totalSum+'</div></strong>';
				                    html += '</div>';
				                html += '</div>';
				            html += '</div>';
				        html += '</div>';
				        /*html += '<div class="col-auto d-sm-block mb-2 mt-4">';
				            html += '<div class="pay_now_checkbox">';
				                html += '<label class="custom-control custom-checkbox m-0">';
				                html += '<input name="pay_now_checkbox" id="payment_authorize_checkbox" type="checkbox" class="custom-control-input">';
				                html += '<span class="custom-control-label">I agree and authorize future payments to Rent Synergy will be processed by the Dwolla payment system from the selected account above. In order to cancel this authorization, I will change the payment settings within my account.</span>';
				                html += '</label>';
				            html += '</div>';
				        html += '</div>';*/
				    html += '</div>';
				html += '</form>';

				$('#first_month_rent_collection_container').html(html);

				datepicker("first_month_due_date");

				//addRentCollectionValidation();

				// Below code will check that from where function is called based on that it will load data
	        	var rs_invite_tenant = $('#rs_invite_tenant').val();
				if(rs_invite_tenant == 1) {
					$('#save_btn').remove();
					$('#invite_tenant_data_container').append('<div id="save_btn" class="row border-top mt-3"><div class="col-auto ml-auto text-right mt-3 mb-2"><button type="button" class="btn btn-orange save_first_month_rent_collection_data">Request Payment Authorization</button></div></div>');
				}
            }
        }
    });
}

window.saveFirstMonthPaymentDetail = function(property_id, lease_id, tenant_id,user_id) {
	var logged_in_user = user.user_details.user_id;
	var total = $('.request_to_pay_total').val();
	$.ajax({
        method: 'POST',
        url: api_url+"first-month-payments/add-payment-request",
        data: $('#first-month-rent-collection-wizard').serialize()+'&total='+total+'&property_id='+property_id+'&lease_id='+lease_id+'&tenant_id='+tenant_id+'&user_id='+user_id+'&logged_in_user='+logged_in_user,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
            display_error_message(response);
            if(response.response_data.success == true) {
            	// Below code will check that from where function is called based on that it will load data
	        	var rs_invite_tenant = $('#rs_invite_tenant').val();
				if(rs_invite_tenant == 1) {
					getSignupNextStep(3,4);
					openLoadingDialog($('#invite_tenant_data_container'));
					$('#invite_tenant_data_container').html('<div id="brd-top" class="mt-5 pt-5"><div class="row justify-content-center"><div class="col-auto mt-2 mb-2 mr-2"><button type="button" class="btn btn-orange rs_admin_invite_tenant">Invite Tenant</button></div></div></div>');
				} else {
					$('#requestToPayModal').modal('hide');
	            	var layout = $('#selected_layout').val();
	            	if(layout == 'card_layout') {
						$('#main_container').load('components/properties.html');
					} else {
						$('#main_container').load('components/property/property_table.html');
					}
				}
            } else {
            	$('.save_first_month_rent_collection_data').attr('disabled', false);
            }
        }
    });
}

window.getDateType = function(key) {
	var type = '';
	var date_key = $("input[class*='fm_due_date_"+key+"']").attr('data-type');
	if(date_key == 'rent') {
		type = 'Rent';
	} else if(date_key == 'sd') {
		type = 'Security deposit';
	} else if(date_key == 'pd') {
		type = 'Pet deposit';
	}
	return type;
}

window.getPaymentType = function(key) {
	var type = '';
	var main_amount_key = $('#main_amount_'+key).attr('data-type');
	if(main_amount_key == 'rent') {
		type = 'Rent';
	} else if(main_amount_key == 'sd') {
		type = 'Security deposit';
	} else if(main_amount_key == 'pd') {
		type = 'Pet deposit';
	}
	return type;
}

window.changeLayout = function(layout,module_name) {
	if(layout == 'table_prop') {
        $('#'+layout).html('<i class="align-middle fas fa-fw fa-grip-vertical"></i>');
        $('#'+layout).attr('id','ver_prop');
        if(module_name == 'property') {
        	$('#selected_layout').val('table_layout');
        	$('#main_container').load('components/property/property_table.html');
        } else if (module_name == 'ticket') {
        	$('#open_from').val('table');
        	$('#selected_layout').val('table_layout');
        	$('.ticket_data_container').load('components/ticket/ticket_table.html');
        } else if(module_name == 'document') {
        	$('#document_tab_layout').val('table_layout');
        	$('#document_data_container').load('components/property/document_table.html');
        }  else if(module_name == 'user') {
        	$('#user_list_layout').val('table_layout');
        	$('#user_detail_info_container').load('components/user/user_table.html');
        } 
    } else if(layout == 'ver_prop') {
        $('#'+layout).html('<i class="align-middle fas fa-fw fa-table"></i>');
        $('#'+layout).attr('id','table_prop');
        if(module_name == 'property') {
        	$('#selected_layout').val('card_layout');
        	$('#main_container').load('components/properties.html');
        } else if (module_name == 'ticket') {
        	$('#open_from').val('vertical');
        	$('#selected_layout').val('card_layout');
        	loadTicketList();
        } else if (module_name == 'document') {
        	$('#document_tab_layout').val('card_layout');
        	getPropertyDocument();
        } else if (module_name == 'user') {
        	$('#user_list_layout').val('card_layout');
        	getPendingUserList();
        }
    }
}

window.getPropertyAddressDetail = function(property_id, type) {
	$.ajax({
        method: 'POST',
        //url: api_url+"properties/"+property_id,
        url: api_url+"properties/view",
        data: 'property_id='+property_id+'&user_service_name='+user_service_name,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
    }).then(function(response) {
        if(response.response_data.success == true) {
            $('#selected_property_id').val(property_id);
            
            if(response.Property.address != null) {
	            $('#edit_property_id').val(property_id);
	            $('#property_payment_status').val(response.Property.payment_status);
	            $('#edit_property_address_line1').val(response.Property.address.address_line1);
	            $('#edit_property_address_line2').val(response.Property.address.address_line2);
	            $('#edit_property_city').val(response.Property.address.city);
	            $('#edit_property_state').val(response.Property.address.state);
	            $('#edit_property_zip_code').val(response.Property.address.zip_code);
	            $('#edit_property_country').val(response.Property.address.country);
	        }

	        if(response.subscriptionDetail != null) {
		        $("#subscription_plan_id").val(response.subscriptionDetail.id);
		        $("#property_coupon_id").val(response.subscriptionDetail.coupon_id);
				$("#property_plan_id").val(response.subscriptionDetail.plan_id);

				$('#coupon_code').attr('readonly',true);
				$('#coupon_code').val(response.subscriptionDetail.coupon.code);
				$("#applied_coupon").html('<strong>Coupon Applied '+response.subscriptionDetail.coupon.code);
				$('#apply_coupon_code').text('Clear');
				$('#apply_coupon_code').removeClass('apply_coupon_code');
				$('#apply_coupon_code').addClass('remove_coupon');
			}
        } else {
            display_error_message(response);
        }
    }, function() {
        // hang on this step if the error occur
    });
}

window.getPropertyDetailEditForm = function() {
	setTimeout(function(){
    	var property_id = $('#selected_property_id').val();
    	var user_id = user.user_details.user_id;
		$.ajax({
	        method: 'POST',
	        url: api_url+"properties/view",
	        data: 'property_id='+property_id+'&user_service_name='+user_service_name+'&user_id='+user_id,
	        beforeSend: function(xhr) {
		        xhr.setRequestHeader('Authorization', accessToken);
		    },
		    success: function(response) {
		    	if(response.response_data.success == true) {
		            $('#selected_property_user_id').val(response.Property.user_id);

		            $('#update_property_data_btn').html('<button type="button" data-id="'+property_id+'" data-module="Property" class="btn btn-orange float-right update_property_data">Update</button>');

		            if(response.Property.property_type != null) {
		            	$('#property_types').val(response.Property.property_type.id);
		            } else {
		            	$('#property_types').val('');
		            }

		            $('#property_no_of_units').val(response.Property.no_of_units);
		            $('#property_year_built').val(response.Property.built_year);
		            $('#property_square_feet').val(response.Property.square_feet);
		            $('#property_bedrooms').val(response.Property.no_of_bed);
		            $('#property_bathrooms').val(response.Property.no_of_bath);
		            $('#property_parking_spaces').val(response.Property.no_of_parking);
		            $('#property_swimming_pool').val(response.Property.swimming_pool);
		            $('#property_type_of_colling').val(response.Property.type_of_cooling);
		            $('#property_type_of_heating').val(response.Property.type_of_heating);
		            $('#property_type_of_water_heater').val(response.Property.type_of_water_heater);
		            $('#property_ac_filter_size').val(response.Property.ac_filter_size);
		            $('#property_ac_make_and_model').val(response.Property.ac_model);
		            $('#property_water_heater_make_and_model').val(response.Property.water_heater_model);
		            $('#property_additional_information').val(response.Property.additional_information);

		            $('#hoa').val(response.Property.hoa);
				    $('#flooring').val(response.Property.flooring);
				    $('#fireplace').val(response.Property.fireplace);
				    $('#water_heater').val(response.Property.water_heater);
				    $('#fans').val(response.Property.fans);
				    $('#pergola').val(response.Property.pergola);
				    $('#exterior').val(response.Property.exterior);
				    $('#foundation').val(response.Property.foundation);
				    $('#roof').val(response.Property.roof);
				    $('#fence').val(response.Property.fence);
				    $('#parcel_number').val(response.Property.parcel_number);
				    $('#lot_size').val(response.Property.lot_size);	    
		        } else {
		            display_error_message(response);
		        }
		    }
		});
	}, 500);
}

window.getMonthlyRentWidgetInfo = function() {
	openLoadingDialog($('#monthly_rent_container'));
    setTimeout(function(){
    	var property_id = $('#selected_property_id').val();
    	var user_id = user.user_details.user_id;
		$.ajax({
	        method: 'POST',
	        url: api_url+"properties/get-monthly-rent",
	        data: 'property_id='+property_id+'&user_service_name='+user_service_name+'&user_id='+user_id,
	        beforeSend: function(xhr) {
		        xhr.setRequestHeader('Authorization', accessToken);
		    },
		    success: function(response) {
		    	if(response.response_data.success == true) {
		    		if(response.lease != null && response.lease.status == 4) {
		    			$('#monthly_rent_container').parent('div').remove();
		    		}
		    		if(response.lease == null) {
		    			var html = '';
		            	html += '<div class="card-body py-4">';
							html += '<div class="media">';
								html += '<div class="d-inline-block mt-2 mr-3"><img src="icon/tiles/rent.svg" class="filter-1"/></div>';
								html += '<div class="media-body">';
									html += '<div class="mb-1">Monthly Rent</div>';
									html += '<h3 class="mb-2">$ 0.00</h3>';
								html += '</div>';
							html += '</div>';
						html += '</div>';
		    		} else {
		    			var due_date = ordinal_suffix_of(1);
			    		var today = new Date();
			    		var month = today.getMonth() + 1;
			    		today.setMonth(month + 1)
						var nextMonth = today.getMonth();
						nextMonth = nextMonth.toLocaleString('default', { month: 'long' });
						var nextMonthName = toMonthName(nextMonth);

						var due_str = '';
						var authorize_link = '';
		    			if(Object.keys(response.lease.pending_payment).length !== 0) {
		    				due_str += '<span class="red blink">Due rent for '+response.lease.pending_payment.payment_due_date+'</span>';
		    				if(user_service_name == t_role) {
		    					authorize_link += '<div><a data-property-id='+property_id+' data-scheduled-payment-id='+response.lease.pending_payment.id+' class="mt-2 authorize_rent_btn btn btn-orange" data-toggle="modal" data-target="#payNowModal" data-backdrop="static" data-keyboard="false">Authorize Payment</a></div>';
		    				}
		    			} else {
				    		due_str = 'Due on '+due_date+' of '+nextMonthName;
						}

						var html = '';
		            	html += '<div class="card-body py-4">';
							html += '<div class="media">';
								html += '<div class="d-inline-block mt-2 mr-3"><img src="icon/tiles/rent.svg" class="filter-1"/></div>';
								html += '<div class="media-body">';
									html += '<div class="mb-1">Monthly Rent</div>';
									html += '<h3 class="mb-2">$'+response.lease.monthly_rent_amount+'</h3>';
		                            html += '<h5 class="mb-2">'+due_str+'</h5>';
		                            html += authorize_link;
								html += '</div>';
							html += '</div>';
						html += '</div>';
					}
		            $('#monthly_rent_container').html(html);
		        } else {
		            display_error_message(response);
		        }
		    }
		});
	}, 1000);
}

window.getCurrentLeaseWidgetInfo = function() {
	openLoadingDialog($('#current_lease_container'));
    setTimeout(function(){
    	var property_id = $('#selected_property_id').val();
    	var user_id = user.user_details.user_id;
		$.ajax({
	        method: 'POST',
	        url: api_url+"properties/get-current-lease",
	        data: 'property_id='+property_id+'&user_service_name='+user_service_name+'&user_id='+user_id,
	        beforeSend: function(xhr) {
		        xhr.setRequestHeader('Authorization', accessToken);
		    },
		    success: function(response) {
		    	if(response.response_data.success == true) {
		    		var pet_icon = '<img src="icon/tiles/pet-not-allowed.svg" class="filter-9"/>';
		    		var tenant_icon = '<img src="icon/tiles/tenant.svg" class="filter-9"/>';
		    		if(response.lease == null) {
		    			var html = '';
		            	html += '<div class="card-body py-4">';
							html += '<div class="media">';
								html += '<div class="d-inline-block mt-2 mr-3"><img src="icon/tiles/lease.svg" class="filter-2"/></div>';
								html += '<div class="media-body">';
									//html += '<div class="mb-1">Lease ends on</div>';
									html += '<h3 class="mb-2">Lease not available</h3>';
									html += '<div class="tile-bottom-icon">'+pet_icon+tenant_icon+'</div>';
								html += '</div>';
							html += '</div>';
						html += '</div>';
		    		} else {
			    		if(response.lease.pet_policy == 2) {
			    			pet_icon = '<img src="icon/tiles/pet-allowed.svg" class="filter-8"/>';
			    		}

			    		if(response.lease.tenant != '' && response.lease.tenant != null) {
			    			tenant_icon = '<img src="icon/tiles/tenant.svg" class="filter-8 pointer" data-container="body" data-toggle="popover" data-placement="top" title="Tenant" data-content="'+response.lease.tenant+'"/>';
			    		}

			            var html = '';
			            html += '<div class="card-body py-4">';
							html += '<div class="media">';
								html += '<div class="d-inline-block mt-2 mr-3"><img src="icon/tiles/lease.svg" class="filter-2"/></div>';
								html += '<div class="media-body">';
									if(response.lease.status == 4) {
										html += '<div class="mb-1">Lease ended on</div>';
										html += '<h3 class="mb-2 red">'+response.lease.end_date1+'</h3>';
									} else {
										html += '<div class="mb-1">Lease ends on</div>';
										html += '<h3 class="mb-2">'+response.lease.end_date1+'</h3>';
									}
									html += '<div class="tile-bottom-icon">'+pet_icon+tenant_icon+'</div>';
								html += '</div>';
							html += '</div>';
						html += '</div>';
					}

		            $('#current_lease_container').html(html);

		            $('[data-toggle="popover"]').popover({
			        	html: true,
					    animation:true,
					    sanitize: false,
					    placement: function() { return $(window).width() < 975 ? 'top' : 'bottom'; },
			        });
		        } else {
		            display_error_message(response);
		        }
		    }
		});
	}, 1000);
}

window.getTotalIncomeWidgetInfo = function() {
	openLoadingDialog($('#total_income_container'));
    setTimeout(function(){
    	var property_id = $('#selected_property_id').val();
    	var user_id = user.user_details.user_id;
		$.ajax({
	        method: 'POST',
	        url: api_url+"properties/get-total-income",
	        data: 'property_id='+property_id+'&user_service_name='+user_service_name+'&user_id='+user_id,
	        beforeSend: function(xhr) {
		        xhr.setRequestHeader('Authorization', accessToken);
		    },
		    success: function(response) {
		    	if(response.response_data.success == true) {
		            var html = '';
		            html += '<div class="card-body py-4">';
						html += '<div class="media">';
							html += '<div class="d-inline-block mt-2 mr-3"><img src="icon/tiles/income.svg" class="filter-3"/></div>';
							html += '<div class="media-body">';
								html += '<div class="mb-1">Total Income</div>';
								html += '<h3 class="mb-2">'+response.total_income+'</h3>';
							html += '</div>';
						html += '</div>';
					html += '</div>';
		            $('#total_income_container').html(html);
		        } else {
		            display_error_message(response);
		        }
		    }
		});
	}, 1000);
}

window.getTotalExpenseWidgetInfo = function() {
	openLoadingDialog($('#total_expense_container'));
    setTimeout(function(){
    	var property_id = $('#selected_property_id').val();
    	var user_id = user.user_details.user_id;
		$.ajax({
	        method: 'POST',
	        url: api_url+"properties/get-total-expense",
	        data: 'property_id='+property_id+'&user_service_name='+user_service_name+'&user_id='+user_id,
	        beforeSend: function(xhr) {
		        xhr.setRequestHeader('Authorization', accessToken);
		    },
		    success: function(response) {
		    	if(response.response_data.success == true) {
		            var html = '';
		            html += '<div class="card-body py-4">';
						html += '<div class="media">';
							html += '<div class="d-inline-block mt-2 mr-3"><img src="icon/tiles/expense.svg" class="filter-4"/></div>';
							html += '<div class="media-body">';
								html += '<div class="mb-1">Total Expense</div>';
								html += '<h3 class="mb-2">'+response.total_expense+'</h3>';
							html += '</div>';
						html += '</div>';
					html += '</div>';
		            $('#total_expense_container').html(html);
		        } else {
		            display_error_message(response);
		        }
		    }
		});
	}, 1000);
}

window.getTicketDetailWidgetInfo = function() {
	openLoadingDialog($('#support_container'));
    setTimeout(function(){
    	var property_id = $('#selected_property_id').val();
    	var user_id = user.user_details.user_id;
		$.ajax({
	        method: 'POST',
	        url: api_url+"properties/get-ticket-detail",
	        data: 'property_id='+property_id+'&user_service_name='+user_service_name+'&user_id='+user_id,
	        beforeSend: function(xhr) {
		        xhr.setRequestHeader('Authorization', accessToken);
		    },
		    success: function(response) {
		    	if(response.response_data.success == true) {
		            var html = '';
		            html += '<div class="card-body py-4">';
						html += '<div class="media">';
							html += '<div class="d-inline-block mt-2 mr-3"><img src="icon/tiles/support.svg" class="filter-5"/></div>';
							html += '<div class="media-body">';
								html += '<div class="mb-1">Support Ticket</div>';
								html += '<h4 class="mb-2 blue">Open '+response.totalReported+'</h4>';
								html += '<h4 class="mb-2 orange">Closed '+response.totalCompleted+'</h4>';
							html += '</div>';
							/*html += '<div class="media-body">';
								html += '<div class="chart chart-xs"><canvas id="chartjs-dashboard-pie"></canvas></div>';
							html += '</div>';*/
						html += '</div>';
					html += '</div>';
		            $('#support_container').html(html);

		            // Pie chart
					/*new Chart(document.getElementById("chartjs-dashboard-pie"), {
						type: "pie",
						data: {
							labels: ["Open", "Closed"],
							datasets: [{
								data: [response.totalReported, response.totalCompleted],
								backgroundColor: [
									window.theme.primary,
									window.theme.warning,
									window.theme.danger, "#E8EAED"
								],
								borderColor: "transparent"
							}]
						},
						options: {
							responsive: !window.MSInputMethodContext,
							maintainAspectRatio: false,
							legend: {
								display: false
							}
						}
					});*/
		        } else {
		            display_error_message(response);
		        }
		    }
		});
	}, 1000);
}

window.getPropertyDetailWidgetInfo = function() {
	openLoadingDialog($('#property_detail_widget'));
    setTimeout(function(){
    	var property_id = $('#selected_property_id').val();
	    $('#property_detail_widget').load('components/property/property_detail_widget.html', property_id , function(){
			var user_id = user.user_details.user_id;
			$.ajax({
		        method: 'POST',
		        url: api_url+"properties/view",
		        data: 'property_id='+property_id+'&user_service_name='+user_service_name+'&user_id='+user_id,
		        beforeSend: function(xhr) {
			        xhr.setRequestHeader('Authorization', accessToken);
			    },
			    success: function(response) {
			    	if(response.response_data.success == true) {
			            $('#selected_property_user_id').val(response.Property.user_id);

			            if(user.permissionData['PROPERTIES']["feature_items['update_proeprty_detail']"]) {
			            	$('#property_detail_edit_btn').html('<span data-id="'+property_id+'" data-module="Property" class="btn btn-orange float-right edit_property_detail">Edit</span>');
			            }

			            var property_type = '-';
			            if(response.Property.property_type != null) { property_type = response.Property.property_type.name; }

			            var no_of_units = '-';
			            if(response.Property.no_of_units != '' && response.Property.no_of_units != null) { no_of_units = response.Property.no_of_units; }

			            var built_year = '-';
			            if(response.Property.built_year != '' && response.Property.built_year != null) { built_year = response.Property.built_year; }

			            var square_feet = '-';
			            if(response.Property.square_feet != '' && response.Property.square_feet != null) { square_feet = response.Property.square_feet; }

			            var no_of_bed = '-';
			            if(response.Property.no_of_bed != '' && response.Property.no_of_bed != null) { no_of_bed = response.Property.no_of_bed; }

			            var no_of_bath = '-';
			            if(response.Property.no_of_bath != '' && response.Property.no_of_bath != null) { no_of_bath = response.Property.no_of_bath; }

			            var no_of_parking = '-';
			            if(response.Property.no_of_parking != '' && response.Property.no_of_parking != null) { no_of_parking = response.Property.no_of_parking+' Parking Spaces'; }

			            var swimming_pool = '-';
			            if(response.Property.swimming_pool != '' && response.Property.swimming_pool != null) { swimming_pool = response.Property.swimming_pool; }

			            var type_of_cooling = '-';
			            if(response.Property.type_of_cooling != '' && response.Property.type_of_cooling != null) { type_of_cooling = response.Property.type_of_cooling; }

			            var type_of_heating = '-';
			            if(response.Property.type_of_heating != '' && response.Property.type_of_heating != null) { type_of_heating = response.Property.type_of_heating; }

			            var type_of_water_heater = '-';
			            if(response.Property.type_of_water_heater != '' && response.Property.type_of_water_heater != null) { type_of_water_heater = response.Property.type_of_water_heater; }

			            var additional_information = '-';
			            if(response.Property.additional_information != '' && response.Property.additional_information != null) { additional_information = response.Property.additional_information; }

			            var hoa = '-'; if(response.Property.hoa > 0) { hoa = '$ '+response.Property.hoa+' Yearly'; }
			            var flooring = '-'; if(response.Property.flooring != '' && response.Property.flooring != null) { flooring = response.Property.flooring; }
			            var fireplace = '-'; if(response.Property.fireplace != '' && response.Property.fireplace != null) { fireplace = response.Property.fireplace; }
			            var water_heater = '-'; if(response.Property.water_heater != '' && response.Property.water_heater != null) { water_heater = response.Property.water_heater; }
			            var fans = '-'; if(response.Property.fans != '' && response.Property.fans != null) { fans = response.Property.fans; }
			            var pergola = '-'; if(response.Property.pergola != '' && response.Property.pergola != null) { pergola = response.Property.pergola; }
			            var exterior = '-'; if(response.Property.exterior != '' && response.Property.exterior != null) { exterior = response.Property.exterior; }
			            var foundation = '-'; if(response.Property.foundation != '' && response.Property.foundation != null) { foundation = response.Property.foundation; }
			            var roof = '-'; if(response.Property.roof != '' && response.Property.roof != null) { roof = response.Property.roof; }
			            var fence = '-'; if(response.Property.fence != '' && response.Property.fence != null) { fence = response.Property.fence; }
			            var parcel_number = '-'; if(response.Property.parcel_number != '' && response.Property.parcel_number != null) { parcel_number = response.Property.parcel_number; }
			            var lot_size = '-'; if(response.Property.lot_size != '' && response.Property.lot_size != null) { lot_size = response.Property.lot_size; }

			            var html = '';
			            html += '<div class="col-md-12">';
					        html += '<div class="form-row">';
					            html += '<div class="form-group col-md-6 col-xl-3">';
					            	html += '<div class="media">';
										html += '<div class="d-inline-block mt-1 mr-2"><img src="icon/detail/type.svg" class="filter-8"/></div>';
										html += '<div class="media-body">';
											html += '<h5 class="mb-0">Type</h5>';
											html += '<div class="mb-0">'+property_type+'</div>';
										html += '</div>';
									html += '</div>';
					            html += '</div>';
					            html += '<div class="form-group col-md-6 col-xl-3">';
					            	html += '<div class="media">';
										html += '<div class="d-inline-block mt-1 mr-2"><img src="icon/detail/unit.svg" class="filter-8"/></div>';
										html += '<div class="media-body">';
											html += '<h5 class="mb-0">No. of Units</h5>';
											html += '<div class="mb-0">'+no_of_units+'</div>';
										html += '</div>';
									html += '</div>';
					            html += '</div>';
					            html += '<div class="form-group col-md-6 col-xl-3">';
					            	html += '<div class="media">';
										html += '<div class="d-inline-block mt-1 mr-2"><img src="icon/detail/year.svg" class="filter-8"/></div>';
										html += '<div class="media-body">';
											html += '<h5 class="mb-0">Year Built</h5>';
											html += '<div class="mb-0">'+built_year+'</div>';
										html += '</div>';
									html += '</div>';
					            html += '</div>';
					            html += '<div class="form-group col-md-6 col-xl-3">';
					            	html += '<div class="media">';
										html += '<div class="d-inline-block mt-1 mr-2"><img src="icon/detail/area.svg" class="filter-8"/></div>';
										html += '<div class="media-body">';
											html += '<h5 class="mb-0">Interior Livable area</h5>';
											html += '<div class="mb-0">'+square_feet+'</div>';
										html += '</div>';
									html += '</div>';
					            html += '</div>';
					        html += '</div>';
					        html += '<div class="form-row mt-4">';
				        		html += '<div class="form-group col-md-6 col-xl-3">';
					            	html += '<div class="media">';
										html += '<div class="d-inline-block mt-1 mr-2"><img src="icon/detail/heating.svg" class="filter-8"/></div>';
										html += '<div class="media-body">';
											html += '<h5 class="mb-0">Heating</h5>';
											html += '<div class="mb-0">'+type_of_heating+'</div>';
										html += '</div>';
									html += '</div>';
					            html += '</div>';
					            html += '<div class="form-group col-md-6 col-xl-3">';
					            	html += '<div class="media">';
										html += '<div class="d-inline-block mt-1 mr-2"><img src="icon/detail/cooling.svg" class="filter-8"/></div>';
										html += '<div class="media-body">';
											html += '<h5 class="mb-0">Cooling</h5>';
											html += '<div class="mb-0">'+type_of_cooling+'</div>';
										html += '</div>';
									html += '</div>';
					            html += '</div>';
					            html += '<div class="form-group col-md-6 col-xl-3">';
					            	html += '<div class="media">';
										html += '<div class="d-inline-block mt-1 mr-2"><img src="icon/detail/parking.svg" class="filter-8"/></div>';
										html += '<div class="media-body">';
											html += '<h5 class="mb-0">Parking</h5>';
											html += '<div class="mb-0">'+no_of_parking+'</div>';
										html += '</div>';
									html += '</div>';
					            html += '</div>';
					            html += '<div class="form-group col-md-6 col-xl-3">';
					            	html += '<div class="media">';
										html += '<div class="d-inline-block mt-1 mr-2"><img src="icon/detail/hoa.svg" class="filter-8"/></div>';
										html += '<div class="media-body">';
											html += '<h5 class="mb-0">HOA</h5>';
											html += '<div class="mb-0">'+hoa+'</div>';
										html += '</div>';
									html += '</div>';
					            html += '</div>';
					        html += '</div>';
					    html += '</div>';
					    
					    html += '<div class="col-12 col-lg-12">';
                            html += '<div class="accordion mt-4" id="accordionExample">';
                                html += '<div id="interior_detail_accordion" class="card mb-1">';
                                    html += '<div class="pl-2 pt-2" id="headingOne" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">';
                                        html += '<span class="mr-2"><img src="icon/detail/interior.svg" class="filter-8"/></span><h5 class="card-title my-2"><a href="javascript:void(0)">Interior Details</a></h5><span class="accordion_arrow"><i class="align-middle mr-2 fas fa-fw fa-chevron-circle-down"></i></span>';
                                    html += '</div>';
                                    html += '<div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">';
                                        html += '<div class="card-body">';
                                        	html += '<div class="form-row">';
									            html += '<div class="form-group col-md-6 col-xl-3">';
									            	html += '<div class="media">';
														html += '<div class="media-body">';
															html += '<h5 class="mb-0">Bedrooms</h5>';
															html += '<div class="mb-0">'+no_of_bed+'</div>';
														html += '</div>';
													html += '</div>';
									            html += '</div>';
									            html += '<div class="form-group col-md-6 col-xl-3">';
									            	html += '<div class="media">';
														html += '<div class="media-body">';
															html += '<h5 class="mb-0">Bathrooms</h5>';
															html += '<div class="mb-0">'+no_of_bath+'</div>';
														html += '</div>';
													html += '</div>';
									            html += '</div>';
									            html += '<div class="form-group col-md-6 col-xl-3">';
									            	html += '<div class="media">';
														html += '<div class="media-body">';
															html += '<h5 class="mb-0">Flooring</h5>';
															html += '<div class="mb-0">'+flooring+'</div>';
														html += '</div>';
													html += '</div>';
									            html += '</div>';
									            html += '<div class="form-group col-md-6 col-xl-3">';
									            	html += '<div class="media">';
														html += '<div class="media-body">';
															html += '<h5 class="mb-0">Fireplace</h5>';
															html += '<div class="mb-0">'+fireplace+'</div>';
														html += '</div>';
													html += '</div>';
									            html += '</div>';
									        html += '</div>';
									        html += '<div class="form-row">';
									            html += '<div class="form-group col-md-6 col-xl-3">';
									            	html += '<div class="media">';
														html += '<div class="media-body">';
															html += '<h5 class="mb-0">Water Heater</h5>';
															html += '<div class="mb-0">'+water_heater+'</div>';
														html += '</div>';
													html += '</div>';
									            html += '</div>';
									            html += '<div class="form-group col-md-6 col-xl-3">';
									            	html += '<div class="media">';
														html += '<div class="media-body">';
															html += '<h5 class="mb-0">Fans</h5>';
															html += '<div class="mb-0">'+fans+'</div>';
														html += '</div>';
													html += '</div>';
									            html += '</div>';
									            html += '<div class="form-group col-md-6 col-xl-3">';
									            	html += '<div class="media">';
														html += '<div class="media-body">';
															html += '<h5 class="mb-0">Pergola</h5>';
															html += '<div class="mb-0">'+pergola+'</div>';
														html += '</div>';
													html += '</div>';
									            html += '</div>';
									        html += '</div>';
                                        html += '</div>';
                                    html += '</div>';
                                html += '</div>';
                                html += '<div id="exterior_detail_accordion" class="card mb-1">';
                                    html += '<div class="pl-2 pt-2" id="headingTwo" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="true" aria-controls="collapseOne">';
                                        html += '<span class="mr-2"><img src="icon/detail/exterior.svg" class="filter-8"/></span><h5 class="card-title my-2"><a href="javascript:void(0)">Exterior Details</a></h5><span class="accordion_arrow"><i class="align-middle mr-2 fas fa-fw fa-chevron-circle-down"></i></span>';
                                    html += '</div>';
                                    html += '<div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">';
                                        html += '<div class="card-body">';
                                        	html += '<div class="form-row">';
									            html += '<div class="form-group col-md-6 col-xl-3">';
									            	html += '<div class="media">';
														html += '<div class="media-body">';
															html += '<h5 class="mb-0">Exterior</h5>';
															html += '<div class="mb-0">'+exterior+'</div>';
														html += '</div>';
													html += '</div>';
									            html += '</div>';
									            html += '<div class="form-group col-md-6 col-xl-3">';
									            	html += '<div class="media">';
														html += '<div class="media-body">';
															html += '<h5 class="mb-0">Foundation</h5>';
															html += '<div class="mb-0">'+foundation+'</div>';
														html += '</div>';
													html += '</div>';
									            html += '</div>';
									            html += '<div class="form-group col-md-6 col-xl-3">';
									            	html += '<div class="media">';
														html += '<div class="media-body">';
															html += '<h5 class="mb-0">Roof</h5>';
															html += '<div class="mb-0">'+roof+'</div>';
														html += '</div>';
													html += '</div>';
									            html += '</div>';
									            html += '<div class="form-group col-md-6 col-xl-3">';
									            	html += '<div class="media">';
														html += '<div class="media-body">';
															html += '<h5 class="mb-0">Fence</h5>';
															html += '<div class="mb-0">'+fence+'</div>';
														html += '</div>';
													html += '</div>';
									            html += '</div>';
									        html += '</div>';
									        html += '<div class="form-row">';
									            html += '<div class="form-group col-md-6 col-xl-3">';
									            	html += '<div class="media">';
														html += '<div class="media-body">';
															html += '<h5 class="mb-0">Parcel Number</h5>';
															html += '<div class="mb-0">'+parcel_number+'</div>';
														html += '</div>';
													html += '</div>';
									            html += '</div>';
									            html += '<div class="form-group col-md-6 col-xl-3">';
									            	html += '<div class="media">';
														html += '<div class="media-body">';
															html += '<h5 class="mb-0">Lot Size</h5>';
															html += '<div class="mb-0">'+lot_size+'</div>';
														html += '</div>';
													html += '</div>';
									            html += '</div>';
									        html += '</div>';
                                        html += '</div>';
                                    html += '</div>';
                                html += '</div>';
                                html += '<div id="community_accordion" class="card mb-1">';
                                    html += '<div class="pl-2 pt-2" id="headingThree" data-toggle="collapse" data-target="#collapseThree" aria-expanded="true" aria-controls="collapseOne">';
                                        html += '<span class="mr-2"><img src="icon/detail/community.svg" class="filter-8"/></span><h5 class="card-title my-2"><a href="javascript:void(0)">Country, Community & Neighborhood Details</a></h5><span class="accordion_arrow"><i class="align-middle mr-2 fas fa-fw fa-chevron-circle-down"></i></span>';
                                    html += '</div>';
                                    html += '<div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">';
                                        html += '<div class="card-body">';
                                        	html += '<div class="form-row">';
									            html += '<div class="form-group col-md-3">';
									            	html += '<div class="media">';
														html += '<div class="media-body">';
															html += '<h5 class="mb-0">Country</h5>';
															html += '<div class="mb-0">-</div>';
														html += '</div>';
													html += '</div>';
									            html += '</div>';
									            html += '<div class="form-group col-md-3">';
									            	html += '<div class="media">';
														html += '<div class="media-body">';
															html += '<h5 class="mb-0">City</h5>';
															html += '<div class="mb-0">-</div>';
														html += '</div>';
													html += '</div>';
									            html += '</div>';
									            html += '<div class="form-group col-md-3">';
									            	html += '<div class="media">';
														html += '<div class="media-body">';
															html += '<h5 class="mb-0">School Dist.</h5>';
															html += '<div class="mb-0">-</div>';
														html += '</div>';
													html += '</div>';
									            html += '</div>';
									            html += '<div class="form-group col-md-3">';
									            	html += '<div class="media">';
														html += '<div class="media-body">';
															html += '<h5 class="mb-0">Neighborhood</h5>';
															html += '<div class="mb-0">-</div>';
														html += '</div>';
													html += '</div>';
									            html += '</div>';
									        html += '</div>';
                                        html += '</div>';
                                    html += '</div>';
                                html += '</div>';
                                html += '<div id="additional_info_accordion" class="card mb-1">';
                                    html += '<div class="pl-2 pt-2" id="headingFour" data-toggle="collapse" data-target="#collapseFour" aria-expanded="true" aria-controls="collapseOne">';
                                        html += '<span class="mr-2"><img src="icon/detail/info.svg" class="filter-8"/></span><h5 class="card-title my-2"><a href="javascript:void(0)">Additional Information</a></h5><span class="accordion_arrow"><i class="align-middle mr-2 fas fa-fw fa-chevron-circle-down"></i></span>';
                                    html += '</div>';
                                    html += '<div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordionExample">';
                                        html += '<div class="card-body">'+additional_information+'</div>';
                                    html += '</div>';
                                html += '</div>';
                            html += '</div>';
                        html += '</div>';

			            $('#property_detail_container').html(html);

			            $('#interior_detail_accordion, #exterior_detail_accordion, #community_accordion, #additional_info_accordion').on('show.bs.collapse', function () {
						    $(this).find('span.accordion_arrow').html('<i class="align-middle mr-2 fas fa-fw fa-chevron-circle-up">');
						    $(this).find('div:first').addClass('border-bottom');
						});

						$('#interior_detail_accordion, #exterior_detail_accordion, #community_accordion, #additional_info_accordion').on('hide.bs.collapse', function () {
						    $(this).find('span.accordion_arrow').html('<i class="align-middle mr-2 fas fa-fw fa-chevron-circle-down">');
						    $(this).find('div:first').removeClass('border-bottom');
						});
			        } else {
			            display_error_message(response);
			        }
			    }
			});
		});
	}, 1000);
}

window.getPropertyPhotoWidgetInfo = function() {
	openLoadingDialog($('#property_photo_widget'));
    setTimeout(function(){
    	var property_id = $('#selected_property_id').val();
	    $('#property_photo_widget').load('components/property/property_photo_widget.html', property_id , function(){
			var user_id = user.user_details.user_id;
			$.ajax({
		        method: 'POST',
		        url: api_url+"properties/view",
		        data: 'property_id='+property_id+'&user_service_name='+user_service_name+'&user_id='+user_id,
		        beforeSend: function(xhr) {
			        xhr.setRequestHeader('Authorization', accessToken);
			    },
			    success: function(response) {
			    	if(response.response_data.success == true) {
			        	$('#selected_property_user_id').val(response.Property.user_id);
			            // Create property picture list container
			        	if(user.permissionData['PROPERTIES']["feature_items['update_property_pictures']"]) {
							$('#property_photo_add_btn').html('<span class="btn btn-orange float-right get_property_image_dropzone">Add</span>');
						}

						var img_list = '';
			            var prev_html = '';
			            var img_crousal_list = '';
			            var active = '';
						if(response.totalAttachment == 0) {
							prev_html += '<div class="row justify-content-center">Pictures not available</div>';
							$('#property_picture_container').html(prev_html);
						} else {
				            /*img_list += '<div class="row">';
				            for (var key in response.Property.user_attachments) {
				                if (response.Property.user_attachments.hasOwnProperty(key)) {
				                	var checked = '';
				                    if(response.Property.user_attachments[key].module_name == "Property" && response.Property.user_attachments[key].status != 2) {
				                    	if(response.Property.user_attachments[key].cover_image == 1) {
				                    		checked = "checked";
				                    	}
				                        
				                        img_list += '<div id="user_property_media_'+response.Property.user_attachments[key].id+'" data-id="'+response.Property.user_attachments[key].id+'" data-property-id="'+property_id+'" class="col-12 col-md-6 col-xl-6 property_image"> <div class="card bg-light"> <div class="card-img"><input data-id="'+response.Property.user_attachments[key].id+'" name="property-img-radio" type="radio" class="d-none property_cover_image" '+checked+' data-toggle="tooltip" data-placement="bottom" title="Cover Image" data-original-title="Cover Image"><i class="align-middle mr-2 fas fa-fw fa-trash d-none delete_property_image" data-id="'+response.Property.user_attachments[key].id+'" data-module="Property" data-toggle="tooltip" data-placement="bottom" title="Delete" data-original-title="Delete"></i><img class="card-img-top" src="'+response.Property.user_attachments[key].aws_url+'" alt="Unsplash"></div> </div> </div>';	                        
				                    }
				                }
				            }	            
				            img_list += '</div>';*/

				            // Below code is used when user click on image and display full image
            
				            prev_html += '<div class="pt-0">';
			            		prev_html += '<div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">';
					                prev_html += '<ol class="carousel-indicators">';
						                var img_cnt = 0;
						                for (var key in response.Property.user_attachments) {
							                if (response.Property.user_attachments.hasOwnProperty(key)) {
							                	if(response.Property.user_attachments[key].module_name == "Property" && response.Property.user_attachments[key].status != 2) {
							                		var act = '';
							                		if(img_cnt == 0) { act = 'active'; }
							                		prev_html += '<li data-target="#carouselExampleIndicators" data-slide-to="'+img_cnt+'" class="'+act+'"></li>';
							                		img_cnt = img_cnt + 1;
						                    	}
						                    }
						                }
					                prev_html += '</ol>';
					                var img_cnt1 = 0;
					                prev_html += '<div class="carousel-inner">';
					                	for (var key in response.Property.user_attachments) {
							                if (response.Property.user_attachments.hasOwnProperty(key)) {
							                	var checked = '';
							                	if(response.Property.user_attachments[key].module_name == "Property" && response.Property.user_attachments[key].status != 2) {
							                		if(response.Property.user_attachments[key].cover_image == 1) {
							                    		checked = "checked";
							                    	}
							                		var act1 = '';
							                		if(img_cnt1 == 0) { act1 = 'active'; }
						                			prev_html += '<div class="carousel-item property_image '+act1+'" data-interval="10000" data-id="'+response.Property.user_attachments[key].id+'" data-property-id="'+response.Property.id+'">';
						                				if(user_service_name == po_role) {
						                					prev_html += '<input data-id="'+response.Property.user_attachments[key].id+'" name="property-img-radio" type="radio" class="d-none property_cover_image" '+checked+' data-toggle="tooltip" data-placement="bottom" title="Cover Image" data-original-title="Cover Image"><i class="align-middle mr-2 fas fa-fw fa-trash d-none delete_property_image" data-id="'+response.Property.user_attachments[key].id+'" data-module="Property" data-toggle="tooltip" data-placement="bottom" title="Delete" data-original-title="Delete"></i><img class="d-block" src="'+response.Property.user_attachments[key].aws_url+'" alt="'+response.Property.user_attachments[key].display_name+'">';
						                				} else {
						                					prev_html += '<img class="d-block" src="'+response.Property.user_attachments[key].aws_url+'" alt="'+response.Property.user_attachments[key].display_name+'">';
						                				}
								                    prev_html += '</div>';
								                    img_cnt1 = img_cnt1 + 1;
							                    }
								            }
								        }
							        prev_html += '</div>';
							        prev_html += '<a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">';
					                    prev_html += '<span class="carousel-control-prev-icon" aria-hidden="true"></span>';
					                    prev_html += '<span class="sr-only">Previous</span>';
					                prev_html += '</a>';
					                prev_html += '<a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">';
					                    prev_html += '<span class="carousel-control-next-icon" aria-hidden="true"></span>';
					                    prev_html += '<span class="sr-only">Next</span>';
					                prev_html += '</a>';
					            prev_html += '</div>';
					        prev_html += '</div>';

					        $('#property_picture_container').html(prev_html);
							$('.carousel').carousel({
							  	cycle: true
							}); 
				        }
			            //$('#property_picture_container').html(img_list);
			            //Scrollbar.initAll({damping: .1,thumbMinSize: 20,renderByPixels: true,continuousScrolling: 'auto',alwaysShowTracks: false,wheelEventTarget: null,plugins: {}});
			        } else {
			            display_error_message(response);
			        }
			    }
			});
		});
	}, 1000);
}

window.getPropertyLeaseWidgetInfo = function() {
	openLoadingDialog($('#property_lease_widget'));
    setTimeout(function(){
    	var user = JSON.parse(localStorage.getItem('user'));
	    var tz = jstz.determine();
		var timezone = tz.name();
		var property_id = $('#selected_property_id').val();
		var user_id = user.user_details.user_id;

		$('#property_lease_widget').load('components/property/property_lease_widget.html', property_id , function(){
			// Set kebab menu conditions
    		var kebab = '';
	        var kebab_item = '';
    		if(user_service_name == po_role && user.permissionData['PROPERTIES']["feature_items['lease_add']"]) {
    			kebab_item += '<a href="javascript:void(0)" class="dropdown-item add_new_lease">Add Lease</a>';
    		}

	        if($.trim(kebab_item) != '') {
	        	kebab = '<div class="text-center"><div class="input-group-prepend"><i class="align-middle mr-2 fas fa-fw fa-ellipsis-v" data-toggle="dropdown" aria-expanded="false"></i><div class="dropdown-menu dropdown-menu-right" style="">'+kebab_item+'</div></div></div>';
	        }
	        $('#property_lease_add_btn').html(kebab);

			$.ajax({
		        method: 'POST',
		        url: api_url+"leases",
		        data: 'user_timezone='+timezone+'&property_id='+property_id+'&user_service_name='+user_service_name+'&user_id='+user_id,
		        beforeSend: function(xhr) {
			        xhr.setRequestHeader('Authorization', accessToken);
			    },
			    success: function(response) {
			    	if(response.response_data.success == true) {
			    		var html = '';
			        	for (var key in response.leases) {
			                if (response.leases.hasOwnProperty(key)) {

			                	var rent_due_date = ordinal_suffix_of(response.leases[key].rent_due_date);
			                	var sd_due_date = '';
			                	var pd_due_date = '&nbsp;';
			                	if(response.leases[key].sd_due_date != null) {
			                		sd_due_date = 'Due on '+response.leases[key].sd_due_date;
			                	}
			                	if(response.leases[key].pd_due_date != null) {
			                		pd_due_date = 'Due on '+response.leases[key].pd_due_date;
			                	}

			                	var add_opacity = '';
			                	var status = '';
			                    if(response.leases[key].status == 0 || response.leases[key].status == 4) {
			                    	add_opacity = 'add_opacity';
			                        status += '<span class="badge badge-danger">'+response.leases[key].status_label+'</span>';
			                    } if(response.leases[key].status == 1 || response.leases[key].status == 5) {
			                        status += '<span class="badge badge-success">'+response.leases[key].status_label+'</span>';
			                    }

			                    var kebab_item = '';

			                    if(user.permissionData['PROPERTIES']["feature_items['lease_edit']"] && response.leases[key].status != 4 && response.leases[key].transaction_created == 0) {
			                    	kebab_item += '<a class="dropdown-item edit_lease_detail" data-id="'+response.leases[key].id+'" href="javascript:void(0)" data-toggle="modal" data-target="#leaseEditModal" data-backdrop="static" data-keyboard="false">Edit</a>';
			                    }

			                    if(response.leases[key].tenant == '' && user.permissionData['PROPERTIES']["feature_items['lease_delete']"] && response.leases[key].status != 4 && response.leases[key].transaction_created == 0) {
			                    	kebab_item += '<a class="dropdown-item delete_lease_detail" data-id="'+response.leases[key].id+'" href="javascript:void(0)">Delete</a>';
			                    }

			                    if(user_service_name == sa_role && response.leases[key].status == 4 && response.leases[key].is_extended == 'No' && response.leases[key].sd_amount > 0 && response.leases[key].is_security_deposit_refunded == 0) {
			                    	kebab_item += '<a class="dropdown-item refund_security_deposit" data-id="'+response.leases[key].id+'" data-sd-amount="'+response.leases[key].security_deposit_amount+'" data-tenants-table-id="'+response.leases[key].tenant_table_ids+'" data-tenant-user-id="'+response.leases[key].tenant_ids+'" data-payment-id="'+response.leases[key].payment_details_id+'" href="javascript:void(0)">Refund Security Deposit</a>';
			                    }

			                    var kebab = '';
			                    if(kebab_item != '') {
			                    	kebab = '<div class="text-center"><div class="input-group-prepend"><i class="align-middle mr-2 fas fa-fw fa-ellipsis-v" data-toggle="dropdown" aria-expanded="false"></i><div class="dropdown-menu dropdown-menu-right" style="">'+kebab_item+'</div></div></div>';
			                    }

			                    // Card based UI
			                	html += '<tr data-id="'+response.leases[key].id+'" class="'+add_opacity+'">';
			                		html += '<td class="bold-black">'+response.leases[key].start_date+'</td>';
			                		html += '<td class="bold-black">'+response.leases[key].end_date+'</td>';
			                		html += '<td>$'+response.leases[key].monthly_rent_amount+'<br><small>Due on '+rent_due_date+' of the month</small></td>';
			                		html += '<td>$'+response.leases[key].security_deposit_amount+'<br><small>'+sd_due_date+'</small></td>';
			                		html += '<td>'+response.leases[key].pet_policy+'<br>$'+response.leases[key].pet_deposit_amount+'<br><small>'+pd_due_date+'</small></td>';
			                		html += '<td>'+response.leases[key].tenant+'</td>';
			                		html += '<td>';
										if(user.permissionData['PROPERTIES']["feature_items['lease_link_tenant']"]) {
											var add_opacity1 = '';
						                    if(response.leases[key].tenant_ids != '' && response.leases[key].tenant_ids != null) {
						                    	add_opacity1 = 'add_opacity';
						                    }
                                        	html += '<a href="#" data-tenant-id="'+response.leases[key].tenant_ids+'" data-module="lease" data-id="'+response.leases[key].id+'" class="list_existing_tenant '+add_opacity1+'" data-toggle="modal" data-target="#tenantListModal" data-backdrop="static" data-keyboard="false">Link Tenants</a><br>';
                                        }
									html += '</td>';
			                		html += '<td>'+status+'</td>';
			                		html += '<td>'+kebab+'</td>';
			                	html += '</tr>';
			                }
			            }
			            $('#property-lease-data-container').html(html);

			            var property_lease_table = $("#property-lease-datatable").DataTable({
			            	responsive: true,
			            	pageLength: 6,
							lengthChange: false,
							dom: 'frtip',
			    			autoWidth: false,
			    			searching: false,
			            	paging: false,
			            	info: false,
						    scrollY: '400px',
						    scrollX: false,
					        order: [[7,'asc']]
						});
			        } else {
			            display_error_message(response);
			        }
			    }
			});
		});
	}, 1000);
}

window.getPropertyTenantWidgetInfo = function() {
	openLoadingDialog($('#property_tenant_widget'));
    setTimeout(function(){
    	var property_id = $('#selected_property_id').val();
    	$('#property_tenant_widget').load('components/property/property_tenant_widget.html', property_id , function(){
    		var user = JSON.parse(localStorage.getItem('user'));
		    var tz = jstz.determine();
			var timezone = tz.name();
			var user_id = user.user_details.user_id;

			
			// Set kebab menu conditions
    		var kebab = '';
	        var kebab_item = '';
    		if(user_service_name == po_role && user.permissionData['PROPERTIES']["feature_items['tenant_add']"]) {
    			kebab_item += '<a href="javascript:void(0)" class="dropdown-item add_new_tenant">Add Tenant</a>';
    		}

	        if($.trim(kebab_item) != '') {
	        	kebab = '<div class="text-center"><div class="input-group-prepend"><i class="align-middle mr-2 fas fa-fw fa-ellipsis-v" data-toggle="dropdown" aria-expanded="false"></i><div class="dropdown-menu dropdown-menu-right" style="">'+kebab_item+'</div></div></div>';
	        }
	        $('#property_tenant_add_btn').html(kebab);

			$.ajax({
		        method: 'POST',
		        url: api_url+"tenants",
		        data: 'user_timezone='+timezone+'&property_id='+property_id+'&user_service_name='+user_service_name+'&user_id='+user_id,
		        beforeSend: function(xhr) {
			        xhr.setRequestHeader('Authorization', accessToken);
			    },
			    success: function(response) {
			    	if(response.response_data.success == true) {
			    		var html = '';
			        	if(response.TotalTenants == 0) {
				        	html += '<a href="javascript:void(0)" class="list-group-item list-group-item-action border-0 bg-light text-left"><div class="fs-16">'+response.response_data.message+'</div></a>';
					    } else {
					    	for (var key in response.tenants) {
				                if (response.tenants.hasOwnProperty(key)) {
				                	var monthly_rent_amount = '-';
				                	var rent_due_date = '-';
				                	var security_deposit_amount = '-';
				                	var pet_policy = '-';
				                	var pet_deposit_amount = '-';
				                	var start_date = '-';
				                	var end_date = '-';
				                	var sd_due_date = '-';
				                	var pd_due_date = '-';

				                	var kebab_item = '';
				                	if(user.permissionData['PROPERTIES']["feature_items['tenant_edit']"] && response.tenants[key].lease_status != 4) {
				                    	kebab_item += '<a class="dropdown-item edit_tenant_detail" data-id="'+response.tenants[key].id+'" href="javascript:void(0)" data-toggle="modal" data-target="#tenantEditModal" data-backdrop="static" data-keyboard="false">Edit</a>';
				                    }

				                    var status = '';
				                    if(response.tenants[key].application_constant == 'draft') {
				                        status += '<span class="badge badge-danger">'+response.tenants[key].application_status+'</span>';
				                        if(user_service_name == po_role) {
				                        	kebab_item += '<a class="dropdown-item submit_tenant_detail" data-id="'+response.tenants[key].id+'" href="javascript:void(0)">Submit</a>';
				                        }
				                    } else if(response.tenants[key].application_constant == 'submitted' || (response.tenants[key].application_constant == 'invite_sent' && response.tenants[key].user.status == 0)) {
				                        status += '<span class="badge badge-info">'+response.tenants[key].application_status+'</span>';
				                        if(user_service_name == sa_role) {
				                        	var invite_lable = '';
				                        	if(response.tenants[key].application_constant == 'invite_sent' && response.tenants[key].user.status == 0) {
				                        		invite_lable = 'Re-invite Tenant';
				                        	} else {
				                        		invite_lable = 'Invite Tenant';
				                        	}
				                        	kebab_item += '<a class="dropdown-item invite_tenant" data-id="'+response.tenants[key].id+'" href="javascript:void(0)" data-toggle="modal" data-target="#inviteTenantModal" data-backdrop="static" data-keyboard="false">'+invite_lable+'</a>';
				                        }
				                    } else if(response.tenants[key].application_constant == 'invite_sent') {
				                        status += '<span class="badge badge-primary">'+response.tenants[key].application_status+'</span>';
				                    } else if(response.tenants[key].application_constant == 'signed_up') {
				                        status += '<span class="badge badge-success">'+response.tenants[key].application_status+'</span>';
				                    } else if(response.tenants[key].application_constant == 'tenant_deleted') {
				                        status += '<span class="badge badge-danger">'+response.tenants[key].application_status+'</span>';
				                    } else if(response.tenants[key].application_constant == 'invite_pending') {
				                        status += '<span class="badge badge-info">'+response.tenants[key].application_status+'</span>';
				                    }

				                    var add_opacity = '';
				                    if(response.tenants[key].lease_status == 4) {
				                    	add_opacity = 'add_opacity';
				                    	status = '<span class="badge badge-danger">Expired</span>';
				                    }

				                    if(user.permissionData['PROPERTIES']["feature_items['tenant_delete']"]) {
				                    	if(response.tenants[key].tenant_leases.length <= 0 && response.tenants[key].application_constant != 'tenant_deleted') {
				                    		kebab_item += '<a class="dropdown-item delete_tenant_detail" data-id="'+response.tenants[key].id+'" href="javascript:void(0)">Delete</a>';
			                        	}
			                        }

			                        /*if(user_service_name == sa_role && response.tenants[key].status == 4) {
			                    		kebab_item += '<a class="dropdown-item undelete_tenant_detail" data-id="'+response.tenants[key].id+'" href="javascript:void(0)">Un-delete</a>';
			                    	}*/

			                    	var kebab = '';
			                    	if($.trim(kebab_item) != '') {
							        	kebab = '<div class="text-center"><div class="input-group-prepend"><i class="align-middle mr-2 fas fa-fw fa-ellipsis-v" data-toggle="dropdown" aria-expanded="false"></i><div class="dropdown-menu dropdown-menu-right" style="">'+kebab_item+'</div></div></div>';
							        }
				                    
				                    if(response.tenants[key].tenant_leases.length > 0) {
					                	for (var tlkey in response.tenants[key].tenant_leases) {
					                		if (response.tenants[key].tenant_leases.hasOwnProperty(tlkey)) {
					                			var lease = response.tenants[key].tenant_leases[tlkey].lease;
					                			var add_opacity = '';
					                			if(lease != null) {
					                				add_opacity = 'add_opacity';
							                		monthly_rent_amount = lease.monthly_rent_amount;
							                		rent_due_date = ordinal_suffix_of(lease.rent_due_date);
							                		security_deposit_amount = lease.security_deposit_amount;
							                		pet_policy = lease.pet_policy_term_label;
							                		pet_deposit_amount = lease.pet_deposit_amount;
							                		start_date = lease.start_date;
							                		end_date = lease.end_date;
							                		sd_due_date = 'Due on '+lease.sd_due_date;
							                		pd_due_date = 'Due on '+lease.pd_due_date;
							                	}

							                	var lease_status = '';
							                	if(response.tenants[key].tenant_leases[tlkey].status == 1) {
							                    	lease_status = '<span class="badge badge-info">Submitted</span>';
							                    }
							                    var add_opacity1 = '';
							                	if(response.tenants[key].tenant_leases[tlkey].status == 4) {
							                		add_opacity1 = 'add_opacity';
							                    	lease_status = '<span class="badge badge-danger">Expired</span>';
							                    }
							                    if(response.tenants[key].tenant_leases[tlkey].status == 5) {
							                    	lease_status = '<span class="badge badge-primary">Pending signature</span>';
							                    }

							                    html += '<tr data-id="'+response.tenants[key].id+'" class="'+add_opacity1+'">';
							                    	html += '<td class="bold-black">'+response.tenants[key].user.first_name+' '+response.tenants[key].user.last_name+'</h5><br><small>'+response.tenants[key].user.email+'</small></td>';
							                		html += '<td>'+start_date+'</td>';
							                		html += '<td>'+end_date+'</td>';
							                		//html += '<td>$'+monthly_rent_amount+'<br><small>Due on '+rent_due_date+' of the month</small></td>';
							                		//html += '<td>$'+security_deposit_amount+'<br><small>'+sd_due_date+'</small></td>';
							                		//html += '<td>'+pet_policy+'<br>$'+pet_deposit_amount+'<br><small>'+pd_due_date+'</small></td>';
							                		html += '<td>';
														if(user.permissionData['PROPERTIES']["feature_items['tenant_link_lease']"]) {
				                                        	html += '<a href="#" data-l-id="'+response.tenants[key].tenant_leases[tlkey].lease_id+'" data-lease-id="'+response.tenants[key].lease_ids+'" data-id="'+response.tenants[key].id+'" class="list_existing_lease '+add_opacity+'" data-toggle="modal" data-target="#leaseListModal" data-backdrop="static" data-keyboard="false">Link Leases</a><br>';
				                                        }
													html += '</td>';
							                		html += '<td>'+lease_status+'</td>';
							                		html += '<td>'+kebab+'</td>';
							                	html += '</tr>';
										    }
										}
									} else {
										html += '<tr data-id="'+response.tenants[key].id+'" class="'+add_opacity+'">';
					                    	html += '<td class="bold-black">'+response.tenants[key].user.first_name+' '+response.tenants[key].user.last_name+'</h5><br><small>'+response.tenants[key].user.email+'</small></td>';
					                		html += '<td>'+start_date+'</td>';
					                		html += '<td>'+end_date+'</td>';
					                		//html += '<td>$'+monthly_rent_amount+'<br><small>Due on '+rent_due_date+' of the month</small></td>';
					                		//html += '<td>$'+security_deposit_amount+'<br><small>'+sd_due_date+'</small></td>';
					                		//html += '<td>'+pet_policy+'<br>$'+pet_deposit_amount+'<br><small>'+pd_due_date+'</small></td>';
					                		html += '<td>';
												if(user.permissionData['PROPERTIES']["feature_items['tenant_link_lease']"]) {
		                                        	html += '<a href="#" data-lease-id="0" data-id="'+response.tenants[key].id+'" class="list_existing_lease" data-toggle="modal" data-target="#leaseListModal" data-backdrop="static" data-keyboard="false">Link Leases</a><br>';
		                                        }
											html += '</td>';
					                		html += '<td>'+status+'</td>';
					                		html += '<td>'+kebab+'</td>';
					                	html += '</tr>';	                	
									}
				                }
				            }
				        }

			            $('#property-tenant-data-container').html(html);

			            var property_tenant_table = $("#property-tenant-datatable").DataTable({
			            	responsive: true,
			            	pageLength: 6,
							lengthChange: false,
							dom: 'frtip',
			    			autoWidth: false,
			    			searching: false,
			            	paging: false,
			            	info: false,
						    scrollY: '400px',
						    scrollX: false,
					        order: [[4,'desc']]
						});
			        } else {
			            display_error_message(response);
			        }
			    }
			});
		});
	}, 1000);
}

window.getPropertyDocumentWidgetInfo = function() {
	openLoadingDialog($('#property_document_widget'));
    setTimeout(function(){
    	var user_id = user.user_details.user_id;
		var property_id = $('#selected_property_id').val();

		$('#property_document_widget').load('components/property/property_document_widget.html', property_id , function(){
			$.ajax({
		        method: 'POST',
		        url: api_url+"properties/view",
        		data: 'property_id='+property_id+'&user_service_name='+user_service_name+'&user_id='+user_id,
		        beforeSend: function(xhr) {
			        xhr.setRequestHeader('Authorization', accessToken);
			    },
			    success: function(response) {
			    	if(response.response_data.success == true) {
			    		$('#selected_property_user_id').val(response.Property.user_id);

			    		// Set kebab menu conditions
			    		var kebab = '';
				        var kebab_item = '';
			    		if(user.permissionData['PROPERTIES']["feature_items['upload_document']"]) {
			    			kebab_item += '<a href="javascript:void(0)" class="dropdown-item get_property_document_dropzone">Upload Document</a>';
			    		}
			    		if(user.permissionData['PROPERTIES']["feature_items['add_signature_document']"]) {
			    			kebab_item += '<a href="javascript:void(0)" class="dropdown-item add_lease_aggrement_signature_document">Add Signature Document</a>';
			    		}

				        if($.trim(kebab_item) != '') {
				        	kebab = '<div class="text-center"><div class="input-group-prepend"><i class="align-middle mr-2 fas fa-fw fa-ellipsis-v" data-toggle="dropdown" aria-expanded="false"></i><div class="dropdown-menu dropdown-menu-right" style="">'+kebab_item+'</div></div></div>';
				        }
				        $('#property_document_btn').html(kebab);

			            // Set Property zoho template and normal pdf file data
			            
			            var checklist_list = '';

			            // Start : Document section
			            var file_cnt = 0;
			            if(zoho_signature_enabled == "true") {
			                if(response.templateData != null) {
				            	for (var key in response.templateData) {
					                if (response.templateData.hasOwnProperty(key)) {
					                	file_cnt++

					                	// Create data for receipents
					                	var user_popup_html = '';
					                	var user_html = '';
					                	for (var akey in response.templateData[key].recipients) {
					                		if (response.templateData[key].recipients.hasOwnProperty(akey)) {
					                			user_popup_html += '<div>'+response.templateData[key].recipients[akey].recipient+' <br> <strong>'+response.templateData[key].recipients[akey].status+'</strong> <br>on '+response.templateData[key].recipients[akey].date+'</div>';

					                			var status_color = '';
				                				if(response.templateData[key].recipients[akey].status == 'Completed') {
				                					status_color = 'green';
				                				}
				                				user_html += '<div class="fs-14 mb-2">'+response.templateData[key].recipients[akey].recipient+' <br> <strong class="fs-16 '+status_color+'">'+response.templateData[key].recipients[akey].status+'</strong> <br>on '+response.templateData[key].recipients[akey].date+'</div>';
					                		}
					                	}

				                        // Set kebab menu conditions
								        var document_status = '';
								        var kebab = '';
								        var kebab_item = '';
						                var send_for_signature = '';
										var btn_class = '';

										if(user_service_name == sa_role && response.templateData[key].status == 0) {
											kebab_item += '<a data-user-id='+response.templateData[key].user_id+' data-zoho-template-id='+response.templateData[key].zoho_template_id+' data-zohoTemplateID="'+response.templateData[key].zoho_template.zoho_templateID+'" data-zohoActionID="'+response.templateData[key].zoho_template.zoho_actionID+'" class="dropdown-item send_for_signatures" href="javascript:void(0)">Send for Signatures</a>';

											document_status += '<div class="badge badge-danger" title="'+response.templateData[key].zoho_template.zoho_templateName+'">'+response.templateData[key].status_label+'</div>';
										} else {
											if(response.templateData[key].status == 1) {
												btn_class = 'badge-primary';
											} else if(response.templateData[key].status == 2) {
												btn_class = 'badge-primary';
											} else if(response.templateData[key].status == 3) {
												btn_class = 'badge-warning';
											} else if(response.templateData[key].status == 4) {
												btn_class = 'badge-success';
											} else if(response.templateData[key].status == 5) {
												btn_class = 'badge-warning';
											} else if(response.templateData[key].status == 6) {
												btn_class = 'badge-success';
											} else if(response.templateData[key].status == 7) {
												btn_class = 'badge-danger';
											} 

											document_status += '<div class="badge '+btn_class+'" title="'+response.templateData[key].zoho_template.zoho_templateName+'" data-container="body" data-toggle="popover" data-placement="top" data-content="'+user_popup_html+'">'+response.templateData[key].status_label+'</div>';
										}

										if(response.templateData[key].status > 0) {
											kebab_item += '<a data-type="zoho" data-request-id="'+response.templateData[key].sign_requestID+'" class="dropdown-item view_document_preview" href="javascript:void(0)">View</a>';

											kebab_item += '<a data-type="download" data-filename="'+stripHtml(response.templateData[key].zoho_template.zoho_templateName)+'" data-request-id="'+response.templateData[key].sign_requestID+'" class="dropdown-item download_zoho_document" href="javascript:void(0)">Download</a>';
						                }

						                if(response.templateData[key].status == 0) {
						                	kebab_item += '<a data-id="'+response.templateData[key].id+'" class="dropdown-item delete_property_template" href="javascript:void(0)">Delete</a>';
						                }

								        if($.trim(kebab_item) != '') {
								        	kebab = '<div class="text-center"><div class="input-group-prepend"><i class="align-middle mr-2 fas fa-fw fa-ellipsis-v" data-toggle="dropdown" aria-expanded="false"></i><div class="dropdown-menu dropdown-menu-right" style="">'+kebab_item+'</div></div></div>';
								        }

								        var tr_bg = 'odd';
					                	if(key % 2 == 1) {
					                		tr_bg = 'even';
					                	}

					                	var name = stripHtml(response.templateData[key].zoho_template.zoho_templateName);
										if (name.length > 35) {
										    var shortname = name.substring(0, 35) + " ...";
										    name = shortname;
										}
								        
								        checklist_list += '<tr id="user_template_'+response.templateData[key].id+'" class="'+tr_bg+'">';
					                		checklist_list += '<td>'+response.templateData[key].id+'</td>';
					                		checklist_list += '<td>'+name+'</td>';
				                            checklist_list += '<td>'+response.templateData[key].zoho_template.document_for+'</td>';
				                            checklist_list += '<td>'+user_html+'</td>';
				                            checklist_list += '<td>'+document_status+'</td>';
				                            //checklist_list += '<td>&nbsp</td>';
				                            checklist_list += '<td>&nbsp</td>';
				                            checklist_list += '<td>&nbsp;</td>';
				                            checklist_list += '<td>'+kebab+'</td>';
				                        checklist_list += '</tr>';
					                }
					            }
				            }
				        } else {
				        	if(response.userContractData != null) {
				            	for (var key in response.userContractData) {
					                if (response.userContractData.hasOwnProperty(key)) {
					                	file_cnt++

					                	// Create data for receipents
					                	var user_html = '';
					                	for (var akey in response.userContractData[key].recipients) {
					                		if (response.userContractData[key].recipients.hasOwnProperty(akey)) {
					                			if(response.userContractData[key].recipients[akey].date == '') {
					                				user_html += '<div>'+response.userContractData[key].recipients[akey].recipient+'</div>';
					                			} else {
					                				user_html += '<div>'+response.userContractData[key].recipients[akey].recipient+' : <strong>'+response.userContractData[key].recipients[akey].status+'</strong> on '+response.userContractData[key].recipients[akey].date+'</div>';
					                			}
					                		}
					                	}

					                	// Set kebab menu conditions
								        var document_status = '';
								        var kebab = '';
								        var kebab_item = '';
						                var send_for_signature = '';
										var btn_class = '';

										var application_status = '';
										if(user_service_name == sa_role && (response.userContractData[key].application_constant == 'document_draft' || response.userContractData[key].application_constant == 'document_ready_for_review' || response.userContractData[key].application_constant == 'document_pending_review')) {
											kebab_item += '<a data-contract-id="'+response.userContractData[key].contract.id+'" data-user-contract-id="'+response.userContractData[key].id+'" data-form="'+response.userContractData[key].contract.form+'" data-table="'+response.userContractData[key].contract.table_name+'" class="dropdown-item edit_property_contract" href="javascript:void(0)">Edit</a>';

											if(response.userContractData[key].application_constant == 'document_ready_for_review') {
												kebab_item += '<a data-contract-id="'+response.userContractData[key].contract.id+'" data-user-contract-id="'+response.userContractData[key].id+'" data-form="'+response.userContractData[key].contract.form+'" data-table="'+response.userContractData[key].contract.table_name+'" class="dropdown-item contract_send_for_review" href="javascript:void(0)">Send for review</a>';
											}

					                    	kebab_item += '<a data-id="'+response.userContractData[key].id+'" class="dropdown-item delete_property_contract" href="javascript:void(0)">Delete</a>';
										}

					                    if(response.userContractData[key].application_constant == 'document_draft') {
					                    	application_status += '<span class="badge badge-danger">'+response.userContractData[key].application_status+'</span>';
					                    } else if(response.userContractData[key].application_constant == 'document_ready_for_review' || response.userContractData[key].application_constant == 'document_pending_review') {
					                        application_status += '<span class="badge badge-warning">'+response.userContractData[key].application_status+'</span>';
					                    } else if(response.userContractData[key].application_constant == 'document_ready_for_signature') {
					                        application_status += '<span class="badge badge-warning">'+response.userContractData[key].application_status+'</span>';
					                        if(user_service_name == sa_role) {
					                        	kebab_item += '<a data-contract-id="'+response.userContractData[key].contract.id+'" data-user-contract-id="'+response.userContractData[key].id+'" data-form="'+response.userContractData[key].contract.form+'" data-table="'+response.userContractData[key].contract.table_name+'" class="dropdown-item contract_send_for_signature" href="javascript:void(0)">Send for signature</a>';
					                        }
					                    } else if(response.userContractData[key].application_constant == 'document_pending_signature') {
					                        application_status += '<span class="badge badge-success">'+response.userContractData[key].application_status+'</span>';
					                    } else if(response.userContractData[key].application_constant == 'document_completed') {
					                        application_status += '<span class="badge badge-primary">'+response.userContractData[key].application_status+'</span>';
					                    } else if(response.userContractData[key].application_constant == 'document_deleted') {
					                        application_status += '<span class="badge badge-danger">'+response.userContractData[key].application_status+'</span>';
					                    }
										
										if($.trim(kebab_item) != '') {
								        	kebab = '<div class="text-center"><div class="input-group-prepend"><i class="align-middle mr-2 fas fa-fw fa-ellipsis-v" data-toggle="dropdown" aria-expanded="false"></i><div class="dropdown-menu dropdown-menu-right" style="">'+kebab_item+'</div></div></div>';
								        }

								        var tr_bg = 'odd';
					                	if(key % 2 == 1) {
					                		tr_bg = 'even';
					                	}

					                	var name = stripHtml(response.userContractData[key].contract.name);
										if (name.length > 35) {
										    var shortname = name.substring(0, 35) + " ...";
										    name = shortname;
										}

							            checklist_list += '<tr id="user_contract_'+response.userContractData[key].id+'" class="'+tr_bg+'">';
					                		checklist_list += '<td>'+response.userContractData[key].id+'</td>';
					                		checklist_list += '<td>'+name+'</td>';
				                            checklist_list += '<td>'+response.userContractData[key].contract.service.service_name+'</td>';
				                            checklist_list += '<td>'+user_html+'</td>';
				                            checklist_list += '<td>'+application_status+'</td>';
				                            //checklist_list += '<td>&nbsp</td>';
				                            checklist_list += '<td>&nbsp</td>';
				                            checklist_list += '<td>&nbsp;</td>';
				                            checklist_list += '<td>'+kebab+'</td>';
				                        checklist_list += '</tr>';
					                }
					            }
				            }
				        }
			            
			            // Display normal document
			            for (var key in response.Property.user_attachments) {
			                if (response.Property.user_attachments.hasOwnProperty(key)) {
			                    if(response.Property.user_attachments[key].module_name == "Property Checklist") {
			                    	file_cnt++;
			                        // Set kebab menu conditions
							        var kebab = '';
							        var kebab_item = '';
							        kebab_item += '<a data-type="normal" data-name="'+response.Property.user_attachments[key].display_name+'" data-url="'+response.Property.user_attachments[key].file_url+'" class="dropdown-item view_document_preview" href="javascript:void(0)">View</a>';

							        kebab_item += '<a class="dropdown-item" href="'+response.Property.user_attachments[key].file_url+'" download="'+response.Property.user_attachments[key].display_name+'" target="_blank">Download</a>';

							        if(response.Property.user_attachments[key].user_id == user_id && response.Property.user_attachments[key].status == 1) {
							        	kebab_item += '<a data-id="'+response.Property.user_attachments[key].id+'" class="dropdown-item delete_property_image" href="javascript:void(0)">Delete</a>';
							        }

							        if(user_service_name == sa_role && response.Property.user_attachments[key].status == 2) {
							        	kebab_item += '<a data-id="'+response.Property.user_attachments[key].id+'" class="dropdown-item undelete_property_image" href="javascript:void(0)">Un-Delete</a>';
							        }

							        if($.trim(kebab_item) != '') {
							        	kebab = '<div class="text-center"><div class="input-group-prepend"><i class="align-middle mr-2 fas fa-fw fa-ellipsis-v" data-toggle="dropdown" aria-expanded="false"></i><div class="dropdown-menu dropdown-menu-right" style="">'+kebab_item+'</div></div></div>';
							        }

							        var name = response.Property.user_attachments[key].display_name;
									
									var doc_status = '';
				                    if(response.Property.user_attachments[key].status == 1) {
				                        doc_status += '<span class="badge badge-primary">Active</span>';
				                    } else if(response.Property.user_attachments[key].status == 2) {
				                        doc_status += '<span class="badge badge-danger">Deleted</span>';
				                    }

									if(user_service_name == sa_role || response.Property.user_attachments[key].status == 1) {
										var uploaded_by = response.Property.user_attachments[key].user.first_name+' '+response.Property.user_attachments[key].user.last_name;
										var uploaded_on = response.Property.user_attachments[key].created;

										var tr_bg = 'odd';
					                	if(key % 2 == 1) {
					                		tr_bg = 'even';
					                	}

										checklist_list += '<tr id="user_property_attachment_'+response.Property.user_attachments[key].id+'" class="'+tr_bg+'">';
					                		checklist_list += '<td>'+response.Property.user_attachments[key].id+'</td>';
					                		checklist_list += '<td>'+name+'</td>';
				                            checklist_list += '<td>&nbsp;</td>';
				                            checklist_list += '<td>&nbsp;</td>';
				                            checklist_list += '<td>'+doc_status+'</td>';
				                            //checklist_list += '<td>'+response.Property.user_attachments[key].file_size+'</td>';
				                            checklist_list += '<td>'+uploaded_by+'</td>';
				                            checklist_list += '<td>'+uploaded_on+'</td>';
				                            checklist_list += '<td>'+kebab+'</td>';
			                        	checklist_list += '</tr>';
							        }
			                    }
			                }
			            }

			            if(file_cnt == 0) {
			            	checklist_list += '<a href="javascript:void(0)" class="list-group-item list-group-item-action border-0 bg-light text-left"><div class="fs-16">No results found</div></a>';
			            }
			            $('#property-document-data-container').html(checklist_list);

			            var property_document_table = $("#property-document-datatable").DataTable({
			            	responsive: true,
			            	pageLength: 6,
							lengthChange: false,
							dom: 'frtip',
			    			autoWidth: false,
			    			searching: false,
			            	paging: false,
			            	info: false,
						    scrollY: '400px',
						    scrollX: false,
					        order: [[0,'desc']],
					        columnDefs: [
					        	{ className: 'all', targets: [1], width:'150px', orderable: true},
							],
						});
			        } else {
			            display_error_message(response);
			        }
			    }
			});
		});
	}, 1000);
}

window.getPropertyAccountingWidgetInfo = function(date_macro) {
	if ($.fn.DataTable.isDataTable("#property-accounting-datatable")) {
        $("#property-accounting-datatable").DataTable().destroy();
    }
	openLoadingDialog($('#property_accounting_widget'));
    setTimeout(function(){
    	var user_id = user.user_details.user_id;
	    var tz = jstz.determine();
		var timezone = tz.name();
		var property_id = $('#selected_property_id').val();

		$('#property_accounting_widget').load('components/property/property_accounting_widget.html', property_id , function(){
			$.ajax({
		        method: 'POST',
		        url: api_url+"scheduled-payments/payment-receipt-report",
		        data: 'user_timezone='+timezone+'&user_id='+user_id+'&user_service_name='+user_service_name+'&date_macro='+date_macro+'&property_id='+property_id,
		        beforeSend: function(xhr) {
			        xhr.setRequestHeader('Authorization', accessToken);
			    },
			    success: function(response) {
			    	if(response.response_data.success == true) {
			    		var html = '';
						if(response.TotalscheduledPayments == 0) {
				        	html += '<a href="javascript:void(0)" class="list-group-item list-group-item-action border-0 bg-light text-left"><div class="fs-16">'+response.response_data.message+'</div></a>';
					    } else {
					    	for (var key in response.scheduledPayments) {
				                if (response.scheduledPayments.hasOwnProperty(key)) {

				                	// Proeprty Detail
				                	var property_name = response.scheduledPayments[key].property.name;
				                	var property_owner = response.scheduledPayments[key].po_name;

				                	var property_tenant = '';
				                	var tenant_detail = '';
				                	var tenant_name = '';
				                	var tenant_email = '';
				                	if(response.scheduledPayments[key].tenant_detail) {
					                	for (var tkey in response.scheduledPayments[key].tenant_detail) {
				                            if (response.scheduledPayments[key].tenant_detail.hasOwnProperty(tkey)) {
				                                tenant_detail += response.scheduledPayments[key].tenant_detail[tkey].first_name+' '+response.scheduledPayments[key].tenant_detail[tkey].last_name+',';
				                                tenant_name += response.scheduledPayments[key].tenant_detail[tkey].first_name+' '+response.scheduledPayments[key].tenant_detail[tkey].last_name+'<small>(Tenant)</small>';
				                                tenant_email += response.scheduledPayments[key].tenant_detail[tkey].email;
				                            }
				                        }
			                        	tenant_detail = tenant_detail.replace(/,\s*$/, "");
			                        	property_tenant += tenant_detail;
			                        }

			                        var property_detail = property_name+'<br>'+'Current Owner:&nbsp;'+property_owner+'<br>Current Tenant:&nbsp;'+property_tenant;

			                        // Payment Method
			                        var bank_data = '';
					                var payment_details = response.scheduledPayments[key].payment_details;
					                if(payment_details != null) {
					                	if(Object.keys(payment_details).length !== 0) {
				                			bank_data = response.scheduledPayments[key].payment_details.bank_name+'('+response.scheduledPayments[key].payment_details.account_number+')';
					                	}
					                }

			                        // Source and Destination
			                        var source_name = '';
			                        var source_email = '';
			                        var source_bank = '';
			                        var destination_name = '';
			                        var destination_email = '';
			                        var destination_bank = '';

			                        var company_name = 'Rent Synergy';
			                        if(response.scheduledPayments[key].payment_type == 1) {
			                        	source_name = response.scheduledPayments[key].po_name+'<small>(Property Owner)</small>';
			                        	source_email = response.scheduledPayments[key].po_email;
			                        	source_bank = bank_data;
			                        	destination_name = company_name;
			                        } else if(response.scheduledPayments[key].payment_type == 2 || response.scheduledPayments[key].payment_type == 7) {
			                        	source_name = tenant_name;
			                        	source_email = tenant_email;
			                        	source_bank = bank_data;
			                        	destination_name = response.scheduledPayments[key].po_name+'<small>(Property Owner)</small>';
			                        	destination_email = response.scheduledPayments[key].po_email;
			                        } else if(response.scheduledPayments[key].payment_type == 3 || response.scheduledPayments[key].payment_type == 4) {
			                        	source_name = tenant_name;
			                        	source_email = tenant_email;
			                        	source_bank = bank_data;
			                        	destination_name = company_name;
			                        } else if(response.scheduledPayments[key].payment_type == 8) {
			                        	source_name = response.scheduledPayments[key].po_name+'<small>(Property Owner)</small>';
			                        	source_email = response.scheduledPayments[key].po_email;
			                        	source_bank = bank_data;
			                        	destination_name = company_name;
			                        } else if(response.scheduledPayments[key].payment_type == 9) {
			                        	source_name = company_name;
			                        	destination_name = tenant_name;
			                        	destination_email = tenant_email;
			                        	destination_bank = bank_data;
			                        }

			                        var source_data = source_name+'<br>'+source_email+'<br>'+source_bank;
			                        var destination_data = destination_name+'<br>'+destination_email+'<br>'+destination_bank;
			                        
			                        // Payment Due Date
			                        var payment_due_date = response.scheduledPayments[key].payment_due_date;
				                	if(response.scheduledPayments[key].payment_due_date == null) {
				                		payment_due_date = '';
				                	}

				                	// Rent month
			                        var rent_month = response.scheduledPayments[key].rent_month;
				                	if(response.scheduledPayments[key].rent_month == null) {
				                		rent_month = '';
				                	}

				                	// Authorized Date
			                        var authorized_date = response.scheduledPayments[key].authorized_date;
			                        var authorized_time = response.scheduledPayments[key].authorized_time;
				                	if(response.scheduledPayments[key].authorized_date == null) {
				                		authorized_date = '';
				                		authorized_time = '';
				                	}

				                	// Initiated date Date
			                        var initiated_date = response.scheduledPayments[key].initiated_date;
			                        var initiated_time = response.scheduledPayments[key].initiated_time;
				                	if(response.scheduledPayments[key].initiated_date == null) {
				                		initiated_date = '';
				                		initiated_time = '';
				                	}

				                	// Completed date Date
			                        var completed_date = response.scheduledPayments[key].completed_date;
			                        var completed_time = response.scheduledPayments[key].completed_time;
				                	if(response.scheduledPayments[key].completed_date == null) {
				                		completed_date = '';
				                		completed_time = '';
				                	}

				                	// Coupon Data
				                	var coupon_code = response.scheduledPayments[key].coupon_code.code;
				                	var coupon_amount = '$'+response.scheduledPayments[key].coupon_amount;
				                	if(response.scheduledPayments[key].coupon_id == 0) {
				                		coupon_code = '';
				                		coupon_amount = '';
				                	}

				                	var coupon_data = coupon_code+'<br>'+coupon_amount;

				                	// Status
				                	var application_status = '';
				                    if(response.scheduledPayments[key].application_constant == 'pending_authorization') {
				                        application_status += '<span class="badge badge-danger fs-12">'+response.scheduledPayments[key].application_status+'</span>';
				                    } else if(response.scheduledPayments[key].application_constant == 'transfer_authorized') {
				                        application_status += '<span class="badge badge-warning fs-12">'+response.scheduledPayments[key].application_status+'</span>';
				                    } else if(response.scheduledPayments[key].application_constant == 'transfer_in_progress') {
				                        application_status += '<span class="badge badge-warning fs-12">'+response.scheduledPayments[key].application_status+'</span>';
				                    } else if(response.scheduledPayments[key].application_constant == 'processed') {
				                        application_status += '<span class="badge badge-success fs-12">'+response.scheduledPayments[key].application_status+'</span>';
				                    } else if(response.scheduledPayments[key].application_constant == 'cancelled_authorized_transfer' || response.scheduledPayments[key].application_constant == 'cancelled_transfer' || response.scheduledPayments[key].application_constant == 'failed_transfer') {
				                        application_status += '<span class="badge badge-danger fs-12">'+response.scheduledPayments[key].application_status+'</span>';
				                    } else if(response.scheduledPayments[key].application_constant == 'processing') {
				                        application_status += '<span class="badge badge-primary fs-12">'+response.scheduledPayments[key].application_status+'</span>';
				                    }

				                    var kebab = '';
				                    var kebab_item = '';
				                    if(user.permissionData['PROPERTIES']["feature_items['cancel_transaction']"]) {
					                    if(response.scheduledPayments[key].application_constant == 'transfer_authorized') {
					                    	kebab_item += '<a class="dropdown-item cancel_payment" data-id="'+response.scheduledPayments[key].id+'" data-type="authorized" href="javascript:void(0)">Cancel</a>';
					                    } else if(response.scheduledPayments[key].application_constant == 'transfer_in_progress') {
					                    	kebab_item += '<a class="dropdown-item cancel_payment" data-id="'+response.scheduledPayments[key].id+'" data-type="dwolla_transfer" href="javascript:void(0)">Cancel</a>';
					                    }
					                }

					                if(user.permissionData['PROPERTIES']["feature_items['regenerate_transaction']"]) {
					                    if(user_service_name == sa_role && response.scheduledPayments[key].regenerate_transaction == 1) {
				                    		kebab_item += '<a class="dropdown-item regenerate_transaction" data-id="'+response.scheduledPayments[key].id+'" data-payment-type="'+response.scheduledPayments[key].payment_type+'" href="javascript:void(0)">Regenerate Transaction</a>';
				                    	}
				                    }

				                    if(user_service_name == po_role && response.scheduledPayments[key].authorize_subscription_fee == 1) {
			                    		kebab_item += '<a class="dropdown-item authorize_subscription_fee" data-id="'+response.scheduledPayments[key].id+'" data-property-id="'+response.scheduledPayments[key].property_id+'" data-payment-type="'+response.scheduledPayments[key].payment_type+'" data-payment-id="'+response.scheduledPayments[key].payment_details_id+'" data-month="'+response.scheduledPayments[key].rent_month+'" data-open-from="account" href="javascript:void(0)">Authorize Subscription Fee</a>';
			                    	}

			                    	if($.trim(kebab_item) != '') {
							        	kebab = '<div class="text-center"><div class="input-group-prepend"><i class="align-middle mr-2 fas fa-fw fa-ellipsis-v" data-toggle="dropdown" aria-expanded="false"></i><div class="dropdown-menu dropdown-menu-right" style="">'+kebab_item+'</div></div></div>';
							        }

				                    // Add exclamation button, when click display related transaction detail
				                	var exclamation = '';
				                	if(application_status != '') {
				                		exclamation = '<i data-id='+response.scheduledPayments[key].id+' data-transferID='+response.scheduledPayments[key].dwolla_transferID+' class="align-middle mr-2 fas fa-fw fa-info-circle ml-2 get_related_transaction" data-container="body" data-toggle="popover"></i>';
				                	}

			                        var property_setup_fee = '<span>'+response.scheduledPayments[key].property_setup_fee+'</span>';
			                        var security_deposit_fee = '<span>'+response.scheduledPayments[key].security_deposit_fee+'</span>';
			                        var pet_deposit_fee = '<span>'+response.scheduledPayments[key].pet_deposit_fee+'</span>';
			                        var subscription_fee = '<span>'+response.scheduledPayments[key].subscription_fee+'</span>';
			                        var rent_fee = '<span>'+response.scheduledPayments[key].rent_fee+'</span>';

			                        var rent_due_by = '';
			                        var rent_amount = '';
			                        var no_of_days = '';
			                        var late_fee_per_day = '';
			                        var outstanding_late_fee = '';
			                        var late_fee_waived = '';
			                        var total_rent_due = '';
			                        if(response.scheduledPayments[key].is_late_fee_enabled == 1 && response.scheduledPayments[key].waive_late_fee > 0 && (response.scheduledPayments[key].payment_type == 2 || response.scheduledPayments[key].payment_type == 7)) {
				                        rent_due_by = response.scheduledPayments[key].payment_due_date;
				                        rent_amount = '$'+response.scheduledPayments[key].total;
				                        no_of_days = response.scheduledPayments[key].no_of_days;
				                        late_fee_per_day = '$'+response.scheduledPayments[key].price_per_day;
				                        if(response.scheduledPayments[key].late_fee_amount > 0) {
				                        	outstanding_late_fee = '$'+response.scheduledPayments[key].late_fee_amount;
				                        }
				                        if(response.scheduledPayments[key].waived_amount > 0) {
				                        	late_fee_waived = '$'+response.scheduledPayments[key].waived_amount;
				                        }
				                        total_rent_due = '$'+response.scheduledPayments[key].final_total;
				                    }

				                    var receipts = '-';
				                    if(response.scheduledPayments[key].receipts > 0 || response.scheduledPayments[key].receipts != '') {
				                    	receipts = response.scheduledPayments[key].receipts;
				                    }

				                    var payments = '-';
				                    if(response.scheduledPayments[key].payments > 0 || response.scheduledPayments[key].payments != '') {
				                    	payments = response.scheduledPayments[key].payments;
				                    }

				                    var tr_bg = 'odd';
				                	if(key % 2 == 1) {
				                		tr_bg = 'even';
				                	}
			                 
				                	html += '<tr data-id="'+response.scheduledPayments[key].id+'" class="'+tr_bg+'">';
				                		html += '<td class="text-left">'+response.scheduledPayments[key].id+'</td>';
				                		//html += '<td>'+property_detail+'</td>';
				                		html += '<td><span class="badge badge-primary fs-12">'+response.scheduledPayments[key].payment_type_label+'</span></td>';
			                            html += '<td>'+application_status+'<span class="ml-2">'+exclamation+'</span></td>';
			                            html += '<td class="text-right">'+receipts+'</td>';
			                            html += '<td class="text-right">'+payments+'</td>';
			                            html += '<td>'+rent_month+'</td>';
			                            html += '<td>'+authorized_date+'<br><small>'+authorized_time+'</small></td>';
			                            html += '<td>'+initiated_date+'<br><small>'+initiated_time+'</small></td>';
			                            html += '<td>'+completed_date+'<br><small>'+completed_time+'</small></td>';
			                            html += '<td>'+source_data+'</td>';
			                            html += '<td>'+destination_data+'</td>';
			                            html += '<td>'+coupon_data+'</td>';
			                            html += '<td>-</td>';
			                            html += '<td>'+kebab+'</td>';
			                        html += '</tr>';
				                }
				            }
				        }

				        $('#property-accounting-data-container').html(html);
			            $('#property-accounting-datatable thead, #property-accounting-datatable tfoot').removeClass('d-none');
			            //$('#property-accounting-datatable').addClass('table-bordered');

			            $('#payment_total').html(response.payment_total);
				        $('#receipt_total').html(response.receipt_total);

			            var buttonCommon = {
					        exportOptions: {
					            format: {
					                body: function ( data, row, column, node ) {
					                	// Replace <br> with space
					                	var regex = /<br\s*[\/]?>/gi;
					                	data = data.replace(regex, " ");
					                	//data = data.replace(regex, "\n");

					                	// Remove all html tags from data and Remove $ from amount
					                	var regex = /(<([^>]+)>)/ig;
					                    var amt_col = [3,4];  
					                    if(amt_col.indexOf(column) !== -1) {
					                    	var data = data.replace(regex, "");
					                    	return data.replace( /[$,]/g, '' );
					                    } else {
					                    	return data.replace(regex, "");
					                    }
					                }
					            }
					        }
					    };

					    var transaction_table = $("#property-accounting-datatable").DataTable({
			            	//responsive: true,
			            	fixedHeader: true,
			            	pageLength: 6,
							lengthChange: false,
							dom: 'Bfrtip',
			    			autoWidth: false,
			    			paging: false,
			            	info: false,
						    scrollY: '350px',
						    scrollX: true,
					        order: [[0,'desc']],
					        columnDefs: [
					        	{ "visible": false, "targets": [6,7, 8, 9, 10, 11, 12] }
							],
					        buttons: [
					        	$.extend( true, {}, buttonCommon, {
					                extend: 'excelHtml5',
						            text: '<i class="align-middle fas fa-fw fa-download"></i>',
						            title: 'Rental Ledger',
						            message:'Disclaimer: Rent synergy has made every attempt to ensure the correctness of this report, based on the data entered and available with us. Before the final consumption of this report, we recommend you to verify and validate this data with your accounting team.',
						            autoFilter: true,
						            header: true,
						            footer: true,
						            exportOptions: {
					                    //columns: ':visible'
					                    columns: ':visible:not(.notexport)'
					                },
					                customize: function(xlsx) {
								        var sheet = xlsx.xl.worksheets['sheet1.xml'];
								        datatable_excel_common_formatting(xlsx,sheet);
								    }
					            }),
					            $.extend( true, {}, buttonCommon, {
					                extend: 'colvis',
					              	text: "<i class='align-middle mr-2 fas fa-fw fa-eye'></i>",
					              	collectionLayout: 'two-column',
					              	postfixButtons: ['colvisRestore'],
					            }),
							],
							language: {
						        buttons: {
						          	//colvisRestore: "Select All",
						          	colvisRestore: "Restore"
						        }
						    }
						});

						$('.buttons-html5').removeClass('btn-secondary').addClass('btn-orange');
						$('.buttons-colvis').removeClass('btn-secondary').addClass('btn-orange');
						$('#property-accounting-datatable_filter').css({
							'position': 'absolute',
							'top': '5px',
							'right': '1%'
						})

						var dd = '';
						dd += '<div class="btn-group form-button ml-2">';
						    dd += '<div class="dropdown">';
						         dd += '<button type="button" class="btn btn-sm pmd-btn-fab pmd-btn-raised pmd-ripple-effect btn-orange dropdown-toggle date-macro" data-toggle="dropdown" data-placement="bottom" data-original-title="Date macros"><i class="align-middle mr-2 far fa-fw fa-calendar-alt"></i></button>';
						        dd += '<ul class="dropdown-menu dropdown-menu-right" id="date_macros" style="right:20px;">';
						            dd += '<li><a href="javascript:void(0)">All Dates</a></li>';
						            dd += '<li><a href="javascript:void(0)">Today</a></li>';
						            dd += '<li><a href="javascript:void(0)">This Week</a></li>';
						            dd += '<li><a href="javascript:void(0)">This Month</a></li>';
						            dd += '<li><a href="javascript:void(0)">This Quarter</a></li>';
						            dd += '<li><a href="javascript:void(0)">This Year</a></li>';
						            dd += '<li><a href="javascript:void(0)">Last Week</a></li>';
						            dd += '<li><a href="javascript:void(0)">Last Month</a></li>';
						            dd += '<li><a href="javascript:void(0)">Last Quarter</a></li>';
						            dd += '<li><a href="javascript:void(0)">Last Year</a></li>';
						        dd += '</ul>';
						    dd += '</div>';
						dd += '</div>';

						$('.dt-buttons').append(dd);

						$('.dt-buttons > button').addClass('mr-2');
						$('.dt-buttons').css({
							'position': 'absolute',
							'left': '11%',
							'top' : '4px'
						});
						$('.btn-group > .btn:not(:last-child):not(.dropdown-toggle), .btn-group > .btn-group:not(:first-child) > .btn').css({
							'border-radius': '5px'
						});

						$('.dt-buttons').insertAfter('#property_accounting_widget > .card-header > .card-title');

						// Get Related Transaction Detail
			            var title = 'Transaction Detail';
			            var type = 'RelatedTransaction';
			            openPopoverModal(title,type);
			        } else {
			            display_error_message(response);
			        }
			    }
			});
		});
	}, 1000);
}

window.getPropertyDetail = function(property_id, type) {
	var user_id = user.user_details.user_id;
	$.ajax({
        method: 'POST',
        //url: api_url+"properties/"+property_id,
        url: api_url+"properties/view",
        data: 'property_id='+property_id+'&user_service_name='+user_service_name+'&user_id='+user_id,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
    }).then(function(response) {
        if(response.response_data.success == true) {

        	$('#selected_property_id').val(property_id);
            $('#selected_property_user_id').val(response.Property.user_id);

            if(user.permissionData['PROPERTIES']["feature_items['update_proeprty_detail']"]) {
            	$('#property_detail_footer').html('<button data-id="'+property_id+'" data-module="Property" class="btn btn-orange my-2 my-sm-0 ml-2 update_property_data">Update</button>');
            }

            if(user_service_name == t_role) {
            	$('.add_new_lease, .add_new_tenant').remove();
            	$('#propertyContactAccordion, #propertyDetailAccordion, #pictureAccordion, #property_picture_list_container, #lease_data_container, #tenant_data_container').addClass('add_opacity');
            }

        	// Create property picture list container
        	if(user.permissionData['PROPERTIES']["feature_items['update_property_pictures']"]) {
	            var img_list = '';
	            var prev_html = '';
	            var img_crousal_list = '';
	            var active = '';

	            for (var key in response.Property.user_attachments) {
	                if (response.Property.user_attachments.hasOwnProperty(key)) {
	                	var checked = '';
	                    if(response.Property.user_attachments[key].module_name == "Property" && response.Property.user_attachments[key].status != 2) {
	                    	if(response.Property.user_attachments[key].cover_image == 1) {
	                    		checked = "checked";
	                    	}
	                        
	                        img_list += '<div id="user_property_media_'+response.Property.user_attachments[key].id+'" data-id="'+response.Property.user_attachments[key].id+'" data-property-id="'+property_id+'" class="col-12 col-md-4 col-xl-4 property_image"> <div class="card bg-light"> <div class="card-img"><input data-id="'+response.Property.user_attachments[key].id+'" name="property-img-radio" type="radio" class="d-none property_cover_image" '+checked+' data-toggle="tooltip" data-placement="bottom" title="Cover Image" data-original-title="Cover Image"><i class="align-middle mr-2 fas fa-fw fa-trash d-none delete_property_image" data-id="'+response.Property.user_attachments[key].id+'" data-module="Property" data-toggle="tooltip" data-placement="bottom" title="Delete" data-original-title="Delete"></i><img class="card-img-top" src="'+response.Property.user_attachments[key].aws_url+'" alt="Unsplash"></div> </div> </div>';	                        
	                    }
	                }
	            }	            
	            $('#property_picture_list_container').html(img_list);	            
	        } else {
	        	$('#p_picture_container').addClass('d-none');
	        }
            
            if(response.Property.same_as_owner_address == true) {
            	$( "#same_as_owner_address").prop('checked', true);
            	$('#primary_contact_firstname,#primary_contact_lastname,#primary_contact_businessname,#primary_contact_address_line1,#primary_contact_address_line2,#primary_contact_city,#primary_contact_state,#primary_contact_zip_code,#primary_contact_country').attr('disabled', true);
            	$('.save_property_primary_contact_detail').attr('disabled', true);
            	//$('#propertyContactAccordion').addClass('add_opacity');
            } else {
            	$( "#same_as_owner_address").prop('checked', false);
            	$('#primary_contact_firstname,#primary_contact_lastname,#primary_contact_businessname,#primary_contact_address_line1,#primary_contact_address_line2,#primary_contact_city,#primary_contact_state,#primary_contact_zip_code,#primary_contact_country').attr('disabled', false);
            	$('.save_property_primary_contact_detail').attr('disabled', false);
            	//$('#propertyContactAccordion').removeClass('add_opacity');
            }

            if(response.Property.primary_contact_address != null) {
                $('#primary_contact_firstname').val(response.Property.first_name);
		        $('#primary_contact_lastname').val(response.Property.last_name);
		        $('#primary_contact_businessname').val(response.Property.business_name);
	            $('#primary_contact_id').val(response.Property.primary_contact_address.id);
	            $('#primary_contact_address_line1').val(response.Property.primary_contact_address.address_line1);
	            $('#primary_contact_address_line2').val(response.Property.primary_contact_address.address_line2);
	            $('#primary_contact_city').val(response.Property.primary_contact_address.city);
	            $('#primary_contact_state').val(response.Property.primary_contact_address.state);
	            $('#primary_contact_zip_code').val(response.Property.primary_contact_address.zip_code);
	            $('#primary_contact_country').val(response.Property.primary_contact_address.country);
	        } else {
	        	$('#primary_contact_firstname').val(response.Property.user.first_name);
		        $('#primary_contact_lastname').val(response.Property.user.last_name);
		        $('#primary_contact_businessname').val(response.addressData.address.business_name);
	            $('#primary_contact_address_line1').val(response.addressData.address.address_line1);
	            $('#primary_contact_address_line2').val(response.addressData.address.address_line2);
	            $('#primary_contact_city').val(response.addressData.address.city);
	            $('#primary_contact_state').val(response.addressData.address.state);
	            $('#primary_contact_zip_code').val(response.addressData.address.zip_code);
	            $('#primary_contact_country').val(response.addressData.address.country);
	        }

	        if(user_service_name == 'c963a422-edb4-4bad-97ed-276c5abb3b79') {
		        $('#po_detail_card').addClass('d-none');
		    }

		    var po_detail = response.Property.user.first_name+' '+response.Property.user.last_name+'<br>'+response.Property.user.email;
		    $('#po_detail_card span').html(po_detail);

            if(response.Property.property_type != null) {
            	$('#property_types').val(response.Property.property_type.id);
            } else {
            	$('#property_types').val('');
            }

            $('#property_no_of_units').val(response.Property.no_of_units);
            $('#property_year_built').val(response.Property.built_year);
            $('#property_square_feet').val(response.Property.square_feet);
            $('#property_bedrooms').val(response.Property.no_of_bed);
            $('#property_bathrooms').val(response.Property.no_of_bath);
            $('#property_parking_spaces').val(response.Property.no_of_parking);
            $('#property_swimming_pool').val(response.Property.swimming_pool);
            $('#property_type_of_colling').val(response.Property.type_of_cooling);
            $('#property_type_of_heating').val(response.Property.type_of_heating);
            $('#property_type_of_water_heater').val(response.Property.type_of_water_heater);
            $('#property_ac_filter_size').val(response.Property.ac_filter_size);
            $('#property_ac_make_and_model').val(response.Property.ac_model);
            $('#property_water_heater_make_and_model').val(response.Property.water_heater_model);
            $('#property_additional_information').val(response.Property.additional_information);
        } else {
            display_error_message(response);
        }
    }, function() {
        // hang on this step if the error occur
    });
}

window.loadPropertyImagePreview = function(id, property_id) {
	var user_id = user.user_details.user_id;
	$.ajax({
        method: 'POST',
        url: api_url+"properties/view",
        data: 'property_id='+property_id+'&user_service_name='+user_service_name+'&user_id='+user_id,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
	    success: function(response) {
	    	if(response.response_data.success == true) {
	    	    var prev_html = '';

	    	    $('#pname').html(response.Property.name);

	    	    // Below code is used when user click on image and display full image
	            
	            prev_html += '<div class="pt-0">';
            		prev_html += '<div id="carouselmageIndicators" class="carousel slide" data-ride="carousel">';
		                prev_html += '<ol class="carousel-indicators modal-carousel-indicators">';
			                var img_cnt = 0;
			                for (var key in response.Property.user_attachments) {
				                if (response.Property.user_attachments.hasOwnProperty(key)) {
				                	if(response.Property.user_attachments[key].module_name == "Property" && response.Property.user_attachments[key].status != 2) {
				                		var act = '';
				                		if(response.Property.user_attachments[key].id == id) { act = 'active'; }
				                		prev_html += '<li data-target="#carouselmageIndicators" data-slide-to="'+img_cnt+'" class="'+act+'"></li>';
				                		img_cnt = img_cnt + 1;
			                    	}
			                    }
			                }
		                prev_html += '</ol>';
		                prev_html += '<div class="carousel-inner">';
		                	for (var key in response.Property.user_attachments) {
				                if (response.Property.user_attachments.hasOwnProperty(key)) {
				                	if(response.Property.user_attachments[key].module_name == "Property" && response.Property.user_attachments[key].status != 2) {
				                		var act1 = '';
				                		if(response.Property.user_attachments[key].id == id) { act1 = 'active'; }
			                			prev_html += '<div class="carousel-item '+act1+'" data-interval="10000">';
					                        prev_html += '<img class="d-block" src="'+response.Property.user_attachments[key].aws_url+'" alt="'+response.Property.user_attachments[key].display_name+'">';
					                    prev_html += '</div>';
				                    }
					            }
					        }
				        prev_html += '</div>';
				        prev_html += '<a class="carousel-control-prev" href="#carouselmageIndicators" role="button" data-slide="prev">';
		                    prev_html += '<span class="carousel-control-prev-icon" aria-hidden="true"></span>';
		                    prev_html += '<span class="sr-only">Previous</span>';
		                prev_html += '</a>';
		                prev_html += '<a class="carousel-control-next" href="#carouselmageIndicators" role="button" data-slide="next">';
		                    prev_html += '<span class="carousel-control-next-icon" aria-hidden="true"></span>';
		                    prev_html += '<span class="sr-only">Next</span>';
		                prev_html += '</a>';
		            prev_html += '</div>';
		        prev_html += '</div>';

		        $('#image_preview_container').html(prev_html);

				$('#carouselmageIndicators').carousel({
				  	cycle: true
				}); 

				// Total counting
				var totalItems = $('#carouselmageIndicators .carousel-item').length;
				var currentIndex = $('#carouselmageIndicators div.carousel-inner div.active').index() + 1;
				$('.totalNum').html(''+currentIndex+'/'+totalItems+'');

				$('#carouselmageIndicators').on('slid.bs.carousel', function() {
				    currentIndex = $('#carouselmageIndicators div.carousel-inner div.active').index() + 1;
				   $('.totalNum').html(''+currentIndex+'/'+totalItems+'');
				});

				// Change position of indicators to display proper in tab and mobile
				$('#modal_indicators').append($('.modal-carousel-indicators'));
	        } else {
	            display_error_message(response);
	        }
	    }
    });
}

window.getPropertyDocument = function(layout) {
	var user_id = user.user_details.user_id;
	var property_id = $('#selected_property_id').val();
	$.ajax({
        method: 'POST',
        //url: api_url+"properties/"+property_id,
        url: api_url+"properties/view",
        data: 'property_id='+property_id+'&user_service_name='+user_service_name+'&user_id='+user_id,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
    }).then(function(response) {
        if(response.response_data.success == true) {

        	$('#selected_property_id').val(property_id);
            $('#selected_property_user_id').val(response.Property.user_id);

            // Set Property zoho template and normal pdf file data
            
            var checklist_list = '';

            // Start : Document section
            var file_cnt = 0;
            if(zoho_signature_enabled == "true") {
                if(response.templateData != null) {
	            	for (var key in response.templateData) {
		                if (response.templateData.hasOwnProperty(key)) {
		                	file_cnt++

		                	// Create data for receipents
		                	var user_html = '';
		                	for (var akey in response.templateData[key].recipients) {
		                		if (response.templateData[key].recipients.hasOwnProperty(akey)) {
		                			user_html += '<div>'+response.templateData[key].recipients[akey].recipient+' : <strong>'+response.templateData[key].recipients[akey].status+'</strong> on '+response.templateData[key].recipients[akey].date+'</div>';
		                		}
		                	}

	                        // Set kebab menu conditions
					        var document_status = '';
					        var kebab = '';
					        var kebab_item = '';
			                var send_for_signature = '';
							var btn_class = '';

							var name = response.templateData[key].zoho_template.zoho_templateName;
							
							if(user_service_name == sa_role && response.templateData[key].status == 0) {
								kebab_item += '<a data-user-id='+response.templateData[key].user_id+' data-zoho-template-id='+response.templateData[key].zoho_template_id+' data-zohoTemplateID="'+response.templateData[key].zoho_template.zoho_templateID+'" data-zohoActionID="'+response.templateData[key].zoho_template.zoho_actionID+'" class="dropdown-item send_for_signatures" href="javascript:void(0)">Send for Signatures</a>';

								document_status += '<div class="badge badge-danger" title="'+response.templateData[key].zoho_template.zoho_templateName+'">'+response.templateData[key].status_label+'</div>';
							} else {
								if(response.templateData[key].status == 1) {
									btn_class = 'badge-primary';
								} else if(response.templateData[key].status == 2) {
									btn_class = 'badge-primary';
								} else if(response.templateData[key].status == 3) {
									btn_class = 'badge-warning';
								} else if(response.templateData[key].status == 4) {
									btn_class = 'badge-success';
								} else if(response.templateData[key].status == 5) {
									btn_class = 'badge-warning';
								} else if(response.templateData[key].status == 6) {
									btn_class = 'badge-success';
								} else if(response.templateData[key].status == 7) {
									btn_class = 'badge-danger';
								} 

								document_status += '<div class="badge '+btn_class+'" title="'+response.templateData[key].zoho_template.zoho_templateName+'" data-container="body" data-toggle="popover" data-placement="top" data-content="'+user_html+'">'+response.templateData[key].status_label+'</div>';
							}

							if(response.templateData[key].status > 0) {
								kebab_item += '<a data-type="zoho" data-request-id="'+response.templateData[key].sign_requestID+'" class="dropdown-item view_document_preview" href="javascript:void(0)">View</a>';
			                }

			                if(response.templateData[key].status == 0) {
			                	kebab_item += '<a data-id="'+response.templateData[key].id+'" class="dropdown-item delete_property_template" href="javascript:void(0)">Delete</a>';
			                }

					        if($.trim(kebab_item) != '') {
					        	kebab = '<div class="text-center"><div class="input-group-prepend"><i class="align-middle mr-2 fas fa-fw fa-ellipsis-v" data-toggle="dropdown" aria-expanded="false"></i><div class="dropdown-menu dropdown-menu-right" style="">'+kebab_item+'</div></div></div>';
					        }

					        if(layout == 'table') {
						        checklist_list += '<tr id="user_template_'+response.templateData[key].id+'">';
			                		checklist_list += '<td class="bg-light">'+response.templateData[key].id+'</td>';
			                		checklist_list += '<td class="bg-light">'+name+'</td>';
		                            checklist_list += '<td>'+response.templateData[key].zoho_template.document_for+'</td>';
		                            checklist_list += '<td>'+user_html+'</td>';
		                            checklist_list += '<td>&nbsp</td>';
		                            checklist_list += '<td>&nbsp</td>';
		                            checklist_list += '<td>&nbsp;</td>';
		                            checklist_list += '<td>'+document_status+'</td>';
		                            checklist_list += '<td>'+kebab+'</td>';
		                        checklist_list += '</tr>';
		                    } else {
		                    	checklist_list += '<div id="user_template_'+response.templateData[key].id+'" class="col-12 col-md-6 col-xl-3 property_card">';
						        	checklist_list += '<div class="card">';
										checklist_list += '<div class="card-header"><span class="accordion_arrow">'+kebab+' </span><h5 class="card-title capitalize mb-0 mr-3" title="'+response.templateData[key].zoho_template.zoho_templateName+'">'+name+'</h5></div>';
										checklist_list += '<div class="card-img"><img src="img/sample/pdf.png" width="100" height="100" alt="pdf" class="mb-2 mt-4"></div>';
										checklist_list += '<div class="card-body">';
											checklist_list += '<table class="table mb-0 card-table word-break">';
												checklist_list += '<tbody>';
													checklist_list += '<tr>';
														checklist_list += '<td class="nowrap">Document For</td>';
														checklist_list += '<td class="text-right">'+response.templateData[key].zoho_template.document_for+'</td>';
													checklist_list += '</tr>';
													checklist_list += '<tr>';
														checklist_list += '<td colspan="2" class="nowrap bold-black">Signature Recipients</td>';
													checklist_list += '</tr>';
												checklist_list += '</tbody>';
											checklist_list += '</table>';
											checklist_list += '<div class="mt-2 ml-2">'+user_html+'</div>';
											checklist_list += '<div class="text-right mt-4">'+document_status+'</div>';
										checklist_list += '</div>';
										checklist_list += '</div>';
									checklist_list += '</div>';
		                    }
		                }
		            }
	            }
	        } else {
	        	if(response.userContractData != null) {
	            	for (var key in response.userContractData) {
		                if (response.userContractData.hasOwnProperty(key)) {
		                	file_cnt++

		                	// Create data for receipents
		                	var user_html = '';
		                	for (var akey in response.userContractData[key].recipients) {
		                		if (response.userContractData[key].recipients.hasOwnProperty(akey)) {
		                			if(response.userContractData[key].recipients[akey].date == '') {
		                				user_html += '<div>'+response.userContractData[key].recipients[akey].recipient+'</div>';
		                			} else {
		                				user_html += '<div>'+response.userContractData[key].recipients[akey].recipient+' : <strong>'+response.userContractData[key].recipients[akey].status+'</strong> on '+response.userContractData[key].recipients[akey].date+'</div>';
		                			}
		                		}
		                	}

		                	// Set kebab menu conditions
					        var document_status = '';
					        var kebab = '';
					        var kebab_item = '';
			                var send_for_signature = '';
							var btn_class = '';

							var name = response.userContractData[key].contract.name;

							var application_status = '';
							if(user_service_name == sa_role && (response.userContractData[key].application_constant == 'document_draft' || response.userContractData[key].application_constant == 'document_ready_for_review' || response.userContractData[key].application_constant == 'document_pending_review')) {
								kebab_item += '<a data-contract-id="'+response.userContractData[key].contract.id+'" data-user-contract-id="'+response.userContractData[key].id+'" data-form="'+response.userContractData[key].contract.form+'" data-table="'+response.userContractData[key].contract.table_name+'" class="dropdown-item edit_property_contract" href="javascript:void(0)">Edit</a>';

								if(response.userContractData[key].application_constant == 'document_ready_for_review') {
									kebab_item += '<a data-contract-id="'+response.userContractData[key].contract.id+'" data-user-contract-id="'+response.userContractData[key].id+'" data-form="'+response.userContractData[key].contract.form+'" data-table="'+response.userContractData[key].contract.table_name+'" class="dropdown-item contract_send_for_review" href="javascript:void(0)">Send for review</a>';
								}

		                    	kebab_item += '<a data-id="'+response.userContractData[key].id+'" class="dropdown-item delete_property_contract" href="javascript:void(0)">Delete</a>';
							}

		                    if(response.userContractData[key].application_constant == 'document_draft') {
		                    	application_status += '<span class="badge badge-danger">'+response.userContractData[key].application_status+'</span>';
		                    } else if(response.userContractData[key].application_constant == 'document_ready_for_review' || response.userContractData[key].application_constant == 'document_pending_review') {
		                        application_status += '<span class="badge badge-warning">'+response.userContractData[key].application_status+'</span>';
		                    } else if(response.userContractData[key].application_constant == 'document_ready_for_signature') {
		                        application_status += '<span class="badge badge-warning">'+response.userContractData[key].application_status+'</span>';
		                        if(user_service_name == sa_role) {
		                        	kebab_item += '<a data-contract-id="'+response.userContractData[key].contract.id+'" data-user-contract-id="'+response.userContractData[key].id+'" data-form="'+response.userContractData[key].contract.form+'" data-table="'+response.userContractData[key].contract.table_name+'" class="dropdown-item contract_send_for_signature" href="javascript:void(0)">Send for signature</a>';
		                        }
		                    } else if(response.userContractData[key].application_constant == 'document_pending_signature') {
		                        application_status += '<span class="badge badge-success">'+response.userContractData[key].application_status+'</span>';
		                    } else if(response.userContractData[key].application_constant == 'document_completed') {
		                        application_status += '<span class="badge badge-primary">'+response.userContractData[key].application_status+'</span>';
		                    } else if(response.userContractData[key].application_constant == 'document_deleted') {
		                        application_status += '<span class="badge badge-danger">'+response.userContractData[key].application_status+'</span>';
		                    }
							
							if($.trim(kebab_item) != '') {
					        	kebab = '<div class="text-center"><div class="input-group-prepend"><i class="align-middle mr-2 fas fa-fw fa-ellipsis-v" data-toggle="dropdown" aria-expanded="false"></i><div class="dropdown-menu dropdown-menu-right" style="">'+kebab_item+'</div></div></div>';
					        }

					        if(layout == 'table') {
						        checklist_list += '<tr id="user_contract_'+response.userContractData[key].id+'">';
			                		checklist_list += '<td class="bg-light">'+response.userContractData[key].id+'</td>';
			                		checklist_list += '<td class="bg-light">'+name+'</td>';
		                            checklist_list += '<td>'+response.userContractData[key].contract.service.service_name+'</td>';
		                            checklist_list += '<td>'+user_html+'</td>';
		                            checklist_list += '<td>&nbsp</td>';
		                            checklist_list += '<td>&nbsp</td>';
		                            checklist_list += '<td>&nbsp;</td>';
		                            checklist_list += '<td>'+application_status+'</td>';
		                            checklist_list += '<td>'+kebab+'</td>';
		                        checklist_list += '</tr>';
		                    } else {
		                    	checklist_list += '<div id="user_contract_'+response.userContractData[key].id+'" class="col-12 col-md-6 col-xl-3 property_card">';
						        	checklist_list += '<div class="card">';
										checklist_list += '<div class="card-header"><span class="accordion_arrow">'+kebab+' </span><h5 class="card-title capitalize mb-0 mr-3" title="'+name+'">'+name+'</h5></div>';
										checklist_list += '<div class="card-img"><img src="img/sample/pdf.png" width="100" height="100" alt="pdf" class="mb-2 mt-4"></div>';
										checklist_list += '<div class="card-body">';
											checklist_list += '<table class="table mb-0 card-table word-break">';
												checklist_list += '<tbody>';
													checklist_list += '<tr>';
														checklist_list += '<td class="nowrap">Document For</td>';
														checklist_list += '<td class="text-right">'+response.userContractData[key].contract.service.service_name+'</td>';
													checklist_list += '</tr>';
												checklist_list += '</tbody>';
											checklist_list += '</table>';
											checklist_list += '<div class="mt-2 ml-2">'+user_html+'</div>';
											checklist_list += '<div class="text-right mt-4">'+application_status+'</div>';
										checklist_list += '</div>';
										checklist_list += '</div>';
									checklist_list += '</div>';
		                    }
		                }
		            }
	            }
	        }
            
            // Display normal document
            for (var key in response.Property.user_attachments) {
                if (response.Property.user_attachments.hasOwnProperty(key)) {
                    if(response.Property.user_attachments[key].module_name == "Property Checklist") {
                    	file_cnt++;
                        // Set kebab menu conditions
				        var kebab = '';
				        var kebab_item = '';
				        kebab_item += '<a data-type="normal" data-name="'+response.Property.user_attachments[key].display_name+'" data-url="'+response.Property.user_attachments[key].file_url+'" class="dropdown-item view_document_preview" href="javascript:void(0)">View</a>';

				        if(response.Property.user_attachments[key].user_id == user_id && response.Property.user_attachments[key].status == 1) {
				        	kebab_item += '<a data-id="'+response.Property.user_attachments[key].id+'" class="dropdown-item delete_property_image" href="javascript:void(0)">Delete</a>';
				        }

				        if(user_service_name == sa_role && response.Property.user_attachments[key].status == 2) {
				        	kebab_item += '<a data-id="'+response.Property.user_attachments[key].id+'" class="dropdown-item undelete_property_image" href="javascript:void(0)">Un-Delete</a>';
				        }

				        if($.trim(kebab_item) != '') {
				        	kebab = '<div class="text-center"><div class="input-group-prepend"><i class="align-middle mr-2 fas fa-fw fa-ellipsis-v" data-toggle="dropdown" aria-expanded="false"></i><div class="dropdown-menu dropdown-menu-right" style="">'+kebab_item+'</div></div></div>';
				        }

				        var name = response.Property.user_attachments[key].display_name;
						
						var doc_status = '';
	                    if(response.Property.user_attachments[key].status == 1) {
	                        doc_status += '<span class="badge badge-primary">Active</span>';
	                    } else if(response.Property.user_attachments[key].status == 2) {
	                        doc_status += '<span class="badge badge-danger">Deleted</span>';
	                    }

						if(user_service_name == sa_role || response.Property.user_attachments[key].status == 1) {
							var uploaded_by = response.Property.user_attachments[key].user.first_name+' '+response.Property.user_attachments[key].user.last_name;
							var uploaded_on = response.Property.user_attachments[key].created;
							if(layout == 'table') {
								checklist_list += '<tr id="user_property_attachment_'+response.Property.user_attachments[key].id+'">';
			                		checklist_list += '<td class="bg-light">'+response.Property.user_attachments[key].id+'</td>';
			                		checklist_list += '<td class="bg-light">'+name+'</td>';
		                            checklist_list += '<td>&nbsp;</td>';
		                            checklist_list += '<td>&nbsp;</td>';
		                            checklist_list += '<td>'+response.Property.user_attachments[key].file_size+'</td>';
		                            checklist_list += '<td>'+uploaded_by+'</td>';
		                            checklist_list += '<td>'+uploaded_on+'</td>';
		                            checklist_list += '<td>'+doc_status+'</td>';
		                            checklist_list += '<td>'+kebab+'</td>';
	                        	checklist_list += '</tr>';
							} else {
						    	checklist_list += '<div id="user_property_attachment_'+response.Property.user_attachments[key].id+'" class="col-12 col-md-6 col-xl-3 property_card">';
					        	//checklist_list += '<div class="card"><div class="card-header"><span class="accordion_arrow">'+kebab+'</span><h5 class="card-title capitalize mb-0 mr-3" title="'+response.Property.user_attachments[key].display_name+'">'+name+'</h5></div><div class="card-img"><img src="img/sample/pdf.png" alt="Pdf" class="mb-2 mt-3"></div><div class="card-body"><div>File size: <strong>'+response.Property.user_attachments[key].file_size+'</strong></div><div>Uploaded By: <strong>'+uploaded_by+'</strong></div><div>Uploaded On: <strong>'+uploaded_on+'</strong></div><div class="text-right mt-4">'+doc_status+'</div></div></div></div>';

					        	checklist_list += '<div class="card">';
									checklist_list += '<div class="card-header"><span class="accordion_arrow">'+kebab+'</span><h5 class="card-title capitalize mb-0 mr-3" title="'+response.Property.user_attachments[key].display_name+'">'+name+'</h5></div>';
									checklist_list += '<div class="card-img"><img src="img/sample/pdf.png" alt="Pdf" class="mb-2 mt-3"></div>';
									checklist_list += '<div class="card-body">';
										checklist_list += '<table class="table mb-0 card-table word-break">';
											checklist_list += '<tbody>';
												checklist_list += '<tr>';
													checklist_list += '<td class="nowrap">File Size</td>';
													checklist_list += '<td class="text-right">'+response.Property.user_attachments[key].file_size+'</td>';
												checklist_list += '</tr>';
												checklist_list += '<tr>';
													checklist_list += '<td class="nowrap">Uploaded By</td>';
													checklist_list += '<td class="text-right">'+uploaded_by+'</td>';
												checklist_list += '</tr>';
												checklist_list += '<tr>';
													checklist_list += '<td class="nowrap">Uploaded On</td>';
													checklist_list += '<td class="text-right">'+uploaded_on+'</td>';
												checklist_list += '</tr>';
											checklist_list += '</tbody>';
										checklist_list += '</table>';
										checklist_list += '<div class="text-right mt-4">'+doc_status+'</div>';
									checklist_list += '</div>';
								checklist_list += '</div>';
								checklist_list += '</div>';
					        }
				        }
                    }
                }
            }

            if(file_cnt == 0) {
            	checklist_list += '<a href="javascript:void(0)" class="list-group-item list-group-item-action border-0 bg-light text-left"><div class="fs-16">No results found</div></a>';
            }

            if(layout == 'table') {
            	$('#document_table_row').html(checklist_list);
	            var document_table = $("#document-datatable").DataTable({
	            	dom: 'Bfrtip',
	    			autoWidth: true,
	            	paging: false,
	            	info: false,
				    scrollY: '500px',
				    scrollX: true,
			        scrollCollapse: true,
			        order: [[0,'desc']],
			        fixedColumns:   {
			            left: 2
			        },
			        buttons: [
			           {
			              extend: 'colvis',
			              text: "Columns Visibility ",
			              collectionLayout: 'two-column',
			              postfixButtons: ['colvisRestore'],
			           }
					],
					language: {
				        buttons: {
				          colvisRestore: "Select All"
				        }
				    },
					initComplete: function () {
			          	//console.log("The Table has been initialized!")
			        }
				});

				$('.buttons-colvis').removeClass('btn-secondary').addClass('btn-primary');
            } else {
            	$('#document_data_container').html(checklist_list);
            }
            
            // Get Floating Action Button
			getFloatingActionButton('Document', '#document_data_container');

            // End : Document section
        } else {
            display_error_message(response);
        }
    }, function() {
        // hang on this step if the error occur
    });
}

window.savePropertyPrimaryContactDetail = function(property_id) {
	var first_name = $('#primary_contact_firstname').val();
	var last_name = $('#primary_contact_lastname').val();
	var business_name = $('#primary_contact_businessname').val();
	var address_line1 = $('#primary_contact_address_line1').val();
	var address_line2 = $('#primary_contact_address_line2').val();
	var city = $('#primary_contact_city').val();
	var state = $('#primary_contact_state').val();
	var zip_code = $('#primary_contact_zip_code').val();
	var country = $('#primary_contact_country').val();

	var data = 'property_id='+property_id+'&first_name='+first_name+'&last_name='+last_name+'&business_name='+business_name+'&address_line1='+address_line1+'&address_line2='+address_line2+'&city='+city+'&state='+state+'&zip_code='+zip_code+'&country='+country+'&usps_verified=1';

	$.ajax({
        method: 'POST',
        url: api_url+"properties/save-primary-contact-address",
        data: data,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	display_error_message(response);
            if(response.response_data.success == true) {
                
            }
        }
    });
}

window.loadPropertyTypes = function(container='') {
	$.ajax({
	    method: 'GET',
	    url: api_url+"property-types",
	    beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
	}).then(function(response) {
	    if(response.response_data.success == true) {
	        var html = "<option value=''>Select Type</option>";
	        for (var key in response.propertyTypes) {
	            if (response.propertyTypes.hasOwnProperty(key)) {
	                var id = response.propertyTypes[key].id;
	                var name = response.propertyTypes[key].name;
	                html += '<option value="'+id+'">'+name+'</option>';
	            }
	        }
	        $('#'+container).html(html);
	    } else {
	        
	    }
	}, function() {
	    // hang on this step if the error occur
	});
}

window.deletePropertyContract = function(user_contract_id) {
	var user_id = user.user_details.user_id;
	$.ajax({
        method: 'POST',
        url: api_url+"user-contracts/delete",
        data:'id='+user_contract_id+'&user_id='+user_id,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
            display_error_message(response);
            if(response.response_data.success == true) {
                getPropertyDocument();
            }
        }
    });
}

/* Start : Account */

window.loadTableAccountDetail = function() {
	var user_id = user.user_details.user_id;
    var tz = jstz.determine();
	var timezone = tz.name();
	var property_id = $('#selected_property_id').val();

	var data = '';
	data = 'user_timezone='+timezone+'&user_id='+user_id+'&property_id='+property_id+'&user_service_name='+user_service_name;
	
	$.ajax({
	    method: 'POST',
	    url: api_url+"scheduled-payments",
	    data:data,
	    beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
	}).then(function(response) {
		if(response.response_data.success == true) {
			var html = '';
			if(response.TotalscheduledPayments == 0) {
	        	html += '<a href="javascript:void(0)" class="list-group-item list-group-item-action border-0 bg-light text-left"><div class="fs-16">'+response.response_data.message+'</div></a>';
		    } else {
		    	for (var key in response.scheduledPayments) {
	                if (response.scheduledPayments.hasOwnProperty(key)) {

	                	var bg_light = '';
	                	if(key % 2 == 1) {
	                		bg_light = 'bg-light';
	                	}

	                	var rent_month = ' ';
	                	if(response.scheduledPayments[key].rent_month != null && response.scheduledPayments[key].payment_type != 1) {
	                		rent_month = response.scheduledPayments[key].rent_month;
	                	}

	                	var payment_due_date = response.scheduledPayments[key].payment_due_date;
	                	if(response.scheduledPayments[key].payment_due_date == null) {
	                		payment_due_date = '';
	                	}

	                	var bank_name = '';
		                var account_number = '';
		                var payment_details = response.scheduledPayments[key].payment_details;
		                if(payment_details != null) {
			                if(Object.keys(payment_details).length !== 0) {
		                		bank_name = response.scheduledPayments[key].payment_details.bank_name;
		                		account_number = response.scheduledPayments[key].payment_details.account_number;
			                }
			            }

	                	var coupon_code = response.scheduledPayments[key].coupon_code.code;
	                	var coupon_amount = '$'+response.scheduledPayments[key].coupon_amount;
	                	var tax_percentage = response.scheduledPayments[key].tax_percentage+'%';
	                	var tax_amount = '$'+response.scheduledPayments[key].tax_amount;
	                	if(response.scheduledPayments[key].coupon_id == 0) {
	                		coupon_code = '-';
	                		coupon_amount = '-';
	                	} if(response.scheduledPayments[key].tax_percentage == 0) {
	                		tax_percentage = '-';
	                		tax_amount = '-';
	                	}

	                	var application_status = '';
	                    if(response.scheduledPayments[key].application_constant == 'pending_authorization') {
	                        application_status += '<span class="badge badge-danger">'+response.scheduledPayments[key].application_status+'</span>';
	                    } else if(response.scheduledPayments[key].application_constant == 'transfer_authorized') {
	                        application_status += '<span class="badge badge-warning">'+response.scheduledPayments[key].application_status+'</span>';
	                    } else if(response.scheduledPayments[key].application_constant == 'transfer_in_progress') {
	                        application_status += '<span class="badge badge-warning">'+response.scheduledPayments[key].application_status+'</span>';
	                    } else if(response.scheduledPayments[key].application_constant == 'processed') {
	                        application_status += '<span class="badge badge-success">'+response.scheduledPayments[key].application_status+'</span>';
	                    } else if(response.scheduledPayments[key].application_constant == 'cancelled_authorized_transfer' || response.scheduledPayments[key].application_constant == 'cancelled_transfer' || response.scheduledPayments[key].application_constant == 'failed_transfer') {
	                        application_status += '<span class="badge badge-danger">'+response.scheduledPayments[key].application_status+'</span>';
	                    } else if(response.scheduledPayments[key].application_constant == 'processing') {
	                        application_status += '<span class="badge badge-primary">'+response.scheduledPayments[key].application_status+'</span>';
	                    }

	                    var kebab = '';
	                    if(user.permissionData['PROPERTIES']["feature_items['cancel_transaction']"]) {
		                    if(response.scheduledPayments[key].application_constant == 'transfer_authorized') {
		                    	kebab = '<div class="text-center"><div class="input-group-prepend"><i class="align-middle mr-2 fas fa-fw fa-ellipsis-v" data-toggle="dropdown" aria-expanded="false"></i><div class="dropdown-menu dropdown-menu-right" style=""><a class="dropdown-item cancel_payment" data-id="'+response.scheduledPayments[key].id+'" data-type="authorized" href="javascript:void(0)">Cancel</a></div></div></div>';
		                    } else if(response.scheduledPayments[key].application_constant == 'transfer_in_progress') {
		                    	kebab = '<div class="text-center"><div class="input-group-prepend"><i class="align-middle mr-2 fas fa-fw fa-ellipsis-v" data-toggle="dropdown" aria-expanded="false"></i><div class="dropdown-menu dropdown-menu-right" style=""><a class="dropdown-item cancel_payment" data-id="'+response.scheduledPayments[key].id+'" data-type="dwolla_transfer" href="javascript:void(0)">Cancel</a></div></div></div>';
		                    }
		                }

		                if(user.permissionData['PROPERTIES']["feature_items['regenerate_transaction']"]) {
		                    if(user_service_name == sa_role && response.scheduledPayments[key].regenerate_transaction == 1) {
	                    		kebab = '<div class="text-center"><div class="input-group-prepend"><i class="align-middle mr-2 fas fa-fw fa-ellipsis-v" data-toggle="dropdown" aria-expanded="false"></i><div class="dropdown-menu dropdown-menu-right" style=""><a class="dropdown-item regenerate_transaction" data-id="'+response.scheduledPayments[key].id+'" data-payment-type="'+response.scheduledPayments[key].payment_type+'" href="javascript:void(0)">Regenerate Transaction</a></div></div></div>';
	                    	}
	                    }

	                    var po_detail = response.propertyOwnerDetail.user.first_name+' '+response.propertyOwnerDetail.user.last_name;
	                    if(user_service_name == t_role) {
	                    	po_detail = 'Rent Synergy';
	                    }

	                	// Card based UI

	                	var payment_amount = response.scheduledPayments[key].final_total;

	                	// Add exclamation button, when click display related transaction detail
	                	var exclamation = '';
	                	if(application_status != '') {
	                		exclamation = '<i data-id='+response.scheduledPayments[key].id+' data-transferID='+response.scheduledPayments[key].dwolla_transferID+' class="align-middle mr-2 fas fa-fw fa-info-circle ml-2 get_related_transaction" data-container="body" data-toggle="popover"></i>';
	                	}

	                	html += '<div class="card tab-card '+bg_light+'">';
				            html += '<div class="accordion">';
				                html += '<div class="border-bottom">';
				                if(response.scheduledPayments[key].payment_type == 1) {
				                    html += '<h5 class="card-title my-2 fs-16"><span class="bold-black">'+response.scheduledPayments[key].payment_type_label+'</span>&nbsp;<span>'+application_status+'</span>'+exclamation+'</h5>';
				                } else {
				                    html += '<h5 class="card-title my-2 fs-16"><span class="bold-black">'+response.scheduledPayments[key].payment_type_label+'</span><small> for '+rent_month+' </small>&nbsp;<span>'+application_status+'</span>'+exclamation+'</h5>';
				                }
				                    html += '<span class="accordion_arrow">';
				                        html += kebab;
				                    html += '</span>';
				                html += '</div>';
				                html += '<div class="tab_inner_data" data-id="'+response.scheduledPayments[key].id+'">';
				                    html += '<div class="row">';
				                    	html += '<div class="col-12 col-md-6 col-xl-2 d-flex">';
				                            html += '<div class="card flex-fill '+bg_light+'">';
				                                html += '<div class="mt-2">';
				                                    html += '<div class="black"><strong>Property Owner:</strong></div>';
				                                    html += '<div class="grey"><strong>'+po_detail+'</strong></div>';
				                                    var tenant_detail = '';
							                        if(response.scheduledPayments[key].tenant_detail) {
							                        	html += '<div class="black"><strong>Tenant:</strong></div>';
								                        for (var tkey in response.scheduledPayments[key].tenant_detail) {
					                						if (response.scheduledPayments[key].tenant_detail.hasOwnProperty(tkey)) {
					                							tenant_detail += response.scheduledPayments[key].tenant_detail[tkey].first_name+' '+response.scheduledPayments[key].tenant_detail[tkey].last_name+',';
					                						}
					                					}
					                					tenant_detail = tenant_detail.replace(/,\s*$/, "");
					                					html += '<div class="grey"><strong>'+tenant_detail+'</strong></div>';
					                				}
				                                html += '</div>';
				                            html += '</div>';
				                        html += '</div>';
				                    	html += '<div class="col-12 col-md-6 col-xl-2 d-flex">';
				                            html += '<div class="card flex-fill '+bg_light+'">';
				                                html += '<div class="mt-2">';
				                                	html += '<div class="black"><strong>Due Date:</strong></div>';
				                                    html += '<div><small>'+payment_due_date+'</small></div>';
				                                html += '</div>';
				                            html += '</div>';
				                        html += '</div>';
				                        html += '<div class="col-12 col-md-6 col-xl-3 d-flex">';
				                            html += '<div class="card flex-fill '+bg_light+'">';
				                                html += '<div class="mt-2">';
				                                    html += '<div class="black"><strong>Payment Method:</strong></div>';
				                                    html += '<div class="grey"><strong>'+bank_name+'</strong></div>';
				                                    html += '<div class="grey"><strong>'+account_number+'</strong></div>';
				                                html += '</div>';
				                            html += '</div>';
				                        html += '</div>';
				                        html  += '<div class="col-12 col-md-6 col-xl-2 d-flex">';
				                            html  += '<div class="card flex-fill '+bg_light+'">';
				                                html  += '<div class="mt-2">';
				                                    html  += '<div class="black"><strong>Coupon:</strong></div>';
				                                    html  += '<div class="grey">Code: <strong>'+coupon_code+'</strong></div>';
				                                    html  += '<div class="grey">Amount: <strong>'+coupon_amount+'</strong></div>';
				                                html += '</div>';
				                            html += '</div>';
				                        html += '</div>';
				                        /*html  += '<div class="col-12 col-md-6 col-xl-2 d-flex">';
				                            html  += '<div class="card flex-fill '+bg_light+'">';
				                                html  += '<div class="mt-2">';
				                                    html  += '<div class="black"><strong>Tax:</strong></div>';
				                                    html  += '<div class="grey">Percentage: <strong>'+tax_percentage+'</strong></div>';
				                                    html  += '<div class="grey">Amount: <strong>'+tax_amount+'</strong></div>';
				                                html += '</div>';
				                            html += '</div>';
				                        html += '</div>';*/
				                        html += '<div class="col-12 col-md-6 col-xl-3 d-flex">';
				                            html += '<div class="card flex-fill '+bg_light+'">';
				                                html += '<div class="mt-2">';
				                                    html += '<div class="black"><strong>Payment(USD):</strong></div>';
				                                    html += '<div class="black fs-18"> $'+payment_amount+'</div>';
				                                    if(response.scheduledPayments[key].is_late_fee_enabled == 1 && response.scheduledPayments[key].waive_late_fee > 0) {
				                                    	html += '<div><small>(A) Rent Due by '+response.scheduledPayments[key].payment_due_date+' : $'+response.scheduledPayments[key].total+'</small></div>';
				                                    	html += '<div ml-4><small>(1) No. of days late after '+response.scheduledPayments[key].lateFeeafterDays+' day of the month: '+response.scheduledPayments[key].no_of_days+'</small></div>';
				                                    	html += '<div ml-4><small>(2) Late fee applied per day: $'+response.scheduledPayments[key].price_per_day+'</small></div>';
				                                    	html += '<div><small class="red">(B) Outstanding late fee (1) x (2): $'+response.scheduledPayments[key].late_fee_amount+'</small></div>';
				                                    	html += '<div><small class="green">(C) Late fee waived: $'+response.scheduledPayments[key].waived_amount+'</small></div>';
				                                    	html += '<div><small>(D) Total (A) + (B) - (C): $'+payment_amount+'</small></div>';
				                                    }
				                                html += '</div>';
				                            html += '</div>';
				                        html += '</div>';
				                    html += '</div>';
				                html += '</div>';
				            html += '</div>';
				        html += '</div>';
	                }
	            }
	        }

            $('#accountingDataContainer').html(html);

            // Get Related Transaction Detail
            var title = 'Transaction Detail';
            var type = 'RelatedTransaction';
            openPopoverModal(title,type);
        } else {
            display_error_message(response);
        }
    }, function() {
        // hang on this step if the error occur
    });
}

window.cancelPayment = function(id,cancel_type) {
	var user_id = user.user_details.user_id;
	$.ajax({
        method: 'POST',
        url: api_url+"scheduled-payments/cancel-payment",
        data:'scheduled_payment_id='+id+'&type='+cancel_type+'&user_id='+user_id+'&user_service_name='+user_service_name,
        async: true,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	display_error_message(response);
            if(response.response_data.success == true) {
                loadTableAccountDetail();
                getPropertyAccountingWidgetInfo();
            }
        }
    });
}

window.regenerateTransaction = function(id,payment_type) {
	var user_id = user.user_details.user_id;
	$.ajax({
        method: 'POST',
        url: api_url+"scheduled-payments/regenerate-transaction",
        data:'id='+id+'&user_id='+user_id+'&user_service_name='+user_service_name,
        async: true,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	display_error_message(response);
            if(response.response_data.success == true) {
                loadTableAccountDetail();
                getPropertyAccountingWidgetInfo();
            }
        }
    });
}

window.getSubscriptionDetail = function(id, property_id, payment_type, payment_details_id, rent_month, open_from) {
	var user_id = user.user_details.user_id;
	var tz = jstz.determine();
	var timezone = tz.name();
	$.ajax({
        method: 'POST',
        url: api_url+"scheduled-payments/get-subscription-fee-detail",
        data: 'user_id='+user_id+'&property_id='+property_id+'&scheduled_payment_id='+id+'&user_timezone='+timezone,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	if(response.response_data.success == true) {

        		openLoadingDialog($('#authorize_subscription_fee_container'));
        		setTimeout(function(){
					var bank_html = "<option value=''>Select Payment Method</option>";
					if(response.paymentDetails != null) {
		                for (var key in response.paymentDetails) {
					        if (response.paymentDetails.hasOwnProperty(key)) {
					        	if(payment_details_id == response.paymentDetails[key].id) {
			            			var b_selected = 'selected';
			            		} else {
			            			var b_selected = '';
			            		}
					        	bank_html += '<option value="'+response.paymentDetails[key].id+'" '+b_selected+'>'+response.paymentDetails[key].bank_name+'</option>';
							}
						}
					}

		        	var html = '';
		            html += '<input type="hidden" name="subscription_property_id" id="subscription_property_id" value="'+property_id+'">';
		            html += '<input type="hidden" name="subscription_scheculed_payment_id" id="subscription_scheculed_payment_id" value="'+id+'">';
		            html += '<input type="hidden" name="subscription_fee_open_from" id="subscription_fee_open_from" value="'+open_from+'">';
		            	html += '<form id="subscription-fee-collection-wizard" class="wizard-primary create-account-form" action="javascript:void(0)">';
					    html += '<div id="payNowCollapse">';			    	
				    		var add_opacity = 'add_opacity';			    		
					    	if(response.scheduledPayment != null) {
			            		html += '<div class="rent-detail-card p-3 mb-1 bg-white">';
						            html += '<div class="border-bottom">';
						                html += '<h5 class="card-title"><span class="fs-22">Subscription fees</h5> <span class="bold-black">('+response.scheduledPayment.property.name+')</span>';
						            html += '</div>';
						            html += '<div class="rent_collection_data mt-2">';
						                html += '<div class="row">';
						                	html += '<div class="col-12 col-md-4 col-xl-4">';
						                        html += '<div class="mt-2">';
						                            html += '<div class="black mb-2"><strong>Month</strong></div>';
						                            html += '<div class="black fs-18"><strong>'+response.scheduledPayment.rent_month+'</strong></div>';
						                        html += '</div>';
						                    html += '</div>';
						                	html += '<div class="col-12 col-md-4 col-xl-4 '+add_opacity+'">';
						                        html += '<div class="mt-2">';
						                            html += '<div class="black mb-2"><strong>Select Payment Method</strong></div>';
						                            html += '<div><select name="subscription_payment_detail_id" id="subscription_payment_detail_id" class="bank_dd form-control">'+bank_html+'</select></div>';
						                        html += '</div>';
						                    html += '</div>';
						                    html += '<div class="col-12 col-md-4 col-xl-4 text-right">';
						                        html += '<div class="mt-2">';
						                            html += '<div class="black mb-2"><strong>Amount</strong></div>';
						                            html += '<div class="black fs-18"><strong>$ '+response.scheduledPayment.formatted_totalSum+'</strong></div>';
						                        html += '</div>';
						                    html += '</div>';
						                html += '</div>';
						            html += '</div>';
						        html += '</div>';
						        html += '<div class="rent-detail-card pl-3 pr-3 pt-3 bg-white">';
						            html += '<div class="rent_collection_data">';
						                html += '<div class="row">';
						                    html += '<div class="col-12 col-md-6 col-xl-9">';
						                        html += '<h5 class="card-title"><span class="fs-16"><strong>Total</strong></h5>';
						                    html += '</div>';
						                    html += '<div class="col-12 col-md-6 col-xl-3 text-right">';
						                    	html += '<div class="black fs-18"><strong>$ '+response.scheduledPayment.formatted_totalSum+'</strong></div>';
						                    html += '</div>';
						                html += '</div>';
						            html += '</div>';
						        html += '</div>';				
						    }

						    html += '<div class="col-auto d-sm-block mb-2 mt-4">';
					            html += '<div class="autopay_checkbox">';
					                html += '<label class="custom-control custom-checkbox m-0">';
					                html += '<input name="subscription_fee_authorize_checkbox" id="subscription_fee_authorize_checkbox" type="checkbox" class="custom-control-input">';
					                html += '<span class="custom-control-label">I agree and authorize future payments to Rent Synergy will be processed by the Dwolla payment system from the selected account above. In order to cancel this authorization, I will change the payment settings within my account.</span>';
					                html += '</label>';
					            html += '</div>';
					        html += '</div>';
					    html += '</div>';
					html += '</form>';

					$('#authorize_subscription_fee_container').html(html);
				}, 1000);
    			
            } else {
            	display_error_message(response);
            }
        }
    });
}

window.authorizeSubscriptionFee = function(scheduled_payment_id, property_id, payment_detail_id, open_from) {
	var user_id = user.user_details.user_id;
	var tz = jstz.determine();
	var timezone = tz.name();
	var data = 'user_id='+user_id+'&user_timezone='+timezone+'&scheduled_payment_id='+scheduled_payment_id+'&property_id='+property_id+'&payment_detail_id='+payment_detail_id;
	$.ajax({
        method: 'POST',
        url: api_url+"scheduled-payments/authorize-subscription-fee",
        data:data,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	display_error_message(response);
            if(response.response_data.success == true) {
            	$('#authorizeSubscriptionFeeModal').modal('hide');
            	if(open_from == 'account') {
            		getPropertyAccountingWidgetInfo();
            	} else if(open_from == 'notification') {
            		// Enable next card
	            	$('#subscription_fee_card_'+scheduled_payment_id).next('div.subscription_fee_section').find('.authorize_subscription_fee').removeClass('add_opacity');
	            	// Remove current card
	            	$('#subscription_fee_card_'+scheduled_payment_id).remove();
	            	// When authorized payment from detail page below function call and reload monthly card
	            	getPropertyAccountingWidgetInfo();
	            	var notification = parseInt($('#notification_count').text());
	            	var total_notification = notification - 1;
	            	$('#notification_indicator, #notification_count').text(total_notification);
            	}
            }
        }
    });
}

window.getLeaseReport = function() {
	var user = JSON.parse(localStorage.getItem('user'));
    var tz = jstz.determine();
	var timezone = tz.name();
	var user_id = user.user_details.user_id;

	var data = '';
	data = 'user_timezone='+timezone+'&user_service_name='+user_service_name+'&user_id='+user_id;
	
	$.ajax({
	    method: 'POST',
	    url: api_url+"leases/get-lease-report",
	    data:data,
	    beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
	}).then(function(response) {
		if(response.response_data.success == true) {
			var html = '';
			if(response.TotalLeases == 0) {
	        	html += '<a href="javascript:void(0)" class="list-group-item list-group-item-action border-0 bg-light text-left"><div class="fs-16">'+response.response_data.message+'</div></a>';
		    } else {
	            for (var key in response.leases) {
	                if (response.leases.hasOwnProperty(key)) {

	                	var property_name = '';
		                if(response.leases[key].property != null) {
		                	property_name = response.leases[key].property.name;
		                }

	                	var address = '';
		                if(response.leases[key].property != null && response.leases[key].property.address != null) {
		                	address = response.leases[key].property.address.address_line1+', '+response.leases[key].property.address.address_line2+'<br>'+response.leases[key].property.address.city+', '+response.leases[key].property.address.state+' '+response.leases[key].property.address.zip_code;
		                }

	                	var rent_due_date = ordinal_suffix_of(response.leases[key].rent_due_date);
	                	var sd_due_date = '';
	                	var pd_due_date = '';
	                	if(response.leases[key].sd_due_date != null) {
	                		sd_due_date = 'Due on '+response.leases[key].sd_due_date;
	                	}
	                	if(response.leases[key].pd_due_date != null) {
	                		pd_due_date = 'Due on '+response.leases[key].pd_due_date;
	                	}

	                	var status = '';
	                    if(response.leases[key].status == 0 || response.leases[key].status == 4) {
	                        status += '<span class="badge badge-danger">'+response.leases[key].status_label+'</span>';
	                    } if(response.leases[key].status == 1 || response.leases[key].status == 5) {
	                        status += '<span class="badge badge-success">'+response.leases[key].status_label+'</span>';
	                    }

	                    html += '<tr data-id="'+response.leases[key].id+'">';
	                		html += '<td class="bg-light">'+response.leases[key].id+'</td>';
	                		html += '<td class="bg-light"><span class="bold-black">'+property_name+'</span><br><small>'+address+'</small></td>';
	                		html += '<td>'+response.leases[key].start_date+'</td>';
	                		html += '<td>'+response.leases[key].end_date+'</td>';
	                		html += '<td class="text-right bold-black">$'+response.leases[key].monthly_rent_amount+'<br><small>Due on '+rent_due_date+' of the month</small></td>';
	                		html += '<td class="text-right bold-black">$'+response.leases[key].security_deposit_amount+'<br><small>'+sd_due_date+'</small></td>';
	                		html += '<td class="text-right bold-black">$'+response.leases[key].pet_deposit_amount+'<br><small>'+pd_due_date+'</small></td>';
	                		html += '<td>'+response.leases[key].tenant+'</td>';
	                		html += '<td>'+status+'</td>';
                        html += '</tr>';	                	
	                }
	            }
	        }

            $('#lease_report_data_container').html(html);
            var lease_table = $("#lease-report-datatable").DataTable({
            	dom: 'Bfrtip',
    			autoWidth: true,
            	paging: false,
            	info: false,
			    scrollY: '600px',
			    scrollX: true,
		        scrollCollapse: true,
		        order: [[0,'desc']],
		        fixedColumns:   {
		            left: 2
		        },
		        buttons: [
		           {
		              extend: 'colvis',
		              text: "Columns Visibility ",
		              collectionLayout: 'two-column',
		              postfixButtons: ['colvisRestore'],
		           }
				],
				language: {
			        buttons: {
			          colvisRestore: "Select All"
			        }
			    },
				initComplete: function () {
		          	//console.log("The Table has been initialized!")
		        }
			});

			$('.buttons-colvis').removeClass('btn-secondary').addClass('btn-primary');
        } else {
            display_error_message(response);
        }
    }, function() {
        // hang on this step if the error occur
    });
}

// Epxneses code start
window.getExpensesPolicies = function(page = null) {
    $.ajax({
        method: 'GET',
        url: api_url+"expenses/get-policies",
        beforeSend: function(xhr) {
            xhr.setRequestHeader('Authorization', accessToken);
        },
        success: function(response) {
            var html = '';
            html += "<option value=''>Select Policy</option>";
            for (var key in response.data) {
                    var id = response.data[key].policy_id;
                    var name = response.data[key].policy_name;
					var is_default = response.data[key].is_default;
					if(page == 'add_expense' && is_default == true){
						html += '<option value="'+id+'" selected>'+name+'</option>';
					} else {
						html += '<option value="'+id+'">'+name+'</option>';
					}
            }
            $('#inputPolicy').html(html);
        }
    })
}

window.loadVendorData = function(){
	if ($.fn.DataTable.isDataTable("#vendor-list-datatable")) {
        $("#vendor-list-datatable").DataTable().destroy();
    }
	$.ajax({
        method: 'GET',
        url: api_url+"vendors",
        beforeSend: function(xhr) {
            xhr.setRequestHeader('Authorization', accessToken);
        },
        success: function(response) {
			var html = '';
        	if(response.response_data.success == true) {
	            for (var key in response.vendors) {
						var kebab = '<a class="dropdown-item edit_vendor" href="javascript:void(0)" data-id="'+response.vendors[key].id+'">Edit</a>';
						kebab += '<a class="dropdown-item delete_vendor" href="javascript:void(0)" data-id="'+response.vendors[key].id+'">Delete</a>';
						html += '<tr>';
							html += '<td>'+response.vendors[key].id+'</td>';
							if(response.vendors[key].name != null){
								html += '<td>'+response.vendors[key].name+'</td>';
							} else {
								html += '<td>-</td>';
							}

							if(response.vendors[key].email != null){
								html += '<td>'+response.vendors[key].email+'</td>';
							} else {
								html += '<td>-</td>';
							}
							
							if(response.vendors[key].phone != null){
								html += '<td>'+response.vendors[key].phone+'</td>';
							} else {
								html += '<td>-</td>';
							}
							
							html += '<td>'+response.vendors[key].status_label+'</td>';
							html += '<td><div class="text-center"><div class="input-group-prepend"><i class="align-middle mr-2 fas fa-fw fa-ellipsis-v" data-toggle="dropdown" aria-expanded="false"></i><div class="dropdown-menu dropdown-menu-right" style="">'+kebab+'</div></div></div></td>';
						html+= '</tr>';
	            }
	        } else {
				html += '<p> No Data Available </p>';
	        	// display_error_message(response);
	        }
			$('#vendor_data_container').html(html);
			// var buttonCommon = {
			// 	exportOptions: {
			// 		format: {
			// 			body: function ( data, row, column, node ) {
			// 				// Replace <br> with space
			// 				var regex = /<br\s*[\/]?>/gi;
			// 				data = data.replace(regex, " ");
			// 				return data.replace(regex, "");
			// 			}
			// 		}
			// 	}
			// };
						
			$("#vendor-list-datatable").DataTable({
				responsive: true
				// dom: 'frtip',
				// autoWidth: true,
				// // paging: false,
				// // info: false,
				// scrollY: '475px',
				// scrollX: true,
				// scrollCollapse: true,
				// buttons: [
				// 	$.extend( true, {}, buttonCommon, {
				// 		extend: 'excelHtml5',
				// 		text: 'Download Excel',
				// 		title: 'Vendor List',
				// 		message:'Disclaimer: Rent synergy has made every attempt to ensure the correctness of this report, based on the data entered and available with us. Before the final consumption of this report, we recommend you to verify and validate this data with your accounting team.',
				// 		autoFilter: true,
				// 		header: true,
				// 		footer: true,
				// 		exportOptions: {
				// 			//columns: ':visible'
				// 			columns: ':visible:not(.notexport)'
				// 		},
				// 		customize: function(xlsx) {
				// 			var sheet = xlsx.xl.worksheets['sheet1.xml'];
				// 			datatable_excel_common_formatting(xlsx,sheet);
				// 		}
				// 	}),
				// 	// $.extend( true, {}, buttonCommon, {
				// 	// 	extend: 'colvis',
				// 	// 		text: "Columns Visibility ",
				// 	// 		collectionLayout: 'two-column',
				// 	// 		postfixButtons: ['colvisRestore'],
				// 	// }),
				// ],
				// language: {
				// 	buttons: {
				// 		colvisRestore: "Select All"
				// 	}
				// },
			});
			
			// $('.buttons-html5').removeClass('btn-secondary').addClass('btn-primary');
			// // $('.buttons-colvis').removeClass('btn-secondary').addClass('btn-primary');
			// $('.dt-buttons > button').addClass('mr-2');
			// $('.btn-group > .btn:not(:last-child):not(.dropdown-toggle), .btn-group > .btn-group:not(:first-child) > .btn').css({
			// 	'border-radius': '5px'
			// });			
		}
	});
}

window.getVendorDetails = function(vendor_id){
	$.ajax({
        method: 'GET',
        url: api_url+"vendors/detail/"+vendor_id,
        beforeSend: function(xhr) {
            xhr.setRequestHeader('Authorization', accessToken);
        },
        success: function(response) {
			if(response.response_data.success == true) {
				$('#address_id').val(response.vendor.vendor_address.address_id);
				$('#vendor_id').val(vendor_id);
				$('#vendorName').val(response.vendor.name);
				$('#vendorEmail').val(response.vendor.email);
				$('#vendorPhone').val(response.vendor.phone);
				$('#vendorAddress1').val(response.vendor.vendor_address.address.address_line1);
				if(response.vendor.vendor_address.address.address_line2 != null){
					$('#vendorAddress2').val(response.vendor.vendor_address.address.address_line2);
				}

				$('#vendorZip').val(response.vendor.vendor_address.address.zip_code);
				$('#vendorCity').val(response.vendor.vendor_address.address.city);
				$('#vendorCountry').val(response.vendor.vendor_address.address.country);

				var selected_state = response.vendor.vendor_address.address.state;
				if(selected_state != null){
					getStateDropdownForSignup(selected_state);
				} else {
					getStateDropdownForSignup();
				}
			
			} else {
				display_error_message(response);
			}
		}
	})
}

window.updateVendor = function(){
	var user_id = user.user_details.user_id;    
	$.ajax({
		method : 'POST',
		url : api_url+"vendors/update",
		data : $('#edit_vendor_form').serialize()+'&user_id='+user_id,
		beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
			display_error_message(response);
			if(response.response_data.success == true) {
				$('#masterDataModal').modal('hide');
	        	$('.modal-backdrop').remove();
				loadVendorData();
			}
        }
	})
}

window.submitVendor = function(){
	var user_id = user.user_details.user_id;
	$.ajax({
		method : 'POST',
		url : api_url+"vendors/create",
		data : $('#new_vendor_form').serialize()+'&user_id='+user_id,
		beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
			display_error_message(response);
			if(response.response_data.success == true) {
				$('#masterDataModal').modal('hide');
	        	$('.modal-backdrop').remove();
				loadVendorData();
			}
        }
	})
}

window.deleteVendor = function(vendor_id){
	$.ajax({
		method : 'POST',
		url : api_url+"vendors/delete",
		data : 'vendor_id='+vendor_id,
		beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
			display_error_message(response);
			if(response.response_data.success == true) {
				loadVendorData();
			}
        }
	})
}

window.submitCategory = function(){
	var user_id = user.user_details.user_id;
	
	$.ajax({
		method : 'POST',
		url : api_url+"categories/create",
		data : $('#new_category_form').serialize()+'&user_id='+user_id,
		beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
			display_error_message(response);
			if(response.response_data.success == true) {
				$('#masterDataModal').modal('hide');
	        	$('.modal-backdrop').remove();
				loadCategoryData();

			} 
        }
	})
}

window.submitSubCategory = function(){
	var user_id = user.user_details.user_id;
	$.ajax({
		method : 'POST',
		url : api_url+"categories/create-sub-category",
		data : $('#new_sub_category_form').serialize()+'&user_id='+user_id,
		beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
			display_error_message(response);
			if(response.response_data.success == true) {
				$('#masterDataModal').modal('hide');
	        	$('.modal-backdrop').remove();
				
				var user_id = user.user_details.user_id;
				var category_id = $('input[name="category-radio"]:checked').attr('data-id');		
				loadSubCategoryData(category_id, user_id);
			}
        }
	})
}

window.editCategory = function(){
	var user_id = user.user_details.user_id;
	
	$.ajax({
		method : 'POST',
		url : api_url+"categories/update",
		data : $('#edit_category_form').serialize()+'&user_id='+user_id,
		beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {	
			display_error_message(response);		
			if(response.response_data.success == true) {
				$('#masterDataModal').modal('hide');
	        	$('.modal-backdrop').remove();
				loadCategoryData();
			} 
        }
	})
}

window.editSubCategory = function(){
	var user_id = user.user_details.user_id;
	
	$.ajax({
		method : 'POST',
		url : api_url+"categories/update-sub-category",
		data : $('#edit_sub_category_form').serialize()+'&user_id='+user_id,
		beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {	
			display_error_message(response);		
			if(response.response_data.success == true) {
				$('#masterDataModal').modal('hide');
	        	$('.modal-backdrop').remove();
				var user_id = user.user_details.user_id;
				var category_id = $('input[name="category-radio"]:checked').attr('data-id');		
				loadSubCategoryData(category_id, user_id);
			}
        }
	})
}

window.deleteCategory = function(category_id){
	var user_id = user.user_details.user_id;
	
	$.ajax({
		method : 'POST',
		url : api_url+"categories/delete",
		data : '&user_id='+user_id+'&categoryId='+category_id,
		beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
			display_error_message(response);			
			if(response.response_data.success == true) {
				$('#masterDataModal').modal('hide');
	        	$('.modal-backdrop').remove();
				loadCategoryData();
			} 
        }
	})
}

window.deleteSubCategory = function(sub_category_id){
	var user_id = user.user_details.user_id;
	
	$.ajax({
		method : 'POST',
		url : api_url+"categories/delete-sub-category",
		data : '&user_id='+user_id+'&subCategoryId='+sub_category_id,
		beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
			display_error_message(response);			
			if(response.response_data.success == true) {
				$('#masterDataModal').modal('hide');
	        	$('.modal-backdrop').remove();
				var user_id = user.user_details.user_id;
				var category_id = $('input[name="category-radio"]:checked').attr('data-id');		
				loadSubCategoryData(category_id, user_id);
			}
        }
	})
}

window.getCategoryById = function(category_id){
	var user_id = user.user_details.user_id;
	$.ajax({
		method : 'POST',
		url : api_url+"categories/get-category-by-id",
		data : 'categoryId='+category_id+'&user_id='+user_id,
		beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
			if(response.response_data.success == true){
				$('#categoryId').val(category_id);
				$('#categoryName').val(response.data.category_name);
				$('#categoryDescription').val(response.data.description);
				//$('#isDefault').val(response.data.is_default);
				$('#isDefault option[value="'+response.data.is_default+'"]').attr("selected",true);
			}			
        }
	})
}

window.getSubCategoryById = function(sub_category_id){
	var user_id = user.user_details.user_id;
	$.ajax({
		method : 'POST',
		url : api_url+"categories/get-sub-category-by-id",
		data : 'subCategoryId='+sub_category_id+'&user_id='+user_id,
		beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
			if(response.response_data.success == true){
				$('#subCategoryId').val(sub_category_id);
				$('#subCategoryName').val(response.data.sub_category_name);
				$('#subCategoryDescription').val(response.data.description);				
			} 
        }
	})
}

window.loadCategoryData = function(){
	var user_id = user.user_details.user_id;
	if ($.fn.DataTable.isDataTable("#category-data-datatable")) {
        $("#category-data-datatable").DataTable().destroy();
    }
	$.ajax({
        method: 'GET',
        url: api_url+"expenses/get-categories",
        beforeSend: function(xhr) {
            xhr.setRequestHeader('Authorization', accessToken);
        },
        success: function(response) {
			var html = '';			
			for(var key in response.data){
				var radio = '<label class="custom-control custom-radio"><input name="category-radio" type="radio" class="custom-control-input get_sub_cat_data" data-id="'+response.data[key].category_id+'"><span class="custom-control-label">&nbsp;</span></label>';
				var kebab = '<a class="dropdown-item view_category" href="javascript:void(0)" data-id="'+response.data[key].category_id+'">Edit</a><a class="dropdown-item delete_category" href="javascript:void(0)" data-id="'+response.data[key].category_id+'">Delete</a>';				

				html += '<tr data-id="'+response.data[key].category_id+'">';
					html += '<td>'+radio+'</td>';
					html += '<td>'+response.data[key].category_name+'</td>';
					html += '<td>'+response.data[key].description+'</td>';
					if(response.data[key].is_default == true){
						html += '<td><span class="badge badge-success fs-12">YES</span></td>';				
					} else{
						html += '<td><span class="badge badge-primary fs-12">NO</span></td>';				
					}
					html += '<td><div class="text-center"><div class="input-group-prepend"><i class="align-middle mr-2 fas fa-fw fa-ellipsis-v" data-toggle="dropdown" aria-expanded="false"></i><div class="dropdown-menu dropdown-menu-right" style="">'+kebab+'</div></div></div></td>';							
				html += '</tr>';			
			}	
			$('#category_row_data_container').html(html);
			var transaction_table = $("#category-data-datatable").DataTable({
				responsive: true
			});
        }
	})
}

window.loadSubCategoryData = function(category_id, user_id){
	if ($.fn.DataTable.isDataTable("#sub-category-data-datatable")) {
        $("#sub-category-data-datatable").DataTable().destroy();
    }
	$.ajax({
        method: 'POST',
        url: api_url+"expenses/get-sub-categories",
		data: "category_id="+category_id+"&user_id="+user_id,
        beforeSend: function(xhr) {
            xhr.setRequestHeader('Authorization', accessToken);
        },
        success: function(response) {
			$('#sub_category_data_container').removeClass('d-none');
			$('.add_new_sub_category').attr('data-id',category_id);
			var html = '';
			for(var key in response.data){
				if(response.data[key].status != 2){			
					var kebab = '<a class="dropdown-item view_sub_category" href="javascript:void(0)" data-id="'+response.data[key].sub_category_id+'">Edit</a><a class="dropdown-item delete_sub_category" href="javascript:void(0)" data-id="'+response.data[key].sub_category_id+'">Delete</a>';				
					html += '<tr data-id="'+response.data[key].sub_category_id+'">';
					// html += '<td>'+radio+'</td>';
						html += '<td>'+response.data[key].sub_category_name+'</td>';
						html += '<td>'+response.data[key].description+'</td>';						
						html += '<td><div class="text-center"><div class="input-group-prepend"><i class="align-middle mr-2 fas fa-fw fa-ellipsis-v" data-toggle="dropdown" aria-expanded="false"></i><div class="dropdown-menu dropdown-menu-right" style="">'+kebab+'</div></div></div></td>';							
					html += '</tr>';
				}				
			}
			$('#sub-category_row_data_container').html(html);
			var transaction_table = $("#sub-category-data-datatable").DataTable({
				responsive: true
			});
		}
	})
	
}

// Epxneses code start
window.getExpenseVendor = function(page = null, vendor_id = '') {
    $.ajax({
        method: 'GET',
        url: api_url+"vendors",
        beforeSend: function(xhr) {
            xhr.setRequestHeader('Authorization', accessToken);
        },
        success: function(response) {
        	if(response.response_data.success == true) {
	            var html = '';
	            html += "<option value=''>Select Pay to</option>";
	            for (var key in response.vendors) {
	                    var id = response.vendors[key].id;
	                    var name = response.vendors[key].name;
						if(page == 'edit_expense' && id == vendor_id) {
							html += '<option value="'+vendor_id+'" selected="true">'+name+'</option>';
						} else {
							html += '<option value="'+id+'">'+name+'</option>';
						}
	            }
	            $('#inputVendor').html(html);
	        } else {
	        	display_error_message(response);
	        }
        }
    });
}

window.getExpenseCategories = function(page = null, line_item_category_id = '') {
    $.ajax({
        method: 'GET',
        url: api_url+"expenses/get-categories",
        beforeSend: function(xhr) {
            xhr.setRequestHeader('Authorization', accessToken);
        },
        success: function(response) {
            var html = '';
            html += "<option value=''>Select Category</option>";
            for (var key in response.data) {
                    var id = response.data[key].category_id;
                    var name = response.data[key].category_name;
					var is_default = response.data[key].is_default;
					if(page == 'add_expense' && is_default == true){
						html += '<option value="'+id+'" selected>'+name+'</option>';
					} else if(page == 'edit_expense' && id == line_item_category_id) {
						html += '<option value="'+line_item_category_id+'" selected="true">'+name+'</option>';
					} else {
						html += '<option value="'+id+'">'+name+'</option>';
					}
            }
            $('#inputCategory').html(html);
        }
    });
}

window.getExpenseSubCategories = function(page = null, category_id, line_item_sub_category_id = '') {
	var user_id = user.user_details.user_id;
	$.ajax({
        method: 'POST',
        url: api_url+"expenses/get-sub-categories",
		data: "category_id="+category_id+"&user_id="+user_id,
        beforeSend: function(xhr) {
            xhr.setRequestHeader('Authorization', accessToken);
        },
        success: function(response) {
            var html = '';
            html += "<option value=''>Select Sub Category</option>";
            for (var key in response.data) {
				if(response.data[key].status == 1){
					var id = response.data[key].sub_category_id;
                    var name = response.data[key].sub_category_name;
					if(page == 'edit_expense' && id == line_item_sub_category_id) {
						html += '<option value="'+line_item_sub_category_id+'" selected="true">'+name+'</option>';
					} else {
						html += '<option value="'+id+'">'+name+'</option>';
					}
				}                    
            }
            $('#inputSubCategory').html(html);
			if(!response.data || Object.keys(response.data).length == 0 ){
				$('#inputSubCategory').attr('disabled',true);
				$('#inputSubCategory').val('');
			} else {
				$('#inputSubCategory').attr('disabled',false);
			}
        }
    });
}

window.getExpenseTaxes = function(page = null, line_item_tax_id = '') {
    $.ajax({
        method: 'GET',
        url: api_url+"expenses/get-taxes",
        beforeSend: function(xhr) {
            xhr.setRequestHeader('Authorization', accessToken);
        },
        success: function(response) {
            var html = '';
            html += "<option value='' data-percentage='0'>Select Tax</option>";
			
            for (var key in response.data) {
                    var id = response.data[key].tax_id;
                    var name = response.data[key].tax_name;
					var tax_percentage = response.data[key].tax_percentage;
					var is_default = response.data[key].is_default;
					if(page == 'add_expense' && is_default == true) {
						html += '<option value="'+id+'" data-percentage="'+tax_percentage+'"  selected>'+name+'</option>';
						$('#taxPercentage').val(tax_percentage);
					} else if(page == 'edit_expense' && id == line_item_tax_id) {
						html += '<option value="'+line_item_tax_id+'" data-percentage="'+tax_percentage+'" selected="true">'+name+'</option>';
					} else {
						html += '<option value="'+id+'" data-percentage="'+tax_percentage+'">'+name+'</option>';
					}
            }
            $('#inputTaxes').html(html);
        }
    });
}


window.getLineItems = function(){

    var slno = $('#line_item_table tbody tr').length + 1;
    var id = 'tablerow'+slno;
    var html = '';
    // html += '<tr id="'+id+'">';
    //     html += '<td align="center"><lable class="form-control" id="inputsl">'+slno+'</lable></td>';
    //     html += '<td><input type="text" name="inputMerchant" class="form-control no_special_char_allow" id="inputMerchant'+slno+'" placeholder="Enter Merchant Name"></td>';
    //     html += '<td><input type="text" name="inputInvoice" class="form-control no_special_char_allow" id="inputInvoice'+slno+'" placeholder="Enter Invoice Number"></td>';
    //     html += '<td><input type="date" name="inputDate" class="form-control no_special_char_allow" id="inputDate'+slno+'"></td>';
    //     html += '<td><input type="text" name="inputAmount" class="form-control no_special_char_allow inputAmount text-right allow_numeric_with_decimal" maxlength="10" id="inputAmount'+slno+'" placeholder="Enter Amount"></td>';
    //     html += '<td><select name="inputCategory" class="form-control no_special_char_allow" id="inputCategory'+slno+'"></select></td>';
    //     html += '<td><select name="inputTaxes" class="form-control no_special_char_allow" id="inputTaxes'+slno+'"></select></td>';
    //     html += '<td><input type="text" name="inputComment" class="form-control no_special_char_allow" id="inputComment'+slno+'" placeholder="Comments/Notes"></td>';
    //     html += '<td><div class="image-upload"><label for="inputFile'+slno+'" class="inputFile"><i class="fas fa-plus"><input name="inputFile" id="inputFile'+slno+'" type="file" style="display: none"/></i></label></div></td>';
    //     html += '<td align="center"><button class="btn btn-danger mb-1 remove_line_item" type="button"><i class="fas fa-minus"></i></button></td>';
    // html += '</tr>'

	var cnt = slno - 1;
    html += '<tr id="'+id+'">';
        html += '<td align="center"><lable class="form-control" id="inputsl">'+slno+'</lable><input type="hidden" name="LineItems[inputSeqNo][]" class="form-control" id="inputSeqNo'+slno+'" value="'+slno+'"></td>';
        html += '<td><input type="text" name="LineItems[inputMerchant][]" class="form-control no_special_char_allow" id="inputMerchant'+slno+'" placeholder="Enter Merchant Name"></td>';
        html += '<td><input type="text" name="LineItems[inputInvoice][]" class="form-control invoice_number_char_allow" id="inputInvoice'+slno+'" placeholder="Enter Invoice Number"></td>';
        //html += '<td><input type="date" name="LineItems[inputDate][]" class="form-control no_special_char_allow" id="inputDate'+slno+'"></td>';
		html += '<td>';
			html += '<div class="input-group date" data-target-input="nearest">';
				html += '<input type="text" name="LineItems[inputDate][]" id="inputDate'+slno+'" class="form-control readonly_white" autocomplete="off" readonly=""  data-toggle="tooltip" data-placement="bottom" title data-original-title="Select date" />';
				html += '<div class="input-group-append">';
					html += '<div class="input-group-text"><i class="fa fa-calendar"></i></div>';
				html += '</div>';
			html += '</div>';
		html += '</td>';
        html += '<td><input type="text" name="LineItems[inputAmount][]" class="form-control no_special_char_allow allow_numeric_with_decimal text-right inputAmount" maxlength="10" id="inputAmount'+slno+'" placeholder="Enter Amount"></td>';
        html += '<td><select name="LineItems[inputCategory][]" class="form-control no_special_char_allow" id="inputCategory'+slno+'"></select></td>';
        html += '<td><select name="LineItems[inputTaxes][]" class="form-control no_special_char_allow" id="inputTaxes'+slno+'"></select></td>';
        html += '<td><input type="text" name="LineItems[inputComment][]" class="form-control no_special_char_allow" id="inputComment'+slno+'" placeholder="Comments/Notes"></td>';
        html += '<td><div class="image-upload" data-id="'+slno+'"><label for="inputFile'+slno+'" class="inputFile"><i class="fas fa-plus" data-toggle="tooltip" data-placement="bottom" title data-original-title="Add attachment"><input name="LineItems[inputFile]['+cnt+'][]" id="inputFile'+slno+'" type="file" multiple style="display: none"/></i></label></div><div id="inputFileList'+slno+'"></div></td>';
        html += '<td align="center"><button class="btn btn-danger mb-1 remove_line_item" data-toggle="tooltip" data-placement="bottom" title data-original-title="Remove line item" type="button"><i class="fas fa-minus"></i></button></td>';
    html += '</tr>'

    $('#line_item_row_table').append(html);
	var page = "add_expense";
    getExpenseCategories(page);
    // getExpenseTaxes(page,slno);
	datepicker('inputDate');
}

window.editLineItems = function(line_item){

    var slno = $('#line_item_table tbody tr').length + 1;
    var id = 'tablerow'+slno;
    var html = '';
	var date = line_item.date.split('T');
	var invoice_date = getFormattedDate(date[0]);
	var cnt = slno - 1;

	var document_html = '';
	if(line_item.documents) {
		for(var i = 0 ; i < line_item.documents.length ;  i++) {
			document_html += '<span>';
			document_html += '<lable>'+line_item.documents[i].display_name+'</lable>';
			document_html += '<i class="align-middle mr-2 fas fa-fw fa-trash-alt remove_exp_documents" data-id="'+line_item.documents[i].document_id+'" data-toggle="tooltip" data-placement="bottom" title data-original-title="Remove this attachment"></i>';
			document_html += '</span>'
		}
	}

    html += '<tr id="'+id+'" data-id="'+line_item.line_item_id+'">';
		html += '<td align="center"><lable class="form-control" id="inputsl'+slno+'">'+slno+'</lable><input type="hidden" name="LineItems[inputSeqNo][]" class="form-control" id="inputSeqNo'+slno+'" value="'+slno+'"></td>';
        html += '<td><input type="hidden" name="LineItems[lineItemId][]" class="form-control no_special_char_allow" id="lineItemId'+slno+'" value="'+line_item.line_item_id+'"><input type="text" name="LineItems[inputMerchant][]" class="form-control no_special_char_allow" id="inputMerchant'+slno+'" placeholder="Enter Merchant Name" value="'+line_item.merchant_name+'"></td>';
        html += '<td><input type="text" name="LineItems[inputInvoice][]" class="form-control invoice_number_char_allow" id="inputInvoice'+slno+'" placeholder="Enter Invoice Number" value="'+line_item.invoice_number+'"></td>';
        //html += '<td><input type="date" name="LineItems[inputDate][]" class="form-control no_special_char_allow" id="inputDate'+slno+'" value="'+date[0]+'"></td>';
		html += '<td>';
			html += '<div class="input-group date" data-target-input="nearest">';
				html += '<input type="text" name="LineItems[inputDate][]" id="inputDate'+slno+'" class="form-control readonly_white" autocomplete="off" readonly="" value="'+invoice_date+'"  data-toggle="tooltip" data-placement="bottom" title data-original-title="Update date"/>';
				html += '<div class="input-group-append">';
					html += '<div class="input-group-text"><i class="fa fa-calendar"></i></div>';
				html += '</div>';
			html += '</div>';
		html += '</td>';
        html += '<td><input type="text" name="LineItems[inputAmount][]" class="form-control no_special_char_allow allow_numeric_with_decimal inputAmount text-right" maxlength="10" id="inputAmount'+slno+'" placeholder="Enter Amount" value="'+line_item.amount+'"></td>';
        html += '<td><select name="LineItems[inputCategory][]" class="form-control no_special_char_allow" id="inputCategory'+slno+'"></select></td>';
        html += '<td><select name="LineItems[inputTaxes][]" class="form-control no_special_char_allow" id="inputTaxes'+slno+'"></select></td>';
        html += '<td><input type="text" name="LineItems[inputComment][]" class="form-control no_special_char_allow" id="inputComment'+slno+'" placeholder="Comments/Notes" value="'+line_item.description+'"></td>';
        html += '<td><div class="image-upload" data-id="'+slno+'"><label for="inputFile'+slno+'" class="inputFile"><i class="fas fa-plus" data-toggle="tooltip" data-placement="bottom" title data-original-title="Add attachment"><input name="LineItems[inputFile]['+cnt+'][]" id="inputFile'+slno+'" type="file" multiple style="display: none" value="'+line_item.documents+'"/></i></label></div><div id="inputFileList'+slno+'"></div><div id="existing_doc'+slno+'">'+document_html+'</div></td>';
        html += '<td align="center"><button class="btn btn-danger mb-1 remove_line_item" type="button" data-toggle="tooltip" data-placement="bottom" title data-original-title="Remove line item"><i class="fas fa-minus"></i></button></td>';
    html += '</tr>'

	$('#line_item_row_table').append(html);
	var line_item_category_id = line_item.category_id;
	var line_item_tax_id = line_item.tax_id;
	var page = "edit_expense";
    getExpenseCategories(page, line_item_category_id);
    // getExpenseTaxes(page, line_item_tax_id);
	datepicker('inputDate');
}

window.updateExpense = function(expense_id, type){

	var user_id = user.user_details.user_id;
    
	if(type == 'expense_header') {
		var form = $('#new_expense_form')[0];
		var formData = new FormData(form);
	} else if(type == 'expense_item') {
		var form = $('#expense_item_form')[0];
		var formData = new FormData(form);

		var deletedDocuments = [];
		if($('#deletedDocuments').length && ($('#deletedDocuments').val() != "" || $('#deletedDocuments').val() != null)) {
			deletedDocuments = $('#deletedDocuments').val().split(',');
		}

		for (var i = 0; i < deletedDocuments.length; i++) {
			formData.append('deleted_documents[]', deletedDocuments[i]);
		}
	} else if(type == 'expense_item_delete') {
		var formData = new FormData();

		var deletedLineItemArray = [];
		if($('#deletedLineItemId').length && ($('#deletedLineItemId').val() != "" || $('#deletedLineItemId').val() != null)) {
			deletedLineItemArray = $('#deletedLineItemId').val().split(',');
		}

		for (var i = 0; i < deletedLineItemArray.length; i++) {
			formData.append('deleted_line_items[]', deletedLineItemArray[i]);
		}
	} else if (type == 'expense_submit') {
		var formData = new FormData();
		formData.append('status',1);
	}

	formData.append('user_id', user_id);
	formData.append('expense_id', expense_id);
	formData.append('type', type);

	// scanned file
    var input = document.getElementById('fileToUpload');
    if(input.files.length > 0){
        formData.append('scannedFile', input.files[0]);
    }
	
	$.ajax({
		method : 'POST',
		url : api_url+"expenses/update-expense-by-id",
		data : formData,
		processData: false,
        contentType: false,
		beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
			display_expense_message(response);
        	if(response.status == "SUCCESS") {
                //load_selected_page('expenses','left_menu');
                $('#addExpenseItemModal').modal('hide');
	        	$('.modal-backdrop').remove();
				if(type == 'expense_submit'){
					$('#main_container').load('components/expenses.html', function(){
					});
				} else {
					var expense_id = response.data.expense_id;
					$('#main_container').load('components/expenses/view_expense.html', expense_id, function(){
						calculateTotal();
						$('#selected_expense_id').val(expense_id);
						setTimeout(function(){
							viewExpense(expense_id);
							getStateDropdownForSignup();
						}, 700);
					})
				}
            }
        }
	})
}

window.viewExpense = function(expense_id){
	var user = JSON.parse(localStorage.getItem('user'));
    var tz = jstz.determine();
    var timezone = tz.name();
    
    openLoadingDialog($('#line_item_container'));
	$.ajax({
		method : 'POST',
		url : api_url+"expenses/get-expense-by-id",
		data : 'expense_id='+expense_id,
		beforeSend: function(xhr) {
            xhr.setRequestHeader('Authorization', accessToken);
        },
		success: function(response) {
			//console.log(response.data.line_items);
			if(response.status == "SUCCESS") {
				var missing_items = '';
				$('#expense_header_detail').find('select').attr('disabled', true);
				$('#expense_header_detail').find('input').attr('disabled', true);
				$('#expense_report_name').html('<h2>'+response.data.expense_name+'</h2>');
				if(response.data.property_id && response.data.property_id != 0 && response.data.property_id != null){
					$('#inputProperty').val(response.data.property_id);
				} else {
					$('#inputProperty').val('');
					missing_items += '<li> Property </li>';
				}

				if(response.data.policy_id && response.data.policy_id != 0 && response.data.policy_id != null){
					$('#inputPolicy').val(response.data.policy_id);
				} else {
					missing_items += '<li> Policy </li>';
				}

				if(response.data.vendor_name && response.data.vendor_name != '' && response.data.vendor_name != null){
					$('#inputVendor').val(response.data.vendor_name);
				} else {
					missing_items += '<li> Vendor </li>';
				}

				if(response.data.expense_name && response.data.expense_name != '' && response.data.expense_name != null){
					$('#expenseName').val(response.data.expense_name);
				} else {
					missing_items += '<li> Expense Name </li>';
				}

				if(response.data.sub_total && response.data.sub_total != null){
					var f_sub_total = response.data.sub_total.replace(/,/g, '');
					$('#subTotalAmountInput').val(f_sub_total);
					$('#expenseSubTotalAmount').html(currency_sign+' '+response.data.sub_total);
				} else {
					if(response.data.total && response.data.total != null){
						var f_sub_total = response.data.total.replace(/,/g, '');
						$('#subTotalAmountInput').val(f_sub_total);
						$('#expenseSubTotalAmount').html(currency_sign+' '+response.data.total);
					}
				}

				if(response.data.tax_amount && response.data.tax_amount != null){
					var f_tax_amount = response.data.tax_amount.replace(/,/g, '');
					$('#taxAmountInput').val(f_tax_amount);
					$('#expenseTaxAmount').html(currency_sign+' '+response.data.tax_amount);
				}

				if(response.data.total && response.data.total != null){
					var f_total = response.data.total.replace(/,/g, '');
					$('#totalAmountInput').val(f_total);
					$('#expenseTotalAmount').html(currency_sign+' '+response.data.total);
				} 
				// else {
				// 	$('#totalAmountInput').val('0.00');
				// 	$('#expenseTotalAmount').html(currency_sign+' '+'0.00');
				// }

				var line_items = [];

				var html = '';

				if(response.data.line_items.length > 0) {
					for (var key in response.data.line_items) {
	                    if (response.data.line_items.hasOwnProperty(key)) {
							var line_item = response.data.line_items[key];
							//editLineItems(line_item);
							var line_item_id = line_item.line_item_id;
							var index = parseInt(key) + 1;

							if(line_item.merchant_name && line_item.merchant_name != null && line_item.merchant_name != undefined){
								var merchant_name = line_item.merchant_name;
							} else {
								var merchant_name = '-';
								missing_items += '<li> Merchant Name of line_item: '+index+' is missing </li>';
							}

							if(line_item.invoice_number && line_item.invoice_number != null && line_item.invoice_number != undefined){
								var invoice_number = line_item.invoice_number;
							} else {
								var invoice_number = '-';
								missing_items += '<li> Invoice Number of line_item: '+index+' is missing </li>';
							}

							if(!line_item.date){
								missing_items += '<li> Invoice Date of line_item: '+index+' is missing </li>';
							}

							if(line_item.category && line_item.category.length > 0){
								var category_name = line_item.category.category_name;
							} 
							
							if(!line_item.category) {
								missing_items += '<li> Category of line_item: '+index+' is missing </li>';
							}

							if(line_item.total && line_item.total != null){
								var amount = line_item.total;
							} else {
								var amount = 0.00;
								missing_items += '<li> Amount of line_item: '+index+' is missing </li>';
							}

							html += '<div id="line_item_'+line_item_id+'" class="row pointer border-bottom border-top p-2 edit_line_item" data-id="'+line_item_id+'">';
								html += '<div class="col-6 col-md-6 text-left"><span class="bold-black">Vendor</span><br>'+merchant_name+'</div>';
								html += '<div class="col-6 col-md-2 text-left"><span class="bold-black">Inv. Number</span><br>'+invoice_number+'</div>';
								html += '<div class="col-6 col-md-3 text-right"><span class="bold-black">Amount</span><br><span class="bold-black fs-18">$ '+amount+'</span></div>';
								var attachment_icon = '';
								if(line_item.documents.length != 0){
									attachment_icon += '<i class="fas fa-fw fa-paperclip mt-3 mr-4 pr-2"></i>';
								}															
								html += '<div class="col-6 col-md-1 text-center">'+attachment_icon+'<i class="mt-2 mr-2 fas fa-fw fa-trash delete_line_item" data-id="'+line_item_id+'"></i></div>';
							html += '</div>';
						}
					}
				} else {
					missing_items += '<li> line_items </li>';
					html += '<div class="center">';
                        html += '<img src="icon/expense.svg" class="filter-8" width="50"><br>';
                        html += '<small>Add your receipt details here by clicking <a href="javascript:void(0);" class="add_expense_item">Add expense</a></small>';
                    html += '</div>';
				}

				$('#line_item_container').html(html);
			}

			var missingItemHtml = '';
			if(missing_items != '') {
				missingItemHtml = '<ul>'+missing_items+'</ul>';
			}
			
			$('#missingItems').html(missingItemHtml);
		}
	})
}

window.viewExpenseDocument = function(document_id,display_name){
	$.ajax({
		method : 'POST',
		url : api_url+"expenses/view-document",
		data : 'document_id='+document_id,
		beforeSend: function(xhr) {
            xhr.setRequestHeader('Authorization', accessToken);
        },
		success: function(response) {
			if(response.status == "SUCCESS") {
				var file_url = response.data.file_url;
				var file_ext = response.data.document_data.document_ext;
				var dataURI =  response.data.data_uri;
				
				var html = '';
				if(file_ext == "pdf"){
					html += '<iframe src="'+file_url+'" width="100%" height="500px" frameborder="0"></iframe>';
				} else {
					html += '<img src="'+file_url+'" width="auto" max-width="100%" max-height="70vh"></img>';
				}
	
				$('#expense_document_media').html(html);
				var download = '<a download="'+display_name+'" href="'+dataURI+'"><button type="button" class="btn btn-orange m-2" data-toggle="tooltip" data-placement="bottom" title data-original-title="Download">Download</button></a>';
				$('#expense_image_download').html(download);
			} else {
				display_expense_message(response);
			}
		}
	});
}

window.getLineItemDetail = function(expense_id, line_item_id){
	var user = JSON.parse(localStorage.getItem('user'));
    $.ajax({
		method : 'POST',
		url : api_url+"expenses/get-expense-by-id",
		data : 'expense_id='+expense_id,
		beforeSend: function(xhr) {
            xhr.setRequestHeader('Authorization', accessToken);
        },
		success: function(response) {
			if(response.status == "SUCCESS") {
				var line_items = [];
				for (var key in response.data.line_items) {
                    if (response.data.line_items.hasOwnProperty(key)) {
						var line_item = response.data.line_items[key];
						if(line_item.line_item_id == line_item_id) {
							// if(line_item.tax_amount && line_item.tax_amount != null){
							// 	if(line_item.sub_total && line_item.sub_total != null){
							// 		var sub_total = line_item.sub_total;
							// 	} else {
							// 		if(line_item.total && line_item.total != null){
							// 			var sub_total = line_item.total - line_item.tax_amount;
							// 		} else {
							// 			var sub_total = 0.00;
							// 		}
							// 	}
							// 	$('#taxAmount').val(currencyFormat(line_item.tax_amount));
							// } else {
							// 	if(line_item.total && line_item.total != null){
							// 		var sub_total = line_item.total;
							// 	} else {
							// 		var sub_total = 0.00;
							// 	}
							// }

							// if(line_item.tax_percentage && line_item.tax_percentage != null){
							// 	$('#taxPercentage').val(line_item.tax_percentage);
							// }
							
							if(line_item.total && line_item.total != null){
								$('#inputAmount').val(currencyFormat(Number(line_item.total.replace(/[^0-9\.]/g, ''))));
							}

							// $('#expenseAmount').val(currencyFormat(sub_total)); 
							$('#inputMerchant').val(line_item.merchant_name);
							$('#inputInvoice').val(line_item.invoice_number);

							if(line_item.date && line_item.date != null){
								// var date = line_item.date.split('T');
								// var invoice_date = getFormattedDate(date[0]);
								var invoice_date = getFormattedDate(line_item.date);
							} else {
								var invoice_date = '';
							}

							$('#inputDate').val(invoice_date);
							$('#inputComment').val(line_item.description);

							var line_item_category_id = line_item.category_id;
							if(line_item.tax_id){
								var line_item_tax_id = line_item.tax_id.replace(/^\s+|\s+$/gm,'');
							} else {
								var line_item_tax_id = '';
							}

							if(line_item.sub_category_id){
								var line_item_sub_category_id = line_item.sub_category_id.replace(/^\s+|\s+$/gm,'');
							} else {
								var line_item_sub_category_id = '';
							}

							var page = "edit_expense";
						    getExpenseCategories(page,line_item_category_id);
							// $('#inputSubCategory').attr('disabled',false);
							getExpenseSubCategories(page,line_item_category_id,line_item_sub_category_id);
						    // getExpenseTaxes(page, line_item_tax_id);
							datepicker('inputDate');

							var document_html = '';
							document_html += '<div class="row fs-16 bold-black mb-3">Uploaded Receipt(s)</div>';
							if(line_item.documents) {
								for(var i = 0 ; i < line_item.documents.length ;  i++) {
									document_html += '<div id="doc_'+line_item.documents[i].document_id+'" class="row border-bottom border-top p-2">';
										document_html += '<div class="col-auto col-md-6 text-left">'+line_item.documents[i].display_name+'</div>';
										document_html += '<div class="col-auto col-md-6 text-right"><i class="fas fa-eye mr-3 view_expense_document" data-id="'+line_item.documents[i].document_id+'" data-value="'+line_item.documents[i].display_name+'" data-toggle="tooltip" data-placement="bottom" title data-original-title="view this attachment"></i><i class="align-middle mr-2 fas fa-fw fa-trash-alt remove_exp_documents" data-id="'+line_item.documents[i].document_id+'" data-toggle="tooltip" data-placement="bottom" title data-original-title="Remove this attachment"></i></div>';
									document_html += '</div>';
								}
							}
							$('#existing_doc').html(document_html);
						}
					}
				}
			}
		}
	})
}

window.deleteExpenseLineItem = function(line_item_id) {
	if(line_item_id != null){
		var delimeter = '';
		if($('#deletedLineItemId').val() != "") { delimeter = ','; }
		var deletedDocumentId = $('#deletedLineItemId').val($('#deletedLineItemId').val()+delimeter+line_item_id);

		var expense_id = $('#selected_expense_id').val();
		var type = 'expense_item_delete';
		if(updateExpense(expense_id, type)) {
			$('#line_item_'+line_item_id).remove();
		}
	}
}

window.getExpenses = function() {
    var user = JSON.parse(localStorage.getItem('user'));
    var tz = jstz.determine();
    var timezone = tz.name();
	var user_id = user.user_details.user_id;
    //var data = '';
    //data = 'user_timezone='+timezone+'&user_service_name='+user_service_name;

    $.ajax({
        method: 'POST',
        url: api_url+"expenses/get-expenses",
		data : 'user_id='+user_id,
        beforeSend: function(xhr) {
            xhr.setRequestHeader('Authorization', accessToken);
        },
        success: function(response) {
            if(response.status == "SUCCESS") {
                var html = '';
                var i = 1;
                for (var key in response.data) {
                    if (response.data.hasOwnProperty(key)) {
						var status_label = response.data[key].status_label;
                    	var expense_id = response.data[key].expense_id;
                        var property_name = response.data[key].property_name;
                        var expense_name = response.data[key].expense_name;
						var ct = new Date(response.data[key].created_time);
						var created_time = new Date(ct.getFullYear(),ct.getMonth(),ct.getDate(),ct.getHours(),ct.getMinutes(),ct.getSeconds())
						var date = created_time.toDateString();
						var time = created_time.toLocaleTimeString('en-US', {hour: 'numeric', minute: 'numeric', hour12: true});
						//created_time = moment(created_time).format('ddd MMM DD YYYY hh:mm:ss');
						
						if(response.data[key].policy){
							var policy_name = response.data[key].policy.policy_name;
						} else {
							var policy_name = '';
						}
                        
                        var total = response.data[key].total;
						if(response.data[key].vendor_name) {
							var vendor_name = response.data[key].vendor_name;
						} else {
							var vendor_name = '';
						}
                        
						//if(response.data[key].line_items.length > 0 && response.data[key].line_items != null) {
							var total_line_item = response.data[key].line_items.length;
							var str = '<small>('+total_line_item+' expense)</small>';
							if(total_line_item > 1) {
								str = '<small>('+total_line_item+' expenses)</small>';
							}
							if(response.data[key].status == 0) {
								status_label = '<small class="fs-16 red dark">'+status_label+'</small>';
							} else if(response.data[key].status == 1) {
								status_label = '<small class="fs-16 green dark">'+status_label+'</small>';
							} else {
								status_label = '<small class="fs-16 dark">'+status_label+'</small>';
							}
							html += '<tr class="view_expense pointer" data-id="'+response.data[key].expense_id+'">';
								html += '<td>';

									html += '<div class="row card">';
						                html += '<div class="row justify-content-center">';
						                    html += '<div class="col-12 col-md-6 col-lg-6">';
						                        html += '<div class="card-body text-left"><span class="bold-black fs-18">'+expense_name+'</span><br><small>ID: '+expense_id+'</small><br><small>'+date+' '+time+'</small><br><small>'+policy_name+'</small>&nbsp;'+str+'&nbsp;<br>'+status_label+'</div>';
						                    html += '</div>';
						                    html += '<div class="col-12 col-md-6 col-lg-6">';
						                        html += '<div class="card-body expense_total text-right bold-black fs-22">$ '+total;

						                        if(response.data[key].status == 0) {
													html += '<i class="align-middle mr-2 fas fa-fw fa-trash fs-16 delete_expense" data-id="'+expense_id+'"></i>';
												}

						                        html += '</div>';
						                    html += '</div>';
						                html += '</div>';
						            html += '</div>';
								html += '</td>';
							html += '</tr>'

							i++;
						//}
                    }
                }

                $('#expenses_table_row').html(html);

                var expenses_table = $("#expenses-datatable").DataTable({
					ordering: false
                    //dom: 'Bfrtip',
                    //autoWidth: true,
                    //paging: true,
                    //info: false,
                    //scrollY: '600px',
                    //scrollX: true,
                    //scrollCollapse: true,
                    //order: [[0,'desc']],
                   /* buttons: [
                        'excel'
                    ],*/
                });

                //$('.buttons-excel').removeClass('btn-secondary').addClass('btn-primary');

                $("#expenses-datatable tbody tr td:first").addClass('border-top');
                
                getFloatingActionButton('Expenses','#expensesDataContainer');
            }
        }
    });
	
}

window.arrangeslno = function(tableID){
	var i = 1;
	$(`#${tableID} tbody tr`).each(function(){
		$(this).find('lable[id^="inputsl"]').html(i);
		$(this).find('lable[id^="inputsl"]').val(i);
		$(this).find('input[name^="LineItems[inputSeqNo]"]').val(i);

		$(this).attr('id', 'tablerow'+i);
		$(this).find('input[name^="LineItems[inputSeqNo]"]').attr('id', 'inputSeqNo'+i);
		$(this).find('input[name^="LineItems[inputMerchant]"]').attr('id', 'inputMerchant'+i);
		$(this).find('input[name^="LineItems[inputInvoice]"]').attr('id', 'inputInvoice'+i);
		$(this).find('input[name^="LineItems[inputDate]"]').attr('id', 'inputDate'+i);
		$(this).find('input[name^="LineItems[inputAmount]"]').attr('id', 'inputAmount'+i);
		$(this).find('select[name^="LineItems[inputCategory]"]').attr('id', 'inputCategory'+i);
		$(this).find('select[name^="LineItems[inputTaxes]"]').attr('id', 'inputTaxes'+i);
		$(this).find('input[name^="LineItems[inputComment]"]').attr('id', 'inputComment'+i);

		var cnt = i - 1;
		$(this).find('input[name^="LineItems[inputFile]"]').attr('id', 'inputFile'+i);
		$(this).find('input[name^="LineItems[inputFile]"]').attr('name', 'LineItems[inputFile]['+cnt+'][]');
		$(this).find('div[id^="existing_doc"]').attr('id', 'existing_doc'+i);
		$(this).find('.image-upload').attr('data-id', i);
		$(this).find('label.inputFile').attr('for', 'inputFile'+i);
		$(this).find('div[id^="inputFileList"]').attr('id', 'inputFileList'+i);
		i++;
	})
}

var $validateVendor;
window.addVendorValidation = function() {
	$validateVendor = $("#new_vendor_form,#edit_vendor_form")
	$validateVendor.validate({
	    errorPlacement: function errorPlacement(error, element) {
	        $(element).parents(".form-group").append(error.addClass("invalid-feedback small d-block"))
	    },
	    highlight: function(element) {
	        $(element).addClass("is-invalid");
	    },
	    unhighlight: function(element) {
	        $(element).removeClass("is-invalid");
	    },
	    rules: {
	        "vendorName": { required: true },
	        "vendorEmail": { required: true, email: true },
			"vendorPhone": { required: true},
			"vendorAddress1": { required: true },
	        "vendorState": { required: true },
			"vendorCity": { required: true},
			"vendorZip": { required: true },
	        "vendorCountry": { required: true }
	    },
	    messages: {
	        "vendorName": { required: '' },
	        "vendorEmail": { required: '' },
			"vendorPhone": { required: ''},
			"vendorAddress1": { required: '' },
	        "vendorState": { required: '' },
			"vendorCity": { required: ''},
			"vendorZip": { required: '' },
	        "vendorCountry": { required: '' }
	    }
	});
}

var $validateExpense;
window.addExpenseValidation = function() {
	$validateExpense = $("#new_expense_form")
	$validateExpense.validate({
	    errorPlacement: function errorPlacement(error, element) {
	        $(element).parents(".form-group").append(error.addClass("invalid-feedback small d-block"))
	    },
	    highlight: function(element) {
	        $(element).addClass("is-invalid");
	    },
	    unhighlight: function(element) {
	        $(element).removeClass("is-invalid");
	    },
	    rules: {
	        "inputProperty": { required: true },
	        "inputPolicy": { required: true },
			"expenseName": { required: true},
			"inputVendor": { required: true }
	    },
	    messages: {
	        "inputProperty": { required: '' },
	        "inputPolicy": { required: '' },
			"expenseName": { required: ''},
			"inputVendor": { required: '' }
	    }
	});
}

var $validateCategory;
window.addCategoryValidation = function() {
	$validateCategory = $("#new_category_form, #edit_category_form")
	$validateCategory.validate({
	    errorPlacement: function errorPlacement(error, element) {
	        $(element).parents(".form-group").append(error.addClass("invalid-feedback small d-block"))
	    },
	    highlight: function(element) {
	        $(element).addClass("is-invalid");
	    },
	    unhighlight: function(element) {
	        $(element).removeClass("is-invalid");
	    },
	    rules: {
	        "categoryName": { required: true },
	        "categoryDescription": { required: true },
			"isDefault": { required: true},			
	    },
	    messages: {
	        "categoryName": { required: '' },
	        "categoryDescription": { required: '' },
			"isDefault": { required: ''},			
	    }
	});
}

var $validateSubCategory;
window.addSubCategoryValidation = function() {
	$validateSubCategory = $("#new_sub_category_form, #edit_sub_category_form")
	$validateSubCategory.validate({
	    errorPlacement: function errorPlacement(error, element) {
	        $(element).parents(".form-group").append(error.addClass("invalid-feedback small d-block"))
	    },
	    highlight: function(element) {
	        $(element).addClass("is-invalid");
	    },
	    unhighlight: function(element) {
	        $(element).removeClass("is-invalid");
	    },
	    rules: {
	        "subCategoryName": { required: true },
	        "subCategoryDescription": { required: true },
	    },
	    messages: {
	        "subCategoryName": { required: '' },
	        "subCategoryDescription": { required: '' },
	    }
	});
}

var $validateExpenseItem;
window.addExpenseItemValidation = function() {
	$validateExpenseItem = $("#expense_item_form")
	$validateExpenseItem.validate({
	    errorPlacement: function errorPlacement(error, element) {
	        $(element).parents(".form-group").append(error.addClass("invalid-feedback small d-block"))
	    },
	    highlight: function(element) {
	        $(element).addClass("is-invalid");
	    },
	    unhighlight: function(element) {
	        $(element).removeClass("is-invalid");
	    },
	    rules: {
	        "inputMerchant": { required: true },
			"inputInvoice": { required: true },
			"inputDate": { required: true },
			"inputAmount": { required: true },
	        "inputCategory": { required: true },
	        // "inputTaxes": { required: true },
			"inputSubCategory": {
				required:function(element){
					return $('#inputSubCategory option').length > 1;
				}
			}
	    },
	    messages: {
	        "inputMerchant": { required: '' },
			"inputInvoice": { required: '' },
			"inputDate": { required: '' },
			"inputAmount": { required: '' },
	        "inputCategory": { required: '' },
	        // "inputTaxes": { required: '' },
			"inputSubCategory": {required:''}
	    }
	});
}

window.saveExpenses = function(){
	var user_id = user.user_details.user_id;
    var form = $('#new_expense_form')[0];
    var formData = new FormData(form);
	formData.append('user_id', user_id);
	formData.append('status', 0);

	$.ajax({
		method : 'POST',
		url : api_url+"expenses/add-expense",
		data : formData,
		processData: false,
	    contentType: false,
	    cache: false,
		beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
			display_expense_message(response);
        	if(response.status == "SUCCESS") {
        		$('#addExpenseModal').modal('hide');
	        	$('.modal-backdrop').remove();
                var expense_id = response.data.expense_id;
				$('#main_container').load('components/expenses/view_expense.html', expense_id, function(){
					$('#selected_expense_id').val(expense_id);
					setTimeout(function(){
						viewExpense(expense_id);
						getStateDropdownForSignup();
					}, 700);
				})
            }
        }
	})
}

window.deleteExpense = function(expense_id) {
	if(expense_id != null){
		$.ajax({
			method : 'POST',
			url : api_url+"expenses/delete-expense",
			data : 'expense_id='+expense_id,
			beforeSend: function(xhr) {
		        xhr.setRequestHeader('Authorization', accessToken);
		    },
	        success: function(response) {
				display_expense_message(response);
	        	if(response.status == "SUCCESS") {
	        		var expense_id = response.data.expense_id;
					$('#main_container').load('components/expenses.html');
	            }
	        }
		})
	}
}

window.saveScannedExpense = function(response) {
	var user_id = user.user_details.user_id;
	var expObj = {};
	expObj.submitted_by = user_id;
	expObj.status = 0;
	expObj.expense_name = response.data.summaryFields['VENDOR_NAME'];
	if(response.data.summaryFields['SUBTOTAL']){
		expObj.sub_total = Number(response.data.summaryFields['SUBTOTAL'].replace(/[`~!@$%^|+\=?;'"<>\{\}\[\]\\\/]/gi, ''));
	} else {
		expObj.sub_total = 0;
	}
	
	if(response.data.summaryFields['TOTAL']) {
		var total = Number(response.data.summaryFields['TOTAL'].replace(/[`~!@$%^|+\=?;'"<>\{\}\[\]\\\/]/gi, ''));
		total = Math.round((total + Number.EPSILON) * 100) / 100;
	} else {
		var total = 0;
	}

	if(response.data.summaryFields['TAX']){
		var taxAmount = Number(response.data.summaryFields['TAX'].replace(/[`~!@$%^|+\=?;'"<>\{\}\[\]\\\/]/gi, ''));
		taxAmount = Math.round((taxAmount + Number.EPSILON) * 100) / 100;
		var subTotal = total - taxAmount;
		subTotal = Math.round((subTotal + Number.EPSILON) * 100) / 100;
		var taxPercentage = taxAmount/subTotal * 100;
		taxPercentage = Math.round((taxPercentage + Number.EPSILON) * 100) / 100;
	} else {
		var taxAmount = 0;
		var sub_total = 0;
		var taxPercentage = 0;
	}
	
	expObj.total = total;
	var line_items = [];
	if(response.data.summaryFields['INVOICE_RECEIPT_DATE']){
		var date = response.data.summaryFields['INVOICE_RECEIPT_DATE'];
	} else {
		var date = '';
	}

	if(response.data.summaryFields['INVOICE_RECEIPT_ID']) {
		var invoice_number = response.data.summaryFields['INVOICE_RECEIPT_ID'];
	} else {
		var invoice_number = '';
	}

	for (var key in response.data.lineItems) {
		if (response.data.lineItems.hasOwnProperty(key)) {
			var lineData = response.data.lineItems[key];
			lineData.PRICE = Number(lineData.PRICE.replace(/[`~!@$%^|+\=?;'"<>\{\}\[\]\\\/]/gi, ''));
			lineData.PRICE = Math.round((lineData.PRICE + Number.EPSILON) * 100) / 100;
			if(taxAmount == 0 && sub_total == 0 && taxPercentage == 0){
				var tax_amount = 0;
				var amount = lineData.PRICE;
				var sub_total_line_item = amount;
			} else {
				var tax_amount = lineData.PRICE * ( taxPercentage / 100 );
				tax_amount = Math.round((tax_amount + Number.EPSILON) * 100) / 100;
				var amount = lineData.PRICE * (1 + (taxPercentage/100));
				amount = Math.round((amount + Number.EPSILON) * 100) / 100;
				var sub_total_line_item = amount - tax_amount;
				sub_total_line_item = Math.round((sub_total_line_item + Number.EPSILON) * 100) / 100;
			}

			if(invoice_number == '' && lineData.PRODUCT_CODE){
				invoice_number = lineData.PRODUCT_CODE;
			}
			line_items[key] = {
				"merchant_name" : response.data.summaryFields['VENDOR_NAME'],
				"total" : amount,
				"sub_total": sub_total_line_item,
				"description" : lineData.EXPENSE_ROW,
				"date" : date,
				"invoice_number" : invoice_number,
				"tax_percentage" : taxPercentage,
				"tax_amount" : tax_amount
			};
		}
	}

	expObj.line_items = line_items;

	//console.log(JSON.stringify(expObj)); return false;
	$.ajax({
		url: api_url+"expenses/add-scanned-expense",
		data: "data="+JSON.stringify(expObj),
		type: "POST",
		beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
		success: function(result) {
			var expense_id = result.data.expense_id;
			$('#main_container').load('components/expenses/view_expense.html', expense_id, function(){
				$('#selected_expense_id').val(expense_id);
				setTimeout(function(){
					viewExpense(expense_id);
					getStateDropdownForSignup();
				}, 700);
			})
		}
	})
}

window.createScannedExpense = function(response) {

	var line_items = [];
	if(response.data.summaryFields['INVOICE_RECEIPT_DATE']){
		var date = new Date(response.data.summaryFields['INVOICE_RECEIPT_DATE']);
		if(date == 'Invalid Date'){
			date = '';
		}
		else{
			date = getFormattedDate(date);
		}		
	} else {
		var date = '';
	}

	if(response.data.summaryFields['INVOICE_RECEIPT_ID']) {
		var invoice_number = response.data.summaryFields['INVOICE_RECEIPT_ID'];
	} else {
		var invoice_number = '';
	}

	var total = currencyFormat(Number(response.data.summaryFields['TOTAL'].replace(/[^0-9\.]/g, '')));

	line_items = {
		"merchant_name" : response.data.summaryFields['VENDOR_NAME'],
		"total" : total,				
		"invoice_number" : invoice_number,
		"date" : date,
	};

	return line_items;

}

window.showExpenseItems = function(id){
	var user_id = user.user_details.user_id;
	//var user_id = 219;
    $.ajax({
		method : 'POST',
		url : api_url+"expenses/get-expense-items",
		data : 'user_id='+user_id+'&sub_category_id='+id,
		beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	display_expense_message(response);
        	if(response.response_data.success == true) {
				var html = '';
				if(Object.keys(response.data).length > 0){
					for (var pkey in response.data) {
						if(pkey != 'total'){
							var p = pkey.split("-");
							html += '<tr>';
								html += '<td>';
									html += '<div class="card row mb-0">';
										html += '<h5>'+p[1]+'</h5>';
										html += '<div class="mt-2">';
											html += '<div id="show_line_item_container" class="col-auto col-md-12">';
												for(var lkey in response.data[pkey]){

													var line_item_id = response.data[pkey][lkey]['line_item_id'];
													var merchant_name = response.data[pkey][lkey]['merchant_name'];
													var invoice_number = response.data[pkey][lkey]['invoice_number'];
													var amount = currencyFormat(Number(response.data[pkey][lkey]['total']));

													html += '<div id="line_item_'+line_item_id+'" class="row pointer border-bottom border-top p-2" data-id="'+line_item_id+'">';
														html += '<div class="col-6 col-md-6 text-left"><span class="bold-black">Merchant</span><br>'+merchant_name+'</div>';
														html += '<div class="col-6 col-md-3 text-left"><span class="bold-black">Inv. Number</span><br>'+invoice_number+'</div>';
														html += '<div class="col-6 col-md-3 text-right"><span class="bold-black">Amount</span><br><span class="bold-black fs-18">$ '+amount+'</span></div>';
													html += '</div>';
												}
											html += '</div>';
										html += '</div>';
									html += '</div>';
								html += '</td>';
							html += '</tr>';
						}
					}
					var total = 'Total = $ '+currencyFormat(Number(response.data['total']));
					$('.category_total_amount').html(total);
					$('#show_expenses_media').html(html);
					$("#expenses-item-datatable").DataTable({
						paging: false,
						info: false
					});
					$("#expenses-item-datatable tbody tr td").css('border', 'none');
				} else {
					html += '<h3 class="text-center mt-3"> No Data Available </h3>';
					$('#show_expenses_media').html(html);
				}
			}
		}
	})
}

window.getExpenseDetailedReport = function() {
	var user_id = user.user_details.user_id;
	//var user_id = 219;
    $.ajax({
		method : 'POST',
		url : api_url+"expenses/get-expense-reports",
		data : 'user_id='+user_id,
		beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	display_expense_message(response);
        	var html = '';
        	if(response.response_data.success == true) {
        		var col_cnt = 0;
        		var col_cnt_arr = [];
        		html += '<table id="expense-report-datatable" class="table table-striped" cellspacing="10" cellpadding="10" style="width: 100%;">';
                    html += '<thead>';
                        html += '<tr>';
                            html += '<th class="fs-14">Categories</th>';
							html += '<th class="fs-14">Sub Categories</th>';
                            for (var key in response.data) {
                            	var p = key.split("-");
                            	html += '<th class="text-right fs-14">'+p[1]+'</th>';
                            	col_cnt++;
                            	col_cnt_arr.push(col_cnt);
                            }
                        html += '</tr>';
                    html += '</thead>';
                    html += '<tbody>';

					html += '<tr class="income_col">';
						html += '<th class="fs-22 bold-black">Income</th>';
						html += '<th>&nbsp;</th>';
						for (var key in response.data) {
							html += '<th>&nbsp;</th>';
						}
					html += '</tr>';

					var	incomeArray = ['Rents received','Royalties received'];

					for(var ikey in incomeArray){
						html += '<tr class="income_col" data-type="income">';
						html += '<td class="fs-14 bold-black">'+incomeArray[ikey]+'</td>';
						html += '<td class="fs-14">-</td>';
						for (var key in response.data) {
							html += '<td class="text-right fs-14">-</td>';
						}
						html += '</tr>';
					}

					html += '<tr>';
						html += '<th class="fs-22 bold-black">Expenses</th>';
						html += '<th>&nbsp;</th>';
						for (var key in response.data) {
							html += '<th>&nbsp;</th>';
						}
					html += '</tr>';


                    	var cnt = 0;
                        for (var key in response.data) {
							var data_number = 0;
                        	for (var jkey in response.data[key]) {
                        		if(cnt == 0) {
									if(jkey != 'property_total' && jkey != 'other_total')
									{
										var category_id = response.data[key][jkey]['category_id'];
										html += '<tr class="show_expense_report_row pointer" data-number="'+data_number+'">';
										html += '<td class="fs-14 bold-black">'+jkey+'</td>';
										html += '<td class="fs-14">-</td>';
										for (var pkey in response.data) {
											//if(response.data[pkey][jkey]['category_total'] == 0) {
												html += '<td class="text-right fs-14 val_total_on_collaps bold-black category'+data_number+'" data-val="'+response.data[pkey][jkey]['category_total']+'">-</td>';
											// } else {
											// 	html += '<td class="text-right">'+currency_sign+' '+currencyFormat(Number(Math.round(response.data[pkey][jkey]['category_total'])))+'</td>';
											// }
										}
										html += '</tr>';
										if(response.data[key][jkey]['sub_category']){
											var temp = 0;
											for(var subKey in response.data[key][jkey]['sub_category']){
												var sub_category_id = response.data[key][jkey]['sub_category'][subKey]['sub_category_id'];
												html += '<tr class="sub_cat'+data_number+' calc_sub exp_col open_expense_item pointer" data-title="'+jkey+' - '+subKey+'" data-id="'+sub_category_id+'" data-cnt="'+temp+'">';
												html += '<td class="fs-14 blur-font">'+jkey+'</td>';
												html += '<td class="fs-14">'+subKey+'</td>';
												for (var Pkey in response.data) {
													if(response.data[Pkey][jkey]['sub_category'][subKey]['sub_category_total'] == 0) {
														html += '<td class="text-right fs-14 sub_calc'+temp+'" data-val="'+response.data[Pkey][jkey]['sub_category'][subKey]['sub_category_total']+'">-</td>';
													} else {
														html += '<td class="text-right fs-14 sub_calc'+temp+'" data-val="'+response.data[Pkey][jkey]['sub_category'][subKey]['sub_category_total']+'">'+currency_sign+' '+currencyFormat(Number(response.data[Pkey][jkey]['sub_category'][subKey]['sub_category_total']))+'</td>';
													}
												}
												html += '</tr>';
												temp++;
											}
										}

										// html += '<tr class = "sub_cat'+data_number+' exp_col open_expense_item pointer" data-title="'+jkey+' - Not selected" data-id="'+category_id+'">';
										// html += '<td class="fs-14 blur-font">'+jkey+'</td>';
										// html += '<td class="fs-14">Not selected</td>';
										// for (var pkey in response.data) {
										// 	if(response.data[pkey][jkey]['other_total'] == 0) {
										// 		html += '<td class="text-right fs-14">-</td>';
										// 	} else {
										// 		html += '<td class="text-right fs-14">'+currency_sign+' '+currencyFormat(Number(response.data[pkey][jkey]['other_total']))+'</td>';
										// 	}
										// }
										// html += '</tr>';

										html += '<tr class="sub_cat'+data_number+' exp_col" data-cnt="total">';
										html += '<td class="fs-14">'+jkey+' total</td>';
										html += '<td class="fs-14">-</td>';
										for (var pkey in response.data) {
											if(response.data[pkey][jkey]['category_total'] == 0) {
												html += '<td class="text-right fs-14">-</td>';
											} else {
												html += '<td class="text-right bold-black fs-14">'+currency_sign+' '+currencyFormat(Number(response.data[pkey][jkey]['category_total']))+'</td>';
											}
										}
										html += '</tr>';
									}
			                    }
								data_number++;
		                    }
		                    cnt++;
                        }
                    html += '</tbody>';
                    html += '<tfoot>';
                    	html += '<tr>';
                            html += '<td>Total</td>';
							html += '<td>&nbsp;</td>';
                            for (var key in response.data) {
								var val = currency_sign+' '+currencyFormat(Number(response.data[key]['property_total']));
                            	html += '<td class="bold-black text-right">'+val+'</td>';
                            }
                        html += '</tr>';
                    html += '</tfoot>';
                html += '</table>';
                
                $('#expense_report_data_container').html(html);
				$('.income_col').addClass('d-none');
				$('.show_expense_report_row').each(function(){
					var data_number = $(this).attr('data-number');
					var sum = 0;
					$('.category'+data_number).each(function(){
						sum = sum + $(this).attr('data-val');
					});
					
					if(sum == 0){
						if(!$(this).hasClass('d-none')){
							$(this).addClass('d-none');
							if(!$('.sub_cat'+data_number).hasClass('d-none')){
								$('.sub_cat'+data_number).addClass('d-none');
							}
						}
					} else {
						$('.calc_sub').each(function(){
							var data_cnt = $(this).attr('data-cnt');
							var flag = 0;
							$('.sub_calc'+data_cnt).each(function(){
								flag = flag + Number($(this).attr('data-val'));
							});
							if(flag == 0){
								if(!$(this).hasClass('d-none')){
									$(this).addClass('d-none');
								}
							}
						})
					}
				});
                var buttonCommon = {
				    exportOptions: {
				        format: {
				            body: function ( data, row, column, node ) {
				            	// Replace <br> with space
				            	var regex = /<br\s*[\/]?>/gi;
				            	data = data.replace(regex, " ");
				            	
				            	// Remove all html tags from data and Remove $ from amount
				            	/*var regex = /(<([^>]+)>)/ig;
				                var amt_col = col_cnt_arr; //[1,2,3,4,5];  
				                if(amt_col.indexOf(column) !== -1) {
				                	var data = data.replace(regex, "");
				                	return data.replace( /[$,]/g, '' );
				                } else {
				                	return data.replace(regex, "");
				                }*/
				                return data.replace(regex, "");
				            }
				        }
				    }
				};
        		
        		var tenant_table = $("#expense-report-datatable").DataTable({
	            	dom: 'Bfrtip',
	    			autoWidth: true,
	            	paging: false,
	            	info: false,
				    scrollY: '475px',
				    scrollX: true,
			        scrollCollapse: true,
					ordering: false,
			        // order: [[0,'asc']],
			        fixedColumns:   {
			            left: 2
			        },
			        buttons: [
						$.extend( true, {}, buttonCommon, {
					        extend: 'excelHtml5',
					        text: 'Download Excel',
					        title: 'Expenses Report',
					        message:'Disclaimer: Rent synergy has made every attempt to ensure the correctness of this report, based on the data entered and available with us. Before the final consumption of this report, we recommend you to verify and validate this data with your accounting team.',
					        autoFilter: true,
					        header: true,
					        footer: true,
					        exportOptions: {
								//columns: ':visible'
					            columns: ':visible:not(.notexport)'
					        },
					        customize: function(xlsx) {
						        var sheet = xlsx.xl.worksheets['sheet1.xml'];
						        datatable_excel_common_formatting(xlsx,sheet);
						    }
					    }),
					    $.extend( true, {}, buttonCommon, {
					        extend: 'colvis',
					      	text: "Columns Visibility ",
					      	collectionLayout: 'two-column',
					      	postfixButtons: ['colvisRestore'],
					    }),
					],
					language: {
				        buttons: {
				          colvisRestore: "Select All"
				        }
				    },
				    // "footerCallback": function ( row, data, start, end, display ) {
					// 	var api = this.api();

					// 	// Remove the formatting to get integer data for summation
			        //     var intVal = function ( i ) {
			        //         return typeof i === 'string' ?
			        //             i.replace(/[\$,-]/g, '')*1 :
			        //             typeof i === 'number' ?
			        //                 i : 0;
			        //     };

					// 	var nb_cols = api.columns().nodes().length;
					// 	var j = 1;
					// 	while(j < nb_cols) {
					// 		var pageTotal = api
		            //     		.column( j, { page: 'current'} )
		            //     		.data()
		            //     		.reduce( function (a, b) {
		            //     			var t = intVal(a) + intVal(b);
		            //         		return t;
		            //     		}, 0 );
		          	// 		// Update footer
		          	// 		$( api.column( j ).footer() ).html(currency_sign+' '+currencyFormat(Number(Math.round(pageTotal))));
					// 		j++;
					// 	} 
					// }
				});

				$('.buttons-html5').removeClass('btn-secondary').addClass('btn-primary');
				$('.buttons-colvis').removeClass('btn-secondary').addClass('btn-primary');
				$('.dt-buttons > button').addClass('mr-2');
				$('.btn-group > .btn:not(:last-child):not(.dropdown-toggle), .btn-group > .btn-group:not(:first-child) > .btn').css({
					'border-radius': '5px'
				});
				$('.dt-buttons').append('<div class="custom-control custom-switch ml-3 mt-2 fs-16 bold-black"><input type="checkbox" class="custom-control-input pointer" id="show_all_categories" checked><label class="custom-control-label" for="show_all_categories">Show All</label></div>');
				$('.dt-buttons').append('<div class="custom-control custom-switch ml-3 mt-2 fs-16 bold-black"><input type="checkbox" class="custom-control-input pointer" id="expand_collapse" checked><label class="custom-control-label" for="expand_collapse">Collapse</label></div>');
            } else {
            	html += '<a href="javascript:void(0)" class="list-group-item list-group-item-action border-0 bg-light text-center"><div class="fs-16">'+response.response_data.message+'</div></a>';
            	$('#expense_report_data_container').html(html);
            }
        }
	})
}

window.calculateTotal = function(){
    var total = 0;
	$('.inputAmount').each(function(){
        	// if($(this).val() == 0 || $(this).val() == 0.00) {
			// 	$(this).val('0.00');
			// }
			var val = $(this).val();
			total += parseFloat(val.replace(/,/g, ''));

			var amount = $(this).val();
			var amt = amount.replace(/,/g, '');
			$(this).val(currencyFormat(Number(amt)));
    })
    $("#expenseTotalAmount").html(currency_sign+' '+currencyFormat(Number(total)));
    $("#totalAmountInput").val(total.toFixed(2));
}

window.getTenantReport = function() {
	var user = JSON.parse(localStorage.getItem('user'));
    var tz = jstz.determine();
	var timezone = tz.name();
	
	var data = '';
	data = 'user_timezone='+timezone+'&user_service_name='+user_service_name;
	
	$.ajax({
	    method: 'POST',
	    url: api_url+"tenants/get-tenant-report",
	    data:data,
	    beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
	}).then(function(response) {
		if(response.response_data.success == true) {
			var html = '';
			if(response.TotalTenants == 0) {
	        	html += '<a href="javascript:void(0)" class="list-group-item list-group-item-action border-0 bg-light text-left"><div class="fs-16">'+response.response_data.message+'</div></a>';
		    } else {
		    	for (var key in response.tenants) {
	                if (response.tenants.hasOwnProperty(key)) {

	                	var property_name = '';
		                if(response.tenants[key].property != null) {
		                	property_name = response.tenants[key].property.name;
		                }

	                	var address = '';
		                if(response.tenants[key].property != null && response.tenants[key].property.address != null) {
		                	address = response.tenants[key].property.address.address_line1+', '+response.tenants[key].property.address.address_line2+'<br>'+response.tenants[key].property.address.city+', '+response.tenants[key].property.address.state+' '+response.tenants[key].property.address.zip_code;
		                }

	                	var monthly_rent_amount = '-';
	                	var rent_due_date = '-';
	                	var security_deposit_amount = '-';
	                	var pet_policy = '-';
	                	var pet_deposit_amount = '-';
	                	var start_date = '-';
	                	var end_date = '-';
	                	var sd_due_date = '-';
	                	var pd_due_date = '-';

	                	var status = '';
	                    if(response.tenants[key].application_constant == 'draft') {
	                        status += '<span class="badge badge-danger">'+response.tenants[key].application_status+'</span>';
	                    } else if(response.tenants[key].application_constant == 'submitted') {
	                        status += '<span class="badge badge-info">'+response.tenants[key].application_status+'</span>';
	                    } else if(response.tenants[key].application_constant == 'invite_sent') {
	                        status += '<span class="badge badge-primary">'+response.tenants[key].application_status+'</span>';
	                    } else if(response.tenants[key].application_constant == 'signed_up') {
	                        status += '<span class="badge badge-success">'+response.tenants[key].application_status+'</span>';
	                    } else if(response.tenants[key].application_constant == 'tenant_deleted') {
	                        status += '<span class="badge badge-danger">'+response.tenants[key].application_status+'</span>';
	                    } else if(response.tenants[key].application_constant == 'invite_pending') {
	                        status += '<span class="badge badge-info">'+response.tenants[key].application_status+'</span>';
	                    }

	                    if(response.tenants[key].lease_status == 4) {
	                    	status = '<span class="badge badge-danger">Lease Expired</span>';
	                    }

	                    if(response.tenants[key].tenant_leases.length > 0) {
		                	for (var tlkey in response.tenants[key].tenant_leases) {
		                		if (response.tenants[key].tenant_leases.hasOwnProperty(tlkey)) {
		                			var lease = response.tenants[key].tenant_leases[tlkey].lease;
		                			if(lease != null) {
				                		monthly_rent_amount = lease.monthly_rent_amount;
				                		rent_due_date = ordinal_suffix_of(lease.rent_due_date);
				                		security_deposit_amount = lease.security_deposit_amount;
				                		pet_policy = lease.pet_policy_term_label;
				                		pet_deposit_amount = lease.pet_deposit_amount;
				                		start_date = lease.start_date;
				                		end_date = lease.end_date;
				                		sd_due_date = 'Due on '+lease.sd_due_date;
				                		pd_due_date = 'Due on '+lease.pd_due_date;
				                	}

				                	html += '<tr data-id="'+response.tenants[key].id+'">';
				                		html += '<td class="bg-light">'+response.tenants[key].id+'</td>';
				                		html += '<td class="bg-light"><span class="bold-black">'+property_name+'</span><br><small>'+address+'</small></td>';
				                		html += '<td>'+response.tenants[key].user.first_name+' '+response.tenants[key].user.last_name+'</td>';
				                		html += '<td>'+start_date+'</td>';
				                		html += '<td>'+end_date+'</td>';
				                		html += '<td class="text-right bold-black">$'+monthly_rent_amount+'<br><small>Due on '+rent_due_date+' of the month</small></td>';
				                		html += '<td class="text-right bold-black">$'+security_deposit_amount+'<br><small>'+sd_due_date+'</small></td>';
				                		html += '<td>'+pet_policy+'</td>';
				                		html += '<td class="text-right bold-black">$'+pet_deposit_amount+'<br><small>'+pd_due_date+'</small></td>';
				                		html += '<td>'+status+'</td>';
			                        html += '</tr>';
							    }
							}
						} else {
							html += '<tr data-id="'+response.tenants[key].id+'">';
		                		html += '<td class="bg-light">'+response.tenants[key].id+'</td>';
		                		html += '<td class="bg-light"><span class="bold-black">'+property_name+'</span><br><small>'+address+'</small></td>';
		                		html += '<td>'+response.tenants[key].user.first_name+' '+response.tenants[key].user.last_name+'</td>';
		                		html += '<td>'+start_date+'</td>';
		                		html += '<td>'+end_date+'</td>';
		                		html += '<td class="text-right bold-black">$'+monthly_rent_amount+'<br><small>Due on '+rent_due_date+' of the month</small></td>';
		                		html += '<td class="text-right bold-black">$'+security_deposit_amount+'<br><small>'+sd_due_date+'</small></td>';
		                		html += '<td>'+pet_policy+'</td>';
		                		html += '<td class="text-right bold-black">$'+pet_deposit_amount+'<br><small>'+pd_due_date+'</small></td>';
		                		html += '<td>'+status+'</td>';
	                        html += '</tr>';              	
						}
	                }
	            }
	        }

            $('#tenant_report_data_container').html(html);
            var tenant_table = $("#tenant-report-datatable").DataTable({
            	dom: 'Bfrtip',
    			autoWidth: true,
            	paging: false,
            	info: false,
			    scrollY: '600px',
			    scrollX: true,
		        scrollCollapse: true,
		        order: [[0,'desc']],
		        fixedColumns:   {
		            left: 2
		        },
		        buttons: [
		           {
		              extend: 'colvis',
		              text: "Columns Visibility ",
		              collectionLayout: 'two-column',
		              postfixButtons: ['colvisRestore'],
		           }
				],
				language: {
			        buttons: {
			          colvisRestore: "Select All"
			        }
			    },
				initComplete: function () {
		          	//console.log("The Table has been initialized!")
		        }
			});

			$('.buttons-colvis').removeClass('btn-secondary').addClass('btn-primary');
        } else {
            display_error_message(response);
        }
    }, function() {
        // hang on this step if the error occur
    });
}

window.getPaymentSummaryReport = function() {
	var user_id = user.user_details.user_id;
    var tz = jstz.determine();
	var timezone = tz.name();
	
	var data = '';
	data = 'user_timezone='+timezone+'&user_id='+user_id+'&user_service_name='+user_service_name;

	$.ajax({
        method: 'POST',
        url: api_url+"scheduled-payments/payment-summary-report",
        data:data,
	    beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
            if(response.response_data.success == true) {
            	for (var key in response.paymentArray) {
	                if (response.paymentArray.hasOwnProperty(key)) {
	                	var html = '';
	                	html += '<div class="col-12 col-md-6 col-xl-4 property_card">';
	                		html += '<div class="card">';
									html += '<div class="card-header border-bottom">';
										html += '<h5 class="card-title mb-0 mr-3">'+response.paymentArray[key].label+'<span class="float-right">'+response.paymentArray[key].formatted_total+'</span></h5>';
									html += '</div>';
									html += '<div class="card-body">';
										html += '<div class="chart chart-md">';
											html += '<canvas id="'+response.paymentArray[key].label+'"></canvas>';
										html += '</div>';
										html += '<div class="status_container mt-5"></div>';
									html += '</div>';
								html += '</div>';
							html += '</div>';
						html += '</div>';

						var labels = [];
						var data = [];
						var color = [];
						var status_label = '';
						for (var akey in response.paymentArray[key]) {
	                		if (response.paymentArray[key].hasOwnProperty(akey)) {
	                			if (typeof response.paymentArray[key][akey] === 'object') {
	                				labels.push(response.paymentArray[key][akey].label);
	                				data.push(response.paymentArray[key][akey].total);
	                				color.push(response.paymentArray[key][akey].color);
	                				status_label += '<span style="text-align:center;word-break:break-all;padding: 0px 18px 0px 18px;font-size:65%;background-color:'+response.paymentArray[key][akey].color+'">&nbsp;</span><span class="fs-12 bold-grey ml-2 mr-2">'+response.paymentArray[key][akey].label+'</span>';
	                			}
	                		}
	                	}

	                	$('#summary_data_section').append(html);
	                	$('.status_container').html(status_label);

						// Pie chart
						new Chart(document.getElementById(response.paymentArray[key].label), {
							type: "pie",
							data: {
								labels: labels,
								datasets: [{
									data: data,
									backgroundColor: color,
									borderColor: "transparent"
								}]
							},
							options: {
								maintainAspectRatio: false,
								legend: {
									display: false
								},
								tooltips: {
						            callbacks: {
						                label: function(tooltipItem, data) {
						                	var label_text = data.labels[tooltipItem.index];
						                  	var value = data.datasets[0].data[tooltipItem.index];
						                  	value = (new Intl.NumberFormat('en-US', {
								                style: 'currency',
								                currency: 'USD',
								            })).format(value);
								            if(label_text == 'Cancelled Authorized Transfer' || label_text == 'Cancelled Transfer' || label_text == 'Failed Transfer' || label_text == 'Refund') {
								            	return ' '+label_text+' : - ('+value+')';
								            } else {
								            	return ' '+label_text+' : '+value;
								            }
						                }
						           }
						        },
							}
						});
	                }
	            }
            } else {
            	display_error_message(response);
            }
        }
    });
}

window.getTransactionReport = function() {
	openLoadingDialog($('#transaction_report_data_container'));
	var user_id = user.user_details.user_id;
    var tz = jstz.determine();
	var timezone = tz.name();
	
	var data = '';
	data = 'user_timezone='+timezone+'&user_id='+user_id+'&user_service_name='+user_service_name;
	
	$.ajax({
	    method: 'POST',
	    url: api_url+"scheduled-payments/transaction-report",
	    data:data,
	    beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
	}).then(function(response) {
		if(response.response_data.success == true) {
			var html = '';
			if(response.TotalscheduledPayments == 0) {
	        	html += '<a href="javascript:void(0)" class="list-group-item list-group-item-action border-0 bg-light text-left"><div class="fs-16">'+response.response_data.message+'</div></a>';
		    } else {
		    	for (var key in response.scheduledPayments) {
	                if (response.scheduledPayments.hasOwnProperty(key)) {

	                	// Proeprty Detail
	                	var property_name = response.scheduledPayments[key].property.name;
	                	var property_owner = response.scheduledPayments[key].po_name;

	                	var property_tenant = '';
	                	var tenant_detail = '';
	                	var tenant_name = '';
	                	var tenant_email = '';
	                	if(response.scheduledPayments[key].tenant_detail) {
		                	for (var tkey in response.scheduledPayments[key].tenant_detail) {
	                            if (response.scheduledPayments[key].tenant_detail.hasOwnProperty(tkey)) {
	                                tenant_detail += response.scheduledPayments[key].tenant_detail[tkey].first_name+' '+response.scheduledPayments[key].tenant_detail[tkey].last_name+',';
	                                tenant_name += response.scheduledPayments[key].tenant_detail[tkey].first_name+' '+response.scheduledPayments[key].tenant_detail[tkey].last_name+'<small>(Tenant)</small>';
	                                tenant_email += response.scheduledPayments[key].tenant_detail[tkey].email;
	                            }
	                        }
                        	tenant_detail = tenant_detail.replace(/,\s*$/, "");
                        	property_tenant += tenant_detail;
                        }

                        // Source and Destination
                        var source_name = '';
                        var source_email = '';
                        var destination_name = '';
                        var destination_email = '';

                        if(response.scheduledPayments[key].payment_type == 1) {
                        	source_name = response.scheduledPayments[key].po_name+'<small>(Property Owner)</small>';
                        	source_email = response.scheduledPayments[key].po_email;
                        	destination_name = 'Rent Synergy';
                        } else if(response.scheduledPayments[key].payment_type == 2 || response.scheduledPayments[key].payment_type == 7) {
                        	source_name = tenant_name;
                        	source_email = tenant_email;
                        	destination_name = response.scheduledPayments[key].po_name+'<small>(Property Owner)</small>';
                        	destination_email = response.scheduledPayments[key].po_email;
                        } else if(response.scheduledPayments[key].payment_type == 3 || response.scheduledPayments[key].payment_type == 4) {
                        	source_name = tenant_name;
                        	source_email = tenant_email;
                        	destination_name = 'Rent Synergy';
                        } else if(response.scheduledPayments[key].payment_type == 8) {
                        	source_name = response.scheduledPayments[key].po_name+'<small>(Property Owner)</small>';
                        	source_email = response.scheduledPayments[key].po_email;
                        	destination_name = 'Rent Synergy';
                        } else if(response.scheduledPayments[key].payment_type == 9) {
                        	source_name = 'Rent Synergy';
                        	destination_name = tenant_name;
                        	destination_email = tenant_email;
                        }

                        // Payment Due Date
                        var payment_due_date = response.scheduledPayments[key].payment_due_date;
	                	if(response.scheduledPayments[key].payment_due_date == null) {
	                		payment_due_date = '';
	                	}

	                	// Authorized Date
                        var authorized_date = response.scheduledPayments[key].authorized_date;
                        var authorized_time = response.scheduledPayments[key].authorized_time;
	                	if(response.scheduledPayments[key].authorized_date == null) {
	                		authorized_date = '';
	                		authorized_time = '';
	                	}

	                	// Transaction Date
                        var transaction_start_date = response.scheduledPayments[key].transaction_start_date;
                        var transaction_start_time = response.scheduledPayments[key].transaction_start_time;
                        var transaction_end_date = response.scheduledPayments[key].transaction_end_date;
                        var transaction_end_time = response.scheduledPayments[key].transaction_end_time;

	                	// Payment Method
                        var bank_data = '';
		                var payment_details = response.scheduledPayments[key].payment_details;
		                if(Object.keys(payment_details).length !== 0) {
	                		bank_data = response.scheduledPayments[key].payment_details.bank_name+'('+response.scheduledPayments[key].payment_details.account_number+')';
		                }

		                // Coupon Data
	                	var coupon_code = response.scheduledPayments[key].coupon_code.code;
	                	var coupon_amount = '$'+response.scheduledPayments[key].coupon_amount;
	                	if(response.scheduledPayments[key].coupon_id == 0) {
	                		coupon_code = '';
	                		coupon_amount = '';
	                	}

	                	// Status
	                	var application_status = '';
	                    if(response.scheduledPayments[key].application_constant == 'pending_authorization') {
	                        application_status += '<span class="badge badge-danger fs-12">'+response.scheduledPayments[key].application_status+'</span>';
	                    } else if(response.scheduledPayments[key].application_constant == 'transfer_authorized') {
	                        application_status += '<span class="badge badge-warning fs-12">'+response.scheduledPayments[key].application_status+'</span>';
	                    } else if(response.scheduledPayments[key].application_constant == 'transfer_in_progress') {
	                        application_status += '<span class="badge badge-warning fs-12">'+response.scheduledPayments[key].application_status+'</span>';
	                    } else if(response.scheduledPayments[key].application_constant == 'processed') {
	                        application_status += '<span class="badge badge-success fs-12">'+response.scheduledPayments[key].application_status+'</span>';
	                    } else if(response.scheduledPayments[key].application_constant == 'cancelled_authorized_transfer' || response.scheduledPayments[key].application_constant == 'cancelled_transfer' || response.scheduledPayments[key].application_constant == 'failed_transfer') {
	                        application_status += '<span class="badge badge-danger fs-12">'+response.scheduledPayments[key].application_status+'</span>';
	                    } else if(response.scheduledPayments[key].application_constant == 'processing') {
	                        application_status += '<span class="badge badge-primary fs-12">'+response.scheduledPayments[key].application_status+'</span>';
	                    }

	                    // Add exclamation button, when click display related transaction detail
	                	var exclamation = '';
	                	if(application_status != '') {
	                		exclamation = '<i data-id='+response.scheduledPayments[key].id+' data-transferID='+response.scheduledPayments[key].dwolla_transferID+' class="align-middle mr-2 fas fa-fw fa-info-circle ml-2 get_related_transaction" data-container="body" data-toggle="popover"></i>';
	                	}

                        var property_setup_fee = '<span>'+response.scheduledPayments[key].property_setup_fee+'</span>';
                        var security_deposit_fee = '<span>'+response.scheduledPayments[key].security_deposit_fee+'</span>';
                        var pet_deposit_fee = '<span>'+response.scheduledPayments[key].pet_deposit_fee+'</span>';
                        var subscription_fee = '<span>'+response.scheduledPayments[key].subscription_fee+'</span>';
                        var rent_fee = '<span>'+response.scheduledPayments[key].rent_fee+'</span>';

                        var rent_due_by = '';
                        var rent_amount = '';
                        var no_of_days = '';
                        var late_fee_per_day = '';
                        var outstanding_late_fee = '';
                        var late_fee_waived = '';
                        var total_rent_due = '';
                        if(response.scheduledPayments[key].is_late_fee_enabled == 1 && response.scheduledPayments[key].waive_late_fee > 0 && (response.scheduledPayments[key].payment_type == 2 || response.scheduledPayments[key].payment_type == 7)) {
	                        /*rent_fee += '<div><small>(A) Rent Due by '+response.scheduledPayments[key].payment_due_date+' : $'+response.scheduledPayments[key].total+'</small></div>';
	                        rent_fee += '<div ml-4><small>(1) No. of days late after '+response.scheduledPayments[key].lateFeeafterDays+' day of the month: '+response.scheduledPayments[key].no_of_days+'</small></div>';
	                        rent_fee += '<div ml-4><small>(2) Late fee applied per day: $'+response.scheduledPayments[key].price_per_day+'</small></div>';
	                        rent_fee += '<div><small>(B) Outstanding late fee (1) x (2): $'+response.scheduledPayments[key].late_fee_amount+'</small></div>';
	                        rent_fee += '<div><small>(C) Late fee waived: $'+response.scheduledPayments[key].waived_amount+'</small></div>';
	                        rent_fee += '<div><small>(D) Total (A) + (B) - (C): $'+response.scheduledPayments[key].final_total+'</small></div>';*/

	                        rent_due_by = response.scheduledPayments[key].payment_due_date;
	                        rent_amount = '$'+response.scheduledPayments[key].total;
	                        no_of_days = response.scheduledPayments[key].no_of_days;
	                        late_fee_per_day = '$'+response.scheduledPayments[key].price_per_day;
	                        if(response.scheduledPayments[key].late_fee_amount > 0) {
	                        	outstanding_late_fee = '$'+response.scheduledPayments[key].late_fee_amount;
	                        }
	                        if(response.scheduledPayments[key].waived_amount > 0) {
	                        	late_fee_waived = '$'+response.scheduledPayments[key].waived_amount;
	                        }
	                        total_rent_due = '$'+response.scheduledPayments[key].final_total;
	                    }
                 
	                	html += '<tr data-id="'+response.scheduledPayments[key].id+'">';
	                		html += '<td class="text-left bg-light">'+response.scheduledPayments[key].id+'</td>';
	                		html += '<td class="bg-light"><span class="badge badge-primary fs-12">'+response.scheduledPayments[key].payment_type_label+'</span></td>';
                            html += '<td class="bg-light">'+property_name+'</td>';
                            html += '<td>'+property_owner+'</td>';
                            html += '<td>'+property_tenant+'</td>';
                            html += '<td>'+payment_due_date+'</td>';
                            html += '<td>'+authorized_date+'<br><small>'+authorized_time+'</small></td>';
                            html += '<td>'+transaction_start_date+'<br><small>'+transaction_start_time+'</small></td>';
                            html += '<td>'+transaction_end_date+'<br><small>'+transaction_end_time+'</small></td>';
                            html += '<td>'+application_status+'<span class="ml-2">'+exclamation+'</span></td>';
                            html += '<td>'+source_name+'</td>';
                            html += '<td>'+source_email+'</td>';
                            html += '<td>'+destination_name+'</td>';
                            html += '<td>'+destination_email+'</td>';
                            html += '<td>'+bank_data+'</td>';
                            html += '<td>'+coupon_code+'</td>';
                            html += '<td>'+coupon_amount+'</td>';
                            html += '<td class="text-right">'+property_setup_fee+'</td>';
                            html += '<td class="text-right">'+security_deposit_fee+'</td>';
                            html += '<td class="text-right">'+pet_deposit_fee+'</td>';
                            html += '<td class="text-right">'+subscription_fee+'</td>';
                            html += '<td class="text-right">'+rent_fee+'</td>';

                            html += '<td>'+rent_due_by+'</td>';
                            html += '<td class="text-right">'+rent_amount+'</td>';
                            html += '<td class="text-right">'+no_of_days+'</td>';
                            html += '<td class="text-right">'+late_fee_per_day+'</td>';
                            html += '<td class="text-right">'+outstanding_late_fee+'</td>';
                            html += '<td class="text-right">'+late_fee_waived+'</td>';
                            html += '<td class="text-right">'+total_rent_due+'</td>';
                        html += '</tr>';
	                }
	            }
	        }

	        $('#property_setup_fee_total').html(response.property_setup_fee_total);
	        $('#security_deposit_fee_total').html(response.security_deposit_fee_total);
	        $('#pet_deposit_fee_total').html(response.pet_deposit_fee_total);
	        $('#subscription_fee_total').html(response.subscription_fee_total);
	        $('#rent_fee_total').html(response.rent_fee_total);

	        $('#late_fee_amount_total').html(response.late_fee_amount_total);
	        $('#waived_amount_total').html(response.waived_amount_total);
	        $('#main_total').html(response.main_total);

            $('#transaction_report_data_container').html(html);
            $('#transaction-report-datatable thead, #transaction-report-datatable tfoot').removeClass('d-none');
            $('#transaction-report-datatable').addClass('table-bordered');

            if ($.fn.DataTable.isDataTable("#transaction-report-datatable")) {
	            $("#transaction-report-datatable").DataTable().destroy();
	        }

            var buttonCommon = {
		        exportOptions: {
		            format: {
		                body: function ( data, row, column, node ) {
		                	// Replace <br> with space
		                	var regex = /<br\s*[\/]?>/gi;
		                	data = data.replace(regex, " ");
		                	//data = data.replace(regex, "\n");

		                	// Remove all html tags from data and Remove $ from amount
		                	var regex = /(<([^>]+)>)/ig;
		                    var amt_col = [16,17,18,19,20,21,23,25,26,27,28];  
		                    if(amt_col.indexOf(column) !== -1) {
		                    	var data = data.replace(regex, "");
		                    	return data.replace( /[$,]/g, '' );
		                    } else {
		                    	return data.replace(regex, "");
		                    }
		                }
		            }
		        }
		    };

            var transaction_table = $("#transaction-report-datatable").DataTable({
            	dom: 'Bfrtip',
    			autoWidth: true,
            	paging: false,
            	info: false,
			    scrollY: '425px',
			    scrollX: true,
		        scrollCollapse: true,
		        order: [[0,'desc']],
		        fixedColumns:   {
		            left: 3
		        },
		        buttons: [
		        	$.extend( true, {}, buttonCommon, {
		                extend: 'excelHtml5',
			            text: 'Download Excel',
			            title: 'Transaction Report',
			            message:'Disclaimer: Rent synergy has made every attempt to ensure the correctness of this report, based on the data entered and available with us. Before the final consumption of this report, we recommend you to verify and validate this data with your accounting team.',
			            autoFilter: true,
			            header: true,
			            footer: true,
			            exportOptions: {
							//columns: ':visible'
		                    columns: ':visible:not(.notexport)'
		                },
		                customize: function(xlsx) {
					        var sheet = xlsx.xl.worksheets['sheet1.xml'];
					        datatable_excel_common_formatting(xlsx,sheet);
					    }
		            }),
		            $.extend( true, {}, buttonCommon, {
		                extend: 'colvis',
		              	text: "Columns Visibility ",
		              	collectionLayout: 'two-column',
		              	postfixButtons: ['colvisRestore'],
		            }),
				],
				language: {
			        buttons: {
			          colvisRestore: "Select All"
			        }
			    }
			});

			$('.buttons-html5').removeClass('btn-secondary').addClass('btn-primary');
			$('.buttons-colvis').removeClass('btn-secondary').addClass('btn-primary');
			$('.dt-buttons > button').addClass('mr-2');
			$('.btn-group > .btn:not(:last-child):not(.dropdown-toggle), .btn-group > .btn-group:not(:first-child) > .btn').css({
				'border-radius': '5px'
			});

            // Get Related Transaction Detail
            var title = 'Transaction Detail';
            var type = 'RelatedTransaction';
            openPopoverModal(title,type);
        } else {
            display_error_message(response);
        }
    }, function() {
        // hang on this step if the error occur
    });
}

window.getPaymentReceiptReport = function(date_macro) {
	if ($.fn.DataTable.isDataTable("#payment-receipt-report-datatable")) {
        $("#payment-receipt-report-datatable").DataTable().destroy();
    }
	$('#payment_receipt_report_data_container').html('');
	openLoadingDialog($('#payment_receipt_report_data_container'));
	var user_id = user.user_details.user_id;
    var tz = jstz.determine();
	var timezone = tz.name();
	
	var data = '';
	data = 'user_timezone='+timezone+'&user_id='+user_id+'&user_service_name='+user_service_name+'&date_macro='+date_macro;
	
	$.ajax({
	    method: 'POST',
	    url: api_url+"scheduled-payments/payment-receipt-report",
	    data:data,
	    beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
	}).then(function(response) {
		if(response.response_data.success == true) {
			var html = '';
			if(response.TotalscheduledPayments == 0) {
	        	html += '<a href="javascript:void(0)" class="list-group-item list-group-item-action border-0 bg-light text-left"><div class="fs-16">'+response.response_data.message+'</div></a>';
		    } else {
		    	for (var key in response.scheduledPayments) {
	                if (response.scheduledPayments.hasOwnProperty(key)) {

	                	// Proeprty Detail
	                	var property_name = response.scheduledPayments[key].property.name;
	                	var property_owner = response.scheduledPayments[key].po_name;
	                	var address = '';
		                if(response.scheduledPayments[key].property != null && response.scheduledPayments[key].property.address != null) {
		                	address = response.scheduledPayments[key].property.address.address_line1+', '+response.scheduledPayments[key].property.address.address_line2+'<br>'+response.scheduledPayments[key].property.address.city+', '+response.scheduledPayments[key].property.address.state+' '+response.scheduledPayments[key].property.address.zip_code;
		                }

	                	var property_tenant = '';
	                	var tenant_detail = '';
	                	var tenant_name = '';
	                	var tenant_email = '';
	                	if(response.scheduledPayments[key].tenant_detail) {
		                	for (var tkey in response.scheduledPayments[key].tenant_detail) {
	                            if (response.scheduledPayments[key].tenant_detail.hasOwnProperty(tkey)) {
	                                tenant_detail += response.scheduledPayments[key].tenant_detail[tkey].first_name+' '+response.scheduledPayments[key].tenant_detail[tkey].last_name+',';
	                                tenant_name += response.scheduledPayments[key].tenant_detail[tkey].first_name+' '+response.scheduledPayments[key].tenant_detail[tkey].last_name+'<small>(Tenant)</small>';
	                                tenant_email += response.scheduledPayments[key].tenant_detail[tkey].email;
	                            }
	                        }
                        	tenant_detail = tenant_detail.replace(/,\s*$/, "");
                        	property_tenant += tenant_detail;
                        }

                        var property_detail = property_name+'<br>'+'Current Owner:&nbsp;'+property_owner+'<br>Current Tenant:&nbsp;'+property_tenant;

                        // Payment Method
                        var bank_data = '';
		                var payment_details = response.scheduledPayments[key].payment_details;
		                if(payment_details != null) {
		                	if(Object.keys(payment_details).length !== 0) {
	                			bank_data = response.scheduledPayments[key].payment_details.bank_name+'('+response.scheduledPayments[key].payment_details.account_number+')';
		                	}
		                }

                        // Source and Destination
                        var source_name = '';
                        var source_email = '';
                        var source_bank = '';
                        var destination_name = '';
                        var destination_email = '';
                        var destination_bank = '';

                        var company_name = 'Rent Synergy';
                        if(response.scheduledPayments[key].payment_type == 1) {
                        	source_name = response.scheduledPayments[key].po_name+'<small>(Property Owner)</small>';
                        	source_email = response.scheduledPayments[key].po_email;
                        	source_bank = bank_data;
                        	destination_name = company_name;
                        } else if(response.scheduledPayments[key].payment_type == 2 || response.scheduledPayments[key].payment_type == 7) {
                        	source_name = tenant_name;
                        	source_email = tenant_email;
                        	source_bank = bank_data;
                        	destination_name = response.scheduledPayments[key].po_name+'<small>(Property Owner)</small>';
                        	destination_email = response.scheduledPayments[key].po_email;
                        } else if(response.scheduledPayments[key].payment_type == 3 || response.scheduledPayments[key].payment_type == 4) {
                        	source_name = tenant_name;
                        	source_email = tenant_email;
                        	source_bank = bank_data;
                        	destination_name = company_name;
                        } else if(response.scheduledPayments[key].payment_type == 8) {
                        	source_name = response.scheduledPayments[key].po_name+'<small>(Property Owner)</small>';
                        	source_email = response.scheduledPayments[key].po_email;
                        	source_bank = bank_data;
                        	destination_name = company_name;
                        } else if(response.scheduledPayments[key].payment_type == 9) {
                        	source_name = company_name;
                        	destination_name = tenant_name;
                        	destination_email = tenant_email;
                        	destination_bank = bank_data;
                        }

                        var source_data = source_name+'<br>'+source_email+'<br>'+source_bank;
                        var destination_data = destination_name+'<br>'+destination_email+'<br>'+destination_bank;
                        
                        // Payment Due Date
                        var payment_due_date = response.scheduledPayments[key].payment_due_date;
	                	if(response.scheduledPayments[key].payment_due_date == null) {
	                		payment_due_date = '';
	                	}

	                	// Authorized Date
                        var authorized_date = response.scheduledPayments[key].authorized_date;
                        var authorized_time = response.scheduledPayments[key].authorized_time;
	                	if(response.scheduledPayments[key].authorized_date == null) {
	                		authorized_date = '';
	                		authorized_time = '';
	                	}

	                	// Initiated date Date
                        var initiated_date = response.scheduledPayments[key].initiated_date;
                        var initiated_time = response.scheduledPayments[key].initiated_time;
	                	if(response.scheduledPayments[key].initiated_date == null) {
	                		initiated_date = '';
	                		initiated_time = '';
	                	}

	                	// Completed date Date
                        var completed_date = response.scheduledPayments[key].completed_date;
                        var completed_time = response.scheduledPayments[key].completed_time;
	                	if(response.scheduledPayments[key].completed_date == null) {
	                		completed_date = '';
	                		completed_time = '';
	                	}

	                	// Coupon Data
	                	var coupon_code = response.scheduledPayments[key].coupon_code.code;
	                	var coupon_amount = '$'+response.scheduledPayments[key].coupon_amount;
	                	if(response.scheduledPayments[key].coupon_id == 0) {
	                		coupon_code = '';
	                		coupon_amount = '';
	                	}

	                	var coupon_data = coupon_code+'<br>'+coupon_amount;

	                	// Status
	                	var application_status = '';
	                    if(response.scheduledPayments[key].application_constant == 'pending_authorization') {
	                        application_status += '<span class="badge badge-danger fs-12">'+response.scheduledPayments[key].application_status+'</span>';
	                    } else if(response.scheduledPayments[key].application_constant == 'transfer_authorized') {
	                        application_status += '<span class="badge badge-warning fs-12">'+response.scheduledPayments[key].application_status+'</span>';
	                    } else if(response.scheduledPayments[key].application_constant == 'transfer_in_progress') {
	                        application_status += '<span class="badge badge-warning fs-12">'+response.scheduledPayments[key].application_status+'</span>';
	                    } else if(response.scheduledPayments[key].application_constant == 'processed') {
	                        application_status += '<span class="badge badge-success fs-12">'+response.scheduledPayments[key].application_status+'</span>';
	                    } else if(response.scheduledPayments[key].application_constant == 'cancelled_authorized_transfer' || response.scheduledPayments[key].application_constant == 'cancelled_transfer' || response.scheduledPayments[key].application_constant == 'failed_transfer') {
	                        application_status += '<span class="badge badge-danger fs-12">'+response.scheduledPayments[key].application_status+'</span>';
	                    } else if(response.scheduledPayments[key].application_constant == 'processing') {
	                        application_status += '<span class="badge badge-primary fs-12">'+response.scheduledPayments[key].application_status+'</span>';
	                    }

	                    var kebab = '<div class="text-center"><div class="input-group-prepend"><i class="align-middle mr-2 fas fa-fw fa-ellipsis-v" data-toggle="dropdown" aria-expanded="false"></i><div class="dropdown-menu dropdown-menu-right" style="">';
	                    if(user.permissionData['PROPERTIES']["feature_items['cancel_transaction']"]) {
		                    if(response.scheduledPayments[key].application_constant == 'transfer_authorized') {
		                    	kebab += '<a class="dropdown-item cancel_payment" data-id="'+response.scheduledPayments[key].id+'" data-type="authorized" href="javascript:void(0)">Cancel</a>';
		                    } else if(response.scheduledPayments[key].application_constant == 'transfer_in_progress') {
		                    	kebab += '<a class="dropdown-item cancel_payment" data-id="'+response.scheduledPayments[key].id+'" data-type="dwolla_transfer" href="javascript:void(0)">Cancel</a>';
		                    }
		                }

		                if(user.permissionData['PROPERTIES']["feature_items['regenerate_transaction']"]) {
		                    if(user_service_name == sa_role && response.scheduledPayments[key].regenerate_transaction == 1) {
	                    		kebab += '<a class="dropdown-item regenerate_transaction" data-id="'+response.scheduledPayments[key].id+'" data-payment-type="'+response.scheduledPayments[key].payment_type+'" href="javascript:void(0)">Regenerate Transaction</a>';
	                    	}
	                    }

	                    if(user_service_name == po_role && response.scheduledPayments[key].authorize_subscription_fee == 1) {
                    		kebab += '<a class="dropdown-item authorize_subscription_fee" data-id="'+response.scheduledPayments[key].id+'" data-property-id="'+response.scheduledPayments[key].property_id+'" data-payment-type="'+response.scheduledPayments[key].payment_type+'" href="javascript:void(0)">Authorize Subscription Fee</a>';
                    	}

                    	kebab += '</div></div></div>';

	                    // Add exclamation button, when click display related transaction detail
	                	var exclamation = '';
	                	if(application_status != '') {
	                		exclamation = '<i data-id='+response.scheduledPayments[key].id+' data-transferID='+response.scheduledPayments[key].dwolla_transferID+' class="align-middle mr-2 fas fa-fw fa-info-circle ml-2 get_related_transaction" data-container="body" data-toggle="popover"></i>';
	                	}

                        var property_setup_fee = '<span>'+response.scheduledPayments[key].property_setup_fee+'</span>';
                        var security_deposit_fee = '<span>'+response.scheduledPayments[key].security_deposit_fee+'</span>';
                        var pet_deposit_fee = '<span>'+response.scheduledPayments[key].pet_deposit_fee+'</span>';
                        var subscription_fee = '<span>'+response.scheduledPayments[key].subscription_fee+'</span>';
                        var rent_fee = '<span>'+response.scheduledPayments[key].rent_fee+'</span>';

                        var rent_due_by = '';
                        var rent_amount = '';
                        var no_of_days = '';
                        var late_fee_per_day = '';
                        var outstanding_late_fee = '';
                        var late_fee_waived = '';
                        var total_rent_due = '';
                        if(response.scheduledPayments[key].is_late_fee_enabled == 1 && response.scheduledPayments[key].waive_late_fee > 0 && (response.scheduledPayments[key].payment_type == 2 || response.scheduledPayments[key].payment_type == 7)) {
	                        rent_due_by = response.scheduledPayments[key].payment_due_date;
	                        rent_amount = '$'+response.scheduledPayments[key].total;
	                        no_of_days = response.scheduledPayments[key].no_of_days;
	                        late_fee_per_day = '$'+response.scheduledPayments[key].price_per_day;
	                        if(response.scheduledPayments[key].late_fee_amount > 0) {
	                        	outstanding_late_fee = '$'+response.scheduledPayments[key].late_fee_amount;
	                        }
	                        if(response.scheduledPayments[key].waived_amount > 0) {
	                        	late_fee_waived = '$'+response.scheduledPayments[key].waived_amount;
	                        }
	                        total_rent_due = '$'+response.scheduledPayments[key].final_total;
	                    }
                 
	                	html += '<tr data-id="'+response.scheduledPayments[key].id+'">';
	                		html += '<td class="text-left bg-light">'+response.scheduledPayments[key].id+'</td>';
	                		html += '<td class="bg-light">'+property_detail+'</td>';
	                		html += '<td class="bg-light"><span class="badge badge-primary fs-12">'+response.scheduledPayments[key].payment_type_label+'</span></td>';
                            html += '<td>'+application_status+'<span class="ml-2">'+exclamation+'</span></td>';
                            html += '<td class="text-right">'+response.scheduledPayments[key].payments+'</td>';
                            html += '<td class="text-right">'+response.scheduledPayments[key].receipts+'</td>';
                            html += '<td>'+payment_due_date+'</td>';
                            html += '<td>'+authorized_date+'<br><small>'+authorized_time+'</small></td>';
                            html += '<td>'+initiated_date+'<br><small>'+initiated_time+'</small></td>';
                            html += '<td>'+completed_date+'<br><small>'+completed_time+'</small></td>';
                            html += '<td>'+source_data+'</td>';
                            html += '<td>'+destination_data+'</td>';
                            html += '<td>'+coupon_data+'</td>';
                            html += '<td>-</td>';
                            //html += '<td><span class="accordion_arrow">'+kebab+'</span></td>';
                        html += '</tr>';
	                }
	            }
	        }

	        $('#payment_receipt_report_data_container').html(html);
            $('#payment-receipt-report-datatable thead, #payment-receipt-report-datatable tfoot').removeClass('d-none');
            $('#payment-receipt-report-datatable').addClass('table-bordered');

            $('#payment_total').html(response.payment_total);
	        $('#receipt_total').html(response.receipt_total);

	        var buttonCommon = {
		        exportOptions: {
		            format: {
		                body: function ( data, row, column, node ) {
		                	// Replace <br> with space
		                	var regex = /<br\s*[\/]?>/gi;
		                	data = data.replace(regex, " ");
		                	//data = data.replace(regex, "\n");

		                	// Remove all html tags from data and Remove $ from amount
		                	var regex = /(<([^>]+)>)/ig;
		                    var amt_col = [4,5];  
		                    if(amt_col.indexOf(column) !== -1) {
		                    	var data = data.replace(regex, "");
		                    	return data.replace( /[$,]/g, '' );
		                    } else {
		                    	return data.replace(regex, "");
		                    }
		                }
		            }
		        }
		    };

            var transaction_table = $("#payment-receipt-report-datatable").DataTable({
            	dom: 'Bfrtip',
    			autoWidth: true,
            	paging: false,
            	info: false,
			    scrollY: '425px',
			    scrollX: true,
		        scrollCollapse: true,
		        order: [[0,'desc']],
		        fixedColumns:   {
		            left: 3
		        },
		        "columnDefs": [
				    { "visible": false, "targets": [7, 8, 9, 10, 11, 12, 13] }
				],
		        buttons: [
		        	$.extend( true, {}, buttonCommon, {
		                extend: 'excelHtml5',
			            text: 'Download Excel',
			            title: 'Transaction Report',
			            message:'Disclaimer: Rent synergy has made every attempt to ensure the correctness of this report, based on the data entered and available with us. Before the final consumption of this report, we recommend you to verify and validate this data with your accounting team.',
			            autoFilter: true,
			            header: true,
			            footer: true,
			            exportOptions: {
							//columns: ':visible'
		                    columns: ':visible:not(.notexport)'
		                },
		                customize: function(xlsx) {
					        var sheet = xlsx.xl.worksheets['sheet1.xml'];
					        datatable_excel_common_formatting(xlsx,sheet);
					    }
		            }),
		            $.extend( true, {}, buttonCommon, {
		                extend: 'colvis',
		              	text: "Columns Visibility ",
		              	collectionLayout: 'two-column',
		              	postfixButtons: ['colvisRestore'],
		            }),
				],
				language: {
			        buttons: {
			          colvisRestore: "Select All"
			        }
			    }
			});

			$('.buttons-html5').removeClass('btn-secondary').addClass('btn-primary');
			$('.buttons-colvis').removeClass('btn-secondary').addClass('btn-primary');

			var dd = '';
			dd += '<div class="form-button ml-2">';
			    dd += '<div class="dropdown">';
			         dd += '<button type="button" class="btn btn-sm pmd-btn-fab pmd-btn-raised pmd-ripple-effect btn-primary dropdown-toggle date-macro" data-toggle="dropdown" data-placement="bottom" data-original-title="Date macros"><i class="material-icons md-dark pmd-sm">date_range</i></button>';
			        dd += '<ul class="dropdown-menu dropdown-menu-right" id="date_macros">';
			            dd += '<li><a href="javascript:void(0)">All Dates</a></li>';
			            dd += '<li><a href="javascript:void(0)">Today</a></li>';
			            dd += '<li><a href="javascript:void(0)">This Week</a></li>';
			            dd += '<li><a href="javascript:void(0)">This Month</a></li>';
			            dd += '<li><a href="javascript:void(0)">This Quarter</a></li>';
			            dd += '<li><a href="javascript:void(0)">This Year</a></li>';
			            dd += '<li><a href="javascript:void(0)">Last Week</a></li>';
			            dd += '<li><a href="javascript:void(0)">Last Month</a></li>';
			            dd += '<li><a href="javascript:void(0)">Last Quarter</a></li>';
			            dd += '<li><a href="javascript:void(0)">Last Year</a></li>';
			        dd += '</ul>';
			    dd += '</div>';
			dd += '</div>';

			$('.dt-buttons').append(dd);

			$('.dt-buttons > button').addClass('mr-2');
			$('.btn-group > .btn:not(:last-child):not(.dropdown-toggle), .btn-group > .btn-group:not(:first-child) > .btn').css({
				'border-radius': '5px'
			});

			// Get Related Transaction Detail
            var title = 'Transaction Detail';
            var type = 'RelatedTransaction';
            openPopoverModal(title,type);
        } else {
            display_error_message(response);
        }
    }, function() {
        // hang on this step if the error occur
    });
}

window.getPaymentReceiptCardReport = function(date_macro) {
	$('#pr_report_data_container').html('');
	openLoadingDialog($('#pr_report_data_container'));
	var user_id = user.user_details.user_id;
    var tz = jstz.determine();
	var timezone = tz.name();
	
	var data = '';
	data = 'user_timezone='+timezone+'&user_id='+user_id+'&user_service_name='+user_service_name+'&date_macro='+date_macro;
	
	$.ajax({
	    method: 'POST',
	    url: api_url+"scheduled-payments/payment-receipt-report",
	    data:data,
	    beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
	}).then(function(response) {
		if(response.response_data.success == true) {
			var html = '';
			var card_html = '';
			if(response.TotalscheduledPayments == 0) {
	        	html += '<a href="javascript:void(0)" class="list-group-item list-group-item-action border-0 bg-light text-left"><div class="fs-16">'+response.response_data.message+'</div></a>';
		    } else {

		    	card_html += '<div class="row overview_tiles">';
					card_html += '<div class="col-12 col-md-4 col-lg-4">';
						card_html += '<div class="bg-light light-border mb-1">';
							card_html += '<div class="media ml-2">';
								card_html += '<div class="d-inline-block mt-2 mr-3"><img src="icon/tiles/money.svg" class="filter-7"/></div>';
								card_html += '<div class="media-body mt-2">';
									card_html += '<div class="mb-1 bold-black">Total Balance</div>';
									if(response.negative == 1) {
										card_html += '<h3 class="mb-2 red">('+response.total+')</h3>';
									} else {
										card_html += '<h3 class="mb-2">'+response.total+'</h3>';
									}
								card_html += '</div>';
							card_html += '</div>';
						card_html += '</div>';
					card_html += '</div>';
					card_html += '<div class="col-12 col-md-4 col-lg-4">';
						card_html += '<div class="bg-light light-border mb-1">';
							card_html += '<div class="media ml-2">';
								card_html += '<div class="d-inline-block mt-2 mr-3"><img src="icon/tiles/download.svg" class="filter-10"/></div>';
								card_html += '<div class="media-body mt-2">';
									card_html += '<div class="mb-1 bold-black">Total Income</div>';
									card_html += '<h3 class="mb-2">'+response.payment_total+'</h3>';
								card_html += '</div>';
							card_html += '</div>';
						card_html += '</div>';
					card_html += '</div>';
					card_html += '<div class="col-12 col-md-4 col-lg-4">';
						card_html += '<div class="bg-light light-border mb-1">';
							card_html += '<div class="media ml-2">';
								card_html += '<div class="d-inline-block mt-2 mr-3"><img src="icon/tiles/upload.svg" class="filter-11"/></div>';
								card_html += '<div class="media-body mt-2">';
									card_html += '<div class="mb-1 bold-black">Total Expenses</div>';
									card_html += '<h3 class="mb-2 red">('+response.receipt_total+')</h3>';
								card_html += '</div>';
							card_html += '</div>';
						card_html += '</div>';
					card_html += '</div>';
				card_html += '</div>';

		    	for (var key in response.scheduledPayments) {
	                if (response.scheduledPayments.hasOwnProperty(key)) {
	                	// Proeprty Detail
	                	var property_name = response.scheduledPayments[key].property.name;
	                	var property_owner = response.scheduledPayments[key].po_name;
	                	var address = '';
		                if(response.scheduledPayments[key].property != null && response.scheduledPayments[key].property.address != null) {
		                	if(response.scheduledPayments[key].property.address.address_line2 != '') {
		                		address = response.scheduledPayments[key].property.address.address_line1+'<br>'+response.scheduledPayments[key].property.address.address_line2+'<br>'+response.scheduledPayments[key].property.address.city+', '+response.scheduledPayments[key].property.address.state+' '+response.scheduledPayments[key].property.address.zip_code;
		                	} else {
		                		address = response.scheduledPayments[key].property.address.address_line1+'<br>'+response.scheduledPayments[key].property.address.city+', '+response.scheduledPayments[key].property.address.state+' '+response.scheduledPayments[key].property.address.zip_code;
		                	}
		                }

		                var property_tenant = '';
	                	var tenant_detail = '';
	                	var tenant_name = '';
	                	var tenant_email = '';
	                	if(response.scheduledPayments[key].tenant_detail) {
		                	for (var tkey in response.scheduledPayments[key].tenant_detail) {
	                            if (response.scheduledPayments[key].tenant_detail.hasOwnProperty(tkey)) {
	                                tenant_detail += response.scheduledPayments[key].tenant_detail[tkey].first_name+' '+response.scheduledPayments[key].tenant_detail[tkey].last_name+',';
	                                tenant_name += response.scheduledPayments[key].tenant_detail[tkey].first_name+' '+response.scheduledPayments[key].tenant_detail[tkey].last_name+'<small>(Tenant)</small>';
	                                tenant_email += response.scheduledPayments[key].tenant_detail[tkey].email;
	                            }
	                        }
                        	tenant_detail = tenant_detail.replace(/,\s*$/, "");
                        	property_tenant += tenant_detail;
                        }

                        var property_detail = property_name+'<br>'+'Current Owner:&nbsp;'+property_owner+'<br>Current Tenant:&nbsp;'+property_tenant;

                        // Payment Method
                        var bank_data = '';
		                var payment_details = response.scheduledPayments[key].payment_details;
		                if(payment_details != null) {
		                	if(Object.keys(payment_details).length !== 0) {
	                			bank_data = response.scheduledPayments[key].payment_details.bank_name+'('+response.scheduledPayments[key].payment_details.account_number+')';
		                	}
		                }

                        // Source and Destination
                        var source_name = '';
                        var source_email = '';
                        var source_bank = '';
                        var destination_name = '';
                        var destination_email = '';
                        var destination_bank = '';

                        var company_name = 'Rent Synergy';
                        if(response.scheduledPayments[key].payment_type == 1) {
                        	source_name = response.scheduledPayments[key].po_name+'<small>(Property Owner)</small>';
                        	source_email = response.scheduledPayments[key].po_email;
                        	source_bank = bank_data;
                        	destination_name = company_name;
                        } else if(response.scheduledPayments[key].payment_type == 2 || response.scheduledPayments[key].payment_type == 7) {
                        	source_name = tenant_name;
                        	source_email = tenant_email;
                        	source_bank = bank_data;
                        	destination_name = response.scheduledPayments[key].po_name+'<small>(Property Owner)</small>';
                        	destination_email = response.scheduledPayments[key].po_email;
                        } else if(response.scheduledPayments[key].payment_type == 3 || response.scheduledPayments[key].payment_type == 4) {
                        	source_name = tenant_name;
                        	source_email = tenant_email;
                        	source_bank = bank_data;
                        	destination_name = company_name;
                        } else if(response.scheduledPayments[key].payment_type == 8) {
                        	source_name = response.scheduledPayments[key].po_name+'<small>(Property Owner)</small>';
                        	source_email = response.scheduledPayments[key].po_email;
                        	source_bank = bank_data;
                        	destination_name = company_name;
                        } else if(response.scheduledPayments[key].payment_type == 9) {
                        	source_name = company_name;
                        	destination_name = tenant_name;
                        	destination_email = tenant_email;
                        	destination_bank = bank_data;
                        }

                        var source_data = source_name+'<br>'+source_email+'<br>'+source_bank;
                        var destination_data = destination_name+'<br>'+destination_email+'<br>'+destination_bank;

	                	// Payment Due Date
                        var payment_due_date = response.scheduledPayments[key].payment_due_date;
	                	if(response.scheduledPayments[key].payment_due_date == null) {
	                		payment_due_date = '';
	                	}

	                	// Authorized Date
                        var authorized_date = response.scheduledPayments[key].authorized_date;
                        var authorized_time = response.scheduledPayments[key].authorized_time;
	                	if(response.scheduledPayments[key].authorized_date == null) {
	                		authorized_date = '';
	                		authorized_time = '';
	                	}

	                	// Initiated date Date
                        var initiated_date = response.scheduledPayments[key].initiated_date;
                        var initiated_time = response.scheduledPayments[key].initiated_time;
	                	if(response.scheduledPayments[key].initiated_date == null) {
	                		initiated_date = '';
	                		initiated_time = '';
	                	}

	                	// Completed date Date
                        var completed_date = response.scheduledPayments[key].completed_date;
                        var completed_time = response.scheduledPayments[key].completed_time;
	                	if(response.scheduledPayments[key].completed_date == null) {
	                		completed_date = '';
	                		completed_time = '';
	                	}

	                	// Coupon Data
	                	var coupon_code = response.scheduledPayments[key].coupon_code.code;
	                	var coupon_amount = '$'+response.scheduledPayments[key].coupon_amount;
	                	if(response.scheduledPayments[key].coupon_id == 0) {
	                		coupon_code = '';
	                		coupon_amount = '';
	                	}

	                	var coupon_data = coupon_code+'<br>'+coupon_amount;

	                	// Transaction status
	                    var card_trans_status = '';
						if(response.scheduledPayments[key].application_constant == 'pending_authorization' || response.scheduledPayments[key].application_constant == 'cancelled_authorized_transfer' || response.scheduledPayments[key].application_constant == 'cancelled_transfer' || response.scheduledPayments[key].application_constant == 'failed_transfer') {
						    card_trans_status += 'badge-danger';
						} else if(response.scheduledPayments[key].application_constant == 'transfer_authorized' || response.scheduledPayments[key].application_constant == 'transfer_in_progress') {
						    card_trans_status += 'badge-warning';
						} else if(response.scheduledPayments[key].application_constant == 'processed') {
						    card_trans_status += 'badge-success';
						} else if(response.scheduledPayments[key].application_constant == 'processing') {
						    card_trans_status += 'badge-primary';
						}

						// Add exclamation button, when click display related transaction detail
	                	var exclamation = '';
	                	if(card_trans_status != '') {
	                		exclamation = '<i data-id='+response.scheduledPayments[key].id+' data-transferID='+response.scheduledPayments[key].dwolla_transferID+' class="align-middle mr-2 fas fa-fw fa-info-circle ml-2 get_related_transaction" data-container="body" data-toggle="popover"></i>';
	                	}
						
                        card_html += '<div class="card mb-1 light-border">';
			                card_html += '<div class="row justify-content-center">';
			                    card_html += '<div class="col-6 col-md-6 col-lg-6">';
			                        card_html += '<div class="text-left fs-13 m-2">';
				                        card_html += '<p class="mt-2">'+address+'</p>';
		                                card_html += '<div class="text-center badge badge-primary mr-1 mb-1">'+response.scheduledPayments[key].payment_type_label+'</div>';
				                        card_html += '<div class="text-center badge mb-1 '+card_trans_status+'">'+response.scheduledPayments[key].application_status+'&nbsp;'+exclamation+'</div>';
				                    card_html += '</div>';
			                    card_html += '</div>';
			                    card_html += '<div class="col-6 col-md-6 col-lg-6">';
			                        card_html += '<div class="text-right fs-13 m-2">';
			                        	card_html += 'Due by: <span>'+payment_due_date+'</span><br>';
				                        card_html += 'Authorized on: <span>'+authorized_date+'</span><br>';
				                        var payments = '-';
					                    if(response.scheduledPayments[key].payments != '' || response.scheduledPayments[key].payments > 0) {
					                    	payments = response.scheduledPayments[key].payments;
					                    	card_html += '<div class="text-right bold-black fs-20">'+payments+'</div>';
					                    }
					                    var receipts = '-';
					                    if(response.scheduledPayments[key].receipts != '' || response.scheduledPayments[key].receipts > 0) {
					                    	receipts = response.scheduledPayments[key].receipts;
					                    	card_html += '<div class="text-right bold-black fs-20 red">('+receipts+')</div>';
					                    }
					                    card_html += '<div data-id='+response.scheduledPayments[key].id+' class="show_hide_transaction_detail bold-black blue pointer">Show More <i class="align-middle fas fa-fw fa-angle-down"></i></div>';
			                        card_html += '</div>';
			                    card_html += '</div>';
			                card_html += '</div>';

			                card_html += '<div id="tran_card_'+response.scheduledPayments[key].id+'" class="row justify-content-center d-none">';
			                    card_html += '<div class="col-6 col-md-6 col-lg-6">';
			                        card_html += '<div class="text-left fs-13 m-2">';
			                        	card_html += '<div class="mt-1 bold-black">Source</div>';
				                        card_html += '<div class="mt-1">'+source_data+'</div>';
				                        card_html += '<div class="mt-1 bold-black">Destination</div>';
				                        card_html += '<div class="mt-1">'+destination_data+'</div>';
				                    card_html += '</div>';
			                    card_html += '</div>';
			                    card_html += '<div class="col-6 col-md-6 col-lg-6">';
			                        card_html += '<div class="text-right fs-13 m-2">';
			                        	if(initiated_date != '') {
			                        		card_html += 'Initiated on: <span>'+initiated_date+'</span><br>';
			                        	}
			                        	if(completed_date != '') {
			                        		card_html += 'Completed on: <span>'+completed_date+'</span><br>';
			                        	}
			                        	if(response.scheduledPayments[key].coupon_id != 0) {
			                        		card_html += 'Coupon: <span>'+coupon_data+'</span>';
			                        	}
			                        card_html += '</div>';
			                    card_html += '</div>';
			                card_html += '</div>';

			            card_html += '</div>';
	                }
	            }
	        }

	        $('#pr_report_data_container').html(card_html);

	        // Get Related Transaction Detail
            var title = 'Transaction Detail';
            var type = 'RelatedTransaction';
            openPopoverModal(title,type);
			
        } else {
            display_error_message(response);
        }
    }, function() {
        // hang on this step if the error occur
    });
}

/* End : Account */

/* Start : Lease */

window.loadLeaseTerm = function(container='') {
	$.ajax({
        method: 'GET',
        url: api_url+"leases/get-term",
        async: true,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
            if(response.response_data.success == true) {
            	var html = '';
                //var html = "<option value=''>Select Term</option>";
                $.each( response.terms, function( key, value ) {
				  	html += '<option value="'+key+'">'+value+'</option>';
				});
		        $('#'+container).html(html);
            } else {
            	display_error_message(response);
            }
        }
    });
}

window.loadLeasePetPolicy = function(container='') {
	$.ajax({
        method: 'GET',
        url: api_url+"leases/get-pet-policy",
        async: true,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
            if(response.response_data.success == true) {
                var html = "<option value=''>Select Pet Policy</option>";
		        $.each( response.petPolicy, function( key, value ) {
				  	html += '<option value="'+key+'">'+value+'</option>';
				});
		        $('#'+container).html(html);
            } else {
            	display_error_message(response);
            }
        }
    });
}

window.loadLeaseRentDueDate = function(container='') {
	$.ajax({
        method: 'GET',
        url: api_url+"leases/get-due-days",
        async: true,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
            if(response.response_data.success == true) {
                var html = "<option value=''>Select Due Day</option>";
		        $.each( response.dueDays, function( key, value ) {
		        	value = ordinal_suffix_of(value);
				  	html += '<option value="'+key+'">'+value+' of month</option>';
				});
		        $('#'+container).html(html);
            } else {
            	display_error_message(response);
            }
        }
    });
}

var $leaseForm;
window.addLeaseValidation = function() {
	$leaseForm = $("#lease_add_form, #lease_edit_form");
	$leaseForm.validate({
	    errorPlacement: function errorPlacement(error, element) {
	        $(element).parents(".form-group").append(error.addClass("invalid-feedback small d-block"))
	    },
	    highlight: function(element) {
	        $(element).addClass("is-invalid");
	    },
	    unhighlight: function(element) {
	        $(element).removeClass("is-invalid");
	    },
	    rules: {
	        "monthly_rent_amount": { required: true },
	        "term": { required: true },
	        "security_deposit_amount": { required: true },
	        "start_date": { required: true },
	        "end_date": { required: true },
	        "rent_due_date": { required: true },
	        "sd_due_date": { required: true },
	        "pet_policy": { required: true },
	        "pd_due_date": { required: true }
	    },
	    messages: {
	        "monthly_rent_amount": { required: '' },
	        "term": { required: '' },
	        "security_deposit_amount": { required: '' },
	        "start_date": { required: '' },
	        "end_date": { required: '' },
	        "rent_due_date": { required: '' },
	        "sd_due_date": { required: '' },
	        "pet_policy": { required: '' },
	        "pd_due_date": { required: '' }
	    }
	});
}

window.saveLeaseData = function() {
	if($leaseForm.valid()){
		$('.save_lease_data').attr('disabled',true);

		var user_id = user.user_details.user_id;
		var first_time_signup = $('#first_time_signup').val();
		if(first_time_signup == 1) {
			var property_id = $('#first_property_id').val();
		} else {
			var property_id = $('#selected_property_id').val();
		}
		var module_name = $('#module_name').val();
		var lease_tenant_id = $('#lease_tenant_id').val();
		$.ajax({
            method: 'POST',
            url: api_url+"leases/add",
            data: $('#lease_add_form').serialize()+'&property_id='+property_id+'&tenant_id='+lease_tenant_id+'&user_id='+user_id+'&first_time_signup='+first_time_signup,
            beforeSend: function(xhr) {
		        xhr.setRequestHeader('Authorization', accessToken);
		    },
            success: function(response) {
                display_error_message(response);
                $('.save_lease_data').attr('disabled',false);
                if(response.response_data.success == true) {
                	// Below code for initial signup step
                	if(first_time_signup == 1) {
						// Checkilist step 12
						getSignupNextStep(12,13);

						var user = JSON.parse(localStorage.user);
						user.user_details.cheklist_seq_no = 12;
						localStorage.setItem("user", JSON.stringify(user));  //put the object back
						
						// Display Rent Deposit and Subscription Fee Payment Authorization Model for Linking payment method
        				openLoadingDialog($('#signup_data_container'));
						setTimeout(function(){
							getBankListForProperty(user_id, property_id, response.property_name);
						}, 500);
					} else {
	                    $('#leaseAddModal').modal('hide');
	                    $('#lease_detail_container_add,#lease_detail_container_edit').html('');
                    	openLoadingDialog($('#lease_data_container'));
	    				$('#lease_data_container').load('components/property/lease_detail_table.html');

	    				getPropertyLeaseWidgetInfo();
	                }
                }
            }
        });
    }
}

window.updateLeaseData = function() {
	if($leaseForm.valid()){
		var user_id = user.user_details.user_id;
		var lease_id = $('#lease_id').val();
		var property_id = $('#selected_property_id').val();
		$.ajax({
            method: 'POST',
            url: api_url+"leases/edit/"+lease_id,
            data: $('#lease_edit_form').serialize()+'&property_id='+property_id+'&user_id='+user_id,
            beforeSend: function(xhr) {
		        xhr.setRequestHeader('Authorization', accessToken);
		    },
            success: function(response) {
                display_error_message(response);
                if(response.response_data.success == true) {
                    $('#leaseEditModal').modal('hide');
                    $('#lease_detail_container_add,#lease_detail_container_edit').html('');
                    openLoadingDialog($('#lease_data_container'));
	    			$('#lease_data_container').load('components/property/lease_detail_table.html');

	    			getPropertyLeaseWidgetInfo();
                }
            }
        });
    }
}

window.getLeaseDetailById = function(type, lease_id) {
	var user_id = user.user_details.user_id;
	var tz = jstz.determine();
	var timezone = tz.name();
	$.ajax({
        method: 'POST',
        url: api_url+"leases/view/"+lease_id,
        data:'user_id='+user_id+'&user_timezone='+timezone,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	display_error_message(response);
            if(response.response_data.success == true) {
            	if(type == 'edit') {
	            	setTimeout(function(){
					    $('#lease_id').val(response.lease.id);
		                $('#monthly_rent_amount').val(response.lease.monthly_rent_amount);
		                $('#term').val(response.lease.term);
		                $('#security_deposit_amount').val(response.lease.security_deposit_amount);
		                $('#pet_deposit_amount').val(response.lease.pet_deposit_amount);
		                $('#start_date > input').val(response.lease.start_date);
		                $('#end_date > input').val(response.lease.end_date);
		                $('#pet_policy').val(response.lease.pet_policy);
		                $('#rent_due_date').val(response.lease.rent_due_date);
		                $('#sd_due_date > input').val(response.lease.sd_due_date);
		                $('#pd_due_date > input').val(response.lease.pd_due_date);

		                if(response.lease.pet_policy == 2) {
							$('#pet_deposit_amount').attr('readonly', false); 
							$('#pd_due_date > input').attr('disabled', false); 
						} else {
							$('#pet_deposit_amount').val('');
							$('#pd_due_date > input').val('');
							$('#pet_deposit_amount').attr('readonly', true);
							$('#pd_due_date > input').attr('disabled', true); 
						}
					}, 100);
	            }

	            if(type == 'autopay') {
	            	var autopay_enable_disable = $('#autopay_enable_disable').val();

	            	var date_html = "<option value=''>Autopay date</option>";
	            	for (let i = 21; i <= 31; i++) {
	            		if(response.lease.day == i) {
	            			var selected = 'selected';
	            		} else {
	            			var selected = '';
	            		}
					  	var due_date = ordinal_suffix_of(i);
				        date_html += '<option value="'+i+'" '+selected+'>'+due_date+'</option>';
					}

	            	var bank_html = "<option value=''>Select Payment Method</option>";
	                for (var key in response.paymentDetails) {
				        if (response.paymentDetails.hasOwnProperty(key)) {
				        	if(response.lease.autopay_payment_detail_id == response.paymentDetails[key].id) {
		            			var b_selected = 'selected';
		            		} else {
		            			var b_selected = '';
		            		}
				        	bank_html += '<option value="'+response.paymentDetails[key].id+'" '+b_selected+'>'+response.paymentDetails[key].bank_name+'</option>';
						}
					}

	            	var html = '';
	            	html += '<input type="hidden" name="autopay_lease_id" id="autopay_lease_id" value="'+response.lease.id+'">';
	            	html += '<input type="hidden" name="autopay_property_id" id="autopay_property_id" value="'+response.lease.property_id+'">';
	            	html += '<form id="autopay-collection-wizard" class="wizard-primary create-account-form" action="javascript:void(0)">';
					    html += '<div id="payNowCollapse">';

					    	if(autopay_enable_disable == 1) {
					    		var add_opacity = '';
					    		var terms = 'By selecting Enable Autopay, I agree and authorize scheduled monthly rent payments to Rent Synergy on the scheduled autopay date for the amount mentioned above from the selected bank account. These payments will be processed by the Dwolla payment processing system. I understand that I can cancel this autopay option by selecting Disable Autopay option from my accounts page.';
					    	} else {
					    		var add_opacity = 'add_opacity';
					    		var terms = 'By selecting Disable Autopay, I am cancelling the automatic procesing of future scheduled rent payments. I understand that future monthly rent payments will be authorized manually and in the absence of doing so may incur additional late fees.';
					    	}

					    	if(response.lease != null) {
			            		html += '<div class="rent-detail-card p-3 mb-1 bg-white '+add_opacity+'">';
						            html += '<div class="border-bottom">';
						                html += '<h5 class="card-title"><span class="fs-22">Monthly Rent</h5> <span class="bold-black">('+response.lease.property.name+')</span>';
						            html += '</div>';
						            html += '<div class="rent_collection_data mt-2">';
						                html += '<div class="row">';
						                	html += '<div class="col-12 col-md-4 col-xl-4">';
											    html += '<div class="mt-2">';
						                            html += '<div class="black mb-2"><strong>Autopay Date</strong></div>';
						                            html += '<div><select name="autopay_date" id="autopay_date" class="form-control" style="width:150px;">'+date_html+'</select></div>';
						                        html += '</div>';
											html += '</div>';

											html += '<div class="col-12 col-md-4 col-xl-4">';
						                        html += '<div class="mt-2">';
						                            html += '<div class="black mb-2"><strong>Select Payment Method</strong></div>';
						                            html += '<div><select name="autopay_payment_detail_id" id="autopay_payment_detail_id" class="bank_dd form-control">'+bank_html+'</select></div>';
						                        html += '</div>';
						                    html += '</div>';
						                    html += '<div class="col-12 col-md-4 col-xl-4 text-right">';
						                        html += '<div class="mt-2">';
						                            html += '<div class="black mb-2"><strong>Amount</strong></div>';
						                            html += '<div class="black fs-18"><strong>$ '+response.lease.monthly_rent_amount+'</strong></div>';
						                        html += '</div>';
						                    html += '</div>';
						                html += '</div>';
						            html += '</div>';
						        html += '</div>';
						        html += '<div class="rent-detail-card pl-3 pr-3 pt-3 bg-white '+add_opacity+'">';
						            html += '<div class="rent_collection_data">';
						                html += '<div class="row">';
						                    html += '<div class="col-12 col-md-6 col-xl-9">';
						                        html += '<h5 class="card-title"><span class="fs-16"><strong>Total</strong></h5>';
						                    html += '</div>';
						                    html += '<div class="col-12 col-md-6 col-xl-3 text-right">';
						                    	html += '<div class="black fs-18"><strong>$ '+response.lease.monthly_rent_amount+'</strong></div>';
						                    html += '</div>';
						                html += '</div>';
						            html += '</div>';
						        html += '</div>';				
						    }

						    html += '<div class="col-auto d-sm-block mb-2 mt-4">';
					            html += '<div class="autopay_checkbox">';
					                html += '<label class="custom-control custom-checkbox m-0">';
					                html += '<input name="autopay_checkbox" id="autopay_authorize_checkbox" type="checkbox" class="custom-control-input">';
					                html += '<span class="custom-control-label">'+terms+'</span>';
					                html += '</label>';
					            html += '</div>';
					        html += '</div>';
					    html += '</div>';
					html += '</form>';

					$('#autopay_container').html(html);

					addAutopayValidation();
	            }
            }
        }
    });
}

window.loadTableLeaseDetail = function() {
	var user = JSON.parse(localStorage.getItem('user'));
    var tz = jstz.determine();
	var timezone = tz.name();
	var property_id = $('#selected_property_id').val();
	var user_id = user.user_details.user_id;

	var data = '';
	data = 'user_timezone='+timezone+'&property_id='+property_id+'&user_service_name='+user_service_name+'&user_id='+user_id;
	
	$.ajax({
	    method: 'POST',
	    url: api_url+"leases",
	    data:data,
	    beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
	}).then(function(response) {
		if(response.response_data.success == true) {
			var html = '';
			if(response.TotalLeases == 0) {
	        	html += '<a href="javascript:void(0)" class="list-group-item list-group-item-action border-0 bg-light text-left"><div class="fs-16">'+response.response_data.message+'</div></a>';
		    } else {
	            for (var key in response.leases) {
	                if (response.leases.hasOwnProperty(key)) {

	                	var rent_due_date = ordinal_suffix_of(response.leases[key].rent_due_date);
	                	var sd_due_date = '';
	                	var pd_due_date = '&nbsp;';
	                	if(response.leases[key].sd_due_date != null) {
	                		sd_due_date = 'Due on '+response.leases[key].sd_due_date;
	                	}
	                	if(response.leases[key].pd_due_date != null) {
	                		pd_due_date = 'Due on '+response.leases[key].pd_due_date;
	                	}

	                	var status = '';
	                    if(response.leases[key].status == 0 || response.leases[key].status == 4) {
	                        status += '<span class="badge badge-danger">'+response.leases[key].status_label+'</span>';
	                    } if(response.leases[key].status == 1 || response.leases[key].status == 5) {
	                        status += '<span class="badge badge-success">'+response.leases[key].status_label+'</span>';
	                    }

	                    var kebab_item = '';

	                    if(user.permissionData['PROPERTIES']["feature_items['lease_edit']"] && response.leases[key].status != 4 && response.leases[key].transaction_created == 0) {
	                    	kebab_item += '<a class="dropdown-item edit_lease_detail" data-id="'+response.leases[key].id+'" href="javascript:void(0)" data-toggle="modal" data-target="#leaseEditModal" data-backdrop="static" data-keyboard="false">Edit</a>';
	                    }

	                    if(response.leases[key].tenant == '' && user.permissionData['PROPERTIES']["feature_items['lease_delete']"] && response.leases[key].status != 4 && response.leases[key].transaction_created == 0) {
	                    	kebab_item += '<a class="dropdown-item delete_lease_detail" data-id="'+response.leases[key].id+'" href="javascript:void(0)">Delete</a>';
	                    }

	                    if(user_service_name == sa_role && response.leases[key].status == 4 && response.leases[key].is_extended == 'No' && response.leases[key].sd_amount > 0 && response.leases[key].is_security_deposit_refunded == 0) {
	                    	kebab_item += '<a class="dropdown-item refund_security_deposit" data-id="'+response.leases[key].id+'" data-sd-amount="'+response.leases[key].security_deposit_amount+'" data-tenants-table-id="'+response.leases[key].tenant_table_ids+'" data-tenant-user-id="'+response.leases[key].tenant_ids+'" data-payment-id="'+response.leases[key].payment_details_id+'" href="javascript:void(0)">Refund Security Deposit</a>';
	                    }

	                    var kebab = '';
	                    if(kebab_item != '') {
	                    	kebab = '<div class="text-center"><div class="input-group-prepend"><i class="align-middle mr-2 fas fa-fw fa-ellipsis-v" data-toggle="dropdown" aria-expanded="false"></i><div class="dropdown-menu dropdown-menu-right" style="">'+kebab_item+'</div></div></div>';
	                    }

	                	var add_opacity = '';
	                	if(response.leases[key].tenant == null) {
	                		add_opacity = 'add_opacity';
	                	}

	                	// Card based UI
	                	html += '<div data-id="'+response.leases[key].id+'" class="col-12 col-md-6 col-xl-3 property_card">';
							html += '<div class="card">';
								html += '<div class="card-header bg-light"><span class="accordion_arrow">'+kebab+' </span><h5 class="card-title capitalize mb-0 mr-3">'+response.leases[key].start_date+' - '+response.leases[key].end_date+'</h5></div>';
								html += '<div class="card-body">';
									html += '<table class="table mb-0 card-table word-break">';
										html += '<tbody>';
											html += '<tr>';
												html += '<td class="nowrap">Monthly Rent</td>';
												html += '<td class="text-right bold-black">$'+response.leases[key].monthly_rent_amount+'<br><small>Due on '+rent_due_date+' of the month</small></td>';
											html += '</tr>';
											html += '<tr>';
												html += '<td class="nowrap">Security Deposit</td>';
												html += '<td class="text-right bold-black">$'+response.leases[key].security_deposit_amount+'<br><small>'+sd_due_date+'</small></td>';
											html += '</tr>';
											html += '<tr>';
												html += '<td class="nowrap">Pet Policy</td>';
												html += '<td class="text-right">'+response.leases[key].pet_policy+'</td>';
											html += '</tr>';
											html += '<tr>';
												html += '<td class="nowrap">Pet Deposit</td>';
												html += '<td class="text-right bold-black">$'+response.leases[key].pet_deposit_amount+'<br><small>'+pd_due_date+'</small></td>';
											html += '</tr>';
											html += '<tr>';
												html += '<td class="nowrap">Tenant</td>';
												html += '<td class="text-right">'+response.leases[key].tenant+'</td>';
											html += '</tr>';
											/*html += '<tr>';
												html += '<td class="nowrap fs-14">';
													if(user.permissionData['PROPERTIES']["feature_items['lease_link_tenant']"]) {
			                                        	html += '<a href="#" data-tenant-id="'+response.leases[key].tenant_ids+'" data-module="lease" data-id="'+response.leases[key].id+'" class="list_existing_tenant" data-toggle="modal" data-target="#tenantListModal" data-backdrop="static" data-keyboard="false">Link Tenants</a><br>';
			                                        }
												html += '</td>';
												html += '<td class="text-right fs-14">';
											    	if(user.permissionData['PROPERTIES']["feature_items['lease_show_document']"]) {
			                                        	html += '<a href="#" data-tenant-id="'+response.leases[key].tenant_ids+'" class="list_tenant_document '+add_opacity+'" data-toggle="modal" data-target="#documentListModal" data-backdrop="static" data-keyboard="false">Show Documents</a><br>';
			                                        }
												html += '</td>';
											html += '</tr>';*/
										html += '</tbody>';
									html += '</table>';
									html += '<div class="text-right mt-4">'+status+'</div>';
								html += '</div>';
							html += '</div>';
						html += '</div>';
	                }
	            }
	        }
            $('#leaseDataContainer').html(html);
            // Get Floating Action Button
			getFloatingActionButton('Lease', '#leaseDataContainer');
        } else {
            display_error_message(response);
        }
    }, function() {
        // hang on this step if the error occur
    });
}

window.deleteLease = function(lease_id) {
	$.ajax({
        method: 'POST',
        url: api_url+"leases/delete",
        data:'id='+lease_id,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
            display_error_message(response);
            if(response.response_data.success == true) {
                openLoadingDialog($('#lease_data_container'));
    			$('#lease_data_container').load('components/property/lease_detail_table.html');

    			getPropertyLeaseWidgetInfo();
            }
        }
    });
}

window.getExistingLeaseDetail = function(lease_ids, lease_id) {
	openLoadingDialog($('#existingLeaseList'));
	var user = JSON.parse(localStorage.getItem('user'));
    var tz = jstz.determine();
	var timezone = tz.name();
	var property_id = $('#selected_property_id').val();

	var data = '';
	data = 'user_timezone='+timezone+'&property_id='+property_id+'&lease_ids='+lease_ids;
	
	$.ajax({
	    method: 'POST',
	    url: api_url+"leases",
	    data:data,
	    beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
	    success: function(response) {
	    	if(response.response_data.success == true) {
				var html = '';
				if(response.TotalLeases == 0) {
		        	html += '<a href="javascript:void(0)" class="list-group-item list-group-item-action border-0 bg-light text-left"><div class="fs-16">'+response.response_data.message+'</div></a>';
			    } else {
			    	var html = '';
			    	html += '<ul style="list-style-type:none;">';
			    	for (var key in response.leases) {
		                if (response.leases.hasOwnProperty(key)) {
		                	if(response.leases[key].status == 1) {
		                		var checked = '';
		                		var add_opacity = '';
		                		if(response.leases[key].id == lease_id) { checked = 'checked'; add_opacity = 'add_opacity'; }	                		    
						        html += '<li class="float-left '+add_opacity+'">';
						            html += '<label class="custom-control custom-checkbox">';
						            html += '<input type="checkbox" name="existing_lease_checkbox" class="custom-control-input existing_lease_checkbox" data-id="'+response.leases[key].id+'" '+checked+'>';
						            html += '<span class="custom-control-label">'+response.leases[key].start_date+' - '+response.leases[key].end_date+'</span>';
						            html += '</label>';
						        html += '</li>';
							}
		                }
		            }
		            html += '</ul>';
		        }
		        $('#existingLeaseList').html(html);
	        } else {
	            display_error_message(response);
	        }
	    }
	});
}

window.assignLeaseToTenant = function(property_id,lease_id,tenant_id,tenant_lease_array) {
	var user_id = user.user_details.user_id;
	$.ajax({
        method: 'POST',
        url: api_url+"tenant-leases/add",
        data: 'user_id='+user_id+'&property_id='+property_id+'&lease_id='+lease_id+'&tenant_id='+tenant_id+'&tenant_lease_data='+tenant_lease_array,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
            display_error_message(response);
            if(response.response_data.success == true) {
            	$('#leaseListModal').modal('hide');
            	openLoadingDialog($('#tenant_data_container'));
	    		$('#tenant_data_container').load('components/property/tenant_detail_table.html');
	    		getPropertyTenantWidgetInfo();
            } else {
            	$('.assign_lease_to_tenant').attr('disabled',false);
            }
        }
    });
}

window.getTenantSignedDocument = function(tenant_ids) {
	var property_id = $('#selected_property_id').val();
	$.ajax({
	    method: 'POST',
	    url: api_url+"user-templates",
	    data:'property_id='+property_id+'&tenant_ids='+tenant_ids,
	    beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
	}).then(function(response) {
		if(response.response_data.success == true) {
			var html = '';
			if(response.TotalTemplates == 0) {
	        	html += '<a href="javascript:void(0)" class="list-group-item list-group-item-action border-0 bg-light text-center"><div class="fs-16">'+response.response_data.message+'</div></a>';
		    } else {
		    	var html = '';
		    	for (var key in response.userTemplates) {
	                if (response.userTemplates.hasOwnProperty(key)) {
	                	html += '<div class="card mb-3 bg-light border">';
						    html += '<div class="p-2">';
						        html += '<div class="float-left">';
						            /*html += '<label class="custom-control custom-checkbox">';
						            html += '<input type="checkbox" name="existing_document_checkbox" class="custom-control-input existing_document_checkbox" data-id="'+response.userTemplates[key].id+'">';
						            html += '<span class="custom-control-label text-hide">Checkbox</span>';
						            html += '</label>';*/
						        html += '</div>';
						        html += '<div>'+response.userTemplates[key].zoho_template.zoho_templateName+'</div>';
						    html += '</div>';
						html += '</div>';
	                }
	            }
	        }
	        $('#existingDocumentList').html(html);
        } else {
            display_error_message(response);
        }
    }, function() {
        // hang on this step if the error occur
    });
}

/* End : Lease */

/* Start: Tenants */

var $tenantForm;
window.addTenantValidation = function() {
	$tenantForm = $("#tenant_add_form, #tenant_edit_form");
	$tenantForm.validate({
	    errorPlacement: function errorPlacement(error, element) {
	        $(element).parents(".form-group").append(error.addClass("invalid-feedback small d-block"))
	    },
	    highlight: function(element) {
	        $(element).addClass("is-invalid");
	    },
	    unhighlight: function(element) {
	        $(element).removeClass("is-invalid");
	    },
	    rules: {
	        "tenant_firstname": { required: true },
	        "tenant_lastname": { required: true },
	        "tenant_email": { required: true, email: true },
	        "tenant_type": { required: true }
	    },
	    messages: {
	        "tenant_firstname": { required: '' },
	        "tenant_lastname": { required: '' },
	        "tenant_email": { required: '', email: 'Please enter valid email address' },
	        "tenant_type": { required: '' }
	    }
	});
}

window.saveTenantData = function(button_type) {
	if($tenantForm.valid()){
		$('.save_tenant_data').attr('disabled',true);
		$('.save_and_submit_tenant_data').attr('disabled',true);
		
		var user_id = user.user_details.user_id;
		var first_time_signup = $('#first_time_signup').val();
		if(first_time_signup == 1) {
			var property_id = $('#first_property_id').val();
		} else {
			var property_id = $('#selected_property_id').val();
		}
		var first_name = $('#tenant_firstname').val();
		var last_name = $('#tenant_lastname').val();
		var email = $('#tenant_email').val();
		var module_name = $('#module_name').val();
		var tenant_lease_id = $('#tenant_lease_id').val();
		if(typeof tenant_lease_id === 'undefined') {
			var lease_id = 0;
		} else {
			var lease_id = tenant_lease_id;
		}
		var tenant_type = $('input[name="tenant_type"]:checked').val();
		var data = 'first_name='+first_name+'&last_name='+last_name+'&email='+email+'&property_id='+property_id+'&lease_id='+lease_id+'&tenant_type='+tenant_type+'&user_id='+user_id+'&first_time_signup='+first_time_signup;
		
        $.ajax({
            method: 'POST',
            url: api_url+"tenants/add",
            data: data,
            beforeSend: function(xhr) {
		        xhr.setRequestHeader('Authorization', accessToken);
		    },
            success: function(response) {
                display_error_message(response);
                $('.save_tenant_data').attr('disabled',false);
                $('.save_and_submit_tenant_data').attr('disabled',false);
                if(response.response_data.success == true) {
                	// Below code for initial signup step
					var first_time_signup = $('#first_time_signup').val();
					if(first_time_signup == 1) {
						var tenant_id = response.tenant_id;
                    	submitTenantDetail(property_id, tenant_id);
                    	ConfirmAddTenant('tenant');
					} else {
	                    $('#tenantAddModal').modal('hide');
	                    $('#tenant_detail_container_add, #tenant_detail_container_edit').html('');
	                    if(button_type == 'save') {
		                    if(module_name == 'lease') {
		                    	openLoadingDialog($('#lease_data_container'));
			    				$('#lease_data_container').load('components/property/lease_detail_table.html');
		                    } else {
		                    	openLoadingDialog($('#tenant_data_container'));
			    				$('#tenant_data_container').load('components/property/tenant_detail_table.html');
		                    }
		                } else if(button_type == 'save_submit') {
		                	var tenant_id = response.tenant_id;
	                    	inviteTenant(property_id, tenant_id, button_type);
		                }

		                getPropertyTenantWidgetInfo();
		            }
                }
            }
        });
    }
}

window.getTenantDetailById = function(tenant_id) {
	var tz = jstz.determine();
	var timezone = tz.name();
	$.ajax({
        method: 'GET',
        url: api_url+"tenants/view/"+tenant_id,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	display_error_message(response);
            if(response.response_data.success == true) {
            	setTimeout(function(){
            		if(response.tenant.status >= 2) {
	                	$('.update_and_submit_tenant_data').attr('disabled',true);
	                }
        			$('#tenant_id').val(response.tenant.id);
	                $('#tenant_firstname').val(response.tenant.user.first_name);
	                $('#tenant_lastname').val(response.tenant.user.last_name);
	                $('#tenant_email').val(response.tenant.user.email);
	                $('input[name="tenant_type"][value="'+response.tenant.tenant_type+'"]').attr('checked',true);
				}, 100);
            }
        }
    });
}

window.getInvitedTenantDetail = function(invitation_code) {
	$.ajax({
        method: 'POST',
        url: api_url+"tenants/get-invited-tenant-detail",
        data:'invitation_code='+invitation_code,
        success: function(response) {
        	display_error_message(response);
            if(response.response_data.success == true) {
        		$('#property_id').val(response.propertyData.id);
        		$('#property_user_id').val(response.propertyData.user.id);
        		$('#tenant_user_id').val(response.tenant.user.id);

        		$('#tenant_id').val(response.tenant.id);
        	    $('#firstname').val(response.tenant.user.first_name);
                $('#lastname').val(response.tenant.user.last_name);
                $('#email_address').val(response.tenant.user.email);

                //$('#property_owner_firstname').val(response.propertyData.user.first_name);
                //$('#property_owner_lastname').val(response.propertyData.user.last_name);
                //$('#property_owner_email_address').val(response.propertyData.user.email);
                if(response.propertyData.address != null) {
	                $('#property_address_line1').val(response.propertyData.address.address_line1);
	                $('#property_address_line2').val(response.propertyData.address.address_line2);
	                $('#property_city').val(response.propertyData.address.city);
	                $('#property_state').val(response.propertyData.address.state);
	                $('#property_zip_code').val(response.propertyData.address.zip_code);
	                $('#property_country').val(response.propertyData.address.country);
	            }
            }
        }
    });
}

window.updateTenantData = function(button_type) {
	if($tenantForm.valid()){
		$('.update_tenant_data').attr('disabled',true);
		$('.update_and_submit_tenant_data').attr('disabled',true);
		var tenant_id = $('#tenant_id').val();
		var property_id = $('#selected_property_id').val();
		var first_name = $('#tenant_firstname').val();
		var last_name = $('#tenant_lastname').val();
		var email = $('#tenant_email').val();
		var tenant_type = $('input[name="tenant_type"]:checked').val();
		var user_id = user.user_details.user_id;
		$.ajax({
            method: 'POST',
            url: api_url+"tenants/edit/"+tenant_id,
            data: 'first_name='+first_name+'&last_name='+last_name+'&email='+email+'&property_id='+property_id+'&tenant_type='+tenant_type+'&user_id='+user_id,
            beforeSend: function(xhr) {
		        xhr.setRequestHeader('Authorization', accessToken);
		    },
            success: function(response) {
            	$('.update_tenant_data').attr('disabled',false);
            	$('.update_and_submit_tenant_data').attr('disabled',false);
                display_error_message(response);
                if(response.response_data.success == true) {
                	$('#tenantEditModal').modal('hide');
                    $('#tenant_detail_container_add, #tenant_detail_container_edit').html('');
                    if(button_type == 'save') {
                    	openLoadingDialog($('#tenant_data_container'));
	    				$('#tenant_data_container').load('components/property/tenant_detail_table.html');
	    			} else if(button_type == 'save_submit') {
	                	inviteTenant(property_id, tenant_id, button_type);
	                }
	                getPropertyTenantWidgetInfo();
                }
            }
        });
    }
}

window.deleteTenant = function(tenant_id) {
	var user_id = user.user_details.user_id;
	$.ajax({
        method: 'POST',
        url: api_url+"tenants/delete",
        data:'id='+tenant_id+'&user_id='+user_id,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
            display_error_message(response);
            if(response.response_data.success == true) {
                openLoadingDialog($('#tenant_data_container'));
    			$('#tenant_data_container').load('components/property/tenant_detail_table.html');
    			getPropertyTenantWidgetInfo();
            }
        }
    });
}

window.submitTenantDetail = function(property_id, tenant_id) {
	var user_id = user.user_details.user_id;
	var first_time_signup = $('#first_time_signup').val();
	$.ajax({
        method: 'POST',
        url: api_url+"tenants/submit",
        data: 'property_id='+property_id+'&tenant_id='+tenant_id+'&first_time_signup='+first_time_signup+'&user_id='+user_id,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
            if(response.response_data.success == true) {
            	// Below code for initial signup step
				if(first_time_signup != 1) {
					display_error_message(response);
                	openLoadingDialog($('#tenant_data_container'));
    				$('#tenant_data_container').load('components/property/tenant_detail_table.html');
    				getPropertyTenantWidgetInfo();
    			}
            } else {
            	display_error_message(response);
            }
        }
    });
}

window.inviteTenant = function(property_id, tenant_id, button_type = '') {
	var user_id = user.user_details.user_id;
	$.ajax({
        method: 'POST',
        url: api_url+"tenants/invite",
        data: 'property_id='+property_id+'&tenant_id='+tenant_id+'&button_type='+button_type+'&user_id='+user_id,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
            display_error_message(response);
            if(response.response_data.success == true) {
            	var rs_invite_tenant = $('#rs_invite_tenant').val();
				if(rs_invite_tenant == 1) {
					$('#inviteTenantModal').modal('hide');
					$('#tenant_data_container').load('components/property/tenant_detail_table.html');
				} else {
        			openLoadingDialog($('#tenant_data_container'));
					$('#tenant_data_container').load('components/property/tenant_detail_table.html');
				}
				getPropertyTenantWidgetInfo();
            } else {
            	$('.rs_admin_invite_tenant').attr('disabled', false);
            }
        }
    });
}

window.loadTableTenantDetail = function() {
	var user = JSON.parse(localStorage.getItem('user'));
    var tz = jstz.determine();
	var timezone = tz.name();
	var property_id = $('#selected_property_id').val();
	var user_id = user.user_details.user_id;

	var data = '';
	data = 'user_timezone='+timezone+'&property_id='+property_id+'&user_service_name='+user_service_name+'&user_id='+user_id;
	
	$.ajax({
	    method: 'POST',
	    url: api_url+"tenants",
	    data:data,
	    beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
	}).then(function(response) {
		if(response.response_data.success == true) {
			var html = '';
			if(response.TotalTenants == 0) {
	        	html += '<a href="javascript:void(0)" class="list-group-item list-group-item-action border-0 bg-light text-left"><div class="fs-16">'+response.response_data.message+'</div></a>';
		    } else {
		    	for (var key in response.tenants) {
	                if (response.tenants.hasOwnProperty(key)) {

	                	var monthly_rent_amount = '-';
	                	var rent_due_date = '-';
	                	var security_deposit_amount = '-';
	                	var pet_policy = '-';
	                	var pet_deposit_amount = '-';
	                	var start_date = '-';
	                	var end_date = '-';
	                	var sd_due_date = '-';
	                	var pd_due_date = '-';

	                	var kebab_item = '';
	                	if(user.permissionData['PROPERTIES']["feature_items['tenant_edit']"]) {
	                    	kebab_item += '<a class="dropdown-item edit_tenant_detail" data-id="'+response.tenants[key].id+'" href="javascript:void(0)" data-toggle="modal" data-target="#tenantEditModal" data-backdrop="static" data-keyboard="false">Edit</a>';
	                    }

	                    var status = '';
	                    if(response.tenants[key].application_constant == 'draft') {
	                        status += '<span class="badge badge-danger">'+response.tenants[key].application_status+'</span>';
	                        if(user_service_name == po_role) {
	                        	kebab_item += '<a class="dropdown-item submit_tenant_detail" data-id="'+response.tenants[key].id+'" href="javascript:void(0)">Submit</a>';
	                        }
	                    } else if(response.tenants[key].application_constant == 'submitted' || (response.tenants[key].application_constant == 'invite_sent' && response.tenants[key].user.status == 0)) {
	                        status += '<span class="badge badge-info">'+response.tenants[key].application_status+'</span>';
	                        if(user_service_name == sa_role) {
	                        	var invite_lable = '';
	                        	if(response.tenants[key].application_constant == 'invite_sent' && response.tenants[key].user.status == 0) {
	                        		invite_lable = 'Re-invite Tenant';
	                        	} else {
	                        		invite_lable = 'Invite Tenant';
	                        	}
	                        	kebab_item += '<a class="dropdown-item invite_tenant" data-id="'+response.tenants[key].id+'" href="javascript:void(0)" data-toggle="modal" data-target="#inviteTenantModal" data-backdrop="static" data-keyboard="false">'+invite_lable+'</a>';
	                        }
	                    } else if(response.tenants[key].application_constant == 'invite_sent') {
	                        status += '<span class="badge badge-primary">'+response.tenants[key].application_status+'</span>';
	                    } else if(response.tenants[key].application_constant == 'signed_up') {
	                        status += '<span class="badge badge-success">'+response.tenants[key].application_status+'</span>';
	                    } else if(response.tenants[key].application_constant == 'tenant_deleted') {
	                        status += '<span class="badge badge-danger">'+response.tenants[key].application_status+'</span>';
	                    } else if(response.tenants[key].application_constant == 'invite_pending') {
	                        status += '<span class="badge badge-info">'+response.tenants[key].application_status+'</span>';
	                    }

	                    if(response.tenants[key].lease_status == 4) {
	                    	status = '<span class="badge badge-danger">Lease Expired</span>';
	                    }

	                    if(user.permissionData['PROPERTIES']["feature_items['tenant_delete']"]) {
	                    	if(response.tenants[key].tenant_leases.length <= 0 && response.tenants[key].application_constant != 'tenant_deleted') {
	                    		kebab_item += '<a class="dropdown-item delete_tenant_detail" data-id="'+response.tenants[key].id+'" href="javascript:void(0)">Delete</a>';
                        	}
                        }

                        /*if(user_service_name == sa_role && response.tenants[key].status == 4) {
                    		kebab_item += '<a class="dropdown-item undelete_tenant_detail" data-id="'+response.tenants[key].id+'" href="javascript:void(0)">Un-delete</a>';
                    	}*/

	                    var kebab = kebab_item;
	                    
	                    if(response.tenants[key].tenant_leases.length > 0) {
		                	for (var tlkey in response.tenants[key].tenant_leases) {
		                		if (response.tenants[key].tenant_leases.hasOwnProperty(tlkey)) {
		                			var lease = response.tenants[key].tenant_leases[tlkey].lease;
		                			if(lease != null) {
				                		monthly_rent_amount = lease.monthly_rent_amount;
				                		rent_due_date = ordinal_suffix_of(lease.rent_due_date);
				                		security_deposit_amount = lease.security_deposit_amount;
				                		pet_policy = lease.pet_policy_term_label;
				                		pet_deposit_amount = lease.pet_deposit_amount;
				                		start_date = lease.start_date;
				                		end_date = lease.end_date;
				                		sd_due_date = 'Due on '+lease.sd_due_date;
				                		pd_due_date = 'Due on '+lease.pd_due_date;
				                	}

				                	if(response.tenants[key].tenant_leases[tlkey].status == 1) {
				                    	status = '<span class="badge badge-info">Submitted</span>';
				                    }
				                	if(response.tenants[key].tenant_leases[tlkey].status == 4) {
				                    	status = '<span class="badge badge-danger">Lease Expired</span>';
				                    }
				                    if(response.tenants[key].tenant_leases[tlkey].status == 5) {
				                    	status = '<span class="badge badge-primary">Pending signature</span>';
				                    }

				                	// Card based UI
				                	html += '<div data-id="'+response.tenants[key].id+'" class="col-12 col-md-6 col-xl-3 property_card">';
										html += '<div class="card">';
											html += '<div class="card-header bg-light"><span class="accordion_arrow"><div class="text-center"><div class="input-group-prepend"><i class="align-middle mr-2 fas fa-fw fa-ellipsis-v" data-toggle="dropdown" aria-expanded="false"></i><div class="dropdown-menu dropdown-menu-right" style="">'+kebab+'</div></div></div></span><h5 class="card-title capitalize mb-0 mr-3">'+response.tenants[key].user.first_name+' '+response.tenants[key].user.last_name+'</h5><small>'+response.tenants[key].user.email+'</small></div>';
											html += '<div class="card-body">';
												html += '<table class="table mb-0 card-table word-break">';
													html += '<tbody>';
														html += '<tr>';
															html += '<td class="nowrap">Lease Start Date</td>';
															html += '<td class="text-right">'+start_date+'</td>';
														html += '</tr>';
														html += '<tr>';
															html += '<td class="nowrap">Lease End Date</td>';
															html += '<td class="text-right">'+end_date+'</td>';
														html += '</tr>';
														html += '<tr>';
															html += '<td class="nowrap">Monthly Rent</td>';
															html += '<td class="text-right bold-black">$'+monthly_rent_amount+'<br><small>Due on '+rent_due_date+' of the month</small></td>';
														html += '</tr>';
														html += '<tr>';
															html += '<td class="nowrap">Security Deposit</td>';
															html += '<td class="text-right bold-black">$'+security_deposit_amount+'<br><small>'+sd_due_date+'</small></td>';
														html += '</tr>';
														html += '<tr>';
															html += '<td class="nowrap">Pet Policy</td>';
															html += '<td class="text-right">'+pet_policy+'</td>';
														html += '</tr>';
														html += '<tr>';
															html += '<td class="nowrap">Pet Deposit</td>';
															html += '<td class="text-right bold-black">$'+pet_deposit_amount+'<br><small>'+pd_due_date+'</small></td>';
														html += '</tr>';
														html += '<tr>';
															html += '<td class="nowrap fs-14">';
																if(user.permissionData['PROPERTIES']["feature_items['tenant_link_lease']"]) {
						                                        	html += '<a href="#" data-lease-id="'+response.tenants[key].lease_ids+'" data-id="'+response.tenants[key].id+'" class="list_existing_lease" data-toggle="modal" data-target="#leaseListModal" data-backdrop="static" data-keyboard="false">Link Leases</a><br>';
						                                        }
															html += '</td>';
															html += '<td class="text-right fs-14">&nbsp;</td>';
														html += '</tr>';
													html += '</tbody>';
												html += '</table>';
												html += '<div class="text-right mt-4">'+status+'</div>';
											html += '</div>';
										html += '</div>';
									html += '</div>';
							    }
							}
						} else {
							// Card based UI

							html += '<div data-id="'+response.tenants[key].id+'" class="col-12 col-md-6 col-xl-3 property_card">';
								html += '<div class="card">';
									html += '<div class="card-header bg-light"><span class="accordion_arrow"><div class="text-center"><div class="input-group-prepend"><i class="align-middle mr-2 fas fa-fw fa-ellipsis-v" data-toggle="dropdown" aria-expanded="false"></i><div class="dropdown-menu dropdown-menu-right" style="">'+kebab+'</div></div></div></span><h5 class="card-title capitalize mb-0 mr-3">'+response.tenants[key].user.first_name+' '+response.tenants[key].user.last_name+'</h5><small>'+response.tenants[key].user.email+'</small></div>';
									html += '<div class="card-body">';
										html += '<table class="table mb-0 card-table word-break">';
											html += '<tbody>';
												html += '<tr>';
													html += '<td class="nowrap">Lease Start Date</td>';
													html += '<td class="text-right">'+start_date+'</td>';
												html += '</tr>';
												html += '<tr>';
													html += '<td class="nowrap">Lease End Date</td>';
													html += '<td class="text-right">'+end_date+'</td>';
												html += '</tr>';
												html += '<tr>';
													html += '<td class="nowrap">Monthly Rent</td>';
													html += '<td class="text-right bold-black">$'+monthly_rent_amount+'<br><small>Due on '+rent_due_date+' of the month</small></td>';
												html += '</tr>';
												html += '<tr>';
													html += '<td class="nowrap">Security Deposit</td>';
													html += '<td class="text-right bold-black">$'+security_deposit_amount+'<br><small>'+sd_due_date+'</small></td>';
												html += '</tr>';
												html += '<tr>';
													html += '<td class="nowrap">Pet Policy</td>';
													html += '<td class="text-right">'+pet_policy+'</td>';
												html += '</tr>';
												html += '<tr>';
													html += '<td class="nowrap">Pet Deposit</td>';
													html += '<td class="text-right bold-black">$'+pet_deposit_amount+'<br><small>'+pd_due_date+'</small></td>';
												html += '</tr>';
												html += '<tr>';
													html += '<td class="nowrap fs-14">';
														html += '<a href="#" data-lease-id="'+response.tenants[key].lease_ids+'" data-id="'+response.tenants[key].id+'" class="list_existing_lease" data-toggle="modal" data-target="#leaseListModal" data-backdrop="static" data-keyboard="false">Link Leases</a><br>';
													html += '</td>';
													html += '<td class="text-right fs-14">&nbsp;</td>';
												html += '</tr>';
											html += '</tbody>';
										html += '</table>';
										html += '<div class="text-right mt-4">'+status+'</div>';
									html += '</div>';
								html += '</div>';
							html += '</div>';		                	
						}
	                }
	            }
	        }
            $('#tenantDataContainer').html(html);
            // Get Floating Action Button
			getFloatingActionButton('Tenant', '#tenantDataContainer');
        } else {
            display_error_message(response);
        }
    }, function() {
        // hang on this step if the error occur
    });
}

window.getExistingTenantDetail = function(tenant_ids) {
	var user = JSON.parse(localStorage.getItem('user'));
    var tz = jstz.determine();
	var timezone = tz.name();
	var property_id = $('#selected_property_id').val();
	var user_id = user.user_details.user_id;

	var data = '';
	data = 'user_timezone='+timezone+'&property_id='+property_id+'&tenant_ids='+tenant_ids+'&user_id='+user_id;
	
	$.ajax({
	    method: 'POST',
	    url: api_url+"tenants",
	    data:data,
	    beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
	}).then(function(response) {
		if(response.response_data.success == true) {
			var html = '';
			if(response.TotalTenants == 0) {
	        	html += '<a href="javascript:void(0)" class="list-group-item list-group-item-action border-0 bg-light text-left"><div class="fs-16">'+response.response_data.message+'</div></a>';
		    } else {
		    	var html = '';
		    	for (var key in response.tenants) {
	                if (response.tenants.hasOwnProperty(key)) {

	                	var status = '';
	                    if(response.tenants[key].application_constant == 'draft') {
	                        status += '<span class="badge badge-danger">'+response.tenants[key].application_status+'</span>';
	                    } else if(response.tenants[key].application_constant == 'submitted') {
	                        status += '<span class="badge badge-info">'+response.tenants[key].application_status+'</span>';
	                    } else if(response.tenants[key].application_constant == 'invite_sent') {
	                        status += '<span class="badge badge-primary">'+response.tenants[key].application_status+'</span>';
	                    } else if(response.tenants[key].application_constant == 'signed_up') {
	                        status += '<span class="badge badge-success">'+response.tenants[key].application_status+'</span>';
	                    }

	                    html += '<div class="col-12">';
		                	html += '<div class="card mb-3 bg-light border">';
							    html += '<div class="p-2">';
							        html += '<div class="float-left">';
							            html += '<label class="custom-control custom-checkbox">';
							            html += '<input type="checkbox" name="existing_tenant_checkbox" class="custom-control-input existing_tenant_checkbox" data-id="'+response.tenants[key].id+'">';
							            html += '<span class="custom-control-label text-hide">Checkbox</span>';
							            html += '</label>';
							        html += '</div>';
							        html += '<div>'+response.tenants[key].user.first_name+' '+response.tenants[key].user.last_name+' ('+response.tenants[key].user.email+')</div>';
							        //html += '<div class="float-right mt-n1">';
							            //html += '<img src="icon/avatar.jpg" width="32" height="32" class="rounded-circle" alt="Avatar">';
							        //html += '</div>';
							        //html += status;
							    html += '</div>';
							html += '</div>';
						html += '</div>';
	                }
	            }
	        }
	        $('#existingTenantList').html(html);
        } else {
            display_error_message(response);
        }
    }, function() {
        // hang on this step if the error occur
    });
}

window.assignTenantToLease = function(property_id,lease_id,tenant_id,tenantArray) {
	var user_id = user.user_details.user_id;
	$.ajax({
        method: 'POST',
        url: api_url+"tenant-leases/add",
        data: 'user_id='+user_id+'&property_id='+property_id+'&lease_id='+lease_id+'&tenant_id='+tenant_id+'&tenant_lease_data='+tenantArray,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
            display_error_message(response);
            if(response.response_data.success == true) {
            	$('#tenantListModal').modal('hide');
            	openLoadingDialog($('#lease_data_container'));
	    		$('#lease_data_container').load('components/property/lease_detail_table.html');
	    		getPropertyLeaseWidgetInfo();
            } else {
            	$('.assign_tenant_to_lease').attr('disabled', false);
            }
        }
    });
}

/* End : Tenant */

/*window.loadPropertyOwnerDetail = function(module_name) {
	var user_address = JSON.parse(localStorage.getItem('user_address'));
	if(module_name == 'add_new_property') {
		$('#add_property_owner_firstname').val(user_address.user_details.first_name);
		$('#add_property_owner_lastname').val(user_address.user_details.last_name);
		$('#add_property_owner_businessname').val(user_address.user_details.business_name);
		$('#add_address_line1').val(user_address.user_details.address_line1);
		$('#add_address_line2').val(user_address.user_details.address_line2);
		$('#add_city').val(user_address.user_details.city);
		$('#add_state').val(user_address.user_details.state);
		$('#add_zip_code').val(user_address.user_details.zip_code);
		$('#add_country').val(user_address.user_details.country);
	} else if(module_name == 'property_detail') {
		$('#primary_contact_firstname').val(user_address.user_details.first_name);
        $('#primary_contact_lastname').val(user_address.user_details.last_name);
        $('#primary_contact_businessname').val(user_address.user_details.business_name);
        $('#primary_contact_address_line1').val(user_address.user_details.address_line1);
        $('#primary_contact_address_line2').val(user_address.user_details.address_line2);
        $('#primary_contact_city').val(user_address.user_details.city);
        $('#primary_contact_state').val(user_address.user_details.state);
        $('#primary_contact_zip_code').val(user_address.user_details.zip_code);
        $('#primary_contact_country').val(user_address.user_details.country);
	}
}*/

var $propertyForm;
window.addPropertyValidation = function() {
	$propertyForm = $("#property_add_form");
	$propertyForm.validate({
	    errorPlacement: function errorPlacement(error, element) {
	        $(element).parents(".form-group").append(error.addClass("invalid-feedback small d-block"))
	    },
	    highlight: function(element) {
	        $(element).addClass("is-invalid");
	    },
	    unhighlight: function(element) {
	        $(element).removeClass("is-invalid");
	    },
	    rules: {
	        "add_property_address_line1": { required: true },
	        "add_property_city": { required: true },
	        "add_property_state": { required: true },
	        "add_property_zip_code": { required: true },
	        "add_property_country": { required: true },
	    },
	    messages: {
	        "add_property_address_line1": { required: '' },
	        "add_property_city": { required: '' },
	        "add_property_state": { required: '' },
	        "add_property_zip_code": { required: '' },
	        "add_property_country": { required: '' },
	    }
	});
}

var $editPropertyForm;
window.editPropertyValidation = function() {
	$editPropertyForm = $("#property_edit_form");
	$editPropertyForm.validate({
	    errorPlacement: function errorPlacement(error, element) {
	        $(element).parents(".form-group").append(error.addClass("invalid-feedback small d-block"))
	    },
	    highlight: function(element) {
	        $(element).addClass("is-invalid");
	    },
	    unhighlight: function(element) {
	        $(element).removeClass("is-invalid");
	    },
	    rules: {
	        "edit_property_address_line1": { required: true },
	        "edit_property_city": { required: true },
	        "edit_property_state": { required: true },
	        "edit_property_zip_code": { required: true },
	        "edit_property_country": { required: true },
	    },
	    messages: {
	        "edit_property_address_line1": { required: '' },
	        "edit_property_city": { required: '' },
	        "edit_property_state": { required: '' },
	        "edit_property_zip_code": { required: '' },
	        "edit_property_country": { required: '' },
	    }
	});
}

var $propertyContactForm;
window.addPropertyContactValidation = function() {
	$propertyContactForm = $("#property-primary-contact-address");
	$propertyContactForm.validate({
	    errorPlacement: function errorPlacement(error, element) {
	        $(element).parents(".form-group").append(error.addClass("invalid-feedback small d-block"))
	    },
	    highlight: function(element) {
	        $(element).addClass("is-invalid");
	    },
	    unhighlight: function(element) {
	        $(element).removeClass("is-invalid");
	    },
	    rules: {
	        "primary_contact_firstname": { required: true },
	        "primary_contact_lastname": { required: true },
	        "primary_contact_businessname": { required: true },
	        "primary_contact_address_line1": { required: true },
	        "primary_contact_city": { required: true },
	        "primary_contact_state": { required: true },
	        "primary_contact_zip_code": { required: true },
	        "primary_contact_country": { required: true },
	    },
	    messages: {
	    	"primary_contact_firstname": { required: '' },
	        "primary_contact_lastname": { required: '' },
	        "primary_contact_businessname": { required: '' },
	        "primary_contact_address_line1": { required: '' },
	        "primary_contact_city": { required: '' },
	        "primary_contact_state": { required: '' },
	        "primary_contact_zip_code": { required: '' },
	        "primary_contact_country": { required: '' },
	    }
	});
}

window.savePropertyData = function() {
	$('.save_property_data').attr('disabled', true);
	var property_name =  $('#add_property_address_line1').val();
    $.ajax({
        method: 'POST',
        url: api_url+"properties/add",
        data: getPropertyFileObj(),
        processData: false,
        contentType: false,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
            display_error_message(response);
            if(response.response_data.success == true) {

            	var property_id = response.property_id;

            	// Below code for initial signup step
				var first_time_signup = $('#first_time_signup').val();
				if(first_time_signup != 1) {
	            	$('#propertyAddModal').modal('hide');
	            	var layout = $('#selected_layout').val();
	            	if(layout == 'card_layout') {
	            		$('#main_container').load('components/properties.html', function(){
	            			$('#propertyCheckoutModal').modal('show');
	                		$('#property_detail_container,#edit_property_address_container,#property_setup_checkout_container').html('');
	            		});
	            	} else {
	            		$('#main_container').load('components/property/property_table.html', function() {
	            			$('#propertyCheckoutModal').modal('show');
	                		$('#property_detail_container,#edit_property_address_container,#property_setup_checkout_container').html('');
	            		});
	            	}
	            } else {
	            	$('#first_property_id').val(property_id);
	            	var user = JSON.parse(localStorage.user);
					user.user_details.first_property_id = property_id;
					localStorage.setItem("user", JSON.stringify(user));  //put the object back
	            }
            	
                checkIfPropertySetupPlanAvailable(property_id);
            } else {
            	$('.save_property_data').attr('disabled', false);
            }
        }
    });
}

window.updatePropertyAddress = function() {
	var property_id = $('#edit_property_id').val();
    $.ajax({
        method: 'POST',
        url: api_url+"properties/edit/"+property_id,
        data: getEditPropertyFileObj(),
        processData: false,
        contentType: false,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
            display_error_message(response);
            if(response.response_data.success == true) {
            	$('#propertyEditModal').modal('hide');
            	var layout = $('#selected_layout').val();
            	var property_payment_status = $('#property_payment_status').val();
            	if(property_payment_status == 1) {
            		if(layout == 'card_layout') {
						$('#main_container').load('components/properties.html', function(){
	            			$('#propertyCheckoutModal').modal('show');
	                		$('#property_detail_container,#edit_property_address_container,#property_setup_checkout_container').html('');
	            		});
					} else {
						$('#main_container').load('components/property/property_table.html', function() {
	            			$('#propertyCheckoutModal').modal('show');
	                		$('#property_detail_container,#edit_property_address_container,#property_setup_checkout_container').html('');
	            		});
					}

					checkIfPropertySetupPlanAvailable(property_id);
            	} else {
            		if(layout == 'card_layout') {
						$('#main_container').load('components/properties.html');
					} else {
						$('#main_container').load('components/property/property_table.html');
					}
					$('#property_detail_container,#edit_property_address_container,#property_setup_checkout_container').html('');
            	}
            }
        }
    });
}

window.getPropertyFileObj = function() {
	var first_time_signup = $('#first_time_signup').val();
	var user_id = user.user_details.user_id;
	var address_line1 = $('#add_property_address_line1').val();
	var address_line2 = $('#add_property_address_line2').val();
	var formData = new FormData();
	formData.append('first_time_signup', first_time_signup);
    formData.append('user_id', user_id);
    //formData.append('name', $('#add_property_name').val());
    formData.append('name', address_line1+' '+address_line2);
    formData.append('address_line1', $('#add_property_address_line1').val());
    formData.append('address_line2', $('#add_property_address_line2').val());
    formData.append('city', $('#add_property_city').val());
    formData.append('state', $('#add_property_state').val());
    formData.append('zip_code', $('#add_property_zip_code').val());
    formData.append('country', $('#add_property_country').val());
    formData.append('usps_verified', 1);
    
    // Upload Property files
    for (var x = 0; x < addPropertyImageDropzone.files.length; x++) {
        formData.append("attachment[]", addPropertyImageDropzone.files[x]);
    }

    formData.append('plan_id', $('#property_plan_id').val());
    formData.append('coupon_id', $('#property_coupon_id').val());
    formData.append('coupon_code', $('#coupon_code').val());

    return formData;
}

window.getEditPropertyFileObj = function() {
	var formData = new FormData();
	var user_id = user.user_details.user_id;
    formData.append('name', $('#edit_property_address_line1').val()+' '+$('#edit_property_address_line2').val());
    formData.append('address_line1', $('#edit_property_address_line1').val());
    formData.append('address_line2', $('#edit_property_address_line2').val());
    formData.append('city', $('#edit_property_city').val());
    formData.append('state', $('#edit_property_state').val());
    formData.append('zip_code', $('#edit_property_zip_code').val());
    formData.append('country', $('#edit_property_country').val());
    formData.append('type','card_edit');
    formData.append('usps_verified', 1);

    // Upload Property files
    for (var x = 0; x < editPropertyImageDropzone.files.length; x++) {
        formData.append("attachment[]", editPropertyImageDropzone.files[x]);
    }

    formData.append('user_id', user_id);
    formData.append('plan_id', $('#property_plan_id').val());
    formData.append('coupon_id', $('#property_coupon_id').val());
    formData.append('coupon_code', $('#coupon_code').val());

    return formData;
}

window.updatePropertyCoverImage = function(attachment_id) {
	$.ajax({
	    method: 'POST',
	    url: api_url+"properties/update-cover-image",
	    data:'attachment_id='+attachment_id,
	    beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
	}).then(function(response) {
		display_error_message(response);
	    if(response.response_data.success == true) {
	    	
	    }
	}, function() {
	    // hang on this step if the error occur
	});
}

window.getPropertyUserList = function(property_id) {
	$.ajax({
        method: 'POST',
        url: api_url+"property-users",
        data: 'property_id='+property_id,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
    }).then(function(response) {
        if(response.response_data.success == true) {
            var html = "";
            html += '<table id="property-user-datatable" class="table table-striped" style="width:100%">';
            html += '<tbody>';
	            if(response.totalUsers == 0) {
		        	html += '<a href="javascript:void(0)" class="list-group-item list-group-item-action border-0 bg-light text-left"><div class="fs-16">'+response.response_data.message+'</div></a>';
			    } else {
		            for (var key in response.propertyUsers) {
		                if (response.propertyUsers.hasOwnProperty(key)) {
		                	//var user_name = response.propertyUsers[key].user.first_name+' '+response.propertyUsers[key].user.last_name;
		                	var user_name = response.propertyUsers[key].user.first_name;
		                	html += '<tr><td><label class="custom-control custom-checkbox"><input name="property_user_name" type="checkbox" class="custom-control-input" data-user-id="'+response.propertyUsers[key].user_id+'" data-username="'+user_name+'"><span class="custom-control-label">'+user_name+'</span></label></td></tr>';
		                }
		            }
		        }
            html += '</tbody>';
            html += '</table>';
            $('#property_user_container').html(html);
        } else {
            display_error_message(response);
        }
    }, function() {
        // hang on this step if the error occur
    });
}

window.deleteProperty = function(property_id) {
	var user_id = user.user_details.user_id;
	$.ajax({
        method: 'POST',
        url: api_url+"properties/delete",
        data: "id="+property_id+'&user_id='+user_id,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
            display_error_message(response);
            if(response.response_data.success == true) {
                var page_name = localStorage.getItem('page_name');
                $('#main_container').load('components/'+page_name+'.html');
            }
        }
    });
}

window.deletePropertyImage = function(attachment_id) {
	var user_id = user.user_details.user_id;
	$.ajax({
        method: 'POST',
        url: api_url+"properties/change-image",
        data:'attachment_id='+attachment_id+'&user_id='+user_id,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
            display_error_message(response);
            if(response.response_data.success == true) {
                $('#user_property_media_'+attachment_id).remove();
                $('#user_property_attachment_'+attachment_id).remove();
                openLoadingDialog($('#document_data_container'));
	    		$('#document_data_container').load('components/property/document_detail.html');
	    		getPropertyPhotoWidgetInfo();
            }
        }
    });
}

window.unDeletePropertyImage = function(attachment_id) {
	var user_id = user.user_details.user_id;
	$.ajax({
        method: 'POST',
        url: api_url+"properties/undelete",
        data:'attachment_id='+attachment_id+'&user_id='+user_id,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
            display_error_message(response);
            if(response.response_data.success == true) {
                openLoadingDialog($('#document_data_container'));
	    		$('#document_data_container').load('components/property/document_detail.html');
            }
        }
    });
}

window.loadChatroomData = function(user_id,property_id) {
	$.ajax({
	    method: 'POST',
	    url: api_url+"chatrooms",
	    data:'property_id='+property_id+'&logged_in_user='+user_id,
	    beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
	}).then(function(response) {
	    if(response.response_data.success == true) {
	    	var html = "";
	    	var i = 0;
	    	if(response.totalChatrooms == 0) {
	        	html += '<a href="javascript:void(0)" class="list-group-item list-group-item-action border-0 bg-light text-left"><div class="fs-16">'+response.response_data.message+'</div></a>';
		    } else {
		        for (var key in response.chatrooms) {
		        	if (response.chatrooms.hasOwnProperty(key)) {
		        		var chatroom_name = response.chatrooms[key].name;
		                html += '<a data-id="'+response.chatrooms[key].id+'" href="javascript:void(0)" class="list-group-item list-group-item-action border-0 chatroom_list"><div class="media"><img src="icon/avatar.jpg" class="rounded-circle mr-1" width="50" height="50"><div class="media-body ml-3 mt-3"><strong id="chatroom-'+response.chatrooms[key].id+'">'+chatroom_name+'</strong></div></div></div></a>';
		            }
		        }
		    }
		    $('#chatroom_info_container').html(html);
	    } else {
	       display_error_message(response);
	    }
	}, function() {
	    // hang on this step if the error occur
	});
}

window.saveChatroomData = function(user_id,property_id,name,chatroom_users) {
	var property_name = $('.property_name_span').html();
	$.ajax({
        method: 'POST',
        url: api_url+"chatrooms/add",
        data: 'user_id='+user_id+'&property_id='+property_id+'&name='+name+'&chatroom_users='+chatroom_users,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
            display_error_message(response);
            if(response.response_data.success == true) {
            	var chatroom_id = response.chatroom_id;
            	$('#chatroomAddModal').modal('hide');
            	$('#main_container').load('components/chat.html', function() {
			    	$('#chatroom_property_id').val(property_id);
			    	$('.property_name_span').html(property_name);
			    	loadChatroomData(user_id,property_id);
				});
            }
        }
    });
}

window.getChatroomMessage = function(chatroom_id) {
	$('.chat_info_container_right').removeClass('add_opacity');
	var tz = jstz.determine();
	var timezone = tz.name();
	$.ajax({
        method: 'POST',
        url: api_url+"conversations",
        data: 'chatroom_id='+chatroom_id+'&user_timezone='+timezone,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
            if(response.response_data.success == true) {
            	$('.chatroom_name').text($('#chatroom-'+chatroom_id).text());
            	$('#chatroom_id').val(chatroom_id);
            	var html = "";
            	if(response.totalConversations == 0) {
		        	//html += '<a href="javascript:void(0)" class="list-group-item list-group-item-action border-0"><div class="fs-16">'+response.response_data.message+'</div></a>';
			    } else {
	            	for (var key in response.conversations) {
				        if (response.conversations.hasOwnProperty(key)) {
				        	var user_label = '';
				        	var align_class = '';
				        	var message = response.conversations[key].message;
				        	if(user.user_details.user_id == response.conversations[key].user_id) {
				        		user_label = 'You';
				        		align_class = 'chat-message-right';
				        	} else {
				        		user_label = response.conversations[key].user.first_name+' '+response.conversations[key].user.last_name;
				        		align_class = 'chat-message-left';
				        	}
				        	html += '<div class="'+align_class+' pb-4"> <div> <img src="icon/avatar.jpg" class="rounded-circle mr-1" alt="Chris Wood" width="40" height="40"> <div class="text-muted small text-nowrap mt-2">&nbsp;</div> </div> <div class="flex-shrink-1 bg-light rounded py-2 px-3 mr-3"> <div class="font-weight-bold mb-1">'+user_label+'</div>'+message+' <div class="text-muted small text-nowrap mt-2">'+response.conversations[key].converted_created+'</div></div></div>';
				        }
				    }
				}
			    $('#chat-messages').html(html);
            } else {
            	display_error_message(response);
            }
        }
    });
}

window.saveChatroomMessage = function(user_id,chatroom_id,chatroom_message) {
	$.ajax({
        method: 'POST',
        url: api_url+"conversations/add",
        data: 'user_id='+user_id+'&chatroom_id='+chatroom_id+'&message='+chatroom_message,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
            if(response.response_data.success == true) {
            	$('#chatroom_message').val('');
            	//getChatroomMessage(chatroom_id);
            	$('#chat-messages').scrollTop($('#chat-messages')[0].scrollHeight);
            } else {
            	display_error_message(response);	
            }
        }
    });
}

window.loadTicketList = function(layout) {

	var user = JSON.parse(localStorage.getItem('user'));
    var tz = jstz.determine();
	var timezone = tz.name();
	var property_id = $('#selected_property_id').val();
	var user_id = user.user_details.user_id;
	var ticket_open_from = $('#ticket_open_from').val();

	var data = '';
	if(property_id > 0) {
		data = 'user_id='+user_id+'&user_service_name='+user.user_details.service_constant+'&user_timezone='+timezone+'&property_id='+property_id+'&ticket_open_from='+ticket_open_from;
	} else {
		data = 'user_id='+user_id+'&user_service_name='+user.user_details.service_constant+'&user_timezone='+timezone+'&ticket_open_from='+ticket_open_from;
	}

	$.ajax({
	    method: 'POST',
	    url: api_url+"tickets",
	    data:data,
	    beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
	    success: function(response) {
	    	if(response.response_data.success == true) {
	            var html = '';
	            if(response.TotalTickets == 0) {
		        	html += '<a href="javascript:void(0)" class="list-group-item list-group-item-action border-0 bg-light text-left"><div class="fs-16">'+response.response_data.message+'</div></a>';
			    } else {
		            for (var key in response.tickets) {
		                if (response.tickets.hasOwnProperty(key)) {

		                	var bg_light = '';
		                	if(key % 2 == 1) {
		                		bg_light = 'bg-light';
		                	}

		                	//var submitted_by = response.tickets[key].user.first_name+' '+response.tickets[key].user.last_name+' on '+response.tickets[key].converted_created;
		                	var submitted_by = response.tickets[key].user.first_name+' '+response.tickets[key].user.last_name+'<br>'+response.tickets[key].user.email;

		                	var description = response.tickets[key].description;
		                	if (description != null && description.length > 50) {
							    description = description.substr(0, 50) + ' ...';
							}

		                    var status = '';
		                    var del_btn = '';
		                    if(response.tickets[key].status == 0) {
		                        status += '<span class="badge badge-info">Draft</span>';
		                        if(user.permissionData['SUPPORT']["feature_items['delete']"]) {
		                        	del_btn = '<a class="dropdown-item delete_ticket_data" href="javascript:void(0)" data-id="'+response.tickets[key].id+'">Delete</a>';
		                        }
		                    } else if(response.tickets[key].status == 1) {
		                    	status += '<span class="badge badge-primary">Open</span>';
		                    } else if(response.tickets[key].status == 2) {
		                        status += '<span class="badge badge-danger ticket_closed">Closed</span>';
		                    } else if(response.tickets[key].status == 3) {
		                        status += '<span class="badge badge-success">Re-open</span>';
		                    }

		                    var kebab = '';
		                    if(response.tickets[key].user_id == user_id || response.tickets[key].status != 0) {
		                    	kebab = '<div class="text-center"><div class="input-group-prepend"><i class="align-middle mr-2 fas fa-fw fa-ellipsis-v" data-toggle="dropdown" aria-expanded="false"></i><div class="dropdown-menu dropdown-menu-right" style=""><a class="dropdown-item ticket_table_row" href="javascript:void(0)" data-id="'+response.tickets[key].id+'">Details</a>'+del_btn+'</div></div></div>';
		                    }

		                    var property_name = '';
			                if(response.tickets[key].property != null) {
			                	property_name = response.tickets[key].property.name;
			                }

			                var address = '';
			                if(response.tickets[key].property != null && response.tickets[key].property.address != null) {
			                	address = response.tickets[key].property.address.address_line1+', '+response.tickets[key].property.address.address_line2+'<br>'+response.tickets[key].property.address.city+', '+response.tickets[key].property.address.state+' '+response.tickets[key].property.address.zip_code;
			                }

			                var title = "";
			                if(response.tickets[key].title != null) {
			                	title = response.tickets[key].title;
			                }

			                if(description == null) {
			                	description = '';
			                }

			                // Card based UI
			                if(layout == 'table_layout') {
			                	html += '<tr data-id="'+response.tickets[key].id+'">';
			                		html += '<td class="bg-light">'+response.tickets[key].id+'</td>';
			                		html += '<td class="bg-light"><h5 class="card-title capitalize mb-0 mr-3">'+response.tickets[key].id+' - <span id="ticket-title-'+response.tickets[key].id+'">'+title+'</span></h5></td>';
			                		html += '<td>'+address+'</td>';
			                		html += '<td><div id="ticket-description-'+response.tickets[key].id+'">'+description+'</div></td>';
		                            html += '<td>'+submitted_by+'</td>';
		                            html += '<td>'+response.tickets[key].converted_created+'</td>';
		                            html += '<td>'+status+'</td>';
		                            html += '<td>'+kebab+'</td>';
	                        	html += '</tr>';
			                } else {
				                html += '<div data-id="'+response.tickets[key].id+'" class="col-12 col-md-6 col-xl-3 property_card">';
									html += '<div class="card">';
										html += '<div class="card-header bg-light"><span class="accordion_arrow">'+kebab+' </span><h5 class="card-title capitalize mb-0 mr-3">'+response.tickets[key].id+' - <span id="ticket-title-'+response.tickets[key].id+'">'+title+'</span></h5></div>';
										html += '<div class="card-body">';
											html += '<table class="table mb-0 card-table word-break">';
												html += '<tbody>';
													html += '<tr>';
														html += '<td class="nowrap">Submitted By</td>';
														html += '<td class="text-right">'+submitted_by+'</td>';
													html += '</tr>';
													html += '<tr>';
														html += '<td class="nowrap">Submitted On</td>';
														html += '<td class="text-right">'+response.tickets[key].converted_created+'</td>';
													html += '</tr>';
													html += '<tr>';
														html += '<td class="nowrap bold-black" colspan="2">Description</td>';
													html += '</tr>';
													html += '<tr>';
														html += '<td colspan="2"><div id="ticket-description-'+response.tickets[key].id+'">'+description+'</div></td>';
													html += '</tr>';
													if(response.tickets[key].property_id > 0) {
														html += '<tr>';
															html += '<td class="nowrap">Address</td>';
															html += '<td class="text-right"><div class="card-text capitalize">'+address+'</div></td>';
														html += '</tr>';
													}
												html += '</tbody>';
											html += '</table>';
											html += '<div class="text-right mt-4">'+status+'</div>';
										html += '</div>';
									html += '</div>';
								html += '</div>';
							}
		                }
		            }
		        }

		        if(layout == 'table_layout') {
		        	$('#ticket_table_row').html(html);

		        	var column = [
			            { className: 'all', targets: [0], width:'3%', orderable: true},
			            { targets: [1], width:'25%', orderable: true},
			            { targets: [2], width:'10%', orderable: true},
			            { targets: [3], width:'10%', orderable: true},
			            { targets: [4], width:'10%', orderable: true},
			            { targets: [5], width:'10%', orderable: false},
			            { targets: [6], width:'10%', orderable: false},
			            { targets: [7], width:'5%', orderable: false}
			        ]; 

		            var ticket_table = $("#ticket-datatable").DataTable({
		            	columnDefs: column,
		            	dom: 'Bfrtip',
		    			autoWidth: true,
		            	paging: false,
		            	info: false,
					    scrollY: '575px',
					    scrollX: true,
				        scrollCollapse: true,
				        order: [[0,'desc']],
				        buttons: [
				           {
				              extend: 'colvis',
				              text: "Columns Visibility ",
				              collectionLayout: 'two-column',
				              postfixButtons: ['colvisRestore'],
				           }
						],
						language: {
					        buttons: {
					          colvisRestore: "Select All"
					        }
					    },
						initComplete: function () {
				          	//console.log("The Table has been initialized!")
				        }
					});

					$('.buttons-colvis').removeClass('btn-secondary').addClass('btn-primary');
		        } else {
	            	$('.ticket_data_container').html(html);
	            }
	            // Get Floating Button
	            if(property_id > 0) {
	            	getFloatingActionButton('Ticket','#service_request_data_container');
	            } else {
	            	getFloatingActionButton('Ticket','.ticket_data_container');
	            }
	        } else {
	            display_error_message(response);
	        }
	    }
	});
}

window.loadTicketDetail = function(ticket_id, type, status) {
	var tz = jstz.determine();
	var timezone = tz.name();
	$.ajax({
        method: 'POST',
        url: api_url+"tickets/view/"+ticket_id,
        data: 'user_timezone='+timezone,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
	    success: function(response) {
	    	if(response.response_data.success == true) {
	        	if(type == 'vertical') {
	                $('.ticket_info_container_right').removeClass('add_opacity');
	            }

	            $('#ticket_id').val(ticket_id);
	            
	            var property_id = response.ticket.property_id;
	            var title = response.ticket.title;
	            var description = response.ticket.description;
	            var ticket_user_name = response.ticket.user.first_name+' '+response.ticket.user.last_name;
	            var created = response.ticket.created;
	            var converted_created = response.ticket.converted_created;
	            var status = response.ticket.status;

	            if(status == 0) {
	            	$('.current_status').html('<span class="badge badge-info">Draft</span>');
	            } else if(status == 1) {
	            	$('.current_status').html('<span class="badge badge-success">Open</span>');
	            } else if(status == 2) {
	            	$('.current_status').html('<span class="badge badge-danger">Closed</span>');
	            } else if(status == 3) {
	            	$('.current_status').html('<span class="badge badge-success">Re-open</span>');
	            }

	            if(status == 0) {
	            	$('#ticketEditAccordion').removeClass('d-none');
	            	$('#ticket_edit_form').load('components/ticket/add_new_ticket.html', function(){
	            		$('#tickets-title').val(title);
	            		if(description != null){
	            			desc_editor.value(description);
	            		}
	            		$('#ticket_open_from').val('detail');
	            		$('#add_ticket_id').val(ticket_id);
	            		$('#ticket_property_id').val(response.ticket.property_id);
	            		$('#ticket_modal_heading').text('Draft Ticket');

	            		if(user_service_name == sa_role) {
		            		if(property_id > 0) {
						    	getPropertyRelatedUsers(property_id, response.ticket.to_whom);
						    } else {
						    	$('#to_whom_container').html('');
						    }
						}
	                });
	            } else if(status == 1) {
	            	$('#ticketDetailAccordion').removeClass('d-none');
	            	$('#ticketEditAccordion').addClass('d-none');
	            	$('.reopen_ticket_comment').css('display','none');
	            	$('.close_ticket_comment').css('display','inline-block');
	            } else if(status == 2) {
	            	$('#ticketDetailAccordion').removeClass('d-none');
	            	$('#ticketEditAccordion').addClass('d-none');
	            	$('.reopen_ticket_comment').css('display','inline-block');
	            	$('.close_ticket_comment').css('display','none');
	            } else if(status == 3) {
	            	$('#ticketDetailAccordion').removeClass('d-none');
	            	$('#ticketEditAccordion').addClass('d-none');
	            	$('.reopen_ticket_comment').css('display','none');
	            	$('.close_ticket_comment').css('display','inline-block');
	            } else {
	            	$('#ticketDetailAccordion').removeClass('d-none');
	            	$('#ticketEditAccordion').addClass('d-none');
	            }

	            getCommentList(ticket_id, title, ticket_user_name, description, converted_created, created);
	        } else {
	            display_error_message(response);
	        }
	    }
    });
}

window.getCommentList = function(ticket_id, title, ticket_user_name, description, converted_created, created) {
	// Get selected ticket comment list
    $('#comment_section').html('');

    var ticket_data = "";
    		
	// ticket detail
	ticket_data += "<div class='col-12 col-lg-12 d-flex'><h1 class='h3 ml-3 mr-3 mb-4'>#"+ticket_id+' '+title+"</h1></div>";
	ticket_data += '<div class="col-12 col-lg-12 d-flex"> <div class="card shadow flex-fill w-100"> <div class="card-header bg-light"><h5 class="card-title mb-0 comment-title">'+ticket_user_name+'</h5> created this ticket on '+converted_created+' ('+created+') </div> <div id="ticket_description" class="p-4">'+description+'</div> </div> </div>';

	$('#comment_section').append(ticket_data);
	parse_markdown('ticket_description');

	var tz = jstz.determine();
	var timezone = tz.name();

    $.ajax({
        method: 'POST',
        url: api_url+"comments",
        data: 'module_id='+ticket_id+'&module_name=tickets&user_timezone='+timezone,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
    }).then(function(response) {
    	if(response.response_data.success == true) {
    		for (var key in response.comments) {
	            if (response.comments.hasOwnProperty(key)) {
	            	var comment_data = "";
	            	var user_name = response.comments[key].user.first_name+' '+response.comments[key].user.last_name;
	                comment_data += '<div id="comment_container_'+response.comments[key].id+'" class="col-12 col-lg-12 d-flex"> <div class="card flex-fill w-100 shadow"> <div class="card-header bg-light"> <div class="card-actions float-right d-none"> <div class="dropdown show"> <a href="#" data-toggle="dropdown" data-display="static"> <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-more-horizontal align-middle"> <circle cx="12" cy="12" r="1"></circle> <circle cx="19" cy="12" r="1"></circle> <circle cx="5" cy="12" r="1"></circle> </svg> </a> <div class="dropdown-menu dropdown-menu-right"> <a class="dropdown-item edit_comment" href="javascript:void(0)" data-id="'+response.comments[key].id+'">Edit</a> <a class="dropdown-item delete_comment" href="javascript:void(0)" data-id="'+response.comments[key].id+'">Delete</a> </div> </div> </div> <h5 class="card-title mb-0 comment-title">'+user_name+'</h5> commented at '+response.comments[key].converted_created+' ('+response.comments[key].created+')</div> <div id="comment_description_'+response.comments[key].id+'" class="p-4">'+response.comments[key].comment+'</div></div> </div>';

	                $('#comment_section').append(comment_data);
	                parse_markdown('comment_description_'+response.comments[key].id);
	            }
	        }
    	} else {
    		display_error_message(response);
    	}
    }, function() {
        // hang on this step if the error occur
    });
}

var $ticketForm;
window.addTicketValidation = function() {
	$ticketForm = $("#ticket_add_form");
	$ticketForm.validate({
	    errorPlacement: function errorPlacement(error, element) {
	        $(element).parents(".form-group").append(error.addClass("invalid-feedback small d-block"))
	    },
	    highlight: function(element) {
	        $(element).addClass("is-invalid");
	    },
	    unhighlight: function(element) {
	        $(element).removeClass("is-invalid");
	    },
	    rules: {
	        "tickets-property-id": { required: true },
	        "tickets-title": { required: true },
	    },
	    messages: {
	        "tickets-property-id": { required: '' },
	        "tickets-title": { required: '' }
	    }
	});
}

window.getPropertyRelatedUsers = function(property_id, to_whom = '') {
	$.ajax({
       	method: 'POST',
	    url: api_url+"properties/get-users",
	    data:'property_id='+property_id,
	    beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	display_error_message(response);
            if(response.response_data.success == true) {
            	var html = '';
            	html += '<p>Send email to</p>';
            	html += '<select name="to_whom_dd" class="form-control to_whom_dd mt-3" data-toggle="select2" multiple>';
	            	//html += "<option value=''>Select User</option>";
	            	for (var key in response.userData) {
		                if (response.userData.hasOwnProperty(key)) {
	                		//var tenant_name = response.userData[key].name+' ('+response.userData[key].email+')';
	                		var tenant_name = response.userData[key].name+' ('+response.userData[key].type+')';
	                		html += '<option data-user-id="'+response.userData[key].user_id+'" value="'+response.userData[key].user_id+'">'+tenant_name+'</option>';
						}
					}
				html += '</select>';
				$('#to_whom_container').html(html);

				$(".to_whom_dd").each(function() {
				    $(this)
				        .wrap("<div class=\"position-relative\"></div>")
				        .select2({
				            placeholder: "Select users",
				            dropdownParent: $(this).parent()
				        });
				})

				if(to_whom != null) {
	            	var userIds = to_whom.split(',');
					$(".to_whom_dd").val(userIds).change();
				}
            }
        }
    });
}

window.saveDraftTicket = function() {
	var user_id = user.user_details.user_id;
	var property_id = $("#selected_property_id").val();

	if(typeof property_id === 'undefined') {
		var data = 'user_id='+user_id;
	} else {

		var to_whom = '';
		if(user_service_name == sa_role) {
			var to_whom = $('.to_whom_dd').find(':selected').map(function() { 
			    return $(this).attr("data-user-id"); 
			}).get().join(',');
		}

		var property_id = $("#selected_property_id").val();
		var data = 'user_id='+user_id+'&property_id='+property_id+'&to_whom='+to_whom;
	}

	$.ajax({
        method: 'POST',
        url: api_url+"tickets/add",
        data: data,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
            display_error_message(response);
            if(response.response_data.success == true) {
            	var ticket_id = response.id;
                
                if(typeof property_id === 'undefined') {
                	$('#ticketAddModal').modal('show');
                	var container = 'ticket_add_container';
                	if($('#selected_layout').val() == 'table_layout') {
	                	$('#open_from').val('table');
			        	$('#selected_layout').val('table_layout');
			        	$('.ticket_data_container').load('components/ticket/ticket_table.html');
	                } else {
	                	loadTicketList();
	                }
                } else {
                	$('#propertyTicketAddModal').modal('show');
                	var container = 'property_ticket_detail_container';
	                openLoadingDialog($('#service_request_data_container'));
	    			//$('#service_request_data_container').load('components/ticket/ticket_table.html');
	    			$('#service_request_data_container').load('components/support.html');
	            }

	            $('#'+container).load('components/ticket/add_new_ticket.html', ticket_id, function(){
	            	$('#add_ticket_id').val(ticket_id);
	            	var property_id = $("#selected_property_id").val();
	            	if(typeof property_id !== 'undefined') {
	                	setTimeout(function(){
						    $('#tickets-property-id').val(property_id);
						}, 500);
                	}
                });
            }
        }
    });
}

window.updateDraftTicket = function(element) {
	var field = '';
	if(element == 'tickets-property-id') {
    	field = $('#'+element).val();
    } else if(element == 'tickets-title') {
    	field = $('#'+element).val();
    } else if(element == 'desc_editor') {
    	field = desc_editor.value();
    } else if(element == 'to_whom_dd') {
    	var field = $('.to_whom_dd').find(':selected').map(function() { 
		    return $(this).attr("data-user-id"); 
		}).get().join(',');
    }

    clearTimeout(typingTimer);

    if($.trim(field) != "") {
    	typingTimer = setTimeout(function(){

    		$('.save_ticket_data').text('Saving ...');

            //do stuff here e.g ajax call etc....
            var property_id = $('#tickets-property-id').val();
            var title = $('#tickets-title').val();
            var description = desc_editor.value();
            var user_id = user.user_details.user_id;
            
            if($('#ticket_open_from').val() == "detail") {
        		var ticket_id = $('#ticket_id').val();
        	} else {
        		var ticket_id = $('#add_ticket_id').val();
        	}

        	var to_whom = $('.to_whom_dd').find(':selected').map(function() { 
			    return $(this).attr("data-user-id"); 
			}).get().join(',');

            var data = '';
			if(element == 'tickets-property-id') {
			    data = 'user_id='+user_id+'&property_id='+property_id+'&title='+btoa(unescape(encodeURIComponent(title)))+'&description='+btoa(unescape(encodeURIComponent(description)))+'&status=0'+'&to_whom='+to_whom;
			} else if(element == 'tickets-title') {
			    data = 'user_id='+user_id+'&property_id='+property_id+'&title='+btoa(unescape(encodeURIComponent(field)))+'&description='+btoa(unescape(encodeURIComponent(description)))+'&status=0'+'&to_whom='+to_whom;
			} else if(element == 'to_whom_dd') {
			    data = 'user_id='+user_id+'&property_id='+property_id+'&title='+btoa(unescape(encodeURIComponent(title)))+'&description='+btoa(unescape(encodeURIComponent(description)))+'&status=0'+'&to_whom='+to_whom;
			} else {
			    data = 'user_id='+user_id+'&property_id='+property_id+'&description='+btoa(unescape(encodeURIComponent(field)))+'&title='+btoa(unescape(encodeURIComponent(title)))+'&status=0'+'&to_whom='+to_whom;
			}

            $.ajax({
		        method: 'POST',
		        url: api_url+"tickets/edit/"+ticket_id,
		        data: data,
		        beforeSend: function(xhr) {
			        xhr.setRequestHeader('Authorization', accessToken);
			    },
		        success: function(response) {
		            if(response.response_data.success == true) {
		            	$('.save_ticket_data').text('Submit');
		            	if($('#ticket_open_from').val() == "detail") {
		            		$('#ticket-title-'+ticket_id).text(title);	
		            		$('#ticket-description-'+ticket_id).text(description);	
		            	} else {
		            		if($('#selected_layout').val() == 'table_layout') {
		            			$('#open_from').val('table');
					        	$('#selected_layout').val('table_layout');
					        	$('.ticket_data_container').load('components/ticket/ticket_table.html');
		            		} else {
		            			loadTicketList();
		            		}
		            	}
		            } else {
		            	display_error_message(response);
		            }
		        }
		    });
        }, doneTypingInterval);
    }
}

window.saveTicketData = function() {
	if($ticketForm.valid()){
		$('.save_ticket_data').attr('disabled', true);

		// Check validaiton for user dropdown
		var to_whom = '';
		if(user_service_name == sa_role) {
			var to_whom = $('.to_whom_dd').find(':selected').map(function() { 
			    return $(this).attr("data-user-id"); 
			}).get().join(',');

			if(to_whom == '') {
				$('.save_ticket_data').attr('disabled', false);
				var res = {
	                "response_data": {
	                    "success": false,
	                    "message": 'Please select user',
	                }
	            };
	            display_error_message(res);
	            return false;
	        }
	    }

        // Check validation for comment area
		if($.trim(desc_editor.value()) == "") {
			$('.save_ticket_data').attr('disabled', false);
            var response = {
                "response_data": {
                	"success": false,
                  	"message": 'Comment should not be blank.'
                }
            };
            $('.save_ticket_data').attr('disabled', false);
            display_error_message(response);
            return false;
        }

        // Parse markdown to html
        var formatted_markdown_description = parse_markdown_to_html(desc_editor.value());
		
		var property_id = $('#tickets-property-id').val();
        var ticket_id = $('#add_ticket_id').val();
		var user_id = user.user_details.user_id;
		var title = $('#tickets-title').val();
		var description = desc_editor.value();
		var status = 1;
        var data = 'user_id='+user_id+'&property_id='+property_id+'&title='+btoa(unescape(encodeURIComponent(title)))+'&description='+btoa(unescape(encodeURIComponent(description)))+'&status='+status+'&to_whom='+to_whom+'&formatted_markdown_description='+formatted_markdown_description;

        $.ajax({
            method: 'POST',
            url: api_url+"tickets/edit/"+ticket_id,
            data: data,
            beforeSend: function(xhr) {
		        xhr.setRequestHeader('Authorization', accessToken);
		    },
            success: function(response) {
                display_error_message(response);
                $('.save_ticket_data').attr('disabled', false);
                if(response.response_data.success == true) {
                	clearTimeout(typingTimer);
                	var property_id = $("#selected_property_id").val();
	            	if(typeof property_id === 'undefined') {
	            		$('#ticketAddModal').modal('hide');
                		$('.modal-backdrop').remove();
	            		if($('#selected_layout').val() == 'table_layout') {
	                    	$('#open_from').val('table');
				        	$('#selected_layout').val('table_layout');
				        	$('.ticket_data_container').load('components/ticket/ticket_table.html');
	                    } else {
	                    	loadTicketList();
	                    }
	                } else {
	                	$('#propertyTicketAddModal').modal('hide');
                		$('.modal-backdrop').remove();
						openLoadingDialog($('#service_request_data_container'));
	    				$('#service_request_data_container').load('components/ticket/ticket_table.html');
					}
                }
            }
        });
    }
}

window.updateTicketStatus = function(ticket_id,status) {

	// Check validation for comment area
	if($.trim(comment_editor.value()) == "") {
		$('.close_ticket_comment, .reopen_ticket_comment').attr('disabled', false);
        var response = {
            "response_data": {
            	"success": false,
              	"message": 'Comment should not be blank.'
            }
        };
        display_error_message(response);
        return false;
    }

    // Parse markdown to html
    var formatted_markdown_comment = parse_markdown_to_html(comment_editor.value());
    var user_id = user.user_details.user_id;
    
	$.ajax({
        method: 'POST',
        url: api_url+"tickets/status/"+ticket_id,
        data: 'status='+status+'&user_id='+user_id+'&formatted_markdown_comment='+formatted_markdown_comment,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
            display_error_message(response);
            if(response.response_data.success == true) {
            	$('#ticketDetailModal').modal('hide');
            	if($('#selected_layout').val() == 'table_layout') {
                	$('#open_from').val('table');
		        	$('#selected_layout').val('table_layout');
		        	$('.ticket_data_container').load('components/ticket/ticket_table.html');
                } else {
                	loadTicketList();
                }
            }
        }
    });
}

window.deleteDraftTicket = function(ticket_id) {
	var user_id = user.user_details.user_id;
	$.ajax({
        method: 'POST',
        url: api_url+"tickets/delete/"+ticket_id,
        data:"user_id="+user_id,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
            display_error_message(response);
            if(response.response_data.success == true) {
            	var property_id = $("#selected_property_id").val();
            	if(typeof property_id === 'undefined') {
					if($('#open_from').val() == 'table') {
	            		$('#ticketDetailModal').modal('hide');
	                	loadTableTicketsDetail();
	                } else {
	                	loadTicketList();
	                }
				} else {
					openLoadingDialog($('#service_request_data_container'));
	    			//$('#service_request_data_container').load('components/ticket/ticket_table.html');
	    			$('#service_request_data_container').load('components/support.html');
				}
            }
        }
    });
}

window.addTicketComment = function(ticket_id) {
	$('.add_ticket_comment').attr('disabled', true);

	// condition for dashboard review contract
	if($('#selected_ticket_id').val() > 0) {
		var ticket_id = $('#selected_ticket_id').val();
	}

	var module_id = ticket_id;
	var module_name = 'tickets';
    var user_id = user.user_details.user_id;
    var comment = comment_editor.value();

    var formatted_markdown_comment = parse_markdown_to_html(comment_editor.value());

    var data = '';
    data = 'module_id='+module_id+'&module_name='+module_name+'&user_id='+user_id+'&comment='+btoa(comment)+'&formatted_markdown_comment='+formatted_markdown_comment;

    $.ajax({
        method: 'POST',
        url: api_url+"comments/add",
        data: data,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
            display_error_message(response);
            if(response.response_data.success == true) {
            	// condition for dashboard review contract
				if($('#selected_ticket_id').val() > 0) {
					// Load Ticket Detail
					openLoadingDialog($('#ticket_detail_container'));
					setTimeout(function(){
						$('#ticket_detail_container').load('components/ticket/ticket_detail.html', ticket_id , function(){
							loadTicketDetail(ticket_id,'table');
					        $('.close_ticket_comment').addClass('d-none');
					        $('.reopen_ticket_comment').addClass('d-none');
					        $('#ticket_detail_container .card-header').remove();
					    });
					}, 1000);
				} else {
					$('#ticketDetailModal').modal('hide');
	            	if($('#selected_layout').val() == 'table_layout') {
	            		$('#open_from').val('table');
			        	$('#selected_layout').val('table_layout');
			        	$('.ticket_data_container').load('components/ticket/ticket_table.html');
	            	} else {
	            		loadTicketList();
	            	}
	            }
            } else {
            	$('.add_ticket_comment').attr('disabled', false);
            }
        }
    });
}

var edit_comment_editor;
window.editTicketComment = function(comment_id) {
	var tz = jstz.determine();
	var timezone = tz.name();
	$.ajax({
        method: 'POST',
        url: api_url+"comments/view/"+comment_id,
        data: 'user_timezone='+timezone,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
    }).then(function(response) {
    	if(response.response_data.success == true) {
	    	var comment_data = "";
        	comment_data += '<input type="hidden" name="edit_comment_id" id="edit_comment_id" value="'+comment_id+'"><div class="row"> <div class="col-12 col-lg-12"> <div class="form-row"> <div class="form-group col-md-12"><textarea name="description" id="edit_comment_editor" class="form-control input-sm"></textarea> <div class="markdown_file_upload"> <input multiple="multiple" type="file" class="markdown_sub_upload_input_'+comment_id+'"> <a class="markdown_sub_upload_link_'+comment_id+'" href="javascript:void(0)">Browse</a> or Drag & Drop or Copy from clipboard files and images here <span class="markdown-ext-msg">Allowed extension : .jpg , .jpeg , .zip</span> </div> </div> </div> <div id="property_detail_footer" class="mt-2 mb-2 ml-2 small footer-content text-right"> <button class="btn btn-orange my-2 my-sm-0 ml-2 cancel_comment">Cancel</button> <button class="btn btn-orange my-2 my-sm-0 ml-2 update_comment">Save</button></div></div>';

            $('#comment_description_'+comment_id).html(comment_data);

            /* markdown editor code */
			edit_comment_editor = initialize_markdown("edit_comment_editor");
			markdown_drag_drop(edit_comment_editor);
			markdown_sub_comment_selectfile(edit_comment_editor, comment_id);
			markdown_attachment(edit_comment_editor);
			edit_comment_editor.value(response.comment.comment);
			/* markdown editor code */
            parse_markdown('edit_comment_editor');
    	} else {
    		display_error_message(response);
    	}
    }, function() {
        // hang on this step if the error occur
    });
}

window.updateTicketComment = function(comment_id) {
	if($.trim(edit_comment_editor.value()) == "") {
        var response = {
            "response_data": {
              "message": 'Comment should not be blank.'
            }
        };
        display_error_message(response);
        return false;
    }

    var user_id = user.user_details.user_id;
    var comment = edit_comment_editor.value();
    var formatted_markdown_comment = parse_markdown_to_html(edit_comment_editor.value());
    var data = 'comment='+btoa(comment)+'&user_id='+user_id+'&formatted_markdown_comment='+formatted_markdown_comment;

    $.ajax({
        method: 'POST',
        url: api_url+"comments/edit/"+comment_id,
        data: data,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
            display_error_message(response);
            if(response.response_data.success == true) {
            	$('#comment_description_'+comment_id).html(comment);
            	parse_markdown('comment_description_'+comment_id);
            }
        }
    });
}

window.cancelTicketComment = function(comment_id) {
	// call comment view api to get created date
	var tz = jstz.determine();
	var timezone = tz.name();
	$.ajax({
        method: 'POST',
        url: api_url+"comments/view/"+comment_id,
        data: 'user_timezone='+timezone,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(data) {
            display_error_message(data);
            if(data.response_data.success == true) {
            	$('#comment_description_'+comment_id).html(data.comment.comment);
                parse_markdown('comment_description_'+comment_id);
            }
        }
    });
}

window.deleteTicketComment = function(comment_id) {
	$.ajax({
        method: 'POST',
        url: api_url+"comments/delete/"+comment_id,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
            display_error_message(response);
            if(response.response_data.success == true) {
            	$('#comment_container_'+comment_id).remove();
            }
        }
    });
}

/* Start : Myacocunt */

window.getMyAccountProfileDetailWidget = function() {
	openLoadingDialog($('#myaccount_profile_widget'));
	setTimeout(function(){
    	$('#myaccount_profile_widget').load('components/myaccount/myaccount_profile_widget.html', function(){
			var user_id = user.user_details.user_id;
			var html = '';
			$.ajax({
		        method: 'POST',
		        url: api_url+"users/details",
		        data: 'user_id='+user_id,
		        beforeSend: function(xhr) {
			        xhr.setRequestHeader('Authorization', accessToken);
			    },
		        success: function(response) {
		            if(response.response_data.success == true) {
		            	//$('#myaccount_profile_edit_btn').html('<span class="btn btn-orange float-right edit_profile">Edit</span>');
			        	var status = '';
			            if(response.user_details.status_code == 0) {
			            	status += '<span class="badge badge-info">'+response.user_details.status+'</span>';
			            } else if(response.user_details.status_code == 1) {
			            	status += '<span class="badge badge-success">'+response.user_details.status+'</span>';
			            } else if(response.user_details.status_code == 2) {
			            	status += '<span class="badge badge-warning">'+response.user_details.status+'</span>';
			            } else if(response.user_details.status_code == 5) {
			            	status += '<span class="badge badge-danger">'+response.user_details.status+'</span>';
			            }

			            var username = response.user_details.first_name+' '+response.user_details.last_name;
			            var email = response.user_details.email;
			            var phone = response.user_details.phone;
			            
			            var phone_verified = '<span class="badge badge-danger">Not Verified</span>';
				        var email_verified = '<span class="badge badge-danger">Not Verified</span>';
				        if(response.user_details.phone_verified == 1) {
				        	phone_verified = '<span class="badge badge-success">Verified</span>';
				        }
				        if(response.user_details.email_verified == 1) {
				        	email_verified = '<span class="badge badge-success">Verified</span>';
				        }

				        var optinout = 'Opted Out (SMS)';
				        if(response.user_details.optinout == 1) {
				        	optinout = 'Opted In (SMS)';
				        }	

			            html += '<div data-id="'+user_id+'" class="col-12 col-md-12 col-xl-12">';
		        			html += '<table class="table mt-3 mb-0 card-table word-break">';
								html += '<tbody>';
									html += '<tr>';
										html += '<td><img src="icon/profile/user.svg" class="filter-8"></td>';
										html += '<td class="text-left">'+username+'<span class="float-right"><a href="javascript:void(0)" data-field="name" class="edit_profile">Edit</a></span></td>';
									html += '</tr>';
									html += '<tr>';
										html += '<td><img src="icon/profile/email.svg" class="filter-8"></td>';
										html += '<td class="text-left">'+email+' '+email_verified+'<span class="float-right"><a href="javascript:void(0)" data-field="email" class="edit_profile">Edit</a></span></td>';
									html += '</tr>';
									html += '<tr>';
										html += '<td><img src="icon/profile/phone.svg" class="filter-8"></td>';
										html += '<td class="text-left">'+phone+' '+phone_verified+'<span class="float-right"><a href="javascript:void(0)" data-field="phone" class="edit_profile">Edit</a></span></td>';
									html += '</tr>';
									html += '<tr>';
										html += '<td><img src="icon/profile/sms.svg" class="filter-8"></td>';
										html += '<td class="text-left">'+optinout+'<span class="float-right"><a href="javascript:void(0)" data-field="sms" class="edit_profile">Edit</a></span></td>';
									html += '</tr>';
									if(user_service_name == po_role) {
										html += '<tr>';
											html += '<td><img src="icon/profile/address.svg" class="filter-8"></td>';
											html += '<td class="text-left"><span class="float-right"><a href="javascript:void(0)" data-user-service-id="'+response.user_details.user_service_id+'" data-user-service-address-id="'+response.user_details.user_service_address_id+'" data-field="address" class="edit_address">Edit</a></span><div class="card-text capitalize">'+response.user_details.address_line1+', '+response.user_details.address_line2+'<br>'+response.user_details.city+', '+response.user_details.state+' '+response.user_details.zip_code+'</div></td>';
										html += '</tr>';
									}
								html += '</tbody>';
							html += '</table>';
							html += '<div class="text-right mt-4">'+status+'</div>';
				        html += '</div>';

				        $('#myaccount-profile-data-container').html(html);
			        } else {
			            display_error_message(response);
			        }
		        }
		    });
		});
	}, 1000);
}

window.getProfileEditForm = function(open_from, field, field_value) {
	var user_id = user.user_details.user_id;
	openLoadingDialog($('#profile_container_update'));
	setTimeout(function(){
		$('#editProfileModal #profile_container_update').load('components/myaccount/form/'+field+'.html', function(){
			var user_id = user.user_details.user_id;
			$.ajax({
		        method: 'POST',
		        url: api_url+"users/details",
		        data: 'user_id='+user_id,
		        beforeSend: function(xhr) {
			        xhr.setRequestHeader('Authorization', accessToken);
			    },
		        success: function(response) {
		            if(response.response_data.success == true) {
		            	$('#selected_field_name').val(field);

		            	var disabled = 'disabled';
		            	if(field == 'sms') {
		            		disabled = '';
		            	}

		            	if(field == 'email' || field == 'phone') {
		            		$('#update_profile_data_btn').html('<div class="col-auto ml-auto text-right mt-2 mb-2 mr-3"><button type="button" class="btn btn-orange float-right update_profile_data" '+disabled+'>Verify</button></div>');	
		            	} else {
		            		$('#update_profile_data_btn').html('<div class="col-auto ml-auto text-right mt-2 mb-2 mr-3"><button type="button" class="btn btn-orange float-right update_profile_data" '+disabled+'>Update</button></div>');	
		            	}

		            	var first_name = response.user_details.first_name;
			            var last_name = response.user_details.last_name;
			            var email = response.user_details.email;
			            var phone = response.user_details.phone;
			            var optinout = response.user_details.optinout;
			            $('#firstname').val(first_name);
			            $('#lastname').val(last_name);
			            $('#email_address').val(email);
			            $('#country_code').val();

			            phone = phone.split('+1');
			            $('#phone_number').val(phone[1]);
			            $('#phone_number').mask('(000) 000-0000');

			            $('#optinout_checkbox').attr('checked', false);
			            if(optinout == 1) {
			            	$('#optinout_checkbox').attr('checked', true);
			            }
			        } else {
			            display_error_message(response);
			        }
		        }
		    });
		});
	}, 1000);
}

window.updateProfile = function(field) {
	var user_id = user.user_details.user_id;
	var data = '';
	if(field == 'name') {
		var first_name = $('#firstname').val();
	    var last_name = $('#lastname').val();
		data = 'user_id='+user_id+'&first_name='+first_name+'&last_name='+last_name;
	} else if(field == 'email') {
		var email_address = $('#email_address').val();
	    data = 'user_id='+user_id+'&email='+email_address;
	} else if(field == 'phone') {
		var country_code = $('#country_code').val();
		var phone = $.trim($('#phone_number').val());
		phone = phone.replace('-', '');
		phone = phone.replace('(', '');
		phone = phone.replace(')', '');
		phone = phone.replace(/\s/g, '');
		phone = country_code+phone;
		
		if($('#edit_verify_mobile_checkbox').is(":checked")) {
			var verify_mobile = 1;
		} else {
			var verify_mobile = 0;
		}

		if($('#optinout_checkbox').is(":checked")) {
			var optinout = 1;
		} else {
			var optinout = 0;
		}
		data = 'user_id='+user_id+'&phone='+encodeURIComponent(phone)+'&verify_mobile='+verify_mobile+'&optinout='+optinout;
	} else if(field == 'sms') {
		if($('#optinout_checkbox').is(":checked")) {
			var optinout = 1;
		} else {
			var optinout = 0;
		}
		data = 'user_id='+user_id+'&optinout='+optinout;
	}
	
	$.ajax({
        method: 'POST',
        url: api_url+"auth/update-profile",
        data: data+'&field='+field,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
            if(response.response_data.success == true) {
            	if(response.change_made == 'yes') {
	            	if(field == 'email') {
	            		$('#email_data_container').addClass('d-none');
	            		$('#verify_email_data_contanier').removeClass('d-none');
	            		$('#destination_email').text(censorEmail(email_address));
	            	} else if(field == 'phone') {
	            		$('#phone_data_container').addClass('d-none');
	            		$('#verify_phone_data_contanier').removeClass('d-none');
	            		$('#destination_phone').text(response.destination);
	            	}
	            } else {
	            	$('#editProfileModal').modal('hide');
	            }
            	getMyAccountProfileDetailWidget();
	        } else {
	            display_error_message(response);
	        }
        }
    });
}

window.verifyEmail = function(field) {
	var user_id = user.user_details.user_id;
	var email_digit_1 = $('#signup-email-digit-1').val();
	var email_digit_2 = $('#signup-email-digit-2').val();
	var email_digit_3 = $('#signup-email-digit-3').val();
	var email_digit_4 = $('#signup-email-digit-4').val();
	var email_digit_5 = $('#signup-email-digit-5').val();
	var email_digit_6 = $('#signup-email-digit-6').val();
	var confirmation_code = email_digit_1+email_digit_2+email_digit_3+email_digit_4+email_digit_5+email_digit_6;	
	var data = 'user_id='+user_id+'&confirmation_code='+confirmation_code;
    $.ajax({
        method: 'POST',
        url: api_url+"auth/profile-verify-email",
        data: data,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	display_error_message(response);
	    	if(response.response_data.success == true) {
	    		$('#editProfileModal').modal('hide');
	    		getMyAccountProfileDetailWidget();
	    	} else {
	    		return false;
	    	}
        }
    });
}

window.verifyPhone = function(field) {
	var user_id = user.user_details.user_id;
	var phone_digit_1 = $('#signup-phone-digit-1').val();
	var phone_digit_2 = $('#signup-phone-digit-2').val();
	var phone_digit_3 = $('#signup-phone-digit-3').val();
	var phone_digit_4 = $('#signup-phone-digit-4').val();
	var phone_digit_5 = $('#signup-phone-digit-5').val();
	var phone_digit_6 = $('#signup-phone-digit-6').val();
	var confirmation_code = phone_digit_1+phone_digit_2+phone_digit_3+phone_digit_4+phone_digit_5+phone_digit_6;	
	var data = 'user_id='+user_id+'&confirmation_code='+confirmation_code;
    $.ajax({
        method: 'POST',
        url: api_url+"auth/profile-verify-phone",
        data: data,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	display_error_message(response);
	    	if(response.response_data.success == true) {
	    		$('#editProfileModal').modal('hide');
	    		getMyAccountProfileDetailWidget();
	    	} else {
	    		return false;
	    	}
        }
    });
}

window.getAddressEditForm = function(user_service_id, user_service_address_id) {
	var user_id = user.user_details.user_id;
	openLoadingDialog($('#address_container_update'));
	setTimeout(function(){
		$('#editAddressModal #address_container_update').load('components/myaccount/form/address.html', function(){
			var user_id = user.user_details.user_id;
			$.ajax({
		        method: 'POST',
		        url: api_url+"users/details",
		        data: 'user_id='+user_id,
		        beforeSend: function(xhr) {
			        xhr.setRequestHeader('Authorization', accessToken);
			    },
		        success: function(response) {
		            if(response.response_data.success == true) {
		            	$('#selected_user_service_id').val(user_service_id);
		            	$('#selected_user_service_address_id').val(user_service_address_id);

		            	$('#update_address_data_btn').html('<div class="col-auto ml-auto text-right mt-2 mb-2 mr-3"><button type="button" class="btn btn-orange float-right update_address_data">Update</button></div>');

		            	var address_line1 = response.user_details.address_line1;
			            var address_line2 = response.user_details.address_line2;
			            var city = response.user_details.city;
			            var state = response.user_details.state;
			            var zip_code = response.user_details.zip_code;
			            var country = response.user_details.country;

			            $('#address_line1').val(address_line1);
			            $('#address_line2').val(address_line2);
			            $('#city').val(city);
			            $('#state').val(state);
			            $('#zip_code').val(zip_code);
			            $('#country').val(country);

			            var selected_state = state;
			            getStateDropdownForSignup(selected_state);
			        } else {
			            display_error_message(response);
			        }
		        }
		    });
		});
	}, 1000);
}

window.updateAddress = function() {
	var user_id = user.user_details.user_id;

	var address_line1 = $('#address_line1').val();
    var address_line2 = $('#address_line2').val();
    var city = $('#city').val();
    var state = $('#state').val();
    var zip_code = $('#zip_code').val();
    var country = $('#country').val();
	
	var selected_user_service_id = $('#selected_user_service_id').val();
    var selected_user_service_address_id = $('#selected_user_service_address_id').val();
    var data = '';
	data = 'user_id='+user_id+'&user_service_id='+selected_user_service_id+'&address_id='+selected_user_service_address_id+'&address_line1='+address_line1+'&address_line2='+address_line2+'&city='+city+'&state='+state+'&zip_code='+zip_code+'&country='+country;
	
	$.ajax({
        method: 'POST',
        url: api_url+"users/update-address",
        data: data,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
            if(response.response_data.success == true) {
            	$('#editAddressModal').modal('hide');
            	getMyAccountProfileDetailWidget();
	        } else {
	            display_error_message(response);
	        }
        }
    });
}

window.getMyAccountPaymentDetailWidget = function() {
	openLoadingDialog($('#myaccount_payment_detail_widget'));
	// Below confition will check if applications page or my account page open
	var page_name = localStorage.getItem('page_name');
	if(page_name == 'users') {
		var user_id = $('#selected_user_id').val();
		var data = 'user_id='+user_id;
	} else {
		var data = 'user_id='+user.user_details.user_id;
	}

	setTimeout(function(){
    	$('#myaccount_payment_detail_widget').load('components/myaccount/myaccount_payment_detail_widget.html', function(){
			var user_id = user.user_details.user_id;
			$.ajax({
		        method: 'POST',
			    url: api_url+"payment-details/user-banks",
			    data:data,
			    beforeSend: function(xhr) {
			        xhr.setRequestHeader('Authorization', accessToken);
			    },
			    success: function(response) {
			    	if(response.response_data.success == true) {
			        	if(page_name != 'users') {
							$('#myaccount_bank_add_btn').html('<span class="btn btn-orange float-right add_new_payment">Add</span>');
						}

						var html = '';
						for (var key in response.userBanks) {
			                if (response.userBanks.hasOwnProperty(key)) {

			                	var bg_light = '';
			                	if(key % 2 == 1) {
			                		bg_light = 'bg-light';
			                	}

			                	var payment_method = response.userBanks[key].bank_name+'&&'+response.userBanks[key].account_number;

			                	var kebab_item = '';
			                	var deleted_menu = '';
			                	if(user_service_name == po_role && response.userBanks[key].status == 2) {
			                		kebab_item += '<a class="dropdown-item link_bank_and_property" data-module="Bank" data-id="'+response.userBanks[key].id+'" data-payment-method="'+payment_method+'" href="javascript:void(0)">Link Property</a>';	
			                	}
			                    
			                	var status = '';
			                    if(response.userBanks[key].status == 0) { // Unverified
			                        status += '<span class="badge badge-danger">'+response.userBanks[key].status_label+'</span>';
			                    } else if(response.userBanks[key].status == 1) { // Awaiting
			                        status += '<span class="badge badge-warning">'+response.userBanks[key].status_label+'</span>';
			                        kebab_item += '<a href="javascript:void(0)" data-id='+response.userBanks[key].id+' class="dropdown-item verify_bank_detail" data-toggle="modal" data-target="#microDepositModal" data-backdrop="static" data-keyboard="false">Verify Bank Detail</a>';
			                    } else if(response.userBanks[key].status == 2) { // Verified
			                        status += '<span class="badge badge-success">'+response.userBanks[key].status_label+'</span>';
			                    } else if(response.userBanks[key].status == 3) { // Deleted
			                        status += '<span class="badge badge-danger">'+response.userBanks[key].status_label+'</span>';
			                        //deleted_menu += '<a class="dropdown-item restore_bank_detail" data-id="'+response.userBanks[key].id+'" href="javascript:void(0)">Restore</a>';
			                    } else if(response.userBanks[key].status == 10) { // Processing
			                        status += '<span class="badge badge-danger">'+response.userBanks[key].status_label+'</span>';
			                        if(user_service_name == sa_role) {
			                        	kebab_item += '<a class="dropdown-item initiate_micro_deposit" data-id="'+response.userBanks[key].id+'" href="javascript:void(0)">Initiate Micro Deposit</a>';
			                        }
			                    }

			                    if(user.user_details.user_id == response.userBanks[key].user_id) {
			                    	kebab_item += '<a class="dropdown-item delete_bank_detail" data-id="'+response.userBanks[key].id+'" data-message="'+response.userBanks[key].message+'" href="javascript:void(0)">Delete</a>';
			                    }

			                    var kebab = '';
			                    if(response.userBanks[key].status != 3 && kebab_item != '') {
			                    	kebab = '<div class="text-center"><div class="input-group-prepend"><i class="align-middle mr-2 fas fa-fw fa-ellipsis-v" data-toggle="dropdown" aria-expanded="false"></i><div class="dropdown-menu dropdown-menu-right" style="">'+kebab_item+'</div></div></div>';
			                    } /*else {
			                    	if(user_service_name == sa_role) {
			                    		kebab = '<span class="accordion_arrow"><div class="text-center"><div class="input-group-prepend"><i class="align-middle mr-2 fas fa-fw fa-ellipsis-v" data-toggle="dropdown" aria-expanded="false"></i><div class="dropdown-menu dropdown-menu-right" style="">'+deleted_menu+'</div></div></div></span>';
			                    	}
			                    }*/

			                    // Table based UI
			                	html += '<tr data-id="'+response.userBanks[key].id+'">';
			                		html += '<td>'+response.userBanks[key].bank_name+'</td>';
			                		html += '<td>'+response.userBanks[key].account_number+'</td>';
			                		html += '<td>'+response.userBanks[key].account_type_label+'</td>';
			                		html += '<td>'+response.userBanks[key].linked_to+'</td>';
			                		html += '<td>'+status+'</td>';
			                		html += '<td>'+kebab+'</td>';
			                	html += '</tr>';
			                }
			            }
						
						$('#myaccount-payment-data-container').html(html);
						var property_lease_table = $("#myaccount-payment-detail-datatable").DataTable({
			            	responsive: true,
			            	pageLength: 6,
							lengthChange: false,
							dom: 'frtip',
			    			autoWidth: false,
			    			searching: false,
			            	paging: false,
			            	info: false,
						    scrollY: '400px',
						    scrollX: false
						});
			        } else {
			            display_error_message(response);
			        }
			    }
			});
		});
	}, 1000);
}

window.getMyAccountAutopayWidget = function() {
	openLoadingDialog($('#myaccount_autopay_widget'));
	setTimeout(function(){
    	$('#myaccount_autopay_widget').load('components/myaccount/myaccount_autopay_widget.html', function(){
			var user_id = user.user_details.user_id;
			var tz = jstz.determine();
			var timezone = tz.name();
			$.ajax({
		        url: api_url+"leases/get-user-leases",
			    type: 'POST',
			    data: 'user_id='+user.user_details.user_id+'&user_service_name='+user_service_name+'&user_timezone='+timezone,
		        beforeSend: function(xhr) {
			        xhr.setRequestHeader('Authorization', accessToken);
			    },
		        success: function(response) {
		        	var html = '';
		        	if(response.response_data.success == true) {	
		        		$('#myaccount_autopay_add_btn').html('<span class="btn btn-orange float-right add_new_account">Add</span>');
		    			for (var key in response.leases) {
			                if (response.leases.hasOwnProperty(key)) {
			                	var property_name = response.leases[key].property.name
			                	var start_date = response.leases[key].start_date;
			                	var end_date = response.leases[key].end_date;
			                	var status = '';
			                    if(response.leases[key].status == 0 || response.leases[key].status == 4) {
			                    	status += '<span class="badge badge-danger">'+response.leases[key].status_label+'</span>';
			                    } if(response.leases[key].status == 1 || response.leases[key].status == 5) {
			                        status += '<span class="badge badge-success">'+response.leases[key].status_label+'</span>';
			                    }
			                	html += '<tr>';
				                    html += '<td>';
					                    if(response.leases[key].autopay == 0) {
					                    	html += '<a href="javascript:void(0)" data-type="enable" data-id="'+response.leases[key].id+'" data-property-id="'+response.leases[key].property_id+'" class="enable_disable_autopay_btn">Enable Autopay</a>'
					                    } else {
					                    	html += '<a href="javascript:void(0)" data-type="disable" data-id="'+response.leases[key].id+'" data-property-id="'+response.leases[key].property_id+'" class="enable_disable_autopay_btn">Disable Autopay</a>'
					                    }
					                html += '</td>';
				                    html += '<td>'+property_name+'</td>';
				                    html += '<td>'+start_date+'</td>';
				                    html += '<td>'+end_date+'</td>';
				                    html += '<td>'+status+'</td>';
				                    html += '<td>'+response.leases[key].monthly_rent_amount+'</td>';				                    
			                    html += '</tr>';
			                }
			            }

				        $('#myaccount-autopay-data-container').html(html);

				        var autopay_table = $("#myaccount-autopay-datatable").DataTable({
			            	responsive: true,
			            	pageLength: 6,
							lengthChange: false,
							dom: 'frtip',
			    			autoWidth: false,
			    			searching: false,
			            	paging: false,
			            	info: false,
						    scrollY: '400px',
						    scrollX: false
						});
		            } else {
		            	display_error_message(response);
		            }
		        }
		    });
		});
	}, 1000);
}

window.saveAutopayData = function(type, lease_id, property_id, payment_detail_id, day, autopay) {
	var user_id = user.user_details.user_id;
	var tz = jstz.determine();
	var timezone = tz.name();
	if(autopay == 1) {
		var data = 'user_id='+user_id+'&user_timezone='+timezone+'&type='+type+'&lease_id='+lease_id+'&property_id='+property_id+'&payment_detail_id='+payment_detail_id+'&day='+day+'&autopay='+autopay;
	} else {
		var data = 'user_id='+user_id+'&user_timezone='+timezone+'&type='+type+'&lease_id='+lease_id+'&property_id='+property_id+'&autopay='+autopay;
	}
	$.ajax({
        method: 'POST',
        url: api_url+"autopays/save-autopay-data",
        data:data,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	display_error_message(response);
            if(response.response_data.success == true) {
            	$('#enableAutopayModal').modal('hide');
            	getMyAccountAutopayWidget();
            }
        }
    });
}

window.getMyAccountLinkedAccountDetailWidget = function() {
	openLoadingDialog($('#myaccount_linked_account_detail_widget'));
	setTimeout(function(){
    	$('#myaccount_linked_account_detail_widget').load('components/myaccount/myaccount_linked_account_detail_widget.html', function(){
			var user_id = user.user_details.user_id;
			var tz = jstz.determine();
			var timezone = tz.name();
			$.ajax({
		        method: 'POST',
		        url: api_url+"linked-accounts",
		        data:'user_id='+user.user_details.user_id+'&user_timezone='+timezone,
		        beforeSend: function(xhr) {
			        xhr.setRequestHeader('Authorization', accessToken);
			    },
		        success: function(response) {
		        	var html = '';
		        	if(response.response_data.success == true) {	
		        		$('#myaccount_link_account_add_btn').html('<span class="btn btn-orange float-right add_new_account">Add</span>');
		    			for (var key in response.linkedAccounts) {
			                if (response.linkedAccounts.hasOwnProperty(key)) {
			                	var from_user = response.linkedAccounts[key].from_user.first_name+' '+response.linkedAccounts[key].from_user.last_name+'<br><small>'+response.linkedAccounts[key].from_user.email+'</small>';
			                	var to_user = response.linkedAccounts[key].to_user.first_name+' '+response.linkedAccounts[key].to_user.last_name+'<br><small>'+response.linkedAccounts[key].to_user.email+'</small>';
			                	html += '<tr>';
				                    //html += '<td>'+response.linkedAccounts[key].id+'</td>';
				                    html += '<td>'+from_user+'</td>';
				                    html += '<td>'+to_user+'</td>';
				                    html += '<td>'+response.linkedAccounts[key].services+'</td>';
				                    html += '<td>'+response.linkedAccounts[key].status_label+'</td>';
				                    html += '<td><a href="javascript:void(0)" data-id="'+response.linkedAccounts[key].id+'" class="unlink_account">Unlink</a></td>';
			                    html += '</tr>';
			                }
			            }

				        $('#myaccount-linked_account-data-container').html(html);

				        var linked_account_table = $("#myaccount-linked-account-datatable").DataTable({
			            	responsive: true,
			            	pageLength: 6,
							lengthChange: false,
							dom: 'frtip',
			    			autoWidth: false,
			    			searching: false,
			            	paging: false,
			            	info: false,
						    scrollY: '400px',
						    scrollX: false
						});
		            } else {
		            	display_error_message(response);
		            }
		        }
		    });
		});
	}, 1000);
}

window.getMyAccountActivityDetailWidget = function() {
	openLoadingDialog($('#myaccount_activity_log_widget'));
	setTimeout(function(){
		$('#myaccount_activity_log_widget').load('components/myaccount/myaccount_activity_log_widget.html', function(){
			var user_id = user.user_details.user_id;
	    	var tz = jstz.determine();
			var timezone = tz.name();
			$.ajax({
		        method: 'POST',
		        url: api_url+"user-activities/list",
		        data:'user_id='+user.user_details.user_id+'&user_timezone='+timezone,
		        beforeSend: function(xhr) {
			        xhr.setRequestHeader('Authorization', accessToken);
			    },
		        success: function(response) {
		        	display_error_message(response);
		        	if(response.response_data.success == true) {
		        		var html = '';
		            	for (var key in response.userActivities) {
			                if (response.userActivities.hasOwnProperty(key)) {
			                	html += '<div class="fs-16 bold-black mt-3">'+key+'</div>';
			                	for (var jkey in response.userActivities[key]) {
			                		var username = '';
			                		if(response.userActivities[key][jkey].user != null) {
			                			username = response.userActivities[key][jkey].user.first_name+' '+response.userActivities[key][jkey].user.last_name
			                		}
				                	var message = response.userActivities[key][jkey].message;
				                	var created1 = response.userActivities[key][jkey].created1;
				                	html += '<div class="m-3">';
					                    html += '<div class="media">';
					                        html += '<img src="icon/avatar.jpg" width="36" height="36" class="rounded-circle mr-2" alt="Ashley Briggs">';
					                        html += '<div class="media-body mt-2">';
					                            html += '<small class="float-right text-navy">'+created1+'</small>';
					                            html += '<strong>'+username+'</strong> '+message;
					                        html += '</div>';
					                    html += '</div>';
						            html += '</div>';
							    }
			                }
			            }

			            if(user_id > 0) {
			            	$('#myaccount-audit-log-data-container').html(html);
			            } else {
			            	$('#activities-container').html(html);
			            }
		            }
		        }
		    });
		});
	}, 1000);
}

window.getMyAccountInvoiceDetailWidget = function() {
	openLoadingDialog($('#myaccount_invoice_detail_widget'));
	setTimeout(function(){
    	$('#myaccount_invoice_detail_widget').load('components/myaccount/myaccount_invoice_detail_widget.html', function(){
			var user_id = user.user_details.user_id;
			var tz = jstz.determine();
			var timezone = tz.name();
			$.ajax({
		        method: 'POST',
		        url: api_url+"invoices",
		        data:'user_id='+user.user_details.user_id+'&user_timezone='+timezone,
		        beforeSend: function(xhr) {
			        xhr.setRequestHeader('Authorization', accessToken);
			    },
		        success: function(response) {
		        	var html = '';
		        	if(response.response_data.success == true) {	
		        		for (var key in response.invoices) {
			                if (response.invoices.hasOwnProperty(key)) {

			                	var bg_light = '';
			                	if(key % 2 == 1) {
			                		bg_light = 'bg-light';
			                	}

			                	var kebab_item = '';
			                	kebab_item += '<a data-invoice-id="'+response.invoices[key].id+'" class="dropdown-item view_invoice" href="javascript:void(0)">View</a>';
			                	kebab_item += '<a data-invoice-id="'+response.invoices[key].id+'" data-from="invoice_list" class="dropdown-item download_invoice" href="javascript:void(0)">Download</a>';
			                	kebab_item += '<a data-invoice-id="'+response.invoices[key].id+'" class="dropdown-item email_invoice" href="javascript:void(0)">Email</a>';
			                    
			                	var status = '';
			                    if(response.invoices[key].status == 1) {
						        	status += '<div class="badge badge-danger">'+response.invoices[key].status_label+'</div>';
						        } else if(response.invoices[key].status == 3) {
						        	status += '<div class="badge badge-warning">'+response.invoices[key].status_label+'</div>';
						        } else if(response.invoices[key].status == 4) {
						        	status += '<div class="badge badge-success">'+response.invoices[key].status_label+'</div>';
						        }

			                    var kebab = kebab_item;

			                	var coupon_code = response.invoices[key].coupon_code;
			                	var coupon_amount = '$'+response.invoices[key].coupon_discounted_amount;
			                	if(response.invoices[key].coupon_id == 0) {
			                		coupon_code = '-';
			                		coupon_amount = '-';
			                	}

			                	// Table based UI
			                	html += '<tr data-id="'+response.invoices[key].id+'">';
			                		html += '<td>'+response.invoices[key].id+'</td>';
			                		html += '<td>'+response.invoices[key].property.name+'</td>';
			                		html += '<td>'+response.invoices[key].bill_from_date+'<br>to<br>'+response.invoices[key].bill_to_date+'</td>';
			                		html += '<td>'+response.invoices[key].next_bill_from_date+'</td>';
			                		html += '<td>';
			                			html  += '<div class="grey">Code: <strong>'+coupon_code+'</strong></div>';
						                html  += '<div class="grey">Amount: <strong>'+coupon_amount+'</strong></div>';
			                		html += '</td>';
			                		html += '<td class="green fs-16">$'+response.invoices[key].total+'</td>';
			                		html += '<td>'+status+'</td>';
			                		html += '<td>';
			                			html += '<small>';
	                                        html += '<a href="javascript:void(0)" data-invoice-id="'+response.invoices[key].id+'" class="view_invoice">View</a><br>';
	                                        html += '<a href="javascript:void(0)" data-invoice-id='+response.invoices[key].id+' data-from="invoice_list" class="download_invoice">Download</a><br>';
	                                        html += '<a href="javascript:void(0)" data-invoice-id='+response.invoices[key].id+' class="email_invoice">Email</a><br>';
	                                    html += '</small>';
			                		html += '</td>';
			                	html += '</tr>';

			                	/*html += '<div class="card tab-card '+bg_light+'">';
						            html += '<div class="accordion">';
						                html += '<div class="border-bottom">';
						                    html += '<h5 class="card-title my-2 fs-16">Invoice: #'+response.invoices[key].id+' &nbsp;'+status+'</h5>';
						                    html += '<span class="accordion_arrow">';
						                        html += '<div class="text-center"><div class="input-group-prepend"><i class="align-middle mr-2 fas fa-fw fa-ellipsis-v" data-toggle="dropdown" aria-expanded="false"></i><div class="dropdown-menu dropdown-menu-right" style="">'+kebab+'</div></div></div>';
						                    html += '</span>';
						                html += '</div>';
						                html += '<div class="tab_inner_data" data-id="'+response.invoices[key].id+'" data-toggle="modal" data-target="#tenantEditModal" data-backdrop="static" data-keyboard="false">';
						                    html += '<div class="row">';
						                        html += '<div class="col-12 col-md-2 col-xl-2 d-flex">';
						                            html += '<div class="card flex-fill '+bg_light+'">';
						                                html += '<div class="mt-2">';
						                                	html += '<div class="black"><strong>Property:</strong></div>';
						                                    html += '<div class="grey"><strong>'+response.invoices[key].property.name+'</strong></div>';
						                                    html += '<div class="black"><strong>Plan:</strong></div>';
						                                    html += '<div class="grey"><strong>'+response.invoices[key].plan_name+'</strong></div>';
						                                html += '</div>';
						                            html += '</div>';
						                        html += '</div>';
						                        html += '<div class="col-12 col-md-2 col-xl-2 d-flex">';
						                            html += '<div class="card flex-fill '+bg_light+'">';
						                                html += '<div class="mt-2">';
						                                    html += '<div class="black"><strong>Invoice Month:</strong></div>';
						                                    html += '<div class="grey"><strong>'+response.invoices[key].bill_from_date+'<br>to<br>'+response.invoices[key].bill_to_date+'</strong></div>';
						                                html += '</div>';
						                            html += '</div>';
						                        html += '</div>';
						                        html += '<div class="col-12 col-md-2 col-xl-2 d-flex">';
						                            html += '<div class="card flex-fill '+bg_light+'">';
						                                html += '<div class="mt-2">';
						                                    html += '<div class="black"><strong>Next Billing Date:</strong></div>';
						                                    html += '<div class="grey"><strong>'+response.invoices[key].next_bill_from_date+'</strong></div>';
						                                html += '</div>';
						                            html += '</div>';
						                        html += '</div>';				                         
						                        html += '<div class="col-12 col-md-2 col-xl-2 d-flex">';
						                            html += '<div class="card flex-fill '+bg_light+'">';
						                                html += '<div class="mt-2">';
						                                    html += '<div class="black"><strong>Coupon:</strong></div>';
						                                    html  += '<div class="grey">Code: <strong>'+coupon_code+'</strong></div>';
						                                    html  += '<div class="grey">Amount: <strong>'+coupon_amount+'</strong></div>';
						                                html += '</div>';
						                            html += '</div>';
						                        html += '</div>';
						                        html += '<div class="col-12 col-md-2 col-xl-2 d-flex">';
						                            html += '<div class="card flex-fill '+bg_light+'">';
						                                html += '<div class="mt-2">';
						                                    html += '<div class="black"><strong>Bill From:</strong></div>';
						                                    html += '<div class="grey"><strong>'+response.invoices[key].bill_from_name+'</strong></div>';
						                                    html += '<div class="black"><strong>Billed To:</strong></div>';
						                                    html += '<div class="grey"><strong>'+response.invoices[key].bill_to_name+'</strong></div>';
						                                html += '</div>';
						                            html += '</div>';
						                        html += '</div>';
						                        html += '<div class="col-12 col-md-2 col-xl-1 d-flex">';
						                            html += '<div class="card flex-fill '+bg_light+'">';
						                                html += '<div class="mt-2">';
						                                	html += '<div class="black"><strong>Amount:</strong></div>';
						                                    html += '<div class="green fs-18"><strong> $'+response.invoices[key].total+'</strong></div>';
						                                html += '</div>';
						                            html += '</div>';
						                        html += '</div>';
						                        html += '<div class="col-12 col-md-2 col-xl-1 d-flex">';
						                            html += '<div class="card flex-fill '+bg_light+'">';
						                                html += '<div class="mt-2">';
						                                    html += '<small>';
						                                        html += '<a href="javascript:void(0)" data-invoice-id="'+response.invoices[key].id+'" class="view_invoice">View</a><br>';
						                                        html += '<a href="javascript:void(0)" data-invoice-id='+response.invoices[key].id+' data-from="invoice_list" class="download_invoice">Download</a><br>';
						                                        html += '<a href="javascript:void(0)" data-invoice-id='+response.invoices[key].id+' class="email_invoice">Email</a><br>';
						                                    html += '</small>';
						                                html += '</div>';
						                            html += '</div>';
						                        html += '</div>';
						                    html += '</div>';
						                html += '</div>';
						            html += '</div>';
						        html += '</div>';*/
			                }
			            }

				        $('#myaccount-invoice-data-container').html(html);

				        var myaccount_invoice_table = $("#myaccount-invoice-datatable").DataTable({
			            	responsive: true,
			            	pageLength: 6,
							lengthChange: false,
							dom: 'frtip',
			    			autoWidth: false,
			    			searching: false,
			            	paging: false,
			            	info: false,
						    scrollY: '400px',
						    scrollX: false
						});
		            } else {
		            	display_error_message(response);
		            }
		        }
		    });
		});
	}, 1000);
}

window.loadTablePaymentDetail = function() {

	// Below confition will check if applications page or my account page open
	var page_name = localStorage.getItem('page_name');
	if(page_name == 'users') {
		var user_id = $('#selected_user_id').val();
		var data = 'user_id='+user_id;
	} else {
		var data = 'user_id='+user.user_details.user_id;
	}

	$.ajax({
	    method: 'POST',
	    url: api_url+"payment-details/user-banks",
	    data:data,
	    beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
	}).then(function(response) {
		if(response.response_data.success == true) {
			var html = '';
			if(response.totalBanks == 0) {
	        	html += '<a href="javascript:void(0)" class="list-group-item list-group-item-action border-0 bg-light text-left"><div class="fs-16">'+response.response_data.message+'</div></a>';
		    } else {
		    	for (var key in response.userBanks) {
	                if (response.userBanks.hasOwnProperty(key)) {

	                	var bg_light = '';
	                	if(key % 2 == 1) {
	                		bg_light = 'bg-light';
	                	}

	                	var payment_method = response.userBanks[key].bank_name+'&&'+response.userBanks[key].account_number;

	                	var kebab_item = '';
	                	var deleted_menu = '';
	                	if(user_service_name == po_role && response.userBanks[key].status == 2) {
	                		kebab_item += '<a class="dropdown-item link_bank_and_property" data-module="Bank" data-id="'+response.userBanks[key].id+'" data-payment-method="'+payment_method+'" href="javascript:void(0)">Link Property</a>';	
	                	}
	                    
	                	var status = '';
	                    if(response.userBanks[key].status == 0) { // Unverified
	                        status += '<span class="badge badge-danger">'+response.userBanks[key].status_label+'</span>';
	                    } else if(response.userBanks[key].status == 1) { // Awaiting
	                        status += '<span class="badge badge-warning">'+response.userBanks[key].status_label+'</span>';
	                        kebab_item += '<a href="javascript:void(0)" data-id='+response.userBanks[key].id+' class="dropdown-item verify_bank_detail" data-toggle="modal" data-target="#microDepositModal" data-backdrop="static" data-keyboard="false">Verify Bank Detail</a>';
	                    } else if(response.userBanks[key].status == 2) { // Verified
	                        status += '<span class="badge badge-success">'+response.userBanks[key].status_label+'</span>';
	                    } else if(response.userBanks[key].status == 3) { // Deleted
	                        status += '<span class="badge badge-danger">'+response.userBanks[key].status_label+'</span>';
	                        //deleted_menu += '<a class="dropdown-item restore_bank_detail" data-id="'+response.userBanks[key].id+'" href="javascript:void(0)">Restore</a>';
	                    } else if(response.userBanks[key].status == 10) { // Processing
	                        status += '<span class="badge badge-danger">'+response.userBanks[key].status_label+'</span>';
	                        if(user_service_name == sa_role) {
	                        	kebab_item += '<a class="dropdown-item initiate_micro_deposit" data-id="'+response.userBanks[key].id+'" href="javascript:void(0)">Initiate Micro Deposit</a>';
	                        }
	                    }

	                    if(user.user_details.user_id == response.userBanks[key].user_id) {
	                    	kebab_item += '<a class="dropdown-item delete_bank_detail" data-id="'+response.userBanks[key].id+'" data-message="'+response.userBanks[key].message+'" href="javascript:void(0)">Delete</a>';
	                    }

	                    var kebab = '';
	                    if(response.userBanks[key].status != 3 && kebab_item != '') {
	                    	kebab = '<div class="text-center"><div class="input-group-prepend"><i class="align-middle mr-2 fas fa-fw fa-ellipsis-v" data-toggle="dropdown" aria-expanded="false"></i><div class="dropdown-menu dropdown-menu-right" style="">'+kebab_item+'</div></div></div>';
	                    } /*else {
	                    	if(user_service_name == sa_role) {
	                    		kebab = '<span class="accordion_arrow"><div class="text-center"><div class="input-group-prepend"><i class="align-middle mr-2 fas fa-fw fa-ellipsis-v" data-toggle="dropdown" aria-expanded="false"></i><div class="dropdown-menu dropdown-menu-right" style="">'+deleted_menu+'</div></div></div></span>';
	                    	}
	                    }*/

	                	// Card based UI
	                	html += '<div data-id="'+response.userBanks[key].id+'" class="col-12 col-md-6 col-xl-3 property_card">';
							html += '<div class="card">';
								html += '<div class="card-header bg-light"><span class="accordion_arrow">'+kebab+'</span><h5 class="card-title capitalize mb-0 mr-3">'+response.userBanks[key].bank_name+'</h5></div>';
								html += '<div class="text-center"><img src="img/sample/bank.jpg" height="100" width="100" alt="Unsplash"></div>';
								html += '<div class="card-body">';
									html += '<table class="table mb-0 card-table word-break">';
										html += '<tbody>';
											html += '<tr>';
												html += '<td class="nowrap">Account Number</td>';
												html += '<td class="text-right">'+response.userBanks[key].account_number+'</td>';
											html += '</tr>';
											html += '<tr>';
												html += '<td class="nowrap">Account Type</td>';
												html += '<td class="text-right">'+response.userBanks[key].account_type_label+'</td>';
											html += '</tr>';
											html += '<tr>';
												html += '<td class="nowrap">Linked to</td>';
												html += '<td class="text-right">'+response.userBanks[key].linked_to+' Properties</td>';
											html += '</tr>';
										html += '</tbody>';
									html += '</table>';
									html += '<div class="text-right mt-4">'+status+'</div>';
								html += '</div>';
							html += '</div>';
						html += '</div>';
	                }
	            }
	        }
            $('#paymentDataContainer').html(html);
            // Get Floating Action Button
            if(page_name != 'users') {
				getFloatingActionButton('Bank', '#paymentDataContainer');
			}
        } else {
            display_error_message(response);
        }
    }, function() {
        // hang on this step if the error occur
    });
}

window.deleteBank = function(payment_detail_id) {
	var user_id = user.user_details.user_id;
	$.ajax({
        method: 'GET',
        url: api_url+"payment-details/delete/"+payment_detail_id,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
            display_error_message(response);
            if(response.response_data.success == true) {
                loadTablePaymentDetail();
            }
        }
    });
}

/*window.restoreBank = function(payment_detail_id) {
	$.ajax({
        method: 'GET',
        url: api_url+"payment-details/restore-bank/"+payment_detail_id,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
            display_error_message(response);
            if(response.response_data.success == true) {
                loadTablePaymentDetail();
            }
        }
    });
}*/

window.initiateMicroDeposit = function(payment_detail_id) {
	$.ajax({
        method: 'POST',
        url: api_url+"payment-details/initiate-micro-deposit",
        data:'payment_details_id='+payment_detail_id,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
            display_error_message(response);
            if(response.response_data.success == true) {
                loadTablePaymentDetail();
            }
        }
    });
}

window.loadTableInvoiceDetail = function() {
	var tz = jstz.determine();
	var timezone = tz.name();
	var data = 'user_id='+user.user_details.user_id+'&user_timezone='+timezone;
	$.ajax({
	    method: 'POST',
	    url: api_url+"invoices",
	    data:data,
	    beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
	}).then(function(response) {
		if(response.response_data.success == true) {
			var html = '';
			if(response.invoices == 0) {
	        	html += '<a href="javascript:void(0)" class="list-group-item list-group-item-action border-0 bg-light text-center"><div class="fs-16">'+response.response_data.message+'</div></a>';
		    } else {
		    	for (var key in response.invoices) {
	                if (response.invoices.hasOwnProperty(key)) {

	                	var bg_light = '';
	                	if(key % 2 == 1) {
	                		bg_light = 'bg-light';
	                	}

	                	var kebab_item = '';
	                	kebab_item += '<a data-invoice-id="'+response.invoices[key].id+'" class="dropdown-item view_invoice" href="javascript:void(0)">View</a>';
	                	kebab_item += '<a data-invoice-id="'+response.invoices[key].id+'" data-from="invoice_list" class="dropdown-item download_invoice" href="javascript:void(0)">Download</a>';
	                	kebab_item += '<a data-invoice-id="'+response.invoices[key].id+'" class="dropdown-item email_invoice" href="javascript:void(0)">Email</a>';
	                    
	                	var status = '';
	                    if(response.invoices[key].status == 1) {
				        	status += '<div class="badge badge-danger">'+response.invoices[key].status_label+'</div>';
				        } else if(response.invoices[key].status == 3) {
				        	status += '<div class="badge badge-warning">'+response.invoices[key].status_label+'</div>';
				        } else if(response.invoices[key].status == 4) {
				        	status += '<div class="badge badge-success">'+response.invoices[key].status_label+'</div>';
				        }

	                    var kebab = kebab_item;

	                	// Card based UI

	                	var coupon_code = response.invoices[key].coupon_code;
	                	var coupon_amount = '$'+response.invoices[key].coupon_discounted_amount;
	                	if(response.invoices[key].coupon_id == 0) {
	                		coupon_code = '-';
	                		coupon_amount = '-';
	                	}

	                	html += '<div class="card tab-card '+bg_light+'">';
				            html += '<div class="accordion">';
				                html += '<div class="border-bottom">';
				                    html += '<h5 class="card-title my-2 fs-16">Invoice: #'+response.invoices[key].id+' &nbsp;'+status+'</h5>';
				                    html += '<span class="accordion_arrow">';
				                        html += '<div class="text-center"><div class="input-group-prepend"><i class="align-middle mr-2 fas fa-fw fa-ellipsis-v" data-toggle="dropdown" aria-expanded="false"></i><div class="dropdown-menu dropdown-menu-right" style="">'+kebab+'</div></div></div>';
				                    html += '</span>';
				                html += '</div>';
				                html += '<div class="tab_inner_data" data-id="'+response.invoices[key].id+'" data-toggle="modal" data-target="#tenantEditModal" data-backdrop="static" data-keyboard="false">';
				                    html += '<div class="row">';
				                        html += '<div class="col-12 col-md-2 col-xl-2 d-flex">';
				                            html += '<div class="card flex-fill '+bg_light+'">';
				                                html += '<div class="mt-2">';
				                                	html += '<div class="black"><strong>Property:</strong></div>';
				                                    html += '<div class="grey"><strong>'+response.invoices[key].property.name+'</strong></div>';
				                                    html += '<div class="black"><strong>Plan:</strong></div>';
				                                    html += '<div class="grey"><strong>'+response.invoices[key].plan_name+'</strong></div>';
				                                html += '</div>';
				                            html += '</div>';
				                        html += '</div>';
				                        html += '<div class="col-12 col-md-2 col-xl-2 d-flex">';
				                            html += '<div class="card flex-fill '+bg_light+'">';
				                                html += '<div class="mt-2">';
				                                    html += '<div class="black"><strong>Invoice Month:</strong></div>';
				                                    html += '<div class="grey"><strong>'+response.invoices[key].bill_from_date+'<br>to<br>'+response.invoices[key].bill_to_date+'</strong></div>';
				                                html += '</div>';
				                            html += '</div>';
				                        html += '</div>';
				                        html += '<div class="col-12 col-md-2 col-xl-2 d-flex">';
				                            html += '<div class="card flex-fill '+bg_light+'">';
				                                html += '<div class="mt-2">';
				                                    html += '<div class="black"><strong>Next Billing Date:</strong></div>';
				                                    html += '<div class="grey"><strong>'+response.invoices[key].next_bill_from_date+'</strong></div>';
				                                html += '</div>';
				                            html += '</div>';
				                        html += '</div>';				                         
				                        html += '<div class="col-12 col-md-2 col-xl-2 d-flex">';
				                            html += '<div class="card flex-fill '+bg_light+'">';
				                                html += '<div class="mt-2">';
				                                    html += '<div class="black"><strong>Coupon:</strong></div>';
				                                    html  += '<div class="grey">Code: <strong>'+coupon_code+'</strong></div>';
				                                    html  += '<div class="grey">Amount: <strong>'+coupon_amount+'</strong></div>';
				                                html += '</div>';
				                            html += '</div>';
				                        html += '</div>';
				                        html += '<div class="col-12 col-md-2 col-xl-2 d-flex">';
				                            html += '<div class="card flex-fill '+bg_light+'">';
				                                html += '<div class="mt-2">';
				                                    html += '<div class="black"><strong>Bill From:</strong></div>';
				                                    html += '<div class="grey"><strong>'+response.invoices[key].bill_from_name+'</strong></div>';
				                                    html += '<div class="black"><strong>Billed To:</strong></div>';
				                                    html += '<div class="grey"><strong>'+response.invoices[key].bill_to_name+'</strong></div>';
				                                html += '</div>';
				                            html += '</div>';
				                        html += '</div>';
				                        html += '<div class="col-12 col-md-2 col-xl-1 d-flex">';
				                            html += '<div class="card flex-fill '+bg_light+'">';
				                                html += '<div class="mt-2">';
				                                	html += '<div class="black"><strong>Amount:</strong></div>';
				                                    html += '<div class="green fs-18"><strong> $'+response.invoices[key].total+'</strong></div>';
				                                html += '</div>';
				                            html += '</div>';
				                        html += '</div>';
				                        html += '<div class="col-12 col-md-2 col-xl-1 d-flex">';
				                            html += '<div class="card flex-fill '+bg_light+'">';
				                                html += '<div class="mt-2">';
				                                    html += '<small>';
				                                        html += '<a href="javascript:void(0)" data-invoice-id="'+response.invoices[key].id+'" class="view_invoice">View</a><br>';
				                                        html += '<a href="javascript:void(0)" data-invoice-id='+response.invoices[key].id+' data-from="invoice_list" class="download_invoice">Download</a><br>';
				                                        html += '<a href="javascript:void(0)" data-invoice-id='+response.invoices[key].id+' class="email_invoice">Email</a><br>';
				                                    html += '</small>';
				                                html += '</div>';
				                            html += '</div>';
				                        html += '</div>';
				                    html += '</div>';
				                html += '</div>';
				            html += '</div>';
				        html += '</div>';
	                }
	            }
	        }
            $('#invoiceDataContainer').html(html);
        } else {
            display_error_message(response);
        }
    }, function() {
        // hang on this step if the error occur
    });
}

window.getInvoiceData = function(invoice_id) {
	var tz = jstz.determine();
	var timezone = tz.name();
	var data = 'invoice_id='+invoice_id+'&user_timezone='+timezone;
	$.ajax({
	    method: 'POST',
	    url: api_url+"invoices/view",
	    data:data,
	    beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
	}).then(function(response) {
		if(response.response_data.success == true) {
			var html = '';
			if(response.invoice == 0) {
	        	html += '<a href="javascript:void(0)" class="list-group-item list-group-item-action border-0 bg-light text-center"><div class="fs-16">'+response.response_data.message+'</div></a>';
		    } else {

		    	var status = '';
                if(response.invoice.status == 1) {
		        	status += '<div class="badge badge-danger">'+response.invoice.status_label+'</div>';
		        } else if(response.invoice.status == 3) {
		        	status += '<div class="badge badge-warning">'+response.invoice.status_label+'</div>';
		        } else if(response.invoice.status == 4) {
		        	status += '<div class="badge badge-success">'+response.invoice.status_label+'</div>';
		        }

		        html += '<input type="hidden" name="selected_invoice_id" id="selected_invoice_id" value="'+invoice_id+'"/>';

		    	html += '<div class="col-12 col-md-12 col-xl-12">';
		            html += '<div class="card-body m-sm-3">';
		                html += '<div class="mb-4">';
		                    html += '<h3 class="float-right mt-4">INVOICE</h3>';
		                    html += '<img src="icon/logo1.PNG" height="75px" />';
		                html += '</div>';
		                html += '<hr class="my-4">';
		                html += '<div class="row mb-4">';
		                    html += '<div class="col-md-6">';
		                        html += '<span class="bold-black">'+response.invoice.bill_from_name+'</span>';
		                        html += '<p>';
		                            html += ''+response.invoice.bill_from_name+'<br>'+response.invoice.bill_from_address_line1+', '+response.invoice.bill_from_address_line2+' <br> '+response.invoice.bill_from_city+', '+response.invoice.bill_from_state+' '+response.invoice.bill_from_zipcode+' <br> '+response.invoice.bill_from_country+' <br>';
		                            html += '<a href="#">'+response.invoice.bill_from_email+'</a>';
		                        html += '</p>';
		                    html += '</div>';
		                    html += '<div class="col-md-6 text-md-left">';
		                        html += 'Customer Account: '+response.invoice.customer_account_number+'<br>';
		                        html += 'Invoice: #'+response.invoice.id+'<br>';
		                        html += 'Invoice Date: '+response.invoice.invoice_date1+'<br>';
		                        html += 'Invoice Amount: <span class="bold-black">$'+response.invoice.total+' (USD)</span><br>';
		                        html += '<h4 class="mt-2">'+status+'</h4>';
		                    html += '</div>';
		                html += '</div>';
		                html += '<div class="row mb-4">';
		                    html += '<div class="col-md-6">';
		                        html += '<span class="bold-black">BILLED TO</span>';
		                        html += '<p>';
		                            html += ''+response.invoice.bill_to_name+'<br>'+response.invoice.bill_to_address_line1+', '+response.invoice.bill_to_address_line2+' <br> '+response.invoice.bill_to_city+', '+response.invoice.bill_to_state+' '+response.invoice.bill_to_zipcode+' <br> '+response.invoice.bill_to_country+' <br>';
		                            html += '<a href="#">'+response.invoice.bill_to_email+'</a>';
		                        html += '</p>';
		                    html += '</div>';
		                    html += '<div class="col-md-6 text-md-left">';
		                        html += '<span class="bold-black">'+response.invoice.sp_type_label+'</span><br>';
		                        if(response.invoice.sp_typeID == 1) {
		                        	html += 'Plan Name: <span class="bold-black">Basic</span><br>';
		                        }
		                        html += 'Billing Period: '+response.invoice.bill_from_date+' to '+response.invoice.bill_to_date+'<br>';
		                        html += 'Next Billing Date: '+response.invoice.next_bill_from_date;
		                    html += '</div>';
		                html += '</div>';
		                html += '<table class="table table-sm">';
		                    html += '<thead>';
		                        html += '<tr class="bg-light">';
		                            html += '<th><span class="bold-black">Description</span></th>';
		                            html += '<th><span class="bold-black">Unit</span></th>';
		                            html += '<th class="text-right"><span class="bold-black">Unit Price</span></th>';
		                            html += '<th class="text-right"><span class="bold-black">Amount(USD)</span></th>';
		                        html += '</tr>';
		                    html += '</thead>';
		                    html += '<tbody>';
		                        html += '<tr>';
		                            html += '<td><span class="bold-black">Rent Synergy Monthly Subscription</span></td>';
		                            html += '<td>1</td>';
		                            html += '<td class="text-right">$'+response.invoice.invoice_amount+'</td>';
		                            html += '<td class="text-right">$'+response.invoice.invoice_amount+'</td>';
		                        html += '</tr>';
		                        if(response.invoice.coupon_id > 0) {
		                        	html += '<tr>';
			                            html += '<td><span class="green ">Coupon Applied ('+response.invoice.coupon_code+')</span></td>';
			                            html += '<td>&nbsp;</td>';
			                            html += '<td class="text-right">&nbsp</td>';
			                            html += '<td class="green text-right">$'+response.invoice.coupon_discounted_amount+'</td>';
			                        html += '</tr>';
		                        }
		                        html += '<tr>';
		                            html += '<th>&nbsp;</th>';
		                            html += '<th>&nbsp;</th>';
		                            html += '<th class="text-right">Total </th>';
		                            html += '<td class="text-right">$'+response.invoice.total+'</td>';
		                        html += '</tr>';
		                        html += '<tr>';
		                            html += '<th>&nbsp;</th>';
		                            html += '<th>&nbsp;</th>';
		                            html += '<th class="text-right">Payments </th>';
		                            html += '<th class="text-right">($'+response.invoice.total+')</th>';
		                        html += '</tr>';
		                        html += '<tr>';
		                            html += '<th>&nbsp;</th>';
		                            html += '<th>&nbsp;</th>';
		                            html += '<th class="text-right">Amount Due(USD) </th>';
		                            html += '<th class="text-right">$0.00</th>';
		                        html += '</tr>';
		                    html += '</tbody>';
		                html += '</table>';
		                html += '<div class="row mb-4">';
		                    html += '<div class="col-md-12">';
		                        html += '<span class="bold-black fs-18">PAYMENTS</span>';
		                        html += '<p class="mt-3"><span class="bold-black">$'+response.invoice.total+'</span> was processed and paid on '+response.invoice.invoice_date2+'.</p>';
		                    html += '</div>';
		                html += '</div>';
		                html += '<div class="text-center">';
		                    html += '<a href="javascript:void(0)" data-invoice-id='+response.invoice.id+' data-from="invoice_preview" class="btn btn-orange download_invoice hide_download_invoice">Download Invoice</a>';
		                html += '</div>';
		            html += '</div>';
			    html += '</div>';
	        }
            $('#invoice_content_container').html(html);
        } else {
            display_error_message(response);
        }
    }, function() {
        // hang on this step if the error occur
    });
}

window.sendInvoiceEmail = function(pdfAsString) {
	var tz = jstz.determine();
	var timezone = tz.name();
	var invoice_id = $('#selected_invoice_id').val();
	var data = 'user_id='+user.user_details.user_id+'&invoice_id='+invoice_id+'&user_timezone='+timezone+'&pdfAsString='+pdfAsString;
	$.ajax({
        method: 'POST',
        url: api_url+"invoices/send-email",
        data: data,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
            display_error_message(response);
        }
    });
}

/* End : Myaccount */

window.processPendingPayment = function(property_id) {
	$.ajax({
        method: 'POST',
        url: api_url+"scheduled-payments/process-scheduled-payments",
        //url: api_url+"scheduled-payments/process-scheduled-payments-in-single-transfer",
        data: 'property_id='+property_id,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	display_error_message(response);
        	if(response.response_data.success == false) {
            	$('.process_pending_payment').attr('disabled',false);
            }
        }
    });
}

window.payToPropertyOwner = function(property_id) {
	var user_id = user.user_details.user_id;
	$.ajax({
        method: 'POST',
        url: api_url+"scheduled-payments/process-property-owner-payments",
        data: 'property_id='+property_id+'&user_id='+user_id,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	display_error_message(response);
        	if(response.response_data.success == false) {
            	$('.pay_to_property_owner').attr('disabled',false);
            }
        }
    });
}

/*window.processFirstMonthPayment = function() {
	$.ajax({
        method: 'POST',
        url: api_url+"first-month-payments/process-first-month-payments",
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
            display_error_message(response);
        }
    });
}*/

/* System email data */

window.getSystemEmailDatas = function(type = '') {
	var tz = jstz.determine();
	var timezone = tz.name();
	var user_id = user.user_details.user_id;
	$.ajax({
        method: 'POST',
        url: api_url+"system-email-datas",
        data: 'user_timezone='+timezone+'&email_type='+type+'&user_id='+user_id+'&user_service_name='+user_service_name,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	//display_error_message(response);
    		var html = '';
    		if(response.TotalEmails == 0) {
    			html += '<tr>';
                    html += '<td class="text-center" colspan="8"><strong>'+response.response_data.message+'</strong></td>';
                html += '</tr>';
    		} else {
            	for (var key in response.system_email_datas) {
	                if (response.system_email_datas.hasOwnProperty(key)) {
	                	var status = 'Success';
	                	if(response.system_email_datas[key].status == 0) {
	                		status = 'Fail';
	                	}
	                	/*var archived = '';
	                	if(response.system_email_datas[key].days >= 180 && response.system_email_datas[key].is_archived == 1) {
	                		archived += '<i class="material-icons md-dark pmd-md make_unarchive" data-id="'+response.system_email_datas[key].id+'" data-toggle="tooltip" data-placement="bottom" data-original-title="unarchive">unarchive</i>';
	                		bg_color = '#f7dcdc';
	                	}*/
	                	html += '<tr data-id="'+response.system_email_datas[key].id+'">';
                            html += '<td>'+response.system_email_datas[key].id+'</td>';
                            html += '<td>'+response.system_email_datas[key].email_from+'</td>';
                            html += '<td>'+response.system_email_datas[key].email_to+'</td>';
                            html += '<td>'+response.system_email_datas[key].email_subject+'</td>';
                            html += '<td>'+status+'</td>';
                            //html += '<td>'+archived+'</td>';
                            html += '<td>'+response.system_email_datas[key].created+'</td>';
                            html += '<td class="text-center"><i class="align-middle mr-2 fas fa-fw fa-eye email_preview" data-id="'+response.system_email_datas[key].id+'" data-type="sent"></i></td>';
                        html += '</tr>';
	                }
	            }
	        }

            if(type == 'inbox') {
            	var container = 'inbox_data_container';
            	var db_table = 'inbox-data-datatable';
            } else if(type == 'sent-email') {
            	var container = 'sent_email_data_container';
            	var db_table = 'sent-email-data-datatable';
            }

            $('#'+container).html(html);

            if ($.fn.DataTable.isDataTable("#"+db_table)) {
	            $("#"+db_table).DataTable().destroy();
	        }

	        $("#"+db_table).DataTable({
			    responsive: true,
			    order: [[0,'desc']]
			});
        }
    });
}

window.getSentEmailPreview = function(id) {
	$.ajax({
        method: 'POST',
        url: api_url+"system-email-datas/email-preview",
        data: 'id='+id,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	var html = '';
        	if(response.response_data.success == true) {
        		$('#emailPreviewModal').modal('show');
        		openLoadingDialog($('#email_preview_container'));
        		var email_subject = response.system_email_data.email_subject;
        		var email_content = atob(response.file_data);
        		
				html += '<div class="row h-100">';
				        html += '<div class="card border">';
				            html += '<div class="card-header border-bottom">';
				                html += '<div class="card-actions float-right">';
				                    html += '<i class="align-middle mr-4 mt-2 fas fa-fw fa-window-close" class="close" data-dismiss="modal" aria-label="Close"></i>';
				                html += '</div>';
				                html += '<h5 class="card-title my-2">Email Subject : '+email_subject+'</h5>';
				            html += '</div>';
				            html += '<form id="email_preview_form" class="wizard-primary create-account-form" action="javascript:void(0)">';
				                html += '<div id="email_preview_content">';
				                	html += '<style>table, td, div, h1, p{font-family: "Segoe UI"; color: dimgray;}p{line-height: 1.5;}h1{padding-bottom: 5% !important;}@media screen and (max-width: 530px){.unsub{display: block; padding: 8px; margin-top: 14px; border-radius: 6px; background-color: #555555; text-decoration: none !important; font-weight: bold;}.col-lge{max-width: 100% !important;}}@media screen and (min-width: 531px){.col-sml{max-width: 27% !important;}.col-lge{max-width: 73% !important;}}</style>';
				                	html += '<body style="margin:0;padding:0;word-spacing:normal;"> <div role="article" aria-roledescription="email" lang="en" style="padding: 10px; text-size-adjust:100%;-webkit-text-size-adjust:100%;-ms-text-size-adjust:100%;background-color:#f6f9fc;"> <table role="presentation" style="width:100%;border:none;border-spacing:0;font-family: Segoe UI";> <tr> <td align="center" style="padding:0;"> <table role="presentation" style="width:94%;max-width:600px;border:none;border-spacing:0;text-align:left;font-family:Segoe UI;font-size:16px;line-height:22px;color:dimgray;"> <tr> <td style="padding:30px 25px 0px 25px;text-align:center;font-size:30px;font-weight:bold;background-color: #ffffff;"> <img src="https://static.rentsynergy.com/images/rslogo22087tbg.png" alt="Logo"> </td></tr><tr> <td style="padding:30px 60px 30px 60px;background-color:#ffffff;min-height: 100px;font-family: Segoe UI;"> '+email_content+' </td></tr><tr> <td style="padding:30px 60px 30px 60px;text-align:center;font-family: Segoe UI; font-size:12px;background-color:white;border-top: 1px solid #ebeef1;"> <p>You\'re receiving this email because you have a Rent Synergy account. Please note, you may not be able to opt out of these emails, as they\'re non-promotional in nature and contain important information regarding your account.<br>Please do not reply to this email. Instead, contact us at <a href="mailto:support@rentsynergy.com">support@rentsynergy.com</a></p><p>&#169;'+new Date().getFullYear()+' L3 Software Services LLC DBA rentsynergy.com. All rights reserved.<br>14200 Midway Road #120, Dallas, TX 75244 USA</p></td></tr></table> </td></tr></table> </div></body>';
				                html += '</div>';
				                html += '<div class="border-top">';
				                    html += '<div class="row">';
				                        html += '<div class="col-auto ml-auto text-right mt-2 mb-2 mr-2">';
				                            html += '<button type="button" data-id="'+id+'" class="btn btn-orange resend_email">Resend</button>';
				                            html += '<button type="button" class="btn btn-primary ml-2" data-dismiss="modal" aria-label="Close">Close</button>';
				                        html += '</div>';
				                    html += '</div>';
				                html += '</div>';
				            html += '</form>';
				        html += '</div>';
				html += '</div>';

				$('#email_preview_container').html(html);
            } else {
				display_error_message(response);
            }
        }
    });
}

window.resendEmail = function(id) {
	$.ajax({
        method: 'POST',
        url: api_url+"system-email-datas/resend-email",
        data: 'id='+id,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	display_error_message(response);
        	$('#emailPreviewModal').modal('hide');
        }
    });
}

var $emailTemplateForm;
window.emailTemplateValidation = function() {
	$emailTemplateForm = $("#email_template_form");
	$emailTemplateForm.validate({
	    errorPlacement: function errorPlacement(error, element) {
	        $(element).parents(".form-group").append(error.addClass("invalid-feedback small d-block"))
	    },
	    highlight: function(element) {
	        $(element).addClass("is-invalid");
	    },
	    unhighlight: function(element) {
	        $(element).removeClass("is-invalid");
	    },
	    rules: {
	        "email_name": { required: true },
	        "email_identifier": { required: true },
	        "email_subject": { required: true },
	    },
	    messages: {
	        "email_name": { required: '' },
	        "email_identifier": { required: '' },
	        "email_subject": { required: '' },
	    }
	});
}

window.getEmailTemplates = function() {
	$.ajax({
        method: 'GET',
        url: api_url+"email-templates",
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	display_error_message(response);
        	if(response.response_data.success == true) {
        		var html = '';
            	for (var key in response.emailTemplates) {
	                if (response.emailTemplates.hasOwnProperty(key)) {
	                	var kebab = '';
	                	kebab += '<div class="text-center"><div class="input-group-prepend"><i class="align-middle mr-2 fas fa-fw fa-ellipsis-v" data-toggle="dropdown" aria-expanded="false"></i><div class="dropdown-menu dropdown-menu-right" style=""><a class="dropdown-item edit_email_template" href="javascript:void(0)" data-id="'+response.emailTemplates[key].id+'">Edit</a></div></div></div>';

	                	html += '<tr>';
                            html += '<td>'+response.emailTemplates[key].id+'</td>';
                            html += '<td>'+response.emailTemplates[key].email_name+'</td>';
                            html += '<td>'+response.emailTemplates[key].email_identifier+'</td>';
                            html += '<td>'+response.emailTemplates[key].email_subject+'</td>';
                            html += '<td>'+response.emailTemplates[key].status_label+'</td>';
                            html += '<td class="text-center"><i class="align-middle mr-2 fas fa-fw fa-eye email_preview" data-id="'+response.emailTemplates[key].id+'" data-type="main" class="email_preview"></i></td>';
                            html += '<td>'+kebab+'</td>';
                        html += '</tr>';
	                }
	            }

	            if ($.fn.DataTable.isDataTable("#email-template-data-datatable")) {
		            $("#email-template-data-datatable").DataTable().destroy();
		        }

	            $('#email_template_data_container').html(html);

				var email_template_table = $("#email-template-data-datatable").DataTable({
	            	dom: 'Bfrtip',
	    			autoWidth: true,
	            	paging: false,
	            	info: false,
				    scrollY: '425px',
				    scrollX: true,
			        scrollCollapse: true,
			        order: [[0,'desc']],
			        fixedColumns:   {
			            left: 2
			        },
			        buttons: [
			           {
			              extend: 'colvis',
			              text: "Columns Visibility ",
			              collectionLayout: 'two-column',
			              postfixButtons: ['colvisRestore'],
			           }
					],
					language: {
				        buttons: {
				          colvisRestore: "Select All"
				        }
				    },
					initComplete: function () {
			          	//console.log("The Table has been initialized!")
			        }
				});

				$('.buttons-colvis').removeClass('btn-secondary').addClass('btn-primary');
            }
        }
    });
}

window.getEmailIdentifier = function(identifier = null) {
	$.ajax({
        method: 'GET',
        url: api_url+"email-templates/identifiers",
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	if(response.response_data.success == true) {
        		var html = '';
        		html += "<option value=''>Select Email Identifire</option>";
                $.each( response.emailIdentifiers, function( key, value ) {
                	var selected = '';
                	if(key == identifier) {
                		selected = 'selected';
                	}
				  	html += '<option value="'+key+'" '+selected+'>'+value+'</option>';
				});
		        $('#email_identifier').html(html);
            } else {
            	display_error_message(response);
            }
        }
    });
}

window.getEmailTemplateDetail = function(email_template_id) {
	$.ajax({
        method: 'GET',
        url: api_url+"email-templates/view/"+email_template_id,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	display_error_message(response);
        	if(response.response_data.success == true) {
        		$('#email_name').val(response.emailTemplate.email_name);
				$('#email_identifier').val(response.emailTemplate.email_identifier);
				$('#email_subject').val(response.emailTemplate.email_subject);
				$('#email_template_status_dd').val(response.emailTemplate.status);
				getEmailIdentifier(response.emailTemplate.email_identifier);
				setTimeout(function() {
					CKEDITOR.instances['email_body'].setData(response.emailTemplate.email_body);
				}, 1000);
            }
        }
    });
}

window.saveEmailTemplateData = function(type) {
	if($emailTemplateForm.valid()){
		$('.save_email_template_data').attr('disabled',true);

		var user_id = user.user_details.user_id;
		var email_name = $('#email_name').val();
		var email_identifier = $('#email_identifier').val();
		var email_subject = $('#email_subject').val();
		var status = $('#email_template_status_dd').val();
		var email_body = CKEDITOR.instances['email_body'].getData();

		if(email_identifier == null) {
			$('.save_email_template_data').attr('disabled',false);
			var response = {
                "response_data": {
                    "success": false,
                    "message": 'Please select email identifier'
                }
            };
            display_error_message(response);
            return false;
		}

		if(type == 'add') {
			var url = api_url+"email-templates/add";
		} else if(type == 'edit') {
			var email_template_id = $('#selected_email_template_id').val();
			var url = api_url+"email-templates/edit/"+email_template_id;
		}

		$.ajax({
            method: 'POST',
            url: url,
            data: 'user_id='+user_id+"&email_name="+email_name+'&email_identifier='+email_identifier+'&email_subject='+email_subject+'&status='+status+'&email_body='+encodeURIComponent(email_body),
            beforeSend: function(xhr) {
		        xhr.setRequestHeader('Authorization', accessToken);
		    },
            success: function(response) {
                display_error_message(response);
                $('.save_email_template_data').attr('disabled',false);
                if(response.response_data.success == true) {
                	$('#addEmailTemplateModal').modal('hide');
                	$('#editEmailTemplateModal').modal('hide');
                	getEmailTemplates();
                }
            }
        });
    }
}

window.getMainEmailPreview = function(id) {
	$.ajax({
        method: 'POST',
        url: api_url+"email-templates/email-preview",
        data: 'id='+id,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	var html = '';
        	if(response.response_data.success == true) {
        		$('#emailPreviewModal').modal('show');
        		openLoadingDialog($('#email_preview_container'));
        		var email_subject = response.emailTemplate.email_subject;
        		var email_content = response.emailTemplate.email_body+'<div style="padding: 2% 10% 2% 0%; font-size: 16px; font-family: system-ui; color: dimgray;"><p>Thank you,<br>The Rent Synergy Team</p></div>';
        		
				html += '<div class="row h-100">';
				        html += '<div class="card border">';
				            html += '<div class="card-header border-bottom">';
				                html += '<div class="card-actions float-right">';
				                    html += '<i class="align-middle mr-4 mt-2 fas fa-fw fa-window-close" class="close" data-dismiss="modal" aria-label="Close"></i>';
				                html += '</div>';
				                html += '<h5 class="card-title my-2">Email Subject : '+email_subject+'</h5>';
				            html += '</div>';
				            html += '<form id="email_preview_form" class="wizard-primary create-account-form" action="javascript:void(0)">';
				                html += '<div id="email_preview_content">';
				                	html += '<style>table, td, div, h1, p{font-family: "Segoe UI"; color: dimgray;}p{line-height: 1.5;}h1{padding-bottom: 5% !important;}@media screen and (max-width: 530px){.unsub{display: block; padding: 8px; margin-top: 14px; border-radius: 6px; background-color: #555555; text-decoration: none !important; font-weight: bold;}.col-lge{max-width: 100% !important;}}@media screen and (min-width: 531px){.col-sml{max-width: 27% !important;}.col-lge{max-width: 73% !important;}}</style>';
				                	html += '<body style="margin:0;padding:0;word-spacing:normal;"> <div role="article" aria-roledescription="email" lang="en" style="padding: 10px; text-size-adjust:100%;-webkit-text-size-adjust:100%;-ms-text-size-adjust:100%;background-color:#f6f9fc;"> <table role="presentation" style="width:100%;border:none;border-spacing:0;font-family: Segoe UI";> <tr> <td align="center" style="padding:0;"> <table role="presentation" style="width:94%;max-width:600px;border:none;border-spacing:0;text-align:left;font-family:Segoe UI;font-size:16px;line-height:22px;color:dimgray;"> <tr> <td style="padding:30px 25px 0px 25px;text-align:center;font-size:30px;font-weight:bold;background-color: #ffffff;"> <img src="https://static.rentsynergy.com/images/rslogo22087tbg.png" alt="Logo"> </td></tr><tr> <td style="padding:30px 60px 30px 60px;background-color:#ffffff;min-height: 100px;font-family: Segoe UI;"><h1 style="line-height:initial;text-align:center;margin-bottom:36px;">'+email_subject+'</h1> '+email_content+' </td></tr><tr> <td style="padding:30px 60px 30px 60px;text-align:center;font-family: Segoe UI; font-size:12px;background-color:white;border-top: 1px solid #ebeef1;"> <p>You\'re receiving this email because you have a Rent Synergy account. Please note, you may not be able to opt out of these emails, as they\'re non-promotional in nature and contain important information regarding your account.<br>Please do not reply to this email. Instead, contact us at <a href="mailto:support@rentsynergy.com">support@rentsynergy.com</a></p><p>&#169;'+new Date().getFullYear()+' L3 Software Services LLC DBA rentsynergy.com. All rights reserved.<br>14200 Midway Road #120, Dallas, TX 75244 USA</p></td></tr></table> </td></tr></table> </div></body>';
				                html += '</div>';
				                html += '<div class="border-top">';
				                    html += '<div class="row">';
				                        html += '<div class="col-auto ml-auto text-right mt-2 mb-2 mr-2">';
				                            html += '<button type="button" class="btn btn-primary ml-2" data-dismiss="modal" aria-label="Close">Close</button>';
				                        html += '</div>';
				                    html += '</div>';
				                html += '</div>';
				            html += '</form>';
				        html += '</div>';
				html += '</div>';

				$('#email_preview_container').html(html);
            } else {
				display_error_message(response);
            }
        }
    });
}

/* Dwolla webhook datas */

window.getDwollaWebhooks = function() {
	if(user.permissionData['DWOLLA-WEBHOOK']["feature_items['list']"]) {
		$.ajax({
	        method: 'POST',
	        url: api_url+"webhook-events",
	        beforeSend: function(xhr) {
		        xhr.setRequestHeader('Authorization', accessToken);
		    },
	        success: function(response) {
	        	display_error_message(response);
	        	if(response.response_data.success == true) {
	        		var html = '';
	            	for (var key in response.webhookEvents) {
		                if (response.webhookEvents.hasOwnProperty(key)) {
		                	html += '<tr>';
	                            html += '<td>'+response.webhookEvents[key].id+'</td>';
	                            html += '<td>'+response.webhookEvents[key].transferID+'</td>';
	                            html += '<td>'+response.webhookEvents[key].webhookID+'</td>';
	                            html += '<td>'+response.webhookEvents[key].resourceID+'</td>';
	                            html += '<td>'+response.webhookEvents[key].topic+'</td>';
	                            html += '<td>'+response.webhookEvents[key].timestamp+'</td>';
	                            html += '<td>'+response.webhookEvents[key].self+'</td>';
	                            html += '<td>'+response.webhookEvents[key].account+'</td>';
	                            html += '<td>'+response.webhookEvents[key].resource+'</td>';
	                            html += '<td>'+response.webhookEvents[key].customer+'</td>';
	                            html += '<td>'+response.webhookEvents[key].created+'</td>';
	                        html += '</tr>';
		                }
		            }
		            $('#dwolla_data_container').html(html);
		            $("#dwolla-webhook-datatable").DataTable({
					    responsive: true,
					    order: [[0,'desc']]
					});
	            }
	        }
	    });
	}
}

window.getRelatedTransactionDetail = function(id,transferID) {
	var html = '';
	$.ajax({
        method: 'POST',
        url: api_url+"scheduled-payments/get-related-transaction-detail",
        data: 'id='+id+'&transferID='+transferID,
        async: false,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	if(response.response_data.success == true) {
        		html += '<div class="fixTableHead">';
        		html += '<table id="transaction_table" class="table table-striped" style="width:100%">';
        			html += '<thead>';
	        			html += '<tr>';
	        			html += '<th class="black fs-14">Status</th>';
	        			html += '<th class="black fs-14">Progress Detail</th>';
	        			html += '<th class="black fs-14">Source</th>';
	        			html += '<th class="black fs-14">Destination</th>';
	        			html += '<th class="black fs-14">Who Changed</th>';
	        			html += '<th class="black fs-14">Which Date</th>';
	        			html += '</tr>';
	        		html += '</thead>';
        			html += '<tbody>';
        			if(response.TotalapplicationStatus == 0) {
        				html += '<tr>';
		                    html += '<td class="text-center" colspan="6"><strong>'+response.response_data.message+'</strong></td>';
	                    html += '</tr>';
        			} else {
		    			for (var key in response.transactionStatusArray) {
			                if (response.transactionStatusArray.hasOwnProperty(key)) {
			                	var d1 = response.transactionStatusArray[key].status_date;
			                	d1 = d1.replace('T',' ');
			                	d1 = d1.replace('+00:00','');
		                		html += '<tr>';
				                    html += '<td><strong>'+response.transactionStatusArray[key].status+'</strong></td>';
				                    html += '<td>&nbsp;</td>';
				                    html += '<td>&nbsp;</td>';
				                    html += '<td>&nbsp;</td>';
				                    html += '<td><strong>'+response.transactionStatusArray[key].who_changed+'</strong></td>';
				                    html += '<td><strong>'+d1+'</strong></td>';
			                    html += '</tr>';

			                    if(response.transactionStatusArray[key].webhook_data) {
									for (var wkey in response.transactionStatusArray[key].webhook_data) {
										if (response.transactionStatusArray[key].webhook_data.hasOwnProperty(wkey)) {
											var d2 = response.transactionStatusArray[key].webhook_data[wkey].timestamp;
						                	d2 = d2.replace('T',' ');
						                	d2 = d2.replace('+00:00','');
						                	var topic = response.transactionStatusArray[key].webhook_data[wkey].topic;
						                	topic = topic.replaceAll("_", " ");
						                	topic = eachWordFirstLetterCapital(topic);
						                	var customer = response.transactionStatusArray[key].webhook_data[wkey].user;
											html += '<tr>';
												html += '<td>&nbsp;</td>';
							                    html += '<td>'+topic+'</td>';
							                    html += '<td>'+customer+'</td>';
							                    html += '<td>&nbsp;</td>';
							                    html += '<td>&nbsp;</td>';
							                    html += '<td><strong>'+d2+'</strong></td>';
						                    html += '</tr>';
										}
									}
								}
			                }
			            }
			        }
		        html += '</tbody>';
		        html += '</table>';
		        if(response.failureData != '') {
		        	html += '<h3>Transaction failed reason</h3>';
		           	html += '<table id="transaction_table" class="table table-striped" style="width:100%">';
		    			html += '<thead>';
		        			html += '<tr>';
		        			html += '<th class="black fs-14">Code</th>';
		        			html += '<th class="black fs-14">Description</th>';
		        			html += '<th class="black fs-14">Explanation</th>';
		        			html += '</tr>';
		        		html += '</thead>';
		    			html += '<tbody>';
		    				html += '<tr>';
			        			html += '<td>'+response.failureData.failure_code+'</td>';
			        			html += '<td>'+response.failureData.failure_description+'</td>';
			        			html += '<td>'+response.failureData.failure_explanation+'</td>';
		        			html += '</tr>';	
		    			html += '</tbody>';	
		    		html += '</table>';
			    }
		        html += '</div>';
            } else {
            	display_error_message(response);
            }
        }
    });

	return html;
}

/* Dwolla transfer to bank and add to balance */

var $transferToBankForm;
window.transferToBankValidation = function() {
	$transferToBankForm = $("#transfer_to_bank_form");
	$transferToBankForm.validate({
	    errorPlacement: function errorPlacement(error, element) {
	        $(element).parents(".form-group").append(error.addClass("invalid-feedback small d-block"))
	    },
	    highlight: function(element) {
	        $(element).addClass("is-invalid");
	    },
	    unhighlight: function(element) {
	        $(element).removeClass("is-invalid");
	    },
	    rules: {
	        "amount_to_withdraw": { required: true, notEqual: '0.00' },
	        "transfer_to": { required: true },
	    },
	    messages: {
	        "amount_to_withdraw": { required: '', notEqual:"" },
	        "transfer_to": { required: '' },
	    }
	});
}

var $addToBalanceForm;
window.addToBalanceValidation = function() {
	$addToBalanceForm = $("#add_to_balance_form");
	$addToBalanceForm.validate({
	    errorPlacement: function errorPlacement(error, element) {
	        $(element).parents(".form-group").append(error.addClass("invalid-feedback small d-block"))
	    },
	    highlight: function(element) {
	        $(element).addClass("is-invalid");
	    },
	    unhighlight: function(element) {
	        $(element).removeClass("is-invalid");
	    },
	    rules: {
	        "amount_to_transfer": { required: true, notEqual: '0.00' },
	        "transfer_from": { required: true },
	    },
	    messages: {
	        "amount_to_transfer": { required: '', notEqual:"" },
	        "transfer_from": { required: '' },
	    }
	});
}

window.getBalanceAmount = function() {
	openLoadingDialog($('#dwolla_balance'));
	$.ajax({
        method: 'POST',
        url: api_url+"dwollas/get-balance",
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	display_error_message(response);
        	if(response.response_data.success == true) {
        		$('#dwolla_balance').html('<h1 class="fs-36">$'+response.fs_balance+'</h1>');
        		$('#dwolla_balance_id').html('<small>id: '+response.dwollaFSBalanceID+'</small>');
            }
        }
    });
}

window.getFundingSource = function() {
	$.ajax({
        method: 'POST',
        url: api_url+"dwollas/get-banks",
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	display_error_message(response);
        	if(response.response_data.success == true) {
        		var html = "";
        		html += '<option value="">Select Bank</option>';
            	for (var key in response.fundingSource) {
	                if (response.fundingSource.hasOwnProperty(key)) {
	                	var name = response.fundingSource[key].name;
	                	// for dropdown
	                	if(response.fundingSource[key].type == 'bank' && response.fundingSource[key].status == 'verified') {
                			html += '<option value="'+response.fundingSource[key].id+'">'+name+'</option>';
                		}
					}
				}
				$('#transfer_to, #transfer_from').html(html);

				var card_html = "";
				for (var key in response.fundingSource) {
	                if (response.fundingSource.hasOwnProperty(key)) {
	                	var id = response.fundingSource[key].id;
	                	var name = response.fundingSource[key].name;
	                	var type = response.fundingSource[key].type;
	                	var status = response.fundingSource[key].status;
	                	var removed = response.fundingSource[key].removed;
	                	var bankAccountType = response.fundingSource[key].bankAccountType;
	                	var created = response.fundingSource[key].created;
	                	var time = response.fundingSource[key].time;
	                	var status_class = 'badge-danger';
	                	if(status == 'verified') {
	                		status_class = 'badge-success';
	                	}

	                	if(removed == 1) {
	                		status = 'Removed'
	                	}
                		// for display bank card
                		if(type == 'bank') {
	                		card_html += '<div class="col-xl-6">';
					            card_html += '<div class="card">';
					                card_html += '<div class="card-body">';
					                    card_html += '<div class="row no-gutters">';
					                        card_html += '<div class="col-sm-2 col-xl-2 col-xxl-2 text-center bg-light">';
					                            card_html += '<img src="img/sample/bank.jpg" width="64" height="64" alt="'+name+'">';
					                        card_html += '</div>';
					                        card_html += '<div class="col-sm-10 col-xl-10 col-xxl-10 mt-2 pl-3">';
					                            card_html += '<h5 class="card-title mb-2">'+name+'</h5>';
					                            card_html += '<p><span class="badge '+status_class+'">'+status+'</span></p>';
					                        card_html += '</div>';
					                    card_html += '</div>';
					                    card_html += '<div class="mt-2 bold-black">';
					                    	if(type == 'bank') {
					                        	card_html += '<small>'+type+' | '+bankAccountType+' | Added: '+created+' at '+time+'</small><br>';
					                        } else {
					                        	card_html += '<small>'+type+' | Added: '+created+' at '+time+'</small><br>';
					                        }
					                        card_html += '<small>id: '+id+'</small>';
					                    card_html += '</div>';
					                card_html += '</div>';
					            card_html += '</div>';
					        card_html += '</div>';
					    }
					}
				}
				$('#funding-source-container').html(card_html);
            }
        }
    });
}

window.transferToBank = function() {
	if($transferToBankForm.valid()) {
		var user_id = user.user_details.user_id;
		var transfer_to_name = $('#transfer_to :selected').html();
		$.ajax({
	        method: 'POST',
	        url: api_url+"dwollas/transfer-to-bank",
	        data: $('#transfer_to_bank_form').serialize()+'&transfer_to_name='+transfer_to_name+'&user_id='+user_id,
	        beforeSend: function(xhr) {
		        xhr.setRequestHeader('Authorization', accessToken);
		    },
	        success: function(response) {
	        	display_error_message(response);
	        	if(response.response_data.success == true) {
	        		getBalanceAmount();
	        		$('#transferFundModal').modal('hide');
	            } else {
	            	$('.dwolla_transfer_btn').attr('disabled', false);
	            }
	        }
	    });
	} else {
		$('.dwolla_transfer_btn').attr('disabled', false);
	}
}

window.addToBalance = function() {
	if($addToBalanceForm.valid()) {
		var user_id = user.user_details.user_id;
		var transfer_from_name = $('#transfer_from :selected').html();
		$.ajax({
	        method: 'POST',
	        url: api_url+"dwollas/add-to-balance",
	        data: $('#add_to_balance_form').serialize()+'&transfer_from_name='+transfer_from_name+'&user_id='+user_id,
	        beforeSend: function(xhr) {
		        xhr.setRequestHeader('Authorization', accessToken);
		    },
	        success: function(response) {
	        	display_error_message(response);
	        	if(response.response_data.success == true) {
	        		getBalanceAmount();
	        		$('#transferFundModal').modal('hide');
	            } else {
	            	$('.dwolla_transfer_btn').attr('disabled', false);
	            }
	        }
	    });
	} else {
		$('.dwolla_transfer_btn').attr('disabled', false);
	}
}

/* UI for Manage plans & Subscriptions */

var $couponForm;
window.addCouponValidation = function() {
	$couponForm = $("#coupon_add_form, #coupon_edit_form");
	$couponForm.validate({
	    errorPlacement: function errorPlacement(error, element) {
	        $(element).parents(".form-group").append(error.addClass("invalid-feedback small d-block"))
	    },
	    highlight: function(element) {
	        $(element).addClass("is-invalid");
	    },
	    unhighlight: function(element) {
	        $(element).removeClass("is-invalid");
	    },
	    rules: {
	        "coupon_code": { required: true },
	        "coupon_type": { required: true },
	        "offer_name": { required: true },
	        "discount_percentage": { required: true },
	        "offer_start_date": { required: true },
	        "offer_end_date": { required: true }
	    },
	    messages: {
	        "coupon_code": { required: '' },
	        "coupon_type": { required: '' },
	        "offer_name": { required: '' },
	        "discount_percentage": { required: '' },
	        "offer_start_date": { required: '' },
	        "offer_end_date": { required: '' }
	    }
	});
}

window.loadCouponType = function(container='') {
	$.ajax({
        method: 'GET',
        url: api_url+"coupons/get-types",
        async: true,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
            if(response.response_data.success == true) {
            	var html = '';
                //var html = "<option value=''>Select Coupon Type</option>";
                $.each( response.types, function( key, value ) {
				  	html += '<option value="'+key+'">'+value+'</option>';
				});
		        $('#'+container).html(html);
            } else {
            	display_error_message(response);
            }
        }
    });
}

window.getCouponDetailById = function(coupon_id) {
	var tz = jstz.determine();
	var timezone = tz.name();
	$.ajax({
        method: 'POST',
        url: api_url+"coupons/view/"+coupon_id,
        data: 'user_timezone='+timezone,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	display_error_message(response);
        	if(response.response_data.success == true) {
        		$('#coupon_code').val(response.coupon.code);
        		$('#coupon_type').val(response.coupon.type);
        		$('#offer_name').val(response.coupon.offer_name);
        		$('#coupon_description').val(response.coupon.description);
        		$('#offer_start_date > input').val(response.coupon.offer_start_date);
        		$('#offer_end_date > input').val(response.coupon.offer_end_date);
        		$('#discount_percentage').val(response.coupon.discount_percentage);
        		$('#duration_month').val(response.coupon.duration_months);

        		var status = response.coupon.status;
        		var html = '';
        		$.each( response.status, function( key, value ) {
        			var selected = '';
        			if(key == status) {
        				selected = 'selected';
        			}
				  	html += '<option value="'+key+'" '+selected+'>'+value+'</option>';
				});
		        $('#coupon_status').html(html);

		        if(response.coupon.coupon_used == 1) {
					$('#coupon_code, #coupon_type, #offer_name, #offer_start_date > input, #offer_end_date > input, #discount_percentage, #duration_month').attr('disabled', true);
        		}
            }
        }
    });
}

window.loadCouponData = function() {
	if(!user.permissionData['PLAN-SUBSCRIPTIONS']["feature_items['coupon_add']"]) {
		$('.add_new_coupon').remove();
	}
	//getFloatingActionButton('Coupon', '#coupon_data_section');
	var tz = jstz.determine();
	var timezone = tz.name();
	$.ajax({
        method: 'POST',
        url: api_url+"coupons",
        data: 'user_timezone='+timezone,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	display_error_message(response);
        	if(response.response_data.success == true) {
        		var html = '';
            	for (var key in response.coupons) {
	                if (response.coupons.hasOwnProperty(key)) {

	                	var kebab = '';
	                	if(response.coupons[key].status == 1) {
	                		if(user.permissionData['PLAN-SUBSCRIPTIONS']["feature_items['coupon_edit']"]) {
	                			kebab += '<div class="text-center"><div class="input-group-prepend"><i class="align-middle mr-2 fas fa-fw fa-ellipsis-v" data-toggle="dropdown" aria-expanded="false"></i><div class="dropdown-menu dropdown-menu-right" style=""><a class="dropdown-item edit_coupon" href="javascript:void(0)" data-id="'+response.coupons[key].id+'">Edit</a></div></div></div>';
	                		}
	                	}

	                	html += '<tr>';
                            html += '<td>'+response.coupons[key].id+'</td>';
                            html += '<td>'+response.coupons[key].type_label+'</td>';
                            html += '<td>'+response.coupons[key].code+'</td>';
                            html += '<td>'+response.coupons[key].offer_name+'</td>';
                            html += '<td>'+response.coupons[key].offer_start_date+'</td>';
                            html += '<td>'+response.coupons[key].offer_end_date+'</td>';
                            html += '<td>'+response.coupons[key].description+'</td>';
                            //html += '<td>'+response.coupons[key].discount_amount+'</td>';
                            html += '<td>'+response.coupons[key].discount_percentage+'%</td>';
                            //html += '<td>'+response.coupons[key].duration_months+'</td>';
                            //html += '<td>'+response.coupons[key].duration_end_date+'</td>';
                            html += '<td>'+response.coupons[key].status_label+'</td>';
                            html += '<td>'+kebab+'</td>';
                        html += '</tr>';
	                }
	            }
	            $('#coupon_data_container').html(html);
	            if (!$.fn.DataTable.isDataTable("#coupon-datatable")) {
					$("#coupon-datatable").DataTable({
				        responsive: true
				    });
				}
            }
        }
    });
}

window.saveCouponData = function(type) {
	if($couponForm.valid()){
		$('.save_coupon_data').attr('disabled',true);

		var user_id = user.user_details.user_id;
		var coupon_code = $('#coupon_code').val();
		var coupon_type = $('#coupon_type').val();
		var offer_name = $('#offer_name').val();
		var description = $('#coupon_description').val();
		var offer_start_date = $('#offer_start_date > input').val();
		var offer_end_date = $('#offer_end_date > input').val();
		var discount_amount = '0.00';
		var discount_percentage = $('#discount_percentage').val();
		var duration_months = $('#duration_month').val();
		
		var url;
		if(type == 'add') {
			var status = 1;
			var url = api_url+"coupons/add";
		} else if(type == 'edit') {
			var coupon_id = $('#selected_coupon_id').val();
			var status = $('#coupon_status').val();
			var url = api_url+"coupons/edit/"+coupon_id;
		}

		$.ajax({
            method: 'POST',
            url: url,
            data: 'user_id='+user_id+'&code='+coupon_code+'&type='+coupon_type+'&offer_name='+offer_name+'&offer_start_date='+offer_start_date+"&offer_end_date="+offer_end_date+"&description="+description+"&discount_amount="+discount_amount+"&discount_percentage="+discount_percentage+'&duration_months='+duration_months+'&duration_end_date='+offer_end_date+"&status="+status,
            beforeSend: function(xhr) {
		        xhr.setRequestHeader('Authorization', accessToken);
		    },
            success: function(response) {
                display_error_message(response);
                $('.save_coupon_data').attr('disabled',false);
                if(response.response_data.success == true) {
                	$('#couponAddModal').modal('hide');
                	$('#couponEditModal').modal('hide');
                	loadCouponData();
                }
            }
        });
    }
}

/* Plan Data */

var $planForm;
window.addPlanValidation = function() {
	$planForm = $("#plan_add_form, #plan_edit_form");
	$planForm.validate({
	    errorPlacement: function errorPlacement(error, element) {
	        $(element).parents(".form-group").append(error.addClass("invalid-feedback small d-block"))
	    },
	    highlight: function(element) {
	        $(element).addClass("is-invalid");
	    },
	    unhighlight: function(element) {
	        $(element).removeClass("is-invalid");
	    },
	    rules: {
	        "plan_name": { required: true },
	    },
	    messages: {
	        "plan_name": { required: '' },
	    }
	});
}

window.getPlanDetailById = function(plan_id) {
	var tz = jstz.determine();
	var timezone = tz.name();
	$.ajax({
        method: 'POST',
        url: api_url+"plans/view",
        data: 'user_timezone='+timezone+'&plan_id='+plan_id,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	display_error_message(response);
        	if(response.response_data.success == true) {
        		$('#plan_name').val(response.plan.name);
        		$('#plan_description').val(response.plan.description);

        		var status = response.plan.status;
        		var html = '';
        		$.each( response.status, function( key, value ) {
        			var selected = '';
        			if(key == status) {
        				selected = 'selected';
        			}
				  	html += '<option value="'+key+'" '+selected+'>'+value+'</option>';
				});
		        $('#plan_status').html(html);
            }
        }
    });
}

window.loadPlanData = function() {
	if(!user.permissionData['PLAN-SUBSCRIPTIONS']["feature_items['plan_add']"]) {
		$('.add_new_plan').remove();
	}
	$.ajax({
        method: 'GET',
        url: api_url+"plans",
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	display_error_message(response);
        	if(response.response_data.success == true) {
        		var html = '';
            	for (var key in response.plans) {
	                if (response.plans.hasOwnProperty(key)) {

	                	var radio = '<label class="custom-control custom-radio"><input name="plan-radio" type="radio" class="custom-control-input get_pricing_data" data-id="'+response.plans[key].id+'"><span class="custom-control-label">&nbsp;</span></label>';

	                	var kebab = '';
	                	if(user.permissionData['PLAN-SUBSCRIPTIONS']["feature_items['plan_edit']"]) {
	                		kebab += '<div class="text-center"><div class="input-group-prepend"><i class="align-middle mr-2 fas fa-fw fa-ellipsis-v" data-toggle="dropdown" aria-expanded="false"></i><div class="dropdown-menu dropdown-menu-right" style=""><a class="dropdown-item edit_plan" href="javascript:void(0)" data-id="'+response.plans[key].id+'">Edit</a></div></div></div>';
	                	}

	                	html += '<tr>';
                            html += '<td>'+radio+'</td>';
                            html += '<td>'+response.plans[key].id+'</td>';
                            html += '<td>'+response.plans[key].name+'</td>';
                            html += '<td>'+response.plans[key].description+'</td>';
                            html += '<td>'+response.plans[key].status_label+'</td>';
                            html += '<td>'+kebab+'</td>';
                        html += '</tr>';
	                }
	            }
	            $('#plan_data_container').html(html);
	            if (!$.fn.DataTable.isDataTable("#plan-datatable")) {
					$("#plan-datatable").DataTable({
				        responsive: true
				    });
				}
            }
        }
    });
}

window.savePlanData = function(type) {
	if($planForm.valid()){
		$('.save_plan_data').attr('disabled',true);

		var user_id = user.user_details.user_id;
		var name = $('#plan_name').val();
		var description = $('#plan_description').val();
		
		var url;
		if(type == 'add') {
			var status = 1;
			var url = api_url+"plans/add";
		} else if(type == 'edit') {
			var plan_id = $('#selected_plan_id').val();
			var status = $('#plan_status').val();
			var url = api_url+"plans/edit/"+plan_id;
		}

		$.ajax({
            method: 'POST',
            url: url,
            data: 'user_id='+user_id+'&name='+name+'&description='+description+"&status="+status,
            beforeSend: function(xhr) {
		        xhr.setRequestHeader('Authorization', accessToken);
		    },
            success: function(response) {
                display_error_message(response);
                $('.save_plan_data').attr('disabled',false);
                if(response.response_data.success == true) {
                	$('#planAddModal').modal('hide');
                	$('#planEditModal').modal('hide');
                	$('#plan_pricing_data_section').addClass('d-none');
                	loadPlanData();
                }
            }
        });
    }
}

/* Plan Pricing Data */

var $planPricingForm;
window.addPlanPricingValidation = function() {
	$planPricingForm = $("#plan_pricing_add_form, #plan_pricing_edit_form");
	$planPricingForm.validate({
	    errorPlacement: function errorPlacement(error, element) {
	        $(element).parents(".form-group").append(error.addClass("invalid-feedback small d-block"))
	    },
	    highlight: function(element) {
	        $(element).addClass("is-invalid");
	    },
	    unhighlight: function(element) {
	        $(element).removeClass("is-invalid");
	    },
	    rules: {
	        "property_setup_fee": { required: true },
	        "price_per_month": { required: true },
	        "valid_from_date": { required: true },
	        "valid_to_date": { required: true }
	    },
	    messages: {
	        "property_setup_fee": { required: '' },
	        "price_per_month": { required: '' },
	        "valid_from_date": { required: '' },
	        "valid_to_date": { required: '' }
	    }
	});
}

window.getPlanPricingDetailById = function(plan_pricing_id) {
	var tz = jstz.determine();
	var timezone = tz.name();
	$.ajax({
        method: 'POST',
        url: api_url+"plan-pricings/view/"+plan_pricing_id,
        data: 'user_timezone='+timezone,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	display_error_message(response);
        	if(response.response_data.success == true) {
        		$('#property_setup_fee').val(response.plan_pricing.property_setup_fee);
        		$('#price_per_month').val(response.plan_pricing.price_per_month);
        		$('#valid_from_date > input').val(response.plan_pricing.valid_from_date2);
        		$('#valid_to_date > input').val(response.plan_pricing.valid_to_date2);

        		var status = response.plan_pricing.status;
        		var html = '';
        		$.each( response.status, function( key, value ) {
        			var selected = '';
        			if(key == status) {
        				selected = 'selected';
        			}
				  	html += '<option value="'+key+'" '+selected+'>'+value+'</option>';
				});
		        $('#plan_pricing_status').html(html);
            }
        }
    });
}

window.loadPlanPricingData = function(plan_id) {
	$('#plan_pricing_data_section').removeClass('d-none');
	var tz = jstz.determine();
	var timezone = tz.name();
	$.ajax({
        method: 'POST',
        url: api_url+"plans/view",
        data: "plan_id="+plan_id+"&user_timezone="+timezone,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	display_error_message(response);
        	if(response.response_data.success == true) {
        		var html = '';
    	    	for (var key in response.plan.plan_pricings) {
            		if (response.plan.plan_pricings.hasOwnProperty(key)) {
	                	var kebab = '<div class="text-center"><div class="input-group-prepend"><i class="align-middle mr-2 fas fa-fw fa-ellipsis-v" data-toggle="dropdown" aria-expanded="false"></i><div class="dropdown-menu dropdown-menu-right" style=""><a class="dropdown-item edit_plan_pricing" href="javascript:void(0)" data-id="'+response.plan.plan_pricings[key].id+'">Edit</a></div></div></div>';

	                	html += '<tr>';
                            html += '<td>'+response.plan.plan_pricings[key].id+'</td>';
                            html += '<td>'+response.plan.name+'</td>';
                            html += '<td>'+response.plan.plan_pricings[key].property_setup_fee+'</td>';
                           	html += '<td>'+response.plan.plan_pricings[key].unit_price+'</td>';
                            html += '<td>'+response.plan.plan_pricings[key].price_per_month+'</td>';
                            html += '<td>'+response.plan.plan_pricings[key].price_per_year+'</td>';
                            html += '<td>'+response.plan.plan_pricings[key].valid_from_date+'</td>';
                            html += '<td>'+response.plan.plan_pricings[key].valid_to_date+'</td>';
                            html += '<td>'+response.plan.plan_pricings[key].status_label+'</td>';
                            //html += '<td>'+response.plan.plan_pricings[key].created+'</td>';
                            //html += '<td>'+response.plan.plan_pricings[key].modified+'</td>';
                            html += '<td>'+kebab+'</td>';
                        html += '</tr>';
                    }
                }
	            $('#plan_pricing_data_container').html(html);
	            if (!$.fn.DataTable.isDataTable("#plan-pricing-datatable")) {
					$("#plan-pricing-datatable").DataTable({
				        responsive: true
				    });
				}
            }
        }
    });
}

window.savePlanPricingData = function(type) {
	if($planPricingForm.valid()){
		$('.save_plan_pricing_data').attr('disabled',true);

		var user_id = user.user_details.user_id;
		var plan_id = $('input[name="plan-radio"]:checked').attr('data-id');
		var property_setup_fee = $('#property_setup_fee').val();
		var price_per_month = $('#price_per_month').val();
		var valid_from_date = $('#valid_from_date > input').val();
		var valid_to_date = $('#valid_to_date > input').val();
		var uom = 1;
		var unit_price = $('#price_per_month').val();
		var price_per_year = $('#price_per_month').val() * 12;
		
		var url;
		if(type == 'add') {
			var status = 1;
			var url = api_url+"plan-pricings/add";
		} else if(type == 'edit') {
			var plan_pricing_id = $('#selected_plan_pricing_id').val();
			var status = $('#plan_pricing_status').val();
			var url = api_url+"plan-pricings/edit/"+plan_pricing_id;
		}

		$.ajax({
            method: 'POST',
            url: url,
            data: 'user_id='+user_id+'&plan_id='+plan_id+'&property_setup_fee='+property_setup_fee+"&price_per_month="+price_per_month+'&valid_from_date='+valid_from_date+'&valid_to_date='+valid_to_date+'&uom='+uom+'&unit_price='+unit_price+'&price_per_year='+price_per_year,
            beforeSend: function(xhr) {
		        xhr.setRequestHeader('Authorization', accessToken);
		    },
            success: function(response) {
                display_error_message(response);
                $('.save_plan_pricing_data').attr('disabled',false);
                if(response.response_data.success == true) {
                	$('#planPricingAddModal').modal('hide');
                	$('#planPricingEditModal').modal('hide');
                	loadPlanPricingData(plan_id);
                }
            }
        });
    }
}

/* Subscriptions Data */

window.loadSubscriptionData = function() {
	var tz = jstz.determine();
	var timezone = tz.name();
	$.ajax({
        method: 'POST',
        url: api_url+"subscription-plans",
        data:'user_timezone='+timezone,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	display_error_message(response);
        	if(response.response_data.success == true) {
        		var html = '';
            	for (var key in response.subscriptionPlans) {
	                if (response.subscriptionPlans.hasOwnProperty(key)) {

	                	var kebab = '';
	                	/*var kebab = '<div class="text-center"><div class="input-group-prepend"><i class="align-middle mr-2 fas fa-fw fa-ellipsis-v" data-toggle="dropdown" aria-expanded="false"></i><div class="dropdown-menu dropdown-menu-right" style=""><a class="dropdown-item edit_plan" href="javascript:void(0)" data-id="'+response.plans[key].id+'">Edit</a></div></div></div>';*/

	                	var username = '-';
	                	if(response.subscriptionPlans[key].user != null) {
	                		username = response.subscriptionPlans[key].user.first_name+' '+response.subscriptionPlans[key].user.last_name;
	                	}

	                	var property = '-';
	                	if(response.subscriptionPlans[key].property != null) {
	                		property = response.subscriptionPlans[key].property.name;
	                	}

	                	var plan = '-';
	                	if(response.subscriptionPlans[key].plan != null) {
	                		plan = response.subscriptionPlans[key].plan.name;
	                	}

	                	var coupon = '-';
	                	if(response.subscriptionPlans[key].coupon != null) {
	                		coupon = response.subscriptionPlans[key].coupon_type_label+'<br>'+response.subscriptionPlans[key].coupon.code;
	                	}

	                	var date_cancelled = '-';
	                	if(response.subscriptionPlans[key].date_cancelled != null) {
	                		date_cancelled = response.subscriptionPlans[key].date_cancelled;
	                	}

	                	html += '<tr>';
                            html += '<td>'+response.subscriptionPlans[key].id+'</td>';
                            html += '<td>'+username+'</td>';
                            html += '<td>'+property+'</td>';
                            html += '<td>'+plan+'</td>';
                            html += '<td>'+coupon+'</td>';
                            html += '<td>'+response.subscriptionPlans[key].start_date+'</td>';
                            html += '<td>'+response.subscriptionPlans[key].end_date+'</td>';
                            html += '<td>'+response.subscriptionPlans[key].subscription_duration_label+'</td>';
                            html += '<td>'+response.subscriptionPlans[key].date_subscribed+'</td>';
                            html += '<td>'+date_cancelled+'</td>';
                            html += '<td>'+response.subscriptionPlans[key].status_label+'</td>';
                            html += '<td>'+kebab+'</td>';
                        html += '</tr>';
	                }
	            }
	            $('#subscription_plan_data_container').html(html);
	            if (!$.fn.DataTable.isDataTable("#subscription-plan-datatable")) {
					$("#subscription-plan-datatable").DataTable({
				        responsive: true
				    });
				}
            }
        }
    });
}

/* Load Group Data */

var groupForm;
window.addGroupValidation = function() {
	groupForm = $("#group_add_form, #group_edit_form");
	groupForm.validate({
	    errorPlacement: function errorPlacement(error, element) {
	        $(element).parents(".form-group").append(error.addClass("invalid-feedback small d-block"))
	    },
	    highlight: function(element) {
	        $(element).addClass("is-invalid");
	    },
	    unhighlight: function(element) {
	        $(element).removeClass("is-invalid");
	    },
	    rules: {
	        "group_name": { required: true },
	        "group_owner": { required: true }
	    },
	    messages: {
	        "group_name": { required: '' },
	        "group_owner": { required: '' }
	    }
	});
}

window.getGroupDetailById = function(group_id) {
	var tz = jstz.determine();
	var timezone = tz.name();
	$.ajax({
        method: 'POST',
        url: api_url+"rsgroups/view/"+group_id,
        data: 'user_timezone='+timezone,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	display_error_message(response);
        	if(response.response_data.success == true) {
        		$('#group_name').val(response.group.name);
        		$('#group_description').val(response.group.description);
        		setTimeout(function () {
		            $('#group_owner').val(response.group.user_id);
		            $(".select2").each(function() {
					    $(this).select2();
					});
		        }, 1000);
            }
        }
    });
}

window.loadGroupData = function() {
	//getFloatingActionButton('Coupon', '#coupon_data_section');
	var tz = jstz.determine();
	var timezone = tz.name();
	$.ajax({
        method: 'GET',
        url: api_url+"rsgroups",
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	display_error_message(response);
        	if(response.response_data.success == true) {
        		var html = '';
            	for (var key in response.groups) {
	                if (response.groups.hasOwnProperty(key)) {

	                	var kebab = '<div class="text-center"><div class="input-group-prepend"><i class="align-middle mr-2 fas fa-fw fa-ellipsis-v" data-toggle="dropdown" aria-expanded="false"></i><div class="dropdown-menu dropdown-menu-right" style=""><a class="dropdown-item edit_group" href="javascript:void(0)" data-id="'+response.groups[key].id+'">Edit</a><a data-id="'+response.groups[key].id+'" data-module="Group" class="dropdown-item delete_group" href="javascript:void(0)">Delete</a></div></div></div>';

	                	var owner = response.groups[key].user.first_name+' '+response.groups[key].user.last_name;

	                	html += '<tr>';
                            html += '<td>'+response.groups[key].id+'</td>';
                            html += '<td>'+response.groups[key].name+'</td>';
                            html += '<td>'+response.groups[key].description+'</td>';
                            html += '<td>'+owner+'</td>';
                            html += '<td>'+response.groups[key].status_label+'</td>';
                            //html += '<td>'+response.groups[key].created+'</td>';
                            //html += '<td>'+response.groups[key].modified+'</td>';
                            html += '<td>'+kebab+'</td>';
                        html += '</tr>';
	                }
	            }
	            $('#group_data_container').html(html);
	            if (!$.fn.DataTable.isDataTable("#group-datatable")) {
					$("#group-datatable").DataTable({
				        responsive: true
				    });
				}
            }
        }
    });
}

window.saveGroupData = function(type) {
	if(groupForm.valid()){
		$('.save_group_data').attr('disabled',true);

		var user_id = user.user_details.user_id;
		var group_name = $('#group_name').val();
		var group_owner = $('#group_owner').val();
		var group_description = $('#group_description').val();
		
		var url;
		if(type == 'add') {
			var group_id = 0;
			var url = api_url+"rsgroups/add";
		} else if(type == 'edit') {
			var group_id = $('#selected_group_id').val();
			var url = api_url+"rsgroups/edit";
		}

		$.ajax({
            method: 'POST',
            url: url,
            data: 'user_id='+user_id+'&id='+group_id+'&group_name='+group_name+'&group_owner='+group_owner+'&group_description='+group_description,
            beforeSend: function(xhr) {
		        xhr.setRequestHeader('Authorization', accessToken);
		    },
            success: function(response) {
                display_error_message(response);
                $('.save_group_data').attr('disabled',false);
                if(response.response_data.success == true) {
                	$('#groupAddModal').modal('hide');
                	$('#groupEditModal').modal('hide');
                	loadGroupData();
                }
            }
        });
    }
}

window.deleteGroup = function(group_id) {
	var user_id = user.user_details.user_id;
	$.ajax({
        method: 'POST',
        url: api_url+"rsgroups/delete",
        data:'id='+group_id,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
            display_error_message(response);
            if(response.response_data.success == true) {
                loadGroupData();
            }
        }
    });
}

/* Features code start */

var $featureForm;
window.addFeatureValidation = function() {
	$featureForm = $("#feature_add_form, #feature_edit_form");
	$featureForm.validate({
	    errorPlacement: function errorPlacement(error, element) {
	        $(element).parents(".form-group").append(error.addClass("invalid-feedback small d-block"))
	    },
	    highlight: function(element) {
	        $(element).addClass("is-invalid");
	    },
	    unhighlight: function(element) {
	        $(element).removeClass("is-invalid");
	    },
	    rules: {
	        "feature_name": { required: true },
	        "feature_action": { required: true }
	    },
	    messages: {
	        "feature_name": { required: '' },
	        "feature_action": { required: '' }
	    }
	});
}

window.loadFeaturesData = function() {
	var tz = jstz.determine();
	var timezone = tz.name();
	$.ajax({
        method: 'GET',
        url: api_url+"features",
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	display_error_message(response);
        	if(response.response_data.success == true) {
        		var html = '';
            	for (var key in response.features) {
	                if (response.features.hasOwnProperty(key)) {

	                	var radio = '<label class="custom-control custom-radio"><input name="feature-radio" type="radio" class="custom-control-input get_feature_item" data-id="'+response.features[key].id+'"><span class="custom-control-label">&nbsp;</span></label>';

	                	var kebab = '<a class="dropdown-item edit_feature" href="javascript:void(0)" data-id="'+response.features[key].id+'">Edit</a>';
	                	/*if(response.features[key].status != 1) {
	                		kebab += '<a data-id="'+response.features[key].id+'" data-module="Service" class="dropdown-item delete_service" href="javascript:void(0)">Delete</a>';
	                	}*/
	                	
	                	html += '<tr>';
                            html += '<td>'+radio+'</td>';
                            html += '<td>'+response.features[key].id+'</td>';
                            html += '<td>'+response.features[key].name+'</td>';
                            html += '<td>'+response.features[key].action+'</td>';
                            html += '<td>'+response.features[key].icon+'</td>';
                            html += '<td>'+response.features[key].status_label+'</td>';
                            html += '<td><div class="text-center"><div class="input-group-prepend"><i class="align-middle mr-2 fas fa-fw fa-ellipsis-v" data-toggle="dropdown" aria-expanded="false"></i><div class="dropdown-menu dropdown-menu-right" style="">'+kebab+'</div></div></div></td>';
                        html += '</tr>';
	                }
	            }
	            $('#feature_data_container').html(html);
	            if (!$.fn.DataTable.isDataTable("#feature-datatable")) {
					$("#feature-datatable").DataTable({
				        responsive: true
				    });
				}
            }
        }
    });
}

window.getFeatureDetailById = function(feature_id) {
	var tz = jstz.determine();
	var timezone = tz.name();
	$.ajax({
        method: 'GET',
        url: api_url+"features/view/"+feature_id,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	display_error_message(response);
        	if(response.response_data.success == true) {
        		$('#feature_name').val(response.feature.name);
        		$('#feature_action').val(response.feature.action);
        		$('#feature_icon').val(response.feature.icon);
        		$('#feature_status_dd').val(response.feature.status);
            }
        }
    });
}

window.saveFeatureData = function(type) {
	if($featureForm.valid()){
		$('.save_feature_data').attr('disabled',true);

		var user_id = user.user_details.user_id;
		var name = $('#feature_name').val();
		var action = $('#feature_action').val();
		var icon = $('#feature_icon').val();
		var status = $('#feature_status_dd').val();
		
		var url;
		if(type == 'add') {
			var feature_id = 0;
			var url = api_url+"features/add";
		} else if(type == 'edit') {
			var feature_id = $('#selected_feature_id').val();
			var url = api_url+"features/edit";
		}

		$.ajax({
            method: 'POST',
            url: url,
            data: 'user_id='+user_id+'&id='+feature_id+'&name='+name+'&action='+action+'&icon='+icon+'&status='+status,
            beforeSend: function(xhr) {
		        xhr.setRequestHeader('Authorization', accessToken);
		    },
            success: function(response) {
                display_error_message(response);
                $('.save_feature_data').attr('disabled',false);
                if(response.response_data.success == true) {
                	$('#featureAddModal').modal('hide');
                	$('#featureEditModal').modal('hide');
                	$('#feature_item_data_container').html('');
                	loadFeaturesData();
                }
            }
        });
    }
}

/* Feature items code start here */

var $featureItemForm;
window.addFeatureItemValidation = function() {
	$featureItemForm = $("#feature_item_add_form, #feature_item_edit_form");
	$featureItemForm.validate({
	    errorPlacement: function errorPlacement(error, element) {
	        $(element).parents(".form-group").append(error.addClass("invalid-feedback small d-block"))
	    },
	    highlight: function(element) {
	        $(element).addClass("is-invalid");
	    },
	    unhighlight: function(element) {
	        $(element).removeClass("is-invalid");
	    },
	    rules: {
	        "feature_item_display_name": { required: true },
	        "feature_item_action": { required: true }
	    },
	    messages: {
	        "feature_item_display_name": { required: '' },
	        "feature_item_action": { required: '' }
	    }
	});
}

window.loadFeatureItemData = function(feature_id) {
	$('#feature_item_data_section').removeClass('d-none');
	var tz = jstz.determine();
	var timezone = tz.name();
	$.ajax({
        method: 'POST',
        url: api_url+"feature-items/get-feature-items",
        data: "feature_id="+feature_id+"&user_timezone="+timezone,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	display_error_message(response);
        	if(response.response_data.success == true) {
        		var html = '';
    	    	for (var key in response.feature_items) {
            		if (response.feature_items.hasOwnProperty(key)) {
            			var kebab = '<a data-id="'+response.feature_items[key].id+'" data-module="FeatureItems" class="dropdown-item edit_feature_item" href="javascript:void(0)">Edit</a>';
	                	html += '<tr>';
                            html += '<td>'+response.feature_items[key].id+'</td>';
                            html += '<td>'+response.feature_items[key].display_name+'</td>';
                            html += '<td>'+response.feature_items[key].action+'</td>';
                            html += '<td>'+response.feature_items[key].status_label+'</td>';
                            html += '<td><div class="text-center"><div class="input-group-prepend"><i class="align-middle mr-2 fas fa-fw fa-ellipsis-v" data-toggle="dropdown" aria-expanded="false"></i><div class="dropdown-menu dropdown-menu-right" style="">'+kebab+'</div></div></div></td>';
                        html += '</tr>';
                    }
                }
	            $('#feature_item_data_container').html(html);
	            if (!$.fn.DataTable.isDataTable("#feature-item-datatable")) {
					$("#feature-item-datatable").DataTable({
				        responsive: true
				    });
				}
            }
        }
    });
}

window.getFeatureItemDetailById = function(feature_item_id) {
	var tz = jstz.determine();
	var timezone = tz.name();
	$.ajax({
        method: 'GET',
        url: api_url+"feature-items/view/"+feature_item_id,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	display_error_message(response);
        	if(response.response_data.success == true) {
        		$('#feature_item_display_name').val(response.feature_item.display_name);
        		$('#feature_item_action').val(response.feature_item.action);
        		$('#feature_item_status_dd').val(response.feature_item.status);
            }
        }
    });
}

window.saveFeatureItemData = function(type) {
	if($featureItemForm.valid()){
		$('.save_feature_item_data').attr('disabled',true);

		var user_id = user.user_details.user_id;
		var display_name = $('#feature_item_display_name').val();
		var action = $('#feature_item_action').val();
		var status = $('#feature_item_status_dd').val();

		var feature_id = $("input[name='feature-radio']:checked").attr('data-id');
		
		var url;
		if(type == 'add') {
			var feature_item_id = 0;
			var url = api_url+"feature-items/add";
		} else if(type == 'edit') {
			var feature_item_id = $('#selected_feature_item_id').val();
			var url = api_url+"feature-items/edit";
		}

		$.ajax({
            method: 'POST',
            url: url,
            data: 'user_id='+user_id+'&id='+feature_item_id+'&feature_id='+feature_id+'&display_name='+display_name+'&action='+action+'&status='+status,
            beforeSend: function(xhr) {
		        xhr.setRequestHeader('Authorization', accessToken);
		    },
            success: function(response) {
                display_error_message(response);
                $('.save_feature_item_data').attr('disabled',false);
                if(response.response_data.success == true) {
                	$('#featureItemAddModal').modal('hide');
                	$('#featureItemEditModal').modal('hide');
                	loadFeatureItemData(feature_id);
                }
            }
        });
    }
}

/* Services code start */

var $serviceForm;
window.addServiceValidation = function() {
	$serviceForm = $("#service_add_form, #service_edit_form");
	$serviceForm.validate({
	    errorPlacement: function errorPlacement(error, element) {
	        $(element).parents(".form-group").append(error.addClass("invalid-feedback small d-block"))
	    },
	    highlight: function(element) {
	        $(element).addClass("is-invalid");
	    },
	    unhighlight: function(element) {
	        $(element).removeClass("is-invalid");
	    },
	    rules: {
	        "service_name": { required: true }
	    },
	    messages: {
	        "service_name": { required: '' }
	    }
	});
}

window.getServiceDetailById = function(service_id) {
	var tz = jstz.determine();
	var timezone = tz.name();
	$.ajax({
        method: 'POST',
        url: api_url+"services/view/"+service_id,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	display_error_message(response);
        	if(response.response_data.success == true) {
        		$('#service_name').val(response.service.service_name);
        		$('#service_description').val(response.service.description);
        		$('#service_status_dd').val(response.service.status);
        		var description = response.service.description;
        		if(description != null){
        			service_description_editor.value(description);
        		}
            }
        }
    });
}

window.loadServicesData = function() {
	var tz = jstz.determine();
	var timezone = tz.name();
	$.ajax({
        method: 'GET',
        url: api_url+"services",
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	display_error_message(response);
        	if(response.response_data.success == true) {
        		var html = '';
            	for (var key in response.services) {
	                if (response.services.hasOwnProperty(key)) {

	                	var radio = '<label class="custom-control custom-radio"><input name="service-radio" type="radio" class="custom-control-input get_service_member" data-id="'+response.services[key].id+'"><span class="custom-control-label">&nbsp;</span></label>';

	                	var kebab = '<a class="dropdown-item edit_service" href="javascript:void(0)" data-id="'+response.services[key].id+'">Edit</a>';
	                	/*if(response.services[key].status != 1) {
	                		kebab += '<a data-id="'+response.services[key].id+'" data-module="Service" class="dropdown-item delete_service" href="javascript:void(0)">Delete</a>';
	                	}*/
	                	var description = '';
	                	if(response.services[key].description != null) {
	                		description = response.services[key].description;
	                	}
	                	html += '<tr>';
                            html += '<td>'+radio+'</td>';
                            html += '<td>'+response.services[key].id+'</td>';
                            html += '<td>'+response.services[key].service_name+'</td>';
                            html += '<td>'+description+'</td>';
                            html += '<td>'+response.services[key].status_label+'</td>';
                            html += '<td><div class="text-center"><div class="input-group-prepend"><i class="align-middle mr-2 fas fa-fw fa-ellipsis-v" data-toggle="dropdown" aria-expanded="false"></i><div class="dropdown-menu dropdown-menu-right" style="">'+kebab+'</div></div></div></td>';
                        html += '</tr>';
	                }
	            }
	            console.log(html);
	            $('#service_data_container').html(html);
	            if (!$.fn.DataTable.isDataTable("#service-datatable")) {
					$("#service-datatable").DataTable({
				        responsive: true
				    });
				}
            }
        }
    });
}

window.saveServiceData = function(type) {
	if($serviceForm.valid()){
		$('.save_service_data').attr('disabled',true);

		// Check validation for comment area
		if($.trim(service_description_editor.value()) == "") {
			$('.save_service_data').attr('disabled', false);
            var response = {
                "response_data": {
                	"success": false,
                  	"message": 'Description should not be blank.'
                }
            };
            $('.save_service_data').attr('disabled', false);
            display_error_message(response);
            return false;
        }

        // Parse markdown to html
        var formatted_markdown_description = parse_markdown_to_html(service_description_editor.value());

		var user_id = user.user_details.user_id;
		var service_name = $('#service_name').val();
		var description = service_description_editor.value();
		var status = $('#service_status_dd').val();
		
		var url;
		if(type == 'add') {
			var service_id = 0;
			var url = api_url+"services/add";
		} else if(type == 'edit') {
			var service_id = $('#selected_service_id').val();
			var url = api_url+"services/edit";
		}

		$.ajax({
            method: 'POST',
            url: url,
            data: 'user_id='+user_id+'&id='+service_id+'&service_name='+service_name+'&description='+btoa(unescape(encodeURIComponent(description)))+'&status='+status+'&formatted_markdown_description='+formatted_markdown_description,
            beforeSend: function(xhr) {
		        xhr.setRequestHeader('Authorization', accessToken);
		    },
            success: function(response) {
                display_error_message(response);
                $('.save_service_data').attr('disabled',false);
                if(response.response_data.success == true) {
                	$('#serviceAddModal').modal('hide');
                	$('#serviceEditModal').modal('hide');
                	$('#service_member_data_section').html('');
                	loadServicesData();
                }
            }
        });
    }
}

window.deleteService = function(service_id) {
	var user_id = user.user_details.user_id;
	$.ajax({
        method: 'POST',
        url: api_url+"services/delete",
        data:'id='+service_id+'&user_id='+user_id,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
            display_error_message(response);
            if(response.response_data.success == true) {
                loadServicesData();
                $('#service_member_data_section').addClass('d-none');
                $('#service_member_data_container').html('');
            }
        }
    });
}

/* Get Service Member */

window.loadServiceMemberData = function(service_id) {
	$('#service_member_data_section').removeClass('d-none');
	var tz = jstz.determine();
	var timezone = tz.name();
	$.ajax({
        method: 'POST',
        url: api_url+"user-services/get-service-members",
        data: "service_id="+service_id+"&user_timezone="+timezone,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	display_error_message(response);
        	if(response.response_data.success == true) {
        		var html = '';
    	    	for (var key in response.user_services) {
            		if (response.user_services.hasOwnProperty(key)) {
            			var kebab = '<a data-id="'+response.user_services[key].id+'" data-module="ServiceMember" class="dropdown-item delete_user_service" href="javascript:void(0)">Delete</a>';
	                	html += '<tr>';
                            html += '<td>'+response.user_services[key].id+'</td>';
                            html += '<td>'+response.user_services[key].user.first_name+'</td>';
                            html += '<td>'+response.user_services[key].user.last_name+'</td>';
                           	html += '<td>'+response.user_services[key].user.email+'</td>';
                            html += '<td>'+response.user_services[key].user.phone+'</td>';
                            html += '<td>'+response.user_services[key].status_label+'</td>';
                            html += '<td><div class="text-center"><div class="input-group-prepend"><i class="align-middle mr-2 fas fa-fw fa-ellipsis-v" data-toggle="dropdown" aria-expanded="false"></i><div class="dropdown-menu dropdown-menu-right" style="">'+kebab+'</div></div></div></td>';
                        html += '</tr>';
                    }
                }
	            $('#service_member_data_container').html(html);
	            if (!$.fn.DataTable.isDataTable("#service-member-datatable")) {
					$("#service-member-datatable").DataTable({
				        responsive: true
				    });
				}
            }
        }
    });
}

window.getUserList = function() {
	$.ajax({
	    method: 'GET',
	    url: api_url+"users/list",
	    beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
	    success: function(response) {
	    	if(response.response_data.success == true) {
	    		var html = "";
		        html += '<select name="service_members" class="form-control service_member_dd mt-3" data-toggle="select2" multiple>';
	            	for (var key in response.users) {
		                if (response.users.hasOwnProperty(key)) {
		                	var name = response.users[key].first_name+' '+response.users[key].last_name;
			                var email = response.users[key].email;
	                		html += '<option data-user-id="'+response.users[key].id+'" value="'+response.users[key].id+'">'+name+'</option>';
						}
					}
				html += '</select>';
				$('#service_member_dd_container').html(html);

				$(".service_member_dd").each(function() {
				    $(this)
				        .wrap("<div class=\"position-relative\"></div>")
				        .select2({
				            placeholder: "Select User",
				            dropdownParent: $(this).parent()
				        });
				});
		    } else {
		        display_error_message(response);
		    }
	    }
	});
}

window.saveServiceMemberData = function() {

	var service_member_id_arr = $('.service_member_dd').val();
	var service_member_ids = $('.service_member_dd').find(':selected').map(function() { 
	    return $(this).attr("data-user-id"); 
	}).get().join(',');

	$('.save_service_member_data').attr('disabled',true);

	// Check validation for comment area
	if($.trim(service_member_ids) == "") {
		$('.save_service_member_data').attr('disabled', false);
        var response = {
            "response_data": {
            	"success": false,
              	"message": 'Please select user.'
            }
        };
        $('.save_service_member_data').attr('disabled', false);
        display_error_message(response);
        return false;
    }

    var user_id = user.user_details.user_id;
    var service_id = $("input[name='service-radio']:checked").attr('data-id');
	
	var status = 1;
	var url = api_url+"user-services/add";
	
	$.ajax({
        method: 'POST',
        url: url,
        data: 'user_id='+user_id+'&service_id='+service_id+'&service_member_ids='+service_member_ids+'&status='+status,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
            display_error_message(response);
            $('.save_service_member_data').attr('disabled',false);
            if(response.response_data.success == true) {
            	$('#serviceMemberAddModal').modal('hide');
            	loadServiceMemberData(service_id);
            }
        }
    });
}

window.deleteUserService = function(user_service_id) {
	var user_id = user.user_details.user_id;
	$.ajax({
        method: 'POST',
        url: api_url+"user-services/delete",
        data:'id='+user_service_id+'&user_id='+user_id,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
            display_error_message(response);
            if(response.response_data.success == true) {
            	var service_id = $("input[name='service-radio']:checked").attr('data-id');
                loadServiceMemberData(service_id);
            }
        }
    });
}

/* Get Feature permissions data */

window.loadFeaturePermissionsData = function() {
	var tz = jstz.determine();
	var timezone = tz.name();
	
	$.ajax({
	    method: 'POST',
	    url: api_url+"features-permissions/list-permissions",
	    beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
	    success: function(response) {
	    	display_error_message(response);
	    	if(response.response_data.success == true) {
	    		var html = '';
	    		html += '<div class="card">';
				    html += '<table class="table">';
				        html += '<thead>';
				            html += '<tr>';
				                html += '<th>&nbsp;</th>';
				                for (var i=1; i<=Object.keys(response.services).length; i++) {
				        			html += '<th class="fs-16 bold-black text-center p_service_name" data-service-id="'+i+'">'+response.services[i]+'</th>';
								}
								html += '</tr>';
						html += '</thead>';
						html += '<tbody class="service_permissions_table">';
		            	for (var fkey in response.feature_permissions) {
			                if (response.feature_permissions.hasOwnProperty(fkey)) {
				        		html += '<tr class="bg-light">';
					                html += '<td class="fs-16 bold-black p_feature_name" data-feature-id="'+fkey+'">'+response.feature_permissions[fkey].name+'</td>';
					                html += '<td>&nbsp;</td>';
					                html += '<td>&nbsp;</td>';
					                html += '<td>&nbsp;</td>';
					                html += '<td>&nbsp;</td>';
					            html += '</tr>';
						        for (var fikey in response.feature_permissions[fkey].feature_items) {
	                                if (response.feature_permissions[fkey].feature_items.hasOwnProperty(fikey)) {
	                                	html += '<tr>';
							                html += '<td>'+response.feature_permissions[fkey].feature_items[fikey].action+'</td>';
							                for (var skey in response.feature_permissions[fkey].feature_items[fikey].services) {
	                                			if (response.feature_permissions[fkey].feature_items[fikey].services.hasOwnProperty(skey)) {
	                                				if(response.feature_permissions[fkey].feature_items[fikey].services[skey] == 1) {
	                                					var checked= 'checked';
	                                				} else {
	                                					var checked= '';
	                                				}

	                                				if(skey == 1) {
	                                					var add_opacity = 'add_opacity';
	                                				} else {
	                                					var add_opacity = '';
	                                				}

							                		html += '<td class="text-center '+add_opacity+'">';
									                    html += '<label class="custom-control custom-checkbox">';
									                        html += '<input name="" type="checkbox" class="custom-control-input permission_checkbox" data-service-id="'+skey+'" data-feature-id="'+fkey+'" data-feature-item-id="'+fikey+'" '+checked+'>';
									                        html += '<span class="custom-control-label">&nbsp;</span>';
									                    html += '</label>';
									                html += '</td>';
												}
											}
							            html += '</tr>';
						        	}
						    	}
			                }
			            }
			            html += '</tbody>';
			        html += '</table>';
				html += '</div>';
				$('#feature_item_data').html(html);
	        }
	    }
	});
}

window.saveFeaturePermissionData = function(permissionArray) {
	var user_id = user.user_details.user_id;
	$.ajax({
        method: 'POST',
        url: api_url+"features-permissions/save-permission-data",
        data:'user_id='+user_id+'&permissionArray='+permissionArray,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	display_error_message(response);
        	if(response.response_data.success == true) {
        		loadFeaturePermissionsData();
        	}
        }
    });
}

/* UI API for Manage Zoho Template */

window.getZohoTemplates = function() {
	getFloatingActionButton('Template', '#zoho_template_container');
	if(user.permissionData['TEMPLATE']["feature_items['list']"]) {
		if ($.fn.DataTable.isDataTable("#zoho-template-datatable")) {
            $("#zoho-template-datatable").DataTable().destroy();
        }
		$.ajax({
	        method: 'POST',
	        url: api_url+"zoho-templates",
	        beforeSend: function(xhr) {
		        xhr.setRequestHeader('Authorization', accessToken);
		    },
	        success: function(data) {
	        	if(data.response_data.success == true) {
	        		var tempArray = data.tempArray;
		        	$.ajax({
				        method: 'GET',
				        url: api_url+"zoho-templates/list",
				        beforeSend: function(xhr) {
					        xhr.setRequestHeader('Authorization', accessToken);
					    },
				        success: function(response) {
				        	display_error_message(response);
				        	if(response.response_data.success == true) {
				        		var html = '';
				            	for (var key in response.templateList.templates) {
					                if (response.templateList.templates.hasOwnProperty(key)) {
					                	// Display only templates which is belongs to zoho_templates table
					                	if(tempArray.hasOwnProperty(response.templateList.templates[key].template_id)) {
						                	var owner = response.templateList.templates[key].owner_first_name+' '+response.templateList.templates[key].owner_last_name;
						                	var created_time = response.templateList.templates[key].created_time;
						                	created_time = new Date(created_time).toLocaleDateString("en-US");
											
						                	var kebab = '<div class="text-center"><div class="input-group-prepend"><i class="align-middle mr-2 fas fa-fw fa-ellipsis-v" data-toggle="dropdown" aria-expanded="false"></i><div class="dropdown-menu dropdown-menu-right" style="">';

						                	if(user.permissionData['TEMPLATE']["feature_items['edit']"]) {
						                		kebab += '<a class="dropdown-item edit_zoho_template" href="javascript:void(0)" data-template-id="'+response.templateList.templates[key].template_id+'">Edit</a>';
						                	}
						                	if(user.permissionData['TEMPLATE']["feature_items['delete']"]) {
						                		kebab += '<a class="dropdown-item delete_zoho_template" href="javascript:void(0)" data-template-id="'+response.templateList.templates[key].template_id+'">Delete</a>';
						                	}

						                	kebab += '</div></div></div>';


									        html += '<tr>';
					                            html += '<td>'+response.templateList.templates[key].template_name+'</td>';
					                            html += '<td>'+owner+'</td>';
					                            html += '<td>'+response.templateList.templates[key].is_sequential+'</td>';
					                            html += '<td>'+response.templateList.templates[key].expiration_days+'</td>';
					                            html += '<td>'+response.templateList.templates[key].reminder_period+'</td>';
					                            html += '<td>'+created_time+'</td>';
					                            html += '<td>'+kebab+'</td>';
					                        html += '</tr>';
					                    }
					                }
					            }
					            $('#zoho_data_container').html(html);

					            var column = [
						            { className: 'all', targets: [0], width:'40%', orderable: true},
						            { targets: [1], width:'15%', orderable: true},
						            { targets: [2], width:'10%', orderable: true},
						            { targets: [3], width:'10%', orderable: true},
						            { targets: [4], width:'10%', orderable: true},
						            { targets: [5], width:'10%', orderable: false},
						            { className: 'all', targets: [6], width:'5%', orderable: false}
						        ]; 

					            $("#zoho-template-datatable").DataTable({
								    responsive: true,
								    order: [[5,'desc']],
								    columnDefs: column,
								});
				            }
				        }
				    });
		        }
	        }
	    });
	}
}

window.getContractAndAgreements = function(open_for) {

	// Below condition will used when we display data for signature selection
	if(open_for == 'signature') {
		$('.save_template_data').attr('disabled', true);
		openLoadingDialog($('#template_list_container'));
	}

	getFloatingActionButton('Contracts', '#contracts_container');
	if(user.permissionData['TEMPLATE']["feature_items['contract_list']"]) {
		if ($.fn.DataTable.isDataTable("#contract-datatable")) {
            $("#contract-datatable").DataTable().destroy();
        }
		$.ajax({
	        method: 'GET',
	        url: api_url+"contracts",
	        beforeSend: function(xhr) {
		        xhr.setRequestHeader('Authorization', accessToken);
		    },
	        success: function(response) {
	        	if(response.response_data.success == true) {
	        		if(open_for == 'main_list') {
		        		var html = '';
		            	for (var key in response.contracts) {
			                if (response.contracts.hasOwnProperty(key)) {
			                	var kebab = '';
			                	kebab += '<div class="text-center"><div class="input-group-prepend"><i class="align-middle mr-2 fas fa-fw fa-ellipsis-v" data-toggle="dropdown" aria-expanded="false"></i><div class="dropdown-menu dropdown-menu-right" style=""><a class="dropdown-item edit_contract" href="javascript:void(0)" data-id="'+response.contracts[key].id+'" data-service-id="'+response.contracts[key].service_id+'">Edit</a></div></div></div>';

			                	html += '<tr>';
		                            html += '<td>'+response.contracts[key].id+'</td>';
		                            html += '<td>'+response.contracts[key].name+'</td>';
		                            html += '<td>'+response.contracts[key].service.service_name+'</td>';
		                            html += '<td>'+response.contracts[key].filename+'</td>';
		                            html += '<td>'+kebab+'</td>';
		                        html += '</tr>';
			                }
			            }
			            $('#contract_data_container').html(html);
						var contract_table = $("#contract-datatable").DataTable();
					} else if(open_for == 'signature') {
			        	var html = "";
			        	var dd_html = "";

			        	dd_html += '<div class="row pt-4 bg-white">';
				        	dd_html += '<div class="form-group col-md-12 col-xl-12">';
							    dd_html += '<div class="form-row">';
							        dd_html += '<div class="col-md-6">';
							            dd_html += '<div class="custom-controls-stacked">';
							                dd_html += '<label class="custom-control custom-radio">';
							                    dd_html += '<input name="property_user_type" type="radio" class="custom-control-input property_user_type" data-type="po" value="'+po_role+'">';
							                    dd_html += '<span class="custom-control-label">Property Owner</span>';
							                dd_html += '</label>';
							            dd_html += '</div>';
							        dd_html += '</div>';
							        dd_html += '<div class="col-md-6">';
							            dd_html += '<div class="custom-controls-stacked">';
							                dd_html += '<label class="custom-control custom-radio">';
							                    dd_html += '<input name="property_user_type" type="radio" class="custom-control-input property_user_type" data-type="t" value="'+t_role+'">';
							                    dd_html += '<span class="custom-control-label">Tenant</span>';
							                dd_html += '</label>';
							            dd_html += '</div>';
							        dd_html += '</div>';
							    dd_html += '</div>';
							dd_html += '</div>';
						dd_html += '</div>';

						html += dd_html;

			        	/* Document List */

			        	html += '<div class="row mt-2 bg-white">';
			        	html += '<h4 class="m-3 document_title d-none">Document List</h4>';
			            for (var key in response.contracts) {
				            if (response.contracts.hasOwnProperty(key)) {

				            	var user_class = '';
				            	if(response.contracts[key].service.constant_name == '1dc82947-e650-494a-b798-986a6f701d1f') { // PO
				            		user_class = 'po';
				            	} else if(response.contracts[key].service.constant_name == 'c963a422-edb4-4bad-97ed-276c5abb3b79') { // TENANT
				            		user_class = 't';
				            	}

				            	html += '<div class="col-auto col-md-12 col-xl-12 flex d-none account_type_'+user_class+'">';
				                    html += '<div class="flex-fill">';
				                        html += '<div class="mt-2 mb-2">';
				                            html += '<div class="black"><label class="custom-control custom-radio" style="word-break:break-all"><input name="document_name" type="radio" class="custom-control-input contract_template_radio" data-id="'+response.contracts[key].id+'"><span class="custom-control-label">'+response.contracts[key].name+'</span></label></div>';
				                        html += '</div>';
				                    html += '</div>';
				                html += '</div>';
						    }
						}
						html += '</div>';

						$('#template_list_container').html(html);
			        }
		        }
	        }
	    });
	}
}

window.saveContractData = function() {
	$('.submit_contract_detail').attr('disabled',true);

	var user_id = user.user_details.user_id;
	var contract_content = CKEDITOR.instances['contract_content'].getData();
	var name = $('#contract_name').val();
	var contract_id = $('#selected_contract_id').val();
	var filename = $('#contract_filename').val();
	var service_id = $('#contract_service_id').val();

	if(name == '' || name == null) {
		$('.submit_contract_detail').attr('disabled',false);
		var response = {
            "response_data": {
                "success": false,
                "message": 'Please enter contract name'
            }
        };
        display_error_message(response);
        return false;
	}

	if(contract_content == '' || contract_content == null) {
		$('.submit_contract_detail').attr('disabled',false);
		var response = {
            "response_data": {
                "success": false,
                "message": 'Please enter contract detail'
            }
        };
        display_error_message(response);
        return false;
	}

	if(contract_id > 0) {
		var url = api_url+"contracts/edit/"+contract_id;
	} else {
		var url = api_url+"contracts/add";
	}

	$.ajax({
        method: 'POST',
        url: url,
        data: "user_id="+user_id+"&name="+name+"&filename="+filename+"&service_id="+service_id+"&content="+encodeURIComponent(contract_content),
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
            display_error_message(response);
            $('.submit_contract_detail').attr('disabled',false);
            if(response.response_data.success == true) {
            	openLoadingDialog($('#main_container'));
				$('#main_container').load('components/template.html');
            }
        }
    });
}

window.getContractDetail = function(contract_id) {
	$.ajax({
        method: 'GET',
        url: api_url+"contracts/view/"+contract_id,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	display_error_message(response);
        	if(response.response_data.success == true) {
        		$('#selected_contract_id').val(contract_id);
        		$('#contract_name').val(response.contract.name);
        		$('#contract_filename').val(response.contract.filename);
				setTimeout(function() {
					CKEDITOR.instances['contract_content'].setData(response.contract.content);
				}, 1000);
            }
        }
    });
}

window.saveUserContract = function(user_type, property_id, contract_id) {
	var user_id = user.user_details.user_id;
	var data = 'user_id='+user_id+'&property_id='+property_id+'&user_type='+user_type+'&contract_id='+contract_id;
	var url = api_url+"user-contracts/add";
	$.ajax({
        method: 'POST',
        url: url,
        data: data,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(data) {
            display_error_message(data);
            if(data.response_data.success == true) {
        		$('#templateListModal').modal('hide');
        		getPropertyDocument();
            } else {
            	$('.save_template_data').attr('disabled',false);
            }
        }
    });
}

window.sendContractForReview = function(user_contract_id) {
	var user_id = user.user_details.user_id;
	var data = 'user_id='+user_id+'&user_contract_id='+user_contract_id;
	var url = api_url+"user-contracts/send-contract-for-review";
	$.ajax({
        method: 'POST',
        url: url,
        data: data,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(data) {
            display_error_message(data);
            if(data.response_data.success == true) {
        		getPropertyDocument();	
            } else {
            	$('.contract_send_for_review').removeClass('add_opacity');
            }
        }
    });
}

window.getPendingReviewContract = function() {
	$.ajax({
	    method: 'POST',
	    url: api_url+"user-contracts/get-pending-review-contract",
		data: 'user_id='+user.user_details.user_id,
	    beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
	    success: function(response) {
	        if(response.response_data.success == true) {
	        	var html = "";
		        if(response.userContractRecipients == null) {
		        	//html += '<a href="javascript:void(0)" class="list-group-item list-group-item-action border-0"><div class="fs-16">'+response.response_data.message+'</div></a>';
			    } else {
			    	$('#document_pending_review_section').removeClass('d-none');
			    	for (var key in response.userContractRecipients) {
			            if (response.userContractRecipients.hasOwnProperty(key)) {

			            	var name = stripHtml(response.userContractRecipients[key].property.name);
			            	var address = '';
			                if(response.userContractRecipients[key].property != null && response.userContractRecipients[key].property.address != null) {
			                	address = response.userContractRecipients[key].property.address.address_line1+', '+response.userContractRecipients[key].property.address.address_line2+'<br>'+response.userContractRecipients[key].property.address.city+', '+response.userContractRecipients[key].property.address.state+' '+response.userContractRecipients[key].property.address.zip_code;
			                }

			                /*html += '<div id="user_contract_'+response.userContractRecipients[key].user_contract_id+'" class="col-12 col-md-6 col-xl-3 document_review_card">';
					        	html += '<div class="card">';
									html += '<div class="card-header border-bottom"><h5 class="card-title capitalize mb-0 mr-3" title="'+response.userContractRecipients[key].contract.name+'">'+response.userContractRecipients[key].contract.name+'</h5></div>';
									html += '<div class="card-img text-center border-bottom pb-3"><img src="img/sample/pdf.png" width="100" height="100" alt="pdf" class="mb-2 mt-4"></div>';
									html += '<div class="card-body">';
										html += '<table class="table mb-0 card-table word-break">';
											html += '<tbody>';
												html += '<tr>';
													html += '<td class="nowrap">Property Name</td>';
													html += '<td class="text-right">'+name+'</td>';
												html += '</tr>';
												html += '<tr>';
													html += '<td class="nowrap">Address</td>';
													html += '<td class="text-right">'+address+'</td>';
												html += '</tr>';
											html += '</tbody>';
										html += '</table>';
										html += '<div class="mt-3 ml-2">Document <strong>'+response.userContractRecipients[key].contract.name+'</strong> available for your review. Click link below to open document</div>';
										html += '<div class="text-right mt-4">';
											html += '<button data-ticket-id='+response.userContractRecipients[key].user_contract.ticket_id+' data-property-id='+response.userContractRecipients[key].property_id+' data-user-contract-id='+response.userContractRecipients[key].user_contract_id+' data-user-contract-recipient-id='+response.userContractRecipients[key].id+' type="button" class="btn btn-orange review_contract_document">Review Document</button>';
										html += '</div>';
									html += '</div>';
									html += '</div>';
								html += '</div>';
							html += '</div>';*/

							notification_html += '<div id="user_contract_'+response.userContractRecipients[key].user_contract_id+'" class="list-group-item document_review_card">';
							    notification_html += '<div class="row no-gutters align-items-center">';
							        notification_html += '<div class="col-2"> <i class="text-danger" data-feather="credit-card"></i> </div>';
							        notification_html += '<div class="col-10">';
							            notification_html += '<div class="text-dark">'+response.userContractRecipients[key].contract.name+'</div>';
							            notification_html += '<div class="text-muted small mt-1">'+name+'</div>';
							            notification_html += '<div class="text-muted small mt-1">'+address+'</div>';
							            notification_html += '<div class="mt-3 ml-2">Document <strong>'+response.userContractRecipients[key].contract.name+'</strong> available for your review. Click link below to open document</div>';
							            notification_html += '<button data-ticket-id='+response.userContractRecipients[key].user_contract.ticket_id+' data-property-id='+response.userContractRecipients[key].property_id+' data-user-contract-id='+response.userContractRecipients[key].user_contract_id+' data-user-contract-recipient-id='+response.userContractRecipients[key].id+' type="button" class="btn btn-orange review_contract_document">Review Document</button>';
							        notification_html += '</div>';
							    notification_html += '</div>';
							notification_html += '</div>';
			            }
			        }
			    }
		        //$('#document_pending_review_section').html(html);
		        $('#document_pending_review_section').html(notification_html);
	        } else {
	        	display_error_message(response);
	        }
	    }
	});
}

window.getContractForReview = function(property_id,user_contract_id) {
	var user_id = user.user_details.user_id;
	var data = 'user_id='+user_id+'&property_id='+property_id+'&user_contract_id='+user_contract_id;
	var url = api_url+"user-contracts/get-contract-for-review";
	$.ajax({
        method: 'POST',
        url: url,
        data: data,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
            display_error_message(response);
            if(response.response_data.success == true) {
        		$('#document_url').val(response.pdfpath);
        		$('#d_name').html(response.userContract.contract.name);
        		$('#document_name').val(response.userContract.contract.name);
            }
        }
    });
}

window.acceptContract = function(user_contract_recipient_id,user_contract_id,property_id) {
	var user_id = user.user_details.user_id;
	var data = 'user_id='+user_id+'&user_contract_recipient_id='+user_contract_recipient_id+'&user_contract_id='+user_contract_id+'&property_id='+property_id;
	var url = api_url+"user-contracts/accept-contract";
	$.ajax({
        method: 'POST',
        url: url,
        data: data,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
            display_error_message(response);
            if(response.response_data.success == true) {
        		$('#user_contract_'+user_contract_id).remove();
        		$('#viewDocumentModal').modal('hide');
            }
        }
    });
}

window.sendContractForSignature = function(user_contract_id) {
	var user_id = user.user_details.user_id;
	var data = 'user_id='+user_id+'&user_contract_id='+user_contract_id;
	var url = api_url+"user-contracts/send-contract-for-signature";
	$.ajax({
        method: 'POST',
        url: url,
        data: data,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(data) {
            display_error_message(data);
            if(data.response_data.success == true) {
        		getPropertyDocument();	
            } else {
            	$('.contract_send_for_signature').removeClass('add_opacity');
            }
        }
    });
}

window.getPendingSignatureContract = function() {
	$.ajax({
	    method: 'POST',
	    url: api_url+"user-contracts/get-pending-signature-contract",
		data: 'user_id='+user.user_details.user_id,
	    beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
	    success: function(response) {
	        if(response.response_data.success == true) {
	        	var html = "";
		        if(response.userContractRecipients == null) {
		        	//html += '<a href="javascript:void(0)" class="list-group-item list-group-item-action border-0"><div class="fs-16">'+response.response_data.message+'</div></a>';
			    } else {
			    	$('#document_pending_signature_section').removeClass('d-none');
			    	for (var key in response.userContractRecipients) {
			            if (response.userContractRecipients.hasOwnProperty(key)) {

			            	var name = stripHtml(response.userContractRecipients[key].property.name);
			            	var address = '';
			                if(response.userContractRecipients[key].property != null && response.userContractRecipients[key].property.address != null) {
			                	address = response.userContractRecipients[key].property.address.address_line1+', '+response.userContractRecipients[key].property.address.address_line2+'<br>'+response.userContractRecipients[key].property.address.city+', '+response.userContractRecipients[key].property.address.state+' '+response.userContractRecipients[key].property.address.zip_code;
			                }

			                /*html += '<div id="user_contract_'+response.userContractRecipients[key].user_contract_id+'" class="col-12 col-md-6 col-xl-3 document_review_card">';
					        	html += '<div class="card">';
									html += '<div class="card-header border-bottom"><h5 class="card-title capitalize mb-0 mr-3" title="'+response.userContractRecipients[key].contract.name+'">'+response.userContractRecipients[key].contract.name+'</h5></div>';
									html += '<div class="card-img text-center border-bottom pb-3"><img src="img/sample/pdf.png" width="100" height="100" alt="pdf" class="mb-2 mt-4"></div>';
									html += '<div class="card-body">';
										html += '<table class="table mb-0 card-table word-break">';
											html += '<tbody>';
												html += '<tr>';
													html += '<td class="nowrap">Property Name</td>';
													html += '<td class="text-right">'+name+'</td>';
												html += '</tr>';
												html += '<tr>';
													html += '<td class="nowrap">Address</td>';
													html += '<td class="text-right">'+address+'</td>';
												html += '</tr>';
											html += '</tbody>';
										html += '</table>';
										html += '<div class="mt-3 ml-2">Document <strong>'+response.userContractRecipients[key].contract.name+'</strong> available for your signature. Click link below to open document</div>';
										html += '<div class="text-right mt-4">';
											html += '<button data-ticket-id='+response.userContractRecipients[key].user_contract.ticket_id+' data-property-id='+response.userContractRecipients[key].property_id+' data-user-contract-id='+response.userContractRecipients[key].user_contract_id+' data-user-contract-recipient-id='+response.userContractRecipients[key].id+' type="button" class="btn btn-orange sign_contract_document">Sign Document</button>';
										html += '</div>';
									html += '</div>';
									html += '</div>';
								html += '</div>';
							html += '</div>';*/

							notification_html += '<div id="user_contract_'+response.userContractRecipients[key].user_contract_id+'" class="list-group-item document_review_card">';
							    notification_html += '<div class="row no-gutters align-items-center">';
							        notification_html += '<div class="col-2"> <i class="text-danger" data-feather="credit-card"></i> </div>';
							        notification_html += '<div class="col-10">';
							            notification_html += '<div class="text-dark">'+response.userContractRecipients[key].contract.name+'</div>';
							            notification_html += '<div class="text-muted small mt-1">'+name+'</div>';
							            notification_html += '<div class="text-muted small mt-1">'+address+'</div>';
							            notification_html += '<div class="mt-3 ml-2">Document <strong>'+response.userContractRecipients[key].contract.name+'</strong> available for your signature. Click link below to open document</div>';
							            notification_html += '<button data-ticket-id='+response.userContractRecipients[key].user_contract.ticket_id+' data-property-id='+response.userContractRecipients[key].property_id+' data-user-contract-id='+response.userContractRecipients[key].user_contract_id+' data-user-contract-recipient-id='+response.userContractRecipients[key].id+' type="button" class="btn btn-orange sign_contract_document">Sign Document</button>';
							        notification_html += '</div>';
							    notification_html += '</div>';
							notification_html += '</div>';
			            }
			        }
			    }
		        //$('#document_pending_signature_section').html(html);
		        $('#document_pending_signature_section').html(notification_html);
	        } else {
	        	display_error_message(response);
	        }
	    }
	});
}

window.getContractForSignature = function(property_id,user_contract_id,user_contract_recipient_id) {
	var user_id = user.user_details.user_id;
	var data = 'user_id='+user_id+'&property_id='+property_id+'&user_contract_id='+user_contract_id+'&user_contract_recipient_id='+user_contract_recipient_id;
	var url = api_url+"user-contracts/get-contract-for-signature";
	$.ajax({
        method: 'POST',
        url: url,
        data: data,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
            display_error_message(response);
            if(response.response_data.success == true) {
        		$('#document_url').val(response.pdfpath);
        		$('#d_name').html(response.userContract.contract.name);
        		$('#document_name').val(response.userContract.contract.name);
            }
        }
    });
}

window.downloadDraftContractDocument = function(user_contract_id) {
	var user_id = user.user_details.user_id;
	var data = 'user_id='+user_id+'&user_contract_id='+user_contract_id;
	var url = api_url+"user-contracts/download-draft-contract-document";
	$.ajax({
        method: 'POST',
        url: url,
        data: data,
        cache: false,
        xhr: function () {
            var xhr = new XMLHttpRequest();
            xhr.onreadystatechange = function () {
                if (xhr.readyState == 2) {
                    if (xhr.status == 200) {
                        xhr.responseType = "blob";
                    } else {
                        xhr.responseType = "text";
                    }
                }
            };
            return xhr;
        },
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
            //Convert the Byte Data to BLOB object.
            var blob = new Blob([response], { type: "application/octetstream" });
            //Check the Browser type and download the File.
            var fname = $('#d_name').text();
            var fileName = fname+'.pdf';
            var isIE = false || !!document.documentMode;
            if (isIE) {
                window.navigator.msSaveBlob(blob, fileName);
            } else {
                var url = window.URL || window.webkitURL;
                var link = url.createObjectURL(blob);
                var a = $("<a />");
                a.attr("download", fileName);
                a.attr("href", link);
                $("body").append(a);
                a[0].click();
                $("body").remove(a);
            }
        }
    });
}

window.getDocumentTypes = function(request_type_id = '') {
	$.ajax({
       	method: 'GET',
	    url: api_url+"zoho-templates/get-document-types",
	    beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	display_error_message(response);
            if(response.response_data.success == true) {
            	var html = '';
            	html += '<select name="request_type_id"  id="request_type_id" class="form-control">';
	            	for (var key in response.documentTypes) {
		                if (response.documentTypes.hasOwnProperty(key)) {
		                	var selected = '';
		                	if(request_type_id == response.documentTypes[key].folder_id) {
		                		selected = "selected";
		                	}
	                		html += '<option value="'+response.documentTypes[key].folder_id+'" '+selected+'>'+response.documentTypes[key].folder_name+'</option>';
						}
					}
				html += '</select>';
				$('#document_type_container').html(html);
            }
        }
    });
}

window.zohoCreateNewTemplate = function() {

	if(createTemplateDropzone.files.length == 0) {
		$('.submit_zoho_template_detail').attr('disabled', false);
		var response = {
            "response_data": {
            	"success": false,
              	"message": 'Please select pdf document'
            }
        };
        display_error_message(response);
        return false;
	}


	var formData = new FormData();
	
	// Upload Property files
    for (var x = 0; x < createTemplateDropzone.files.length; x++) {
    	formData.append("file[]", createTemplateDropzone.files[x]);
    }

    var is_sequential = false;
    
    var email_reminders = false;
    if($('#email_reminders').is(":checked")) {
    	email_reminders = true;
    }

    var user_id = user.user_details.user_id;
    formData.append('user_id', user_id);
    formData.append('template_name', $('#template_name').val());
    formData.append('request_type_id', $('#request_type_id').val());
    formData.append('expiration_days', $('#expiration_days').val());
    formData.append('is_sequential', is_sequential);
    formData.append('reminder_period', $('#reminder_period').val());
    formData.append('email_reminders', email_reminders);
    formData.append('description', $('#description').val());
    formData.append('notes', $('#notes').val());

    var recipientArray = [];
    $('input.sequenceNo').each(function(key, value) {
    	var no = key + 1;
	    var seq_no = $('#seq_no'+no).val();
	    var role = $('#role'+no).val();
	    //var recipient_email = $('#recipient_email'+no).val();
	    //var recipient_name = $('#recipient_name'+no).val();
	    recipientArray.push({
			'signing_order' : seq_no,
			'role' : role,
			'recipient_email' : '',
			'recipient_name' : '',
		});
	});

	formData.append('actions', JSON.stringify(recipientArray));

	$.ajax({
        method: 'POST',
        url: api_url+"zoho-templates/create",
        data: formData,
        processData: false,
        contentType: false,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	display_error_message(response);
        	if(response.response_data.success == true) {
        		var template_id = response.templateDetail.templates.template_id;
        		var rand_num1 = random_number(11);
				var rand_num2 = random_number(20);
				var tid = rand_num1+template_id+rand_num2;
        		window.history.pushState("", "", '?action=template&tid='+tid);
        		openLoadingDialog($('#main_container'));
        		setTimeout(function(){
					$('#main_container').load('components/template/viewer.html', template_id, function(){
						$('#pdf_template_id').val(template_id);
						//getZohoTemplateRecipients(template_id);
						getZohoFieldTypes();
			    		//getFloatingActionButton('Template', '#pdf_viwer_container');
					});
				}, 2000);
            } else {
            	$('.submit_zoho_template_detail').attr('disabled', false);
            }
        }
    });
}

window.zohoUpdateTemplate = function() {

	/*if(updateTemplateDropzone.files.length == 0) {
		var response = {
            "response_data": {
            	"success": false,
              	"message": 'Please select pdf document'
            }
        };
        display_error_message(response);
        return false;
	}*/


	var formData = new FormData();
	
	// Upload Property files
    for (var x = 0; x < updateTemplateDropzone.files.length; x++) {
    	formData.append("file[]", updateTemplateDropzone.files[x]);
    }

    var is_sequential = false;
    
    var email_reminders = false;
    if($('#email_reminders').is(":checked")) {
    	email_reminders = true;
    }

    var user_id = user.user_details.user_id;
    var template_id = $('#template_id').val();
    formData.append('user_id', user_id);
    formData.append('template_id', template_id);
    formData.append('template_name', $('#template_name').val());
    formData.append('request_type_id', $('#request_type_id').val());
    formData.append('expiration_days', $('#expiration_days').val());
    formData.append('is_sequential', is_sequential);
    formData.append('reminder_period', $('#reminder_period').val());
    formData.append('email_reminders', email_reminders);
    formData.append('description', $('#description').val());
    formData.append('notes', $('#notes').val());

    var deleted_actions = $('#deletedZohoActionId').val();
    deleted_actions =  deleted_actions.split(',');
    formData.append('deleted_actions', deleted_actions);

    var recipientArray = [];
    $('input.sequenceNo').each(function(key, value) {
    	var no = $(this).val();
	    var seq_no = $('#seq_no'+no).val();
	    var role = $('#role'+no).val();
	    var recipient_email = $('#recipient_email'+no).val();
	    var recipient_name = $('#recipient_name'+no).val();
	    var action_id = $('#action_id'+no).val();
	    recipientArray.push({
			'signing_order' : seq_no,
			'role' : role,
			'recipient_email' : '',
			'recipient_name' : '',
			'action_id' : action_id,
		});
	});

	//console.log(recipientArray);return false;

	formData.append('actions', JSON.stringify(recipientArray));

	$.ajax({
        method: 'POST',
        url: api_url+"zoho-templates/update",
        data: formData,
        processData: false,
        contentType: false,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	display_error_message(response);
        	if(response.response_data.success == true) {
        		var rand_num1 = random_number(11);
				var rand_num2 = random_number(20);
				var tid = rand_num1+template_id+rand_num2;
        		window.history.pushState("", "", '?action=template&tid='+tid);
        		openLoadingDialog($('#main_container'));
        		setTimeout(function(){
					$('#main_container').load('components/template/viewer.html', template_id, function(){
						$('#pdf_template_id').val(template_id);
						//getZohoTemplateRecipients(template_id);
						getZohoFieldTypes();
			    		//getFloatingActionButton('Template', '#pdf_viwer_container');
					});
				}, 2000);
            } else {
            	$(".update_zoho_template_detail").attr('disabled', false);
            }
        }
    });
}

window.getZohoTemplateDetailById = function(template_id) {
	$.ajax({
        method: 'GET',
        url: api_url+"zoho-templates/detail/"+template_id,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	display_error_message(response);
        	if(response.response_data.success == true) {
        		var template_id = response.templateDetail.templates.template_id;
        		var template_name = response.templateDetail.templates.template_name;
        		var request_type_id = response.templateDetail.templates.request_type_id;
        		var is_sequential = response.templateDetail.templates.is_sequential;
        		var expiration_days = response.templateDetail.templates.expiration_days;
        		var email_reminders = response.templateDetail.templates.email_reminders;
        		var reminder_period = response.templateDetail.templates.reminder_period;
        		var description = response.templateDetail.templates.description;
        		var notes = response.templateDetail.templates.notes;

        		setTimeout(function () {
		            getDocumentTypes(request_type_id);
		        }, 300);

        		var html = '';
        		for (var key in response.templateDetail.templates.document_ids) {
	                if (response.templateDetail.templates.document_ids.hasOwnProperty(key)) {
        				html += '<div id="document'+response.templateDetail.templates.document_ids[key].document_id+'" class="form-group col-md-4 col-xl-2 ml-3 text-center border preview_zoho_document"><i data-template-id="'+template_id+'" data-document-id="'+response.templateDetail.templates.document_ids[key].document_id+'" class="align-middle mr-2 fas fa-fw fa-trash delete_zoho_document d-none" data-toggle="tooltip" data-placement="bottom" title="Delete" data-original-title="Delete"></i><img src="data:image/png;base64,'+response.templateDetail.templates.document_ids[key].image_string+'" alt="'+response.templateDetail.templates.document_ids[key].document_name+'" /></div>';
        			}
        		}

        		$('#pdf_section').append(html);

        		$('#document_dropzone_container').removeClass('d-none');

        		$('#email_reminders').attr('checked', false);
        		if(email_reminders == true) {
        			$('#email_reminders').attr('checked', true);
        		}

        		$('#template_id').val(template_id);
        		$('#template_name').val(template_name);
        		$('#expiration_days').val(expiration_days);
        		$('#reminder_period').val(reminder_period);
        		$('#description').val(description);
        		$('#notes').val(notes);

        		var html = '';

        		var display = 'd-none';
        		var total = response.templateDetail.templates.actions.length;
        		if(total > 1) {
        			display = '';
        		}

        		for (var key in response.templateDetail.templates.actions) {
	                if (response.templateDetail.templates.actions.hasOwnProperty(key)) {
	                	var sigining_order = response.templateDetail.templates.actions[key].signing_order;
	                	var action_id = response.templateDetail.templates.actions[key].action_id;
	                	var role = response.templateDetail.templates.actions[key].role;
	                	var recipient_email = response.templateDetail.templates.actions[key].recipient_email;
	                	var recipient_name = response.templateDetail.templates.actions[key].recipient_name;

	                	var add_opacity = '';
	                	if(key == 0) {
	                		add_opacity = 'add_opacity bold-black';
	                	}

	                	html += '<div id="recipients_'+sigining_order+'" class="form-row recipients_data">';
				            html += '<div class="form-group col-md-4 col-xl-4">';
				            	html += '<div class="input-group mb-2 mr-sm-2">';
									html += '<div class="input-group-prepend">';
										html += '<input type="hidden" name="action_id[]" id="action_id'+sigining_order+'" class="action_input" value="'+action_id+'">';
										html += '<input type="hidden" name="seq_no[]" id="seq_no'+sigining_order+'" class="sequenceNo" value="'+sigining_order+'">';
										html += '<div class="input-group-text sequenceNo">'+sigining_order+'</div>';
									html += '</div>';
									//html += '<input type="text" name="role[]" id="role'+sigining_order+'" class="form-control role" placeholder="Role" value="'+role+'">';
									html += '<select name="role[]" id="role'+sigining_order+'" class="form-control role '+add_opacity+'">';
										$.each(role_dd, function( key, value ) {
											var selected = '';
											if(value == role) {
												selected = 'selected';
											}
										  	html += '<option value="'+value+'" '+selected+'>'+value+'</option>';
										});
									html += '</select>';
								html += '</div>';
				            html += '</div>';
				            /*html += '<div class="form-group col-md-4 col-xl-4">';
				            	html += '<input type="text" name="recipient_email[]" id="recipient_email'+sigining_order+'" class="form-control recipient_email" placeholder="Email" value="'+recipient_email+'">';
				            html += '</div>';
				            html += '<div class="form-group col-md-3 col-xl-3">';
				            	html += '<input type="text" name="recipient_name[]" id="recipient_name'+sigining_order+'" type="text" class="form-control recipient_name" placeholder="Name" value="'+recipient_name+'">';
					        html += '</div>';*/
					        html += '<div class="action_btn_group form-group col-md-1 col-xl-1">';
				            	html += '<a href="javascript:void(0)" data-row-id="'+sigining_order+'" class="removeRowButton btn btn-default mr-2 '+display+'"><i class="fas fa-minus"></i></a>';
				            	if((total - 1) == key) {
				            		html += '<a href="javascript:void(0)" data-row-id="'+sigining_order+'" class="addRowButton btn btn-default"><i class="fas fa-plus"></i></a>';
				            	}
					        html += '</div>';
				        html += '</div>';
	                }
	            }

	            $('#recipients_container').html(html);

	            if($('.recipients_data').length > 1) {
		        	$('.recipients_data:first').find('.removeRowButton').remove();
		        }
            }
        }
    });
}

window.saveZohoDocumentData = function(template_id, actions) {
	//console.log(actions);
	$.ajax({
        method: 'POST',
        url: api_url+"zoho-templates/update-document",
        data: 'template_id='+template_id+'&actions='+actions,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
	    async: true,
        success: function(response) {
        	display_error_message(response);
        	if(response.response_data.success == true) {
        		$('.save_document_data').attr('disabled',false);
    			openLoadingDialog($('#main_container'));
				$('#main_container').load('components/template.html');    		
            } else {
            	$('.save_document_data').attr('disabled',false);
            }
        }
    });
}

window.deleteZohoDocument = function(id) {
	var arr = id.split('&&');
	var template_id = arr[0];
	var document_id = arr[1];
	$.ajax({
        method: 'POST',
        url: api_url+"zoho-templates/delete-document",
        data: 'template_id='+template_id+'&document_id='+document_id,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
	    async: true,
        success: function(response) {
        	display_error_message(response);
        	if(response.response_data.success == true) {
        		$('#document'+document_id).remove();
            }
        }
    });
}

window.deleteDocumentField = function(id, field_id, action_id) {
	$('.field'+id).remove();
	// Add delete field_id in hidden input
	if(field_id > 0) {
		var delimeter = '';
		if($('#deletedFieldIds').val() != "") { delimeter = ','; }
		var deletedActionIds = $('#deletedFieldIds').val($('#deletedFieldIds').val()+delimeter+field_id);
	}
}

window.getZohoTemplateRecipients = function(response) {
	var template_id = response.templateDetail.templates.template_id;
	var template_name = response.templateDetail.templates.template_name;
	$('#navbar_role_name').html(humanize(current_page)+' <small class="fs-12">('+template_name+')</small>');
	
	var html = '';
	var field_input = '';
	var bg_color = '';
	for (var key in response.templateDetail.templates.actions) {
        if (response.templateDetail.templates.actions.hasOwnProperty(key)) {
        	var sigining_order = response.templateDetail.templates.actions[key].signing_order;
        	var action_id = response.templateDetail.templates.actions[key].action_id;
        	var role = response.templateDetail.templates.actions[key].role;
        	var recipient_email = response.templateDetail.templates.actions[key].recipient_email;
        	var recipient_name = response.templateDetail.templates.actions[key].recipient_name;

        	var action_type = response.templateDetail.templates.actions[key].action_type;
        	var signing_order = response.templateDetail.templates.actions[key].signing_order;
        	var in_person_name = recipient_name;
        	var verify_recipient = response.templateDetail.templates.actions[key].verify_recipient;
        	var verification_type = response.templateDetail.templates.actions[key].verification_type;

        	var bg_color = color_array[key];
        	var border_color = border_color_array[key];

        	var bg_class = '';
        	if(key == 0) {
            	$("<style type='text/css'> .recipient_row_bg { background-color: "+bg_color+" } </style>").appendTo("head");
				bg_class = 'recipient_row_bg';
			}

        	html += '<div data-border-color='+border_color+' data-bg-color='+bg_color+' data-template-id='+template_id+' data-action-id='+action_id+' data-role="'+role+'" data-name="'+recipient_name+'" data-action-type='+action_type+' data-signing-order='+signing_order+' data-in-person_name="'+recipient_name+'" data-verify-recipient='+verify_recipient+' data-verification-type='+verification_type+' class="recipient_row media border-bottom p-2 '+bg_class+'">';
				html += '<div class="po_bg mt-1 ml-2 mr-3"><div class="circle"><p class="text-center">'+role.charAt(0)+'</p></div></div>';
				html += '<div class="media-body mt-1 pt-2"><strong>'+role+'</strong></div>';
			html += '</div>';

			field_input += '<input type="hidden" name="'+action_id+'" id="'+action_id+'">';
        }
    }

    $('#recipients_data_container').html(html);
    $('#recipients_data_container').append(field_input);
}

window.getZohoDocumentFields = function(response) {
	display_error_message(response);
	if(response.response_data.success == true) {
		var template_id = response.templateDetail.templates.template_id;
		
		var bg_color = '';
		for (var key in response.templateDetail.templates.actions) {
            if (response.templateDetail.templates.actions.hasOwnProperty(key)) {
            	var sigining_order = response.templateDetail.templates.actions[key].signing_order;
            	var action_id = response.templateDetail.templates.actions[key].action_id;
            	var role = response.templateDetail.templates.actions[key].role;
            	var recipient_email = response.templateDetail.templates.actions[key].recipient_email;
            	var recipient_name = response.templateDetail.templates.actions[key].recipient_name;

            	var action_type = response.templateDetail.templates.actions[key].action_type;
            	var signing_order = response.templateDetail.templates.actions[key].signing_order;
            	var in_person_name = recipient_name;
            	var verify_recipient = response.templateDetail.templates.actions[key].verify_recipient;
            	var verification_type = response.templateDetail.templates.actions[key].verification_type;

            	var bg_color = color_array[key];
            	var border_color = border_color_array[key];

            	var bg_class = '';
            	if(key == 0) {
                	$("<style type='text/css'> .recipient_row_bg { background-color: "+bg_color+"; border-left: 3px solid "+border_color+" !important; border-right: 3px solid "+border_color+" !important; } </style>").appendTo("head");
					bg_class = 'recipient_row_bg';
				}

            	// Set Field data in document
				for (var fkey in response.templateDetail.templates.actions[key].fields) {
            		if (response.templateDetail.templates.actions[key].fields.hasOwnProperty(fkey)) {
            			var field_id = response.templateDetail.templates.actions[key].fields[fkey].field_id;
            			var field_type_id = response.templateDetail.templates.actions[key].fields[fkey].field_type_id;
            			var field_label = response.templateDetail.templates.actions[key].fields[fkey].field_label;
            			var field_category = response.templateDetail.templates.actions[key].fields[fkey].field_category;
            			var document_id = response.templateDetail.templates.actions[key].fields[fkey].document_id;
            			var page_no = response.templateDetail.templates.actions[key].fields[fkey].page_no;
            			var x_value = response.templateDetail.templates.actions[key].fields[fkey].x_value;
            			var y_value = response.templateDetail.templates.actions[key].fields[fkey].y_value;

            			var randomStr = Math.floor(Math.random() * 1000000000);

            			// Left and Top position calculation
				  		// Percentage with 817 * 1057

				  		var leftPixel = (Math.round(x_value) * canvas_width) / 100 ;
				  		var topPixel = (Math.round(y_value) * canvas_height) / 100;

				  		leftPixel = leftPixel+'px';
				  		topPixel = topPixel+'px';

				  		var field_html = '';
				  		field_html += '<div id='+field_label+' field-id='+field_id+' field-type-id='+field_type_id+' field-category='+field_category+' name='+field_label+' class="drag ui-draggable ui-draggable-handle item_element field'+randomStr+'" page-no='+page_no+' data-id='+randomStr+' data-template-id='+template_id+' data-action-id='+action_id+' data-role="'+role+'" data-name="'+recipient_name+'" data-action-type='+action_type+' data-signing-order='+signing_order+' data-verify-recipient='+verify_recipient+' data-verification-type='+verification_type+' data-bg-color='+bg_color+' style="position:absolute;left:'+leftPixel+';top:'+topPixel+';border:1px dashed '+border_color+'; background-color: '+bg_color+'">';
            				field_html += '<div class="field_label" title='+field_label+'>'+field_label+'</div>';
            			field_html += '</div>';

            			$('div[page-no="'+page_no+'"][document-id="'+document_id+'"]').find('.text-layer').append(field_html);
			            $('div[page-no="'+page_no+'"][document-id="'+document_id+'"]').find('.item_element').draggable({
			                containment: 'parent'
			            });
            		}
            	}
            }
        }
    }
}

window.getZohoFieldTypes = function() {
	openLoadingDialog($('#field_types_container'));
	$.ajax({
        method: 'GET',
        url: api_url+"zoho-templates/fieldtypes",
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
	    async: true,
        success: function(response) {
        	display_error_message(response);
        	if(response.response_data.success == true) {
        		var html = '';
        		html += '<div id="fields-list" class="fields-list">';
        		for (var key in response.response.field_types) {
	                if (response.response.field_types.hasOwnProperty(key)) {
	                	var display_name = response.response.field_types[key].field_type_name;
	                	var field_type_id = response.response.field_types[key].field_type_id;
	                	var field_type_name = response.response.field_types[key].field_type_name;
	                	var field_category = response.response.field_types[key].field_category;

	                	var icon = '';
	                	if(field_type_name == 'Signature') {
	                		icon = '<i class="far fa-edit field-icon"></i>';
	                	} else if(field_type_name == 'Dropdown') {
	                		icon = '<i class="far fa-caret-square-down field-icon"></i>';
	                	} else if(field_type_name == 'Attachment') {
	                		icon = '<i class="fas fa-paperclip field-icon"></i>';
	                	} else if(field_type_name == 'Email') {
	                		icon = '<i class="far fa-envelope field-icon"></i>';
	                	} else if(field_type_name == 'Company') {
	                		icon = '<i class="far fa-building field-icon"></i>';
	                	} else if(field_type_name == 'Date') {
	                		icon = '<i class="far fa-calendar-alt field-icon"></i>';
	                	} else if(field_type_name == 'Textfield') {
	                		icon = '<i class="fas fa-font field-icon"></i>';
	                		display_name = 'Text';
	                	} else if(field_type_name == 'Jobtitle') {
	                		icon = '<i class="fas fa-font field-icon"></i>';
	                		display_name = 'Job Title';
	                	} else if(field_type_name == 'Initial') {
	                		icon = '<i class="fas fa-signature field-icon"></i>';
	                	} else if(field_type_name == 'Name') {
	                		icon = '<i class="fas fa-male field-icon"></i>';
	                		display_name = 'Full Name';
	                	} else if(field_type_name == 'CustomDate') {
	                		icon = '<i class="far fa-calendar-alt field-icon"></i>';
	                		display_name = 'Sign Date';
	                	} else if(field_type_name == 'Stamp') {
	                		icon = '<i class="fas fa-stamp field-icon"></i>';
	                	} else if(field_type_name == 'Checkbox') {
	                		icon = '<i class="far fa-check-square field-icon"></i>';
	                	} else if(field_type_name == 'Radiogroup') {
	                		icon = '<i class="far fa-dot-circle field-icon"></i>';
	                		display_name = 'Radio';
	                	}

						html += '<div class="field-li">';
							html += '<div id="'+field_type_name+'" field-type-id="'+field_type_id+'" field-category="'+field_category+'" name="'+field_type_name+'" class="drag field-li-view">';
									html += '<i class="fas fa-grip-vertical icon-move drag-icon"></i>';
									html += '<div class="field_label" title="'+field_type_name+'">'+display_name+'</div>';
									html += icon;
							html += '</div>';
						html += '</div>';
					}
				}
				html += '</div>';

				$('#field_types_container').html(html);

				$(".field-li-view").draggable({
			  		helper: "clone",
			  		cursor: 'move',
				    tolerance: 'fit',
				    revert: true,
				    start: function( event, ui ) {
				    	var border_color = $('#recipients_data_container').find('div.recipient_row_bg').attr('data-border-color');
				    	$(ui.helper).css({'border': '1px dashed '+border_color});
				    	$(ui.helper).height(35);
				    	$(ui.helper).css('line-height','35px !important');
				    	$(ui.helper).find('div.field_label').css({'position' : 'relative','top': '20%'});

				    	$(ui.helper).find('.drag-icon').remove();
						$(ui.helper).find('.field-icon').remove();
				    }
			    });

				// Change color of fields section
			    /*setTimeout(function(){
					var border_color = $('#recipients_data_container').find('div.recipient_row_bg').attr('data-border-color');
					$('#field_header').css({'background': border_color, 'color': 'white'});
					$('.field-li-view').css({'border': '1px solid '+border_color});
					$('.field-li-view .drag-icon').css({'color': border_color});
					$('.field-li-view .field-icon').css({'background-color': border_color});
				}, 500);*/
            }
        }
    });
}

window.deleteZohoTemplateById = function(template_id) {
	$.ajax({
        method: 'POST',
        url: api_url+"zoho-templates/remove",
        data: 'template_id='+template_id,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	display_error_message(response);
        	if(response.response_data.success == true) {
        		openLoadingDialog($('#main_container'));
				$('#main_container').load('components/template.html');
            }
        }
    });
}

/* Manage Agreement */

window.getAgreementTypeList = function(container='') {
	$.ajax({
        method: 'GET',
        url: api_url+"agreement-types",
        async: true,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
            if(response.response_data.success == true) {
            	var html = '';
                //var html = "<option value=''>Select Term</option>";
                for (var key in response.agreement_types) {
		            if (response.agreement_types.hasOwnProperty(key)) {
					  	html += '<option value="'+response.agreement_types[key].id+'">'+response.agreement_types[key].name+'</option>';
					}
				}
		        $('#'+container).html(html);
            } else {
            	display_error_message(response);
            }
        }
    });
}

window.getMasterAgreements = function(open_for) {
	getFloatingActionButton('MasterAgreements', '#master_agreements_container');
	if(user.permissionData['TEMPLATE']["feature_items['master_agreement_list']"]) {
		if ($.fn.DataTable.isDataTable("#master-agreement-datatable")) {
            $("#master-agreement-datatable").DataTable().destroy();
        }
		$.ajax({
	        method: 'GET',
	        url: api_url+"master-agreements",
	        beforeSend: function(xhr) {
		        xhr.setRequestHeader('Authorization', accessToken);
		    },
	        success: function(response) {
	        	display_error_message(response);
	        	if(response.response_data.success == true) {
	        		var html = '';
	            	for (var key in response.master_agreements) {
		                if (response.master_agreements.hasOwnProperty(key)) {
	                		var created = response.master_agreements[key].created;
		                	created = new Date(created).toLocaleDateString("en-US");
							
		                	var kebab = '<div class="text-center"><div class="input-group-prepend"><i class="align-middle mr-2 fas fa-fw fa-ellipsis-v" data-toggle="dropdown" aria-expanded="false"></i><div class="dropdown-menu dropdown-menu-right" style="">';

		                	if(user.permissionData['TEMPLATE']["feature_items['master_agreement_disable']"] && response.master_agreements[key].status == 1) {
		                		kebab += '<a class="dropdown-item disable_master_agreement" href="javascript:void(0)" data-master-agreement-id="'+response.master_agreements[key].id+'">Disable Agreement</a>';
		                	}
		                	if(user.permissionData['TEMPLATE']["feature_items['add_signature_document']"] && response.master_agreements[key].status == 1) {
		                		kebab += '<a class="dropdown-item add_signature_agreement" href="javascript:void(0)" data-master-agreement-id="'+response.master_agreements[key].id+'" data-form="'+response.master_agreements[key].form+'" data-table="'+response.master_agreements[key].table_name+'" data-url="'+response.master_agreements[key].document_url+'">Add Signature Document</a>';
		                	}

		                	kebab += '</div></div></div>';

					        html += '<tr>';
	                            html += '<td>'+response.master_agreements[key].id+'</td>';
	                            html += '<td>'+response.master_agreements[key].master_agreement_name+'</td>';
	                            html += '<td>'+response.master_agreements[key].agreement_type.name+'</td>';
	                            html += '<td>'+response.master_agreements[key].display_name+'</td>';
	                            html += '<td>'+response.master_agreements[key].status_label+'</td>';
	                            html += '<td>'+created+'</td>';
	                            html += '<td>'+kebab+'</td>';
	                        html += '</tr>';
		                }
		            }
		            $('#master_agreement_data_container').html(html);

		            var column = [
			            { className: 'all', targets: [0], width:'5%', orderable: true},
			            { className: 'all',  targets: [1], width:'40%', orderable: true},
			            { className: 'all', targets: [6], width:'5%', orderable: false}
			        ]; 

		            $("#master-agreement-datatable").DataTable({
					    responsive: true,
					    order: [[0,'desc']],
					    columnDefs: column,
					});
	            }
	        }
	    });
	}
}

window.createMasterAgreement = function() {
	$('.submit_master_agreement_detail').attr('disabled', false);
	if(createMasterAgreementDropzone.files.length == 0) {
		$('.submit_master_agreement_detail').attr('disabled', false);
		var response = {
            "response_data": {
            	"success": false,
              	"message": 'Please select pdf document'
            }
        };
        display_error_message(response);
        return false;
	}


	var formData = new FormData();
	
	// Upload Property files
    for (var x = 0; x < createMasterAgreementDropzone.files.length; x++) {
    	formData.append("file", createMasterAgreementDropzone.files[x]);
    }

    var user_id = user.user_details.user_id;
    formData.append('user_id', user_id);
    formData.append('master_agreement_name', $('#master_agreement_name').val());
    formData.append('agreement_type_id', $('#agreement_types').val());
    formData.append('status', 1);
    
	$.ajax({
        method: 'POST',
        url: api_url+"master-agreements/add",
        data: formData,
        processData: false,
        contentType: false,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	display_error_message(response);
        	if(response.response_data.success == true) {
        		openLoadingDialog($('#main_container'));
        		setTimeout(function(){
        			openLoadingDialog($('#main_container'));
					$('#main_container').load('components/template.html');	
				}, 1000);
            } else {
            	$('.submit_master_agreement_detail').attr('disabled', false);
            }
        }
    });
}

window.disableMasterAgreement = function(id) {
	$.ajax({
        method: 'POST',
        url: api_url+"master-agreements/disable",
        data: 'master_agreement_id='+id,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
	    async: true,
        success: function(response) {
        	display_error_message(response);
        	if(response.response_data.success == true) {
        		openLoadingDialog($('#main_container'));
				$('#main_container').load('components/template.html');	
            }
        }
    });
}

/* Agreement Code start here */

window.getAgreements = function(open_for) {
	getFloatingActionButton('Agreements', '#agreements_container');
	if(user.permissionData['TEMPLATE']["feature_items['agreement_list']"]) {
		if ($.fn.DataTable.isDataTable("#agreement-datatable")) {
            $("#agreement-datatable").DataTable().destroy();
        }
		$.ajax({
	        method: 'GET',
	        url: api_url+"agreements",
	        beforeSend: function(xhr) {
		        xhr.setRequestHeader('Authorization', accessToken);
		    },
	        success: function(response) {
	        	display_error_message(response);
	        	if(response.response_data.success == true) {
	        		var html = '';
	            	for (var key in response.agreements) {
		                if (response.agreements.hasOwnProperty(key)) {
	                		var created = response.agreements[key].created;
		                	created = new Date(created).toLocaleDateString("en-US");
							
		                	// Set kebab menu conditions
					        var document_status = '';
					        var kebab = '';
					        var kebab_item = '';
				            var send_for_signature = '';
							var btn_class = '';

							var application_status = '';
							//if(user_service_name == sa_role && (response.agreements[key].application_constant == 'document_draft' || response.agreements[key].application_constant == 'document_ready_for_review' || response.agreements[key].application_constant == 'document_pending_review')) {
								if(user.permissionData['TEMPLATE']["feature_items['agreement_edit']"]) {
									kebab_item += '<a data-agreement-id="'+response.agreements[key].id+'" class="dropdown-item edit_agreement" href="javascript:void(0)">Edit</a>';
								}

								if(response.agreements[key].application_constant == 'document_ready_for_review') {
									kebab_item += '<a data-agreement-id="'+response.agreements[key].id+'" class="dropdown-item document_send_for_review" href="javascript:void(0)">Send for review</a>';
								}

								if(user.permissionData['TEMPLATE']["feature_items['agreement_delete']"]) {
				            		kebab_item += '<a data-agreement-id="'+response.agreements[key].id+'" class="dropdown-item delete_agreement" href="javascript:void(0)">Delete</a>';
				            	}
							//}

				            if(response.agreements[key].application_constant == 'document_draft') {
				            	application_status += '<span class="badge badge-danger">'+response.agreements[key].application_status+'</span>';
				            } else if(response.agreements[key].application_constant == 'document_ready_for_review' || response.agreements[key].application_constant == 'document_pending_review') {
				                application_status += '<span class="badge badge-warning">'+response.agreements[key].application_status+'</span>';
				            } else if(response.agreements[key].application_constant == 'document_ready_for_signature') {
				                application_status += '<span class="badge badge-warning">'+response.agreements[key].application_status+'</span>';
				                if(user_service_name == sa_role) {
				                	kebab_item += '<a data-agreement-id="'+response.agreements[key].id+'" data-property-id="'+response.agreements[key].property_id+'" class="dropdown-item document_send_for_signature" href="javascript:void(0)">Send for signature</a>';
				                }
				            } else if(response.agreements[key].application_constant == 'document_pending_signature') {
				                application_status += '<span class="badge badge-success">'+response.agreements[key].application_status+'</span>';
				            } else if(response.agreements[key].application_constant == 'document_completed') {
				                application_status += '<span class="badge badge-primary">'+response.agreements[key].application_status+'</span>';
				            } else if(response.agreements[key].application_constant == 'document_deleted') {
				                application_status += '<span class="badge badge-danger">'+response.agreements[key].application_status+'</span>';
				            }
							
							if($.trim(kebab_item) != '') {
					        	kebab = '<div class="text-center"><div class="input-group-prepend"><i class="align-middle mr-2 fas fa-fw fa-ellipsis-v" data-toggle="dropdown" aria-expanded="false"></i><div class="dropdown-menu dropdown-menu-right" style="">'+kebab_item+'</div></div></div>';
					        }

					        html += '<tr>';
	                            html += '<td>'+response.agreements[key].agreement_name+'</td>';
	                            html += '<td>'+response.agreements[key].property.name+'</td>';
	                            html += '<td>'+response.agreements[key].complete_in_order+'</td>';
	                            html += '<td>'+response.agreements[key].expiration_days+'</td>';
	                            html += '<td>'+response.agreements[key].reminder_period+'</td>';
	                            html += '<td>'+response.agreements[key].is_review_required+'</td>';
	                            html += '<td>'+application_status+'</td>';
	                            html += '<td>'+created+'</td>';
	                            html += '<td>'+kebab+'</td>';
	                        html += '</tr>';
		                }
		            }
		            $('#agreement_data_container').html(html);

		            var column = [
			            { className: 'all', targets: [0], width:'40%', orderable: true},
			            { targets: [1], width:'15%', orderable: true},
			            { targets: [2], width:'10%', orderable: true},
			            { targets: [3], width:'10%', orderable: true},
			            { targets: [4], width:'10%', orderable: false},
			            { className: 'all', targets: [5], width:'5%', orderable: false}
			        ]; 

		            $("#agreement-datatable").DataTable({
					    responsive: true,
					    order: [[0,'desc']],
					    columnDefs: column,
					});
	            }
	        }
	    });
	}
}

window.createNewAgreement = function() {

	$('.submit_agreement_detail').attr('disabled', false);

	if(createAgreementDropzone.files.length == 0) {
		$('.submit_agreement_detail').attr('disabled', false);
		var response = {
            "response_data": {
            	"success": false,
              	"message": 'Please select pdf document'
            }
        };
        display_error_message(response);
        return false;
	}


	var formData = new FormData();
	
	// Upload Property files
    for (var x = 0; x < createAgreementDropzone.files.length; x++) {
    	formData.append("file[]", createAgreementDropzone.files[x]);
    }

    var complete_in_order = false;
    if($('#complete_in_order').is(":checked")) {
    	complete_in_order = true;
    }
    
    var email_reminders = false;
    if($('#email_reminders').is(":checked")) {
    	email_reminders = true;
    }

    var is_review_required = false;
    if($('#is_review_required').is(":checked")) {
    	is_review_required = true;
    }

    var user_id = user.user_details.user_id;
    formData.append('user_id', user_id);
    formData.append('complete_in_order', complete_in_order);
    formData.append('agreement_name', $('#agreement_name').val());
    formData.append('expiration_days', $('#expiration_days').val());
    formData.append('reminder_period', $('#reminder_period').val());
    formData.append('email_reminders', email_reminders);
    formData.append('is_review_required', is_review_required);
    formData.append('notes', $('#notes').val());

    var recipientArray = [];
    $("select[name^='property_tenant_dd']").each(function(key, value) {
        var key = $(this).attr('data-key');
        var user_id = $(this).val();
        recipientArray.push({
            'user_id' : user_id,
            'signing_order': key
        });
    });

    formData.append('recipients', JSON.stringify(recipientArray));

	$.ajax({
        method: 'POST',
        url: api_url+"agreements/add",
        data: formData,
        processData: false,
        contentType: false,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	display_error_message(response);
        	if(response.response_data.success == true) {
        		var agreement_id = response.agreement.id;
        		var rand_num1 = random_number(11);
				var rand_num2 = random_number(20);
				var aid = rand_num1+agreement_id+rand_num2;
        		window.history.pushState("", "", '?action=template&aid='+aid);
        		openLoadingDialog($('#main_container'));
        		setTimeout(function(){
					$('#main_container').load('components/agreement/viewer.html', agreement_id, function(){
						$('#pdf_agreement_id').val(agreement_id);
						getFieldTypes();
					});
				}, 2000);
            } else {
            	$('.submit_agreement_detail').attr('disabled', false);
            }
        }
    });
}

window.updateAgreement = function() {

	//$(".update_agreement_detail").attr('disabled', true);

	/*if(updateAgreementDropzone.files.length == 0) {
		var response = {
            "response_data": {
            	"success": false,
              	"message": 'Please select pdf document'
            }
        };
        display_error_message(response);
        return false;
	}*/


	var formData = new FormData();
	
	// Upload Property files
    for (var x = 0; x < updateAgreementDropzone.files.length; x++) {
    	formData.append("file[]", updateAgreementDropzone.files[x]);
    }

    var complete_in_order = false;
    if($('#complete_in_order').is(":checked")) {
    	complete_in_order = true;
    }
    
    var email_reminders = false;
    if($('#email_reminders').is(":checked")) {
    	email_reminders = true;
    }

    var is_review_required = false;
    if($('#is_review_required').is(":checked")) {
    	is_review_required = true;
    }

    var user_id = user.user_details.user_id;
    var master_agreement_id = $('#selected_master_agreement_id').val();
    var agreement_id = $('#selected_agreement_id').val();
    var property_id = $('#selected_property_id').val();
    formData.append('user_id', user_id);
    formData.append('master_agreement_id', master_agreement_id);
    formData.append('agreement_id', agreement_id);
    formData.append('property_id', property_id);
    formData.append('agreement_name', $('#agreement_name').val());
    formData.append('expiration_days', $('#expiration_days').val());
    formData.append('complete_in_order', complete_in_order);
    formData.append('reminder_period', $('#reminder_period').val());
    formData.append('email_reminders', email_reminders);
    formData.append('is_review_required', is_review_required);
    formData.append('notes', $('#notes').val());

    var recipientArray = [];
    $("select[name^='property_tenant_dd']").each(function(key, value) {
        var key = $(this).attr('data-key');
        var user_id = $(this).val();
        recipientArray.push({
            'user_id' : user_id,
            'signing_order': key
        });
    });

    formData.append('recipients', JSON.stringify(recipientArray));

    /*var deleted_recipients = $('#deletedAgreementRecipientId').val();
    deleted_recipients =  deleted_recipients.split(',');
    formData.append('deleted_recipients', deleted_recipients);

    var recipientArray = [];
    $('input.sequenceNo').each(function(key, value) {
    	var no = $(this).val();
	    var seq_no = $('#seq_no'+no).val();
	    var role = $('#role'+no).val();
	    var recipient_email = $('#recipient_email'+no).val();
	    //var recipient_name = $('#recipient_name'+no).val();
	    var agreement_recipient_id = $('#action_id'+no).val();
	    recipientArray.push({
			'signing_order' : seq_no,
			'role' : role,
			'recipient_email' : recipient_email,
			'recipient_name' : '',
			'agreement_recipient_id' : agreement_recipient_id,
		});
	});

	formData.append('recipients', JSON.stringify(recipientArray));*/

	$.ajax({
        method: 'POST',
        url: api_url+"agreements/edit",
        data: formData,
        processData: false,
        contentType: false,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	display_error_message(response);
        	if(response.response_data.success == true) {
        		var rand_num1 = random_number(11);
				var rand_num2 = random_number(20);
				var aid = rand_num1+agreement_id+rand_num2;
        		window.history.pushState("", "", '?action=template&aid='+aid);
        		openLoadingDialog($('#main_container'));
        		setTimeout(function(){
					$('#main_container').load('components/agreement/viewer.html', agreement_id, function(){
						$('#pdf_agreement_id').val(agreement_id);
						getFieldTypes();
					});
				}, 2000);
            } else {
            	$(".update_agreement_detail").attr('disabled', false);
            }
        }
    });
}

window.getAgreementDetailById = function(agreement_id) {
	$.ajax({
        method: 'GET',
        url: api_url+"agreements/detail/"+agreement_id,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	display_error_message(response);
        	if(response.response_data.success == true) {

        		$('#selected_master_agreement_id').val(response.agreement.master_agreement.id);
        		$('#selected_agreement_id').val(response.agreement.id);
        		$('#selected_property_id').val(response.agreement.property_id);

        		var agreement_id = response.agreement.id;
        		var agreement_name = response.agreement.agreement_name;
        		var complete_in_order = response.agreement.complete_in_order;
        		var expiration_days = response.agreement.expiration_days;
        		var email_reminders = response.agreement.email_reminders;
        		var reminder_period = response.agreement.reminder_period;
        		var is_review_required = response.agreement.is_review_required;
        		var notes = response.agreement.notes;

        		var html = '';
        		for (var key in response.agreement.agreement_documents) {
	                if (response.agreement.agreement_documents.hasOwnProperty(key)) {
        				html += '<div id="document'+response.agreement.agreement_documents[key].id+'" class="form-group col-md-4 col-xl-2 ml-3 text-center border preview_agreement_document"><i data-agreement-id="'+agreement_id+'" data-document-id="'+response.agreement.agreement_documents[key].id+'" class="align-middle mr-2 fas fa-fw fa-trash delete_agreement_document d-none" data-toggle="tooltip" data-placement="bottom" title="Delete" data-original-title="Delete"></i><iframe src="'+response.agreement.agreement_documents[key].aws_url+'" width="250" height="250" frameBorder="0"></iframe></div>';
        			}
        		}

        		$('#pdf_section').append(html);

        		$('#document_dropzone_container').removeClass('d-none');

        		$('#complete_in_order').attr('checked', false);
			    if(complete_in_order == true) {
			    	$('#complete_in_order').attr('checked', true);
			    }

        		$('#email_reminders').attr('checked', false);
        		if(email_reminders == true) {
        			$('#email_reminders').attr('checked', true);
        		}

        		$('#is_review_required').attr('checked', false);
        		if(is_review_required == true) {
        			$('#is_review_required').attr('checked', true);
        		}

        		$('#agreement_id').val(agreement_id);
        		$('#agreement_name').val(agreement_name);
        		$('#expiration_days').val(expiration_days);
        		$('#reminder_period').val(reminder_period);
        		$('#notes').val(notes);

        		/*var html = '';

        		var display = 'd-none';
        		var total = response.agreement.agreement_recipients.length;
        		if(total > 1) {
        			display = '';
        		}

        		for (var key in response.agreement.agreement_recipients) {
	                if (response.agreement.agreement_recipients.hasOwnProperty(key)) {
	                	var sigining_order = response.agreement.agreement_recipients[key].signing_order;
	                	var action_id = response.agreement.agreement_recipients[key].id;
	                	var role = response.agreement.agreement_recipients[key].role;
	                	var recipient_email = response.agreement.agreement_recipients[key].recipient_email;
	                	var recipient_name = response.agreement.agreement_recipients[key].recipient_name;

	                	var add_opacity = '';
	                	if(key == 0) {
	                		add_opacity = 'add_opacity bold-black';
	                	}

	                	html += '<div id="recipients_'+sigining_order+'" class="form-row recipients_data">';
				            html += '<div class="form-group col-md-5 col-xl-5">';
				            	html += '<div class="input-group mb-2 mr-sm-2">';
									html += '<div class="input-group-prepend">';
										html += '<input type="hidden" name="action_id[]" id="action_id'+sigining_order+'" class="action_input" value="'+action_id+'">';
										html += '<input type="hidden" name="seq_no[]" id="seq_no'+sigining_order+'" class="sequenceNo" value="'+sigining_order+'">';
										html += '<div class="input-group-text sequenceNo">'+sigining_order+'</div>';
									html += '</div>';
									html += '<select name="role[]" id="role'+sigining_order+'" class="form-control role '+add_opacity+'">';
										$.each(role_dd, function( key, value ) {
											var selected = '';
											if(value == role) {
												selected = 'selected';
											}
										  	html += '<option value="'+value+'" '+selected+'>'+value+'</option>';
										});
									html += '</select>';
								html += '</div>';
				            html += '</div>';
				            html += '<div class="form-group col-md-5 col-xl-5">';
				            	html += '<input type="text" name="recipient_email[]" id="recipient_email'+sigining_order+'" class="form-control recipient_email" placeholder="Email" value="'+recipient_email+'">';
				            html += '</div>';
				            html += '<div class="action_btn_group form-group col-md-2 col-xl-2">';
				            	html += '<a href="javascript:void(0)" data-row-id="'+sigining_order+'" class="removeRowButton btn btn-default mr-2 '+display+'"><i class="fas fa-minus"></i></a>';
				            	if((total - 1) == key) {
				            		html += '<a href="javascript:void(0)" data-row-id="'+sigining_order+'" class="addRowButton btn btn-default"><i class="fas fa-plus"></i></a>';
				            	}
					        html += '</div>';
				        html += '</div>';
	                }
	            }

	            $('#recipients_container').html(html);

	            if($('.recipients_data').length > 1) {
		        	$('.recipients_data:first').find('.removeRowButton').remove();
		        }*/
            }
        }
    });
}

window.getAgreementUsers = function(agreement_id,property_id) {

    var user_id = user.user_details.user_id;
    var data = 'user_id='+user_id+'&agreement_id='+agreement_id+'&property_id='+property_id;
    var url = api_url+"agreements/get-agreement-users";

    $.ajax({
        method: 'POST',
        url: url,
        data: data,
        beforeSend: function(xhr) {
            xhr.setRequestHeader('Authorization', accessToken);
        },
        success: function(response) {
            display_error_message(response);
            if(response.response_data.success == true) {
                var html = "";
                var dd_html = "";

                /* Create Tenants Dropdown */

                dd_html += "<option value=''>--- Select User ---</option>";
                $.each( response.arrRecipints, function( key, value ) {
                    dd_html += '<option value="'+key+'">'+value+'</option>';
                });

                /* Recipients */
                
                for (var key in response.arrSignatureDetails) {
                    if (response.arrSignatureDetails.hasOwnProperty(key)) {
                        html += '<div class="form-group col-md-12 col-xl-12">';
                            html += '<div class="form-row">';
                                html += '<div class="col-md-6">';
                                    html += '<input name="role_name" type="text" class="form-control" value="'+response.arrSignatureDetails[key].recipients+'" disabled>';
                                html += '</div>';
                                html += '<div class="col-md-6">';
                                    if(key == 0) {
                                        html += response.arrSignatureDetails[key].label;
                                    } else {
                                        html += '<select name="property_tenant_dd[]" id="property_tenant_dd_'+key+'" data-key="'+key+'" class="form-control property_tenant_dd">'+dd_html+'</select>';
                                    }
                                html += '</div>';
                            html += '</div>';
                        html += '</div>';
                    }
                }

                $('.recipient_data_section').html(html);
            }
        }
    });
}

window.getAgreementRecipients = function(response) {
	var agreement_id = response.agreement.id;
	var master_agreement_name = response.agreement.master_agreement.master_agreement_name;
	$('#navbar_role_name').html(humanize(current_page)+' <small class="fs-12">('+master_agreement_name+')</small>');
	
	var html = '';
	var field_input = '';
	var bg_color = '';
	for (var key in response.agreement.agreement_recipients) {
        if (response.agreement.agreement_recipients.hasOwnProperty(key)) {
        	var signing_order = response.agreement.agreement_recipients[key].signing_order;
        	var action_id = response.agreement.agreement_recipients[key].id;
        	var recipient_email = response.agreement.agreement_recipients[key].recipient_email;
        	var recipient_name = response.agreement.agreement_recipients[key].recipient_name;

        	var role = recipient_name;
        	if(response.agreement.agreement_recipients[key].user != null) {
        		role = response.agreement.service.service_name;
        	}

        	var bg_color = color_array[key];
        	var border_color = border_color_array[key];

        	var bg_class = '';
        	if(key == 0) {
            	$("<style type='text/css'> .recipient_row_bg { background-color: "+bg_color+" } </style>").appendTo("head");
				bg_class = 'recipient_row_bg';
			}

        	html += '<div data-border-color='+border_color+' data-bg-color='+bg_color+' data-agreement-id='+agreement_id+' data-action-id='+action_id+' data-role="'+role+'" data-name="'+recipient_name+'" data-signing-order='+signing_order+' class="recipient_row media border-bottom p-2 '+bg_class+'">';
				html += '<div class="po_bg mt-1 ml-2 mr-3"><div class="circle"><p class="text-center">'+role.charAt(0)+'</p></div></div>';
				html += '<div class="media-body mt-1 pt-2"><strong>'+role+'</strong><br>'+recipient_email+'</div>';
			html += '</div>';

			field_input += '<input type="hidden" name="'+action_id+'" id="'+action_id+'">';
        }
    }

    $('#recipients_data_container').html(html);
    $('#recipients_data_container').append(field_input);
}

window.deleteAgreementDocument = function(id) {
	var arr = id.split('&&');
	var agreement_id = arr[0];
	var document_id = arr[1];
	$.ajax({
        method: 'POST',
        url: api_url+"agreements/delete-document",
        data: 'agreement_id='+agreement_id+'&document_id='+document_id,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
	    async: true,
        success: function(response) {
        	display_error_message(response);
        	if(response.response_data.success == true) {
        		$('#document'+document_id).remove();
        		var deletedRowId = $(this).closest('.recipients_data').find('input.action_input').val();
				if(deletedRowId != null) {
					var delimeter = '';
					if($('#deletedAgreementDocumentId').val() != "") { delimeter = ','; }
					var deletedAgreementRecipientIds = $('#deletedAgreementDocumentId').val($('#deletedAgreementDocumentId').val()+delimeter+deletedRowId);
				}
            }
        }
    });
}

window.saveAgreementDocumentFieldData = function(agreement_id, recipients) {
	var deleteDocumentFields = $('#deletedFieldIds').val();
	$.ajax({
        method: 'POST',
        url: api_url+"agreements/update-document-fields",
        data: 'agreement_id='+agreement_id+'&recipients='+recipients+'&deleteDocumentFields='+deleteDocumentFields,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
	    async: true,
        success: function(response) {
        	display_error_message(response);
        	if(response.response_data.success == true) {
        		$('.save_document_field_data').attr('disabled',false);
    			openLoadingDialog($('#main_container'));
				$('#main_container').load('components/template.html', function(){
					window.history.pushState("", "", '?action=template');
					$('a[href="#t-agreements"]').click();
				});
            } else {
            	$('.save_document_field_data').attr('disabled',false);
            }
        }
    });
}

window.getAgreementDocumentFields = function(response) {
	display_error_message(response);
	if(response.response_data.success == true) {
		var agreement_id = response.agreement.id;		
		var bg_color = '';
		for (var key in response.agreement.agreement_recipients) {
            if (response.agreement.agreement_recipients.hasOwnProperty(key)) {
            	var signing_order = response.agreement.agreement_recipients[key].signing_order;
            	var agreement_recipient_id = response.agreement.agreement_recipients[key].id;
            	var role = response.agreement.agreement_recipients[key].role;
            	var recipient_email = response.agreement.agreement_recipients[key].recipient_email;
            	var recipient_name = response.agreement.agreement_recipients[key].recipient_name;

            	var bg_color = color_array[key];
            	var border_color = border_color_array[key];

            	var bg_class = '';
            	if(key == 0) {
                	$("<style type='text/css'> .recipient_row_bg { background-color: "+bg_color+"; border-left: 3px solid "+border_color+" !important; border-right: 3px solid "+border_color+" !important; } </style>").appendTo("head");
					bg_class = 'recipient_row_bg';
				}

            	// Set Field data in document
				for (var fkey in response.agreement.agreement_recipients[key].agreement_document_fields) {
            		if (response.agreement.agreement_recipients[key].agreement_document_fields.hasOwnProperty(fkey)) {
            			var field_id = response.agreement.agreement_recipients[key].agreement_document_fields[fkey].id;
            			var field_type_id = response.agreement.agreement_recipients[key].agreement_document_fields[fkey].fieldtype_id;
            			var field_label = response.agreement.agreement_recipients[key].agreement_document_fields[fkey].fieldtype_name;
            			var field_category = response.agreement.agreement_recipients[key].agreement_document_fields[fkey].fieldtype_category;
            			var document_id = response.agreement.agreement_recipients[key].agreement_document_fields[fkey].agreement_document_id;
            			var page_no = response.agreement.agreement_recipients[key].agreement_document_fields[fkey].page_no;
            			var x_coord = response.agreement.agreement_recipients[key].agreement_document_fields[fkey].x_coord;
            			var y_coord = response.agreement.agreement_recipients[key].agreement_document_fields[fkey].y_coord;

            			var randomStr = Math.floor(Math.random() * 1000000000);

            			// Left and Top position calculation
				  		// Percentage with 817 * 1057

				  		var leftPixel = (Math.round(x_coord) * canvas_width) / 100;
				  		var topPixel = (Math.round(y_coord) * canvas_height) / 100;

				  		leftPixel = leftPixel+'px';
				  		topPixel = topPixel+'px';

				  		var field_html = '';
				  		field_html += '<div id='+field_label+' field-id='+field_id+' field-type-id='+field_type_id+' field-category='+field_category+' name='+field_label+' class="drag ui-draggable ui-draggable-handle item_element field'+randomStr+'" page-no='+page_no+' data-id='+randomStr+' data-agreement-id='+agreement_id+' data-action-id='+agreement_recipient_id+' data-role="'+role+'" data-name="'+recipient_name+'" data-signing-order='+signing_order+' data-bg-color='+bg_color+' style="position:absolute;left:'+leftPixel+';top:'+topPixel+';border:1px dashed '+border_color+'; background-color: '+bg_color+'">';
            				field_html += '<div class="field_label" title='+field_label+'>'+field_label+'</div>';
            			field_html += '</div>';

            			$('div[page-no="'+page_no+'"][document-id="'+document_id+'"]').find('.text-layer').append(field_html);
			            $('div[page-no="'+page_no+'"][document-id="'+document_id+'"]').find('.item_element').draggable({
			                containment: 'parent'
			            });
            		}
            	}
            }
        }
    }
}

window.getFieldTypes = function() {
	openLoadingDialog($('#field_types_container'));
	$.ajax({
        method: 'GET',
        url: api_url+"fieldtypes",
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
	    async: true,
        success: function(response) {
        	display_error_message(response);
        	if(response.response_data.success == true) {
        		var html = '';
        		html += '<div id="fields-list" class="fields-list">';
        		for (var key in response.field_types) {
	                if (response.field_types.hasOwnProperty(key)) {
	                	var display_name = response.field_types[key].field_type_name;
	                	var field_type_id = response.field_types[key].id;
	                	var field_type_name = response.field_types[key].field_type_name;
	                	var field_category = response.field_types[key].field_category;

	                	var icon = '';
	                	var add_opacity = 'add_opacity';
	                	if(field_type_name == 'Signature') {
	                		icon = '<i class="far fa-edit field-icon"></i>';
	                		add_opacity = '';
	                	} else if(field_type_name == 'Dropdown') {
	                		icon = '<i class="far fa-caret-square-down field-icon"></i>';
	                	} else if(field_type_name == 'Attachment') {
	                		icon = '<i class="fas fa-paperclip field-icon"></i>';
	                	} else if(field_type_name == 'Email') {
	                		icon = '<i class="far fa-envelope field-icon"></i>';
	                	} else if(field_type_name == 'Company') {
	                		icon = '<i class="far fa-building field-icon"></i>';
	                	} else if(field_type_name == 'Date') {
	                		icon = '<i class="far fa-calendar-alt field-icon"></i>';
	                		add_opacity = '';
	                	} else if(field_type_name == 'Textfield') {
	                		icon = '<i class="fas fa-font field-icon"></i>';
	                		display_name = 'Text';
	                	} else if(field_type_name == 'Jobtitle') {
	                		icon = '<i class="fas fa-font field-icon"></i>';
	                		display_name = 'Job Title';
	                	} else if(field_type_name == 'Initial') {
	                		icon = '<i class="fas fa-signature field-icon"></i>';
	                	} else if(field_type_name == 'Name') {
	                		icon = '<i class="fas fa-male field-icon"></i>';
	                		display_name = 'Name';
	                		add_opacity = '';
	                	} else if(field_type_name == 'Stamp') {
	                		icon = '<i class="fas fa-stamp field-icon"></i>';
	                	} else if(field_type_name == 'Checkbox') {
	                		icon = '<i class="far fa-check-square field-icon"></i>';
	                	} else if(field_type_name == 'Radiogroup') {
	                		icon = '<i class="far fa-dot-circle field-icon"></i>';
	                		display_name = 'Radio';
	                	}

						html += '<div class="field-li '+add_opacity+'">';
							html += '<div id="'+field_type_name+'" field-type-id="'+field_type_id+'" field-category="'+field_category+'" name="'+field_type_name+'" class="drag field-li-view">';
									html += '<i class="fas fa-grip-vertical icon-move drag-icon"></i>';
									html += '<div class="field_label" title="'+field_type_name+'">'+display_name+'</div>';
									html += icon;
							html += '</div>';
						html += '</div>';
					}
				}
				html += '</div>';

				$('#field_types_container').html(html);

				$(".field-li-view").draggable({
			  		helper: "clone",
			  		cursor: 'move',
				    tolerance: 'fit',
				    revert: true,
				    start: function( event, ui ) {
				    	var border_color = $('#recipients_data_container').find('div.recipient_row_bg').attr('data-border-color');
				    	$(ui.helper).css({'border': '1px dashed '+border_color});
				    	$(ui.helper).height(35);
				    	$(ui.helper).css('line-height','35px !important');
				    	$(ui.helper).find('div.field_label').css({'position' : 'relative','top': '20%'});

				    	$(ui.helper).find('.drag-icon').remove();
						$(ui.helper).find('.field-icon').remove();
				    }
			    });

				// Change color of fields section
			    /*setTimeout(function(){
					var border_color = $('#recipients_data_container').find('div.recipient_row_bg').attr('data-border-color');
					$('#field_header').css({'background': border_color, 'color': 'white'});
					$('.field-li-view').css({'border': '1px solid '+border_color});
					$('.field-li-view .drag-icon').css({'color': border_color});
					$('.field-li-view .field-icon').css({'background-color': border_color});
				}, 500);*/
            }
        }
    });
}

window.sendDocumentForReview = function(agreement_id) {
	var user_id = user.user_details.user_id;
	var data = 'user_id='+user_id+'&agreement_id='+agreement_id;
	var url = api_url+"agreements/send-document-for-review";
	$.ajax({
        method: 'POST',
        url: url,
        data: data,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(data) {
            display_error_message(data);
            if(data.response_data.success == true) {
        		openLoadingDialog($('#main_container'));
				$('#main_container').load('components/template.html', function(){
					window.history.pushState("", "", '?action=template');
					$('a[href="#t-agreements"]').click();
				});
            } else {
            	$('.document_send_for_review').removeClass('add_opacity');
            }
        }
    });
}

window.getPendingReviewDocument = function() {
	$.ajax({
	    method: 'POST',
	    url: api_url+"agreements/get-pending-review-document",
		data: 'user_id='+user.user_details.user_id,
	    beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
	    success: function(response) {
	        if(response.response_data.success == true) {
	        	var notification_html = "";
		        if(response.agreementRecipients == null) {
		        	//html += '<a href="javascript:void(0)" class="list-group-item list-group-item-action border-0"><div class="fs-16">'+response.response_data.message+'</div></a>';
			    } else {
			    	//$('#document_pending_review_section').removeClass('d-none');
			    	for (var key in response.agreementRecipients) {
			            if (response.agreementRecipients.hasOwnProperty(key)) {

			            	var name = stripHtml(response.agreementRecipients[key].property.name);
			            	var address = '';
			                if(response.agreementRecipients[key].property != null && response.agreementRecipients[key].property.address != null) {
			                	address = response.agreementRecipients[key].property.address.address_line1+', '+response.agreementRecipients[key].property.address.address_line2+'<br>'+response.agreementRecipients[key].property.address.city+', '+response.agreementRecipients[key].property.address.state+' '+response.agreementRecipients[key].property.address.zip_code;
			                }

							notification_html += '<div id="agreement_'+response.agreementRecipients[key].agreement_id+'" class="list-group-item document_review_card">';
							    notification_html += '<div class="row no-gutters align-items-center">';
							        notification_html += '<div class="col-2"> <img src="icon/notification/bank.svg" class="filter-7"> </div>';
							        notification_html += '<div class="col-10">';
							            notification_html += '<div class="text-dark">'+response.agreementRecipients[key].master_agreement.master_agreement_name+'</div>';
							            notification_html += '<div class="text-muted small mt-1">'+address+'</div>';
							            notification_html += '<div class="mt-2 mb-2">Document <strong>'+response.agreementRecipients[key].master_agreement.master_agreement_name+'</strong> available for your review. Click link below to open document</div>';
							            notification_html += '<button data-ticket-id='+response.agreementRecipients[key].agreement.ticket_id+' data-property-id='+response.agreementRecipients[key].property_id+' data-agreement-id='+response.agreementRecipients[key].agreement.id+' data-agreement-recipient-id='+response.agreementRecipients[key].id+' type="button" class="btn btn-orange review_document">Review Document</button>';
							        notification_html += '</div>';
							    notification_html += '</div>';
							notification_html += '</div>';
			            }
			        }
			    }
		        //$('#document_pending_review_section').html(html);
		        $('#notification_container').append(notification_html);
	        } else {
	        	display_error_message(response);
	        }
	    }
	});
}

window.getDocumentForReview = function(property_id,agreement_id) {
	var user_id = user.user_details.user_id;
	var data = 'user_id='+user_id+'&property_id='+property_id+'&agreement_id='+agreement_id;
	var url = api_url+"agreements/get-document-for-review";
	$.ajax({
        method: 'POST',
        url: url,
        data: data,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
            display_error_message(response);
            if(response.response_data.success == true) {
        		$('#document_url').val(response.pdfpath);
        		$('#d_name').html(response.agreementDocument.master_agreement.master_agreement_name);
        		$('#document_name').val(response.agreementDocument.master_agreement.master_agreement_name);
            }
        }
    });
}

window.acceptDocument = function(agreement_recipient_id,agreement_id,property_id) {
	var user_id = user.user_details.user_id;
	var data = 'user_id='+user_id+'&agreement_recipient_id='+agreement_recipient_id+'&agreement_id='+agreement_id+'&property_id='+property_id;
	var url = api_url+"agreements/accept-document";
	$.ajax({
        method: 'POST',
        url: url,
        data: data,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
            display_error_message(response);
            if(response.response_data.success == true) {
        		$('#agreement_'+agreement_id).remove();
        		$('#viewDocumentModal').modal('hide');
        		var notification = parseInt($('#notification_count').text());
	        	var total_notification = notification - 1;
	        	$('#notification_indicator, #notification_count').text(total_notification);
            }
        }
    });
}

window.downloadDraftDocument = function(agreement_id, property_id) {
	var user_id = user.user_details.user_id;
	var data = 'user_id='+user_id+'&agreement_id='+agreement_id+'&property_id='+property_id;
	var url = api_url+"agreements/download-draft-document";
	$.ajax({
        method: 'POST',
        url: url,
        data: data,
        cache: false,
        xhr: function () {
            var xhr = new XMLHttpRequest();
            xhr.onreadystatechange = function () {
                if (xhr.readyState == 2) {
                    if (xhr.status == 200) {
                        xhr.responseType = "blob";
                    } else {
                        xhr.responseType = "text";
                    }
                }
            };
            return xhr;
        },
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
            //Convert the Byte Data to BLOB object.
            var blob = new Blob([response], { type: "application/octetstream" });
            //Check the Browser type and download the File.
            var fname = $('#d_name').text();
            var fileName = fname+'.pdf';
            var isIE = false || !!document.documentMode;
            if (isIE) {
                window.navigator.msSaveBlob(blob, fileName);
            } else {
                var url = window.URL || window.webkitURL;
                var link = url.createObjectURL(blob);
                var a = $("<a />");
                a.attr("download", fileName);
                a.attr("href", link);
                $("body").append(a);
                a[0].click();
                $("body").remove(a);
            }
        }
    });
}

window.sendDocumentForSignature = function(agreement_id, property_id) {
	var user_id = user.user_details.user_id;
	var data = 'user_id='+user_id+'&agreement_id='+agreement_id+'&property_id='+property_id;
	var url = api_url+"agreements/send-document-for-signature";
	$.ajax({
        method: 'POST',
        url: url,
        data: data,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(data) {
            display_error_message(data);
            if(data.response_data.success == true) {
        		openLoadingDialog($('#main_container'));
				$('#main_container').load('components/template.html', function(){
					window.history.pushState("", "", '?action=template');
					$('a[href="#t-agreements"]').click();
				});
            } else {
            	$('.document_send_for_signature').removeClass('add_opacity');
            }
        }
    });
}

window.getPendingSignatureDocument = function() {
	$.ajax({
	    method: 'POST',
	    url: api_url+"agreements/get-pending-signature-document",
		data: 'user_id='+user.user_details.user_id+'&user_service_name='+user_service_name,
	    beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
	    success: function(response) {
	        if(response.response_data.success == true) {
	        	var notification_html = "";
		        if(response.agreementRecipients == null) {
		        	//notification_html += '<a href="javascript:void(0)" class="list-group-item list-group-item-action border-0"><div class="fs-16">'+response.response_data.message+'</div></a>';
			    } else {
			    	//$('#document_pending_signature_section').removeClass('d-none');
			    	for (var key in response.agreementRecipients) {
			            if (response.agreementRecipients.hasOwnProperty(key)) {
			            	var name = stripHtml(response.agreementRecipients[key].property.name);
			            	var address = '';
			                if(response.agreementRecipients[key].property != null && response.agreementRecipients[key].property.address != null) {
			                	address = response.agreementRecipients[key].property.address.address_line1+', '+response.agreementRecipients[key].property.address.address_line2+'<br>'+response.agreementRecipients[key].property.address.city+', '+response.agreementRecipients[key].property.address.state+' '+response.agreementRecipients[key].property.address.zip_code;
			                }

							notification_html += '<div id="agreement_signature_'+response.agreementRecipients[key].agreement_id+'" class="list-group-item document_review_card">';
							    notification_html += '<div class="row no-gutters align-items-center">';
							        notification_html += '<div class="col-2"> <img src="icon/notification/bank.svg" class="filter-7"> </div>';
							        notification_html += '<div class="col-10">';
							            notification_html += '<div class="text-dark">'+response.agreementRecipients[key].master_agreement.master_agreement_name+'</div>';
							            notification_html += '<div class="text-muted small mt-1">'+address+'</div>';
							            notification_html += '<div class="mt-2 mb-2">Document <strong>'+response.agreementRecipients[key].master_agreement.master_agreement_name+'</strong> available for your review. Click link below to open document</div>';
							            notification_html += '<button data-ticket-id='+response.agreementRecipients[key].agreement.ticket_id+' data-property-id='+response.agreementRecipients[key].property_id+' data-agreement-id='+response.agreementRecipients[key].agreement.id+' data-agreement-recipient-id='+response.agreementRecipients[key].id+' type="button" class="btn btn-orange sign_document">Sign Document</button>';
							        notification_html += '</div>';
							    notification_html += '</div>';
							notification_html += '</div>';
			            }
			        }
			    }
		        //$('#document_pending_signature_section').html(html);
		        $('#notification_container').append(notification_html);
	        } else {
	        	display_error_message(response);
	        }
	    }
	});
}

window.getDocumentForSignature = function(property_id,agreement_id,agreement_recipient_id) {
	var user_id = user.user_details.user_id;
	var data = 'user_id='+user_id+'&property_id='+property_id+'&agreement_id='+agreement_id+'&agreement_recipient_id='+agreement_recipient_id;
	var url = api_url+"agreements/get-document-for-signature";
	$.ajax({
        method: 'POST',
        url: url,
        data: data,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
            display_error_message(response);
            if(response.response_data.success == true) {
            	$('#selected_document_id').val(response.agreementDocument.id);
            	$('#document_name').val(response.agreementDocument.master_agreement.master_agreement_name);
        		$('#document_url').val(response.pdfpath);
        		$('#d_name').html(response.agreementDocument.master_agreement.master_agreement_name);
            }
        }
    });
}

/* Status Master code start */

var $statusMasterForm;
window.addStatusMasterValidation = function() {
	$statusMasterForm = $("#status_master_add_form, #status_master_edit_form");
	$statusMasterForm.validate({
	    errorPlacement: function errorPlacement(error, element) {
	        $(element).parents(".form-group").append(error.addClass("invalid-feedback small d-block"))
	    },
	    highlight: function(element) {
	        $(element).addClass("is-invalid");
	    },
	    unhighlight: function(element) {
	        $(element).removeClass("is-invalid");
	    },
	    rules: {
	        "constant_name": { required: true},
	        "label": { required: true},
	        "message": { required: true, email: true},
	        "description": { required: true},
	        "transaction_type_id": { required: true}
	    },
	    messages: {
	        "constant_name": { required: ''},
	        "label": { required: ''},
	        "message": { required: ''},
	        "description": { required: ''},
	        "transaction_type_id": { required: ''}
	    }
	});
}

window.getStatusMaster = function() {
	$.ajax({
        method: 'POST',
        url: api_url+"status-masters",
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	display_error_message(response);
        	if(response.response_data.success == true) {
        		/*var html = '';
            	for (var key in response.webhookEvents) {
	                if (response.webhookEvents.hasOwnProperty(key)) {
	                	html += '<tr>';
                            html += '<td>'+response.webhookEvents[key].id+'</td>';
                            html += '<td>'+response.webhookEvents[key].transferID+'</td>';
                            html += '<td>'+response.webhookEvents[key].webhookID+'</td>';
                            html += '<td>'+response.webhookEvents[key].resourceID+'</td>';
                            html += '<td>'+response.webhookEvents[key].topic+'</td>';
                            html += '<td>'+response.webhookEvents[key].timestamp+'</td>';
                            html += '<td>'+response.webhookEvents[key].self+'</td>';
                            html += '<td>'+response.webhookEvents[key].account+'</td>';
                            html += '<td>'+response.webhookEvents[key].resource+'</td>';
                            html += '<td>'+response.webhookEvents[key].customer+'</td>';
                            html += '<td>'+response.webhookEvents[key].created+'</td>';
                        html += '</tr>';
	                }
	            }
	            $('#dwolla_data_container').html(html);
	            $("#dwolla-webhook-datatable").DataTable({
				    responsive: true
				});*/
            }
        }
    });
}

window.saveStatusMasterData = function() {
	if($statusMasterForm.valid()){
		$('.save_status_master_data').attr('disabled',true);
		
		var constant_name = $('#constant_name').val();
		var label = $('#label').val();
		var message = $('#message').val();
		var description = $('#description').val();
		var transaction_type_id = $('#transaction_type_id').val();
		
		var data = 'constant_name='+constant_name+'&label='+label+'&message='+message+'&description='+description+'&transaction_type_id='+transaction_type_id;
		
        $.ajax({
            method: 'POST',
            url: api_url+"status-masters/add",
            data: data,
            beforeSend: function(xhr) {
		        xhr.setRequestHeader('Authorization', accessToken);
		    },
            success: function(response) {
                display_error_message(response);
                $('.save_status_master_data').attr('disabled',false);
                if(response.response_data.success == true) {
                	
                }
            }
        });
    }
}

window.getUserActivities = function(user_id) {
	var tz = jstz.determine();
	var timezone = tz.name();
	$.ajax({
        method: 'POST',
        url: api_url+"user-activities/list",
        data:'user_id='+user.user_details.user_id+'&user_timezone='+timezone,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	display_error_message(response);
        	if(response.response_data.success == true) {
        		var html = '';
            	for (var key in response.userActivities) {
	                if (response.userActivities.hasOwnProperty(key)) {
	                	html += '<div class="fs-16 bold-black mt-3">'+key+'</div>';
	                	for (var jkey in response.userActivities[key]) {
	                		var username = '';
	                		if(response.userActivities[key][jkey].user != null) {
	                			username = response.userActivities[key][jkey].user.first_name+' '+response.userActivities[key][jkey].user.last_name
	                		}
		                	var message = response.userActivities[key][jkey].message;
		                	var created1 = response.userActivities[key][jkey].created1;
		                	html += '<div class="m-3">';
			                    html += '<div class="media">';
			                        html += '<img src="icon/avatar.jpg" width="36" height="36" class="rounded-circle mr-2" alt="Ashley Briggs">';
			                        html += '<div class="media-body mt-2">';
			                            html += '<small class="float-right text-navy">'+created1+'</small>';
			                            html += '<strong>'+username+'</strong> '+message;
			                        html += '</div>';
			                    html += '</div>';
				            html += '</div>';
					    }
	                }
	            }
	            if(user_id > 0) {
	            	$('#audit_data_container').html(html);
	            } else {
	            	$('#activities-container').html(html);
	            }
            }
        }
    });
}

/*window.getLinkedAccountArray = function(user_id) {
	$.ajax({
        method: 'POST',
        url: api_url+"linked-accounts/list",
        data:'user_id='+user_id,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	var html = '';
        	if(response.response_data.success == true) {
        		var html = "";
                for (var key in response.linkedAccounts) {
	                if (response.linkedAccounts.hasOwnProperty(key)) {
	                	var name = response.linkedAccounts[key].name+' ('+response.linkedAccounts[key].email+') ('+response.linkedAccounts[key].services+')';
				  		html += '<option value="'+response.linkedAccounts[key].id+'">'+name+'</option>';
				  	}
				}
				$('#linked_account_dd').html(html);
            } else {
            	display_error_message(response);
            }
        }
    });
}*/

window.getLinkedAccount = function() {
	var tz = jstz.determine();
	var timezone = tz.name();
	$.ajax({
        method: 'POST',
        url: api_url+"linked-accounts",
        data:'user_id='+user.user_details.user_id+'&user_timezone='+timezone,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	var head_html = '';
        	var html = '';
        	if(response.response_data.success == true) {
        		html += '<table id="linked_account_table" class="table table-striped" style="width:100%">';
        			html += '<thead>';
	        			html += '<tr>';
	        			html += '<th>Id</th>';
	        			html += '<th>From</th>';
	        			html += '<th>To</th>';
	        			html += '<th>Services</th>';
	        			html += '<th>Created</th>';
	        			html += '<th>Status</th>';
	        			html += '<th>Action</th>';
	        			html += '</tr>';
	        		html += '</thead>';
        			html += '<tbody>';
        			if(response.TotalLinkedAccounts == 0) {
        				html += '<tr>';
		                    html += '<td class="text-center" colspan="7"><strong>'+response.response_data.message+'</strong></td>';
	                    html += '</tr>';
        			} else {
        				head_html += '<div class="dropdown-divider"></div>';
        				head_html += '<div class="fs-16 bold-grey pl-4">Linked Accounts</div>';
        				head_html += '<div class="dropdown-divider"></div>';
		    			for (var key in response.linkedAccounts) {
			                if (response.linkedAccounts.hasOwnProperty(key)) {
			                	var from_user = response.linkedAccounts[key].from_user.first_name+' '+response.linkedAccounts[key].from_user.last_name+'<br><small>'+response.linkedAccounts[key].from_user.email+'</small>';
			                	var to_user = response.linkedAccounts[key].to_user.first_name+' '+response.linkedAccounts[key].to_user.last_name+'<br><small>'+response.linkedAccounts[key].to_user.email+'</small>';
			                	html += '<tr>';
				                    html += '<td>'+response.linkedAccounts[key].id+'</td>';
				                    html += '<td>'+from_user+'</td>';
				                    html += '<td>'+to_user+'</td>';
				                    html += '<td>'+response.linkedAccounts[key].services+'</td>';
				                    html += '<td>'+response.linkedAccounts[key].created+'</td>';
				                    html += '<td>'+response.linkedAccounts[key].status_label+'</td>';
				                    html += '<td><a href="javascript:void(0)" data-id="'+response.linkedAccounts[key].id+'" class="unlink_account">Unlink</a></td>';
			                    html += '</tr>';

			                    var linked_user = response.linkedAccounts[key].to_user.first_name+' '+response.linkedAccounts[key].to_user.last_name+' ('+response.linkedAccounts[key].services+')<br><small>'+response.linkedAccounts[key].to_user.email+'</small>';
			                    head_html += '<div data-id="'+response.linkedAccounts[key].id+'" data-email="'+response.linkedAccounts[key].to_user.email+'" class="dropdown-item bold-grey switch_user">';
			                    	head_html += linked_user;
			                    	head_html += '<span><i data-type="down" class="float-right fas fa-fw fa-angle-down"></i></span>';
			                    head_html += '</div>';
			                }
			            }
			        }
		        	html += '</tbody>';
		        html += '</table>';

		        $('#linked_account_data_container').html(html);
		        $('#header_linked_account_container').html(head_html);

		        var linked_account_table = $("#linked_account_table").DataTable({
	            	dom: 'Bfrtip',
	    			autoWidth: true,
	            	paging: false,
	            	info: false,
				    scrollY: '650px',
				    scrollX: true,
			        scrollCollapse: true,
			        order: [[0,'desc']],
			        fixedColumns:   {
			            left: 2
			        },
			        buttons: [
			           {
			              extend: 'colvis',
			              text: "Columns Visibility ",
			              collectionLayout: 'two-column',
			              postfixButtons: ['colvisRestore'],
			           }
					],
					language: {
				        buttons: {
				          colvisRestore: "Select All"
				        }
				    },
					initComplete: function () {
			          	//console.log("The Table has been initialized!")
			        }
				});

				$('.buttons-colvis').removeClass('btn-secondary').addClass('btn-primary');
            } else {
            	display_error_message(response);
            }
        }
    });

    getFloatingActionButton('LinkedAccount', '#a-linked-account');
}

window.linkAccount = function() {
	$('.link_account_btn').attr('disabled', true);
	var user_id = user.user_details.user_id;
	var challenge = $('#login_challenge').val();
	var email = $('#login_email').val();
	var session = $('#login_session').val();
	var username = $('#login_username').val();
	var phone_number = $('#login_phone_number').val();

	var phone_digit_1 = $('#login-phone-digit-1').val();
	var phone_digit_2 = $('#login-phone-digit-2').val();
	var phone_digit_3 = $('#login-phone-digit-3').val();
	var phone_digit_4 = $('#login-phone-digit-4').val();
	var answer = phone_digit_1+phone_digit_2+phone_digit_3+phone_digit_4;

	var data = 'challenge='+challenge+'&email='+email+'&session='+session+'&username='+username+'&phone_number='+phone_number+'&answer='+answer+'&user_id='+user_id;
	var url = api_url+"linked-accounts/add";
	$.ajax({
        method: 'POST',
        url: url,
        data: data,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	display_error_message(response);
        	if(response.response_data.success == true) {
	    		$('#linkAccountModal').modal('hide');
	    		$('#link_form_container').html('');
	    		getMyAccountLinkedAccountDetailWidget();
	    	} else {
	    		$('.link_account_btn').attr('disabled', false);
	    	}
        }
    });
}

window.unlinkAccount = function(id, user_id) {
	$.ajax({
        method: 'POST',
        url: api_url+"linked-accounts/delete",
        data:'user_id='+user.user_details.user_id+'&id='+id,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	display_error_message(response);
        	if(response.response_data.success == true) {
        		getMyAccountLinkedAccountDetailWidget();
            }
        }
    });
}

/*window.loadMyAccountOverviewData = function() {
	openLoadingDialog($('#property_block_container'));
	if(user_service_name == po_role) {
		$('#property_block_container').load('components/myaccount/po_overview.html');
	} else if(user_service_name == t_role) {
		$('#property_block_container').load('components/myaccount/t_overview.html');
	} else if(user_service_name == sa_role) {
		$('#property_block_container').html('No data available');
	}
}*/

window.getUserSubscriptionData = function() {
	if(user_service_name == po_role) {
		$('#myaccount_po_tiles').removeClass('d-none');
		openLoadingDialog($('#total_properties_container'));
		openLoadingDialog($('#current_monthly_bill_container'));
		openLoadingDialog($('#next_payment_due_container'));
		openLoadingDialog($('#billing_cycle_container'));
	} else if(user_service_name == t_role) {
		$('#myaccount_t_tiles').removeClass('d-none');
		openLoadingDialog($('#tenant_total_properties_container'));
		openLoadingDialog($('#current_monthe_rent_container'));
		openLoadingDialog($('#total_pending_amount_container'));
	} else {
		return false;
	}
	
	var user_id = user.user_details.user_id;
	var type = '';
	$.ajax({
        method: 'POST',
        url: api_url+"subscription-plans/get-user-subscription-data",
        data:"user_id="+user_id+'&user_service_name='+user_service_name+'&type='+type,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	if(response.response_data.success == true) {
        		if(user_service_name == po_role) {
		    		var html = '';
		    		setTimeout(function(){
			    		html += '<div class="card-header">';
				            html += '<span class="float-right text-right"><h3 id="total_property" class="mb-0 pt-2 font-weight-light">'+response.subscriptionPlans.length+'</h3></span>';
				            html += '<img src="icon/property.svg" class="filter-1">';
				            html += '<div class="mb-1 mt-4">';
				                html += '<h3 class="d-flex align-items-center mb-0 font-weight-light fs-18">Total Property <span data-id="po_total_property" data-title="Total Property" class="tile-view overview_detail"><i class="align-middle fas fa-fw fa-angle-down"></i></span></h3>';
				            html += '</div>';
				            html += '<div id="total_property_data_container" class="mb-1 mt-3"><!-- total property data --></div>';
				        html += '</div>';
						$('#total_properties_container').html(html);
					}, 1000);

					var html1 = '';
					setTimeout(function(){
	    		        html1 += '<div class="card-header">';
				            html1 += '<span class="float-right text-right"><h3 id="total_amount" class="mb-0 pt-2 font-weight-light">$'+response.total+'</h3></span>';
				            html1 += '<img src="icon/tiles/expense.svg" class="filter-5">';
				            html1 += '<div class="mb-1 mt-4">';
				                html1 += '<h3 class="d-flex align-items-center mb-0 font-weight-light fs-18">Current monthly bill <span data-id="po_current_monthly_bill" data-title="Current Monthly Bill" class="tile-view overview_detail"><i class="align-middle fas fa-fw fa-angle-down"></i></span></h3>';
				            html1 += '</div>';
				            html1 += '<div id="current_monthly_bill_data_container" class="mb-1 mt-3"><!-- total property data --></div>';
				        html1 += '</div>';
						$('#current_monthly_bill_container').html(html1);
					}, 1000);

					var html2 = '';
					setTimeout(function(){
	    		        html2 += '<div class="card-header">';
				            html2 += '<span class="float-right text-right"><h3 id="payment_due_date" class="mb-0 pt-2 font-weight-light">'+response.next_payment_due_date+'</h3></span>';
				            html2 += '<img src="icon/detail/year.svg" class="filter-3">';
				            html2 += '<div class="mb-1 mt-4">';
				                html2 += '<h3 class="d-flex align-items-center mb-0 font-weight-light fs-18">Next payment due</h3>';
				            html2 += '</div>';
				            html2 += '<div id="next_payment_due_data_container" class="mb-1 mt-3"><!-- next payment due data --></div>';
				        html2 += '</div>';
						$('#next_payment_due_container').html(html2);
					}, 1000);

					var html3 = '';
					setTimeout(function(){
	    		        html3 += '<div class="card-header">';
				            html3 += '<span class="float-right text-right"><h3 id="billing_cycle" class="mb-0 pt-2 font-weight-light">'+response.plan_name+'<br>'+response.billing_cycle+'</h3></span>';
				            html3 += '<img src="icon/tiles/cycle.svg" class="filter-4">';
				            html3 += '<div class="mb-1 mt-4">';
				                html3 += '<h3 class="d-flex align-items-center mb-0 font-weight-light fs-18">Plan name & Billing cycle</h3>';
				            html3 += '</div>';
				            html3 += '<div id="billing_cycle_data_container" class="mb-1 mt-3"><!-- billing cycle data --></div>';
				        html3 += '</div>';
						$('#billing_cycle_container').html(html3);
					}, 1000);

		    	} else if(user_service_name == t_role) {
		    		var html = '';
		    		setTimeout(function(){
			    		html += '<div class="card-header">';
				            html += '<span class="float-right text-right"><h3 id="total_property" class="mb-0 pt-2 font-weight-light">'+response.totalProperty+'</h3></span>';
				            html += '<img src="icon/tent.svg" class="filter-5">';
				            html += '<div class="mb-1 mt-4">';
				                html += '<h3 class="d-flex align-items-center mb-0 font-weight-light fs-18">Total Property <span data-id="t_total_property" data-title="Total Property" class="tile-view overview_detail"><i class="align-middle fas fa-fw fa-angle-down"></i></span></h3>';
				            html += '</div>';
				            html += '<div id="total_property_data_container" class="mb-1 mt-3"><!-- total property data --></div>';
				        html += '</div>';
						$('#tenant_total_properties_container').html(html);
					}, 1000);

					var html1 = '';
					setTimeout(function(){
	    		        html1 += '<div class="card-header">';
				            html1 += '<span class="float-right text-right"><h3 id="current_month_rent" class="mb-0 pt-2 font-weight-light">$'+response.currentMonthAmount+'</h3></span>';
				            html1 += '<img src="icon/vacant.svg" class="filter-6">';
				            html1 += '<div class="mb-1 mt-4">';
				                html1 += '<h3 class="d-flex align-items-center mb-0 font-weight-light fs-18">Current month rent <span data-id="t_current_month_rent" data-title="Current Month Rent" class="tile-view overview_detail"><i class="align-middle fas fa-fw fa-angle-down"></i></span></h3>';
				            html1 += '</div>';
				            html1 += '<div id="current_month_rent_data_container" class="mb-1 mt-3"><!-- total property data --></div>';
				        html1 += '</div>';
						$('#current_monthe_rent_container').html(html1);
					}, 1000);

					var html2 = '';
					setTimeout(function(){
	    		        html2 += '<div class="card-header">';
				            html2 += '<span class="float-right text-right"><h3 id="total_pending_amount" class="mb-0 pt-2 font-weight-light">$'+response.totalPendingAmount+'</h3></span>';
				            html2 += '<img src="icon/rent.svg" class="filter-7">';
				            html2 += '<div class="mb-1 mt-4">';
				                html2 += '<h3 class="d-flex align-items-center mb-0 font-weight-light fs-18">Total pending amount <span data-id="t_total_pending_amount" data-title="Total Pending Amount" class="tile-view overview_detail"><i class="align-middle fas fa-fw fa-angle-down"></i></span></h3>';
				            html2 += '</div>';
				            html2 += '<div id="total_pending_amount_data_container" class="mb-1 mt-3"><!-- next payment due data --></div>';
				        html2 += '</div>';
						$('#total_pending_amount_container').html(html2);
					}, 1000);
		    	}
	    	}
        }
    });
}

window.getMyAccountOverviewDetail = function(type, container) {
	var user_id = user.user_details.user_id;
	$.ajax({
        method: 'POST',
        url: api_url+"subscription-plans/get-user-subscription-data",
        data:"user_id="+user_id+'&user_service_name='+user_service_name+'&type='+type,
        beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
        success: function(response) {
        	if(response.response_data.success == true) {
        		var html = '';

        		if(type == 'po_current_monthly_bill') {
        			html += '<div class="fixTableHead" data-simplebar>';
	        		html += '<table id="transaction_table" class="table" style="width:100%">';
	        			html += '<thead>';
		        			html += '<tr>';
		        			html += '<th class="black fs-14">Price Per Month</th>';
		        			html += '<th class="black fs-14">Coupon</th>';
		        			html += '<th class="black fs-14 text-right">Amount</th>';
		        			html += '</tr>';
		        		html += '</thead>';
	        			html += '<tbody>';
	        			if(response.subscriptionPlans.length == 0) {
	        				html += '<tr>';
			                    html += '<td class="text-center" colspan="6"><strong>'+response.response_data.message+'</strong></td>';
		                    html += '</tr>';
	        			} else {
	        				for (var key in response.subscriptionPlans) {
				                if (response.subscriptionPlans.hasOwnProperty(key)) {
				                	var msg_class = response.subscriptionPlans[key].coupon_message_color;
				                	html += '<tr class="bg-light">';
					                    html += '<td colspan="3" class="fs-14"><strong>'+response.subscriptionPlans[key].property_name+'</strong></td>';
				                    html += '</tr>';

	                    			html += '<tr>';
	                    				html += '<td><small class="bold-black text-right">$'+response.subscriptionPlans[key].price_per_month+'</small></td>';
					                    html += '<td class="text-center"><small class="bold-black text-right">'+response.subscriptionPlans[key].coupon_code+'<br><span class="'+msg_class+'">'+response.subscriptionPlans[key].coupon_message+'</span></small></td>';
					                    html += '<td class="text-right"><small class="bold-black text-right">$'+response.subscriptionPlans[key].finalAmont+'</small></td>';
				                    html += '</tr>';
				                }
				            }

				            html += '<tr class="bg-light bold-black fs-14">';
				            	html += '<td colspan="2" class="text-left">Total</td>';
			                    html += '<td class="text-right"><strong>$'+response.total+'</strong></td>';
		                    html += '</tr>';
				        }
			        html += '</tbody>';
			        html += '</table>';
			        html += '</div>';
        		} else if(type == 'po_total_property') {
		    		html += '<div class="fixTableHead" data-simplebar>';
	        		html += '<table id="transaction_table" class="table table-striped" style="width:100%">';
	        			html += '<thead>';
		        			html += '<tr>';
		        			html += '<th class="black fs-14">Property Name</th>';
		        			html += '</tr>';
		        		html += '</thead>';
	        			html += '<tbody>';
	        			if(response.subscriptionPlans.length == 0) {
	        				html += '<tr>';
			                    html += '<td class="text-center" colspan="6"><strong>'+response.response_data.message+'</strong></td>';
		                    html += '</tr>';
	        			} else {
			    			for (var key in response.subscriptionPlans) {
				                if (response.subscriptionPlans.hasOwnProperty(key)) {
				                	html += '<tr>';
					                    html += '<td><strong>'+response.subscriptionPlans[key].property_name+'</strong></td>';
				                    html += '</tr>';
				                    html += '<tr>';
					                    html += '<td class="text-right"><small class="bold-black">'+response.subscriptionPlans[key].tenant_name+'</small></td>';
				                    html += '</tr>';
				                }
				            }
				        }
			        html += '</tbody>';
			        html += '</table>';
			        html += '</div>';
		    	} else if(type == 't_current_month_rent' || type == 't_total_pending_amount') {
		    		html += '<div class="fixTableHead" data-simplebar>';
	        		html += '<table id="transaction_table" class="table" style="width:100%">';
	        			html += '<thead>';
		        			html += '<tr>';
		        			html += '<th class="black fs-14">Name</th>';
		        			html += '<th class="black fs-14">Due Date</th>';
		        			html += '<th class="black fs-14 text-right">Amount</th>';
		        			html += '</tr>';
		        		html += '</thead>';
	        			html += '<tbody>';
	        			if(response.arrSP.length == 0) {
	        				html += '<tr>';
			                    html += '<td class="text-center" colspan="6"><strong>'+response.response_data.message+'</strong></td>';
		                    html += '</tr>';
	        			} else {
			    			for (var key in response.propertyAray) {
				                if (response.propertyAray.hasOwnProperty(key)) {
				                	html += '<tr class="bg-light">';
					                    html += '<td colspan="3" class="fs-14"><strong>'+key+'</strong></td>';
				                    html += '</tr>';

				                    if(response.propertyAray[key]) {
										for (var wkey in response.propertyAray[key]) {
											if (response.propertyAray[key].hasOwnProperty(wkey)) {
												html += '<tr>';
								                    html += '<td class="text-center">-</td>';
								                    html += '<td><small class="bold-black">'+response.propertyAray[key][wkey].payment_due_date+'</small></td>';
								                    html += '<td class="text-right"><small class="bold-black">$'+response.propertyAray[key][wkey].formated_total+'</small></td>';
							                    html += '</tr>';
											}
										}
									}
				                }
				            }

				            var total = 0;
				            if(type == 'current_month_rent') {
				            	total = response.currentMonthAmount;
				            } else {
				            	total = response.totalPendingAmount;
				            }

				            html += '<tr class="bg-light bold-black fs-14">';
				            	html += '<td colspan="2" class="text-left">Total</td>';
			                    html += '<td class="text-right"><strong>$'+total+'</strong></td>';
		                    html += '</tr>';
				        }
			        html += '</tbody>';
			        html += '</table>';
			        html += '</div>';
		    	} else if(type == 't_total_property') {
		    		html += '<div class="fixTableHead" data-simplebar>';
	        		html += '<table id="transaction_table" class="table table-striped" style="width:100%">';
	        			html += '<thead>';
		        			html += '<tr>';
		        			html += '<th class="black fs-14">Property Name</th>';
		        			html += '</tr>';
		        		html += '</thead>';
	        			html += '<tbody>';
	        			if(response.arrSP.length == 0) {
	        				html += '<tr>';
			                    html += '<td class="text-center" colspan="6"><strong>'+response.response_data.message+'</strong></td>';
		                    html += '</tr>';
	        			} else {
			    			for (var key in response.propertyAray) {
				                if (response.propertyAray.hasOwnProperty(key)) {
				                	html += '<tr>';
					                    html += '<td><strong>'+key+'</strong></td>';
				                    html += '</tr>';

				                    if(response.propertyAray[key]) {
										for (var wkey in response.propertyAray[key]) {
											if (response.propertyAray[key].hasOwnProperty(wkey)) {
												if(wkey == 0) {
													html += '<tr>';
									                    html += '<td><small class="bold-black">'+response.propertyAray[key][wkey].property_address+'</small></td>';
								                    html += '</tr>';
								                }
											}
										}
									}
				                }
				            }
				        }
			        html += '</tbody>';
			        html += '</table>';
			        html += '</div>';
		    	}

		    	$(container).html(html);
	    	}
        }
    });
}

window.getPropertyList = function() {
	$.ajax({
	    url: api_url+"properties/list",
	    type: 'POST',
	    data: 'user_id='+user.user_details.user_id+'&user_service_name='+user_service_name,
	    beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
	    success: function(response) {
	        if(response.response_data.success == true) {
	            var html = "";
	            for (var key in response.propertyData) {
	                if (response.propertyData.hasOwnProperty(key)) {
	                    var name = response.propertyData[key].name;
	                    html += '<option value="'+response.propertyData[key].id+'">'+name+'</option>';
	                }
	            }
	        	$('#agreement_property_id').html(html);
	        }
	    }
	});
}

window.getPropertyListForSearch = function(open_for) {
	$.ajax({
	    url: api_url+"properties/list",
	    type: 'POST',
	    data: 'user_id='+user.user_details.user_id+'&user_service_name='+user_service_name,
	    beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
	    success: function(response) {
	        if(response.response_data.success == true) {
	            var html = "";
	            if(open_for == 'modal') {
	            	// Create aray of selected checkbox
		            var selectedProperty = [];
		            if(localStorage.getItem('filter_data') != null) {
		            	$('.selected_property_data').each( function(){
				            selectedProperty.push($.trim($(this).val()));
				        });
	            	} else {
	            		$('.property_search_checkbox:checked').each( function(){
				            selectedProperty.push($.trim($(this).val()));
				        });
	            	}

	            	var propertyData = response.propertyData;
		            propertyData.sort(function(a, b) {
					  return compareStrings(a.name, b.name);
					})
					response.propertyData = propertyData;
		            html += '<ul class="ul-search-modal list-unstyled">';
						var groupData = alphabetGroupCollection(response.propertyData, 'searchPropertyModal');
						for (var key in groupData) {
							if (groupData.hasOwnProperty(key)) {
								//html += '<li id="main_li_'+key+'" class="main_li container_'+key+'"><label class="bold-black">'+key+'</label></li>';
		                    	for (var jkey in groupData[key]) {
		                    		if (groupData[key].hasOwnProperty(jkey)) {
		                    			var checked = '';
		                    			if(selectedProperty.includes($.trim(groupData[key][jkey].id))) {
		                    				checked = 'checked';
		                    			}
		                    			var name = groupData[key][jkey].name;
		                    			var tenants = groupData[key][jkey].tenants;
				                    	html += '<li class="container_'+key+'"><label class="form-check"><input data-field="property" class="form-check-input property_search_checkbox search_modal_checkbox" name="property_search_checkbox" type="checkbox" value="'+groupData[key][jkey].id+'" data-tenants="'+tenants+'" data-modal="searchPropertyModal" data-id="'+groupData[key][jkey].id+'" '+checked+'><span class="form-check-label">'+name+'</span></label></li>';
				                    }
				                }
		                    }
		                }
					html += '</ul>';

		            $('#search_property_container').html(html);
				} else {
	                for (var key in response.propertyData) {
	                    if (response.propertyData.hasOwnProperty(key)) {
	                        var name = response.propertyData[key].name;
	                        var tenants = response.propertyData[key].tenants;
	                        var count = record_to_display - 1;
	                    	if(key > count && key == record_to_display) {
	                    		var record = response.propertyData.length - record_to_display;
	                    		html += '<li><label class="form-check"><span class="bold-black open_search_more_modal_box" data-field="property" data-type="searchPropertyModal" data-container="search_property_container"><a href="javascript:void(0)">'+record+' More</a></span></label></li>';
	                    		break;
                    		} else {
	                    		html += '<li><label class="form-check"><input data-field="property" class="form-check-input property_search_checkbox" name="property_search_checkbox" type="checkbox" value="'+response.propertyData[key].id+'" data-tenants="'+tenants+'" data-modal="searchPropertyModal" data-id="'+response.propertyData[key].id+'"><span class="form-check-label">'+name+'</span></label></li>';
	                    	}
	                    }
	                }
	            	$('#property-search').html(html);
	            }
	        }
	    }
	});
}

window.getTenantListForSearch = function(open_for) {
	$.ajax({
	    url: api_url+"tenants/list",
	    type: 'POST',
	    data: 'user_id='+user.user_details.user_id+'&user_service_name='+user_service_name,
	    beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
	    success: function(response) {
	        if(response.response_data.success == true) {
	            var html = "";
            	if(open_for == 'modal') {
            		
			        // Create aray of selected checkbox
		            var selectedTenant = [];
		            if(localStorage.getItem('filter_data') != null) {
		            	$('.selected_tenant_data').each( function(){
				            selectedTenant.push($.trim($(this).val()));
				        });
	            	} else {
	            		$('.tenant_search_checkbox:checked').each( function(){
				            selectedTenant.push($.trim($(this).val()));
				        });
	            	}

	            	var tenants = response.tenants;
		            tenants.sort(function(a, b) {
					  return compareStrings(a.name, b.name);
					})
					response.tenants = tenants;
					html += '<ul class="ul-search-modal list-unstyled">';
						var groupData = alphabetGroupCollection(response.tenants, 'searchTenantModal');
						for (var key in groupData) {
							if (groupData.hasOwnProperty(key)) {
								html += '<li id="main_li_'+key+'" class="main_li container_'+key+'"><label class="bold-black">'+key+'</label></li>';
		                    	for (var jkey in groupData[key]) {
		                    		if (groupData[key].hasOwnProperty(jkey)) {
		                    			var checked = '';
		                    			if(selectedTenant.includes($.trim(groupData[key][jkey].id))) {
		                    				checked = 'checked';
		                    			}
		                    			var name = groupData[key][jkey].name;
				                        var email = groupData[key][jkey].email;
				                        var properties = groupData[key][jkey].properties;
				                        html += '<li class="container_'+key+'"><label class="form-check"><input data-field="property" class="form-check-input tenant_search_checkbox search_modal_checkbox" name="tenant_search_checkbox" type="checkbox" value="'+groupData[key][jkey].id+'" data-properties="'+properties+'" data-modal="searchTenantModal" data-id="'+groupData[key][jkey].id+'" '+checked+'><span class="form-check-label">'+name+'<br>'+email+'</span></label></li>';
				                    }
				                }
		                    }
		                }
					html += '</ul>';
		            $('#search_tenant_container').html(html);
				} else {
	                for (var key in response.tenants) {
	                    if (response.tenants.hasOwnProperty(key)) {
	                        var name = response.tenants[key].name;
	                        var email = response.tenants[key].email;
	                        var properties = response.tenants[key].properties;
	                        var count = record_to_display - 1;
	                    	if(key > count && key == record_to_display) {
	                    		var record = response.tenants.length - record_to_display;
	                    		html += '<li><label class="form-check"><span class="bold-black open_search_more_modal_box" data-field="property" data-type="searchTenantModal" data-container="search_tenant_container"><a href="javascript:void(0)">'+record+' More</a></span></label></li>';
	                    		break;
                    		} 	else {
	                    		html += '<li><label class="form-check"><input data-field="property" class="form-check-input tenant_search_checkbox" name="tenant_search_checkbox" type="checkbox" value="'+response.tenants[key].id+'" data-properties="'+properties+'" data-modal="searchTenantModal" data-id="'+response.tenants[key].id+'"><span class="form-check-label">'+name+'<br>'+email+'</span></label></li>';
	                    	}
	                    }
	                }
	                $('#tenant-search').html(html);

	                if(user_service_name == t_role) {
	                	$('#search-by-tenant').addClass('d-none add_opacity');
	                }
	            }
	        }
	    }
	});
}

window.getPropertyLeaseListForSearch = function(open_for) {
	$.ajax({
	    url: api_url+"leases/list",
	    type: 'POST',
	    data: 'user_id='+user.user_details.user_id+'&user_service_name='+user_service_name,
	    beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
	    success: function(response) {
	        if(response.response_data.success == true) {
	            var html = "";
	            if(open_for == 'modal') {
	            	// Create aray of selected checkbox
		            var selectedLease = [];
		            if(localStorage.getItem('filter_data') != null) {
		            	$('.selected_lease_data').each( function(){
				            selectedLease.push($.trim($(this).val()));
				        });
	            	} else {
	            		$('.lease_search_checkbox:checked').each( function(){
				            selectedLease.push($.trim($(this).val()));
				        });
	            	}

	            	var leaseData = response.leaseData;
		            leaseData.sort(function(a, b) {
					  return compareStrings(a.start_date, b.start_date);
					})
					response.leaseData = leaseData;
		            html += '<ul class="ul-search-modal list-unstyled">';
						var groupData = alphabetGroupCollection(response.leaseData, 'searchLeaseModal');
						for (var key in groupData) {
							if (groupData.hasOwnProperty(key)) {
								//html += '<li id="main_li_'+key+'" class="main_li container_'+key+'"><label class="bold-black">'+key+'</label></li>';
		                    	for (var jkey in groupData[key]) {
		                    		if (groupData[key].hasOwnProperty(jkey)) {
		                    			var checked = '';
		                    			if(selectedLease.includes($.trim(groupData[key][jkey].name))) {
		                    				checked = 'checked';
		                    			}
		                    			var name = groupData[key][jkey].name;
		                    			var start_date = $.trim(groupData[key][jkey].start_date);
		                    			var end_date = $.trim(groupData[key][jkey].end_date);
				                    	html += '<li data-start="'+start_date+'" data-end="'+end_date+'" class="container_'+key+'"><label class="form-check"><input data-field="property" class="form-check-input lease_search_checkbox search_modal_checkbox" name="lease_search_checkbox" type="checkbox" value="'+name+'" data-modal="searchLeaseModal" data-id="'+name+'" '+checked+'><span class="form-check-label">'+name+'</span></label></li>';
				                    }
				                }
		                    }
		                }
					html += '</ul>';

		            $('#search_lease_container').html(html);
				} else {
	                for (var key in response.leaseData) {
	                    if (response.leaseData.hasOwnProperty(key)) {
	                        var date = response.leaseData[key].start_date+' - '+response.leaseData[key].end_date;
	                        var count = record_to_display - 1;
	                    	if(key > count && key == record_to_display) {
	                    		var record = response.leaseData.length - record_to_display;
	                    		html += '<li><label class="form-check"><span class="bold-black open_search_more_modal_box" data-field="property" data-type="searchLeaseModal" data-container="search_lease_container"><a href="javascript:void(0)">'+record+' More</a></span></label></li>';
	                    		break;
                    		} else {
	                    		html += '<li><label class="form-check"><input data-field="property" class="form-check-input lease_search_checkbox" name="lease_search_checkbox" type="checkbox" value="'+date+'" data-id="'+date+'"><span class="form-check-label">'+date+'</span></label></li>';
	                    	}
	                    }
	                }
	            	$('#lease-search').html(html);
	            }
	        }
	    }
	});
}

window.getPropertyTransactionListForSearch = function(type, open_for) {
	$.ajax({
	    url: api_url+"status-masters/list",
	    type: 'POST',
	    data: 'type='+type,
	    beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
	    success: function(response) {
	        if(response.response_data.success == true) {
	            var html = "";
	            if(open_for == 'modal') {

	            	// Create aray of selected checkbox
		            var selectedTransaction = [];
		            if(localStorage.getItem('filter_data') != null) {
		            	$('.selected_transaction_data').each( function(){
				            selectedTransaction.push($.trim($(this).val()));
				        });
	            	} else {
	            		$('.transaction_search_checkbox:checked').each( function(){
				            selectedTransaction.push($.trim($(this).val()));
				        });
	            	}

	            	// Create UL LI structure for display in modal
	            	var statusData = response.statusData;
		            statusData.sort(function(a, b) {
					  return compareStrings(a.label, b.label);
					});
					response.statusData = statusData;
					html += '<ul class="ul-search-modal list-unstyled">';
						var groupData = alphabetGroupCollection(response.statusData, 'searchTransactionModal');
						for (var key in groupData) {
							if (groupData.hasOwnProperty(key)) {
								html += '<li id="main_li_'+key+'" class="main_li container_'+key+'"><label class="bold-black">'+key+'</label></li>';
		                    	for (var jkey in groupData[key]) {
		                    		if (groupData[key].hasOwnProperty(jkey)) {
		                    			var checked = '';
		                    			if(selectedTransaction.includes($.trim(groupData[key][jkey].id))) {
		                    				checked = 'checked';
		                    			}
				                    	html += '<li class="container_'+key+'"><label class="form-check"><input data-field="property" class="form-check-input transaction_search_checkbox search_modal_checkbox" name="transaction_search_checkbox" type="checkbox" value="'+groupData[key][jkey].id+'" data-modal="searchTransactionModal" data-id="'+groupData[key][jkey].id+'" '+checked+'><span class="form-check-label">'+groupData[key][jkey].label+'</span></label></li>';
				                    }
				                }
		                    }
		                }
					html += '</ul>';
		            $('#search_transaction_status_container').html(html);
				} else {
					for (var key in response.statusData) {
	                    if (response.statusData.hasOwnProperty(key)) {
	                    	var count = record_to_display - 1;
	                    	if(key > count && key == record_to_display) {
	                    		var record = response.statusData.length - record_to_display;
	                    		html += '<li><label class="form-check"><span class="bold-black open_search_more_modal_box" data-field="property" data-type="searchTransactionModal" data-container="search_transaction_status_container"><a href="javascript:void(0)">'+record+' More</a></span></label></li>';
	                    		break;
	                    	} else {
	                    		html += '<li><label class="form-check"><input data-field="property" class="form-check-input transaction_search_checkbox" name="transaction_search_checkbox" type="checkbox" value="'+response.statusData[key].id+'" data-id="'+response.statusData[key].id+'"><span class="form-check-label">'+response.statusData[key].label+'</span></label></li>';
	                    	}
	                    }
	                }
		            $('#transaction-search').html(html);
				}
	        }
	    }
	});
}

window.getUserDataForSearch = function(open_for, field) {
	$.ajax({
	    url: api_url+"users/get-user-data",
	    type: 'POST',
	    data: 'user_id='+user.user_details.user_id+'&user_service_name='+user_service_name+'&field='+field,
	    beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
	    success: function(response) {
	        if(response.response_data.success == true) {
	            var name_html = "";
	            var email_html = "";
	            var phone_html = "";
	            var status_html = "";
	            if(open_for == 'modal') {
	            	if(field == 'name') {
		            	// Create aray of selected checkbox
			            var selectedUserName = [];
			            if(localStorage.getItem('filter_data') != null) {
			            	$('.selected_user_name_data').each( function(){
					            selectedUserName.push($.trim($(this).val()));
					        });
		            	} else {
		            		$('.user_name_search_checkbox:checked').each( function(){
					            selectedUserName.push($.trim($(this).val()));
					        });
		            	}

		            	var users = response.users;
			            users.sort(function(a, b) {
						  return compareStrings(a.first_name, b.first_name);
						});
						response.users = users;
			            name_html += '<ul class="ul-search-modal list-unstyled">';
							var groupData = alphabetGroupCollection(response.users, 'searchUserNameModal');
							for (var key in groupData) {
								if (groupData.hasOwnProperty(key)) {
									name_html += '<li id="main_li_'+key+'" class="main_li container_'+key+'"><label class="bold-black">'+key+'</label></li>';
			                    	for (var jkey in groupData[key]) {
			                    		if (groupData[key].hasOwnProperty(jkey)) {
			                    			var checked = '';
			                    			if(selectedUserName.includes($.trim(groupData[key][jkey].id))) {
			                    				checked = 'checked';
			                    			}
			                    			name_html += '<li class="container_'+key+'"><label class="form-check"><input data-field="user" class="form-check-input user_name_search_checkbox search_modal_checkbox" name="user_name_search_checkbox" type="checkbox" value="'+groupData[key][jkey].id+'" data-user-name="'+groupData[key][jkey].name+'" data-modal="searchUserNameModal" data-id="'+groupData[key][jkey].id+'" '+checked+'><span class="form-check-label">'+groupData[key][jkey].name+'</span></label></li>';
					                    }
					                }
			                    }
			                }
						name_html += '</ul>';
			            $('#search_user_name_container').html(name_html);
			        } else if(field == 'email') {
			        	// Create aray of selected checkbox
			            var selectedUserEmail = [];
			            if(localStorage.getItem('filter_data') != null) {
			            	$('.selected_user_email_data').each( function(){
					            selectedUserEmail.push($.trim($(this).val()));
					        });
		            	} else {
		            		$('.user_email_search_checkbox:checked').each( function(){
					            selectedUserEmail.push($.trim($(this).val()));
					        });
		            	}

		            	var users = response.users;
			            users.sort(function(a, b) {
						  return compareStrings(a.email, b.email);
						});
						response.users = users;
			            email_html += '<ul class="ul-search-modal list-unstyled">';
							var groupData = alphabetGroupCollection(response.users, 'searchUserEmailModal');
							for (var key in groupData) {
								if (groupData.hasOwnProperty(key)) {
									email_html += '<li id="main_li_'+key+'" class="main_li container_'+key+'"><label class="bold-black">'+key+'</label></li>';
			                    	for (var jkey in groupData[key]) {
			                    		if (groupData[key].hasOwnProperty(jkey)) {
			                    			var checked = '';
			                    			if(selectedUserEmail.includes($.trim(groupData[key][jkey].id))) {
			                    				checked = 'checked';
			                    			}
			                    			email_html += '<li class="container_'+key+'"><label class="form-check"><input data-field="user" class="form-check-input user_email_search_checkbox search_modal_checkbox" name="user_email_search_checkbox" type="checkbox" value="'+groupData[key][jkey].id+'" data-user-email="'+groupData[key][jkey].email+'" data-modal="searchUserEmailModal" data-id="'+groupData[key][jkey].id+'" '+checked+'><span class="form-check-label">'+groupData[key][jkey].email+'</span></label></li>';
					                    }
					                }
			                    }
			                }
						email_html += '</ul>';
			            $('#search_user_email_container').html(email_html);
			        } else if(field == 'phone') {
			        	// Create aray of selected checkbox
			            var selectedUserPhone = [];
			            if(localStorage.getItem('filter_data') != null) {
			            	$('.selected_user_phone_data').each( function(){
					            selectedUserPhone.push($.trim($(this).val()));
					        });
		            	} else {
		            		$('.user_phone_search_checkbox:checked').each( function(){
					            selectedUserPhone.push($.trim($(this).val()));
					        });
		            	}

		            	/*var users = response.users;
			            users.sort(function(a, b) {
						  return compareStrings(a.phone, b.phone);
						});
						response.users = users;*/
			            phone_html += '<ul class="ul-search-modal list-unstyled">';
							//var groupData = alphabetGroupCollection(response.users, 'searchUserPhoneModal');
							for (var key in response.users) {
								if (response.users.hasOwnProperty(key)) {
		                        	var phone = response.users[key].phone;
		                        	if(phone != null) {
		                        		phone = phone.split('+1');
		                        		phone = phone[1];
										var checked = '';
		                    			if(selectedUserPhone.includes($.trim(response.users[key].id))) {
		                    				checked = 'checked';
		                    			}
		                    			phone_html += '<li class="container_'+key+'"><label class="form-check"><input data-field="user" class="form-check-input user_phone_search_checkbox search_modal_checkbox" name="user_phone_search_checkbox" type="checkbox" value="'+response.users[key].id+'" data-user-phone="'+phone+'" data-modal="searchUserPhoneModal" data-id="'+response.users[key].id+'" '+checked+'><span class="form-check-label phone_number">'+phone+'</span></label></li>';
	                    			}
			                    }
			                }
						phone_html += '</ul>';
			            $('#search_user_phone_container').html(phone_html);
			            $('.phone_number').mask('(000) 000-0000');
			        }
				} else {
					if(field == 'name') {
						for (var key in response.users) {
		                    if (response.users.hasOwnProperty(key)) {
		                        var name = response.users[key].first_name+' '+response.users[key].last_name;
		                        var count = record_to_display - 1;

		                    	if(key > count && key == record_to_display) {
		                    		var record = response.users.length - record_to_display;
		                    		name_html += '<li><label class="form-check"><span class="bold-black open_search_more_modal_box" data-field="user" data-type="searchUserNameModal" data-container="search_user_name_container"><a href="javascript:void(0)">'+record+' More</a></span></label></li>';
		                    		break;
	                    		} else {
	                    			name_html += '<li><label class="form-check"><input data-field="user" class="form-check-input user_name_search_checkbox" name="user_name_search_checkbox" type="checkbox" value="'+response.users[key].id+'" data-user-name="'+name+'" data-modal="searchUserNameModal" data-id="'+response.users[key].id+'"><span class="form-check-label">'+name+'</span></label></li>';
		                    	}
		                    }
		                }
		            	$('#user-name-search').html(name_html);
					} else if(field == 'email') {
						for (var key in response.users) {
		                    if (response.users.hasOwnProperty(key)) {
		                        var email = response.users[key].email;
		                        var count = record_to_display - 1;

		                    	if(key > count && key == record_to_display) {
		                    		var record = response.users.length - record_to_display;
		                    		email_html += '<li><label class="form-check"><span class="bold-black open_search_more_modal_box" data-field="user" data-type="searchUserEmailModal" data-container="search_user_email_container"><a href="javascript:void(0)">'+record+' More</a></span></label></li>';
		                    		break;
	                    		} else {
	                    			email_html += '<li><label class="form-check"><input data-field="user" class="form-check-input user_email_search_checkbox" name="user_email_search_checkbox" type="checkbox" value="'+response.users[key].id+'" data-user-email="'+email+'" data-modal="searchUserEmailModal" data-id="'+response.users[key].id+'"><span class="form-check-label">'+email+'</span></label></li>';
		                    	}
		                    }
		                }
		                $('#user-email-search').html(email_html);
					} else if(field == 'phone') {
						for (var key in response.users) {
		                    if (response.users.hasOwnProperty(key)) {
		                        var phone = response.users[key].phone;
		                        phone = phone.split('+1');
		                        phone = phone[1];
		                        var count = record_to_display - 1;

		                    	if(key > count && key == record_to_display) {
		                    		var record = response.users.length - record_to_display;
		                    		phone_html += '<li><label class="form-check"><span class="bold-black open_search_more_modal_box" data-field="user" data-type="searchUserPhoneModal" data-container="search_user_phone_container"><a href="javascript:void(0)">'+record+' More</a></span></label></li>';
		                    		break;
	                    		} else {
	                    			if(phone != null) {
	                    				phone_html += '<li><label class="form-check"><input data-field="user" class="form-check-input user_phone_search_checkbox" name="user_phone_search_checkbox" type="checkbox" value="'+response.users[key].id+'" data-user-phone="'+phone+'" data-modal="searchUserPhoneModal" data-id="'+response.users[key].id+'"><span class="form-check-label phone_number">'+phone+'</span></label></li>';
	                    			}
		                    	}
		                    }
		                }
		                $('#user-phone-search').html(phone_html);
		                $('.phone_number').mask('(000) 000-0000');
					} else if(field == 'status') {
						status_html += '<li><label class="form-check"><input data-field="user" class="form-check-input user_status_search_checkbox" name="user_status_search_checkbox" type="checkbox" value="active" data-user-status="Active" data-modal="searchUserStatusModal"><span class="form-check-label">Active</span></label></li>';

		                status_html += '<li><label class="form-check"><input data-field="user" class="form-check-input user_status_search_checkbox" name="user_status_search_checkbox" type="checkbox" value="inactive" data-user-status="Inactive" data-modal="searchUserStatusModal"><span class="form-check-label">Inactive</span></label></li>';

		                status_html += '<li><label class="form-check"><input data-field="user" class="form-check-input user_status_search_checkbox" name="user_status_search_checkbox" type="checkbox" value="verified" data-user-status="Verified" data-modal="searchUserStatusModal"><span class="form-check-label">Verified</span></label></li>';

		                status_html += '<li><label class="form-check"><input data-field="user" class="form-check-input user_status_search_checkbox" name="user_status_search_checkbox" type="checkbox" value="unverified" data-user-status="Unverified" data-modal="searchUserStatusModal"><span class="form-check-label">Unverified</span></label></li>';

		                status_html += '<li><label class="form-check"><input data-field="user" class="form-check-input user_status_search_checkbox" name="user_status_search_checkbox" type="checkbox" value="optin" data-user-status="Optin" data-modal="searchUserStatusModal"><span class="form-check-label">Optin</span></label></li>';

		                status_html += '<li><label class="form-check"><input data-field="user" class="form-check-input user_status_search_checkbox" name="user_status_search_checkbox" type="checkbox" value="optout" data-user-status="Optout" data-modal="searchUserStatusModal"><span class="form-check-label">Optout</span></label></li>';
		                
		            	$('#user-status-search').html(status_html);
					}	                
	            }
	        }
	    }
	});
}

window.getPropertiesFilterData = function(data) {
	$.ajax({
        method: 'POST',
        url: api_url+"searchs/get-filter-data",
        data: data,
        beforeSend: function(xhr) {
            xhr.setRequestHeader('Authorization', accessToken);
        },
        success: function(response) {
            if(response.response_data.success == true) {
            	$('#navbar_role_name').html('Properties');
            	localStorage.setItem('filter_data', data);
                loadPropertyCard(response, 'search');
                //$('#searchPropertyModal').modal('hide');
                //$('#searchTenantModal').modal('hide');
                //$('#searchTransactionModal').modal('hide');
            } else {
                display_error_message(response);
            }
        }
    });
}

window.getUsersFilterData = function(data) {
	$.ajax({
        method: 'POST',
        url: api_url+"searchs/get-user-filter-data",
        data: data,
        beforeSend: function(xhr) {
            xhr.setRequestHeader('Authorization', accessToken);
        },
        success: function(response) {
            if(response.response_data.success == true) {
            	$('#navbar_role_name').html('Users');
            	localStorage.setItem('filter_data', data);
            	loadUserCard('card_layout', response);
            } else {
                display_error_message(response);
            }
        }
    });
}

window.openSearchMoreModalBox = function(search_modal) {
	var open_for = 'modal';
	if(search_modal == 'searchPropertyModal') {
		getPropertyListForSearch(open_for);
	} else if(search_modal == 'searchTenantModal') {
		$('#'+search_modal+' #alphabet_container').html(printAlphabets());
		getTenantListForSearch(open_for);
	} else if(search_modal == 'searchLeaseModal') {
		getPropertyLeaseListForSearch(open_for);
		var html = '';
		var html = '<option value="">&nbsp;</option>';
		$.each(date_dd, function( key, value ) {
			key = key + 1;
			html += '<option value="'+key+'">'+value+'</option>';
		});
		$('#search_date_macro').html(html);
	} else if(search_modal == 'searchTransactionModal') {
		$('#'+search_modal+' #alphabet_container').html(printAlphabets());
		getPropertyTransactionListForSearch('scheduled_payments', open_for);
	} else if(search_modal == 'searchUserNameModal') {
		$('#'+search_modal+' #alphabet_container').html(printAlphabets());
		getUserDataForSearch(open_for, 'name');
	} else if(search_modal == 'searchUserEmailModal') {
		$('#'+search_modal+' #alphabet_container').html(printAlphabets());
		getUserDataForSearch(open_for, 'email');
	} else if(search_modal == 'searchUserPhoneModal') {
		getUserDataForSearch(open_for, 'phone');
	} else if(search_modal == 'searchUserStatusModal') {
		getUserDataForSearch(open_for, 'status');
	}
}

window.alphabetGroupCollection = function(data, type) {
	var groupedCollection = {};
	//var html = '';
	//html += '<ul class="list-unstyled text-left">';
	for (var key in data) {
        if (data.hasOwnProperty(key)) {
        	if(type == 'searchPropertyModal' || type == 'searchTenantModal') {
        		var firstLetter = data[key].name.charAt(0);
        	} else if(type == 'searchLeaseModal') {
        		var name = data[key].start_date+' - '+data[key].end_date;
        		var firstLetter = name.charAt(0);
        	} else if(type == 'searchTransactionModal') {
        		var firstLetter = data[key].label.charAt(0);
        	} else if(type == 'searchUserNameModal') {
        		var firstLetter = data[key].first_name.charAt(0);
        	} else if(type == 'searchUserEmailModal') {
        		var firstLetter = data[key].email.charAt(0);
        	} else if(type == 'searchUserPhoneModal') {
        		var firstLetter = data[key].phone.charAt(0);
        	} else if(type == 'searchUserStatusModal') {
        		var firstLetter = data[key].status.charAt(0);
        	} 

        	firstLetter = firstLetter.toUpperCase();

        	if(groupedCollection[firstLetter] == undefined){             
		        groupedCollection[firstLetter] = [];
		        $('#alpha_'+firstLetter).removeClass('add_opacity');
		    }

		    if(type == 'searchPropertyModal') {
		    	groupedCollection[firstLetter].push({
		    		'id':data[key].id,
		    		'name':data[key].name,
		    		'tenants':data[key].tenants
		    	});
		    } else if(type == 'searchTenantModal') {
		    	groupedCollection[firstLetter].push({
		    		'id':data[key].id,
		    		'name':data[key].name,
		    		'email':data[key].email,
		    		'properties':data[key].properties
		    	});
		    } else if(type == 'searchLeaseModal') {
		    	var name = data[key].start_date+' - '+data[key].end_date;
		    	groupedCollection[firstLetter].push({
		    		'id':data[key].id,
		    		'name':name,
		    		'start_date':data[key].start_date,
		    		'end_date':data[key].end_date,
		    	});
		    } else if(type == 'searchTransactionModal') {
		    	groupedCollection[firstLetter].push({
		    		'id':data[key].id,
		    		'label':data[key].label
		    	});
		    } else if(type == 'searchUserNameModal') {
		    	var name = data[key].first_name+' '+data[key].last_name;
		    	groupedCollection[firstLetter].push({
		    		'id':data[key].id,
		    		'name':name
		    	});
		    } else if(type == 'searchUserEmailModal') {
		    	var email = data[key].email;
		    	groupedCollection[firstLetter].push({
		    		'id':data[key].id,
		    		'email':email
		    	});
		    } else if(type == 'searchUserPhoneModal') {
		    	var phone = data[key].phone;
		    	groupedCollection[firstLetter].push({
		    		'id':data[key].id,
		    		'email':phone
		    	});
		    } else if(type == 'searchUserStatusModal') {
		    	var status = data[key].status;
		    	groupedCollection[firstLetter].push({
		    		'id':data[key].id,
		    		'status':status
		    	});
		    }
        }
    }

    ////html += '</ul>';
    //$('#'+type+' #alphabet_container').html(html);
    
    return groupedCollection;
}

window.printAlphabets = function() {
	var k;
	var i = 65;
	var j = 91;
	var html = '';
	html += '<ul class="list-unstyled text-right mr-2">';
	for(k = i; k < j; k++){
		var str =String.fromCharCode(k);
		html += '<li id="alpha_'+str+'" class="alphabet-li add_opacity" data-char="'+str+'">'+str+'</li>';
	}
	html += '</ul>';
	return html;
}

window.compareStrings = function(a, b) {
  // Assuming you want case-insensitive comparison
  a = a.toLowerCase();
  b = b.toLowerCase();

  return (a < b) ? -1 : (a > b) ? 1 : 0;
}

window.getAnnouncementDetail = function(announcement_id) {
	$.ajax({
	    url: api_url+"announcements/detail",
	    type: 'POST',
	    data: 'user_id='+user.user_details.user_id+'&user_service_name='+user_service_name+'&announcement_id='+announcement_id,
	    beforeSend: function(xhr) {
	        xhr.setRequestHeader('Authorization', accessToken);
	    },
	    success: function(response) {
	        if(response.response_data.success == true) {
	        	var title = $('#announcement_title').val();
		        var description = response.announcement.description;
		        $('#readMore .modal-title').html(title);
		        $('#readMore .modal-body').html(description);    
	        }
	    }
	});
}

window.load_selected_page = function(page_name,clicked_from='') {

	getUserServiceList();
	getLinkedAccount();

	$('.username_container').text(username);
	if(user_service_name == po_role) {
		if(status_code == 1) {
			$('.sidebar-toggle').show();
			$('.hamburger').removeClass('d-none');
			$('#sidebar').removeClass('d-none');
		}
	} else {
		$('.sidebar-toggle').show();
		$('.hamburger').removeClass('d-none');
		$('#sidebar').removeClass('d-none');
	}

	// Start: Reset search form field and close searh navigation
	var search = localStorage.getItem('search');
	if(search == 'pin') {
		var open_for = 'search';
        $('#main_container').load('components/properties.html', open_for, function() {
        	$('#open_for').val(open_for);
        	getPropertiesFilterData(localStorage.getItem('filter_data'));
        });
	} else {

		$(window).on('load', function(){
			if(localStorage.getItem("filter_data") !== null) {
				$('.clear_search_property_data').trigger('click');
				$('#global_search_form .close').trigger('click');
			}
		});

		// Before this code was in loadLeftMenu function
		if(page_name == 'home' && clicked_from == 'loadLeftMenu') {
			/*$('#main_container').load('components/home.html', function(){
				$('.username_container').text(username);
				if(user_service_name == po_role) {
					if(skip_add_payment_details == 1 && status_code == 1) {
						$('.sidebar-toggle').show();
						$('.hamburger').removeClass('d-none');
						$('#sidebar').removeClass('d-none');
					}
				}
			});
			return false;*/
		}

		// End: Reset search form field and close searh navigation

		openLoadingDialog($('#main_container'));
		setTimeout(function(){
			var user_id = user.user_details.user_id;
			$('#navbar_role_name').html(humanize(page_name));
			localStorage.setItem('page_name', page_name);

			if(clicked_from != 'left_menu'){
				var pid = getUrlParameter('pid');
				var tid = getUrlParameter('tid');
				var eaid = getUrlParameter('eaid');
				var aid = getUrlParameter('aid');
				var id = getUrlParameter('id');
			}

			if(page_name == 'properties' && (typeof pid !== "undefined")) {
				var pid = pid.substring(11, pid.length-20);
				loadPropertyDetailLayout(pid, 'vertical', '');
				var property_name = localStorage.getItem('property_name');
				$('#navbar_role_name').html(property_name);
			} else if(page_name == 'template' && (typeof tid !== "undefined")) {
				var tid = tid.substring(11, tid.length-20);
				openLoadingDialog($('#main_container'));
				setTimeout(function(){
					$('#main_container').load('components/template/viewer.html', tid, function(){
						$('#pdf_template_id').val(tid);
						getZohoFieldTypes();
			    		getFloatingActionButton('Template', '#pdf_viwer_container');
					});
				}, 2000);
			} else if(page_name == 'template' && (typeof eaid !== "undefined")) {
				var eaid = eaid.substring(11, eaid.length-20);
				openLoadingDialog($('#main_container'));
				setTimeout(function(){
					$('#main_container').load('components/agreement/update_agreement.html', eaid, function(){
				    	getAgreementDetailById(eaid);
				    });
				}, 2000);
			} else if(page_name == 'template' && (typeof aid !== "undefined")) {
				var aid = aid.substring(11, aid.length-20);
				openLoadingDialog($('#main_container'));
				setTimeout(function(){
					$('#main_container').load('components/agreement/viewer.html', aid, function(){
						$('#pdf_agreement_id').val(aid);
						getFieldTypes();
			    		//getFloatingActionButton('Template', '#pdf_viwer_container');
					});
				}, 2000);
			} else if(page_name == 'template' && (typeof id !== "undefined")) {
				var id = id.substring(11, id.length-20);
				openLoadingDialog($('#main_container'));
				setTimeout(function(){
					$('#main_container').load('components/template/update_template.html', id, function(){
						getZohoTemplateDetailById(id);
					});
				}, 2000);
			} else {
				
				if(page_name == 'dashboard') {
					window.history.replaceState("", "", domain+'dashboard.html');
				} else {
					window.history.pushState("", "", '?action='+page_name);
				}
				$('#main_container').load('components/'+page_name+'.html', function(response, status, xhr) {
					$('.sidebar-item[data-page-name="'+page_name+'"]').find('a.sidebar-link').addClass('bg-light');
					$('.sidebar-item[data-page-name="'+page_name+'"]').find('a.sidebar-link').addClass('orange');

					if(page_name == 'users') {
						getPendingUserList('card_layout');
					}

					if (xhr.status == "403" || xhr.status == "404") {
						$('#main_container').load('components/404.html');
			        } else {
						if(page_name == "dashboard") {
							$('.username_container').text(username);
						}

						// Permission code for plan-subscription
						if(page_name == 'plan-subscriptions') {
							if(!user.permissionData['PLAN-SUBSCRIPTIONS']["feature_items['coupon_list']"]) {
								$('a[href="#p-coupons"]').closest('li').remove();
								$('#p-coupons').remove();
							}

							if(!user.permissionData['PLAN-SUBSCRIPTIONS']["feature_items['plan_list']"]) {
								$('a[href="#p-plans"]').closest('li').remove();
								$('#p-plans').remove();
							}

							if(!user.permissionData['PLAN-SUBSCRIPTIONS']["feature_items['subscription_list']"]) {
								$('a[href="#p-subscriptions"]').closest('li').remove();
								$('#p-subscriptions').remove();
							}

							$('#plan-subscription-tab ul li:first a').addClass('active');
							$('#plan-subscription-tab .tab-content .tab-pane:first').addClass('active');
							if($('#plan-subscription-tab .tab-content .tab-pane:first').attr('id') == 'p-coupons') {
								loadCouponData();
							} else if($('#plan-subscription-tab .tab-content .tab-pane:first').attr('id') == 'p-plans') {
								loadPlanData();
							} else if($('#plan-subscription-tab .tab-content .tab-pane:first').attr('id') == 'p-subscriptions') {
								loadSubscriptionData();
							}
						}

						// Permission code for Permissions menu
						if(page_name == 'permissions') {
							if(!user.permissionData['PERMISSIONS']["feature_items['feature_list']"]) {
								$('a[href="#p-features"]').closest('li').remove();
								$('#p-features').remove();
							}

							if(!user.permissionData['PERMISSIONS']["feature_items['service_list']"]) {
								$('a[href="#p-services"]').closest('li').remove();
								$('#p-services').remove();
							}

							if(!user.permissionData['PERMISSIONS']["feature_items['service_permission_list']"]) {
								$('a[href="#p-service-permissions"]').closest('li').remove();
								$('#p-service-permissions').remove();
							}

							$('#permissions-tab ul li:first a').addClass('active');
							$('#permissions-tab .tab-content .tab-pane:first').addClass('active');
							if($('#permissions-tab .tab-content .tab-pane:first').attr('id') == 'p-features') {
								loadFeaturesData();
							} else if($('#permissions-tab .tab-content .tab-pane:first').attr('id') == 'p-services') {
								loadServicesData();
							} else if($('#permissions-tab .tab-content .tab-pane:first').attr('id') == 'p-service-permissions') {
								loadServicePermissionsData();
							}
						}

						// Permission code for Email menu
						if(page_name == 'email') {
							if(!user.permissionData['EMAIL']["feature_items['inbox_list']"]) {
								$('a[href="#p-inbox"]').closest('li').remove();
								$('#p-inbox').remove();
							}

							if(!user.permissionData['EMAIL']["feature_items['sent_email_list']"]) {
								$('a[href="#p-sent-emails"]').closest('li').remove();
								$('#p-sent-emails').remove();
							}

							if(!user.permissionData['EMAIL']["feature_items['email_template_list']"]) {
								$('a[href="#p-email-templates"]').closest('li').remove();
								$('#p-email-templates').remove();
							}

							$('#system-email-tab ul li:first a').addClass('active');
							$('#system-email-tab .tab-content .tab-pane:first').addClass('active');
							if($('#system-email-tab .tab-content .tab-pane:first').attr('id') == 'p-inbox') {
								getSystemEmailDatas('inbox');
							} else if($('#system-email-tab .tab-content .tab-pane:first').attr('id') == 'p-sent-emails') {
								getSystemEmailDatas('sent-email');
							} else if($('#system-email-tab .tab-content .tab-pane:first').attr('id') == 'p-email-templates') {
								getEmailTemplates();
							}
						}

						// Permission code for Report menu
						if(page_name == 'reports') {
							/*if(!user.permissionData['REPORTS']["feature_items['summary_list']"]) {
								$('a[href="#r-summary"]').closest('li').remove();
								$('#r-summary').remove();
							}*/

							if(!user.permissionData['REPORTS']["feature_items['transaction_report']"]) {
								$('a[href="#r-payment-receipt"]').closest('li').remove();
								$('#r-payment-receipt').remove();
							}

							if(!user.permissionData['REPORTS']["feature_items['income_expense_report']"]) {
								$('a[href="#r-expenses"]').closest('li').remove();
								$('#r-expenses').remove();
							}

							$('#reports-tab ul li:first a').addClass('active');
							$('#reports-tab .tab-content .tab-pane:first').addClass('active');
							/*if($('#reports-tab .tab-content .tab-pane:first').attr('id') == 'r-summary') {
								getPaymentSummaryReport();
							} else */if($('#reports-tab .tab-content .tab-pane:first').attr('id') == 'r-payment-receipt') {
								var date_macro = '';
								//getPaymentReceiptReport(date_macro);
								getPaymentReceiptCardReport(date_macro);
							} else if($('#reports-tab .tab-content .tab-pane:first').attr('id') == 'r-expenses') {
								openLoadingDialog($('#expense_report_data_container'));
						    	setTimeout(function(){
							    	getExpenseDetailedReport();
							    }, 100);
							}
						}
					}
					
					// Permission code for Master data menu
					if(page_name == 'master-data') {
						if(!user.permissionData['MASTER-DATA']["feature_items['pay_to_list']"]) {
							$('a[href="#r-vendor"]').closest('li').remove();
							$('#r-vendor').remove();
						}

						if(!user.permissionData['MASTER-DATA']["feature_items['category_list']"]) {
							$('a[href="#r-category"]').closest('li').remove();
							$('#r-category').remove();
						}

						$('#master-data-tab ul li:first a').addClass('active');
						$('#master-data-tab .tab-content .tab-pane:first').addClass('active');
						if($('#master-data-tab .tab-content .tab-pane:first').attr('id') == 'r-vendor') {
							loadVendorData();
						} else if($('#master-data-tab .tab-content .tab-pane:first').attr('id') == 'r-category') {
							loadCategoryData();
						}
					}
					//createActivity(user_id, '', '', 'Clicked on <b>' + page_name + '</b>page');
				});
			}
		}, 500);
	}
}

window.getSignupNextStep = function(current_step, next_step) {
	$('head').append('<style>#signup_checkilist_step_'+current_step+':before{background:green !important;}</style>');
	$('#signup_completed_steps').html(current_step);
	$('#signup_step_name').html($('#signup_checkilist_step_'+next_step).html());

	// Remove selected green color when close modal popup
    $('#signup_checkilist_step_'+current_step).removeClass('orange');
	$('#signup_checkilist_step_'+next_step).addClass('orange');
}

window.DownloadPdf = function(text) {
    /*change*/
    var $content = "<div class='dialog-ovelay'>" +
        "<div class='dialog'><header>" +
        "<h1>"+text+"</h1>" +
        /*"<i class='fa fa-close'></i>" +*/
        "</header>" +
        "</div>" +
        "</div>";

    $('body').prepend($content);
}

window.getXYCoordinate = function(width, height, viewer_width, viewer_height, leftPixel, topPixel) {

    const width_perc = width / viewer_width;
    const height_perc = height / viewer_height;

    //console.log(width); console.log(height);

    var leftPixel = ((Math.round(leftPixel) * viewer_width) / 100) * width_perc;
    var topPixel = (height - (((Math.round(topPixel) * viewer_height) / 100) * height_perc)); // here we are subtract height from calculation reason Y coordinate calculated from bottom and in viewer we are calculating from top

    //console.log(leftPixel); console.log(topPixel);

    return [leftPixel, topPixel];
}

window.getFloatingActionButton = function(module_name, container) {
	$('#floating-action-button-nav').remove();
	var html = '';
	html += '<nav id="floating-action-button-nav" class="float-action-button">';
		if(module_name == 'LandingPage') {
	    	html += '<a id="scroll_to_top" href="javascript:void(0)" class="buttons main-button" data-toggle="tooltip" data-placement="left" title="Back to top" style="display:none"><i class="fas fa-angle-up"></i></a>';
	    } else if(module_name == 'All-User') {
			var layout = $('#user_list_layout').val();
			if(layout == 'table_layout') {
				html += '<a href="javascript:void(0)" class="buttons child_btn change_layout" id="ver_prop" data-module="user" data-toggle="tooltip" data-placement="left" title="Change Layout"><i class="fas fa-grip-vertical"></i></a>';
			} else if(layout == 'card_layout') {
				html += '<a href="javascript:void(0)" class="buttons child_btn change_layout" id="table_prop" data-module="user" data-toggle="tooltip" data-placement="left" title="Change Layout"><i class="fas fa-table"></i></a>';
			}
			html += '<a href="javascript:void(0)" class="buttons main-button" data-toggle="tooltip" data-placement="left" title="User Menu"><i class="fa fa-ellipsis-v"></i></a>';
		} else if(module_name == 'Application') {
			html += '<a href="javascript:void(0)" class="buttons main-button" data-toggle="tooltip" data-placement="left" title="User Menu"><i class="fa fa-ellipsis-v"></i></a>';
		} else if(module_name == 'Property') {
			var layout = $('#selected_layout').val();
			if(layout == 'table_layout') {
				html += '<a href="javascript:void(0)" class="buttons child_btn change_layout" id="ver_prop" data-module="property" data-toggle="tooltip" data-placement="left" title="Change Layout"><i class="fas fa-grip-vertical"></i></a>';
			} else if(layout == 'card_layout') {
				html += '<a href="javascript:void(0)" class="buttons child_btn change_layout" id="table_prop" data-module="property" data-toggle="tooltip" data-placement="left" title="Change Layout"><i class="fas fa-table"></i></a>';
			}

			if(user_service_name == po_role && user.permissionData['PROPERTIES']["feature_items['property_add']"]) {
	    		html += '<a href="javascript:void(0)" class="buttons child_btn add_new_property" data-toggle="tooltip" data-placement="left" title="Add New Property"><i class="fas fa-plus"></i></a>';
	    	}

	    	html += '<a href="javascript:void(0)" class="buttons main-button" data-toggle="tooltip" data-placement="left" title="Property Menu"><i class="fa fa-ellipsis-v"></i></a>';
	    } else if(module_name == 'PropertyDetail') {
	    	html += '<a href="javascript:void(0)" class="buttons main-button back_button" data-module="property" data-toggle="tooltip" data-placement="left" title="Back to property"><i class="align-middle ml-3 fas fa-fw fa-angle-left">&nbsp;</i></a>';
	    } else if(module_name == 'Lease') {
	    	if(user_service_name == po_role && user.permissionData['PROPERTIES']["feature_items['lease_add']"]) {
	    		html += '<a href="javascript:void(0)" class="buttons main-button add_new_lease" data-toggle="tooltip" data-placement="left" title="Add New Lease"><i class="fas fa-plus"></i></a>';
	    	}
	    } else if(module_name == 'Document') {
	    	//if(user_service_name == sa_role) {
	    		var layout = $('#document_tab_layout').val();
	    		if(layout == 'table_layout') {
					html += '<a href="javascript:void(0)" class="buttons child_btn change_layout" id="ver_prop" data-module="document" data-toggle="tooltip" data-placement="left" title="Change Layout"><i class="fas fa-grip-vertical"></i></a>';
				} else if(layout == 'card_layout') {
					html += '<a href="javascript:void(0)" class="buttons child_btn change_layout" id="table_prop" data-module="document" data-toggle="tooltip" data-placement="left" title="Change Layout"><i class="fas fa-table"></i></a>';
				}

	    		if(user.permissionData['PROPERTIES']["feature_items['upload_document']"]) {
	    			html += '<a href="javascript:void(0)" class="buttons child_btn get_property_document_dropzone" data-toggle="tooltip" data-placement="left" title="Upload Document"><i class="fas fa-upload"></i></a>';
	    		}
	    		if(user.permissionData['PROPERTIES']["feature_items['add_signature_document']"]) {
	    			html += '<a href="javascript:void(0)" class="buttons child_btn add_lease_aggrement_signature_document" data-toggle="tooltip" data-placement="left" title="Add Signature Document"><i class="fas fa-file-signature"></i></a>';
	    		}
	    		html += '<a href="javascript:void(0)" class="buttons main-button" data-toggle="tooltip" data-placement="left" title="Document Menu"><i class="fa fa-ellipsis-v"></i></a>';
	    	/*} else {
	    		html += '<a href="javascript:void(0)" class="buttons main-button get_property_document_dropzone"  title="Upload Document" data-toggle="tooltip" data-placement="left"><i class="fas fa-upload"></i></a>';
	    	}*/
	    } else if(module_name == 'Tenant') {
	    	if(user_service_name == po_role && user.permissionData['PROPERTIES']["feature_items['tenant_add']"]) {
	    		html += '<a href="javascript:void(0)" class="buttons main-button add_new_tenant" data-toggle="tooltip" data-placement="left" title="Add New Tenant"><i class="fas fa-plus"></i></a>';
	    	}
	    } else if(module_name == 'Ticket') {
	    	var layout = $('#selected_layout').val();
			if(layout == 'table_layout') {
				html += '<a href="javascript:void(0)" class="buttons child_btn change_layout" id="ver_prop" data-module="ticket" data-toggle="tooltip" data-placement="left" title="Change Layout"><i class="fas fa-grip-vertical"></i></a>';
			} else if(layout == 'card_layout') {
				html += '<a href="javascript:void(0)" class="buttons child_btn change_layout" id="table_prop" data-module="ticket" data-toggle="tooltip" data-placement="left" title="Change Layout"><i class="fas fa-table"></i></a>';
			}
	    	if(user.permissionData['SUPPORT']["feature_items['add']"]) {
	    		html += '<a href="javascript:void(0)" class="buttons child-button add_new_ticket" data-toggle="tooltip" data-placement="left" title="Add New Ticket"><i class="fas fa-plus"></i></a>';
	    		html += '<a href="javascript:void(0)" class="buttons main-button" data-toggle="tooltip" data-placement="left" title="Support Menu"><i class="fa fa-ellipsis-v"></i></a>';
	    	}
	    } else if(module_name == 'Bank') {
	    	html += '<a href="javascript:void(0)" class="buttons main-button add_new_payment" data-toggle="tooltip" data-placement="left" title="Add New Payment"><i class="fas fa-plus"></i></a>';
	    } else if(module_name == 'Template') {
			if(user.permissionData['TEMPLATE']["feature_items['add']"]) {
	    		html += '<a href="javascript:void(0)" class="buttons child_btn create_zoho_template" data-toggle="tooltip" data-placement="left" title="Create New Template"><i class="fas fa-plus"></i></a>';
	    		html += '<a href="javascript:void(0)" class="buttons main-button" data-toggle="tooltip" data-placement="left" title="Template Menu"><i class="fa fa-ellipsis-v"></i></a>';
	    	}
	    } else if(module_name == 'Contracts') {
			if(user.permissionData['TEMPLATE']["feature_items['contract_add']"]) {
	    		html += '<a href="javascript:void(0)" class="buttons child_btn create_contract" data-toggle="tooltip" data-placement="left" title="Create New Contract"><i class="fas fa-plus"></i></a>';
	    		html += '<a href="javascript:void(0)" class="buttons main-button" data-toggle="tooltip" data-placement="left" title="Template Menu"><i class="fa fa-ellipsis-v"></i></a>';
	    	}
	    } else if(module_name == 'Agreements') {
			if(user.permissionData['TEMPLATE']["feature_items['agreement_add']"]) {
	    		//html += '<a href="javascript:void(0)" class="buttons child_btn create_agreement" data-toggle="tooltip" data-placement="left" title="Create New Agreement"><i class="fas fa-plus"></i></a>';
	    		//html += '<a href="javascript:void(0)" class="buttons main-button" data-toggle="tooltip" data-placement="left" title="Agreement Menu"><i class="fa fa-ellipsis-v"></i></a>';
	    	}
	    } else if(module_name == 'MasterAgreements') {
			if(user.permissionData['TEMPLATE']["feature_items['master_agreement_add']"]) {
	    		html += '<a href="javascript:void(0)" class="buttons child_btn create_master_agreement" data-toggle="tooltip" data-placement="left" title="Create Master Agreement"><i class="fas fa-plus"></i></a>';
	    		html += '<a href="javascript:void(0)" class="buttons main-button" data-toggle="tooltip" data-placement="left" title="Master Agreement Menu"><i class="fa fa-ellipsis-v"></i></a>';
	    	}
	    } else if(module_name == 'LinkedAccount') {
	    	html += '<a href="javascript:void(0)" class="buttons main-button add_new_account" data-toggle="tooltip" data-placement="left" title="Add new account"><i class="fas fa-plus"></i></a>';
	    } else if(module_name == 'Expenses') {
			//console.log("in function");
			// html += '<a href="javascript:void(0)" class="mrshow buttons child_btn" data-toggle="tooltip" data-placement="left" title data-original-title="Scan Receipt"><div class="camera-upload">';
            //     html += '<label for="fileToUpload"><i class="fas fa-camera"></i></label>';
            //     html += '<input type="file" name="fileToUpload" id="fileToUpload" class="fileToUpload" accept="image/*;capture=camera">';
            //     html += '</div></a>';
			html += '<a href="javascript:void(0)" class="buttons child_btn add_new_expense" id="" data-module="" data-toggle="tooltip" data-placement="left" title data-original-title="Add New Expense"><i class="fas fa-plus"></i></a>'
			//html += '<a href="javascript:void(0)" class="buttons child_btn get_expense_detailed_report" id="" data-module="" data-toggle="tooltip" data-placement="left" title data-original-title="Get expense detailed report"><i class="align-middle mr-2 fas fa-fw fa-file-alt"></i></a>'
			html += '<a href="javascript:void(0)" class="buttons main-button" data-toggle="tooltip" data-placement="left" title data-original-title="Expense Menu"><i class="fa fa-ellipsis-v"></i></a>'
		}
	html += '</nav>';
	$(container).append(html);

	$('[data-toggle="tooltip"]').tooltip({
		trigger: 'hover'
	});

	$('[data-toggle="tooltip"]').on('click', function () {
    	$(this).tooltip('hide');
    	setTimeout(function(){
		    $('.tooltip').removeClass('show');
		}, 1000);
  	});
}

window.openPopoverModal = function(title, type) {
	var popOverSettings = {
		title: title+'<i class="mr-2 fas fa-fw fa-times-circle close_transaction_detail_modal" style="float:right;"></i>',
		trigger: 'manual',
	    html: true,
	    animation:false,
	    sanitize: false,
	    placement: function() { return $(window).width() < 975 ? 'top' : 'bottom'; },
	    content: function () {
	    	var id = $(this).attr('data-id');
	    	var transferID = $(this).attr('data-transferID');
	    	var html = getRelatedTransactionDetail(id,transferID);
	    	return html;
	    }
	}

	$('[data-toggle="popover"]').popover(popOverSettings)
    .on("click", function (e) {
    	e.stopPropagation();
        var _this = this;
        $(this).popover("toggle");
    });
}

window.ConfirmFieldDelete = function(type,id, field_id, action_id) {
    /*change*/
    var $content = "<div class='dialog-ovelay'>" +
        "<div class='dialog'><header>" +
        "<h1> Delete " + type + " Field</h1>" +
        /*"<i class='fa fa-close'></i>" +*/
        "</header>" +
	        "<div class='dialog-msg'>" +
	        	"<p>To confirm deletion, type <b><em class='fs-22'>"+delete_confirmation_text+"</em></b> in the text input field.</p>" +
	        	"<p class='mt-4'><input type='text' id='confirm_text' class='form-control grey-border' placeholder='"+delete_confirmation_text+"'/></p>" +
	        "</div>" +
	        "<footer>" +
		        "<div class='controls'>" +
		        	"<button class='btn btn-grey cancelAction'>Close</button>" +
		        	"<button class='btn btn-orange ml-2 doAction' disabled>Confirm</button>" +
		        "</div>" +
	        "</footer>" +
        "</div>" +
        "</div>";

    $('body').prepend($content);
	$('#confirm_text').focus();
    $('#confirm_text').keyup(function() {
    	var text = $(this).val();
    	text = text.toLowerCase();
    	if(text == delete_confirmation_text) {
    		$('.doAction').attr('disabled', false);
    	} else {
    		$('.doAction').attr('disabled', true);
    	}
    });

    $('.cancelAction, .fa-close').click(function() {
        $(this).parents('.dialog-ovelay').remove();
    });

    $('.doAction').click(function() {
    	var text = $('#confirm_text').val();
    	text = text.toLowerCase();
    	if(text != delete_confirmation_text) {
    		var response = {
                "response_data": {
                    "success": false,
                    "message": 'Enter confirmation string before deleting'
                }
            };
            display_error_message(response);
    	} else {
    		deleteDocumentField(id, field_id, action_id);
	        $(this).parents('.dialog-ovelay').remove();
    	}
    });
}

window.MissingFields = function(data){
    /*change*/
    var $content = "<div class='dialog-ovelay'>" +
        "<div class='dialog'><header>" +
        "<h1> Missing Fields in Expense: </h1>" +
        /*"<i class='fa fa-close'></i>" +*/
        "</header>" +
	        "<div class='dialog-msg'>" +
	        	"<p class='mt-4'>"+data+"</p>" +
	        "</div>" +
	        "<footer>" +
		        "<div class='controls'>" +
		        	"<button class='btn btn-grey cancelAction'>Close</button>" +
		        "</div>" +
	        "</footer>" +
        "</div>" +
        "</div>";

    $('body').prepend($content);
	$('.cancelAction, .fa-close').click(function() {
        $(this).parents('.dialog-ovelay').remove();
    });
}

window.ConfirmDelete = function(type,id) {
    /*change*/
    var $content = "<div class='dialog-ovelay'>" +
        "<div class='dialog'><header>" +
        "<h1> Delete " + type + " </h1>" +
        /*"<i class='fa fa-close'></i>" +*/
        "</header>" +
	        "<div class='dialog-msg'>" +
	        	"<p>To confirm deletion, type <b><em class='fs-22'>"+delete_confirmation_text+"</em></b> in the text input field.</p>" +
	        	"<p class='mt-4'><input type='text' id='confirm_text' class='form-control grey-border' placeholder='"+delete_confirmation_text+"'/></p>" +
	        "</div>" +
	        "<footer>" +
		        "<div class='controls'>" +
		        	"<button class='btn btn-grey cancelAction'>Close</button>" +
		        	"<button class='btn btn-orange ml-2 doAction' disabled>Confirm</button>" +
		        "</div>" +
	        "</footer>" +
        "</div>" +
        "</div>";

    $('body').prepend($content);
	$('#confirm_text').focus();
    $('#confirm_text').keyup(function() {
    	var text = $(this).val();
    	text = text.toLowerCase();
    	if(text == delete_confirmation_text) {
    		$('.doAction').attr('disabled', false);
    	} else {
    		$('.doAction').attr('disabled', true);
    	}
    });

    $('.cancelAction, .fa-close').click(function() {
        $(this).parents('.dialog-ovelay').remove();
    });

    $('.doAction').click(function() {
    	var text = $('#confirm_text').val();
    	text = text.toLowerCase();
    	if(text != delete_confirmation_text) {
    		var response = {
                "response_data": {
                    "success": false,
                    "message": 'Enter confirmation string before deleting'
                }
            };
            display_error_message(response);
    	} else {
    		if(type == 'Property') {
	        	deleteProperty(id);
	        } else if(type == 'Property Image') {
	        	deletePropertyImage(id);
	        } else if(type == 'Property Document') {
	        	deleteUserTemplate(id);
	        } else if(type == 'Background Check Report' || type == 'Credit Report') {
	        	deleteUserBackCreditReportFile(id,type);
	        } else if(type == 'Lease') {
	        	deleteLease(id);
	        } else if(type == 'Tenant') {
	        	deleteTenant(id);
	        } else if(type == 'Ticket') {
	        	deleteDraftTicket(id);
	        } else if(type == 'Comment') {
	        	deleteTicketComment(id);
	        } else if(type == 'Bank') {
	        	deleteBank(id);
	        } else if(type == 'ZohoTemplate') {
	        	deleteZohoTemplateById(id);
	        } else if(type == 'Document') {
	        	deleteZohoDocument(id);
	        } else if(type == 'Agreement') {
	        	deleteAgreementDocument(id);
	        } else if(type == 'Group') {
	        	deleteGroup(id);
	        } else if(type == 'Service') {
	        	deleteService(id);
	        } else if(type == 'ServiceMember') {
	        	deleteUserService(id);
	        } else if(type == 'PropertyContract') {
	        	deletePropertyContract(id);
	        } else if(type == 'Expense Report') {
	        	deleteExpense(id);
	        } else if(type == 'Expense') {
	        	deleteExpenseLineItem(id);
	        } else if(type == 'User') {
	        	deleteUser(id);
	        } else if(type == 'Category'){
				deleteCategory(id);
			} else if(type == 'Sub Category'){
				deleteSubCategory(id);
			} else if(type == 'Pay To User') {
				deleteVendor(id);
			}
	        $(this).parents('.dialog-ovelay').remove();
    	}
    });
}

window.ConfirmDisable = function(type,id) {
    /*change*/
    var $content = "<div class='dialog-ovelay'>" +
        "<div class='dialog'><header>" +
        "<h1> Disable " + type + " </h1>" +
        /*"<i class='fa fa-close'></i>" +*/
        "</header>" +
	        "<div class='dialog-msg'>" +
	        	"<p>To confirm disable, type <b><em class='fs-22'>"+disable_confirmation_text+"</em></b> in the text input field.</p>" +
	        	"<p class='mt-4'><input type='text' id='confirm_text' class='form-control grey-border' placeholder='"+disable_confirmation_text+"'/></p>" +
	        "</div>" +
	        "<footer>" +
		        "<div class='controls'>" +
		        	"<button class='btn btn-grey cancelAction'>Close</button>" +
		        	"<button class='btn btn-orange ml-2 doAction' disabled>Confirm</button>" +
		        "</div>" +
	        "</footer>" +
        "</div>" +
        "</div>";

    $('body').prepend($content);
	$('#confirm_text').focus();
    $('#confirm_text').keyup(function() {
    	var text = $(this).val();
    	text = text.toLowerCase();
    	if(text == disable_confirmation_text) {
    		$('.doAction').attr('disabled', false);
    	} else {
    		$('.doAction').attr('disabled', true);
    	}
    });

    $('.cancelAction, .fa-close').click(function() {
        $(this).parents('.dialog-ovelay').remove();
    });

    $('.doAction').click(function() {
    	var text = $('#confirm_text').val();
    	text = text.toLowerCase();
    	if(text != disable_confirmation_text) {
    		var response = {
                "response_data": {
                    "success": false,
                    "message": 'Enter confirmation string before disable'
                }
            };
            display_error_message(response);
    	} else {
    		if(type == 'MasterAgreement') {
	        	disableMasterAgreement(id);
	        }
	        $(this).parents('.dialog-ovelay').remove();
    	}
    });
}

window.ConfirmAddTenant = function(type) {
    /*change*/
    var $content = "<div class='dialog-ovelay'>" +
        "<div class='dialog'><header>" +
        "<h1>Do you want add another "+type+"?</h1>" +
        /*"<i class='fa fa-close'></i>" +*/
        "</header>" +
	        "<footer>" +
		        "<div class='controls'>" +
		        	"<button class='btn btn-orange doAction'>Add another tenant</button>" +
		        	"<button class='btn btn-orange ml-2 cancelAction'>Next</button>" +
		        "</div>" +
	        "</footer>" +
        "</div>" +
        "</div>";

    $('body').prepend($content);
	
    $('.cancelAction, .fa-close').click(function() {
        $(this).parents('.dialog-ovelay').remove();
        getSignupNextStep(11,12);

		var user = JSON.parse(localStorage.user);
		user.user_details.cheklist_seq_no = 11;
		localStorage.setItem("user", JSON.stringify(user));  //put the object back

		openLoadingDialog($('#signup_data_container'));
		$('#signup_data_container').load('components/property/add_new_lease.html', function() {
			$('.card-header').remove();
			$('#signup_data_container .card').removeClass('border');
			$('.save_lease_data').text('Next');
		});
    });

    $('.doAction').click(function() {
    	$(this).parents('.dialog-ovelay').remove();
    	openLoadingDialog($('#signup_data_container'));
		$('#signup_data_container').load('components/property/add_new_tenant.html', function(){
			$('.card-header').remove();
			$('#signup_data_container .card').removeClass('border');
			$('.save_tenant_data').remove();
			$('.save_and_submit_tenant_data').removeClass('d-none');
			$('.save_and_submit_tenant_data').text('Next');
		});
    });
}

/*window.ConfirmRestore = function(type,id) {
    var $content = "<div class='dialog-ovelay'>" +
        "<div class='dialog'><header>" +
        "<h1> Restore " + type + " </h1>" +
        "</header>" +
	        "<div class='dialog-msg'>" +
	        	"<p>To confirm restore, type <b><em class='fs-22'>"+restore_confirmation_text+"</em></b> in the text input field.</p>" +
	        	"<p class='mt-4'><input type='text' id='restore_confirmation_text' class='form-control grey-border' placeholder='"+restore_confirmation_text+"'/></p>" +
	        "</div>" +
	        "<footer>" +
		        "<div class='controls'>" +
		        	"<button class='btn btn-grey cancelAction'>Close</button>" +
		        	"<button class='btn btn-orange ml-2 doAction' disabled>Restore</button>" +
		        "</div>" +
	        "</footer>" +
        "</div>" +
        "</div>";

    $('body').prepend($content);
	$('#restore_confirmation_text').focus();
    $('#restore_confirmation_text').keyup(function() {
    	var text = $(this).val();
    	text = text.toLowerCase();
    	if(text == restore_confirmation_text) {
    		$('.doAction').attr('disabled', false);
    	} else {
    		$('.doAction').attr('disabled', true);
    	}
    });

    $('.cancelAction, .fa-close').click(function() {
        $(this).parents('.dialog-ovelay').remove();
    });

    $('.doAction').click(function() {
    	var text = $('#restore_confirmation_text').val();
    	text = text.toLowerCase();
    	if(text != restore_confirmation_text) {
    		var response = {
                "response_data": {
                    "success": false,
                    "message": 'Enter confirmation string before restoring'
                }
            };
            display_error_message(response);
    	} else {
    		if(type == 'Bank') {
    			restoreBank(id);
	        }
	        $(this).parents('.dialog-ovelay').remove();
    	}
    });
}*/

window.ConfirmCancel = function(type,id,cancel_type='') {
    /*change*/
    var $content = "<div class='dialog-ovelay'>" +
        "<div class='dialog'><header>" +
        "<h1> Cancel " + type + " </h1>" +
        /*"<i class='fa fa-close'></i>" +*/
        "</header>" +
	        "<div class='dialog-msg'>" +
	        	"<p>To confirm cancel, type <b><em class='fs-22'>"+cancel_confirmation_text+"</em></b> in the text input field.</p>" +
	        	"<p class='mt-4'><input type='text' id='cancel_confirm_text' class='form-control grey-border' placeholder='"+cancel_confirmation_text+"'/></p>" +
	        "</div>" +
	        "<footer>" +
		        "<div class='controls'>" +
		        	"<button class='btn btn-grey cancelAction'>Close</button>" +
		        	"<button class='btn btn-orange ml-2 doAction' disabled>Confirm</button>" +
		        "</div>" +
	        "</footer>" +
        "</div>" +
        "</div>";

    $('body').prepend($content);
	$('#cancel_confirm_text').focus();
    $('#cancel_confirm_text').keyup(function() {
    	var text = $(this).val();
    	text = text.toLowerCase();
    	if(text == cancel_confirmation_text) {
    		$('.doAction').attr('disabled', false);
    	} else {
    		$('.doAction').attr('disabled', true);
    	}
    });

    $('.cancelAction, .fa-close').click(function() {
        $(this).parents('.dialog-ovelay').remove();
    });

    $('.doAction').click(function() {
    	var text = $('#cancel_confirm_text').val();
    	text = text.toLowerCase();
    	if(text != cancel_confirmation_text) {
    		var response = {
                "response_data": {
                    "success": false,
                    "message": 'Enter confirmation string before canceling'
                }
            };
            display_error_message(response);
    	} else {
    		if(type == 'Transaction') {
    			cancelPayment(id, cancel_type);
	        }
	        $(this).parents('.dialog-ovelay').remove();
    	}
    });
}

window.ConfirmRegenerate = function(type,id,payment_type='') {
    /*change*/
    var $content = "<div class='dialog-ovelay'>" +
        "<div class='dialog'><header>" +
        "<h1> Regenerate " + type + " </h1>" +
        /*"<i class='fa fa-close'></i>" +*/
        "</header>" +
	        "<div class='dialog-msg'>" +
	        	"<p>To confirm regenerate, type <b><em class='fs-22'>"+confirmation_text+"</em></b> in the text input field.</p>" +
	        	"<p class='mt-4'><input type='text' id='confirm_text' class='form-control grey-border' placeholder='"+confirmation_text+"'/></p>" +
	        "</div>" +
	        "<footer>" +
		        "<div class='controls'>" +
		        	"<button class='btn btn-grey cancelAction'>Close</button>" +
		        	"<button class='btn btn-orange ml-2 doAction' disabled>Confirm</button>" +
		        "</div>" +
	        "</footer>" +
        "</div>" +
        "</div>";

    $('body').prepend($content);
	$('#confirm_text').focus();
    $('#confirm_text').keyup(function() {
    	var text = $(this).val();
    	text = text.toLowerCase();
    	if(text == confirmation_text) {
    		$('.doAction').attr('disabled', false);
    	} else {
    		$('.doAction').attr('disabled', true);
    	}
    });

    $('.cancelAction, .fa-close').click(function() {
        $(this).parents('.dialog-ovelay').remove();
    });

    $('.doAction').click(function() {
    	var text = $('#confirm_text').val();
    	text = text.toLowerCase();
    	if(text != confirmation_text) {
    		var response = {
                "response_data": {
                    "success": false,
                    "message": 'Enter confirm string before confirm'
                }
            };
            display_error_message(response);
    	} else {
    		if(type == 'Transaction') {
    			regenerateTransaction(id, payment_type);
	        }
	        $(this).parents('.dialog-ovelay').remove();
    	}
    });
}

window.sortResults = function(array, field, type) {
    array.sort(function(a, b) {
        if (type) {
            return (a[field] > b[field]) ? 1 : ((a[field] < b[field]) ? -1 : 0);
        } else {
            return (b[field] > a[field]) ? 1 : ((b[field] < a[field]) ? -1 : 0);
        }
    });
    renderResults();
}

window.stripHtml = function (html){
    // Create a new div element
    var temporalDivElement = document.createElement("div");
    // Set the HTML content with the providen
    temporalDivElement.innerHTML = html;
    // Retrieve the text property of the element (cross-browser support)
    return temporalDivElement.textContent || temporalDivElement.innerText || "";
}

window.getFormattedDate = function(date) {
	var date = new Date(date);
    var formated_date = ((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '/' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + '/' + date.getFullYear();
    return formated_date;
}

window.formatDateYmd = function(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
}

window.tenant_authorize_datepicker = function(date_element, payment_due_date) {
	//allow current date to due date selection
	var nowDate = new Date();
	var today = new Date(nowDate.getFullYear(), nowDate.getMonth(), nowDate.getDate(), 0, 0, 0, 0);
	$("."+date_element).datepicker({
		format: 'mm/dd/yyyy',
		startDate: today,
		endDate:payment_due_date,
	   	autoclose: true,
	   	todayHighlight: true,
	   	forceParse: false
	});
}

window.datepicker = function(date_element) {
	if(date_element == 'date_of_birth') {
		$("#"+date_element).datepicker({
			startDate: '-125y',
			endDate: '-18y',
		   	format: 'mm/dd/yyyy',
		   	autoclose: true,
		   	useCurrent: false,
		   	forceParse: false
		});
	} else if(date_element == 'first_month_due_date') {
		//disabling past date from datepicker
		var nowDate = new Date();
		var today = new Date(nowDate.getFullYear(), nowDate.getMonth(), nowDate.getDate(), 0, 0, 0, 0);
		$("."+date_element).datepicker({
			format: 'mm/dd/yyyy',
			startDate: today,
		   	autoclose: true,
		   	todayHighlight: true,
		   	forceParse: false
		});
	} else if (date_element == 'inputDate') {
		$('input[name^="inputDate"]').datepicker({
		   	format: 'mm/dd/yyyy',
		   	autoclose: true,
		   	useCurrent: false,
		   	forceParse: false,
			todayHighlight: true
		});
	}
}

window.initialize_datepicker = function(start_date, end_date, sd_due_date, pd_due_date) {

	$('#start_date, #end_date').on('keyup', function() {
	   validateEnteredDate($(this).val(), $(this).attr('id'));
	});

	$("#"+start_date).datepicker({
	   	format: 'mm/dd/yyyy',
	   	autoclose: true,
	   	startDate: '01/01/2018',
	   	endDate: '12/31/2070',
	   	todayHighlight: true,
		forceParse: false
	}).on('changeDate', function (selected) {
	   	var minDate = new Date(selected.date.valueOf());
	   	minDate.setDate(minDate.getDate() + 1);
	   	$('#'+end_date).datepicker('setStartDate', minDate);

	   	// Check security due date should be less then start date
	   	var sdMaxDate = new Date(selected.date.valueOf());
	   	sdMaxDate.setDate(sdMaxDate.getDate());
	   	$('#'+sd_due_date).datepicker('setEndDate', sdMaxDate);

	   	// Check pet deposit due date should be less then start date
	   	var pdMaxDate = new Date(selected.date.valueOf());
	   	pdMaxDate.setDate(pdMaxDate.getDate());
	   	$('#'+pd_due_date).datepicker('setEndDate', pdMaxDate);
	});

	$("#"+end_date).datepicker({
	   	format: 'mm/dd/yyyy',
	   	autoclose: true,
	   	startDate: '01/01/2018',
	   	endDate: '12/31/2070',
	   	todayHighlight: true,
		forceParse: false
	}).on('changeDate', function (selected) {
	    var minDate = new Date(selected.date.valueOf());
	    $('#'+start_date).datepicker('setEndDate', minDate);
	});

	$('#start_date').on('blur', function () {
		if($(this).val() == "") {
           $('#end_date').datepicker('setStartDate', '');
       	}
   	});

   	// Security and Pet Deposit Due Date
	$("#"+sd_due_date+",#"+pd_due_date).datepicker({
	   	format: 'mm/dd/yyyy',
	   	autoclose: true,
	   	startDate: '01/01/2018',
	   	endDate: '12/31/2070',
	   	todayHighlight: true,
		forceParse: false
	}).on('changeDate', function (selected) {
	    var maxDate = new Date(selected.date.valueOf());
	    maxDate.setDate(maxDate.getDate() + 1);
	    $('#'+start_date).datepicker('setStartDate', maxDate);
	});
}

window.validateEnteredDate = function(value, element_id)
{
   if(value.length == 10) {
       var arr_val = value.split('/');
       
       if(occurrences(value, '/') != 2) {
           $('#'+element_id).val('');
           return true;
       }

       var dtMonth = arr_val[0];
       var dtDay = arr_val[1];
       var dtYear = arr_val[2];

       if (dtMonth < 1 || dtMonth > 12)
          $('#'+element_id).val('');
       else if (dtDay < 1 || dtDay > 31)
          $('#'+element_id).val('');
       else if ((dtMonth == 4 || dtMonth == 6 || dtMonth == 9 || dtMonth == 11) && dtDay == 31)
          $('#'+element_id).val('');
       else if (dtMonth == 2) {
          var isleap = (dtYear % 4 == 0 && (dtYear % 100 != 0 || dtYear % 400 == 0));
          if (dtDay > 29 || (dtDay == 29 && !isleap))
              $('#'+element_id).val('');
       } else if (dtYear.length > 4) {
           $('#'+element_id).val('');
       }
   } else if(value.length > 10) {
       $('#'+element_id).val('');
   }
}

window.datatable_excel_common_formatting = function(xlsx,sheet) {
    var sSh = xlsx.xl['styles.xml'];
    var s1 = '<xf numFmtId="300" fontId="0" fillId="0" borderId="0" applyFont="1" applyFill="1" applyBorder="1" xfId="0" applyNumberFormat="1"/>';
    var s2 = '<xf numFmtId="0" fontId="2" fillId="2" borderId="0" applyFont="1" applyFill="1" applyBorder="1" xfId="0" applyAlignment="1">' +
        '<alignment horizontal="center"/></xf>';
    sSh.childNodes[0].childNodes[5].innerHTML = sSh.childNodes[0].childNodes[5].innerHTML + s1 + s2;

    var row = $('row', sheet);
    var foot_num = row.length - 1;
    $('row:eq(0) c', sheet).attr('s', '42'); // Report name - Report Row : grey background bold and centered : datatable_excel_common_formatting()
    $('row:eq(1) c', sheet).attr('s', '42'); // Desclaimer Row : Bold, green background, thin black border
    $('row:eq(2) c', sheet).attr('s', '47'); // Header : Bold, green background, thin black border
    $('row:eq('+foot_num+') c', sheet).attr('s', '47'); // Footer Row : Bold, green background, thin black border

    /*$(row[0]).attr('ht', 20);
    $(row[1]).attr('ht', 20);*/
}

/**
*Function that count occurrences of a substring in a string;
*/
window.occurrences = function(string, subString, allowOverlapping) {

   string += "";
   subString += "";
   if (subString.length <= 0) return (string.length + 1);

   var n = 0,
       pos = 0,
       step = allowOverlapping ? 1 : subString.length;

   while (true) {
       pos = string.indexOf(subString, pos);
       if (pos >= 0) {
           ++n;
           pos += step;
       } else break;
   }
   return n;
}

window.getImageMimeType = function(file, callback) {
    
    //List of known mimes
    var mimes = [
        {
            mime: 'image/jpeg',
            pattern: [0xFF, 0xD8, 0xFF],
            mask: [0xFF, 0xFF, 0xFF],
        },
        {
            mime: 'image/png',
            pattern: [0x89, 0x50, 0x4E, 0x47],
            mask: [0xFF, 0xFF, 0xFF, 0xFF],
        }
        // you can expand this list @see https://mimesniff.spec.whatwg.org/#matching-an-image-type-pattern
    ];

    function check(bytes, mime) {
        for (var i = 0, l = mime.mask.length; i < l; ++i) {
            if ((bytes[i] & mime.mask[i]) - mime.pattern[i] !== 0) {
                return false;
            }
        }
        return true;
    }

    var blob = file.slice(0, 4); //read the first 4 bytes of the file

    var reader = new FileReader();
    reader.onloadend = function(e) {
        if (e.target.readyState === FileReader.DONE) {
            var bytes = new Uint8Array(e.target.result);

            for (var i=0, l = mimes.length; i<l; ++i) {
                if (check(bytes, mimes[i])) return callback(mimes[i].mime);
            }

            return callback("unknown");
        }
    };
    reader.readAsArrayBuffer(blob);
}

window.getUrlParameter = function(sParam) {
    var sPageURL = decodeURIComponent(window.location.search.substring(1)),
        sURLVariables = sPageURL.split('&'),
        sParameterName,
        i;
 
    for (i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split('=');
 
        if (sParameterName[0] === sParam) {
            return sParameterName[1] === undefined ? true : sParameterName[1];
        }
    }
};

window.random_number = function(len) {
	var x='';
 	for(var i=0;i<len;i++){x+=Math.floor(Math.random() * 10);}
 	return x;
}

window.ordinal_suffix_of = function(i) {
    var j = i % 10,
        k = i % 100;
    if (j == 1 && k != 11) {
        return i + "st";
    }
    if (j == 2 && k != 12) {
        return i + "nd";
    }
    if (j == 3 && k != 13) {
        return i + "rd";
    }
    return i + "th";
}

window.censorEmail = function (email){
    var arr = email.split("@");
    return censorWord(arr[0]) + "@" + censorWord(arr[1]);
}

window.censorWord = function (str) {
   return str[0] + "*".repeat(str.length - 2) + str.slice(-1);
}

window.currencyFormat = function(num) {
   return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

window.humanize = function(str) {
  var i, frags = str.split('_');
  for (i=0; i<frags.length; i++) {
    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
  }
  return frags.join(' ');
}

window.eachWordFirstLetterCapital = function(str) {
	return str.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
}

window.toMonthName = function(monthNumber) {
  const date = new Date();
  date.setMonth(monthNumber - 1);

  return date.toLocaleString('en-US', {
    month: 'long',
  });
}

window.addScript = function(url) {
    var script = document.createElement('script');
    script.type = 'application/javascript';
    script.src = url;
    document.head.appendChild(script);
}

window.getBase64 = function(file, container) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
    	console.log(file.name);
		var image_string = reader.result.replace("data:", "").replace(/^.+,/, "");
		console.log(image_string);
		container.append('<div class="form-group col-md-4 col-xl-2 ml-3 text-center border"><img src="data:image/png;base64,'+image_string+'" alt="'+file.name+'" /></div>');
    };
    reader.onerror = function (error) {
		console.log('Error: ', error);
    };
}

window.openLoadingDialog = function(element) {
	element.html('<div class="spinner-border text-info mr-2" role="status"><span class="sr-only">Loading...</span></div>');
}

window.display_expense_message = function(response) {
	if(response.message != "" && response.status == "ERROR") {
		Snackbar.show({
			text: response.message,
			textColor: '#FFFFFF',
			backgroundColor: 'red',
			actionTextColor:'#FFFFFF',
			actionText:'<i class="fas fa-times-circle"></i>',
			duration: 5000,
			pos: 'top-center'
		});
	} else if(response.message != "" && response.status == "SUCCESS") {
		Snackbar.show({
			text: response.message,
			textColor: '#FFFFFF',
			backgroundColor: 'green',
			actionTextColor:'#FFFFFF',
			actionText:'<i class="fas fa-times-circle"></i>',
			duration: 5000,
			pos: 'top-center'
		});
	}
}

window.display_error_message = function(response) {
	if(response.response_data.message != "" && response.response_data.success == false) {
		Snackbar.show({
			text: response.response_data.message,
			textColor: '#FFFFFF',
			backgroundColor: 'red',
			actionTextColor:'#FFFFFF',
			actionText:'<i class="fas fa-times-circle"></i>',
			duration: 5000,
			pos: 'top-center'
		});
	} else if(response.response_data.message != "" && response.response_data.success == true) {
		Snackbar.show({
			text: response.response_data.message,
			textColor: '#FFFFFF',
			backgroundColor: 'green',
			actionTextColor:'#FFFFFF',
			actionText:'<i class="fas fa-times-circle"></i>',
			duration: 5000,
			pos: 'top-center'
		});
	}
}

/*window.pdfkit = function() {
	const doc = new PDFDocument();
	console.log(doc);

	var stream = doc.pipe(blobStream());

	doc.initForm();

	let rootField = doc.formField('rootField');
	let child1Field = doc.formField('child1Field', { parent: rootField });
	let child2Field = doc.formField('child2Field', { parent: rootField });

	doc.formText('leaf1', 10, 10, 200, 40, {
	  parent: child1Field,
	  multiline: true
	});
	
	doc.formText('leaf2', 10, 60, 200, 40, {
	  parent: child1Field,
	  multiline: true
	});
	
	doc.formText('leaf1', 10, 110, 200, 80, {
	  parent: child2Field,
	  multiline: true
	});

	var opts = {
	  backgroundColor: 'yellow',
	  label: 'Test Button'
	};
	doc.formPushButton('btn1', 10, 200, 100, 30, opts);

	doc.end();

	stream.on('finish', function() {
	    var tt = stream.toBlobURL('application/pdf');
	    console.log(tt);
	});

	return false;
}*/


/*Snackbar.show({
	text: 'Your account created succefully.',
	textColor: '#FFFFFF',
	backgroundColor: 'green',
	actionTextColor:'#FFFFFF',
	actionText:'<i class="fas fa-times-circle"></i>',
	duration: 1500000,
	pos: 'top-center'
});*/